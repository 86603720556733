import React, { CSSProperties } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

export interface RadioButtonChangeEvent {
  value: string;
}

export interface RadioButtonProps {
  style?: CSSProperties
  checked?: boolean;
  label: string;
  name: string;
  value?: string;
  change: (e: RadioButtonChangeEvent) => void;
}

export const RadioButton: React.FC<RadioButtonProps> = (props) => {
  return (
    <FormGroup row>
      <FormControlLabel
        style={props.style}
        label={props.label}
        control={
          <Radio
            checked={props.checked}
            value={props.value}
            onChange={(e) => props.change(e.target)}
            name={props.name}
            color="primary"
          />
        }
      />
    </FormGroup>
  );
}
