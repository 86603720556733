import React, { ReactNode } from 'react';
import ExpandingIconButton, { HorizontalAlignment } from '../../Shared/ExpandingIconButton/ExpandingIconButton';
import { BackIconMode } from '../../modals/SlideEditorModal/SlideEditorModal';
import { withTheme } from 'styled-components';
import icons from '../../../assets/icons';

export interface SlideEditorCommandBarProps {
  showBackNavigationButton: boolean;
  showDefaultActions: boolean;
  showDeleteFile?: boolean;
  expandSettingsButton?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  slideWidth?: string;
  backIconMode: BackIconMode;
  theme?: any;
  style?: React.CSSProperties;
  onDisplaySettings?: () => void;
  onDisplaySlideWidthSettings?: () => void;
  onNavigateBack?: () => void;
  onExit?: () => void;
  onDelete?: () => void;
}

export function SlideEditorCommandBar(props: SlideEditorCommandBarProps) {
  return (
    <div
      style={props.style}
      className={`slideEditorCommandBar${
        props.backIconMode === BackIconMode.Dark
          ? props.theme && props.theme.mode
            ? ` ${props.theme.mode}`
            : ' darkBackground'
          : props.theme && props.theme.mode
          ? ` ${props.theme.mode}`
          : ' lightBackground'
      }`}
    >
      <div className="cornerAction">
        {props.showBackNavigationButton && props.onNavigateBack && (
          <icons.Nav_Arrow_ArrowLeft
            color={props.backIconMode === BackIconMode.Dark ? 'var(--WHITES-NORMAL-900)' : 'var(--COLOR-SECONDARY-500)'}
            className="hoverIcon"
            onClick={() => {
              props.onNavigateBack && props.onNavigateBack();
            }}
            xlinkTitle="go back"
            data-testid="go back"
          />
        )}
        {!props.showBackNavigationButton && props.onExit && (
          <icons.Nav_Exit_Main
            color={props.backIconMode === BackIconMode.Dark ? 'var(--WHITES-NORMAL-900)' : 'var(--COLOR-SECONDARY-500)'}
            className="hoverIcon"
            onClick={() => {
              props.onExit && props.onExit();
            }}
            xlinkTitle="close image slide editor dialog"
            data-testid="close image slide editor dialog"
          />
        )}
      </div>
      <div className="commandBarContent">{props.children}</div>
      {props.showDefaultActions && (
        <div className="commandBarDefaultActions">
          <div className="slideSettingsSeparator"></div>
          {props.showDeleteFile && (
            <ExpandingIconButton
              style={{ marginLeft: '17px' }}
              alt="Delete"
              src="/icons/content-alteration/delete.svg"
              expandWidth={80}
              label="Delete"
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                if (props.onDelete) {
                  props.onDelete();
                }
              }}
              labelLocation={HorizontalAlignment.Left}
              expanded={false}
              visible={true}
            />
          )}
          {!props.showDeleteFile && props.onDisplaySlideWidthSettings && (
            <ExpandingIconButton
              style={{ marginLeft: '6px' }}
              alt="slide width settings"
              src={
                props.slideWidth
                  ? props.slideWidth === 'HALF'
                    ? `/icons/nav/display/half&half.svg`
                    : props.slideWidth === 'EDGE'
                    ? '/icons/nav/display/max-width.svg'
                    : '/icons/nav/display/normal.svg'
                  : '/icons/nav/display/normal.svg'
              }
              iconCssClass={''}
              expandWidth={111}
              label="Slide width"
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                props.onDisplaySlideWidthSettings && props.onDisplaySlideWidthSettings();
              }}
              labelLocation={HorizontalAlignment.Left}
              expanded={false}
              visible={true}
            />
          )}
          {props.onDisplaySettings && (
            <ExpandingIconButton
              style={{ marginLeft: '16px' }}
              alt="settings"
              src="/icons/nav/settings.svg"
              expandWidth={111}
              label="All Settings"
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                props.onDisplaySettings && props.onDisplaySettings();
              }}
              labelLocation={HorizontalAlignment.Left}
              expanded={props.expandSettingsButton === true}
              visible={true}
              disabled={props.disabled}
            />
          )}
        </div>
      )}
    </div>
  );
}
export default withTheme(SlideEditorCommandBar);
