import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Slide } from '../../../models/Slide';
import { User } from '../../../models/User';
import { SlideshowPlayer } from '../SlideshowSlide/SlideshowPlayer';
import { Article } from '../../../models/Article';
import { ChildArticleEditorInfo } from '../../../models/ChildArticleEditorInfo';
import agent from '../../../agent';
import {
  getChildArticleTypeName,
  ChildArticleType,
} from '../../../models/ArticleLayoutEnum';
import { Dictionary, SlideshowSlideInfo } from '../../../models/SlideShowSlide';
import { urlForArticle } from '../../../utils/helperFunctions';
import { history } from '../../../store';
import { RouteComponentProps } from 'react-router-dom';
import { isNullOrUndefined } from 'util';
import {
  MODAL_CLOSE,
  SET_TOASTER_MESSAGE,
  SET_NEXT_VIEW_MODE,
  SET_VIEW_MODE,
} from '../../../constants/actionTypes';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import { ViewMode } from '../../Gide/SlideView/SlideView';

type PathParamsType = {
  slideId: string;
};

const mapStateToProps = (
  state: any,
  ownProps: RouteComponentProps<PathParamsType>,
) => {
  const slideId = ownProps.match.params.slideId;
  return {
    slideId,
    article: state.article.article,
    currentUser: state.common.currentUser,
    appName: state.common.appName,
    view: state.common.view,
    viewMode: state.common.viewMode,
    nextViewMode: state.common.nextViewMode,
    zoomed: state.common.zoomed,
    showChrome: state.common.showChrome,
    showCaptionPanel: state.common.showCaptionPanel,
    showInfoPanel: state.common.showInfoPanel,
    toggleOverlayCount: state.common.toggleOverlayCount,
    percentArticleCompleted: state.article.percentArticleCompleted,
    childArticleEditInfo: state.article.childArticleEditInfo,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onSetViewMode: (mode: ViewMode) => dispatch({ type: SET_VIEW_MODE, mode }),
  setNextViewMode: (mode: ViewMode) =>
    dispatch({ type: SET_NEXT_VIEW_MODE, mode }),
  closeModal: () => dispatch({ type: MODAL_CLOSE }),
  showNotification: (payload: { toasterMessageInfo: ToasterMessageInfo }) =>
    dispatch({ type: SET_TOASTER_MESSAGE, payload }),
});

type SlideshowViewProps = RouteComponentProps<PathParamsType> & {
  slide: Slide;
  article: Article;
  currentUser: User;
  childArticleEditInfo?: ChildArticleEditorInfo;
  showChrome: boolean;
  zoomed: boolean;
  showInfoPanel: boolean;
  showCaptionPanel: boolean;
  appName: string;
  view: string;
  viewMode: ViewMode;
  nextViewMode: ViewMode;
  slug: string;
  showNotification?: (
    payload: { toasterMessageInfo: { message: string; type: any } },
  ) => void;
  // setLoading: () => void;
  setNextViewMode: (mode: ViewMode) => void;
  onSetViewMode: (mode: ViewMode) => void;
};

interface SlideshowViewState {
  slide?: Slide;
  slides: Slide[];
  slideshowSlideArticle?: Article;
  slideshowSlidesInfo: Dictionary<SlideshowSlideInfo>;
  isLoading: boolean;
}

export class SlideshowView extends Component<
  SlideshowViewProps,
  SlideshowViewState
> {
  constructor(props: SlideshowViewProps) {
    super(props);
    this.state = {
      slide: undefined,
      slides: [],
      slideshowSlidesInfo: {},
      isLoading: true,
    };
  }

  componentDidMount() {
    this.load();
  }

  exitSlideshowViewMode = () => {
    // exit if SWIPE mode
    // if not SWIPE mode, then the replay slideshow displays
    if (this.props.nextViewMode === 'SWIPE') {
      this.props.onSetViewMode(this.props.nextViewMode);
      this.props.setNextViewMode('SLIDE');

      const url =
        this.props.article && this.state.slide && this.state.slide.position
          ? `${urlForArticle(this.props.article)}/slide/${this.state.slide
              .position + 1}`
          : `${urlForArticle(this.props.article)}/slide/1}`;

      window.setTimeout(() => {
        history.push(url, undefined);
      }, 300);
    }
  };

  async load() {
    this.setState({ isLoading: true });
    const slideshowSlidesArticleResp = await agent.Slides.getSettings(
      this.props.match.params.slideId,
      getChildArticleTypeName(ChildArticleType.Slideshow),
    );
    const slideshowSlidesResp = await agent.Slides.forArticle(
      slideshowSlidesArticleResp.article,
    );
    // const slideshowSlidesInfo = this.props.slide
    //   ? this.props.slide.data.slideshowSlidesInfo
    //   : {};
    const slideshowSlideResp = await agent.Slides.get(
      this.props.match.params.slideId,
    );
    const slideshowSlidesInfo =
      slideshowSlideResp && slideshowSlideResp.slide
        ? slideshowSlideResp.slide.data.slideshowSlidesInfo
        : {};
    const sortedSlides = slideshowSlidesResp.slides
      ? slideshowSlidesResp.slides.sort((s1: Slide, s2: Slide) => {
          let s1StartTime =
            slideshowSlidesInfo && slideshowSlidesInfo[s1.id]
              ? slideshowSlidesInfo[s1.id].startTimeInSeconds
              : 0;
          let s2StartTime =
            slideshowSlidesInfo && slideshowSlidesInfo[s2.id]
              ? slideshowSlidesInfo[s2.id].startTimeInSeconds
              : 0;
          const timeDiff = s1StartTime - s2StartTime;
          return timeDiff !== 0 ? timeDiff : s1.position - s2.position;
        })
      : [];
    this.setState({
      slide: slideshowSlideResp.slide,
      slideshowSlideArticle: slideshowSlidesArticleResp.article,
      slides: sortedSlides,
      slideshowSlidesInfo: slideshowSlidesInfo,
      isLoading: false,
    });
  }

  render() {
    const slideshowSlidesInfo = this.state.slideshowSlidesInfo;
    const slide = this.state.slide;
    const article = this.props.article;
    const articleSlug = this.props.article ? this.props.article.slug : '';
    const slideshowTitle = slide && slide.data ? slide.data.headerCaption : '';
    const classes = 'container page flexColumnFull';
    const style = { height: '100%' };
    return (
      <div className={classes} style={style}>
        {!isNullOrUndefined(slide) &&
          slide.data.slideshowSlideDetails &&
          this.state.slides.length > 0 &&
          this.props.article &&
          !this.state.isLoading && (
            <SlideshowPlayer
              article={article}
              showChrome={this.props.showChrome}
              showCaptionPanel={true}
              showInfoPanel={true}
              appName={this.props.appName}
              currentUser={this.props.currentUser}
              articleSlug={articleSlug}
              slideshowSlides={this.state.slides}
              slideshowSlidesInfo={slideshowSlidesInfo}
              slideshowBeginTime={slide.data.slideshowSlideDetails.beginTime}
              slideshowDuration={slide.data.slideshowSlideDetails.endTime}
              showSlideshowControls={
                slide.data.slideshowSlideDetails.showSlideshowControls
              }
              useAudioTrack={slide.data.slideshowSlideDetails.useAudioTrack}
              useSlideTimings={slide.data.slideshowSlideDetails.useTimeDuration}
              view={this.props.view}
              viewMode={this.props.viewMode}
              nextViewMode={this.props.nextViewMode}
              slideshowTitle={slideshowTitle}
              slideshowImageUrl={
                slide.data.slideshowImage
                  ? slide.data.slideshowImage
                  : article.image
              }
              audioTrack={slide.data.audioTrack}
              playSlideshow={true}
              finishedPlayingSlideshow={this.exitSlideshowViewMode}
              // setLoading={this.props.setLoading}
              setNextViewMode={this.props.setNextViewMode}
              onSetViewMode={this.props.onSetViewMode}
            />
          )}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SlideshowView);
