import React from 'react';
import { 
  SlideEditorModalProps, 
  BackIconMode, 
  EditorState, 
  ModalOverlayState, 
  ProgressIndicatorType 
} from '../../modals/SlideEditorModal/SlideEditorModal';
import { darkTheme } from '../../../themes/custom-theme';
import { NotificationType } from '../../../constants/strings';
import { ensureImageFileOrientation } from '../../../utils/helperFunctions';
import { API_ROOT } from '../../../constants/paths';
import superagent from 'superagent';
import { 
  CommonContext, 
  CommonDerivedState, 
  getHeaderTitleAndIcon,
  CommonModes, 
} from './Common';
import ImageDraw, { DrawingTool } from '../../Viewers/ImageViewers/ImageDraw/ImageDraw';

const getSlideEditorModalProps = (context: CommonContext): SlideEditorModalProps => {
  const { state, setState, commonSlideEditorModalProps } = context;

  return {
    ...commonSlideEditorModalProps,
    backIconMode: BackIconMode.Dark,
    hideFooter: true,
    hideCommandBar: false,
    editorState: EditorState.Edit,
    loadingInfo: state.loadingInfo,
    modalOverlayState: ModalOverlayState.None,
    selectedItemIndex: -1,
    hideActionContainer: true,
    showBackNavigation: true,
    showDefaultActions: false,
    createActions: [] as any,
    headerActions: getHeaderTitleAndIcon('Doodle', '/icons/slidetype/doodle/main.svg', 'white'),
    onNavigateBack: () => {
      setState({ ...state, mode: CommonModes.Edit });
    },
  };
}

const getContent = (context: CommonContext, slideEditorModalProps: SlideEditorModalProps) => {
  const { state, setState, commonSlideEditorModalProps: { showNotification } } = context;

  // TODO: This ref is to allow the undo functionality. Ask Luke if this needs to be added back in as it is 
  // not in the new designs in Zeplin. 1/15/2020
  // const imageDrawRef = useRef<ImageDraw | null>(null)
  return (
    <ImageDraw
      // ref={imageDrawRef}
      image={state.slide.data.files[state.selectedItemIndex]}
      imageDrawTool={DrawingTool.Pencil}
      width={500}
      height={500}
      saveImage={(image: any) => {
        setState({  
          ...state,        
          loadingInfo: {
            message: 'Saving changes to image',
            progressIndicatorType: ProgressIndicatorType.Indeterminate,
          },
        });
        // catch the returned data and add to list. will need to save it
        ensureImageFileOrientation(image).then(imageFile => {
          superagent
            .post(`${API_ROOT}/util/upload`)
            .attach('theseNamesMustMatch', imageFile)
            .end((err, res) => {
              if (err) {
                console.log(err);
                showNotification({
                  type: NotificationType.ERROR,
                  message: err,
                });
              }
              if (res.body && res.body.url) {
                const slide = {
                  ...state.slide,
                  data: {
                    ...state.slide.data,
                    files: state.slide.data.files.map((i: any, index: number) => {
                      if (state.selectedItemIndex === index) {
                        return {
                          ...i,
                          url: res.body.url,
                          type: 'UPLOAD',
                        };
                      } else {
                        return i;
                      }
                    })
                  }
                };
                setState({ 
                  ...state,
                  mode: CommonModes.Edit,
                  loadingInfo: undefined,
                  slide,
                });
              }
            });
        });
      }}
    />
  );
}

export const getDerivedState = (context: CommonContext): CommonDerivedState => {
  const slideEditorModalProps = getSlideEditorModalProps(context);
  const content = getContent(context, slideEditorModalProps);
  return {
    ...context.state,
    slideEditorModalProps,
    content,
    theme: darkTheme,
  };
};

export const Doodle = {
  getSlideEditorModalProps,
  getContent,
  getDerivedState,
};
