import React, { createContext, Dispatch, useReducer, ConsumerProps } from 'react';
import { assertNever } from 'office-ui-fabric-react/lib/Utilities';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';

/// https://kentcdodds.com/blog/how-to-use-react-context-effectively

// export interface Notification {
//   type: NotificationType;
//   title: string;
//   message: string;
// }

export type NotificationContext = {
  notification?: ToasterMessageInfo;
}

export type ToastAction = {
  type: 'set',
  notification?: ToasterMessageInfo | null,
} | {
  type: 'clear',
};

const defaultNotificationDispatch: Dispatch<ToastAction> = (action) => {
  console.error("Unable to handle action because no NotificationContext was found.", action);
};

const notificationContext = createContext<NotificationContext>({});
const notificationDispatchContext = createContext<Dispatch<ToastAction>>(defaultNotificationDispatch);

const reducer: React.Reducer<NotificationContext, ToastAction> = (state, action) => {
  switch (action.type) {
    case 'set':
      return {
        ...state,
        notification: action.notification,
      };
    case 'clear':
      return {};
    default:
      assertNever(action);
  }
};

export interface NotificationProviderProps {
  initialNotificationContext?: NotificationContext;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = (props) => {
  //// TODO - pass notifications on to outerContext too?
  //const outerContext = useContext(notificationContext);

  const [notification, dispatch] = useReducer(reducer, props.initialNotificationContext || {});

  return (
    <notificationContext.Provider value={notification}>
      <notificationDispatchContext.Provider value={dispatch}>
        {props.children}
      </notificationDispatchContext.Provider>
    </notificationContext.Provider>
  );
}

export const useNotification = () => {
  const notification = React.useContext(notificationContext)
  if (notification === undefined) {
    throw new Error('useNotification must be used within a NotificationProvider')
  }
  return notification
}

export const useNotificationDispatch = () => {
  const dispatch = React.useContext(notificationDispatchContext)
  if (dispatch === undefined) {
    throw new Error('useNotificationDispatch must be used within a NotificationProvider')
  }
  return dispatch
}

export const NotificationConsumer: React.FC<ConsumerProps<NotificationContext>> = (props) => {
  return (
    <notificationContext.Consumer>
      {context => {
        if (!context) {
          throw new Error('NotificationConsumer must be used within a NotificationProvider');
        }
        return props.children(context);
      }}
    </notificationContext.Consumer>
  );
}

export const NotificationDispatchConsumer: React.FC<ConsumerProps<Dispatch<ToastAction>>> = (props) => {
  return (
    <notificationDispatchContext.Consumer>
      {context => {
        if (!context) {
          throw new Error('NotificationDispatchConsumer must be used within a NotificationDispatchProvider');
        }
        return props.children(context);
      }}
    </notificationDispatchContext.Consumer>
  );
}

export const withNotification = <P,>(Component: React.ComponentType<P>) => {
  const wrappedComponent = (props: P) => {
    return (
      <NotificationConsumer>
        {(notification) =>
          <Component {...props} notification={notification} />
        }
      </NotificationConsumer>
    );
  }
  return wrappedComponent;
}

export const withNotificationDispatch = <P,>(Component: React.ComponentType<P>) => {
  const wrappedComponent = (props: P) => {
    return (
      <NotificationDispatchConsumer>
        {(notificationDispatch) =>
          <Component {...props} notificationDispatch={notificationDispatch} />
        }
      </NotificationDispatchConsumer>
    );
  }
  return wrappedComponent;
}
