import React, { CSSProperties, ReactNode } from 'react';
import classnames from 'classnames';
import styles from './_circle-icon-button.module.scss';
import GideImage from '../Image/GideImage';
export interface CircleIconButtonProps {
  image?: string;
  alt?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  backgroundColor: string;
  style?: CSSProperties;
  className?: string;
  iconCssClass?: string;
  width?: number;
  height?: number;
  iconHeight?: number;
  iconWidth?: number;
  children?: ReactNode;
  enabled?: boolean;
}

export function CircleIconButton(props: CircleIconButtonProps) {
  const style = {
    ...props.style,
    backgroundColor: props.backgroundColor,
    '--width': props.width ? `${props.width}px` : '38px',
    '--height': props.height ? `${props.height}px` : '38px',
    '--iconWidth': props.iconWidth ? `${props.iconWidth}px` : '24px',
    '--iconHeight': props.iconHeight ? `${props.iconHeight}px` : '24px',
    '--iconWidthOffset': props.iconWidth ? `${props.iconWidth / 2}px` : '12px',
    '--iconHeightOffset': props.iconHeight ? `${props.iconHeight / 2}px` : '12px',    
  }    
  const classNames = classnames([styles.circleIconButtonContainer, props.className, !!props.onClick ? styles.circleIconButtonContainerHover : '']);
  return (
    <div className={classNames} style={style}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {props.onClick && props.enabled !== false && props.onClick(e);}}
    >
      {props.children && props.children}
      {!props.children && props.image && (
        <GideImage 
          className={`${styles.circleIconButton} ${props.iconCssClass
            ? props.enabled !== false
              ? props.iconCssClass 
              : 'color-secondary-600-svg'
            : 'color-secondary-200-svg'}`
          }
          src={props.image}
          alt={props.alt}          
        />
      )}
    </div>
  );
}
