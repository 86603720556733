import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, List } from 'semantic-ui-react';

import {
  MODAL_CLOSE,
} from '../../constants/actionTypes';

const mapDispatchToProps = dispatch => ({
  closeModal: payload => dispatch({ type: MODAL_CLOSE }),
});

const mapStateToProps = state => ({
  article: state.article.article,
  currentUser: state.common.currentUser,
  folders: state.channel.channel.folders,
});

export class SelectFolderModal extends Component {
  constructor(props) {
    super(props);

    this.selectFolder = folder => {
      this.setState({ folder });
    };
  }

  render() {
    const { folder } = this.state;
    const { closeModal, folders } = this.props;

    return (
      <Modal
        style={{ width: '300px' }}
        closeOnEscape={true}
        onClose={closeModal}
        className="slideModal"
        size="tiny"
        dimmer="inverted"
        closeOnDocumentClick={false}
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Content className="preventSemanticActionStyle">
          <Modal.Description
            className="headerLevel3 preventSemanticActionStyle"
            style={{ padding: '10px' }}
          >
            <span>
              <strong>Select a Folder</strong>
            </span>
          </Modal.Description>

          <section>
            {(!folders || !folders.length) && (
              <div>No Folders available.</div>
            )}
            <List selection divided relaxed>
            {folders.map((f, i) => (
                <List.Item
                key={i}
                onClick={() => this.selectFolder(f)}
                className={
                    folder
                    ? folder._id === f._id ? 'selected' : ''
                    : ''
                }
                >
                <List.Content>{f.title}</List.Content>
                </List.Item>
            ))}
            </List>
          </section>
        </Modal.Content>
        <Modal.Actions className="preventSemanticActionStyle">
          <div className="flexRowFlexEnd">
            <Button
              disabled={!this.state.folder}
              color="blue"
              content={this.props.saveButtonTitle}
              onClick={() => {
                this.props.saveCallback({
                  folderId: this.state.folder._id,
                  folder: this.state.folder,
                });
                closeModal();
              }}
            />
            <Button onClick={closeModal}>Cancel</Button>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  SelectFolderModal,
);
