import React, { useState } from 'react';
import styles from './_header-modal.module.scss';
import ResizeableModal from '../ResizeableModal/ResizeableModal';
import { SlideUpdateInfo, Slide, SlideModel } from '../../../models/Slide';
import icons from '../../../assets/icons';
import Picker, { PickerDisplayMode } from '../../DataEntry/Pickers/Picker/Picker';
import { HeaderMenuOptions } from '../../InputBar/InputBar';
import { HeaderLevel, NotificationType } from '../../../constants/strings';
import { Article } from '../../../models/Article';
import { noop } from 'lodash';
import { AudioFile } from '../../../models/AudioFile';
import classNames from 'classnames';
import GideImage from '../../Shared/Image/GideImage';
import { CheckBox, CheckboxChangeEvent } from '../../Shared/CheckBox/CheckBox';
import agent from '../../../agent';
import InputBarEditor from '../../DataEntry/InputBar/InputBarEditor/InputBarEditor';
import { useDispatch } from 'react-redux';
import { MODAL_CLOSE } from '../../../constants/actionTypes';
import SlideDimensionsPicker from '../../DataEntry/Pickers/Modals/SlideDimensionsPicker/SlideDimensionsPicker';
import SlideSettings, { Settings, getSlideSettings } from '../../SlideEditors/SlideSettings/SlideSettings';
import { useMediaQuery } from '@material-ui/core';

import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import { hasValue } from '../../../utils/helperFunctions';
import AudioRecorder from '../../media/AudioRecorder/AudioRecorder';
export interface HeaderModalProps {
  article: Article;
  availableHeaderLevels: HeaderLevel[];
  submitSlide: (payload: SlideUpdateInfo) => void;
  currentSlidePosition: number;
  slide?: Slide;
  showNotification: (toasterMessageInfo: ToasterMessageInfo) => void;
}



export default function HeaderModal(props: HeaderModalProps) {
  const [selectedHeaderLevel, setSelectedHeaderLevel] = useState<number | undefined>(props.slide ? props.slide.data.level : undefined);
  const [title, setTitle] = useState<string | undefined>(props.slide ? props.slide.data.title : undefined);
  const [headerCaption, setHeaderCaption] = useState<string>(props.slide ? props.slide.data.caption : undefined);
  const [audioCaption, setAudioCaption] = useState<AudioFile | undefined>(props.slide ? props.slide.data.audioCaption : undefined);
  const [displayAudioCaption, setDisplayAudioCaption] = useState<boolean>(false);
  const [isCollapsible, setIsCollapsible] = useState<boolean>(props.slide && props.slide.data.notCollapsible === true ? false : true);
  const [collapsedByDefault, setIsCollapsedByDefault] = useState<boolean>(props.slide && props.slide.data.defaultCollapsed === true ? true : false);
  const [displayWidthSettings, setDisplayWidthSettings] = useState<boolean>(false);
  const [slideWidthPercentage, setSlideWidthPercentage] = useState<number | undefined>(props.slide && props.slide.widthPercentage ? props.slide.widthPercentage : undefined);
  const [slideWidthType, setSlideWidthType] = useState<string | undefined>(props.slide && props.slide.width ? props.slide.width : undefined);
  const [slideHorizontalAlignment, setSlideHorizontalAlignment] = useState<string | undefined>(props.slide && props.slide.contentHorizontalAlignment ? props.slide.contentHorizontalAlignment : undefined)
  const [slideSettings, setSlideSettings] = useState<Settings>(props.slide && props.slide.contentHorizontalAlignment 
    ? getSlideSettings(props.slide, "NORMAL") 
    : getSlideSettings());
  const [displaySlideSettings, setDisplaySlideSettings] = useState<boolean>(false);
  
  const slideNumberClasses = classNames(
    'slideNumberCreate',
    props.currentSlidePosition < 10 ? 'oneToNine' : 'tenToMax',
  );  
  const dispatch = useDispatch();
  const actions: {closeModal: () => void} = {
    closeModal: () => dispatch({ type: MODAL_CLOSE }),
  };

  const useNormalWidth = useMediaQuery('(min-width:535px)');

  const submitSlide = async () => {
    if(selectedHeaderLevel && hasValue(title)) {
      const slide: SlideModel = props.slide 
        ? {
          ...props.slide,
          ...slideSettings,
            data: {
              ...props.slide.data,
              level: selectedHeaderLevel,
              caption: headerCaption,
              title: title,
              notCollapsible: !isCollapsible,
              defaultCollapsed: collapsedByDefault,
              audioCaption: audioCaption,
            },
            width: slideWidthType,
            widthPercentage: slideWidthPercentage,
            contentHorizontalAlignment: slideHorizontalAlignment
          }
        : {
          slideType: 'HEADER',
          ...slideSettings,
          data: {            
            level: selectedHeaderLevel,
            caption: headerCaption,
            title: title,
            notCollapsible: !isCollapsible,
            defaultCollapsed: collapsedByDefault,
            audioCaption: audioCaption,
          },
          position: props.currentSlidePosition,
          width: slideWidthType,
          widthPercentage: slideWidthPercentage,
          contentHorizontalAlignment: slideHorizontalAlignment
        };

        const payload = props.slide 
          ? await agent.Slides.update(props.slide.id, slide) 
          : await agent.Slides.create(props.article, slide);      
        props.submitSlide({slide: payload.slide, preventScrollToSlide: false});
        actions.closeModal();
      } else {
        props.showNotification({message: "You must select a header level and enter a header title", type: NotificationType.ERROR})
      }
    }
    const headerToolbarContent = (      
      <div className={styles.headerHeaderContent}>
        {!displaySlideSettings && (
          <>        
            {/* <RoundedCornerButton label={'Format'} style={{ backgroundColor: 'var(--COLOR-PRIMARY-300)' }} /> */}
            <div style={{height: '32px', marginLeft: '11px', marginRight: '13px'}} className="verticalLine"></div>
            <icons.Nav_Display_Tool color="var(--COLOR-SECONDARY-500)"
              onClick={() => {
                setDisplayWidthSettings(true)
              }}
              style={{cursor: 'pointer'}}
            />
            <icons.Nav_Settings
              onClick={() => {
                setDisplaySlideSettings(true);
              }}
              style={{cursor: 'pointer', marginLeft: '30px', marginRight: '5px'}} color="var(--COLOR-SECONDARY-500)" 
            />
          </>
        )}
      </div>
    );
  return (
    <ResizeableModal
      canResize={false}
      closeModal={() => actions.closeModal()}
      closeOnDimmer={false}
      mode="free"
      width={752}
      hideCommandBar={false}
      rightToolbarContent={headerToolbarContent}
      navigationState={(displaySlideSettings || displayWidthSettings) ? "backNavigation" : "closeModal"}
      navigateBack={() => {
        setDisplaySlideSettings(false);
        setDisplayWidthSettings(false);
      }}
    >
      <div className={styles.headerModalContainer}>
        {displaySlideSettings === false && (
          <>
            <div className={styles.picker}>
              <Picker
                pickerItems={[
                  { icon: icons.ContentAlteration_RichText_Header_H1, label: 'Header 1', value: HeaderMenuOptions.H1 },
                  { icon: icons.ContentAlteration_RichText_Header_H2, label: 'Header 2', value: HeaderMenuOptions.H2 },
                  { icon: icons.ContentAlteration_RichText_Header_H3, label: 'Header 3', value: HeaderMenuOptions.H3 },
                  { icon: icons.ContentAlteration_RichText_Header_H4, label: 'Header 4', value: HeaderMenuOptions.H4 },
                  { icon: icons.ContentAlteration_RichText_Header_H5, label: 'Header 5', value: HeaderMenuOptions.H5 },
                ]}
                displayMode={PickerDisplayMode.CircularTextOutsideUnder}
                itemsPerRow={useNormalWidth ? 5 : 4}
                itemCSSClass={'pickerItem'}
                selectedValue={selectedHeaderLevel ? selectedHeaderLevel : -1}
                enabledValues={props.availableHeaderLevels}
                onChange={(value: number) => {
                  setSelectedHeaderLevel(value);
                }}
              />
            </div>
            <div className={styles.options}>
              <CheckBox
                label="Collapsible"
                checked={isCollapsible}
                change={(e: CheckboxChangeEvent) => {
                  setIsCollapsible(e.checked);
                }}            
              />
              <CheckBox
                label="Collapsed By Default"
                checked={collapsedByDefault}
                change={(e: CheckboxChangeEvent) => {
                  setIsCollapsedByDefault(e.checked);
                }}
              />
            </div>
            <div className={classNames(styles.headerContent, displayAudioCaption ? styles.audioCaptionContainer : undefined)}>
              {!displayAudioCaption && (
                <textarea 
                  value={title}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setTitle(event.target.value);
                  }} 
                />
              )}
              {displayAudioCaption && (
                <AudioRecorder
                  onError={(message: string) => props.showNotification({message: message, type: NotificationType.ERROR})}
                  theme="light"
                  audioFile={audioCaption}
                  displayAutoPlayControls={false}
                  saveAudioRecording={(audioFile: AudioFile) => {
                    setAudioCaption(audioFile);
                    setDisplayAudioCaption(false);
                  }}
                  closeAudioRecording={() => {
                    setDisplayAudioCaption(false);
                  }}
                  onDeleteAudioRecording={(audioFile: AudioFile) => {
                    if(window.confirm("Are you sure you want to delete the audio caption?")) {
                      setAudioCaption(undefined);
                    }
                  }}
                />
              )}              
            </div>
            <div className={styles.inputBarOuterContainer}>
              <div className={styles.inputBar}>
                <InputBarEditor
                  placeholderText="Add a caption"
                  inputBarValue={headerCaption}
                  displayElipsisOnLostFocus={true}
                  onChange={(value: string) => {
                    setHeaderCaption(value);
                  }}
                  onAcceptInput={noop}
                  onInputEditorFocusChanged={noop}
                >
                  <icons.SlideType_Audio_Mic
                    color={audioCaption ? 'var(--COLOR-BLUE-100)' : 'var(--COLOR-SECONDARY-500)'}
                    style={{ marginRight: '11px', marginBottom: '5px', cursor: 'pointer' }}
                    onClick={() => setDisplayAudioCaption(true)} //this.showAudioCaptionControls()}
                  />
                </InputBarEditor>
                
                {/* <icons.Nav_Discover
                  color={audioCaption ? 'var(--COLOR-BLUE-100)' : 'var(--COLOR-SECONDARY-500)'}
                  style={{ marginLeft: '16px', marginBottom: '10px', cursor: 'pointer' }}
                  onClick={() => {
                    // this.setState({showGeotagMap: true})
                  }}
                />           */}
                <div style={{marginLeft: '22px', marginBottom: '5px'}} className="flexRowFlexNormal slideNumberCreateContainer">
                  <span className={slideNumberClasses}>
                    {props.currentSlidePosition}
                  </span>
                  <GideImage
                    className="pointer"
                    onClick={(e) => {
                      submitSlide();
                    }}
                    onMouseDown={(e) => {
                      e.preventDefault();
                    }}
                    src="/icons/content-alteration/input-bar/slide/finalise.svg"
                  />
                </div>           
              </div>
            </div>
            {displayWidthSettings && (
                <SlideDimensionsPicker
                  className={styles.dimensionsModal}
                  onSave={(type, alignment, percentage)  => {
                    setSlideWidthType(type);
                    setSlideHorizontalAlignment(alignment);
                    setSlideWidthPercentage(percentage);
                    setDisplayWidthSettings(false);
                  }}
                  onCancel={(type, alignment, percentage) => {
                    setSlideWidthType(type);
                    setSlideHorizontalAlignment(alignment);
                    setSlideWidthPercentage(percentage);
                    setDisplayWidthSettings(false);
                  }}
                  onChange={(type, alignment, percentage) => {
                    setSlideWidthType(type);
                    setSlideHorizontalAlignment(alignment);
                    setSlideWidthPercentage(percentage);              
                  }}
                  slideWidthType={slideWidthType || 'NORMAL'}
                  slideAlignment={slideHorizontalAlignment || 'CENTER'}
                  slideWidthPercentage={slideWidthPercentage || 100}
                />
            )}
          </>
        )}
        {displaySlideSettings && (
          <SlideSettings
            settings={slideSettings}
            hideTemplateIndicator={true}
            onCloseSettings={() => {
              setDisplaySlideSettings(false);
            }}
            onSettingsChanged={(settings) => {
              setSlideSettings(settings);
            }}
          />
        )}
      </div>
    </ResizeableModal>
  );
}
