import React from 'react';
import { getSlideFileUrl } from '../../../../utils/helperFunctions';
import { ImageFile } from '../../../../models/SlideFile';
import GideImage from '../../../Shared/Image/GideImage';
export interface ImageSwipeProps {
  imageList: ImageFile[];
  width?: number;
  view: string;
  onClick?: (selectedIndex?: number) => void;
}

export default function ImageSwipe(props: ImageSwipeProps) {
  return (
    <div className="gideImageSwipeContainer"
    >
      <div className="gideImageSwipe">
        <div className="gideImageSwipeImage1"
          onClick={() => {
            if (props.onClick) {
              props.onClick(0);
            }
          }}
        >
          <GideImage 
            className={props.imageList.length > 0
              ? ''
              : 'missingResourceIcon'
            }
            src={props.imageList.length > 0
              ? getSlideFileUrl(props.imageList[0])
              : '/icons/content-alteration/camera/MISSING_RESOURCE_REPLACE.svg'}
            alt="top left display"
          />
        </div>
        <div className="gideImageSwipeImage2"
          onClick={() => {
            if (props.onClick) {
              props.onClick(1);
            }
          }}        
        >
          <GideImage 
            className={props.imageList.length > 0
              ? ''
              : 'missingResourceIcon'
            }
            src={props.imageList.length > 1
              ? getSlideFileUrl(props.imageList[1])
              : '/icons/content-alteration/camera/MISSING_RESOURCE_REPLACE.svg'
            }
            alt="top right display"
          />
        </div>
        <div className="gideImageSwipeImage3"
          onClick={() => {
            if (props.onClick) {
              props.onClick(2);
            }
          }}        
        >
          <GideImage 
            className={props.imageList.length > 0
              ? ''
              : 'missingResourceIcon'
            }
            src={props.imageList.length > 2
              ? getSlideFileUrl(props.imageList[2])
              : '/icons/content-alteration/camera/MISSING_RESOURCE_REPLACE.svg'
            }
            alt="bottom left display"
          />
        </div>
        <div className="gideImageSwipeImage4"
          onClick={() => {
            if (props.onClick) {
              props.onClick(3);
            }
          }}        
        >
          <GideImage 
            className={props.imageList.length > 0
              ? ''
              : 'missingResourceIcon'
            }
            src={props.imageList.length > 3
              ? getSlideFileUrl(props.imageList[3])
              : '/icons/content-alteration/camera/MISSING_RESOURCE_REPLACE.svg'
            }
            alt="bottom right display"
          />
        </div>
        {props.imageList.length > 4 && (
          <div className="lastImageOverlay"
            onClick={() => {
              if (props.onClick) {
                props.onClick(3);
              }
            }}           
          >
            {`+${props.imageList.length - 4} Images`}
          </div>
        )}
      </div>
    </div>
  );
}
