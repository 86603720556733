import React, { CSSProperties } from 'react';
import SlideFileCaption from '../../../Slides/SlideFileCaption/SlideFileCaption';
import ArticlePreview from '../../../Shared/Previews/ArticlePreview/ArticlePreview';
import { SlideFileReference } from '../../../../models/SlideFileReference';
import { ArticleSummary } from '../../../../models/ArticleSummary';
import { LinkPreview } from '../LinkPreview/LinkPreview';
import { ViewMode } from '../../../Gide/SlideView/SlideView';
import { User } from '../../../../models/User';
import { urlForArticle } from '../../../../utils/helperFunctions';
import { history } from '../../../../store';
import GideImage from '../../../Shared/Image/GideImage';
import { LinkSlideFile } from '../../../../models/SlideFile';
import { HyperLinkLayout, ArticlePreviewLayout, ChannelPreviewLayout } from '../../../../models/CommonEnums';
import ChannelTilePreview from '../../../Shared/Previews/ChannelTilePreview/ChannelTilePreview';
import { ItemSelectionMode } from '../../../Shared/Previews/GidePreview/GideListItemPreview/GideListItemPreview';
import { SlidePreview } from '../../../Shared/Previews/SlidePreview/SlidePreview';
import BlogSlide from '../../../Slides/BlogSlide';

export interface LinkViewProps {
  linkFile: LinkSlideFile;
  className?: string;
  isReferenceType: boolean;
  hidden?: boolean;
  style?: CSSProperties;
  currentUser: User;
  view: string;
  viewMode: ViewMode;
  overrideWidth?: string;
}

export default function LinkView(props: LinkViewProps) {
  const selectedLinkFileReferenceArticle = props.linkFile.slideFileReference
    ? ((props.linkFile.slideFileReference as SlideFileReference).article as ArticleSummary)
    : undefined;
  const referencedChannel = props.linkFile.slideFileReference && (props.linkFile.slideFileReference as SlideFileReference).referenceChannel;
  const referenceSlide = props.linkFile.slideFileReference && (props.linkFile.slideFileReference as SlideFileReference).referenceSlide;
  
  return (
    <div className={`linkViewContainer${props.hidden ? ' hidden' : ''}${props.className ? ' ' + props.className : ''}`} style={props.style}>
      {props.linkFile.type === 'URL' &&
        (props.linkFile.displayMode === HyperLinkLayout.HyperLink ||
          props.linkFile.displayMode === HyperLinkLayout.BlogImageLeft ||
          props.linkFile.displayMode === HyperLinkLayout.BlogImageTop) && (
          <LinkPreview
            url={props.linkFile.url as string}
            image={props.linkFile.image}
            title={props.linkFile.title}
            description={props.linkFile.description}
            layout={props.linkFile.displayMode}
          />
        )}
      {props.linkFile.type === 'GIDE' && selectedLinkFileReferenceArticle && (
        <ArticlePreview
          id={selectedLinkFileReferenceArticle.id}
          slug={selectedLinkFileReferenceArticle.slug as string}
          image={selectedLinkFileReferenceArticle.image as string}
          title={selectedLinkFileReferenceArticle.title}
          description={selectedLinkFileReferenceArticle.description}
          author={selectedLinkFileReferenceArticle.author}
          authorCountry={selectedLinkFileReferenceArticle.authorCountry}
          authorImage={selectedLinkFileReferenceArticle.authorImage}
          updatedAt={selectedLinkFileReferenceArticle.updatedAt}
          createdAt={selectedLinkFileReferenceArticle.createdAt}
          views={selectedLinkFileReferenceArticle.viewCount}
          layout={props.linkFile.displayMode as ArticlePreviewLayout}
          hasGeotag={selectedLinkFileReferenceArticle.hasGeotag}
          price={selectedLinkFileReferenceArticle.price}
          slideCount={selectedLinkFileReferenceArticle.numberOfSlides ? selectedLinkFileReferenceArticle.numberOfSlides : 0}
          // onClick={() => {
          //   history.push(
          //     `${urlForArticle({
          //       slug: selectedLinkFileReferenceArticle.slug as string,
          //       author: { username: selectedLinkFileReferenceArticle.author },
          //     })}`,
          //   );
          // }}
        />
      )}
      {props.linkFile.type === 'CHANNEL' && referencedChannel
        && (props.linkFile.displayMode as ChannelPreviewLayout) === ChannelPreviewLayout.Blog 
        && (        
        <BlogSlide
          channelSlug={referencedChannel.slug}
        />
      )}
      {props.linkFile.type === 'CHANNEL' && referencedChannel
        && (props.linkFile.displayMode as ChannelPreviewLayout) === ChannelPreviewLayout.Normal && (        
        <ChannelTilePreview
          image={referencedChannel.image}
          title={referencedChannel.title}
          authorImage={referencedChannel.authorImage}
          author={referencedChannel.author}
          authorCountry={referencedChannel.authorCountry}
          slug={referencedChannel.slug}
          type={referencedChannel.type}
          numberOfGides={referencedChannel.numberOfGides ? referencedChannel.numberOfGides : 0}
          numberOfMembers={referencedChannel.numberOfMembers ? referencedChannel.numberOfMembers : 0}
          selectionMode={ItemSelectionMode.SingleDisplayNone}
          linkMode='newTab'
        />
      )}
      {props.isReferenceType && props.linkFile.slideFileReference && selectedLinkFileReferenceArticle && (
        <div
          className="slideReferenceContainer"
          onClick={() => {
            const slideReference = props.linkFile.slideFileReference as SlideFileReference;
            const article = slideReference.article as ArticleSummary;
            if (slideReference.referenceSlide && article) {
              const url = `${urlForArticle({ slug: article.slug as string, author: { username: article.author } })}?slide=${
                slideReference.referenceSlide.position
              }`;
              history.push(url);
            }
          }}
        >
          <SlidePreview
            language={"en-US"}
            id={selectedLinkFileReferenceArticle.id}
            slug={selectedLinkFileReferenceArticle.slug as string}
            image={selectedLinkFileReferenceArticle.image as string}
            title={selectedLinkFileReferenceArticle.title}
            description={selectedLinkFileReferenceArticle.description}
            author={selectedLinkFileReferenceArticle.author}
            authorCountry={selectedLinkFileReferenceArticle.authorCountry}
            authorImage={selectedLinkFileReferenceArticle.authorImage}
            updatedAt={new Date(selectedLinkFileReferenceArticle.updatedAt)}
            createdAt={new Date(selectedLinkFileReferenceArticle.createdAt)}
            views={selectedLinkFileReferenceArticle.viewCount}
            slidePosition={referenceSlide?.position || 0}
            isReadOnly={true}
          />

          {/* <ArticleSlideReferenceHeader
            articleAuthorImageUrl={selectedLinkFileReferenceArticle.authorImage}
            articleAuthor={selectedLinkFileReferenceArticle.author}
            articleTitle={selectedLinkFileReferenceArticle.title}
            articleImage={selectedLinkFileReferenceArticle.image}
            createdAt={selectedLinkFileReferenceArticle.createdAt}
            numberOfSlides={selectedLinkFileReferenceArticle.numberOfSlides}
          />
          {(props.linkFile.slideFileReference as SlideFileReference) &&
            (props.linkFile.slideFileReference as SlideFileReference).referenceFile && (
              <LinkView
                linkFile={{
                  ...((props.linkFile.slideFileReference as SlideFileReference).referenceFile as LinkSlideFile),
                  onSelect: props.linkFile.onSelect,
                }}
                view={props.view}
                viewMode={props.viewMode}
                overrideWidth={props.overrideWidth}
                currentUser={props.currentUser}
                isReferenceType={false}
              />
            )}
          {(props.linkFile.slideFileReference as SlideFileReference) &&
            (props.linkFile.slideFileReference as SlideFileReference).referenceSlide && (
              <SlideView
                slide={(props.linkFile.slideFileReference as SlideFileReference).referenceSlide as Slide}
                view={props.view}
                overrideWidth={props.overrideWidth}
                slidePosition={1}
                viewMode={props.viewMode}
                currentUser={props.currentUser}
              />
            )} */}
        </div>
      )}
      {props.isReferenceType && !props.linkFile.slideFileReference && (
        <div className="noLongerAvailable">
          <GideImage src="/icons/delete-when-replaced/content-unavailable.png" alt="content is no longer available" />
        </div>
      )}
      <SlideFileCaption slideType="Link" slideFile={props.linkFile} />
    </div>
  );
}
