import React, { useState, CSSProperties } from 'react'
import classnames from 'classnames'
import styles from './_index.module.scss';
import {ReactComponent as Avatar_AvatarLg } from './avatar/avatar-lg.svg';
import {ReactComponent as Avatar_AvatarMdDeactive } from './avatar/avatar-md-deactive.svg';
import {ReactComponent as Avatar_AvatarMd } from './avatar/avatar-md.svg';
import {ReactComponent as Avatar_AvatarSm } from './avatar/avatar-sm.svg';
import {ReactComponent as Avatar_Verifyed } from './avatar/verifyed.svg';
import {ReactComponent as ContentAlteration_Atsymbol } from './content-alteration/@.svg';
import {ReactComponent as ContentAlteration_Additions } from './content-alteration/additions.svg';
import {ReactComponent as ContentAlteration_Attach } from './content-alteration/attach.svg';
import {ReactComponent as ContentAlteration_Comments_Active } from './content-alteration/comments/active.svg';
import {ReactComponent as ContentAlteration_Comments_Deactive } from './content-alteration/comments/deactive.svg';
import {ReactComponent as ContentAlteration_Copy } from './content-alteration/copy.svg';
import {ReactComponent as ContentAlteration_Delete } from './content-alteration/delete.svg';
import {ReactComponent as ContentAlteration_Download } from './content-alteration/download.svg';
import {ReactComponent as ContentAlteration_EditSlide } from './content-alteration/edit-slide.svg';
import {ReactComponent as ContentAlteration_Emoji } from './content-alteration/emoji.svg';
import {ReactComponent as ContentAlteration_Follower } from './content-alteration/follower.svg';
import {ReactComponent as ContentAlteration_Followers } from './content-alteration/followers.svg';
import {ReactComponent as ContentAlteration_Hashtag } from './content-alteration/hashtag.svg';
import {ReactComponent as ContentAlteration_Headders } from './content-alteration/headders.svg';
import {ReactComponent as ContentAlteration_List } from './content-alteration/list.svg';
import {ReactComponent as ContentAlteration_Newgide } from './content-alteration/newgide.svg';
import {ReactComponent as ContentAlteration_Notes } from './content-alteration/notes.svg';
import {ReactComponent as ContentAlteration_Questions } from './content-alteration/questions.svg';
import {ReactComponent as ContentAlteration_Rate_Active } from './content-alteration/rate/active.svg';
import {ReactComponent as ContentAlteration_Rate_Deactive } from './content-alteration/rate/deactive.svg';
import {ReactComponent as ContentAlteration_Redo } from './content-alteration/redo.svg';
import {ReactComponent as ContentAlteration_Reply } from './content-alteration/reply.svg';
import {ReactComponent as ContentAlteration_Share } from './content-alteration/share.svg';
import {ReactComponent as ContentAlteration_SlideAdditions } from './content-alteration/slide-additions.svg';
import {ReactComponent as ContentAlteration_Textblock } from './content-alteration/textblock.svg';
import {ReactComponent as ContentAlteration_Transfer } from './content-alteration/transfer.svg';
import {ReactComponent as ContentAlteration_MoveTo } from './content-alteration/move-to.svg';
import {ReactComponent as ContentAlteration_Trash } from "./content-alteration/trash.svg";
import {ReactComponent as ContentAlteration_Undo } from './content-alteration/undo.svg';
import {ReactComponent as ContentAlteration_Upload } from './content-alteration/upload.svg';
import {ReactComponent as ContentAlteration_Camera_Alt } from './content-alteration/camera/alt.svg';
import {ReactComponent as ContentAlteration_Camera_Default } from './content-alteration/camera/default.svg';
import {ReactComponent as ContentAlteration_Camera_Flip } from './content-alteration/camera/flip.svg';
import {ReactComponent as ContentAlteration_Check_Alternative } from './content-alteration/check/alternative.svg';
import {ReactComponent as ContentAlteration_Check_Main } from './content-alteration/check/main.svg';
import {ReactComponent as ContentAlteration_Checkbox_CheckboxActive } from './content-alteration/checkbox/checkbox-active.svg';
import {ReactComponent as ContentAlteration_Checkbox_CheckboxDeactive } from './content-alteration/checkbox/checkbox-deactive.svg';
import {ReactComponent as ContentAlteration_Checkbox_Uncheck } from './content-alteration/checkbox/uncheck.svg';
import {ReactComponent as ContentAlteration_InputBar_Checkmark_1 } from './content-alteration/input-bar/checkmark/1.svg';
import {ReactComponent as ContentAlteration_InputBar_Checkmark_999 } from './content-alteration/input-bar/checkmark/999.svg';
import {ReactComponent as ContentAlteration_InputBar_Checkmark_Basic } from './content-alteration/input-bar/checkmark/basic.svg';
import {ReactComponent as ContentAlteration_InputBar_Checkmark_Edit } from './content-alteration/input-bar/checkmark/edit.svg';
import {ReactComponent as ContentAlteration_InputBar_Checkmark_Outline } from './content-alteration/input-bar/checkmark/outline.svg';
import {ReactComponent as ContentAlteration_InputBar_Slide_1 } from './content-alteration/input-bar/slide/1.svg';
import {ReactComponent as ContentAlteration_InputBar_Slide_999 } from './content-alteration/input-bar/slide/999.svg';
import {ReactComponent as ContentAlteration_InputBar_Slide_9999 } from './content-alteration/input-bar/slide/9999.svg';
import {ReactComponent as ContentAlteration_InputBar_Slide_Basic } from './content-alteration/input-bar/slide/basic.svg';
import {ReactComponent as ContentAlteration_InputBar_Slide_Finalise } from './content-alteration/input-bar/slide/finalise.svg';
import {ReactComponent as ContentAlteration_InputBar_Slide_1_Alt } from './content-alteration/input-bar/slide/1/alt.svg';
import {ReactComponent as ContentAlteration_Like_Active } from './content-alteration/like/active.svg';
import {ReactComponent as ContentAlteration_Like_DeActive } from './content-alteration/like/de-active.svg';
import {ReactComponent as ContentAlteration_Radio_Active } from './content-alteration/radio/active.svg';
import {ReactComponent as ContentAlteration_Radio_Deactive } from './content-alteration/radio/deactive.svg';
import {ReactComponent as ContentAlteration_Save_Active } from './content-alteration/save/active.svg';
import {ReactComponent as ContentAlteration_Save_Deactive } from './content-alteration/save/deactive.svg';
import {ReactComponent as ContentAlteration_TextAlign_Center } from './content-alteration/text-align/center.svg';
import {ReactComponent as ContentAlteration_TextAlign_Left } from './content-alteration/text-align/left.svg';
import {ReactComponent as ContentAlteration_TextAlign_Right } from './content-alteration/text-align/right.svg';
import {ReactComponent as CreationProcess_Citing } from './creationprocess/citing.svg';
import {ReactComponent as CreationProcess_EditText } from './creationprocess/edit-text.svg';
import {ReactComponent as CreationProcess_Events } from './creationprocess/events.svg';
import {ReactComponent as CreationProcess_Preview } from './creationprocess/preview.svg';
import {ReactComponent as CreationProcess_Slide } from './creationprocess/slide.svg';
import {ReactComponent as CreationProcess_Stickers } from './creationprocess/stickers.svg';
import {ReactComponent as CreationProcess_TextBackground } from './creationprocess/text-background.svg';
import {ReactComponent as CreationProcess_TextOnly } from './creationprocess/text-only.svg';
import {ReactComponent as CreationProcess_Tile } from './creationprocess/tile.svg';
import {ReactComponent as CreationProcess_TouchActivate } from './creationprocess/touch-activate.svg';
import {ReactComponent as CreationProcess_Trim } from './creationprocess/trim.svg';
import {ReactComponent as CreationProcess_Advance_Advance10S } from './creationprocess/advance/advance-10s.svg';
import {ReactComponent as CreationProcess_Advance_AutoAdvance } from './creationprocess/advance/auto-advance.svg';
import {ReactComponent as CreationProcess_Advance_AutoPlay } from './creationprocess/advance/auto-play.svg';
import {ReactComponent as CreationProcess_Advance_Rewind10S } from './creationprocess/advance/rewind-10s.svg';
import {ReactComponent as CreationProcess_Bloglist_ImageLeft } from './creationprocess/bloglist/image-left.svg';
import {ReactComponent as CreationProcess_Bloglist_ImageOnly } from './creationprocess/bloglist/image-only.svg';
import {ReactComponent as CreationProcess_Bloglist_ImageTop } from './creationprocess/bloglist/image-top.svg';
import {ReactComponent as CreationProcess_Bloglist_Nothing } from './creationprocess/bloglist/nothing.svg';
import {ReactComponent as CreationProcess_Bloglist_TextOnly } from './creationprocess/bloglist/text-only.svg';
import {ReactComponent as CreationProcess_Cropandrotate_Crop } from './creationprocess/cropandrotate/crop.svg';
import {ReactComponent as CreationProcess_Cropandrotate_Cropandrotate } from './creationprocess/cropandrotate/cropandrotate.svg';
import {ReactComponent as CreationProcess_Cropandrotate_Rotate } from './creationprocess/cropandrotate/rotate.svg';
import {ReactComponent as CreationProcess_Display_Display } from './creationprocess/display/display.svg';
import {ReactComponent as CreationProcess_DisplaySettings_Carocell } from './creationprocess/display-settings/carocell.svg';
import {ReactComponent as CreationProcess_DisplaySettings_Frame } from './creationprocess/display-settings/frame.svg';
import {ReactComponent as CreationProcess_DisplaySettings_SquareFrame } from './creationprocess/display-settings/square-frame.svg';
import {ReactComponent as CreationProcess_DisplaySettings_Stacked } from './creationprocess/display-settings/stacked.svg';
import {ReactComponent as CreationProcess_DisplaySettings_Swipe } from './creationprocess/display-settings/swipe.svg';
import {ReactComponent as CreationProcess_Justification_JustificationBottom } from './creationprocess/justification/justification-bottom.svg';
import {ReactComponent as CreationProcess_Justification_JustificationCenter } from './creationprocess/justification/justification-center.svg';
import {ReactComponent as CreationProcess_Justification_JustificationLeft } from './creationprocess/justification/justification-left.svg';
import {ReactComponent as CreationProcess_Justification_JustificationRight } from './creationprocess/justification/justification-right.svg';
import {ReactComponent as CreationProcess_Justification_JustificationTop } from './creationprocess/justification/justification-top.svg';
// import {ReactComponent as Flags_BlackFlag } from './flags/black-flag.svg';
// import {ReactComponent as Flags_ChequeredFlag } from './flags/chequered-flag.svg';
// import {ReactComponent as Flags_CrossedFlags } from './flags/crossed-flags.svg';
// import {ReactComponent as Flags_FlagAfghanistan } from './flags/flag-afghanistan.svg';
// import {ReactComponent as Flags_FlagAlandIslands } from './flags/flag-aland-islands.svg';
// import {ReactComponent as Flags_FlagAlbania } from './flags/flag-albania.svg';
// import {ReactComponent as Flags_FlagAlgeria } from './flags/flag-algeria.svg';
// import {ReactComponent as Flags_FlagAmericanSamoa } from './flags/flag-american-samoa.svg';
// import {ReactComponent as Flags_FlagAndorra } from './flags/flag-andorra.svg';
// import {ReactComponent as Flags_FlagAngola } from './flags/flag-angola.svg';
// import {ReactComponent as Flags_FlagAnguilla } from './flags/flag-anguilla.svg';
// import {ReactComponent as Flags_FlagAntarctica } from './flags/flag-antarctica.svg';
// import {ReactComponent as Flags_FlagAntiguaAmpBarbuda } from './flags/flag-antigua-amp-barbuda.svg';
// import {ReactComponent as Flags_FlagArgentina } from './flags/flag-argentina.svg';
// import {ReactComponent as Flags_FlagArmenia } from './flags/flag-armenia.svg';
// import {ReactComponent as Flags_FlagAruba } from './flags/flag-aruba.svg';
// import {ReactComponent as Flags_FlagAscensionIsland } from './flags/flag-ascension-island.svg';
// import {ReactComponent as Flags_FlagAustralia } from './flags/flag-australia.svg';
// import {ReactComponent as Flags_FlagAustria } from './flags/flag-austria.svg';
// import {ReactComponent as Flags_FlagAzerbaijan } from './flags/flag-azerbaijan.svg';
// import {ReactComponent as Flags_FlagBahamas } from './flags/flag-bahamas.svg';
// import {ReactComponent as Flags_FlagBahrain } from './flags/flag-bahrain.svg';
// import {ReactComponent as Flags_FlagBangladesh } from './flags/flag-bangladesh.svg';
// import {ReactComponent as Flags_FlagBarbados } from './flags/flag-barbados.svg';
// import {ReactComponent as Flags_FlagBelarus } from './flags/flag-belarus.svg';
// import {ReactComponent as Flags_FlagBelgium } from './flags/flag-belgium.svg';
// import {ReactComponent as Flags_FlagBelize } from './flags/flag-belize.svg';
// import {ReactComponent as Flags_FlagBenin } from './flags/flag-benin.svg';
// import {ReactComponent as Flags_FlagBermuda } from './flags/flag-bermuda.svg';
// import {ReactComponent as Flags_FlagBhutan } from './flags/flag-bhutan.svg';
// import {ReactComponent as Flags_FlagBolivia } from './flags/flag-bolivia.svg';
// import {ReactComponent as Flags_FlagBosniaAmpHerzegovina } from './flags/flag-bosnia-amp-herzegovina.svg';
// import {ReactComponent as Flags_FlagBotswana } from './flags/flag-botswana.svg';
// import {ReactComponent as Flags_FlagBouvetIsland } from './flags/flag-bouvet-island.svg';
// import {ReactComponent as Flags_FlagBrazil } from './flags/flag-brazil.svg';
// import {ReactComponent as Flags_FlagBritishIndianOceanTerritory } from './flags/flag-british-indian-ocean-territory.svg';
// import {ReactComponent as Flags_FlagBritishVirginIslands } from './flags/flag-british-virgin-islands.svg';
// import {ReactComponent as Flags_FlagBrunei } from './flags/flag-brunei.svg';
// import {ReactComponent as Flags_FlagBulgaria } from './flags/flag-bulgaria.svg';
// import {ReactComponent as Flags_FlagBurkinaFaso } from './flags/flag-burkina-faso.svg';
// import {ReactComponent as Flags_FlagBurundi } from './flags/flag-burundi.svg';
// import {ReactComponent as Flags_FlagCambodia } from './flags/flag-cambodia.svg';
// import {ReactComponent as Flags_FlagCameroon } from './flags/flag-cameroon.svg';
// import {ReactComponent as Flags_FlagCanada } from './flags/flag-canada.svg';
// import {ReactComponent as Flags_FlagCanaryIslands } from './flags/flag-canary-islands.svg';
// import {ReactComponent as Flags_FlagCapeVerde } from './flags/flag-cape-verde.svg';
// import {ReactComponent as Flags_FlagCaribbeanNetherlands } from './flags/flag-caribbean-netherlands.svg';
// import {ReactComponent as Flags_FlagCaymanIslands } from './flags/flag-cayman-islands.svg';
// import {ReactComponent as Flags_FlagCentralAfricanRepublic } from './flags/flag-central-african-republic.svg';
// import {ReactComponent as Flags_FlagCeutaAmpMelilla } from './flags/flag-ceuta-amp-melilla.svg';
// import {ReactComponent as Flags_FlagChad } from './flags/flag-chad.svg';
// import {ReactComponent as Flags_FlagChile } from './flags/flag-chile.svg';
// import {ReactComponent as Flags_FlagChina } from './flags/flag-china.svg';
// import {ReactComponent as Flags_FlagChristmasIsland } from './flags/flag-christmas-island.svg';
// import {ReactComponent as Flags_FlagClippertonIsland } from './flags/flag-clipperton-island.svg';
// import {ReactComponent as Flags_FlagCocosKeelingIslands } from './flags/flag-cocos-keeling-islands.svg';
// import {ReactComponent as Flags_FlagColombia } from './flags/flag-colombia.svg';
// import {ReactComponent as Flags_FlagComoros } from './flags/flag-comoros.svg';
// import {ReactComponent as Flags_FlagCongoBrazzaville } from './flags/flag-congo-brazzaville.svg';
// import {ReactComponent as Flags_FlagCongoKinshasa } from './flags/flag-congo-kinshasa.svg';
// import {ReactComponent as Flags_FlagCookIslands } from './flags/flag-cook-islands.svg';
// import {ReactComponent as Flags_FlagCostaRica } from './flags/flag-costa-rica.svg';
// import {ReactComponent as Flags_FlagCoteDivoire } from './flags/flag-cote-divoire.svg';
// import {ReactComponent as Flags_FlagCroatia } from './flags/flag-croatia.svg';
// import {ReactComponent as Flags_FlagCuba } from './flags/flag-cuba.svg';
// import {ReactComponent as Flags_FlagCuracao } from './flags/flag-curacao.svg';
// import {ReactComponent as Flags_FlagCyprus } from './flags/flag-cyprus.svg';
// import {ReactComponent as Flags_FlagCzechia } from './flags/flag-czechia.svg';
// import {ReactComponent as Flags_FlagDenmark } from './flags/flag-denmark.svg';
// import {ReactComponent as Flags_FlagDiegoGarcia } from './flags/flag-diego-garcia.svg';
// import {ReactComponent as Flags_FlagDjibouti } from './flags/flag-djibouti.svg';
// import {ReactComponent as Flags_FlagDominica } from './flags/flag-dominica.svg';
// import {ReactComponent as Flags_FlagDominicanRepublic } from './flags/flag-dominican-republic.svg';
// import {ReactComponent as Flags_FlagEcuador } from './flags/flag-ecuador.svg';
// import {ReactComponent as Flags_FlagEgypt } from './flags/flag-egypt.svg';
// import {ReactComponent as Flags_FlagElSalvador } from './flags/flag-el-salvador.svg';
// import {ReactComponent as Flags_FlagEngland } from './flags/flag-england.svg';
// import {ReactComponent as Flags_FlagEquatorialGuinea } from './flags/flag-equatorial-guinea.svg';
// import {ReactComponent as Flags_FlagEritrea } from './flags/flag-eritrea.svg';
// import {ReactComponent as Flags_FlagEstonia } from './flags/flag-estonia.svg';
// import {ReactComponent as Flags_FlagEswatini } from './flags/flag-eswatini.svg';
// import {ReactComponent as Flags_FlagEthiopia } from './flags/flag-ethiopia.svg';
// import {ReactComponent as Flags_FlagEuropeanUnion } from './flags/flag-european-union.svg';
// import {ReactComponent as Flags_FlagFalklandIslands } from './flags/flag-falkland-islands.svg';
// import {ReactComponent as Flags_FlagFaroeIslands } from './flags/flag-faroe-islands.svg';
// import {ReactComponent as Flags_FlagFiji } from './flags/flag-fiji.svg';
// import {ReactComponent as Flags_FlagFinland } from './flags/flag-finland.svg';
// import {ReactComponent as Flags_FlagFrance } from './flags/flag-france.svg';
// import {ReactComponent as Flags_FlagFrenchGuiana } from './flags/flag-french-guiana.svg';
// import {ReactComponent as Flags_FlagFrenchPolynesia } from './flags/flag-french-polynesia.svg';
// import {ReactComponent as Flags_FlagFrenchSouthernTerritories } from './flags/flag-french-southern-territories.svg';
// import {ReactComponent as Flags_FlagGabon } from './flags/flag-gabon.svg';
// import {ReactComponent as Flags_FlagGambia } from './flags/flag-gambia.svg';
// import {ReactComponent as Flags_FlagGeorgia } from './flags/flag-georgia.svg';
// import {ReactComponent as Flags_FlagGermany } from './flags/flag-germany.svg';
// import {ReactComponent as Flags_FlagGhana } from './flags/flag-ghana.svg';
// import {ReactComponent as Flags_FlagGibraltar } from './flags/flag-gibraltar.svg';
// import {ReactComponent as Flags_FlagGreece } from './flags/flag-greece.svg';
// import {ReactComponent as Flags_FlagGreenland } from './flags/flag-greenland.svg';
// import {ReactComponent as Flags_FlagGrenada } from './flags/flag-grenada.svg';
// import {ReactComponent as Flags_FlagGuadeloupe } from './flags/flag-guadeloupe.svg';
// import {ReactComponent as Flags_FlagGuam } from './flags/flag-guam.svg';
// import {ReactComponent as Flags_FlagGuatemala } from './flags/flag-guatemala.svg';
// import {ReactComponent as Flags_FlagGuernsey } from './flags/flag-guernsey.svg';
// import {ReactComponent as Flags_FlagGuineaBissau } from './flags/flag-guinea-bissau.svg';
// import {ReactComponent as Flags_FlagGuinea } from './flags/flag-guinea.svg';
// import {ReactComponent as Flags_FlagGuyana } from './flags/flag-guyana.svg';
// import {ReactComponent as Flags_FlagHaiti } from './flags/flag-haiti.svg';
// import {ReactComponent as Flags_FlagHeardAmpMcdonaldIslands } from './flags/flag-heard-amp-mcdonald-islands.svg';
// import {ReactComponent as Flags_FlagHonduras } from './flags/flag-honduras.svg';
// import {ReactComponent as Flags_FlagHongKongSarChina } from './flags/flag-hong-kong-sar-china.svg';
// import {ReactComponent as Flags_FlagHungary } from './flags/flag-hungary.svg';
// import {ReactComponent as Flags_FlagIceland } from './flags/flag-iceland.svg';
// import {ReactComponent as Flags_FlagIndia } from './flags/flag-india.svg';
// import {ReactComponent as Flags_FlagIndonesia } from './flags/flag-indonesia.svg';
// import {ReactComponent as Flags_FlagIran } from './flags/flag-iran.svg';
// import {ReactComponent as Flags_FlagIraq } from './flags/flag-iraq.svg';
// import {ReactComponent as Flags_FlagIreland } from './flags/flag-ireland.svg';
// import {ReactComponent as Flags_FlagIsleOfMan } from './flags/flag-isle-of-man.svg';
// import {ReactComponent as Flags_FlagIsrael } from './flags/flag-israel.svg';
// import {ReactComponent as Flags_FlagItaly } from './flags/flag-italy.svg';
// import {ReactComponent as Flags_FlagJamaica } from './flags/flag-jamaica.svg';
// import {ReactComponent as Flags_FlagJapan } from './flags/flag-japan.svg';
// import {ReactComponent as Flags_FlagJersey } from './flags/flag-jersey.svg';
// import {ReactComponent as Flags_FlagJordan } from './flags/flag-jordan.svg';
// import {ReactComponent as Flags_FlagKazakhstan } from './flags/flag-kazakhstan.svg';
// import {ReactComponent as Flags_FlagKenya } from './flags/flag-kenya.svg';
// import {ReactComponent as Flags_FlagKiribati } from './flags/flag-kiribati.svg';
// import {ReactComponent as Flags_FlagKosovo } from './flags/flag-kosovo.svg';
// import {ReactComponent as Flags_FlagKuwait } from './flags/flag-kuwait.svg';
// import {ReactComponent as Flags_FlagKyrgyzstan } from './flags/flag-kyrgyzstan.svg';
// import {ReactComponent as Flags_FlagLaos } from './flags/flag-laos.svg';
// import {ReactComponent as Flags_FlagLatvia } from './flags/flag-latvia.svg';
// import {ReactComponent as Flags_FlagLebanon } from './flags/flag-lebanon.svg';
// import {ReactComponent as Flags_FlagLesotho } from './flags/flag-lesotho.svg';
// import {ReactComponent as Flags_FlagLiberia } from './flags/flag-liberia.svg';
// import {ReactComponent as Flags_FlagLibya } from './flags/flag-libya.svg';
// import {ReactComponent as Flags_FlagLiechtenstein } from './flags/flag-liechtenstein.svg';
// import {ReactComponent as Flags_FlagLithuania } from './flags/flag-lithuania.svg';
// import {ReactComponent as Flags_FlagLuxembourg } from './flags/flag-luxembourg.svg';
// import {ReactComponent as Flags_FlagMacaoSarChina } from './flags/flag-macao-sar-china.svg';
// import {ReactComponent as Flags_FlagMacedonia } from './flags/flag-macedonia.svg';
// import {ReactComponent as Flags_FlagMadagascar } from './flags/flag-madagascar.svg';
// import {ReactComponent as Flags_FlagMalawi } from './flags/flag-malawi.svg';
// import {ReactComponent as Flags_FlagMalaysia } from './flags/flag-malaysia.svg';
// import {ReactComponent as Flags_FlagMaldives } from './flags/flag-maldives.svg';
// import {ReactComponent as Flags_FlagMali } from './flags/flag-mali.svg';
// import {ReactComponent as Flags_FlagMalta } from './flags/flag-malta.svg';
// import {ReactComponent as Flags_FlagMarshallIslands } from './flags/flag-marshall-islands.svg';
// import {ReactComponent as Flags_FlagMartinique } from './flags/flag-martinique.svg';
// import {ReactComponent as Flags_FlagMauritania } from './flags/flag-mauritania.svg';
// import {ReactComponent as Flags_FlagMauritius } from './flags/flag-mauritius.svg';
// import {ReactComponent as Flags_FlagMayotte } from './flags/flag-mayotte.svg';
// import {ReactComponent as Flags_FlagMexico } from './flags/flag-mexico.svg';
// import {ReactComponent as Flags_FlagMicronesia } from './flags/flag-micronesia.svg';
// import {ReactComponent as Flags_FlagMoldova } from './flags/flag-moldova.svg';
// import {ReactComponent as Flags_FlagMonaco } from './flags/flag-monaco.svg';
// import {ReactComponent as Flags_FlagMongolia } from './flags/flag-mongolia.svg';
// import {ReactComponent as Flags_FlagMontenegro } from './flags/flag-montenegro.svg';
// import {ReactComponent as Flags_FlagMontserrat } from './flags/flag-montserrat.svg';
// import {ReactComponent as Flags_FlagMorocco } from './flags/flag-morocco.svg';
// import {ReactComponent as Flags_FlagMozambique } from './flags/flag-mozambique.svg';
// import {ReactComponent as Flags_FlagMyanmarBurma } from './flags/flag-myanmar-burma.svg';
// import {ReactComponent as Flags_FlagNamibia } from './flags/flag-namibia.svg';
// import {ReactComponent as Flags_FlagNauru } from './flags/flag-nauru.svg';
// import {ReactComponent as Flags_FlagNepal } from './flags/flag-nepal.svg';
// import {ReactComponent as Flags_FlagNetherlands } from './flags/flag-netherlands.svg';
// import {ReactComponent as Flags_FlagNewCaledonia } from './flags/flag-new-caledonia.svg';
// import {ReactComponent as Flags_FlagNewZealand } from './flags/flag-new-zealand.svg';
// import {ReactComponent as Flags_FlagNicaragua } from './flags/flag-nicaragua.svg';
// import {ReactComponent as Flags_FlagNiger } from './flags/flag-niger.svg';
// import {ReactComponent as Flags_FlagNigeria } from './flags/flag-nigeria.svg';
// import {ReactComponent as Flags_FlagNiue } from './flags/flag-niue.svg';
// import {ReactComponent as Flags_FlagNorfolkIsland } from './flags/flag-norfolk-island.svg';
// import {ReactComponent as Flags_FlagNorthKorea } from './flags/flag-north-korea.svg';
// import {ReactComponent as Flags_FlagNorthernMarianaIslands } from './flags/flag-northern-mariana-islands.svg';
// import {ReactComponent as Flags_FlagNorway } from './flags/flag-norway.svg';
// import {ReactComponent as Flags_FlagOman } from './flags/flag-oman.svg';
// import {ReactComponent as Flags_FlagPakistan } from './flags/flag-pakistan.svg';
// import {ReactComponent as Flags_FlagPalau } from './flags/flag-palau.svg';
// import {ReactComponent as Flags_FlagPalestinianTerritories } from './flags/flag-palestinian-territories.svg';
// import {ReactComponent as Flags_FlagPanama } from './flags/flag-panama.svg';
// import {ReactComponent as Flags_FlagPapuaNewGuinea } from './flags/flag-papua-new-guinea.svg';
// import {ReactComponent as Flags_FlagParaguay } from './flags/flag-paraguay.svg';
// import {ReactComponent as Flags_FlagPeru } from './flags/flag-peru.svg';
// import {ReactComponent as Flags_FlagPhilippines } from './flags/flag-philippines.svg';
// import {ReactComponent as Flags_FlagPitcairnIslands } from './flags/flag-pitcairn-islands.svg';
// import {ReactComponent as Flags_FlagPoland } from './flags/flag-poland.svg';
// import {ReactComponent as Flags_FlagPortugal } from './flags/flag-portugal.svg';
// import {ReactComponent as Flags_FlagPuertoRico } from './flags/flag-puerto-rico.svg';
// import {ReactComponent as Flags_FlagQatar } from './flags/flag-qatar.svg';
// import {ReactComponent as Flags_FlagReunion } from './flags/flag-reunion.svg';
// import {ReactComponent as Flags_FlagRomania } from './flags/flag-romania.svg';
// import {ReactComponent as Flags_FlagRussia } from './flags/flag-russia.svg';
// import {ReactComponent as Flags_FlagRwanda } from './flags/flag-rwanda.svg';
// import {ReactComponent as Flags_FlagSamoa } from './flags/flag-samoa.svg';
// import {ReactComponent as Flags_FlagSanMarino } from './flags/flag-san-marino.svg';
// import {ReactComponent as Flags_FlagSaoTomeAmpPrincipe } from './flags/flag-sao-tome-amp-principe.svg';
// import {ReactComponent as Flags_FlagSaudiArabia } from './flags/flag-saudi-arabia.svg';
// import {ReactComponent as Flags_FlagScotland } from './flags/flag-scotland.svg';
// import {ReactComponent as Flags_FlagSenegal } from './flags/flag-senegal.svg';
// import {ReactComponent as Flags_FlagSerbia } from './flags/flag-serbia.svg';
// import {ReactComponent as Flags_FlagSeychelles } from './flags/flag-seychelles.svg';
// import {ReactComponent as Flags_FlagSierraLeone } from './flags/flag-sierra-leone.svg';
// import {ReactComponent as Flags_FlagSingapore } from './flags/flag-singapore.svg';
// import {ReactComponent as Flags_FlagSintMaarten } from './flags/flag-sint-maarten.svg';
// import {ReactComponent as Flags_FlagSlovakia } from './flags/flag-slovakia.svg';
// import {ReactComponent as Flags_FlagSlovenia } from './flags/flag-slovenia.svg';
// import {ReactComponent as Flags_FlagSolomonIslands } from './flags/flag-solomon-islands.svg';
// import {ReactComponent as Flags_FlagSomalia } from './flags/flag-somalia.svg';
// import {ReactComponent as Flags_FlagSouthAfrica } from './flags/flag-south-africa.svg';
// import {ReactComponent as Flags_FlagSouthGeorgiaAmpSouthSandwichIslands } from './flags/flag-south-georgia-amp-south-sandwich-islands.svg';
// import {ReactComponent as Flags_FlagSouthKorea } from './flags/flag-south-korea.svg';
// import {ReactComponent as Flags_FlagSouthSudan } from './flags/flag-south-sudan.svg';
// import {ReactComponent as Flags_FlagSpain } from './flags/flag-spain.svg';
// import {ReactComponent as Flags_FlagSriLanka } from './flags/flag-sri-lanka.svg';
// import {ReactComponent as Flags_FlagStBarthelemy } from './flags/flag-st-barthelemy.svg';
// import {ReactComponent as Flags_FlagStHelena } from './flags/flag-st-helena.svg';
// import {ReactComponent as Flags_FlagStKittsAmpNevis } from './flags/flag-st-kitts-amp-nevis.svg';
// import {ReactComponent as Flags_FlagStLucia } from './flags/flag-st-lucia.svg';
// import {ReactComponent as Flags_FlagStMartin } from './flags/flag-st-martin.svg';
// import {ReactComponent as Flags_FlagStPierreAmpMiquelon } from './flags/flag-st-pierre-amp-miquelon.svg';
// import {ReactComponent as Flags_FlagStVincentAmpGrenadines } from './flags/flag-st-vincent-amp-grenadines.svg';
// import {ReactComponent as Flags_FlagSudan } from './flags/flag-sudan.svg';
// import {ReactComponent as Flags_FlagSuriname } from './flags/flag-suriname.svg';
// import {ReactComponent as Flags_FlagSvalbardAmpJanMayen } from './flags/flag-svalbard-amp-jan-mayen.svg';
// import {ReactComponent as Flags_FlagSweden } from './flags/flag-sweden.svg';
// import {ReactComponent as Flags_FlagSwitzerland } from './flags/flag-switzerland.svg';
// import {ReactComponent as Flags_FlagSyria } from './flags/flag-syria.svg';
// import {ReactComponent as Flags_FlagTaiwan } from './flags/flag-taiwan.svg';
// import {ReactComponent as Flags_FlagTajikistan } from './flags/flag-tajikistan.svg';
// import {ReactComponent as Flags_FlagTanzania } from './flags/flag-tanzania.svg';
// import {ReactComponent as Flags_FlagThailand } from './flags/flag-thailand.svg';
// import {ReactComponent as Flags_FlagTimorLeste } from './flags/flag-timor-leste.svg';
// import {ReactComponent as Flags_FlagTogo } from './flags/flag-togo.svg';
// import {ReactComponent as Flags_FlagTokelau } from './flags/flag-tokelau.svg';
// import {ReactComponent as Flags_FlagTonga } from './flags/flag-tonga.svg';
// import {ReactComponent as Flags_FlagTrinidadAmpTobago } from './flags/flag-trinidad-amp-tobago.svg';
// import {ReactComponent as Flags_FlagTristanDaCunha } from './flags/flag-tristan-da-cunha.svg';
// import {ReactComponent as Flags_FlagTunisia } from './flags/flag-tunisia.svg';
// import {ReactComponent as Flags_FlagTurkey } from './flags/flag-turkey.svg';
// import {ReactComponent as Flags_FlagTurkmenistan } from './flags/flag-turkmenistan.svg';
// import {ReactComponent as Flags_FlagTurksAmpCaicosIslands } from './flags/flag-turks-amp-caicos-islands.svg';
// import {ReactComponent as Flags_FlagTuvalu } from './flags/flag-tuvalu.svg';
// import {ReactComponent as Flags_FlagUganda } from './flags/flag-uganda.svg';
// import {ReactComponent as Flags_FlagUkraine } from './flags/flag-ukraine.svg';
// import {ReactComponent as Flags_FlagUnitedArabEmirates } from './flags/flag-united-arab-emirates.svg';
// import {ReactComponent as Flags_FlagUnitedKingdom } from './flags/flag-united-kingdom.svg';
// import {ReactComponent as Flags_FlagUnitedNations } from './flags/flag-united-nations.svg';
// import {ReactComponent as Flags_FlagUnitedStates } from './flags/flag-united-states.svg';
// import {ReactComponent as Flags_FlagUruguay } from './flags/flag-uruguay.svg';
// import {ReactComponent as Flags_FlagUsOutlyingIslands } from './flags/flag-us-outlying-islands.svg';
// import {ReactComponent as Flags_FlagUsVirginIslands } from './flags/flag-us-virgin-islands.svg';
// import {ReactComponent as Flags_FlagUzbekistan } from './flags/flag-uzbekistan.svg';
// import {ReactComponent as Flags_FlagVanuatu } from './flags/flag-vanuatu.svg';
// import {ReactComponent as Flags_FlagVaticanCity } from './flags/flag-vatican-city.svg';
// import {ReactComponent as Flags_FlagVenezuela } from './flags/flag-venezuela.svg';
// import {ReactComponent as Flags_FlagVietnam } from './flags/flag-vietnam.svg';
// import {ReactComponent as Flags_FlagWales } from './flags/flag-wales.svg';
// import {ReactComponent as Flags_FlagWallisAmpFutuna } from './flags/flag-wallis-amp-futuna.svg';
// import {ReactComponent as Flags_FlagWesternSahara } from './flags/flag-western-sahara.svg';
// import {ReactComponent as Flags_FlagYemen } from './flags/flag-yemen.svg';
// import {ReactComponent as Flags_FlagZambia } from './flags/flag-zambia.svg';
// import {ReactComponent as Flags_FlagZimbabwe } from './flags/flag-zimbabwe.svg';
// import {ReactComponent as Flags_PirateFlag } from './flags/pirate-flag.svg';
// import {ReactComponent as Flags_RainbowFlag } from './flags/rainbow-flag.svg';
// import {ReactComponent as Flags_TriangularFlag } from './flags/triangular-flag.svg';
// import {ReactComponent as Flags_WhiteFlag } from './flags/white-flag.svg';
import {ReactComponent as Nav_Channels } from './nav/channels.svg';
import {ReactComponent as Nav_Chat } from './nav/chat.svg';
import {ReactComponent as Nav_Collections } from './nav/collections.svg';
import {ReactComponent as Nav_Darkmode } from './nav/darkmode.svg';
import {ReactComponent as Nav_Discover } from './nav/discover.svg';
import {ReactComponent as Nav_Filter } from './nav/filter.svg';
import {ReactComponent as Nav_History } from './nav/history.svg';
import {ReactComponent as Nav_Home } from './nav/home.svg';
import {ReactComponent as Nav_LogOut } from './nav/log-out.svg';
import {ReactComponent as Nav_Minimalist } from './nav/minimalist.svg';
import {ReactComponent as Nav_Mygide } from './nav/mygide.svg';
import {ReactComponent as Nav_Navigide_Active } from './nav/navigide/active.svg';
import {ReactComponent as Nav_Navigide_Deactive } from './nav/navigide/deactive.svg';
import {ReactComponent as Nav_Notifications } from './nav/notifications.svg';
import {ReactComponent as Nav_Plus1 } from './nav/plus.1.svg';
import {ReactComponent as Nav_Plusicon } from './nav/plusicon.svg';
import {ReactComponent as Nav_Pointer } from './nav/pointer.svg';
import {ReactComponent as Nav_Publish } from './nav/publish.svg';
import {ReactComponent as Nav_Search } from './nav/search.svg';
import {ReactComponent as Nav_Settings } from './nav/settings.svg';
import {ReactComponent as Nav_Shortcuts } from './nav/shortcuts.svg';
import {ReactComponent as Nav_Sortandfilter } from './nav/sort&filter.svg';
import {ReactComponent as Nav_Sort } from './nav/sort.svg';
import {ReactComponent as Nav_ThumbsUp } from './nav/thumbs-up.svg';
import {ReactComponent as Nav_Time } from './nav/time.svg';
import {ReactComponent as Nav_ViewQ } from './nav/view-q.svg';
import {ReactComponent as Nav_Viewsettings_Active } from './nav/view-settings/active.svg';
import {ReactComponent as Nav_Viewsettings_Deactive } from './nav/view-settings/deactive.svg';
import {ReactComponent as Nav_Accordion_Colapsed } from './nav/accordion/colapsed.svg';
import {ReactComponent as Nav_Accordion_Expanded } from './nav/accordion/expanded.svg';
import {ReactComponent as Nav_Arrow_AddAbove } from './nav/arrow/add-above.svg';
import {ReactComponent as Nav_Arrow_AddBelow } from './nav/arrow/add-below.svg';
import {ReactComponent as Nav_Arrow_ArrowDown } from './nav/arrow/arrow-down.svg';
import {ReactComponent as Nav_Arrow_ArrowLeftRight } from './nav/arrow/arrow-left-right.svg';
import {ReactComponent as Nav_Arrow_ArrowLeft } from './nav/arrow/arrow-left.svg';
import {ReactComponent as Nav_Arrow_ArrowRight } from './nav/arrow/arrow-right.svg';
import {ReactComponent as Nav_Arrow_ArrowUpDown } from './nav/arrow/arrow-up-down.svg';
import {ReactComponent as Nav_Arrow_ArrowUp } from './nav/arrow/arrow-up.svg';
import {ReactComponent as Nav_Arrow_Reposition } from './nav/arrow/reposition.svg';
import {ReactComponent as Nav_Channels_Add } from './nav/channels/add.svg';
import {ReactComponent as Nav_Channels_Main } from './nav/channels/main.svg';
import {ReactComponent as Nav_Display_Halfandhalf } from './nav/display/half&half.svg';
import {ReactComponent as Nav_Display_MaxWidth } from './nav/display/max-width.svg';
import {ReactComponent as Nav_Display_Normal } from './nav/display/normal.svg';
import {ReactComponent as Nav_Display_Scroll } from './nav/display/scroll.svg';
import {ReactComponent as Nav_Display_Swipe } from './nav/display/swipe.svg';
import {ReactComponent as Nav_Display_Thumbnail } from './nav/display/thumbnail.svg';
import {ReactComponent as Nav_Display_Tool } from './nav/display/tool.svg';
import {ReactComponent as Nav_Edit_EditAdvanced } from './nav/edit/edit-advanced.svg';
import {ReactComponent as Nav_Edit_EditMain } from './nav/edit/edit-main.svg';
import {ReactComponent as Nav_EditMode_Active } from './nav/edit-mode/active.svg';
import {ReactComponent as Nav_EditMode_Deactive } from './nav/edit-mode/deactive.svg';
import {ReactComponent as Nav_EditMode_EditModeNumber } from './nav/edit-mode/edit-mode-number.svg';
import {ReactComponent as Nav_Exit_Alternative } from './nav/exit/alternative.svg';
import {ReactComponent as Nav_Exit_Main } from './nav/exit/main.svg';
import {ReactComponent as Nav_Eye_EyeClose } from './nav/eye/eye-close.svg';
import {ReactComponent as Nav_Eye_EyeOpen } from './nav/eye/eye-open.svg';
import {ReactComponent as Nav_Fullscreen_FullscreenCollapsed } from './nav/fullscreen/fullscreen-collapsed.svg';
import {ReactComponent as Nav_Fullscreen_FullscreenExpanded } from './nav/fullscreen/fullscreen-expanded.svg';
import {ReactComponent as Nav_Hamburger_Closed } from './nav/hamburger/closed.svg';
import {ReactComponent as Nav_Hamburger_Open } from './nav/hamburger/open.svg';
import {ReactComponent as Nav_Logo_LogoIconMd } from './nav/logo/logo-icon-md.svg';
import {ReactComponent as Nav_Logo_LogoIconSmcopy } from './nav/logo/logo-icon-sm copy.svg';
import {ReactComponent as Nav_Logo_LogoIconSm } from './nav/logo/logo-icon-sm.svg';
import {ReactComponent as Nav_Logo_LogoType } from './nav/logo/logo-type.svg';
import {ReactComponent as Nav_Logo_LogoType_Home} from './nav/logo/logo-type-home.svg';
import {ReactComponent as Nav_Logo_LogoType_InGide } from './nav/logo/logo-type-in-gide.svg';
import {ReactComponent as Nav_Logo_LogoColorEdit } from './nav/logo/logo-type-color-edit.svg';
import {ReactComponent as Nav_Media_Audio_Volume_Loud } from './nav/media/audio/volume/loud.svg';
import {ReactComponent as Nav_Media_Audio_Volume_Mute } from './nav/media/audio/volume/mute.svg';
import {ReactComponent as Nav_Media_Audio_Volume_Quiet } from './nav/media/audio/volume/quiet.svg';
import {ReactComponent as Nav_Media_Player_Pause } from './nav/media/player/pause.svg';
import {ReactComponent as Nav_Media_Player_Play } from './nav/media/player/play.svg';
import {ReactComponent as Nav_Media_Player_Record } from './nav/media/player/record.svg';
import {ReactComponent as Nav_Media_Player_Stop } from './nav/media/player/stop.svg';
import {ReactComponent as Nav_Media_Skip_SkipLeft } from './nav/media/skip/skip-left.svg';
import {ReactComponent as Nav_Notifications_Active } from './nav/notifications/active.svg';
import {ReactComponent as Nav_Notifications_Deactive } from './nav/notifications/deactive.svg';
import {ReactComponent as Nav_Skip_SkipRight } from './nav/skip/skip-right.svg';
import {ReactComponent as Nav_Stars_1 } from './nav/stars/1.svg';
import {ReactComponent as Nav_Stars_2 } from './nav/stars/2.svg';
import {ReactComponent as Nav_Stars_3 } from './nav/stars/3.svg';
import {ReactComponent as Nav_Stars_4 } from './nav/stars/4.svg';
import {ReactComponent as Nav_Stars_5 } from './nav/stars/5.svg';
import {ReactComponent as Nav_Swipe_Overlay } from './nav/swipe/overlay.svg';
import {ReactComponent as Nav_Text_Small } from './nav/text/small.svg';
import {ReactComponent as Nav_TextLock_Locked } from './nav/text-lock/locked.svg';
import {ReactComponent as Nav_TextLock_Unlocked } from './nav/text-lock/unlocked.svg';
import {ReactComponent as Nav_Threedots_Horizontal } from './nav/threedots/horizontal.svg';
import {ReactComponent as Nav_Threedots_Vertical } from './nav/threedots/vertical.svg';
import {ReactComponent as Nav_Threesquare } from './nav/threesquare.svg';
import {ReactComponent as Nav_ViewbarExtension_Colapsed } from './nav/viewbar-extension/colapsed.svg';
import {ReactComponent as Nav_ViewbarExtension_Expanded } from './nav/viewbar-extension/expanded.svg';
import {ReactComponent as SlideType_Audio_AudioFile } from './slidetype/audio/audio-file.svg';
import {ReactComponent as SlideType_Audio_Main } from './slidetype/audio/main.svg';
import {ReactComponent as SlideType_Audio_Mic } from './slidetype/audio/mic.svg';
import {ReactComponent as SlideType_Audio_ProductIcon } from './slidetype/audio/product-icon.svg';
import {ReactComponent as SlideType_Authorize_Main } from './slidetype/authorize/main.svg';
import {ReactComponent as SlideType_Authorize_ProductIcon } from './slidetype/authorize/product-icon.svg';
import {ReactComponent as SlideType_Columns_Main } from './slidetype/columns/main.svg';
import {ReactComponent as SlideType_Columns_ProductIcon } from './slidetype/columns/product-icon.svg';
import {ReactComponent as SlideType_Contact_Main } from './slidetype/contact/main.svg';
import {ReactComponent as SlideType_Contact_ProductIcon } from './slidetype/contact/product-icon.svg';
import {ReactComponent as SlideType_Doodle_Main } from './slidetype/doodle/main.svg';
import {ReactComponent as SlideType_Doodle_ProductIcon } from './slidetype/doodle/product-icon.svg';
import {ReactComponent as SlideType_Embed_Main } from './slidetype/embed/main.svg';
import {ReactComponent as SlideType_File_Maincopy } from './slidetype/file/main copy.svg';
import {ReactComponent as SlideType_File_Main } from './slidetype/file/main.svg';
import {ReactComponent as SlideType_File_ProductIcon } from './slidetype/file/product-icon.svg';
import {ReactComponent as SlideType_Gallery_2Col } from './slidetype/gallery/2-col.svg';
import {ReactComponent as SlideType_Gallery_3Col } from './slidetype/gallery/3-col.svg';
import {ReactComponent as SlideType_Gallery_4Col } from './slidetype/gallery/4-col.svg';
import {ReactComponent as SlideType_Gallery_Main } from './slidetype/gallery/main.svg';
import {ReactComponent as SlideType_Gallery_ProductIcon } from './slidetype/gallery/product-icon.svg';
// import {ReactComponent as SlideType_Header_Ce } from './slidetype/header/ce.svg';
// import {ReactComponent as SlideType_Header_Co } from './slidetype/header/co.svg';
import {ReactComponent as ContentAlteration_RichText_Header_H } from './content-alteration/richtext/header/h.svg';
import {ReactComponent as ContentAlteration_RichText_Header_H1 } from './content-alteration/richtext/header/h1.svg';
import {ReactComponent as ContentAlteration_RichText_Header_H2 } from './content-alteration/richtext/header/h2.svg';
import {ReactComponent as ContentAlteration_RichText_Header_H3 } from './content-alteration/richtext/header/h3.svg';
import {ReactComponent as ContentAlteration_RichText_Header_H4 } from './content-alteration/richtext/header/h4.svg';
import {ReactComponent as ContentAlteration_RichText_Header_H5 } from './content-alteration/richtext/header/h5.svg';
import {ReactComponent as ContentAlteration_RichText_Header_H6 } from './content-alteration/richtext/header/h6.svg';
import {ReactComponent as ContentAlteration_RichText_Header_End } from './content-alteration/richtext/header/end.svg';
import {ReactComponent as ContentAlteration_RichText_Header_Title } from './content-alteration/richtext/header/title.svg';
import {ReactComponent as ContentAlteration_RichText_Header_Modal } from './content-alteration/richtext/header/modal.svg';
// import {ReactComponent as SlideType_Header_Hr } from './slidetype/header/hr.svg';
// import {ReactComponent as SlideType_Header_Main } from './slidetype/header/main.svg';

// import {ReactComponent as SlideType_Header_Vb } from './slidetype/header/vb.svg';
import {ReactComponent as SlideType_Image_Main } from './slidetype/image/main.svg';
import {ReactComponent as SlideType_Image_ProductIcon } from './slidetype/image/product-icon.svg';
import {ReactComponent as SlideType_Input_Main } from './slidetype/input/main.svg';
import {ReactComponent as SlideType_Input_ProductIcon } from './slidetype/input/product-icon.svg';
import {ReactComponent as SlideType_Insert_Main } from './slidetype/insert/main.svg';
import {ReactComponent as SlideType_Insert_ProductIcon } from './slidetype/insert/product-icon.svg';
import {ReactComponent as SlideType_Link_Main } from './slidetype/link/main.svg';
import {ReactComponent as SlideType_Link_ProductIcon } from './slidetype/link/product-icon.svg';
import {ReactComponent as SlideType_List_ProductIcon } from './slidetype/list/product-icon.svg';
import {ReactComponent as SlideType_Location_Findyourlocation } from './slidetype/location/findyourlocation.svg';
import {ReactComponent as SlideType_Location_Location } from './slidetype/location/location.svg';
import {ReactComponent as SlideType_Location_Main } from './slidetype/location/main.svg';
import {ReactComponent as SlideType_Location_ProductIcon } from './slidetype/location/product-icon.svg';
import {ReactComponent as SlideType_Map_Main } from './slidetype/map/main.svg';
import {ReactComponent as SlideType_Map_ProductIcon } from './slidetype/map/product-icon.svg';
import {ReactComponent as SlideType_Slideshow_Main } from './slidetype/slideshow/main.svg';
import {ReactComponent as SlideType_Slideshow_ProductIcon } from './slidetype/slideshow/product-icon.svg';
import {ReactComponent as SlideType_Socialmedia_Main } from './slidetype/socialmedia/main.svg';
import {ReactComponent as SlideType_Socialmedia_ProductIcon } from './slidetype/socialmedia/product-icon.svg';
import {ReactComponent as ContentAlteration_RichText_Main } from './content-alteration/richtext/main.svg';
import {ReactComponent as ContentAlteration_RichText_Header_SimpleText } from './content-alteration/richtext/header/simple-text.svg';
import {ReactComponent as SlideType_Text_Large } from './slidetype/text/large.svg';
import {ReactComponent as SlideType_Text_Main } from './slidetype/text/main.svg';
import {ReactComponent as SlideType_Text_ProductIcon } from './slidetype/text/product-icon.svg';
import {ReactComponent as SlideType_Text_Small } from './slidetype/text/small.svg';
import {ReactComponent as SlideType_Video_Main } from './slidetype/video/main.svg';
import {ReactComponent as SlideType_Video_ProductIcon } from './slidetype/video/product-icon.svg';



import {ReactComponent as Nav_Refresh} from './nav/refresh.svg';




// import {ReactComponent as Nav_Logo_LogoIconMd } from './nav/logo/logo-icon-md.svg';
// import {ReactComponent as Nav_Logo_LogoIconSmcopy } from './nav/logo/logo-icon-sm copy.svg';
// import {ReactComponent as Nav_Logo_LogoIconSm } from './nav/logo/logo-icon-sm.svg';
// import {ReactComponent as Nav_Logo_LogoType } from './nav/logo/logo-type.svg';
import { DirectionalHint } from 'office-ui-fabric-react/lib/Tooltip';
import { Callout } from 'office-ui-fabric-react/lib/Callout';

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  title?: string;
  doNotFillFromColor?: boolean;
  tooltipMessage?: string;
  strokeColor?: string;
  style?: CSSProperties;
}

const StyledSvg = (Icon: React.FC<IconProps>) =>
	(props: IconProps) => {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [target, setTarget] = useState<Element>();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [isTouch, setIsTouch] = useState<boolean>(false);
		const updatedProps = {...props};
    delete updatedProps.doNotFillFromColor;
    const style = props.strokeColor 
      ? {
        ...(props.style || {}),
        "--strokeColor": `${props.strokeColor}`,
      } as React.CSSProperties
      : {...(props.style || {})};
		return <>
      <Icon {...updatedProps}
        style={style}
				title={props.title || ""}
				color={props.color || props.fill}
				className={classnames(props.className, !props.doNotFillFromColor && styles.fillFromColor)}
				onTouchStart={(e) => { !isTouch && setIsTouch(true); }}
				onContextMenuCapture={(e) => {
          if (props.tooltipMessage) {
            // Set this variable in the console window in the browser, if you actually want to see the context-menu
            // (to inspect an element in chrome dev-tools for example).
            const showContextMenuForIcons = (global as any).showContextMenuForIcons;
            if (!showContextMenuForIcons || isTouch) {
              e.preventDefault();
              // e.stopPropagation();
            }
            setTarget(e.currentTarget);
          }
				}}
			/>
			{target &&
				<Callout
					target={target}
					setInitialFocus={true}
					onDismiss={() => { setTarget(undefined); }}
					onBlur={() => { setTarget(undefined); }}
					directionalHint={DirectionalHint.topCenter}>
					<div tabIndex={0} className={styles.callout}>{props.tooltipMessage}</div>
				</Callout>
			}
		</>;
	}

  const icons = {
    Avatar_AvatarLg: StyledSvg(Avatar_AvatarLg),
    Avatar_AvatarMdDeactive: StyledSvg(Avatar_AvatarMdDeactive),
    Avatar_AvatarMd: StyledSvg(Avatar_AvatarMd),
    Avatar_AvatarSm: StyledSvg(Avatar_AvatarSm),
    Avatar_Verifyed: StyledSvg(Avatar_Verifyed),
    ContentAlteration_Atsymbol: StyledSvg(ContentAlteration_Atsymbol),
    ContentAlteration_Additions: StyledSvg(ContentAlteration_Additions),
    ContentAlteration_Attach: StyledSvg(ContentAlteration_Attach),
    ContentAlteration_Comments_Active: StyledSvg(ContentAlteration_Comments_Active),
    ContentAlteration_Comments_Deactive: StyledSvg(ContentAlteration_Comments_Deactive),
    ContentAlteration_Copy: StyledSvg(ContentAlteration_Copy),
    ContentAlteration_Delete: StyledSvg(ContentAlteration_Delete),
    ContentAlteration_Download: StyledSvg(ContentAlteration_Download),
    ContentAlteration_EditSlide: StyledSvg(ContentAlteration_EditSlide),
    ContentAlteration_Emoji: StyledSvg(ContentAlteration_Emoji),
    ContentAlteration_Follower: StyledSvg(ContentAlteration_Follower),
    ContentAlteration_Followers: StyledSvg(ContentAlteration_Followers),
    ContentAlteration_Hashtag: StyledSvg(ContentAlteration_Hashtag),
    ContentAlteration_Headders: StyledSvg(ContentAlteration_Headders),
    ContentAlteration_List: StyledSvg(ContentAlteration_List),
    ContentAlteration_Newgide: StyledSvg(ContentAlteration_Newgide),
    ContentAlteration_Notes: StyledSvg(ContentAlteration_Notes),
    ContentAlteration_Questions: StyledSvg(ContentAlteration_Questions),
    ContentAlteration_Redo: StyledSvg(ContentAlteration_Redo),
    ContentAlteration_Reply: StyledSvg(ContentAlteration_Reply),
    ContentAlteration_Share: StyledSvg(ContentAlteration_Share),
    ContentAlteration_SlideAdditions: StyledSvg(ContentAlteration_SlideAdditions),
    ContentAlteration_Textblock: StyledSvg(ContentAlteration_Textblock),
    ContentAlteration_Transfer: StyledSvg(ContentAlteration_Transfer),
    ContentAlteration_MoveTo: StyledSvg(ContentAlteration_MoveTo),
    ContentAlteration_Trash: StyledSvg(ContentAlteration_Trash),
    ContentAlteration_Undo: StyledSvg(ContentAlteration_Undo),
    ContentAlteration_Upload: StyledSvg(ContentAlteration_Upload),
    ContentAlteration_Camera_Alt: StyledSvg(ContentAlteration_Camera_Alt),
    ContentAlteration_Camera_Default: StyledSvg(ContentAlteration_Camera_Default),
    ContentAlteration_Camera_Flip: StyledSvg(ContentAlteration_Camera_Flip),
    ContentAlteration_Check_Alternative: StyledSvg(ContentAlteration_Check_Alternative),
    ContentAlteration_Check_Main: StyledSvg(ContentAlteration_Check_Main),
    ContentAlteration_Checkbox_CheckboxActive: StyledSvg(ContentAlteration_Checkbox_CheckboxActive),
    ContentAlteration_Checkbox_CheckboxDeactive: StyledSvg(ContentAlteration_Checkbox_CheckboxDeactive),
    ContentAlteration_Checkbox_Uncheck: StyledSvg(ContentAlteration_Checkbox_Uncheck),
    ContentAlteration_InputBar_Checkmark_1: StyledSvg(ContentAlteration_InputBar_Checkmark_1),
    ContentAlteration_InputBar_Checkmark_999: StyledSvg(ContentAlteration_InputBar_Checkmark_999),
    ContentAlteration_InputBar_Checkmark_Basic: StyledSvg(ContentAlteration_InputBar_Checkmark_Basic),
    ContentAlteration_InputBar_Checkmark_Edit: StyledSvg(ContentAlteration_InputBar_Checkmark_Edit),
    ContentAlteration_InputBar_Checkmark_Outline: StyledSvg(ContentAlteration_InputBar_Checkmark_Outline),
    ContentAlteration_InputBar_Slide_1: StyledSvg(ContentAlteration_InputBar_Slide_1),
    ContentAlteration_InputBar_Slide_999: StyledSvg(ContentAlteration_InputBar_Slide_999),
    ContentAlteration_InputBar_Slide_9999: StyledSvg(ContentAlteration_InputBar_Slide_9999),
    ContentAlteration_InputBar_Slide_Basic: StyledSvg(ContentAlteration_InputBar_Slide_Basic),
    ContentAlteration_InputBar_Slide_Finalise: StyledSvg(ContentAlteration_InputBar_Slide_Finalise),
    ContentAlteration_InputBar_Slide_1_Alt: StyledSvg(ContentAlteration_InputBar_Slide_1_Alt),
    ContentAlteration_Like_Active: StyledSvg(ContentAlteration_Like_Active),
    ContentAlteration_Like_DeActive: StyledSvg(ContentAlteration_Like_DeActive),
    ContentAlteration_Radio_Active: StyledSvg(ContentAlteration_Radio_Active),
    ContentAlteration_Radio_Deactive: StyledSvg(ContentAlteration_Radio_Deactive),
    ContentAlteration_Rate_Active: StyledSvg(ContentAlteration_Rate_Active),
    ContentAlteration_Rate_Deactive: StyledSvg(ContentAlteration_Rate_Deactive),
    ContentAlteration_Save_Active: StyledSvg(ContentAlteration_Save_Active),
    ContentAlteration_Save_Deactive: StyledSvg(ContentAlteration_Save_Deactive),
    ContentAlteration_TextAlign_Center: StyledSvg(ContentAlteration_TextAlign_Center),
    ContentAlteration_TextAlign_Left: StyledSvg(ContentAlteration_TextAlign_Left),
    ContentAlteration_TextAlign_Right: StyledSvg(ContentAlteration_TextAlign_Right),
    CreationProcess_Citing: StyledSvg(CreationProcess_Citing),
    CreationProcess_EditText: StyledSvg(CreationProcess_EditText),
    CreationProcess_Events: StyledSvg(CreationProcess_Events),
    CreationProcess_Preview: StyledSvg(CreationProcess_Preview),
    CreationProcess_Slide: StyledSvg(CreationProcess_Slide),
    CreationProcess_Stickers: StyledSvg(CreationProcess_Stickers),
    CreationProcess_TextBackground: StyledSvg(CreationProcess_TextBackground),
    CreationProcess_TextOnly: StyledSvg(CreationProcess_TextOnly),
    CreationProcess_Tile: StyledSvg(CreationProcess_Tile),
    CreationProcess_TouchActivate: StyledSvg(CreationProcess_TouchActivate),
    CreationProcess_Trim: StyledSvg(CreationProcess_Trim),
    CreationProcess_Advance_Advance10S: StyledSvg(CreationProcess_Advance_Advance10S),
    CreationProcess_Advance_AutoAdvance: StyledSvg(CreationProcess_Advance_AutoAdvance),
    CreationProcess_Advance_AutoPlay: StyledSvg(CreationProcess_Advance_AutoPlay),
    CreationProcess_Advance_Rewind10S: StyledSvg(CreationProcess_Advance_Rewind10S),
    CreationProcess_Bloglist_ImageLeft: StyledSvg(CreationProcess_Bloglist_ImageLeft),
    CreationProcess_Bloglist_ImageOnly: StyledSvg(CreationProcess_Bloglist_ImageOnly),
    CreationProcess_Bloglist_ImageTop: StyledSvg(CreationProcess_Bloglist_ImageTop),
    CreationProcess_Bloglist_Nothing: StyledSvg(CreationProcess_Bloglist_Nothing),
    CreationProcess_Bloglist_TextOnly: StyledSvg(CreationProcess_Bloglist_TextOnly),
    CreationProcess_Cropandrotate_Crop: StyledSvg(CreationProcess_Cropandrotate_Crop),
    CreationProcess_Cropandrotate_Cropandrotate: StyledSvg(CreationProcess_Cropandrotate_Cropandrotate),
    CreationProcess_Cropandrotate_Rotate: StyledSvg(CreationProcess_Cropandrotate_Rotate),
    CreationProcess_Display_Display: StyledSvg(CreationProcess_Display_Display),
    CreationProcess_DisplaySettings_Carocell: StyledSvg(CreationProcess_DisplaySettings_Carocell),
    CreationProcess_DisplaySettings_Frame: StyledSvg(CreationProcess_DisplaySettings_Frame),
    CreationProcess_DisplaySettings_SquareFrame: StyledSvg(CreationProcess_DisplaySettings_SquareFrame),
    CreationProcess_DisplaySettings_Stacked: StyledSvg(CreationProcess_DisplaySettings_Stacked),
    CreationProcess_DisplaySettings_Swipe: StyledSvg(CreationProcess_DisplaySettings_Swipe),
    CreationProcess_Justification_JustificationBottom: StyledSvg(CreationProcess_Justification_JustificationBottom),
    CreationProcess_Justification_JustificationCenter: StyledSvg(CreationProcess_Justification_JustificationCenter),
    CreationProcess_Justification_JustificationLeft: StyledSvg(CreationProcess_Justification_JustificationLeft),
    CreationProcess_Justification_JustificationRight: StyledSvg(CreationProcess_Justification_JustificationRight),
    CreationProcess_Justification_JustificationTop: StyledSvg(CreationProcess_Justification_JustificationTop),
    // Flags_BlackFlag: StyledSvg(Flags_BlackFlag),
    // Flags_ChequeredFlag: StyledSvg(Flags_ChequeredFlag),
    // Flags_CrossedFlags: StyledSvg(Flags_CrossedFlags),
    // Flags_FlagAfghanistan: StyledSvg(Flags_FlagAfghanistan),
    // Flags_FlagAlandIslands: StyledSvg(Flags_FlagAlandIslands),
    // Flags_FlagAlbania: StyledSvg(Flags_FlagAlbania),
    // Flags_FlagAlgeria: StyledSvg(Flags_FlagAlgeria),
    // Flags_FlagAmericanSamoa: StyledSvg(Flags_FlagAmericanSamoa),
    // Flags_FlagAndorra: StyledSvg(Flags_FlagAndorra),
    // Flags_FlagAngola: StyledSvg(Flags_FlagAngola),
    // Flags_FlagAnguilla: StyledSvg(Flags_FlagAnguilla),
    // Flags_FlagAntarctica: StyledSvg(Flags_FlagAntarctica),
    // Flags_FlagAntiguaAmpBarbuda: StyledSvg(Flags_FlagAntiguaAmpBarbuda),
    // Flags_FlagArgentina: StyledSvg(Flags_FlagArgentina),
    // Flags_FlagArmenia: StyledSvg(Flags_FlagArmenia),
    // Flags_FlagAruba: StyledSvg(Flags_FlagAruba),
    // Flags_FlagAscensionIsland: StyledSvg(Flags_FlagAscensionIsland),
    // Flags_FlagAustralia: StyledSvg(Flags_FlagAustralia),
    // Flags_FlagAustria: StyledSvg(Flags_FlagAustria),
    // Flags_FlagAzerbaijan: StyledSvg(Flags_FlagAzerbaijan),
    // Flags_FlagBahamas: StyledSvg(Flags_FlagBahamas),
    // Flags_FlagBahrain: StyledSvg(Flags_FlagBahrain),
    // Flags_FlagBangladesh: StyledSvg(Flags_FlagBangladesh),
    // Flags_FlagBarbados: StyledSvg(Flags_FlagBarbados),
    // Flags_FlagBelarus: StyledSvg(Flags_FlagBelarus),
    // Flags_FlagBelgium: StyledSvg(Flags_FlagBelgium),
    // Flags_FlagBelize: StyledSvg(Flags_FlagBelize),
    // Flags_FlagBenin: StyledSvg(Flags_FlagBenin),
    // Flags_FlagBermuda: StyledSvg(Flags_FlagBermuda),
    // Flags_FlagBhutan: StyledSvg(Flags_FlagBhutan),
    // Flags_FlagBolivia: StyledSvg(Flags_FlagBolivia),
    // Flags_FlagBosniaAmpHerzegovina: StyledSvg(Flags_FlagBosniaAmpHerzegovina),
    // Flags_FlagBotswana: StyledSvg(Flags_FlagBotswana),
    // Flags_FlagBouvetIsland: StyledSvg(Flags_FlagBouvetIsland),
    // Flags_FlagBrazil: StyledSvg(Flags_FlagBrazil),
    // Flags_FlagBritishIndianOceanTerritory: StyledSvg(Flags_FlagBritishIndianOceanTerritory),
    // Flags_FlagBritishVirginIslands: StyledSvg(Flags_FlagBritishVirginIslands),
    // Flags_FlagBrunei: StyledSvg(Flags_FlagBrunei),
    // Flags_FlagBulgaria: StyledSvg(Flags_FlagBulgaria),
    // Flags_FlagBurkinaFaso: StyledSvg(Flags_FlagBurkinaFaso),
    // Flags_FlagBurundi: StyledSvg(Flags_FlagBurundi),
    // Flags_FlagCambodia: StyledSvg(Flags_FlagCambodia),
    // Flags_FlagCameroon: StyledSvg(Flags_FlagCameroon),
    // Flags_FlagCanada: StyledSvg(Flags_FlagCanada),
    // Flags_FlagCanaryIslands: StyledSvg(Flags_FlagCanaryIslands),
    // Flags_FlagCapeVerde: StyledSvg(Flags_FlagCapeVerde),
    // Flags_FlagCaribbeanNetherlands: StyledSvg(Flags_FlagCaribbeanNetherlands),
    // Flags_FlagCaymanIslands: StyledSvg(Flags_FlagCaymanIslands),
    // Flags_FlagCentralAfricanRepublic: StyledSvg(Flags_FlagCentralAfricanRepublic),
    // Flags_FlagCeutaAmpMelilla: StyledSvg(Flags_FlagCeutaAmpMelilla),
    // Flags_FlagChad: StyledSvg(Flags_FlagChad),
    // Flags_FlagChile: StyledSvg(Flags_FlagChile),
    // Flags_FlagChina: StyledSvg(Flags_FlagChina),
    // Flags_FlagChristmasIsland: StyledSvg(Flags_FlagChristmasIsland),
    // Flags_FlagClippertonIsland: StyledSvg(Flags_FlagClippertonIsland),
    // Flags_FlagCocosKeelingIslands: StyledSvg(Flags_FlagCocosKeelingIslands),
    // Flags_FlagColombia: StyledSvg(Flags_FlagColombia),
    // Flags_FlagComoros: StyledSvg(Flags_FlagComoros),
    // Flags_FlagCongoBrazzaville: StyledSvg(Flags_FlagCongoBrazzaville),
    // Flags_FlagCongoKinshasa: StyledSvg(Flags_FlagCongoKinshasa),
    // Flags_FlagCookIslands: StyledSvg(Flags_FlagCookIslands),
    // Flags_FlagCostaRica: StyledSvg(Flags_FlagCostaRica),
    // Flags_FlagCoteDivoire: StyledSvg(Flags_FlagCoteDivoire),
    // Flags_FlagCroatia: StyledSvg(Flags_FlagCroatia),
    // Flags_FlagCuba: StyledSvg(Flags_FlagCuba),
    // Flags_FlagCuracao: StyledSvg(Flags_FlagCuracao),
    // Flags_FlagCyprus: StyledSvg(Flags_FlagCyprus),
    // Flags_FlagCzechia: StyledSvg(Flags_FlagCzechia),
    // Flags_FlagDenmark: StyledSvg(Flags_FlagDenmark),
    // Flags_FlagDiegoGarcia: StyledSvg(Flags_FlagDiegoGarcia),
    // Flags_FlagDjibouti: StyledSvg(Flags_FlagDjibouti),
    // Flags_FlagDominica: StyledSvg(Flags_FlagDominica),
    // Flags_FlagDominicanRepublic: StyledSvg(Flags_FlagDominicanRepublic),
    // Flags_FlagEcuador: StyledSvg(Flags_FlagEcuador),
    // Flags_FlagEgypt: StyledSvg(Flags_FlagEgypt),
    // Flags_FlagElSalvador: StyledSvg(Flags_FlagElSalvador),
    // Flags_FlagEngland: StyledSvg(Flags_FlagEngland),
    // Flags_FlagEquatorialGuinea: StyledSvg(Flags_FlagEquatorialGuinea),
    // Flags_FlagEritrea: StyledSvg(Flags_FlagEritrea),
    // Flags_FlagEstonia: StyledSvg(Flags_FlagEstonia),
    // Flags_FlagEswatini: StyledSvg(Flags_FlagEswatini),
    // Flags_FlagEthiopia: StyledSvg(Flags_FlagEthiopia),
    // Flags_FlagEuropeanUnion: StyledSvg(Flags_FlagEuropeanUnion),
    // Flags_FlagFalklandIslands: StyledSvg(Flags_FlagFalklandIslands),
    // Flags_FlagFaroeIslands: StyledSvg(Flags_FlagFaroeIslands),
    // Flags_FlagFiji: StyledSvg(Flags_FlagFiji),
    // Flags_FlagFinland: StyledSvg(Flags_FlagFinland),
    // Flags_FlagFrance: StyledSvg(Flags_FlagFrance),
    // Flags_FlagFrenchGuiana: StyledSvg(Flags_FlagFrenchGuiana),
    // Flags_FlagFrenchPolynesia: StyledSvg(Flags_FlagFrenchPolynesia),
    // Flags_FlagFrenchSouthernTerritories: StyledSvg(Flags_FlagFrenchSouthernTerritories),
    // Flags_FlagGabon: StyledSvg(Flags_FlagGabon),
    // Flags_FlagGambia: StyledSvg(Flags_FlagGambia),
    // Flags_FlagGeorgia: StyledSvg(Flags_FlagGeorgia),
    // Flags_FlagGermany: StyledSvg(Flags_FlagGermany),
    // Flags_FlagGhana: StyledSvg(Flags_FlagGhana),
    // Flags_FlagGibraltar: StyledSvg(Flags_FlagGibraltar),
    // Flags_FlagGreece: StyledSvg(Flags_FlagGreece),
    // Flags_FlagGreenland: StyledSvg(Flags_FlagGreenland),
    // Flags_FlagGrenada: StyledSvg(Flags_FlagGrenada),
    // Flags_FlagGuadeloupe: StyledSvg(Flags_FlagGuadeloupe),
    // Flags_FlagGuam: StyledSvg(Flags_FlagGuam),
    // Flags_FlagGuatemala: StyledSvg(Flags_FlagGuatemala),
    // Flags_FlagGuernsey: StyledSvg(Flags_FlagGuernsey),
    // Flags_FlagGuineaBissau: StyledSvg(Flags_FlagGuineaBissau),
    // Flags_FlagGuinea: StyledSvg(Flags_FlagGuinea),
    // Flags_FlagGuyana: StyledSvg(Flags_FlagGuyana),
    // Flags_FlagHaiti: StyledSvg(Flags_FlagHaiti),
    // Flags_FlagHeardAmpMcdonaldIslands: StyledSvg(Flags_FlagHeardAmpMcdonaldIslands),
    // Flags_FlagHonduras: StyledSvg(Flags_FlagHonduras),
    // Flags_FlagHongKongSarChina: StyledSvg(Flags_FlagHongKongSarChina),
    // Flags_FlagHungary: StyledSvg(Flags_FlagHungary),
    // Flags_FlagIceland: StyledSvg(Flags_FlagIceland),
    // Flags_FlagIndia: StyledSvg(Flags_FlagIndia),
    // Flags_FlagIndonesia: StyledSvg(Flags_FlagIndonesia),
    // Flags_FlagIran: StyledSvg(Flags_FlagIran),
    // Flags_FlagIraq: StyledSvg(Flags_FlagIraq),
    // Flags_FlagIreland: StyledSvg(Flags_FlagIreland),
    // Flags_FlagIsleOfMan: StyledSvg(Flags_FlagIsleOfMan),
    // Flags_FlagIsrael: StyledSvg(Flags_FlagIsrael),
    // Flags_FlagItaly: StyledSvg(Flags_FlagItaly),
    // Flags_FlagJamaica: StyledSvg(Flags_FlagJamaica),
    // Flags_FlagJapan: StyledSvg(Flags_FlagJapan),
    // Flags_FlagJersey: StyledSvg(Flags_FlagJersey),
    // Flags_FlagJordan: StyledSvg(Flags_FlagJordan),
    // Flags_FlagKazakhstan: StyledSvg(Flags_FlagKazakhstan),
    // Flags_FlagKenya: StyledSvg(Flags_FlagKenya),
    // Flags_FlagKiribati: StyledSvg(Flags_FlagKiribati),
    // Flags_FlagKosovo: StyledSvg(Flags_FlagKosovo),
    // Flags_FlagKuwait: StyledSvg(Flags_FlagKuwait),
    // Flags_FlagKyrgyzstan: StyledSvg(Flags_FlagKyrgyzstan),
    // Flags_FlagLaos: StyledSvg(Flags_FlagLaos),
    // Flags_FlagLatvia: StyledSvg(Flags_FlagLatvia),
    // Flags_FlagLebanon: StyledSvg(Flags_FlagLebanon),
    // Flags_FlagLesotho: StyledSvg(Flags_FlagLesotho),
    // Flags_FlagLiberia: StyledSvg(Flags_FlagLiberia),
    // Flags_FlagLibya: StyledSvg(Flags_FlagLibya),
    // Flags_FlagLiechtenstein: StyledSvg(Flags_FlagLiechtenstein),
    // Flags_FlagLithuania: StyledSvg(Flags_FlagLithuania),
    // Flags_FlagLuxembourg: StyledSvg(Flags_FlagLuxembourg),
    // Flags_FlagMacaoSarChina: StyledSvg(Flags_FlagMacaoSarChina),
    // Flags_FlagMacedonia: StyledSvg(Flags_FlagMacedonia),
    // Flags_FlagMadagascar: StyledSvg(Flags_FlagMadagascar),
    // Flags_FlagMalawi: StyledSvg(Flags_FlagMalawi),
    // Flags_FlagMalaysia: StyledSvg(Flags_FlagMalaysia),
    // Flags_FlagMaldives: StyledSvg(Flags_FlagMaldives),
    // Flags_FlagMali: StyledSvg(Flags_FlagMali),
    // Flags_FlagMalta: StyledSvg(Flags_FlagMalta),
    // Flags_FlagMarshallIslands: StyledSvg(Flags_FlagMarshallIslands),
    // Flags_FlagMartinique: StyledSvg(Flags_FlagMartinique),
    // Flags_FlagMauritania: StyledSvg(Flags_FlagMauritania),
    // Flags_FlagMauritius: StyledSvg(Flags_FlagMauritius),
    // Flags_FlagMayotte: StyledSvg(Flags_FlagMayotte),
    // Flags_FlagMexico: StyledSvg(Flags_FlagMexico),
    // Flags_FlagMicronesia: StyledSvg(Flags_FlagMicronesia),
    // Flags_FlagMoldova: StyledSvg(Flags_FlagMoldova),
    // Flags_FlagMonaco: StyledSvg(Flags_FlagMonaco),
    // Flags_FlagMongolia: StyledSvg(Flags_FlagMongolia),
    // Flags_FlagMontenegro: StyledSvg(Flags_FlagMontenegro),
    // Flags_FlagMontserrat: StyledSvg(Flags_FlagMontserrat),
    // Flags_FlagMorocco: StyledSvg(Flags_FlagMorocco),
    // Flags_FlagMozambique: StyledSvg(Flags_FlagMozambique),
    // Flags_FlagMyanmarBurma: StyledSvg(Flags_FlagMyanmarBurma),
    // Flags_FlagNamibia: StyledSvg(Flags_FlagNamibia),
    // Flags_FlagNauru: StyledSvg(Flags_FlagNauru),
    // Flags_FlagNepal: StyledSvg(Flags_FlagNepal),
    // Flags_FlagNetherlands: StyledSvg(Flags_FlagNetherlands),
    // Flags_FlagNewCaledonia: StyledSvg(Flags_FlagNewCaledonia),
    // Flags_FlagNewZealand: StyledSvg(Flags_FlagNewZealand),
    // Flags_FlagNicaragua: StyledSvg(Flags_FlagNicaragua),
    // Flags_FlagNiger: StyledSvg(Flags_FlagNiger),
    // Flags_FlagNigeria: StyledSvg(Flags_FlagNigeria),
    // Flags_FlagNiue: StyledSvg(Flags_FlagNiue),
    // Flags_FlagNorfolkIsland: StyledSvg(Flags_FlagNorfolkIsland),
    // Flags_FlagNorthKorea: StyledSvg(Flags_FlagNorthKorea),
    // Flags_FlagNorthernMarianaIslands: StyledSvg(Flags_FlagNorthernMarianaIslands),
    // Flags_FlagNorway: StyledSvg(Flags_FlagNorway),
    // Flags_FlagOman: StyledSvg(Flags_FlagOman),
    // Flags_FlagPakistan: StyledSvg(Flags_FlagPakistan),
    // Flags_FlagPalau: StyledSvg(Flags_FlagPalau),
    // Flags_FlagPalestinianTerritories: StyledSvg(Flags_FlagPalestinianTerritories),
    // Flags_FlagPanama: StyledSvg(Flags_FlagPanama),
    // Flags_FlagPapuaNewGuinea: StyledSvg(Flags_FlagPapuaNewGuinea),
    // Flags_FlagParaguay: StyledSvg(Flags_FlagParaguay),
    // Flags_FlagPeru: StyledSvg(Flags_FlagPeru),
    // Flags_FlagPhilippines: StyledSvg(Flags_FlagPhilippines),
    // Flags_FlagPitcairnIslands: StyledSvg(Flags_FlagPitcairnIslands),
    // Flags_FlagPoland: StyledSvg(Flags_FlagPoland),
    // Flags_FlagPortugal: StyledSvg(Flags_FlagPortugal),
    // Flags_FlagPuertoRico: StyledSvg(Flags_FlagPuertoRico),
    // Flags_FlagQatar: StyledSvg(Flags_FlagQatar),
    // Flags_FlagReunion: StyledSvg(Flags_FlagReunion),
    // Flags_FlagRomania: StyledSvg(Flags_FlagRomania),
    // Flags_FlagRussia: StyledSvg(Flags_FlagRussia),
    // Flags_FlagRwanda: StyledSvg(Flags_FlagRwanda),
    // Flags_FlagSamoa: StyledSvg(Flags_FlagSamoa),
    // Flags_FlagSanMarino: StyledSvg(Flags_FlagSanMarino),
    // Flags_FlagSaoTomeAmpPrincipe: StyledSvg(Flags_FlagSaoTomeAmpPrincipe),
    // Flags_FlagSaudiArabia: StyledSvg(Flags_FlagSaudiArabia),
    // Flags_FlagScotland: StyledSvg(Flags_FlagScotland),
    // Flags_FlagSenegal: StyledSvg(Flags_FlagSenegal),
    // Flags_FlagSerbia: StyledSvg(Flags_FlagSerbia),
    // Flags_FlagSeychelles: StyledSvg(Flags_FlagSeychelles),
    // Flags_FlagSierraLeone: StyledSvg(Flags_FlagSierraLeone),
    // Flags_FlagSingapore: StyledSvg(Flags_FlagSingapore),
    // Flags_FlagSintMaarten: StyledSvg(Flags_FlagSintMaarten),
    // Flags_FlagSlovakia: StyledSvg(Flags_FlagSlovakia),
    // Flags_FlagSlovenia: StyledSvg(Flags_FlagSlovenia),
    // Flags_FlagSolomonIslands: StyledSvg(Flags_FlagSolomonIslands),
    // Flags_FlagSomalia: StyledSvg(Flags_FlagSomalia),
    // Flags_FlagSouthAfrica: StyledSvg(Flags_FlagSouthAfrica),
    // Flags_FlagSouthGeorgiaAmpSouthSandwichIslands: StyledSvg(Flags_FlagSouthGeorgiaAmpSouthSandwichIslands),
    // Flags_FlagSouthKorea: StyledSvg(Flags_FlagSouthKorea),
    // Flags_FlagSouthSudan: StyledSvg(Flags_FlagSouthSudan),
    // Flags_FlagSpain: StyledSvg(Flags_FlagSpain),
    // Flags_FlagSriLanka: StyledSvg(Flags_FlagSriLanka),
    // Flags_FlagStBarthelemy: StyledSvg(Flags_FlagStBarthelemy),
    // Flags_FlagStHelena: StyledSvg(Flags_FlagStHelena),
    // Flags_FlagStKittsAmpNevis: StyledSvg(Flags_FlagStKittsAmpNevis),
    // Flags_FlagStLucia: StyledSvg(Flags_FlagStLucia),
    // Flags_FlagStMartin: StyledSvg(Flags_FlagStMartin),
    // Flags_FlagStPierreAmpMiquelon: StyledSvg(Flags_FlagStPierreAmpMiquelon),
    // Flags_FlagStVincentAmpGrenadines: StyledSvg(Flags_FlagStVincentAmpGrenadines),
    // Flags_FlagSudan: StyledSvg(Flags_FlagSudan),
    // Flags_FlagSuriname: StyledSvg(Flags_FlagSuriname),
    // Flags_FlagSvalbardAmpJanMayen: StyledSvg(Flags_FlagSvalbardAmpJanMayen),
    // Flags_FlagSweden: StyledSvg(Flags_FlagSweden),
    // Flags_FlagSwitzerland: StyledSvg(Flags_FlagSwitzerland),
    // Flags_FlagSyria: StyledSvg(Flags_FlagSyria),
    // Flags_FlagTaiwan: StyledSvg(Flags_FlagTaiwan),
    // Flags_FlagTajikistan: StyledSvg(Flags_FlagTajikistan),
    // Flags_FlagTanzania: StyledSvg(Flags_FlagTanzania),
    // Flags_FlagThailand: StyledSvg(Flags_FlagThailand),
    // Flags_FlagTimorLeste: StyledSvg(Flags_FlagTimorLeste),
    // Flags_FlagTogo: StyledSvg(Flags_FlagTogo),
    // Flags_FlagTokelau: StyledSvg(Flags_FlagTokelau),
    // Flags_FlagTonga: StyledSvg(Flags_FlagTonga),
    // Flags_FlagTrinidadAmpTobago: StyledSvg(Flags_FlagTrinidadAmpTobago),
    // Flags_FlagTristanDaCunha: StyledSvg(Flags_FlagTristanDaCunha),
    // Flags_FlagTunisia: StyledSvg(Flags_FlagTunisia),
    // Flags_FlagTurkey: StyledSvg(Flags_FlagTurkey),
    // Flags_FlagTurkmenistan: StyledSvg(Flags_FlagTurkmenistan),
    // Flags_FlagTurksAmpCaicosIslands: StyledSvg(Flags_FlagTurksAmpCaicosIslands),
    // Flags_FlagTuvalu: StyledSvg(Flags_FlagTuvalu),
    // Flags_FlagUganda: StyledSvg(Flags_FlagUganda),
    // Flags_FlagUkraine: StyledSvg(Flags_FlagUkraine),
    // Flags_FlagUnitedArabEmirates: StyledSvg(Flags_FlagUnitedArabEmirates),
    // Flags_FlagUnitedKingdom: StyledSvg(Flags_FlagUnitedKingdom),
    // Flags_FlagUnitedNations: StyledSvg(Flags_FlagUnitedNations),
    // Flags_FlagUnitedStates: StyledSvg(Flags_FlagUnitedStates),
    // Flags_FlagUruguay: StyledSvg(Flags_FlagUruguay),
    // Flags_FlagUsOutlyingIslands: StyledSvg(Flags_FlagUsOutlyingIslands),
    // Flags_FlagUsVirginIslands: StyledSvg(Flags_FlagUsVirginIslands),
    // Flags_FlagUzbekistan: StyledSvg(Flags_FlagUzbekistan),
    // Flags_FlagVanuatu: StyledSvg(Flags_FlagVanuatu),
    // Flags_FlagVaticanCity: StyledSvg(Flags_FlagVaticanCity),
    // Flags_FlagVenezuela: StyledSvg(Flags_FlagVenezuela),
    // Flags_FlagVietnam: StyledSvg(Flags_FlagVietnam),
    // Flags_FlagWales: StyledSvg(Flags_FlagWales),
    // Flags_FlagWallisAmpFutuna: StyledSvg(Flags_FlagWallisAmpFutuna),
    // Flags_FlagWesternSahara: StyledSvg(Flags_FlagWesternSahara),
    // Flags_FlagYemen: StyledSvg(Flags_FlagYemen),
    // Flags_FlagZambia: StyledSvg(Flags_FlagZambia),
    // Flags_FlagZimbabwe: StyledSvg(Flags_FlagZimbabwe),
    // Flags_PirateFlag: StyledSvg(Flags_PirateFlag),
    // Flags_RainbowFlag: StyledSvg(Flags_RainbowFlag),
    // Flags_TriangularFlag: StyledSvg(Flags_TriangularFlag),
    // Flags_WhiteFlag: StyledSvg(Flags_WhiteFlag),
    Nav_Channels: StyledSvg(Nav_Channels),
    Nav_Chat: StyledSvg(Nav_Chat),
    Nav_Collections: StyledSvg(Nav_Collections),
    Nav_Darkmode: StyledSvg(Nav_Darkmode),
    Nav_Discover: StyledSvg(Nav_Discover),
    Nav_Filter: StyledSvg(Nav_Filter),
    Nav_History: StyledSvg(Nav_History),
    Nav_Home: StyledSvg(Nav_Home),
    Nav_LogOut: StyledSvg(Nav_LogOut),
    Nav_Minimalist: StyledSvg(Nav_Minimalist),
    Nav_Mygide: StyledSvg(Nav_Mygide),
    Nav_Navigide_Active: StyledSvg(Nav_Navigide_Active),
    Nav_Navigide_Deactive: StyledSvg(Nav_Navigide_Deactive),
    Nav_Notifications: StyledSvg(Nav_Notifications),
    Nav_Plus1: StyledSvg(Nav_Plus1),
    Nav_Plusicon: StyledSvg(Nav_Plusicon),
    Nav_Pointer: StyledSvg(Nav_Pointer),
    Nav_Publish: StyledSvg(Nav_Publish),
    Nav_Search: StyledSvg(Nav_Search),
    Nav_Settings: StyledSvg(Nav_Settings),
    Nav_Shortcuts: StyledSvg(Nav_Shortcuts),
    Nav_Sortandfilter: StyledSvg(Nav_Sortandfilter),
    Nav_Sort: StyledSvg(Nav_Sort),
    Nav_ThumbsUp: StyledSvg(Nav_ThumbsUp),
    Nav_Time: StyledSvg(Nav_Time),
    Nav_ViewQ: StyledSvg(Nav_ViewQ),
    Nav_Viewsettings_Active: StyledSvg(Nav_Viewsettings_Active),
    Nav_Viewsettings_Deactive: StyledSvg(Nav_Viewsettings_Deactive),
    Nav_Accordion_Colapsed: StyledSvg(Nav_Accordion_Colapsed),
    Nav_Accordion_Expanded: StyledSvg(Nav_Accordion_Expanded),
    Nav_Arrow_AddAbove: StyledSvg(Nav_Arrow_AddAbove),
    Nav_Arrow_AddBelow: StyledSvg(Nav_Arrow_AddBelow),
    Nav_Arrow_ArrowDown: StyledSvg(Nav_Arrow_ArrowDown),
    Nav_Arrow_ArrowLeftRight: StyledSvg(Nav_Arrow_ArrowLeftRight),
    Nav_Arrow_ArrowLeft: StyledSvg(Nav_Arrow_ArrowLeft),
    Nav_Arrow_ArrowRight: StyledSvg(Nav_Arrow_ArrowRight),
    Nav_Arrow_ArrowUpDown: StyledSvg(Nav_Arrow_ArrowUpDown),
    Nav_Arrow_ArrowUp: StyledSvg(Nav_Arrow_ArrowUp),
    Nav_Arrow_Reposition: StyledSvg(Nav_Arrow_Reposition),
    Nav_Channels_Add: StyledSvg(Nav_Channels_Add),
    Nav_Channels_Main: StyledSvg(Nav_Channels_Main),
    Nav_Display_Halfandhalf: StyledSvg(Nav_Display_Halfandhalf),
    Nav_Display_MaxWidth: StyledSvg(Nav_Display_MaxWidth),
    Nav_Display_Normal: StyledSvg(Nav_Display_Normal),
    Nav_Display_Scroll: StyledSvg(Nav_Display_Scroll),
    Nav_Display_Swipe: StyledSvg(Nav_Display_Swipe),
    Nav_Display_Thumbnail: StyledSvg(Nav_Display_Thumbnail),
    Nav_Display_Tool: StyledSvg(Nav_Display_Tool),
    Nav_Edit_EditAdvanced: StyledSvg(Nav_Edit_EditAdvanced),
    Nav_Edit_EditMain: StyledSvg(Nav_Edit_EditMain),
    Nav_EditMode_Active: StyledSvg(Nav_EditMode_Active),
    Nav_EditMode_Deactive: StyledSvg(Nav_EditMode_Deactive),
    Nav_EditMode_EditModeNumber: StyledSvg(Nav_EditMode_EditModeNumber),
    Nav_Exit_Alternative: StyledSvg(Nav_Exit_Alternative),
    Nav_Exit_Main: StyledSvg(Nav_Exit_Main),
    Nav_Eye_EyeClose: StyledSvg(Nav_Eye_EyeClose),
    Nav_Eye_EyeOpen: StyledSvg(Nav_Eye_EyeOpen),
    Nav_Fullscreen_FullscreenCollapsed: StyledSvg(Nav_Fullscreen_FullscreenCollapsed),
    Nav_Fullscreen_FullscreenExpanded: StyledSvg(Nav_Fullscreen_FullscreenExpanded),
    Nav_Hamburger_Closed: StyledSvg(Nav_Hamburger_Closed),
    Nav_Hamburger_Open: StyledSvg(Nav_Hamburger_Open),
    Nav_Logo_LogoIconMd: StyledSvg(Nav_Logo_LogoIconMd),
    Nav_Logo_LogoIconSmcopy: StyledSvg(Nav_Logo_LogoIconSmcopy),
    Nav_Logo_LogoIconSm: StyledSvg(Nav_Logo_LogoIconSm),
    Nav_Logo_LogoType: StyledSvg(Nav_Logo_LogoType),
    Nav_Logo_LogoType_Home: StyledSvg(Nav_Logo_LogoType_Home),
    Nav_Logo_LogoType_InGide: StyledSvg(Nav_Logo_LogoType_InGide),
    Nav_Logo_LogoColorEdit: StyledSvg(Nav_Logo_LogoColorEdit),
    Nav_Media_Audio_Volume_Loud: StyledSvg(Nav_Media_Audio_Volume_Loud),
    Nav_Media_Audio_Volume_Mute: StyledSvg(Nav_Media_Audio_Volume_Mute),
    Nav_Media_Audio_Volume_Quiet: StyledSvg(Nav_Media_Audio_Volume_Quiet),
    Nav_Media_Player_Pause: StyledSvg(Nav_Media_Player_Pause),
    Nav_Media_Player_Play: StyledSvg(Nav_Media_Player_Play),
    Nav_Media_Player_Record: StyledSvg(Nav_Media_Player_Record),
    Nav_Media_Player_Stop: StyledSvg(Nav_Media_Player_Stop),
    Nav_Media_Skip_SkipLeft: StyledSvg(Nav_Media_Skip_SkipLeft),
    Nav_Notifications_Active: StyledSvg(Nav_Notifications_Active),
    Nav_Notifications_Deactive: StyledSvg(Nav_Notifications_Deactive),
    Nav_Skip_SkipRight: StyledSvg(Nav_Skip_SkipRight),
    Nav_Stars_1: StyledSvg(Nav_Stars_1),
    Nav_Stars_2: StyledSvg(Nav_Stars_2),
    Nav_Stars_3: StyledSvg(Nav_Stars_3),
    Nav_Stars_4: StyledSvg(Nav_Stars_4),
    Nav_Stars_5: StyledSvg(Nav_Stars_5),
    Nav_Swipe_Overlay: StyledSvg(Nav_Swipe_Overlay),
    Nav_Text_Small: StyledSvg(Nav_Text_Small),
    Nav_TextLock_Locked: StyledSvg(Nav_TextLock_Locked),
    Nav_TextLock_Unlocked: StyledSvg(Nav_TextLock_Unlocked),
    Nav_Threedots_Horizontal: StyledSvg(Nav_Threedots_Horizontal),
    Nav_Threedots_Vertical: StyledSvg(Nav_Threedots_Vertical),
    Nav_Threesquare: StyledSvg(Nav_Threesquare),
    Nav_ViewbarExtension_Colapsed: StyledSvg(Nav_ViewbarExtension_Colapsed),
    Nav_ViewbarExtension_Expanded: StyledSvg(Nav_ViewbarExtension_Expanded),
    SlideType_Audio_AudioFile: StyledSvg(SlideType_Audio_AudioFile),
    SlideType_Audio_Main: StyledSvg(SlideType_Audio_Main),
    SlideType_Audio_Mic: StyledSvg(SlideType_Audio_Mic),
    SlideType_Audio_ProductIcon: StyledSvg(SlideType_Audio_ProductIcon),
    SlideType_Authorize_Main: StyledSvg(SlideType_Authorize_Main),
    SlideType_Authorize_ProductIcon: StyledSvg(SlideType_Authorize_ProductIcon),
    SlideType_Columns_Main: StyledSvg(SlideType_Columns_Main),
    SlideType_Columns_ProductIcon: StyledSvg(SlideType_Columns_ProductIcon),
    SlideType_Contact_Main: StyledSvg(SlideType_Contact_Main),
    SlideType_Contact_ProductIcon: StyledSvg(SlideType_Contact_ProductIcon),
    SlideType_Doodle_Main: StyledSvg(SlideType_Doodle_Main),
    SlideType_Doodle_ProductIcon: StyledSvg(SlideType_Doodle_ProductIcon),
    SlideType_Embed_Main: StyledSvg(SlideType_Embed_Main),
    SlideType_File_Maincopy: StyledSvg(SlideType_File_Maincopy),
    SlideType_File_Main: StyledSvg(SlideType_File_Main),
    SlideType_File_ProductIcon: StyledSvg(SlideType_File_ProductIcon),
    SlideType_Gallery_2Col: StyledSvg(SlideType_Gallery_2Col),
    SlideType_Gallery_3Col: StyledSvg(SlideType_Gallery_3Col),
    SlideType_Gallery_4Col: StyledSvg(SlideType_Gallery_4Col),
    SlideType_Gallery_Main: StyledSvg(SlideType_Gallery_Main),
    SlideType_Gallery_ProductIcon: StyledSvg(SlideType_Gallery_ProductIcon),
    // SlideType_Header_Ce: StyledSvg(SlideType_Header_Ce),
    // SlideType_Header_Co: StyledSvg(SlideType_Header_Co),
    ContentAlteration_RichText_Header_H: StyledSvg(ContentAlteration_RichText_Header_H),
    ContentAlteration_RichText_Header_H1: StyledSvg(ContentAlteration_RichText_Header_H1),
    ContentAlteration_RichText_Header_H2: StyledSvg(ContentAlteration_RichText_Header_H2),
    ContentAlteration_RichText_Header_H3: StyledSvg(ContentAlteration_RichText_Header_H3),
    ContentAlteration_RichText_Header_H4: StyledSvg(ContentAlteration_RichText_Header_H4),
    ContentAlteration_RichText_Header_H5: StyledSvg(ContentAlteration_RichText_Header_H5),
    ContentAlteration_RichText_Header_H6: StyledSvg(ContentAlteration_RichText_Header_H6),
    ContentAlteration_RichText_Header_End: StyledSvg(ContentAlteration_RichText_Header_End),
    ContentAlteration_RichText_Header_Title: StyledSvg(ContentAlteration_RichText_Header_Title),
    ContentAlteration_RichText_Header_Modal: StyledSvg(ContentAlteration_RichText_Header_Modal),
    // SlideType_Header_Hr: StyledSvg(SlideType_Header_Hr),
    // SlideType_Header_Main: StyledSvg(SlideType_Header_Main),
    // SlideType_Header_Ti: StyledSvg(SlideType_Header_Ti),
    // SlideType_Header_Vb: StyledSvg(SlideType_Header_Vb),
    SlideType_Image_Main: StyledSvg(SlideType_Image_Main),
    SlideType_Image_ProductIcon: StyledSvg(SlideType_Image_ProductIcon),
    SlideType_Input_Main: StyledSvg(SlideType_Input_Main),
    SlideType_Input_ProductIcon: StyledSvg(SlideType_Input_ProductIcon),
    SlideType_Insert_Main: StyledSvg(SlideType_Insert_Main),
    SlideType_Insert_ProductIcon: StyledSvg(SlideType_Insert_ProductIcon),
    SlideType_Link_Main: StyledSvg(SlideType_Link_Main),
    SlideType_Link_ProductIcon: StyledSvg(SlideType_Link_ProductIcon),
    SlideType_List_ProductIcon: StyledSvg(SlideType_List_ProductIcon),
    SlideType_Location_Findyourlocation: StyledSvg(SlideType_Location_Findyourlocation),
    SlideType_Location_Location: StyledSvg(SlideType_Location_Location),
    SlideType_Location_Main: StyledSvg(SlideType_Location_Main),
    SlideType_Location_ProductIcon: StyledSvg(SlideType_Location_ProductIcon),
    SlideType_Map_Main: StyledSvg(SlideType_Map_Main),
    SlideType_Map_ProductIcon: StyledSvg(SlideType_Map_ProductIcon),
    SlideType_Slideshow_Main: StyledSvg(SlideType_Slideshow_Main),
    SlideType_Slideshow_ProductIcon: StyledSvg(SlideType_Slideshow_ProductIcon),
    SlideType_Socialmedia_Main: StyledSvg(SlideType_Socialmedia_Main),
    SlideType_Socialmedia_ProductIcon: StyledSvg(SlideType_Socialmedia_ProductIcon),
    SlideType_Text_Large: StyledSvg(SlideType_Text_Large),
    ContentAlteration_RichText_Main: StyledSvg(ContentAlteration_RichText_Main),
    ContentAlteration_RichText_Header_Simple_Text: StyledSvg(ContentAlteration_RichText_Header_SimpleText),
    SlideType_Text_Main: StyledSvg(SlideType_Text_Main),
    SlideType_Text_ProductIcon: StyledSvg(SlideType_Text_ProductIcon),
    SlideType_Text_Small: StyledSvg(SlideType_Text_Small),
    SlideType_Video_Main: StyledSvg(SlideType_Video_Main),
    SlideType_Video_ProductIcon: StyledSvg(SlideType_Video_ProductIcon),
    Nav_Refresh: StyledSvg(Nav_Refresh),
  };


export default icons
