import React, { useState } from 'react';
import styles from './_gide-chooser.module.scss';
import { SearchCriteria } from '../../Header/GidesAppHeader/GidesAppHeader';
import SearchBarWithResults from '../SearchBarWithResults/SearchBarWithResults';
import GideListItemPreview, { ItemSelectionMode } from '../Previews/GidePreview/GideListItemPreview/GideListItemPreview';
import contains from 'ramda/es/contains';
import { RoundedCornerButton } from '../RoundedCornerButton/RoundedCornerButton';
import icons from '../../../assets/icons';
import { Agent } from '../../../models/Agent';
import { displayGideTitle } from '../../../utils/helperFunctions';
import { GideSearchElement } from '../GideElementSearch/GideElementSearch';

export interface GideChooserProps {
  agent: Agent;
  slideTypes?: string[];
  title?: string;
  subtitle?: string;
  onSelectGide?: (gideSummary: GideSearchElement) => void;
  onSelectMultipleGides?: (gideSummaries: GideSearchElement[]) => void;
  selectionMode: ItemSelectionMode;
}

export default function GideChooser(props: GideChooserProps) {
  const [gideSearchResults, updateGideSearchResults] = useState<GideSearchElement[]>([]);
  const [selectedGideIds, updateSelectedGideIds] = useState<string[]>([]);

  const onSelectGide = (gideSearchResult: GideSearchElement) => {
    if (contains(gideSearchResult.id, selectedGideIds)) {
      if(props.selectionMode === ItemSelectionMode.MultipleDisplayCheckBox) {
        updateSelectedGideIds(selectedGideIds.filter(g => g !== gideSearchResult.id));
      } else {
        updateSelectedGideIds([]);
      }
    } else {
      if(props.selectionMode === ItemSelectionMode.MultipleDisplayCheckBox) {
        updateSelectedGideIds([...selectedGideIds, gideSearchResult.id]);
      } else {
        updateSelectedGideIds([gideSearchResult.id]);
      }
    }
    props.onSelectGide && props.onSelectGide(gideSearchResult);
  };
  const onSearch = async (searchCriteria: SearchCriteria) => {
    searchCriteria.slideTypes =
      searchCriteria.slideTypes && props.slideTypes ? [...searchCriteria.slideTypes, ...props.slideTypes] : props.slideTypes;
    const gideSearchResults = await props.agent.Users.gideSearch(searchCriteria);
    updateGideSearchResults(gideSearchResults ? gideSearchResults : []);
  };

  const onClearSearch = () => {
    updateGideSearchResults([]);
  };
  return (
    <div className={styles.gideChooserContainer}>
      {props.title && <div className={styles.title}>{props.title}</div>}
      {props.subtitle && <div className={styles.subtitle}>{props.subtitle}</div>}
      <SearchBarWithResults searchPlaceHolder={'search for gides'} onSearch={onSearch} onClearSearch={onClearSearch}>
        {gideSearchResults.map((gide: GideSearchElement, index: number) => {
          return (
            <div className={styles.gideChooserItem} key={index}>
              <GideListItemPreview
                image={gide.imageUrl}
                title={displayGideTitle(gide.displayName, gide.type)}
                authorImage={gide.authorImageUrl}
                author={gide.authorUsername}
                authorCountry={gide.authorCountry}
                slideCount={gide.slidesLength ? gide.slidesLength : 0}
                createdAt={new Date(gide.createdAt)}
                updatedAt={new Date(gide.updatedAt)}
                onClick={() => {
                  onSelectGide(gide);
                }}
                selected={contains(gide.id, selectedGideIds)}
                selectionMode={props.selectionMode}
              />
            </div>
          );
        })}
        {gideSearchResults.length === 0 && <div>No Results</div>}
      </SearchBarWithResults>
      {props.onSelectMultipleGides && selectedGideIds.length > 0 && (
        <div className={styles.gideChooserSelectionActions}>
          <RoundedCornerButton
            label="Uncheck all"
            labelColor="var(--COLOR-SECONDARY-500)"
            style={{ width: '124px', backgroundColor: '#fafafa' }}
            icon={<icons.ContentAlteration_Checkbox_Uncheck color="var(--COLOR-SECONDARY-500)" />}
            iconCssClass="color-secondary-600-svg"
            fontCssClass="TEXTSUBTITLEwhitehigh-emphasisleft"
            imagePosition="right"
            onClick={() => {
              updateSelectedGideIds([]);
            }}
            disabled={selectedGideIds.length === 0}
          />
          <RoundedCornerButton
            label="Done"
            labelColor="var(--WHITES-NORMAL-1000)"
            style={{ width: '84px', marginLeft: '12px', backgroundColor: 'var(--COLOR-PRIMARY-600)' }}
            icon={<icons.ContentAlteration_Check_Main color="var(--WHITES-NORMAL-1000)" />}
            iconCssClass="whites-normal-1000-svg"
            fontCssClass="TEXTSUBTITLEwhitehigh-emphasisleft"
            imagePosition="right"
            onClick={() => {
              props.onSelectMultipleGides && props.onSelectMultipleGides(gideSearchResults.filter(sr => contains(sr.id, selectedGideIds)));
            }}
            disabled={selectedGideIds.length === 0}
          />
        </div>
      )}
    </div>
  );
}
