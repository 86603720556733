import React, { useState, useEffect } from 'react';
import SlideEditorModal, {
  SlideEditorProps,
  BackIconMode,
  SlideEditorModalProps,
  EditorState,
  ModalOverlayState,
  ProgressIndicatorType,
} from '../../modals/SlideEditorModal/SlideEditorModal';
import { useDispatch } from 'react-redux';
import { Slide, SlideUpdateInfo } from '../../../models/Slide';
import { ThemeProvider } from 'styled-components';
import { darkTheme, lightTheme } from '../../../themes/custom-theme';
import { CircleIconButton } from '../../Shared/CircleIconButton/CircleIconButton';
import { Dispatch } from 'redux';
import { GideAction_ADD_SLIDE, GideAction_MODAL_CLOSE, GideAction_SET_TOASTER_MESSAGE } from '../../../models/GideAction';
import { MODAL_CLOSE, SET_TOASTER_MESSAGE } from '../../../constants/actionTypes';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import ExpandingIconButton, { HorizontalAlignment } from '../../Shared/ExpandingIconButton/ExpandingIconButton';
import { assertNever } from '@uifabric/utilities';
import styles from './_slide-zero-editor.module.scss';
import classNames from 'classnames';
import SlideView from '../../Gide/SlideView/SlideView';
import { GideTitleSlideFormat } from '../../DataEntry/Pickers/Picker/Picker';
import PickerModal from '../../DataEntry/Pickers/Modals/PickerModal/PickerModal';
import { MediaChooser, MultiMediaType, MediaChooserAction } from '../../DataEntry/CreationProcess/MediaChooser/MediaChooser';
import { NotificationType } from '../../../constants/strings';
import icons from '../../../assets/icons';
import GideTitleFormatPicker from '../../DataEntry/Pickers/Modals/GideTitleFormatPicker/GideTitleFormatPicker';
import ImageEditor from '../../Viewers/ImageViewers/ImageEditor/ImageEditor';
import { ensureImageFileOrientation } from '../../../utils/helperFunctions';
import KeywordSelector from '../../DataEntry/Pickers/Modals/KeywordSelector/KeywordSelector';
import { uniq, reject } from 'ramda';
import SlideDimensionsPicker from '../../DataEntry/Pickers/Modals/SlideDimensionsPicker/SlideDimensionsPicker';
import { v4 } from 'uuid';
import { GideAction_ADD_TITLE_SLIDE_FOR_PROCESSING, ADD_TITLE_SLIDE_FOR_PROCESSING } from '../../../reducers/FileProcessing/fileProcessing.actions';
import { TitleSlideProcessorInfo } from '../../../reducers/FileProcessing/fileProcessing';
import { AnonymousUser } from '../../../models/User';
import produce from "immer"
import { maxMegabyteUploadSizeForImagesActual, maxMegabyteUploadSizeForImagesDisplay } from '../../../constants/limits';

export type SlideZeroEditorEvents = GideAction_ADD_SLIDE | GideAction_MODAL_CLOSE | GideAction_SET_TOASTER_MESSAGE | GideAction_ADD_TITLE_SLIDE_FOR_PROCESSING;

export type Mode = 'New'
| 'Photo' | 'Uploading' | 'CroppingImage'
| 'Edit' | 'Format' | 'Crop' | 'Delete'
| 'Dimensions' | 'Tag';

export interface SlideZeroEditorProps extends SlideEditorProps {
  refreshable?: { mode: Mode };
}

interface State {
  mode: Mode;
  slide: Slide;
  mediaChooserAction?: MediaChooserAction;
  tagList: string[];
  gideDescription?: string;
  gideDescriptionSlideId?: string;
}

interface DerivedState extends State {
  slideEditorModalProps: SlideEditorModalProps;
  content: React.ReactNode;
  theme: { mode: string };
}

const getHeaderTitleAndIcon = (title: string, icon: string, color: 'black' | 'white') => {
  return (
    <div className={`headerActionsFlexEnd TEXTSUBTITLE${color}high-emphasisleft`}>
      <div
        style={{
          marginRight: '6px',
        }}
      >
        {title}
      </div>
      <CircleIconButton
        width={30}
        height={30}
        alt={title}
        backgroundColor={color === 'white' ? 'var(--COLOR-BLUE-100)' : 'var(--COLOR-PRIMARY-600)'}
        iconCssClass="whites-normal-1000-svg"
        image={icon}
      />
    </div>
  );
};


const getSlide = (props: SlideZeroEditorProps) => {
  const slide: Slide = props.editSlide
    ? {
        ...props.editSlide,
        data: {
          ...props.editSlide.data,
          // caption: props.editSlide.data.title,
          files: [{}],
        },
      }
    : {
        id: '',
        author: {
          id: '',
          image: props.currentUser.image,
          username: props.currentUser.username,
          connections: {following: []},
          favorites: [],
          views: [],
          following: false,
          verified: false,
        },
        position: props.position,
        slideType: 'GIDETITLE',
        data: {
          title: props.article.title,
          caption: '', // props.article.title,
          image: props.article.image,
          files: [{}],
          slideTypeSpecificSettings: {
            format: GideTitleSlideFormat.Blog,
          },
        },
        width: "NORMAL",
        widthPercentage: undefined,
      };
  return slide;
};

const onAnswerQuestion = () => {};
const onCloseAllModals = () => {};
const onNavigateBack = () => {};
const onShowPreview = () => {};

const getSlideEditorModalProps = (context: Context): SlideEditorModalProps => {
  const { props, state, setState, closeModal, onSubmitSlide, showNotification, addTitleSlideProcessingInfo } = context;

  const common = {
    slide: context.state.slide,
    article: props.article,
    currentUser: props.currentUser,
    position: props.position,
    closeModal: closeModal,
    onSubmitSlide: onSubmitSlide,
    showNotification: showNotification,
    onAnswerQuestion: onAnswerQuestion,
    onCloseAllModals: onCloseAllModals,
    onNavigateBack: onNavigateBack,
    onShowPreview: onShowPreview,
    addTitleSlideProcessingInfo: addTitleSlideProcessingInfo,
    onSlideUpdated: (slide: Slide) => {
      setState({
        ...state,
        mode: state.mode,
        slide: {
          ...slide,
          data: {
            ...slide.data,
            //title: slide.data.caption as string,
          },
        },
      });
    },
    onDisplaySlideWidthSettings: () => {
      setState({ ...state, mode: 'Dimensions' });
    },
    updateGideDescription: (description: string) => {
      setState({
        ...state,
        gideDescription: !props.article.descriptionSlide || (props.article.descriptionSlide && description !== props.article.description) 
          ? description
          : props.article.description,
        gideDescriptionSlideId: props.article.descriptionSlide && description !== props.article.description
         ? undefined
         : props.article.descriptionSlide,
      })
    },
  };

  

  switch (state.mode) {
    case 'New':
      return {
        ...common,
        backIconMode: BackIconMode.Dark,
        hideFooter: true,
        hideCommandBar: false,
        displaySlideAttribution: false,
        question: undefined,
        editorState: EditorState.New,
        loadingInfo: undefined,
        modalOverlayState: ModalOverlayState.None,
        selectedItemIndex: -1,
        hideActionContainer: true,
        showBackNavigation: false,
        showDefaultActions: false,
        showDeleteFile: false,
        createActions: [] as any,
        tagList: state.tagList,
        gideDescription: state.gideDescription,
        gideDescriptionSlideId: state.gideDescriptionSlideId,
        isSlideZero: true,
        headerActions: getHeaderTitleAndIcon('Create your title slide', '/icons/slidetype/image/main.svg', 'white'),
        onNavigateBack: () => {
          closeModal();
        },
      };
    case 'Photo':
      return {
        ...common,
        backIconMode: BackIconMode.Dark,
        hideFooter: true,
        hideCommandBar: false,
        displaySlideAttribution: false,
        question: undefined,
        editorState: EditorState.New,
        loadingInfo: undefined,
        modalOverlayState: ModalOverlayState.None,
        selectedItemIndex: -1,
        hideActionContainer: true,
        showBackNavigation: true,
        showDefaultActions: false,
        showDeleteFile: false,
        createActions: [] as any,
        tagList: state.tagList,
        headerActions: getHeaderTitleAndIcon('Take a photo', '/icons/content-alteration/camera/default.svg', 'white'),
        onNavigateBack: () => {
          setState({ ...state, mode: 'New', mediaChooserAction: { type: 'StopCamera' } });
        },
      };
    case 'Uploading':
      return {
        ...common,
        backIconMode: BackIconMode.Dark,
        hideFooter: true,
        hideCommandBar: false,
        displaySlideAttribution: false,
        question: undefined,
        editorState: EditorState.New,
        loadingInfo: {
          message: 'Uploading image',
          progressIndicatorType: ProgressIndicatorType.Indeterminate,
        },
        modalOverlayState: ModalOverlayState.Header,
        selectedItemIndex: -1,
        hideActionContainer: true,
        showBackNavigation: true,
        showDefaultActions: false,
        showDeleteFile: false,
        createActions: [] as any,
        tagList: state.tagList,
        headerActions: getHeaderTitleAndIcon('Uploading your image', '/icons/slidetype/image/main.svg', 'white'),
        onNavigateBack: () => {
          setState({ ...state, mode: 'New' });
        },
      };
    case 'Edit':
      return {
        ...common,
        backIconMode: BackIconMode.Light,
        hideFooter: false,
        hideCommandBar: false,
        displaySlideAttribution: false,
        question: undefined,
        editorState: EditorState.Edit,
        loadingInfo: undefined,
        modalOverlayState: ModalOverlayState.None,
        selectedItemIndex: -1,
        hideActionContainer: true,
        showBackNavigation: false,
        showDefaultActions: true,
        showDeleteFile: false,
        createActions: [] as any,
        tagList: state.tagList,
        gideDescription: state.gideDescription,
        gideDescriptionSlideId: state.gideDescriptionSlideId,
        isSlideZero: true,
        onHashtagClicked: () => {
          setState({...state, mode: 'Tag'});
        },
        headerActions: (
          <>
            <div className="headerActionsFlexEnd">
              <ExpandingIconButton
                style={{
                  marginLeft: '9px',
                  marginRight: '5px',
                  paddingLeft: '9px',
                  height: '32px',
                  backgroundImage: 'linear-gradient(290deg, #faa114, #fab317)',
                }}
                iconCssClass="color-secondary-300-svg"
                expandedIconCssClass={classNames(styles.formatIcon, 'whites-normal-1000-svg')}
                circleIconButtonCssClass={styles.formatCircleIconButtonCssClass}
                iconBackgroundColor="transparent"
                alt="sticker"
                src="/icons/slidetype/image/main.svg"
                expandedSrc="/icons/slidetype/image/main.svg"
                expandWidth={108}
                label="Format"
                onClick={e => {
                  setState({ ...state,mode: 'Format', slide: context.state.slide });
                }}
                labelLocation={HorizontalAlignment.Left}
                expanded={true}
                visible={true}
              />
              <div className="slideSettingsSeparator"></div>
              <ExpandingIconButton
                style={{
                  marginLeft: '9px',
                  height: '32px',
                }}
                alt="Crop"
                src="/icons/creationprocess/cropandrotate/crop.svg"
                expandWidth={125}
                label="crop image"
                onClick={e => {
                  setState({ ...state, mode: 'Crop', slide: context.state.slide });
                }}
                labelLocation={HorizontalAlignment.Left}
                expanded={false}
                visible={true}
              />
              <ExpandingIconButton
                style={{
                  marginLeft: '12px',
                  marginRight: '7px',
                  height: '32px',
                }}
                alt="Delete"
                src="/icons/content-alteration/delete.svg"
                expandWidth={80}
                label="Delete"
                onClick={e => {
                  setState({ ...state,mode: 'Delete', slide: context.state.slide });
                }}
                labelLocation={HorizontalAlignment.Left}
                expanded={false}
                visible={true}
              />
            </div>
          </>
        ),
        onNavigateBack: () => {
          setState({ ...state, mode: 'New' });
        },
      };
    case 'Format':
      return {
        ...common,
        backIconMode: BackIconMode.Light,
        hideFooter: false,
        hideCommandBar: false,
        displaySlideAttribution: false,
        question: undefined,
        editorState: EditorState.Edit,
        loadingInfo: undefined,
        modalOverlayState: ModalOverlayState.None,
        selectedItemIndex: -1,
        hideActionContainer: true,
        showBackNavigation: true,
        showDefaultActions: false,
        showDeleteFile: false,
        createActions: [] as any,
        tagList: state.tagList,
        headerActions: getHeaderTitleAndIcon('Format', '/icons/creationprocess/bloglist/image-top.svg', 'black'),
        onNavigateBack: () => {
          setState({ ...state,mode: 'Edit', slide: context.state.slide });
        },
      };
    case 'Delete':
      return {
        ...common,
        backIconMode: BackIconMode.Light,
        hideFooter: false,
        hideCommandBar: false,
        displaySlideAttribution: false,
        question: undefined,
        editorState: EditorState.Edit,
        loadingInfo: undefined,
        modalOverlayState: ModalOverlayState.None,
        selectedItemIndex: -1,
        hideActionContainer: true,
        showBackNavigation: true,
        showDefaultActions: false,
        showDeleteFile: false,
        createActions: [] as any,
        tagList: state.tagList,
        headerActions: getHeaderTitleAndIcon('Delete', '/icons/content-alteration/delete.svg', 'black'),
        onNavigateBack: () => {
          setState({ ...state, mode: 'Edit', slide: context.state.slide });
        },
      };
    case 'Tag':
        return {
          ...common,
          backIconMode: BackIconMode.Light,
          hideFooter: false,
          hideCommandBar: false,
          displaySlideAttribution: false,
          question: undefined,
          editorState: EditorState.Edit,
          loadingInfo: undefined,
          modalOverlayState: ModalOverlayState.All,
          selectedItemIndex: -1,
          hideActionContainer: true,
          showBackNavigation: false,
          showDefaultActions: true,
          showDeleteFile: true,
          createActions: [] as any,
          tagList: state.tagList,
          gideDescription: state.gideDescription,
          gideDescriptionSlideId: state.gideDescriptionSlideId,
          isSlideZero: true,
          onHashtagClicked: () => {
            setState({...state, mode: 'Tag'});
          },
          headerActions: (
            <>
              <div className="headerActionsFlexEnd">
                <ExpandingIconButton
                  style={{
                    marginLeft: '9px',
                    marginRight: '5px',
                    paddingLeft: '9px',
                    height: '32px',
                    backgroundImage: 'linear-gradient(290deg, #faa114, #fab317)',
                  }}
                  iconCssClass="color-secondary-300-svg"
                  expandedIconCssClass={classNames(styles.formatIcon, 'whites-normal-1000-svg')}
                  circleIconButtonCssClass={styles.formatCircleIconButtonCssClass}
                  iconBackgroundColor="transparent"
                  alt="sticker"
                  src="/icons/slidetype/image/main.svg"
                  expandedSrc="/icons/slidetype/image/main.svg"
                  expandWidth={108}
                  label="Format"
                  onClick={e => {
                    setState({ ...state, mode: 'Format', slide: context.state.slide });
                  }}
                  labelLocation={HorizontalAlignment.Left}
                  expanded={true}
                  visible={true}
                />
                <div className="slideSettingsSeparator"></div>
                <ExpandingIconButton
                  style={{
                    marginLeft: '9px',
                    height: '32px',
                  }}
                  alt="Crop"
                  src="/icons/creationprocess/cropandrotate/crop.svg"
                  expandWidth={125}
                  label="crop image"
                  onClick={e => {
                    setState({ ...state, mode: 'Crop', slide: context.state.slide });
                  }}
                  labelLocation={HorizontalAlignment.Left}
                  expanded={false}
                  visible={true}
                />
                <ExpandingIconButton
                  style={{
                    marginLeft: '12px',
                    marginRight: '7px',
                    height: '32px',
                  }}
                  alt="Delete"
                  src="/icons/content-alteration/delete.svg"
                  expandWidth={80}
                  label="Delete"
                  onClick={e => {
                    setState({ ...state, mode: 'Delete', slide: context.state.slide });
                  }}
                  labelLocation={HorizontalAlignment.Left}
                  expanded={false}
                  visible={true}
                />
              </div>
            </>
          ),
          onNavigateBack: () => {
            setState({ ...state, mode: 'New' });
          },
        };
    case 'CroppingImage':
      return {
        ...common,
        backIconMode: BackIconMode.Dark,
        hideFooter: true,
        hideCommandBar: false,
        displaySlideAttribution: false,
        question: undefined,
        editorState: EditorState.Edit,
        loadingInfo: {
          message: 'Saving image edits',
          progressIndicatorType: ProgressIndicatorType.Indeterminate,
        },
        modalOverlayState: ModalOverlayState.None,
        selectedItemIndex: -1,
        hideActionContainer: true,
        showBackNavigation: true,
        showDefaultActions: false,
        showDeleteFile: false,
        createActions: [] as any,
        tagList: state.tagList,
        headerActions: getHeaderTitleAndIcon(
          'Crop your image to the tile size',
          '/icons/creationprocess/cropandrotate/cropandrotate.svg',
          'white',
        ),
        onNavigateBack: () => {
          setState({ ...state, mode: 'Edit', slide: context.state.slide });
        },
      };
    case 'Crop':
      return {
        ...common,
        backIconMode: BackIconMode.Dark,
        hideFooter: true,
        hideCommandBar: false,
        displaySlideAttribution: false,
        question: undefined,
        editorState: EditorState.Edit,
        loadingInfo: undefined,
        modalOverlayState: ModalOverlayState.None,
        selectedItemIndex: -1,
        hideActionContainer: true,
        showBackNavigation: true,
        showDefaultActions: false,
        showDeleteFile: false,
        createActions: [] as any,
        tagList: state.tagList,
        headerActions: getHeaderTitleAndIcon(
          'Crop your image to the tile size',
          '/icons/creationprocess/cropandrotate/cropandrotate.svg',
          'white',
        ),
        onNavigateBack: () => {
          setState({...state, mode: 'Edit', slide: context.state.slide });
        },
      };
    case 'Dimensions':
      return {
        ...common,
        backIconMode: BackIconMode.Light,
        hideFooter: false,
        hideCommandBar: false,
        displaySlideAttribution: false,
        question: undefined,
        editorState: EditorState.Edit,
        loadingInfo: undefined,
        modalOverlayState: ModalOverlayState.None,
        selectedItemIndex: -1,
        hideActionContainer: true,
        showBackNavigation: true,
        showDefaultActions: false,
        showDeleteFile: false,
        createActions: [] as any,
        tagList: state.tagList,
        gideDescription: state.gideDescription,
        gideDescriptionSlideId: state.gideDescriptionSlideId,
        isSlideZero: true,
        headerActions: getHeaderTitleAndIcon('Slide Dimensions', '/icons/nav/display/normal.svg', 'black'),
        onNavigateBack: () => {
          setState({ ...state, mode: 'Edit', slide: context.state.slide });
        },
      };
    default:
      return assertNever(state.mode);
  }
};

const getContent = (context: Context, slideEditorModalProps: SlideEditorModalProps): React.ReactNode => {
  const { state, setState, showNotification } = context;
  const article = context.props.article;
  const onModifiedTitle = (newTitle: string) => {
    setState(produce((state: State) => {
      state.slide.data.title = newTitle;
    }));
  };

  const slideView = (
    <div
      className={classNames(styles.slideZeroSlideView, 'website')}
      style={{
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 102px)',
      }}
    >
      <SlideView
        article={{
          ...article,
          tagList: state.tagList
        }}
        slide={state.slide as Slide}
        slidePosition={(slideEditorModalProps.slide as Slide).position}
        currentUser={AnonymousUser}
        viewMode={'SLIDE'}
        viewOnly={true}
        view={'SlideZeroEditor'}
        disableExpiration={true}
        ignoreTheme={true}
        onModifiedTitle={onModifiedTitle}
      />
    </div>
  );

  switch (state.mode) {
    case 'New':
    case 'Photo':
    case 'Uploading':
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <MediaChooser
            action={state.mediaChooserAction}
            style={{
              maxWidth: '768px',
            }}
            maxUploadSizeActual={maxMegabyteUploadSizeForImagesActual}
            maxUploadSizeDisplay={maxMegabyteUploadSizeForImagesDisplay}
            onSavingMedia={() => {
              setState({
                ...state,
                mode: 'Uploading',
                slide: state.slide,
              });
            }}
            mediaType={MultiMediaType.Image}
            onCameraStarted={() => {
              setState({
                ...state,
                mode: 'Photo',
                slide: state.slide,
              });
            }}
            onMediaCreated={mediaUrls => {
              setState({
                ...state,
                mode: 'Edit',
                slide: {
                  ...state.slide,
                  data: {
                    ...state.slide.data,
                    image: URL.createObjectURL(mediaUrls[0]),
                    processingId: v4(),
                    dataUrl: mediaUrls[0],
                  },
                },
              });
            }}
            onError={(error: string) => showNotification({ message: error, type: NotificationType.ERROR })}
          />
        </div>
      );
    case 'Edit':
      return slideView;
    case 'Format':
      return (
        <div style={{ position: 'relative' }}>
          <div>
            <GideTitleFormatPicker
              className={styles.formatModal}
              format={state.slide.data.slideTypeSpecificSettings && state.slide.data.slideTypeSpecificSettings.format}
              onCancel={(format: GideTitleSlideFormat) => {
                setState({
                  ...state,
                  slide: {
                    ...state.slide,
                    data: {
                      ...state.slide.data,
                      slideTypeSpecificSettings: {
                        ...state.slide.data.slideTypeSpecificSettings,
                        format: format,
                      },
                    },
                  },
                  mode: 'Edit',
                });
              }}
              onChange={(format: GideTitleSlideFormat) => {
                setState({
                  ...state,
                  slide: {
                    ...state.slide,
                    data: {
                      ...state.slide.data,
                      slideTypeSpecificSettings: {
                        ...state.slide.data.slideTypeSpecificSettings,
                        format: format,
                      },
                    },
                  },
                  mode: state.mode,
                });
              }}
              onSave={(format: GideTitleSlideFormat) => {
                setState({
                  ...state,
                  slide: state.slide,
                  mode: 'Edit',
                });
              }}
            />
          </div>
          {slideView}
        </div>
      );
    case 'CroppingImage':
    case 'Crop':
      return (
      <div style={{display: 'flex', flex: 1, maxWidth: '100%',  height: '100%', flexDirection: 'row'}}>
        <ImageEditor
          image={{url: state.slide.data.image, type: 'SlideZero'}}
          saveImage={(image: any) => {
            setState({
              ...state,
              mode: 'CroppingImage'
            });
            // catch the returned data and add to list. will need to save it
            ensureImageFileOrientation(image).then(imageFile => {
              const croppedSlide = {
                ...state.slide,
                data: {
                  ...state.slide.data,
                  image: URL.createObjectURL(imageFile),
                  processingId: v4(),
                  dataUrl: imageFile,
                },
              };
              setState({
                ...state,
                slide: croppedSlide,
                mode: 'Edit',
              });
            });
          }}
          responseType='base64'
          cropperOptions={{
            guides : true,
            autoCropArea: 1,
            rotatable: true,
            aspectRatio: 1.5
          }}
        />
      </div>
      );
    case 'Delete':
      return (
        <div style={{ position: 'relative' }}>
          <PickerModal
            className={styles.deleteModal}
            onSave={() => {
              setState({
                ...state,
                mode: 'New',
                slide: state.slide,
              });
            }}
            onCancel={() => {
              setState({ ...state, mode: 'Edit', slide: state.slide });
            }}
            saveLabel="Delete"
            saveIcon={icons.ContentAlteration_Delete}
          >
            <div className={styles.deleteMessage}>Are you sure you want to delete this image?</div>
          </PickerModal>
          {slideView}
        </div>
      );
    case 'Tag':
        return (<>
          <div style={{ position: 'relative', justifyContent: 'center',  display: 'flex' }}>
            <KeywordSelector
              style={{position: 'absolute', top: '20px', maxWidth: '100vw', width: '360px', backgroundColor: 'white', zIndex: 2001}}
              onCancel={(orignalKeywords: string[]) => {
                setState({
                  ...state,
                  tagList: [...orignalKeywords],
                  mode: 'Edit'
                });
              }}
              onAddKeywords={(keywords: string[]) => {
                const updatedKeywords = [...state.tagList];
                keywords.forEach(kw => {
                  updatedKeywords.push(kw);
                })
                setState({
                  ...state,
                  tagList: uniq(updatedKeywords)
                });
              }}
              onRemoveKeyword={(keyword: string) => {
                setState({
                  ...state,
                  tagList: reject(kw => kw === keyword, state.tagList)
                });
              }}
              onSave={(keywords: string[]) => {
                setState({
                  ...state,
                  tagList: [...keywords],
                  mode: 'Edit'
                })
              }}
              keywords={state.tagList}
            />
        </div>
        {slideView}
    </>);
    case 'Dimensions':
      return (
        <div style={{ position: 'relative' }}>
          <div>
            <SlideDimensionsPicker
              className={styles.dimensionsModal}
              onSave={() => {
                setState({ mode: 'Edit', slide: state.slide, tagList: state.tagList, gideDescription: state.gideDescription, gideDescriptionSlideId: state.gideDescriptionSlideId });
              }}
              onCancel={(slideWidthType, slideAlignment, slideWidthPercentage) => {
                setState({
                  ...state,
                  mode: 'Edit',
                  slide: {
                    ...state.slide,
                    width: slideWidthType,
                    contentHorizontalAlignment: slideAlignment,
                    widthPercentage: slideWidthPercentage,
                  },
                });
              }}
              onChange={(slideWidthType, slideAlignment, slideWidthPercentage) => {
                setState({
                  ...state,
                  mode: state.mode,
                  slide: {
                    ...state.slide,
                    width: slideWidthType,
                    contentHorizontalAlignment: slideAlignment,
                    widthPercentage: slideWidthPercentage,
                  },
                });
              }}
              slideWidthType={state.slide.width || 'NORMAL'}
              slideAlignment={state.slide.contentHorizontalAlignment || 'CENTER'}
              slideWidthPercentage={state.slide.widthPercentage || 100}
            />
          </div>
          {slideView}
        </div>
      );
    default:
      return assertNever(state.mode);
  }
};

const getDerivedState = (context: Context): DerivedState => {
  const slideEditorModalProps = getSlideEditorModalProps(context);
  return {
    ...context.state,
    slideEditorModalProps: slideEditorModalProps,
    content: getContent(context, slideEditorModalProps),
    theme:
      context.state.mode === 'New'
        || context.state.mode === 'Photo'
        || context.state.mode === 'Uploading'
        || context.state.mode === 'Crop'
        || context.state.mode === 'CroppingImage'
        ? darkTheme
        : lightTheme,
  };
};

interface Context {
  props: SlideZeroEditorProps;
  state: State;
  setState: (value: React.SetStateAction<State>) => void;
  onSubmitSlide: (payload: SlideUpdateInfo) => Promise<void>;
  closeModal: () => void;
  showNotification: (toasterMessageInfo: ToasterMessageInfo) => void;
  addTitleSlideProcessingInfo: (titleSlideProcessorInfo: TitleSlideProcessorInfo[]) => void;
}

export const SlideZeroEditor: React.FC<SlideZeroEditorProps> = props => {
  const [state, setState] = useState<State>(() => {
    const slide = getSlide(props);
    const mode: Mode =
        props.refreshable && props.refreshable.mode ? props.refreshable.mode
        : slide.data && slide.data.image ? "Edit"
        : "New";
    return { mode, slide, tagList: [...props.article.tagList], gideDescription: props.article.description, gideDescriptionSlideId: props.article.descriptionSlide};
  });
  useEffect(() => {
    setState({
        mode: (props.refreshable && props.refreshable.mode) || state.mode,
        slide: state.slide,
        tagList: state.tagList,
        gideDescription: state.gideDescription,
        gideDescriptionSlideId: state.gideDescriptionSlideId,
    });
  }, [props.refreshable && props.refreshable.mode]);
  const dispatch = useDispatch<Dispatch<SlideZeroEditorEvents>>();
  const closeModal = () => dispatch({ type: MODAL_CLOSE });
  const addTitleSlideProcessingInfo = (titleSlideProcessorInfo: TitleSlideProcessorInfo[]) =>
    dispatch({type: ADD_TITLE_SLIDE_FOR_PROCESSING, payload: {titleSlideProcessorInfo}});
  const showNotification =
    props.showNotification ||
    ((toasterMessageInfo: ToasterMessageInfo) => dispatch({ type: SET_TOASTER_MESSAGE, payload: { toasterMessageInfo } }));
  const onSubmitSlide = props.onSubmitSlide;
  const context: Context = {
    props,
    state,
    setState,
    onSubmitSlide,
    closeModal,
    showNotification,
    addTitleSlideProcessingInfo
  };

  const derivedState = getDerivedState(context);

  const validationError = !state.slide.data.title ? 'Please enter a title.' : undefined;

  return (
    <ThemeProvider theme={derivedState.theme}>
      <SlideEditorModal {...derivedState.slideEditorModalProps} validationError={validationError}>
        {derivedState.content}
      </SlideEditorModal>      
    </ThemeProvider>
  );
};

export default SlideZeroEditor;
