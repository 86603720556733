import React, { Component } from 'react';
import { SlideItemSelectionSettings, SlideItemSelectionActionType } from '../../../models/SlideFile';
import { RoundedCornerButton } from '../../Shared/RoundedCornerButton/RoundedCornerButton';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import { User } from '../../../models/User';
import SlideChooser, { SlideSelection } from '../../Shared/SlideChooser/SlideChooser';
import icons from '../../../assets/icons';
import agent from '../../../agent';
import GideChooser from '../../Shared/GideChooser/GideChooser';
import { ItemSelectionMode } from '../../Shared/Previews/GidePreview/GideListItemPreview/GideListItemPreview';
import { GideSearchElement } from '../../Shared/GideElementSearch/GideElementSearch';
export interface SlideItemTouchSettingsProps {
  fileType: string;
  slideItemSelectionSettings: SlideItemSelectionSettings;
  currentUser: User;
  onFinish: (slideItemSelectionSettings: SlideItemSelectionSettings) => void;
  onChange: (slideItemSelectionSettings: SlideItemSelectionSettings) => void;
  onMessage: (message: string) => void;
}

export interface SlideItemTouchSettingsState {
  slideItemSelectionActionType: SlideItemSelectionActionType;
  // The following should only have a value
  // Can be one of 3 things (slideId, articleId, or exact url)
  linkItemType?: string;
  linkItemReference?: string;
  openLinkInNewWindow: boolean;
  displaySlideChooser: boolean;
  displayGideChooser: boolean;
}

export default class SlideItemTouchSettings extends Component<SlideItemTouchSettingsProps, SlideItemTouchSettingsState> {
  constructor(props: SlideItemTouchSettingsProps) {
    super(props);

    this.state = {
      ...this.props.slideItemSelectionSettings,
      displaySlideChooser: false,
      displayGideChooser: false,
      openLinkInNewWindow: true,
    };
  }

  public render() {
    return (
      <div
        className={`slideItemTouchSettingsContainer${
          this.state.displayGideChooser || this.state.displaySlideChooser ? ' allowfullScreen' : ''
        }`}
        onClick={e => e.stopPropagation()}
      >
        {!this.state.displaySlideChooser &&
          !this.state.displayGideChooser && (
            <div className="slideItemTouchSettings">
              <div className="touchActions">
                <div className="title">
                  {`When someone clicks on your ${this.props.fileType}, what do you want it to do?`}
                </div>
                {/* <div className={pickerModalStyles.horizontalLine} style={{marginBottom: '10px'}} /> */}
                <div className="row1">
                  <RoundedCornerButton
                    label="Full Screen"
                    labelColor={`${
                      this.state.slideItemSelectionActionType === SlideItemSelectionActionType.OpenModal
                        ? 'rgba(255, 255, 255, 0.95)'
                        : '#cccccc'
                    }`}
                    style={{
                      width: '125px',
                      backgroundColor: `${
                        this.state.slideItemSelectionActionType === SlideItemSelectionActionType.OpenModal ? '#ffb213' : '#fafafa'
                      }`,
                    }}
                    alt="Full Screen"
                    className={`${this.state.slideItemSelectionActionType === SlideItemSelectionActionType.OpenModal ? 'selected' : ''}`}
                    onClick={() => {
                      this.setState({ slideItemSelectionActionType: SlideItemSelectionActionType.OpenModal }, () => {
                        this.props.onChange({
                          linkItemReference: undefined,
                          linkItemType: undefined,
                          openLinkInNewWindow: undefined,
                          slideItemSelectionActionType: this.state.slideItemSelectionActionType,
                        });
                      });
                    }}
                    icon={<icons.Nav_Display_MaxWidth color={this.state.slideItemSelectionActionType === SlideItemSelectionActionType.OpenModal ? 'var(--WHITES-NORMAL-1000)' : 'var(--COLOR-SECONDARY-500)'} />}
                    imagePosition='right'
                  />
                  <RoundedCornerButton
                    label="Open URL"
                    labelColor={`${
                      this.state.slideItemSelectionActionType === SlideItemSelectionActionType.OpenLink
                        ? 'rgba(255, 255, 255, 0.95)'
                        : '#cccccc'
                    }`}
                    style={{ width: '125px', backgroundColor: `${
                      this.state.slideItemSelectionActionType === SlideItemSelectionActionType.OpenLink ? '#ffb213' : '#fafafa'
                    }` }}
                    alt="Open Url"
                    onClick={() => {
                      this.setState({ slideItemSelectionActionType: SlideItemSelectionActionType.OpenLink, linkItemType: 'URL' }, () => {
                        this.props.onChange({
                          linkItemReference: undefined,
                          linkItemType: undefined,
                          openLinkInNewWindow: true,
                          slideItemSelectionActionType: this.state.slideItemSelectionActionType,
                        });
                      });
                    }}
                    icon={<icons.SlideType_Link_Main color={this.state.slideItemSelectionActionType === SlideItemSelectionActionType.OpenLink ? 'var(--WHITES-NORMAL-1000)' : 'var(--COLOR-SECONDARY-500)'} />}
                    imagePosition='right'
                  />
                </div>
                <div className="row2">
                  <RoundedCornerButton
                    label="Slide show"
                    labelColor={`${
                      this.state.slideItemSelectionActionType === SlideItemSelectionActionType.SwipeView
                        ? 'rgba(255, 255, 255, 0.95)'
                        : '#cccccc'
                    }`}
                    style={{
                      width: '125px',
                      backgroundColor: `${
                        this.state.slideItemSelectionActionType === SlideItemSelectionActionType.SwipeView ? '#ffb213' : '#fafafa'
                      }`,
                    }}
                    alt="Slide show"
                    className={`${this.state.slideItemSelectionActionType === SlideItemSelectionActionType.SwipeView ? 'selected' : ''}`}
                    onClick={() => {
                      this.setState({ slideItemSelectionActionType: SlideItemSelectionActionType.SwipeView }, () => {
                        this.props.onChange({
                          linkItemReference: undefined,
                          linkItemType: undefined,
                          openLinkInNewWindow: undefined,
                          slideItemSelectionActionType: this.state.slideItemSelectionActionType,
                        });
                      });
                    }}
                    icon={<icons.SlideType_Slideshow_Main color={this.state.slideItemSelectionActionType === SlideItemSelectionActionType.SwipeView ? 'var(--WHITES-NORMAL-1000)' : 'var(--COLOR-SECONDARY-500)'} />}
                    imagePosition='right'
                  />
                  <RoundedCornerButton
                    label="Do nothing"
                    labelColor={`${
                      this.state.slideItemSelectionActionType === SlideItemSelectionActionType.DoNothing
                        ? 'rgba(255, 255, 255, 0.95)'
                        : '#cccccc'
                    }`}
                    style={{ width: '125px', backgroundColor: `${
                      this.state.slideItemSelectionActionType === SlideItemSelectionActionType.DoNothing ? '#ffb213' : '#fafafa'
                    }` }}
                    alt="Do nothing"
                    onClick={() => {
                      this.setState({ slideItemSelectionActionType: SlideItemSelectionActionType.DoNothing }, () => {
                        this.props.onChange({
                          linkItemReference: undefined,
                          linkItemType: undefined,
                          openLinkInNewWindow: undefined,
                          slideItemSelectionActionType: this.state.slideItemSelectionActionType,
                        });
                      });
                    }}
                    icon={<icons.Nav_Minimalist color={this.state.slideItemSelectionActionType === SlideItemSelectionActionType.DoNothing ? 'var(--WHITES-NORMAL-1000)' : 'var(--COLOR-SECONDARY-500)'} />}
                    imagePosition='right'
                  />
                </div>
                {/* {this.state.slideItemSelectionActionType === SlideItemSelectionActionType.OpenLink && (
                  <>
                    <div className="linkOptions">
                      <RadioButtonComponent
                        checked={this.state.linkItemType === 'URL'}
                        label="Url"
                        name="selectionType"
                        value="URL"
                        change={() =>
                          this.setState({
                            linkItemType: 'URL',
                          })
                        }
                      />
                      <RadioButtonComponent
                        checked={this.state.linkItemType === 'SLIDE'}
                        label="Slide"
                        name="selectionType"
                        value="SLIDE"
                        change={() =>
                          this.setState({
                            linkItemType: 'SLIDE',
                          })
                        }
                      />
                      <RadioButtonComponent
                        checked={this.state.linkItemType === 'ARTICLE'}
                        label="Gide"
                        name="selectionType"
                        value="ARTICLE"
                        change={() =>
                          this.setState({
                            linkItemType: 'ARTICLE',
                          })
                        }
                      />
                    </div>
                    <div className="linkActions">
                      {this.state.linkItemType === 'URL' && (
                        <Input
                          style={{ width: '100%' }}
                          placeholder="e.g. https://www.google.com"
                          value={this.state.linkItemReference}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            this.setState({ linkItemReference: e.target.value });
                          }}
                        />
                      )}
                      {this.state.linkItemType === 'SLIDE' && (
                        <RoundedCornerButton
                          style={{ marginLeft: '20px', marginRight: '20px', backgroundColor: 'rgba(0,0,0,.8)' }}
                          label="choose slide"
                          labelColor="rgba(255, 255, 255, 0.95)"
                          alt="apply changes"
                          onClick={() => {
                            this.setState({ displaySlideChooser: true });
                          }}
                        />
                      )}
                      {this.state.linkItemType === 'ARTICLE' && (
                        <RoundedCornerButton
                          style={{ marginLeft: '20px', marginRight: '20px', backgroundColor: 'rgba(0,0,0,.8)' }}
                          label="choose article"
                          labelColor="rgba(255, 255, 255, 0.95)"
                          alt="apply changes"
                          onClick={() => {
                            this.setState({ displayGideChooser: true });
                          }}
                        />
                      )}
                    </div>
                    <div className="openUrlLocation">
                      <Checkbox
                        onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                          this.setState({ openLinkInNewWindow: data.checked === true });
                        }}
                        toggle
                        checked={this.state.openLinkInNewWindow}
                        label="Open in new window"
                      />
                    </div>
                    <div className="finishLinkActions">
                      <RoundedCornerButton
                        label="Finish"
                        image="/icons/content-alteration/check/main.svg"
                        labelColor="rgba(255, 255, 255, 0.95)"
                        imagePosition="right"
                        style={{ margin: '5px', width: '106px', backgroundColor: '#000' }}
                        alt="apply changes"
                        onClick={() => {
                          if (hasValue(this.state.linkItemType) && hasValue(this.state.linkItemReference)) {
                            this.props.onFinish({
                              linkItemReference: this.state.linkItemReference,
                              linkItemType: this.state.linkItemType,
                              openLinkInNewWindow: this.state.openLinkInNewWindow,
                              slideItemSelectionActionType: this.state.slideItemSelectionActionType,
                            });
                          } else {
                            const message =
                              this.state.linkItemType === 'URL'
                                ? 'URL was entered'
                                : this.state.linkItemType === 'SLIDE' ? 'slide was selected' : 'gide was selected';
                            this.props.onMessage(`No ${message}`);
                          }
                        }}
                      />
                    </div>
                  </>
                )} */}
              </div>
            </div>
          )}
        {this.state.displaySlideChooser && (
          <SlideChooser
            currentUser={this.props.currentUser}
            showNotification={(toasterMessageInfo: ToasterMessageInfo) => this.props.onMessage(toasterMessageInfo.message)}
            agent={agent}
            onSelectSlide={(slideSelection: SlideSelection) => {
              this.setState({
                displaySlideChooser: false,
                linkItemReference:
                  `/${slideSelection.articleSummary.authorUsername}/${slideSelection.articleSummary.slug}` +
                  '?slide=' +
                  (slideSelection.slide.position + 1),
              });
            }}
            slideSelectionIcon='/icons/creationprocess/slide.svg'
            slideSelectionLabel='select slide'
          />
        )}
        {this.state.displayGideChooser && (
          <GideChooser
            agent={agent}
            title="Search for a gide to link"
            onSelectGide={(articleSummary: GideSearchElement) => {
              this.setState({
                displayGideChooser: false,
                linkItemReference: `/${articleSummary.authorUsername}/${articleSummary.slug}`,
              });
            }}
            selectionMode={ItemSelectionMode.SingleDisplayRadioButton}
          />
        )}
      </div>
    );
  }
}
