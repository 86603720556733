import React from 'react';
import { DatePicker as DatePickerMaterial } from '@material-ui/pickers/DatePicker';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

export interface DatePickerProps {
  value: ParsableDate;
  onChange: (date: any) => void;
}

export const DatePicker: React.FC<DatePickerProps> = (props) => {
  return (
    <DatePickerMaterial
      value={props.value}
      onChange={props.onChange}
    />
  );
}
