import React, { Component } from 'react';
import { List } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { history } from '../../store';
import { MODAL_OPEN, VIEW_SLIDE } from '../../constants/actionTypes';

const mapStateToProps = state => ({
  ...state.article,
});

const mapDispatchToProps = dispatch => ({
  openModal: payload => dispatch({ type: MODAL_OPEN, payload }),
  onViewSlide: number => dispatch({ type: VIEW_SLIDE, number }),
});

class ChooseSlide extends Component {
  constructor(props) {
    super(props);

    this.onSelect = this._onSelect.bind(this);
  }

  _onSelect(idx) {
    const { article, slide, slides } = this.props;
    const choice = slide.data.choices[idx];

    if (slide.data.type === 'JUMP') {
      if (!choice.slide) return;
      slides.forEach((s, i) => {
        if (choice.slide === s.id) {
          const number = i + 1;
          this.props.openModal({
            modalType: 'SwipeViewModal',
          });
          this.props.onViewSlide(number);
        }
      });
    } else if (slide.data.type === 'MULTIPLE_CHOICE') {
      if (choice.correct) {
        const nextNum = 3;
        history.push('/gide/' + article.slug + '/slide/' + nextNum);
      } else {
        if (slide.data.wrongAction === 'REWIND') {
          history.push(`/gide/${article.slug}/slide/1`);
        }
      }
    }
  }

  render() {
    const { slide } = this.props;
    return (
      <div className="paddedCard">
        <p>{slide.data.question}</p>
        <List selection bulleted>
          {slide.data.choices.map((choice, i) => {
            return (
              <List.Item key={i} onClick={() => this.onSelect(i)}>
                <List.Content>{choice.body}</List.Content>
              </List.Item>
            );
          })}
        </List>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseSlide);
