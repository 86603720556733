import React, { Component, Dispatch } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Icon, Input, Checkbox } from 'semantic-ui-react';
import agent from '../../agent';
import { CompactPicker } from 'react-color';
import {
  ADD_SLIDE,
  MODAL_CLOSE,
  MODAL_OPEN,
  SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES,
} from '../../constants/actionTypes';
import SlideModalActions from './SlideModalActions';
import SlideSettings, { Settings } from '../SlideEditors/SlideSettings/SlideSettings';
import { getSlideSettings, updateSlideSettings } from '../SlideEditors/SlideSettings/SlideSettings';
import slideTools from '../../slideTools';
import { User } from '../../models/User';
import { GideAction } from '../../models/GideAction';
import { Slide, SlideModel } from '../../models/Slide';
import classNames from 'classnames';
import { Article } from '../../models/Article';
import { ChildArticleEditorInfo } from '../../models/ChildArticleEditorInfo';
import { ArticleAction } from '../../models/ArticleAction';
import isNil from 'ramda/es/isNil';
import GideImage from '../Shared/Image/GideImage';

interface CollapseModalProps {
  currentUser: User;
  editSlide: Slide;
  slide: Slide;
  mode: string;
  selection: string;
  currentSlidePosition: number;
  position: number;
  article: Article;
  childArticleEditInfo: ChildArticleEditorInfo;
  onSubmit: (payload: any) => void;
  onSubmitSlide?: (payload: any) => void;
  closeModal: () => void;
  openModal: (payload: any) => void;
  updateSlideAttachmentInfo: (payload: any) => void;
}

interface CollapseModalState {
  slide: SlideModel;
  showSettings: boolean;
  headerCaption: string;
  displayTopBorder: boolean;
  borderTopColor: string;
  displayBottomBorder: boolean;
  borderBottomColor: string;
  displayBottomBorderOnlyWhenCollapsed: boolean;
  openExpanded: boolean;
}

const mapStateToProps = slideTools.mapStateToProps;

const mapDispatchToProps = (dispatch: Dispatch<GideAction | ArticleAction>) => ({
  onSubmit: (payload: any) => dispatch({ type: ADD_SLIDE, payload }),
  closeModal: () => dispatch({ type: MODAL_CLOSE }),
  openModal: (payload: any) => dispatch({ type: MODAL_OPEN, payload }),
  updateSlideAttachmentInfo: (payload: any) =>
    dispatch({ type: SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES, payload }),
});

export class CollapseModal extends Component<
  CollapseModalProps,
  CollapseModalState
> {
  constructor(props: CollapseModalProps) {
    super(props);

    this.state = {
      slide: props.editSlide
        ? props.editSlide
        : {
            slideType: 'COLLAPSE',
            data: {
              level: 1,
              headerCaption: '',
              audioCaption: null,
              caption: '',
              displayTopBorder: true,
              borderTopColor: '#CCCCCC',
              displayBottomBorder: true,
              borderBottomColor: '#CCCCCC',
              displayBottomBorderOnlyWhenCollapsed: true,
            },
          },
      showSettings: false,
      headerCaption: props.editSlide ? props.editSlide.data.headerCaption : '',
      displayTopBorder: props.editSlide
        ? props.editSlide.data.displayTopBorder
        : true,
      borderTopColor: props.editSlide
        ? props.editSlide.data.borderTopColor
        : '#CCCCCC',
      displayBottomBorder: props.editSlide
        ? props.editSlide.data.displayBottomBorder
        : true,
      borderBottomColor: props.editSlide
        ? props.editSlide.data.borderBottomColor
        : '#CCCCCC',
      displayBottomBorderOnlyWhenCollapsed: props.editSlide
        ? props.editSlide.data.displayBottomBorderOnlyWhenCollapsed
        : true,
      openExpanded: props.editSlide
        ? props.editSlide.data.openExpanded
        : true,
    };
  }

  updateSlideSettings(settings: Settings) {
    this.setState({ slide: updateSlideSettings(this.state.slide as Slide, settings) });
  }

  closeSettings() {
    this.setState({ showSettings: false });
  }

  audioCaptionChanged(audioFile: any) {
    const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
    slide.data.audioCaption = audioFile;
    this.setState({ slide });
  }

  async createSlide() {
    let slidePosition = !isNil(this.props.currentSlidePosition) ? this.props.currentSlidePosition : this.props.position;
    let slide = {
      ...this.state.slide,
      slide: this.props.slide,
      createMode: this.props.mode,
      selection: this.props.selection,
      allowComments: false,
      allowQuestions: false,
      // If editing, don't change the position.
      position: this.props.editSlide
        ? this.props.editSlide.position
        : slidePosition,
    };
    slide.data.headerCaption = this.state.headerCaption;
    slide.data.borderTopColor = this.state.borderTopColor;
    slide.data.displayTopBorder = this.state.displayTopBorder;
    slide.data.borderBottomColor = this.state.borderBottomColor;
    slide.data.displayBottomBorder = this.state.displayTopBorder;
    slide.data.displayBottomBorderOnlyWhenCollapsed = this.state.displayBottomBorderOnlyWhenCollapsed;
    slide.data.openExpanded = this.state.openExpanded;
    let payload;
    if (this.props.editSlide) {
      payload = await agent.Slides.update(this.props.editSlide.id, slide);
    } else {
      payload = await agent.Slides.create(this.props.article, slide);
      if (this.props.childArticleEditInfo) {
        slideTools.getSlideAttachmentInfo(
          this.props.childArticleEditInfo.ownerSlide.id,
          this.props.updateSlideAttachmentInfo,
        );
      }
    }
    this.props.onSubmitSlide ?
      this.props.onSubmitSlide({ ...payload, mode: this.props.mode }) :
    this.props.onSubmit({ ...payload, mode: this.props.mode });
    this.props.closeModal();
  }

  async load() {
    if (!this.props.editSlide) {
      const slide = {
        ...this.state.slide,
        data: { ...this.state.slide.data },
      };
      this.setState({ slide });
      //this.createSlide();
    }
  }

  componentDidMount() {
    this.load();
  }

  render() {
    const { slide } = this.state;
    const modalClasses = classNames('modal', 'TEXT');
    return (
      <Modal
        closeOnEscape={true}
        onClose={this.props.closeModal}
        className={modalClasses}
        size="large"
        dimmer="inverted"
        open={true}
        style={{ background: 'rgb(24, 120, 126)', height: '90%' }}
        closeOnDimmerClick={false}
      >
        <Modal.Content
          style={{ background: 'rgb(24, 120, 126)', height: '92%' }}
        >
          <div
            className="modalHeader"
            style={{ background: 'rgb(24, 120, 126)' }}
          >
            <Button id="modalClose" icon onClick={this.props.closeModal}>
              <Icon name="close" />
            </Button>
            <span>Collapse Type</span>
            <GideImage src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-header.svg" />
          </div>
          {!this.state.showSettings && (
            <Modal.Description className="dataEntry" style={{ height: '100%' }}>
              <Input
                className="headerText"
                fluid
                value={this.state.headerCaption}
                onChange={e => this.setState({ headerCaption: e.target.value })}
                autoFocus
              />
              <h3>Open Expanded</h3>
              <fieldset className="form-group">
                <Checkbox
                  onChange={(evt: any, data: any) => {
                    this.setState({ openExpanded: data.checked });
                  }}
                  toggle
                  checked={this.state.openExpanded}
                  label=""
                />
              </fieldset>
              <h3>Display Top Border</h3>
              <fieldset className="form-group">
                <Checkbox
                  onChange={(evt: any, data: any) => {
                    this.setState({ displayTopBorder: data.checked });
                  }}
                  toggle
                  checked={this.state.displayTopBorder}
                  label=""
                />
              </fieldset>
              {this.state.displayTopBorder && (
                <span>
                  <h3>Top Border Color</h3>
                  <CompactPicker
                    color={this.state.borderTopColor}
                    onChange={(color: any) => {
                      this.setState({ borderTopColor: color.hex });
                    }}
                  />
                </span>
              )}
              <h3>Display Bottom Border</h3>
              <fieldset className="form-group">
                <Checkbox
                  onChange={(evt: any, data: any) => {
                    this.setState({ displayBottomBorder: data.checked });
                  }}
                  toggle
                  checked={this.state.displayBottomBorder}
                  label=""
                />
              </fieldset>
              <h3>Display Bottom Border Only When Collapsed</h3>
              <fieldset className="form-group">
                <Checkbox
                  onChange={(evt: any, data: any) => {
                    this.setState({
                      displayBottomBorderOnlyWhenCollapsed: data.checked,
                    });
                  }}
                  toggle
                  checked={this.state.displayBottomBorderOnlyWhenCollapsed}
                  label=""
                />
              </fieldset>
              {this.state.displayBottomBorder && (
                <span>
                  <h3>Bottom Border Color</h3>
                  <CompactPicker
                    color={this.state.borderBottomColor}
                    onChange={(color: any) => {
                      this.setState({ borderBottomColor: color.hex });
                    }}
                  />
                </span>
              )}
            </Modal.Description>
          )}
          {this.state.showSettings && (
            <Modal.Description className="settingsPanel">
              <SlideSettings
                hideTemplateIndicator={true}
                settings={getSlideSettings(
                  this.state.slide as Slide,
                  this.props.article.type,
                )}
                onSettingsChanged={this.updateSlideSettings.bind(this)}
                onCloseSettings={this.closeSettings.bind(this)}
              />
            </Modal.Description>
          )}
        </Modal.Content>
        <Modal.Actions>
          <SlideModalActions
            caption={slide.data.caption}
            captionChanged={(ev: any) => {
              this.setState({
                slide: {
                  ...slide,
                  data: {
                    ...slide.data,
                    caption: ev.target.value,
                  },
                },
              });
            }}
            audioCaption={slide.data.audioCaption}
            onAudioCaptionChanged={this.audioCaptionChanged.bind(this)}
            showSettings={this.state.showSettings}
            canNavigateBack={false}
            settingsClicked={() =>
              this.setState({ showSettings: !this.state.showSettings })
            }
            nextClicked={this.createSlide.bind(this)}
            settings={getSlideSettings(
              this.state.slide as Slide,
              this.props.article.type,
            )}
            onSettingsChanged={this.updateSlideSettings.bind(this)}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CollapseModal);
