import {
  SLIDE_PAGE_LOADED,
  SLIDE_PAGE_UNLOADED,
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch(action.type) {
    case SLIDE_PAGE_LOADED:
      return {
        ...state,
        // slide: state.article.slides[action.slideNumber+1]
      }
    case SLIDE_PAGE_UNLOADED:
      return {};
    default:
      return state;
  }
};
