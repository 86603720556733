import React from 'react';
import classNames from 'classnames';
import { List } from 'semantic-ui-react';
import GideImage from '../Shared/Image/GideImage';

const File = props => {
  const { multiple, file } = props;
  let classes;
  let fileType;
  if (!multiple) {
    let nameParts = file.name.split('.');
    if (nameParts.length > 1) {
      fileType = nameParts[nameParts.length - 1].toUpperCase();
      classes = classNames('fileItem', fileType);
    } else {
      fileType = 'Unknown filetype';
      classes = classNames('fileItem');
    }
  }

  return (
    <div className={classes}>
      <div className="box">
        <div className="inner">
          {fileType ? (
            <div>{fileType}</div>
          ) : (
            <GideImage src="/icons/slidetype/file/main.svg"
              className="whites-normal-1000-svg"
              alt={`□`}
            />
          )}
        </div>
      </div>
      <div className="info">
        <div className="lineOne">{multiple ? 'Multiple Files' : file.name}</div>
        <div className="lineTwo"> {fileType} file</div>
        <div className="lineThree">
          {file && file.size ? `${file.size} kb` : 'Unknown size'}{' '}
        </div>
      </div>
      {!multiple && (
        <div className="right">
          <a href={file.url} target="blank" className="downloadBtn">
            <GideImage src="/icons/content-alteration/download.svg" className="svgIcon lg" alt={`□`} />
          </a>
        </div>
      )}
    </div>
  );
};

const FileSlide = props => {
  const { slide, viewMode } = props;
  if (!slide.data.files || !slide.data.files.length) {
    return <div>No files found.</div>;
  }
  return (
    <div>
      {viewMode === 'SWIPE' && (
        <div>
          <List>
            {slide.data.files.map((file, i) => (
              <List.Item key={i}>
                <File file={file} />
              </List.Item>
            ))}
          </List>
        </div>
      )}
      {(viewMode === 'SLIDE' || viewMode === 'SCROLL') && (
        <div>
          {slide.data.files.length === 1 && <File file={slide.data.files[0]} />}
          {slide.data.files.length > 1 && <File multiple />}
        </div>
      )}
    </div>
  );
};
export default FileSlide;
