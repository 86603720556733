import React from 'react';
import { List } from 'semantic-ui-react';
import AudioCarousel from '../Viewers/AudioViewers/AudioCarousel/AudioCarousel';
import { AudioViewerMode } from '../SlideEditors/AudioSlideEditor/AudioSlideEditor';
import AudioView from '../Viewers/AudioViewers/AudioView/AudioView';

const AudioSlide = ({ slide, viewMode }) => {
  const autoPlay = viewMode === 'SWIPE' && slide.data.autoplay ? true : false;
  return (
    <div>
      {slide.data.slideTypeSpecificSettings && slide.data.slideTypeSpecificSettings.displayType === AudioViewerMode.Carousel && slide.data.files && (
        <AudioCarousel
          slide={slide}
          onClick={() => alert('todo click audio carousel')}
          maxResponsiveHeight={124}
          maxWidescreenHeight={124}
        />
      )}
      {(!slide.data.slideTypeSpecificSettings || slide.data.slideTypeSpecificSettings.displayType !== AudioViewerMode.Carousel) && slide.data.files && (
        <List>
          {slide.data.files.map((audioFile, i) => {
            return (
              <AudioView
                className={i < slide.data.files.length - 1 ? 'hasEnsuingItem' : undefined}
                key={i}
                slide={slide}
                autoPlay={autoPlay}
                audioEnded={() => console.log('TODO: Handle Audio Ended')}
                audioFile={audioFile}
                isReferenceType={audioFile.type === 'SLIDEFILE'}
              />
            );
          })}
        </List>
      )}
    </div>
  );
};

export default AudioSlide;
