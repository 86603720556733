import React, { useState, useEffect } from 'react';
import styles from './_account-profile-modal.module.scss';
import AccountProfile from '../../AccountProfile/AccountProfile';
import { User } from '../../../models/User';
import { Profile, ProfileUpdate } from '../../../models/Profile';
import ResizeableModal from '../ResizeableModal/ResizeableModal';
import ChannelView from '../../Channel/ChannelView';
import { Channel, ChannelCreate } from '../../../models/Channel';
import { Article } from '../../../models/Article';
import Avatar from '../../Shared/Avatar/Avatar';
import icons from '../../../assets/icons';
import { EditorState } from '../../Navigation/CreationProcess/EditorState/EditorState';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import { NotificationType } from '../../../constants/strings';
import ProfileEditor from '../../AccountProfile/ProfileEditor/ProfileEditor';
import { ChannelEditor } from '../../ChannelEditor/ChannelEditor';
import { MediaFile } from '../../../models/SlideFile';
import superagent from 'superagent';
import { API_ROOT } from '../../../constants/paths';
import { ImagePicker } from '../../Shared/ImagePicker/ImagePicker';
import { assertNever } from 'office-ui-fabric-react/lib/Utilities';
import { history } from '../../../store';
import produce from 'immer';
import { Loader } from 'semantic-ui-react';
import { reject } from 'ramda';

export interface  AccountProfileModalProps {
  profile: Profile;
  currentUser?: User | null;
  view: 'channel' | 'activity';
  channelSlug?: string;
  closeModal: (closeDialogList?: string[]) => void;
  getChannel: (slug: string) => Promise<{channel: Channel}>;
  getChannelContent: (slug: string) => Promise<{content: {articles: Article[], collections: any[], channels: Channel[]}}>;
  createChannel: (channel: ChannelCreate) => Promise<{channel: Channel}>;
  onChannelCreated: (channel: Channel) => void;
  updateChannel: (channel: Channel) => Promise<{channel: Channel}>;
  onChannelUpdated: (channel: Channel) => void;
  contactUser: (message: string, toUsername: string, fromUsername: string) => Promise<void>;
  saveUserProfile: (profile: ProfileUpdate) => Promise<{user: Profile}>;
  showNotification: (toasterMessageInfo?: ToasterMessageInfo) => void;
  settingsSaved: (profile: Profile) => void;
  onGideDeleted: (gideId: string) => void;
  page: ProfileMode;
 }
export enum ProfileMode {
  Profile = 'profile',
  Settings = 'settings',
  PickProfileImage = 'pick-profile-image',
  PickCoverImage = 'pick-cover-image',
  PickChannelImage = 'pick-channel-image',
  Channel = 'channel',
  ChannelEditor = 'channel-editor',
}
const AccountProfileHeader = (props: {mode: ProfileMode, profile: Profile, channel?: Channel}) => {
  switch(props.mode) {
    case ProfileMode.Profile:
      return (
        <div style={{display: 'flex', alignItems: 'center'}}>
          <span className="TEXTSUBTITLEblackmedium-emphasisright" style={{marginRight: '7px'}}>@{props.profile.username}</span>
          <Avatar avatarImage={props.profile.image}
            displayOuterBorder={false}
            imageWidthAndHeight={30}
          />
        </div>
      );
    case ProfileMode.Channel:
      return (
        <div style={{display: 'flex', alignItems: 'center', marginRight: '12px', marginLeft: '26px', flex: 1, justifyContent: 'flex-end'}}>
          <span className={styles.channelEllipsis}>
            {props.channel ? props.channel.title : ''}
          </span>
          <EditorState title="" icon={<icons.Nav_Channels_Main color="var(--COLOR-PRIMARY-700)" />} backgroundColor="var(--WHITES-NORMAL-1000)" />
        </div>
      );
    case ProfileMode.Settings:
      return (
        <EditorState title="Edit your profile" icon={<icons.Nav_Settings color="var(--COLOR-PRIMARY-700)" />} backgroundColor="var(--WHITES-NORMAL-1000)" />
      );
    default:
      return <></>;
  }
};

type ChannelInfo = {
  channel: Channel,
  channelDetails: {articles: Article[], collections: any[], channels: Channel[]},
};

export default function AccountProfileModal (props:  AccountProfileModalProps) {
  const [mode, setMode] = useState<ProfileMode>(ProfileMode.Profile);
  const [selectedActivityTab, setSelectedActivityTab] = useState<number>(props.view === 'activity' ? 0 : 1);
  const [channels, setChannels] = useState<Channel[]>(props.profile.channels);
  const [channelInfo, setChannelInfo] = useState<ChannelInfo>();
  const [currentPage, setCurrentPage] = useState<ProfileMode>(props.page);
  const [currentProfileId] = useState<string>(props.profile.id);
  const [loading, setLoading] = useState<boolean>(props.channelSlug ? true : false);

  const navigateToChannel = async (channelSlug: string) => {
    await loadChannelInfo(channelSlug);
    navigate(ProfileMode.Channel);
    setLoading(false);
  }
  useEffect(() => {
    if(props.channelSlug) {
     navigateToChannel(props.channelSlug)
    }
  }, [props.channelSlug])
  useEffect(() => {
    if(props.profile.id !== currentProfileId) {
      props.closeModal();
      setMode(props.page);
    }
    else if(props.page !== currentPage) {
      setCurrentPage(props.page);
      setMode(props.page);
    }
  }, [props.page, props.profile.username]);
  const navigate = (newMode: ProfileMode) => {
      history.push({
        ...history.location,
        search: `profile=${props.profile.username}&page=${newMode}`
      })
  };

  const loadChannelInfo = async (slug: string) => {
    const channelResponse = await props.getChannel(slug);
    const channelDetailsResponse = await props.getChannelContent(slug);
    setChannelInfo({channel: channelResponse.channel, channelDetails: channelDetailsResponse.content});
  }

  useEffect(() => {
    if (channelInfo && channelInfo.channel) {
      loadChannelInfo(channelInfo.channel.slug);
    }
  }, [props.profile.channels]);

  const onDeleteChannelGide = (gideId: string) => {
    if (channelInfo) {
      let newChannelDetails = {...channelInfo.channelDetails, articles: reject(g => g.id === gideId, channelInfo.channelDetails.articles)}
      setChannelInfo({channel: channelInfo.channel, channelDetails: newChannelDetails});
      let deletedChannel = channels.find(c => c._id === channelInfo.channel._id);
      if (deletedChannel) {
        let newChannels = reject(c => c._id === channelInfo.channel._id, channels);
        newChannels.push({...deletedChannel, numberOfGides: deletedChannel.numberOfGides ? deletedChannel.numberOfGides - 1 : 0});
        setChannels(newChannels);
      }
    }
    props.onGideDeleted(gideId);
  }

  return (
    <ResizeableModal
      closeModal={() => {
        props.closeModal(['AccountProfileModal']);
      }}
      canResize={false}
      mode="noMargin"
      style={{ maxWidth: '786px' }}
      navigationState={mode !== ProfileMode.Profile ? 'backNavigation' : 'closeModal'}
      rightToolbarContent={<AccountProfileHeader channel={channelInfo ? channelInfo.channel : undefined} mode={mode} profile={props.profile} />}
      navigateBack={() => {
        history.goBack();
      }}
      hideCommandBar={(mode === ProfileMode.ChannelEditor || mode === ProfileMode.PickCoverImage || mode === ProfileMode.PickProfileImage || mode === ProfileMode.PickChannelImage)}
    >
      <div className={styles.accountProfileModalContainer}>
        {mode === ProfileMode.Profile && !loading && (
          <AccountProfile
            profile={props.profile}
            channels={channels}
            currentUser={props.currentUser}
            onNavigateToChannel={async (slug: string) => {
              await loadChannelInfo(slug);
              navigate(ProfileMode.Channel);
            }}
            onNavigateToSettings={() => {
              navigate(ProfileMode.Settings);
            }}

            onNavigateToMyGide={(myGideSlug: string) => {
              props.closeModal();
            }}
            onContactUser={(message: string, toUsername: string, fromUsername: string) => {
              props.contactUser(message, toUsername, fromUsername);
              props.showNotification({
                message: ' your message was sent.',
                title: 'SUCCESS: ',
                type: NotificationType.SUCCESS,

                timeoutMilliseconds: 4000
              });
            }}
            onCreateNewChannel={async () => {
              navigate(ProfileMode.ChannelEditor);
            }}
            onActivityTabChanged={(tabIndex: number) => setSelectedActivityTab(tabIndex)}
            selectedActivityTab={selectedActivityTab}
            showNotification={props.showNotification}
          />
        )}
        {mode === ProfileMode.Channel && channelInfo && !loading && (
          <ChannelView
            channel={channelInfo.channel}
            gideList={channelInfo.channelDetails.articles}
            currentUser={props.currentUser}
            onGideSelected={(gideId: string) => {
              props.closeModal();
            }}
            onClickChannelImage={() => {
              navigate(ProfileMode.PickChannelImage);
            }}
            onDeleteChannelGide={onDeleteChannelGide}
          />
        )}
        {mode === ProfileMode.PickChannelImage && !loading && (
          <ImagePicker
            title="Choose Channel Image"
            onSave={async image => {
              if (channelInfo) {
                const response = await superagent.post(`${API_ROOT}/util/upload`).attach('theseNamesMustMatch', image.dataUrl);
                const updatedChannel = produce(channelInfo.channel, draft => {
                  draft.image = response.body.url;
                });
                const channelPayload = await props.updateChannel(updatedChannel);
                props.onChannelUpdated(channelPayload.channel);
                history.goBack();
              } else {
                props.showNotification({ message: 'No Channel selected for updated.', type: NotificationType.ERROR });
              }
            }}
            onCancel={() => history.goBack()}
            onError={error => {
              props.showNotification({ message: error, type: NotificationType.ERROR });
            }}
          />
        )}
        {(mode === ProfileMode.Settings || mode === ProfileMode.PickProfileImage || mode === ProfileMode.PickCoverImage) && !loading && (
          <ProfileEditor
            onCancelChanges={() => {
              history.goBack();
            }}
            onConfirmChanges={async (profileUpdateInfo: ProfileUpdate) => {
              // TODO: show loading
              const response: {user: Profile} = await props.saveUserProfile(profileUpdateInfo);
              props.settingsSaved(response.user);
              history.goBack();
              props.showNotification({
                title: "Profile Updated: ",
                message: "Your changes were successfully updated.",
                type: NotificationType.SUCCESS
              });
            }}
            profile={{
              ...props.profile,
            }}
            mode={mode}
            showNotification={props.showNotification}
            onEditImage={(type) => {
              const mode =
                type === 'cover' ? ProfileMode.PickCoverImage :
                type === 'profile' ? ProfileMode.PickProfileImage :
                  assertNever(type);
              navigate(mode);
            }}
          />
        )}
        {mode === ProfileMode.ChannelEditor && !loading && (
          <ChannelEditor
            channel={{description: '', title: '', image:''}}
            showNotification={props.showNotification}
            onSave={async (image: MediaFile, title: string, description: string) => {
              // TODO: Handle this at some point with the background image processing:
              const response = await superagent
                .post(`${API_ROOT}/util/upload`)
                .attach('theseNamesMustMatch', image.dataUrl);
              const channel: ChannelCreate = {
                title,
                image: response.body.url,
                description,
                type: 'PUBLIC'
              };
              const channelPayload = await props.createChannel(channel);
              props.onChannelCreated(channelPayload.channel);
              history.goBack();
              return true;
            }}
            onCancel={() => history.goBack()}
          />
        )}
        {loading && (
          <div className={styles.loading}>
            <Loader active inline="centered" />
            <span className='TEXTSUBTITLEblackmedium-emphasiscenter'>Loading</span>
          </div>
        )}
      </div>
    </ResizeableModal>
  );
}
