import React from 'react';
import styles from './_avatar-menu-section.module.scss';
import icons, { IconProps } from '../../../assets/icons';

export interface  AvatarMenuSectionProps {
  title: string;
  menuTitleImage: (props: IconProps) => JSX.Element;
  // active: boolean;
  onClick?: () => void;
  expanded?: boolean;
  doNotFillFromColor?: boolean;
}

export default function AvatarMenuSection (props:  AvatarMenuSectionProps) {
  return (
    <div className={styles.avatarMenuSectionContainer}
      onClick={() => {
        if(props.onClick) {
          props.onClick();
        }
      }}
    >      
      {/* {props.active &&
          <div className={styles.activeIndicator} />
      } */}
      <div className={styles.titleContainer}>
        <props.menuTitleImage 
          color={props.doNotFillFromColor ? undefined : 'var(--COLOR-SECONDARY-500)'}
          doNotFillFromColor={props.doNotFillFromColor}
        />
        <span className={styles.title}>{props.title}</span>
      </div>
      {props.expanded === true && (
        <icons.Nav_Arrow_ArrowUp
          color='var(--COLOR-SECONDARY-500)'
          style={{marginRight: '11px'}}
        />
      )}
      {props.expanded === false && (
        <icons.Nav_Arrow_ArrowDown
          color='var(--COLOR-SECONDARY-500)'
          style={{marginRight: '11px'}}
        />
      )}      
    </div>
  );
}
