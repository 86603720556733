import {
  CHANNEL_PAGE_CHANNEL_LOADED,
  CHANNEL_PAGE_CONTENT_LOADED,
  UNAUTHENTICATED_AUTHORIZE_SLIDE,
  EDITOR_PAGE_UNLOADED,
  WEBSITE_BODY_ARTICLE_LOADED,
  WEBSITE_HEADER_ARTICLE_LOADED,
  WEBSITE_FOOTER_ARTICLE_LOADED,
  WEBSITE_SIDEBAR_ARTICLE_LOADED,
  JOIN_CHANNEL,
  FOLLOW_CHANNEL,
  UNFOLLOW_CHANNEL,
} from '../constants/actionTypes';
import { isNullOrUndefined } from 'util';
const INITIAL_STATE = {
  websiteBody: null,
  websiteHeader: null,
  websiteFooter: null,
  channel: null,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANNEL_PAGE_CHANNEL_LOADED:
      return {
        ...state,
        channel: action.payload.channel,
      };
    case CHANNEL_PAGE_CONTENT_LOADED:
      return {
        ...state,
        ...action.payload.content,
      };
    case EDITOR_PAGE_UNLOADED:
      return {
        ...state,
        channel: null,
      };
    case WEBSITE_BODY_ARTICLE_LOADED: {
      return {
        ...state,
        websiteBody: {
          article: action.payload.article,
          slides: action.payload.slides,
        },
      };
    }
    case WEBSITE_SIDEBAR_ARTICLE_LOADED: {
      return {
        ...state,
        websiteSidebar: {
          article: action.payload.article,
          slides: action.payload.slides,
        },
      };
    }
    case WEBSITE_HEADER_ARTICLE_LOADED: {
      return {
        ...state,
        websiteHeader: {
          article: action.payload.article,
          slides: action.payload.slides,
        },
      };
    }
    case WEBSITE_FOOTER_ARTICLE_LOADED: {
      return {
        ...state,
        websiteFooter: {
          article: action.payload.article,
          slides: action.payload.slides,
        },
      };
    }
    case UNAUTHENTICATED_AUTHORIZE_SLIDE: {
      const slideId = action.payload.slideId;
      const websiteSection =
        !isNullOrUndefined(state.websiteBody) &&
        action.payload.articleId === state.websiteBody.article._id
          ? state.websiteBody
          : !isNullOrUndefined(state.websiteHeader) &&
            action.payload.articleId === state.websiteHeader.article._id
            ? state.websiteHeader
            : !isNullOrUndefined(state.websiteFooter) &&
              action.payload.articleId === state.websiteFooter.article._id
              ? state.websiteFooter
              : null;
      if (!isNullOrUndefined(websiteSection)) {
        const slides =
          action.payload.authorized === true
            ? [...websiteSection.slides.filter(s => s.id !== slideId), ...action.payload.slides]
            : websiteSection.slides;
        const newSlides = slides.map(slide => {
          if (slide.id === slideId) {
            return {
              ...slide,
              data: {
                ...slide.data,
                maxAttemptsReached: slide.data.maxAttempts
                  ? action.payload.numberOfAttempts >= slide.data.maxAttempts
                  : false,
                authorized: action.payload.authorized,
                message:
                  action.payload.authorized === true
                    ? undefined
                    : slide.data.passcodeType === 'TEXT'
                      ? slide.data.maxAttempts
                        ? `Invalid Password (${slide.data.maxAttempts -
                            action.payload
                              .numberOfAttempts} attempts remaining)`
                        : 'Invalid Password'
                      : slide.data.passcodeType === 'GPS'
                        ? 'Invalid Location'
                        : 'Invalid Date',
              },
            };
          } else {
            return slide;
          }
        });
        return {
          ...state,
          websiteBody:
            !isNullOrUndefined(state.websiteBody) &&
            action.payload.articleId === state.websiteBody.article._id
              ? { ...state.websiteBody, slides: newSlides }
              : state.websiteBody,
          websiteHeader:
            !isNullOrUndefined(state.websiteHeader) &&
            action.payload.articleId === state.websiteHeader.article._id
              ? { ...state.websiteHeader, slides: newSlides }
              : state.websiteHeader,
          websiteFooter:
            !isNullOrUndefined(state.websiteFooter) &&
            action.payload.articleId === state.websiteFooter.article._id
              ? { ...state.websiteFooter, slides: newSlides }
              : state.websiteFooter,
        };
      } else {
        return state;
      }
    }
    case JOIN_CHANNEL:
    case FOLLOW_CHANNEL:
    case UNFOLLOW_CHANNEL:
      return {
        ...state,
        channel: action.payload.channel,
      };
    default:
      return state;
  }
};
