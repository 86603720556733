import React, { Component } from 'react';
import { Slide } from '../../../models/Slide';
import { User } from '../../../models/User';
import { Icon, Button } from 'semantic-ui-react';
import { Dictionary, SlideshowSlideInfo } from '../../../models/SlideShowSlide';
import ReactPlayer from 'react-player';
import { isNullOrUndefined } from 'util';
import { SwipeView } from '../SwipeView/SwipeView';
import { Article } from '../../../models/Article';
import GideImage from '../../Shared/Image/GideImage';
import { ViewMode } from '../../Gide/SlideView/SlideView';

export interface SlideshowPlayerProps {
  currentUser: User;
  articleSlug: any;
  article: Article;
  slideshowSlides: Slide[];
  slideshowSlidesInfo: Dictionary<SlideshowSlideInfo>;
  slideshowBeginTime: number;
  slideshowDuration?: number;
  useSlideTimings: boolean;
  useAudioTrack: boolean;
  view: any;
  viewMode: ViewMode;
  showChrome: boolean;
  showCaptionPanel: boolean;
  showInfoPanel: boolean;
  appName: string;
  nextViewMode: ViewMode;
  slideshowTitle: string;
  slideshowImageUrl: string;
  audioTrack?: any;
  playSlideshow: boolean;
  showSlideshowControls: boolean;
  finishedPlayingSlideshow: () => void;
  // setLoading: () => void;
  setNextViewMode: (mode: ViewMode) => void;
  onSetViewMode: (mode: ViewMode) => void;
}

export interface SlideshowPlayerState {
  nextSlideshowSlide?: Slide;
  currentSlideshowSlide?: Slide;
  slideshowTimeout?: NodeJS.Timeout;
  displaySlideIndex: number;
  playingSlideshow: boolean;
  playing: boolean;
  played: boolean;
  loop: boolean;
  percentCompleted: number;
  slideshowPaused: boolean;
}

export class SlideshowPlayer extends Component<
  SlideshowPlayerProps,
  SlideshowPlayerState
> {
  player: ReactPlayer | null | undefined;
  constructor(props: SlideshowPlayerProps) {
    super(props);
    this.state = {
      currentSlideshowSlide: undefined,
      nextSlideshowSlide: undefined,
      slideshowTimeout: undefined,
      displaySlideIndex: 0,
      playingSlideshow: false,
      playing: false,
      loop: false,
      percentCompleted: 0,
      slideshowPaused: false,
      played: false,
    };
  }

  shouldComponentUpdate(
    nextProps: SlideshowPlayerProps,
    nextState: SlideshowPlayerState,
  ) {
    const shouldUpdate =
      nextProps.articleSlug !== this.props.articleSlug ||
      nextProps.slideshowSlides !== this.props.slideshowSlides ||
      nextState.nextSlideshowSlide !== this.state.nextSlideshowSlide ||
      nextState.currentSlideshowSlide !== this.state.currentSlideshowSlide ||
      nextState.playingSlideshow !== this.state.playingSlideshow ||
      nextProps.playSlideshow !== this.props.playSlideshow ||
      nextProps.slideshowImageUrl !== this.props.slideshowImageUrl ||
      nextProps.slideshowTitle !== this.props.slideshowTitle ||
      nextState.playing !== this.state.playing ||
      nextState.slideshowPaused !== this.state.slideshowPaused ||
      nextState.played !== this.state.played;

    return shouldUpdate;
  }

  componentDidMount() {
    this.load();
  }

  componentWillUnmount() {
    if (this.state.slideshowTimeout) {
      clearTimeout(this.state.slideshowTimeout);
    }
  }

  startTimer = (callback: (...args: any[]) => void, duration: number) => {
    return setTimeout(callback, duration);
  };

  getSlideshowSlideDuration = (
    currentSlideId: string,
    nextSlideId?: string,
  ) => {
    // if (nextSlideId !== undefined || this.props.slideshowDuration) {
    if (this.props.slideshowDuration) {
      const currentSlideStart: number = this.props.slideshowSlidesInfo[
        currentSlideId
      ]
        ? this.props.slideshowSlidesInfo[currentSlideId].startTimeInSeconds *
          1000
        : 0;
      const nextSlideStart: number =
        nextSlideId && this.props.slideshowSlidesInfo[nextSlideId]
          ? this.props.slideshowSlidesInfo[nextSlideId].startTimeInSeconds *
            1000
          : this.props.slideshowDuration * 1000;
      const slideDuration = nextSlideStart - currentSlideStart;
      // the slideDuration should never be greater than the slideshow duration
      // if it is, then return the slideshow duration
      return slideDuration > (this.props.slideshowDuration as number) * 1000
        ? (this.props.slideshowDuration as number) * 1000
        : slideDuration;
    }
    return 0;
  };

  getNextSlideshowSlide = (currentSlide?: Slide) => {
    if (currentSlide && currentSlide.position !== undefined) {
      const nextSlidePosition = currentSlide.position + 1;
      if (this.props.slideshowSlides.length > nextSlidePosition) {
        return this.props.slideshowSlides[nextSlidePosition];
      }
    }
    return undefined;
  };

  createSlideshowSlideDisplayTimeout = (
    currentSlideshowSlideId: string,
    nextSlideshowSlideId?: string,
  ) => {
    // if existing slideshowTimeout, then clear the timeout
    if (this.state.slideshowTimeout) {
      clearTimeout(this.state.slideshowTimeout);
    }
    // slideshowSlidesInfo must exist with begin and end time to calculate duration
    if (!isNullOrUndefined(this.props.slideshowSlidesInfo)) {
      const slideShowDuration = this.getSlideshowSlideDuration(
        currentSlideshowSlideId,
        nextSlideshowSlideId,
      );
      // set the timer to show the next slideshow slide
      return this.startTimer(
        () => this.showNextSlideshowSlide(),
        slideShowDuration,
      );
    }
    return undefined;
  };

  pauseSlideshow = () => {
    // clear the slideshow timeout used to display the next slideshow slide
    if (this.state.slideshowTimeout) {
      clearTimeout(this.state.slideshowTimeout);
    }
    // pause the slideshow slides and the slideshow audio
    this.setState({
      slideshowPaused: true,
      playing: false,
    });
  };

  resumeSlideshow = () => {
    // resume the slideshow and slideshow audio
    this.setState({
      slideshowPaused: false,
      playing: true,
    });
    this.showNextSlideshowSlide();
  };

  showPreviousSlideshowSlide = () => {
    if (this.props.slideshowSlides.length > 0) {
      // clear slideshow timeout
      if (this.state.slideshowTimeout) {
        clearTimeout(this.state.slideshowTimeout);
      }
      const displaySlideIndex = this.state.displaySlideIndex - 2;
      // set the currentSlideshowSlide
      const currentSlideshowSlide =
        displaySlideIndex < this.props.slideshowSlides.length &&
        displaySlideIndex > -1
          ? this.props.slideshowSlides[displaySlideIndex]
          : this.props.slideshowSlides[this.props.slideshowSlides.length - 1];
      // set the nextSlideshowSlide
      const nextSlideshowSlide = this.getNextSlideshowSlide(
        this.state.currentSlideshowSlide,
      );

      const percentCompleted = Math.round(
        displaySlideIndex / this.props.slideshowSlides.length * 100,
      );

      if (this.props.useSlideTimings) {
        const slideshowTimeout = this.createSlideshowSlideDisplayTimeout(
          currentSlideshowSlide.id,
          nextSlideshowSlide !== undefined ? nextSlideshowSlide.id : undefined,
        );
        this.setState({
          currentSlideshowSlide,
          nextSlideshowSlide,
          slideshowTimeout,
          displaySlideIndex,
          percentCompleted,
        });
      } else {
        this.setState({
          currentSlideshowSlide,
          nextSlideshowSlide,
          displaySlideIndex,
          percentCompleted,
        });
      }
    }
  };

  showNextSlideshowSlide = () => {
    if (this.props.slideshowSlides.length > 0) {
      // if currentSlideshowSlide has a value and nexSlideshowSlide is undefined, then end slideshow
      if (
        this.state.nextSlideshowSlide === undefined &&
        this.state.currentSlideshowSlide !== undefined
      ) {
        // last slide of slide show played, so initialize state
        this.setState({
          currentSlideshowSlide: undefined,
          nextSlideshowSlide: undefined,
          slideshowTimeout: undefined,
          playingSlideshow: false,
          displaySlideIndex: 0,
          percentCompleted: 0,
          slideshowPaused: false,
          played: true,
        });
        this.props.finishedPlayingSlideshow();
      } else {
        let nextSlideshowSlide: Slide | undefined;
        let currentSlideshowSlide: Slide | undefined;
        // if currentSlideshowSlide is null then set it to the first slideShow slide
        if (isNullOrUndefined(this.state.currentSlideshowSlide)) {
          currentSlideshowSlide = this.props.slideshowSlides[0];
        } else {
          // set the current slide to the next slide
          currentSlideshowSlide = this.state.nextSlideshowSlide;
        }
        // set the nexSlideshowSlide
        if (
          !isNullOrUndefined(currentSlideshowSlide) &&
          !isNullOrUndefined(currentSlideshowSlide.id)
        ) {
          // get the next slide
          const displaySlideIndex = this.state.displaySlideIndex + 1;
          nextSlideshowSlide =
            displaySlideIndex < this.props.slideshowSlides.length
              ? this.props.slideshowSlides[displaySlideIndex]
              : undefined;
          const percentCompleted = Math.round(
            displaySlideIndex / this.props.slideshowSlides.length * 100,
          );
          if (this.props.useSlideTimings) {
            const slideshowTimeout = this.createSlideshowSlideDisplayTimeout(
              currentSlideshowSlide.id,
              nextSlideshowSlide !== undefined
                ? nextSlideshowSlide.id
                : undefined,
            );
            this.setState({
              currentSlideshowSlide,
              nextSlideshowSlide,
              slideshowTimeout,
              displaySlideIndex,
              percentCompleted,
            });
          } else {
            this.setState({
              currentSlideshowSlide,
              nextSlideshowSlide,
              displaySlideIndex,
              percentCompleted,
            });
          }
        }
      }
    }
  };

  onClickPlaySlideshow = () => {
    if (this.state.slideshowTimeout) {
      clearTimeout(this.state.slideshowTimeout);
    }
    this.setState({
      currentSlideshowSlide: undefined,
      nextSlideshowSlide: undefined,
      slideshowTimeout: undefined,
      displaySlideIndex: 0,
      playingSlideshow: true,
      playing: true,
      slideshowPaused: false,
      played: false,
    });
  }

  onPlaySlideshow = () => {
    if (this.state.slideshowTimeout) {
      clearTimeout(this.state.slideshowTimeout);
    }
    this.setState({
      currentSlideshowSlide: undefined,
      nextSlideshowSlide: undefined,
      slideshowTimeout: undefined,
      displaySlideIndex: 0,
      playingSlideshow: true,
      playing: true,
      slideshowPaused: false,
      played: false,
    });
    this.showNextSlideshowSlide();
  };

  async load() {
    if (
      !this.state.slideshowTimeout &&
      this.props.playSlideshow &&
      !this.state.playingSlideshow
    ) {
      this.onPlaySlideshow();
    }
  }

  onPlay = () => {
    if (this.player && this.props.slideshowBeginTime) {
      this.player.seekTo(this.props.slideshowBeginTime);
    }
    this.setState({ playing: true });
  };
  onPause = () => {
    this.setState({ playing: false });
  };
  onEnded = () => {
    this.setState({ playing: this.state.loop });
  };
  render() {
    const classes = this.state.playingSlideshow
      ? 'container page flexColumnFull'
      : this.state.played ? 'slideshowSlideSlide'
        : '';
    const style = this.state.played
      ? {height: '100%'}
      : {};
    return (
      <div className={classes} style={style}>
        {!this.state.playingSlideshow && !this.state.played && (
          <div className="slideshowViewer">
            <div className="slideShowPreview">
              <div className="slideshowTitle">{this.props.slideshowTitle}</div>
              <GideImage 
                className="articleImage"
                src={this.props.slideshowImageUrl}
                alt={`□`}
              />
              <div className="overlay">
                <Button
                  style={{ height: '100%', width: '100%' }}
                  icon
                  onClick={() => this.onClickPlaySlideshow()}
                >
                  <Icon name="play" size="huge" />
                </Button>
              </div>
            </div>
          </div>
        )}
        {this.props.nextViewMode !== 'SWIPE' &&
          !this.state.playingSlideshow && this.state.played && (
          <div style={{display: 'flex', height: '100%', width: '100%'}}>
          <div className="slideshowViewer">
            <div className="slideShowPreview">
              <div className="slideshowTitle">{this.props.slideshowTitle}</div>
              <GideImage 
                className="articleImage"
                src={this.props.slideshowImageUrl}
                alt={`□`}
              />
              <div className="overlay">
                <Button
                  style={{ height: '100%', width: '100%' }}
                  icon
                  onClick={() => this.onPlaySlideshow()}
                >
                  <Icon name="repeat" size="huge" />
                </Button>
              </div>
            </div>
          </div>
          </div>
        )}
        {this.state.playingSlideshow &&
          this.state.currentSlideshowSlide && (
            <>
              <SwipeView
                slide={this.state.currentSlideshowSlide}
                article={this.props.article}
                currentUser={this.props.currentUser}
                primarySlide={this.state.currentSlideshowSlide}
                showChrome={this.props.showChrome}
                displayViewBar={true}
                showCaptionPanel={this.props.showCaptionPanel}
                showInfoPanel={this.props.showInfoPanel}
                appName={this.props.appName}
                viewMode={this.props.viewMode}
                nextViewMode={this.props.nextViewMode}
                percentArticleCompleted={this.state.percentCompleted}
                percentEmbeddedGideCompleted={0}
                fileNum={0}
                slug={this.props.articleSlug}
                hasChildArticleSlideTypes={false}
                getNextSlide={this.showNextSlideshowSlide}
                getPreviousSlide={this.showPreviousSlideshowSlide}
                swipedLeft={this.showNextSlideshowSlide}
                swipedRight={this.showPreviousSlideshowSlide}
                zoomed={false}
                onTouchStart={() => {}}
                onTouchEnd={() => {}}
                handleSettingsPressed={() => {}}
                handleAudioEnded={() => {}}
                //setLoading={this.props.setLoading}
                setNextViewMode={this.props.setNextViewMode}
                onSetViewMode={this.props.onSetViewMode}
              />
              {this.props.showSlideshowControls && (
                <div className="slideshowPlayer">
                  {this.props.useAudioTrack &&
                    this.props.audioTrack && (
                      <div
                        className="slideshowAudioPlayer"
                        style={{ height: '24px' }}
                      >
                        {!this.state.playing && (
                          <GideImage 
                            style={{
                              width: '24px',
                              height: '24px',
                              zIndex: 1000,
                            }}                            
                            onClick={this.onPlay}
                            className={`pointer whites-normal-1000-svg`}
                            src="/icons/nav/media/player/play.svg"
                            alt={`□`}
                          />
                        )}
                        {this.state.playing && (
                          <GideImage 
                            style={{
                              width: '24px',
                              height: '24px',
                              zIndex: 1000,
                            }}
                            className={`pointer whites-normal-1000-svg`}
                            src="/icons/nav/media/player/pause.svg"
                            onClick={this.onPause}
                            alt="Pause Audio"
                          />
                        )}
                        <div className="audioCaptionLabel">
                          <span>Slideshow</span>&nbsp;<span>Audio</span>
                        </div>
                        <ReactPlayer
                          ref={(player: ReactPlayer) => {
                            this.player = player;
                          }}
                          className="react-player"
                          height="35px"
                          width="80%"
                          url={this.props.audioTrack}
                          playing={this.state.playing}
                          controls={false}
                          loop={this.state.loop}
                          onEnded={this.onEnded}
                          onError={e => console.log('onError', e)}
                        />
                      </div>
                    )}
                  <div className="audioCaptionLabel">
                    {this.state.slideshowPaused && (
                      <GideImage 
                        style={{ width: '24px', height: '24px', zIndex: 1000 }}
                        className={`pointer whites-normal-1000-svg`}
                        src="/icons/nav/media/player/play.svg"
                        onClick={this.resumeSlideshow}                        
                        alt={`□`}
                      />
                    )}
                    {!this.state.slideshowPaused && (
                      <GideImage 
                        style={{ width: '24px', height: '24px', zIndex: 1000 }}
                        className={`pointer whites-normal-1000-svg`}
                        src="/icons/nav/media/player/pause.svg"
                        onClick={this.pauseSlideshow}                        
                        alt="Pause Audio"
                      />
                    )}
                    <span>Slideshow</span>&nbsp;<span>Slides</span>
                  </div>
                </div>
              )}
            </>
          )}
      </div>
    );
  }
}
