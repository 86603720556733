import React, { Suspense, lazy } from 'react';
import { SwatchColorPicker } from 'office-ui-fabric-react/lib-commonjs/SwatchColorPicker';
import { RoundedCornerButton } from '../../../Shared/RoundedCornerButton/RoundedCornerButton';
import { CircleIconButton } from '../../../Shared/CircleIconButton/CircleIconButton';
import { ImageFile } from '../../../../models/SlideFile';
import { Loading } from '../../../Shared/Loading/Loading';
const SketchField = lazy(() => import('../../../../lib/SketchField/index'));

export enum DrawingTool {
  Circle = 'circle',
  Line = 'line',
  Pencil = 'pencil',
  Rectangle = 'rectangle',
  Select = 'select',
  Pan = 'pan'
}
export interface ImageDrawProps {
  image: ImageFile;
  lineColor?: string;
  lineWidth?: number;
  width: number;
  height: number;
  imageDrawTool: DrawingTool;
  saveImage: (image: any) => void;
}
enum TextState {
  Normal = 1,
  TransposedFull = 2,
  TransposedTransparency = 3
}
enum TextAlignment {
  Left = 1,
  Right = 2,
  Center = 3,
}

export interface ImageDrawState {
  selectedDrawingTool: DrawingTool,
  lineColor: string;
  lineWidth: number;
  srcImageLoadedCount: number;
  canvasData?: any;
  width: number;
  height: number;
  hideSketch: boolean;
  color?: string;
  cacheValue: number;
  itemIsSelected: boolean;
  textState: TextState;
  showAlignmentOptions: boolean;
  selectedTextAlignment: TextAlignment;
}
interface ColorChoice {
  id: string;
  label: string;
  color: string;
}

export default class ImageDraw extends React.Component<ImageDrawProps, ImageDrawState> {
  constructor(props: ImageDrawProps) {
    super(props);

    this.state = {
      selectedDrawingTool: props.imageDrawTool,
      lineColor: props.lineColor ? props.lineColor : 'Black',
      lineWidth: props.lineWidth ? props.lineWidth : 3,
      srcImageLoadedCount: -1,
      width: props.width,
      height: props.height,
      hideSketch: false,
      color: 'white',
      cacheValue: new Date().getTime(),
      itemIsSelected: false,
      textState: TextState.Normal,
      showAlignmentOptions: false,
      selectedTextAlignment: TextAlignment.Left,
    }
  }

  imageSketch?: any;
  srcImage?: HTMLImageElement | null;
  quickColors: ColorChoice[] = [
    { id: 'white', label: 'white', color: '#ffffff' },
    { id: 'black', label: 'orange', color: '#000000' },
    { id: 'blue', label: 'cyan', color: '#0094ff' },
    { id: 'yellow', label: 'blueMagenta', color: '#ffff00' },
    { id: 'orange', label: 'orange', color: '#ff5624' },
    { id: 'red', label: 'red', color: '#ff0000' },
    { id: 'pink', label: 'pink', color: '#fe00fc' },
    { id: 'green', label: 'green', color: '#0bd379' },
    { id: 'purple', label: 'pruple', color: '#a400ff' },

  ];
  componentDidUpdate(prevProps: ImageDrawProps, prevState: ImageDrawState) {
    if (this.state.srcImageLoadedCount !== prevState.srcImageLoadedCount) {
      if (this.imageSketch && this.imageSketch._canvas && this.srcImage) {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        canvas.width = this.srcImage.width;
        canvas.height = this.srcImage.height;
        const canvasContext = canvas.getContext('2d');
        if (canvasContext && this.srcImage) {
          canvasContext.drawImage(this.srcImage, 0, 0, this.srcImage.width, this.srcImage.height);
          const imageData = canvas.toDataURL('image/png');
          const imageSketchCanvas = this.imageSketch._canvas.getContext('2d');
          if (imageSketchCanvas) {
            imageSketchCanvas.clearRect(0, 0, this.imageSketch._canvas.width, this.imageSketch._canvas.height);
            // this.imageSketch.clearHistory();
            this.imageSketch.setBackgroundFromDataUrl('');
          }

          this.imageSketch.setBackgroundFromDataUrl(imageData, {
            stretched: true,
            stretchedX: false,
            stretchedY: false,
            originX: 'left',
            originY: 'top',
          });
        }
      }
    }

  }
  private getSelectedColor(): string {
    const colorChoice: ColorChoice | undefined = this.quickColors.find((c) => c.id === this.state.color);
    return colorChoice ? colorChoice.color : this.quickColors[1].color;
  }
  private getTransposedColors(fill: string, backgroundColor: string): { fill: string, backgroundColor: string } {
    if (!backgroundColor || backgroundColor === 'transparent') {
      if (fill === this.quickColors[0].color) { // Text Color is white
        this.setState({ textState: TextState.TransposedFull });
        return { fill: this.quickColors[1].color, backgroundColor: fill };
      } else {
        this.setState({ textState: TextState.TransposedFull });
        return { fill: this.quickColors[0].color, backgroundColor: fill };
      }
    } else {
      // See if we are in a transparency state
      if (backgroundColor.length === 9) {
        this.setState({ textState: TextState.Normal });
        return { fill: backgroundColor.replace('B5', ''), backgroundColor: 'transparent' };
      }
      this.setState({ textState: TextState.TransposedTransparency });
      return { fill: fill, backgroundColor: `${backgroundColor}B5` };
    }
  }
  private getTextIconClassName(textState: TextState): string {
    switch (textState) {
      case TextState.Normal:
        return "whites-normal-1000-svg";
      case TextState.TransposedFull:
        return "color-secondary-800-svg";
      case TextState.TransposedTransparency:
        return "whites-normal-800-svg";
    }
  }
  private getTextIconBackground(textState: TextState): string {
    switch (textState) {
      case TextState.Normal:
        return "transparent";
      case TextState.TransposedFull:
        return "white";
      case TextState.TransposedTransparency:
        return "rgba(0, 0, 0, .8)";
    }
  }
  private getSelectedAlignmentIcon(selectedTextAlignment: TextAlignment): string {
    switch (selectedTextAlignment) {
      case TextAlignment.Left:
        return "/icons/content-alteration/text-align/left.svg";
      case TextAlignment.Right:
        return "/icons/content-alteration/text-align/right.svg";
      case TextAlignment.Center:
        return "/icons/content-alteration/text-align/center.svg";
    }
  }
  private getTextItemAlignment(textItem: any): TextAlignment {
    if (!textItem.textAlign || textItem.textAlign === 'left') {
      return TextAlignment.Left;
    } else if (textItem.textAlign === 'right') {
      return TextAlignment.Right;
    } else {
      return TextAlignment.Center;
    }
  }
  private getTextItemState(textItem: any): TextState {
    if (!textItem.textBackgroundColor || textItem.textBackgroundColor === 'transparent') {
      return TextState.Normal;
    } else {
      // See if we are in a transparency state
      if (textItem.textBackgroundColor.length === 9) {
        return TextState.TransposedTransparency;
      }
      return TextState.TransposedFull;
    }
  }
  private addText(text: string): void {
    if (this.imageSketch) {
      this.imageSketch.addText(text, { fill: this.getSelectedColor() });
    }
  }
  private removeSelectedObject(): void {
    if (this.imageSketch) {
      this.imageSketch.removeSelected();
    }
  }
  public canUndo(): boolean {
    return this.imageSketch && this.imageSketch.canUndo();
  }

  public undo(): void {
    if (this.imageSketch && this.imageSketch.canUndo()) {
      this.imageSketch.undo();
    }
  }
  updateSketchSize(e: any) {
    // Save the current image state
    // Update the size of the sketch

    if (this.imageSketch) {
      const imageData = this.imageSketch.toDataURL('image/png');
      if (this.srcImage) {
        this.srcImage.crossOrigin = 'anonymous';
        this.srcImage.src = imageData;
      }
    }
    // Handle the size change in component did update

  }
  resizeEndTimer = setTimeout(() => { }, 400);
  waitForResizeEnd() {
    clearTimeout(this.resizeEndTimer);
    this.resizeEndTimer = setTimeout(this.updateSketchSize.bind(this), 400);
  }
  componentDidMount() {
    window.addEventListener('resize', this.waitForResizeEnd.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.waitForResizeEnd.bind(this));
  }
  public render() {
    const lineColor = this.getSelectedColor();
    return (
      <div className="imageDrawContainer">
        <div className="imageDraw">
          <Suspense fallback={<Loading />}>
            <SketchField
              width={this.state.width}
              height={this.state.height}
              className={`imageDrawSketchField?${this.state.hideSketch ? ' hideSketch' : ' hideSketch'}`}
              tool={this.state.selectedDrawingTool}
              lineColor={lineColor}
              lineWidth={3}
              ref={sketchField => (this.imageSketch = sketchField)}
              onChange={(e: any) => {
                if (this.imageSketch) {
                  const textItem = this.imageSketch.getTextSelection();
                  if (this.imageSketch.hasSelection() && this.state.itemIsSelected === false) {
                    if (textItem) {
                      this.setState({
                        itemIsSelected: true,
                        textState: this.getTextItemState(textItem),
                        selectedTextAlignment: this.getTextItemAlignment(textItem)
                      });
                    } else {
                      this.setState({ itemIsSelected: true, textState: TextState.Normal, selectedTextAlignment: TextAlignment.Left });
                    }
                  } else if (this.imageSketch.hasSelection() === false && this.state.itemIsSelected) {
                    this.setState({ itemIsSelected: false, textState: TextState.Normal, selectedTextAlignment: TextAlignment.Left });
                  } else if (this.imageSketch.hasSelection()) {
                    this.setState({
                      textState: this.getTextItemState(textItem),
                      selectedTextAlignment: this.getTextItemAlignment(textItem)
                    });
                  }
                }
              }}
            />
          </Suspense>
          <img 
            crossOrigin={'anonymous'}
            src={`${this.props.image.url}?_=${this.state.cacheValue}`}
            ref={srcImage => (this.srcImage = srcImage)}
            className={"sourceImage"}
            alt="hidden src"
            onLoad={() => {
              if (this.srcImage) {
                this.setState({ width: this.srcImage.width, height: this.srcImage.height, srcImageLoadedCount: this.state.srcImageLoadedCount + 1 });
              }
            }}
          // style={{ display: 'none' }}
          />
        </div>
        <div className="imageDrawActionContainer">
          {/* Do Not Remove: This span is for spacing and centering trick  */}
          <span style={{ height: '2px', backgroundColor: 'red' }}></span>
          <div className="imageDrawActions">
            <SwatchColorPicker
              onColorChanged={((id?: string, color?: string) => {
                this.setState({ color: id }, () => {
                  if (this.imageSketch) {
                    const item = this.imageSketch.getTextSelection();
                    if (item) {
                      const color = this.getSelectedColor();
                      this.imageSketch.setTextColor(color);
                    }
                  }
                });
              })}
              columnCount={9}
              selectedId={this.state.color}
              cellShape={'circle'}
              colorCells={this.quickColors}
              cellHeight={24}
              cellWidth={24}
              cellMargin={12.5}
              cellBorderWidth={1}
            />
            <div className="imageDrawActionButtons">
              <RoundedCornerButton
                label="Done"
                image="/icons/content-alteration/check/main.svg"
                iconCssClass="whites-normal-900-svg"
                imagePosition="right"
                style={{ margin: '5px', backgroundColor: 'var(--COLOR-PRIMARY-700)' }}
                alt="apply changes"
                labelColor="var(--WHITES-NORMAL-900)"
                onClick={() => {
                  if (this.imageSketch) {
                    this.props.saveImage(this.imageSketch.toDataURL('image/png'));
                  }
                }}
              />
            </div>
          </div>
          {/* Do Not Remove: This span is for spacing and centering trick  */}
          <span style={{ height: '2px', backgroundColor: 'red' }}></span>
        </div>
        {this.props.imageDrawTool === DrawingTool.Select && (
          <div className="imageDrawFloatingActions">
            {this.imageSketch && this.state.itemIsSelected && (
              <CircleIconButton
                image="/icons/nav/exit/main.svg"
                iconCssClass="whites-normal-1000-svg"
                alt="add text"
                style={{ margin: '5px' }}
                onClick={() => {
                  this.removeSelectedObject();
                  this.setState({ itemIsSelected: false });
                }}
                backgroundColor={'black'}
              />
            )}
            {this.imageSketch && !this.state.itemIsSelected && (
              <CircleIconButton
              image="/icons/nav/plusicon.svg"
                alt="add text"
                style={{ margin: '5px' }}
                onClick={() => { this.addText('text') }}
                backgroundColor={'black'}
                iconCssClass="whites-normal-1000-svg"
              />
            )}
            {this.imageSketch && this.state.itemIsSelected && (
              <div className="alignmentOptions">
                {this.state.showAlignmentOptions && (
                  <CircleIconButton
                    image="/icons/content-alteration/text-align/left.svg"
                    iconCssClass="whites-normal-1000-svg"
                    alt="align left"
                    style={{ margin: '5px' }}
                    onClick={() => {
                      if (this.imageSketch) {
                        const item = this.imageSketch.getTextSelection();
                        if (item) {
                          this.imageSketch.setTextAlignment('left');
                          this.setState({ showAlignmentOptions: false, selectedTextAlignment: TextAlignment.Left })
                        }
                      }
                    }}
                    backgroundColor={'#989898b5'}
                  />
                )}
                {this.state.showAlignmentOptions && (
                  <CircleIconButton
                    image="/icons/content-alteration/text-align/center.svg"
                    iconCssClass="whites-normal-1000-svg"
                    alt="align center"
                    style={{ margin: '5px' }}
                    onClick={() => {
                      if (this.imageSketch) {
                        const item = this.imageSketch.getTextSelection();
                        if (item) {
                          this.imageSketch.setTextAlignment('center');
                          this.setState({ showAlignmentOptions: false, selectedTextAlignment: TextAlignment.Center })
                        }
                      }
                    }}
                    backgroundColor={'#989898b5'}
                  />
                )}
                {this.state.showAlignmentOptions && (
                  <CircleIconButton
                    image="/icons/content-alteration/text-align/right.svg"
                    iconCssClass="whites-normal-1000-svg"
                    alt="align right"
                    style={{ margin: '5px' }}
                    onClick={() => {
                      if (this.imageSketch) {
                        const item = this.imageSketch.getTextSelection();
                        if (item) {
                          this.imageSketch.setTextAlignment('right');
                          this.setState({ showAlignmentOptions: false, selectedTextAlignment: TextAlignment.Right })
                        }
                      }
                    }}
                    backgroundColor={'#989898b5'}
                  />
                )}
                <CircleIconButton
                  image={this.getSelectedAlignmentIcon(this.state.selectedTextAlignment)}
                  alt="selected alignment"
                  iconCssClass="whites-normal-1000-svg"
                  style={{ margin: '5px' }}
                  onClick={() => {
                    if (this.imageSketch) {
                      this.setState({ showAlignmentOptions: true });
                    }
                  }}
                  backgroundColor={'black'}
                />
              </div>
            )}
            {this.imageSketch && this.state.itemIsSelected && (
              <CircleIconButton
                image='/icons/creationprocess/text-background.svg'
                iconCssClass={this.getTextIconClassName(this.state.textState)}
                alt="add text"
                style={{ margin: '5px' }}
                onClick={() => {
                  if (this.imageSketch) {
                    // Get the current colors
                    const item = this.imageSketch.getTextSelection();
                    if (item) {
                      const colors: { fill: string, backgroundColor: string } = this.getTransposedColors(item.fill, item.textBackgroundColor);
                      this.imageSketch.setTextAndBackgroundColor(colors.fill, colors.backgroundColor);
                    }
                  }
                }}
                backgroundColor={this.getTextIconBackground(this.state.textState)}
              />
            )}
          </div>
        )}
      </div>
    );
  }

}
