export enum DurationUnit {
  Year = 1,
  Month = 2,
  Week = 3,
  Day = 4,
  Hour = 5,
  Minute = 6,
  Second = 7
}
export enum ExpirationAction {
  Delete = 1, // Put in the owner's Trash Gide for slides or channel for gides
  Bye = 2, // Means it will not be returned to the user but not deleted
  Hide = 3, // Means it will be returned to the user but hidden from view
  Undefined = 4,
}
export enum ExpireBy {
  User = 1,
  Global = 2
}

export enum Expiration {
  DateTime = 1,
  Duration = 2,
  Location = 3,
  None = 4,
}
export class ExpirationSource {
  constructor(public expiration: Expiration) {}
}
export class DateTimeExpiration extends ExpirationSource {
  constructor(public dateTime: Date) {
    super(Expiration.DateTime)
  }
}
export class DurationExpiration extends ExpirationSource{
  constructor(public duration: number, public durationUnits: DurationUnit) {
    super(Expiration.Duration)
  }
}
export class LocationExpiration extends ExpirationSource {
  constructor(public latitude: string, public longitude: string, public duration?: number) {
    super(Expiration.Location)
  }
}

export interface ExpirationSettings {
  action: ExpirationAction;
  expireBy?: ExpireBy;
  source?: ExpirationSource;
}