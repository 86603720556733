import React from 'react';
import VideoViewer from '../VideoViewer/VideoViewer';
import GideImage from '../../../Shared/Image/GideImage';
import { VideoFile } from '../../../../models/SlideFile';

export interface VideoSwipeProps {
  videoList: VideoFile[];
}

export default function VideoSwipe(props: VideoSwipeProps) {
  return (
    <div className="gidevideoSwipeContainer">
      <div className="gidevideoSwipe">
        <div className="gidevideoSwipevideo1">
          {props.videoList.length > 0 && (
            <VideoViewer
              videoFile={props.videoList[0]}
            />
          )}
          {props.videoList.length <= 0 && (
            <GideImage 
            className='missingResourceIcon'
            src='/icons/content-alteration/camera/MISSING_RESOURCE_REPLACE.svg'
              alt="video"
            />
          )}
        </div>
        <div className="gidevideoSwipevideo2">
          {props.videoList.length > 1 && (
            <VideoViewer
              videoFile={props.videoList[1]}
            />
          )}
          {props.videoList.length <= 1 && (
            <GideImage 
              className='missingResourceIcon'
              src='/icons/content-alteration/camera/MISSING_RESOURCE_REPLACE.svg'
              alt="video"
            />
          )}
        </div>
        <div className="gidevideoSwipevideo3">
          {props.videoList.length > 2 && (
            <VideoViewer
              videoFile={props.videoList[2]}
            />
          )}
          {props.videoList.length <= 2 && (
            <GideImage 
            className='missingResourceIcon'
            src='/icons/content-alteration/camera/MISSING_RESOURCE_REPLACE.svg'
              alt="video"
            />
          )}
        </div>
        <div className="gidevideoSwipevideo4">
          {props.videoList.length > 3 && (
            <VideoViewer
              videoFile={props.videoList[3]}
            />
          )}
          {props.videoList.length <= 3 && (
            <GideImage 
            className='missingResourceIcon'
            src='/icons/content-alteration/camera/MISSING_RESOURCE_REPLACE.svg'
              alt="video"
            />
          )}
        </div>
        {props.videoList.length > 4 && (
          <div className="lastvideoOverlay">
            {`+${props.videoList.length - 4} Videos`}
          </div>
        )}
      </div>
    </div>
  );
}