import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { RichTextEditorSelect } from './RichTextEditorSelect';
/* Icons */
import { useSlate, ReactEditor } from 'slate-react';
import { RichTextEditorColor } from './RichTextEditorColor';
import { ReactComponent as TextColorIcon } from './icons/text-color.svg';
import { ReactComponent as HighlightIcon } from './icons/highlight.svg';
import { ReactComponent as LeftIcon } from './icons/left.svg';
import { ReactComponent as RightIcon } from './icons/right.svg';
import { ReactComponent as CenterIcon } from './icons/center.svg';
import { ReactComponent as JustifyIcon } from './icons/justify.svg';
import { setBlockJustification, getBlockProperty } from './Element';
import { getMarkProperty } from './Leaf';
import textEditorStyles from './_riche-text-editor.module.scss';
const useStyles = makeStyles({
  textColor: {
    '& path:nth-child(2)': {
      fill: (props: any) => props.textColor,
    },
  },
  highlightColor: {
    '& path:nth-child(1)': {
      fill: (props: any) => props.highlightColor,
    },
  },
});

export interface RichTextEditorTypographyToolbarProps {
  useNormalWidth: boolean;
}

export const RichTextEditorTypographyToolbar: React.FC<RichTextEditorTypographyToolbarProps> = (props) => {

  const editor = useSlate() as ReactEditor;
  // const useNormalWidth = useMediaQuery('(min-width:520px)');
  const defaults = {
    font: 'IBM Plex Sans',
    fontSize: 16,
    justification: 'left',
    color: '#000000',
    highlight: '#FFFFFF',
  };
  const values = useRef(defaults);
  const v = values.current;
  
  v.font = editor.selection 
    ? (getMarkProperty(editor, 'font') || defaults.font) 
    : v.font;
  v.fontSize = editor.selection 
    ? (getMarkProperty(editor, 'fontSize') || defaults.fontSize) 
    : v.fontSize;
  v.justification = editor.selection 
    ? (getBlockProperty(editor, 'justification') || defaults.justification) 
    : v.justification;
  v.color = editor.selection 
    ? (getMarkProperty(editor, 'color') || defaults.color) 
    : v.color;
  v.highlight = editor.selection 
    ? (getMarkProperty(editor, 'highlight') || defaults.highlight) 
    : v.highlight;

  const setFont = (value: string) => {
    console.log(value);
    editor.addMark('font', value);
    return value;
  }
  const setFontSize = (value: string) => {
    editor.addMark('fontSize', value);
    return value;
  }
  const setJustification = (value: string) => {
    setBlockJustification(editor, value);
    return value;
  }
  const setColor = (value: string) => {
    editor.addMark('color', value);
    return value;
  }
  const setHighlight = (value: string) => {
    editor.addMark('highlight', value);
    return value;
  }

  const styles = useStyles({
    textColor: v.color,
    highlightColor: v.highlight,
  });
  const ResponsiveEditors = (addMargin: boolean) => (
    <>
      {/* Color */}
      <RichTextEditorColor style={addMargin ? {marginLeft : '12px'} : {}} icon={<TextColorIcon className={styles.textColor} />} value={v.color} onChange={setColor} labelWidth={30} />

      {/* Highlight */}
      <RichTextEditorColor style={{marginLeft : '12px'}} icon={<HighlightIcon className={styles.highlightColor} />} value={v.highlight} onChange={setHighlight} labelWidth={30} />
    </>
  );
  const fonts = [
    { label: 'IBM Plex sans', value: 'IBM Plex Sans' }, /* Default Font */
    { label: 'HILVCC Boing', value: 'HILVCCBoing' },
    { label: 'Open Sans', value: 'Open Sans' },
    { label: 'Roboto', value: 'Roboto' },
    { label: 'Lato', value: 'Lato' },
    { label: 'Oswald', value: 'Oswald' },
    { label: 'Source Sans Pro', value: 'Source Sans Pro' },
    { label: 'Montserrat', value: 'Montserrat' },
    { label: 'PT Sans', value: 'PT Sans' },
    { label: 'Raleway', value: 'Raleway' },
    { label: 'Lora', value: 'Lora' },
    { label: 'Tahoma', value: 'Tahoma,Geneva,sans-serif' },
  ];

  const fontSizes = [
    { label: "8", value: 8 },
    { label: "10", value: 10 },
    { label: "12", value: 12 },
    { label: "14", value: 14 },
    { label: "16", value: 16 }, /* Default Font Size */
    { label: "18", value: 18 },
    { label: "24", value: 24 },
    { label: "36", value: 36 },
  ];

  const justifications = [
    { label: <LeftIcon />, value: 'left' }, /* Default Justification */
    { label: <RightIcon />, value: 'right' },
    { label: <CenterIcon />, value: 'center' },
    { label: <JustifyIcon />, value: 'justify' },
  ];

  return (
    <div className={textEditorStyles.typographyContainer}>
      <div style={{
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: props.useNormalWidth ? 24 : 16,
        paddingRight: props.useNormalWidth ? 24 : 16, 
        borderStyle: 'solid',
        borderColor: '#F4F5F5',
        borderWidth: 1,
        display: 'flex',
        justifyContent: 'flex-start'
      }}>
        {/* Font */}
        <RichTextEditorSelect options={fonts} value={v.font} onChange={setFont} labelWidth={132} />

        {/* Font Size */}
        <RichTextEditorSelect options={fontSizes} style={{marginLeft : '12px'}} value={v.fontSize} onChange={setFontSize} labelWidth={30} />

        {/* Justification */}
        <RichTextEditorSelect options={justifications} style={{marginLeft : '12px'}} value={v.justification} onChange={setJustification} labelWidth={30} />
        {props.useNormalWidth && 
          ResponsiveEditors(true)
        }
      </div>
      {!props.useNormalWidth && (
        <div className={textEditorStyles.responsiveTypographyItems}>
          {ResponsiveEditors(false)}
        </div>
      )}
    </div>
  );
}
