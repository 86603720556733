import * as React from 'react';
import { Slide } from '../../../models/Slide';
import { ImageGalleryFile } from '../../SlideEditors/ImageGallerySlideEditor/ImageGallerySlideEditor';
import ImageGallery, { ImageGalleryImageSelectEvent } from '../../Viewers/ImageViewers/ImageGallery/ImageGallery';
import { hasValue } from '../../../utils/helperFunctions';
import AudioPlayer, { AudioPlayerType } from '../../media/AudioPlayer/AudioPlayer';
import { User } from '../../../models/User';
import { MODAL_OPEN, MODAL_CLOSE } from '../../../constants/actionTypes';
import ImageSlide from '../ImageSlide';
import { connect } from 'react-redux';
import { ImageFile } from '../../../models/SlideFile';

export interface ImageGallerySlideProps {
  slide: Slide;
  currentUser: User;
  openModal: (payload: any) => void;
  closeModal: () => void;
}
const mapDispatchToProps = (dispatch: any) => ({
  openModal: (payload: any) => dispatch({ type: MODAL_OPEN, payload: payload }),
  closeModal: () => dispatch({ type: MODAL_CLOSE })
});

export class ImageGallerySlide extends React.Component<ImageGallerySlideProps> {
  getSlideShowSlides(imageGalleryFile: ImageGalleryFile) {
    const slides = imageGalleryFile.imageFiles.map((image: ImageFile, i: number) => {
      return {
        ...this.props.slide,
        slideType: 'IMAGE',
        position: i,
        data: {
          files: [{
            ...image,
            onSelect: undefined, // PREVENT INFINITE ABILITY TO SELECT
            caption: '',
            audioCaption: undefined,
            attribution: '',
          }],
          caption: image.caption,
          audioCaption: image.audioCaption,
          attribution: image.attribution,
        },
        autoAdvanceSlide: image.audioCaption ? image.audioCaption.autoAdvance : false,
        autoPlayAudioCaption: image.audioCaption ? image.audioCaption.autoAdvance : false,
      };
    });
    const swipeableItems = slides.map((slide, i) => {
      const component = <ImageSlide key={i} {...this.props}
        slide={slide}
        viewMode="SWIPE"
      />;
      return {
        component,
        slide
      }
    }
    );
    return swipeableItems;
  }
  public render() {
    return (
      <div className="imageGallerySlide">
        {this.props.slide.data.files.map((imageGalleryFile: ImageGalleryFile, index: number) => (
          <div key={`ImageGalleryFile${index}`}>
            {hasValue(imageGalleryFile.title) && (
              <div className="imageGalleryTitle">
                {imageGalleryFile.title}
              </div>
            )}
            <ImageGallery
              onClick={(event: ImageGalleryImageSelectEvent) => {
                this.props.openModal({
                  modalType: 'SwipeViewModal',
                  modalProps: {
                    showHeader: true,
                    closeModal: this.props.closeModal,
                    closeOnNavigatePastLastItem: true,
                    swipeableItems: this.getSlideShowSlides(imageGalleryFile),
                    currentUser: this.props.currentUser,
                    totalNumberOfSlides: imageGalleryFile.imageFiles.length,
                    startAtIndex: event.imageIndex
                  },
                });
              }}
              imageGallery={imageGalleryFile}
              imageGalleryViewerMode={this.props.slide.data.slideTypeSpecificSettings.displayType}
              marginBottom={!hasValue(imageGalleryFile.caption) && this.props.slide.data.files.length - 1 > index ? '15px' : '0px'}
            />
            {/* {hasValue(imageGalleryFile.caption) && (
              <div className="imageGalleryCaption"
                style={{marginBottom : (!hasValue(imageGalleryFile.caption) && this.props.slide.data.files.length - 1 > index) ? '15px' : '0px'}}
              >
                {imageGalleryFile.caption}
              </div>
            )} */}

            {(hasValue(imageGalleryFile.caption) ||
              hasValue(imageGalleryFile.attribution ||
                hasValue(imageGalleryFile.audioCaption))) && (
                <div className="imageGalleryFileCaptionContainer">
                  {hasValue(imageGalleryFile.attribution) && (
                    <span className="TEXTSUBTITLEblackhigh-emphasisleft">
                      Photo by:
                        <span style={{ marginLeft: '5px' }} className="TEXTSUBTITLEblackboldlow-emphasisleft">
                        {imageGalleryFile.attribution}
                      </span>
                    </span>
                  )}
                  {hasValue(imageGalleryFile.caption) && (
                    <span className="TEXTBODYblackregularmedium-emphasisleft" style={{ marginRight: '5px' }}>
                      {imageGalleryFile.caption}
                    </span>
                  )}
                  {imageGalleryFile.audioCaption && (
                    <AudioPlayer
                      autoPlay={false}
                      src={imageGalleryFile.audioCaption.url}
                      playerType={AudioPlayerType.AudioCaption}
                      color={'blue'}
                    />
                  )}
                </div>
              )}

          </div>
        ))}
      </div>
    );
  }
}
export default connect(null, mapDispatchToProps)(ImageGallerySlide);