import { Author } from './../models/Author';
import { Profile } from './../models/Profile';
import { User } from './../models/User';

export const getUserFullName = (user: User | Author | Profile): string => {
  if(user.firstName || user.secondName) {
    return `${user.firstName ? user.firstName : ''} ${user.secondName ? user.secondName : ''}`;
  } else {
    return user.username;
  }
}
