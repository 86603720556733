import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { CompactPicker } from 'react-color';
import DropZone from 'react-dropzone';
import {
  Modal,
  Icon,
  Input,
  Button,
  Menu,
  Dropdown,
  Image,
} from 'semantic-ui-react';

import SlideModalActions from './SlideModalActions';
import SlideSettings from '../SlideEditors/SlideSettings/SlideSettings';
import { getSlideSettings, updateSlideSettings } from '../SlideEditors/SlideSettings/SlideSettings';

import agent from '../../agent';
import slideTools from '../../slideTools';
import {
  ADD_SLIDE,
  REPLACE_SLIDE,
  SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES,
} from '../../constants/actionTypes';
import { contentMetaData } from '../../constants/contentMetaData';
import { history } from '../Viewers/ImageViewers/ImageView/ImageView';
import Tools from '../../lib/SketchField/tools';
import { Loading } from '../Shared/Loading/Loading';

const SketchField = lazy(() => import('../../lib/SketchField'));

const dropZoneStyles = {
  display: 'none',
  width: '0',
  height: '0',
};

const TOOLS = [
  { key: 1, text: 'Pencil', value: Tools.Pencil },
  { key: 2, text: 'Select', value: Tools.Select },
  { key: 3, text: 'Line', value: Tools.Line },
];

const mapStateToProps = slideTools.mapStateToProps;

const mapDispatchToProps = dispatch => ({
  onSubmit: (replaceSlide, payload) =>
    dispatch({ type: replaceSlide ? REPLACE_SLIDE : ADD_SLIDE, payload }),
  updateSlideAttachmentInfo: payload =>
    dispatch({ type: SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES, payload }),
});

export class DoodleModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tool: TOOLS[0],
      lineColor: 'black',
      addTextMode: false,
      newText: '',
      slide: {
        slideType: 'DOODLE',
        data: {
          audioCaption: null,
          caption: '',
        },
      },
      showSettings: false,
      replaceMode: false,
    };

    // Populates the new slide with the settings from the parent slide which is this.props.slide
    if (props.mode === 'REPLACE' && props.slide) {
      this.state.replaceMode = true;
      const inputSlide = props.slides.find(s => s.id === props.slide);
      if (inputSlide) {
        const settings = getSlideSettings(inputSlide);
        this.state.slide = updateSlideSettings(this.state.slide, settings);
      }
    }

    if (props.editSlide) {
      this.state.slide = props.editSlide;
    }

    this.updateSlideSettings = settings => {
      this.setState({ slide: updateSlideSettings(this.state.slide, settings) });
    };

    this.audioCaptionChanged = audioFile => {
      const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
      slide.data.audioCaption = audioFile;
      this.setState({ slide });
    };

    this.closeSettings = () => {
      this.setState({ showSettings: false });
    };

    this.updateState = field => ev => {
      const { state } = this;
      const newState = Object.assign({}, state, { [field]: ev.target.value });
      this.setState(newState);
    };

    this.updateDataState = field => ev => {
      let slide = Object.assign({}, this.state.slide);
      slide.data[field] = ev.target.value;
      this.setState({ slide });
    };

    this.selectTool = (e, data) => {
      const arr = TOOLS.filter(tool => {
        return tool.value === data.value;
      });
      if (arr.length) {
        this.setState({
          tool: arr[0],
        });
      }
    };

    this.createSlide = async () => {
      let slidePosition = this.props.currentSlidePosition ? this.props.currentSlidePosition : this.props.position;
      let slide = {
        ...this.state.slide,
        slide: this.props.slide,
        createMode: this.props.mode,
        selection: props.selection,
        allowComments: this.props.editSlide
          ? this.state.slide.allowComments
          : this.props.article.allowSlideComments,
        allowQuestions: this.props.editSlide
          ? this.state.slide.allowQuestions
          : this.props.article.allowSlideQuestions,
        // If editing, don't change the position.
        position: this.props.editSlide
          ? this.state.slide.position
          : slidePosition,
      };
      slide.data.dataURL = this._sketch.toDataURL();
      let payload;
      if (this.state.replaceMode) {
        let replaceSlideId = slide.slide;
        slide.slide = null;
        payload = await agent.Slides.replace(
          this.props.article,
          replaceSlideId,
          slide,
        );
        payload = {
          ...payload,
          slideIdToRemove: replaceSlideId,
        };
      } else {
        if (this.props.editSlide) {
          payload = await agent.Slides.update(this.props.editSlide.id, slide);
        } else {
          payload = await agent.Slides.create(this.props.article, slide);
          if (this.props.childArticleEditInfo) {
            slideTools.getSlideAttachmentInfo(
              this.props.childArticleEditInfo.ownerSlide.id,
              this.props.updateSlideAttachmentInfo,
            );
          }
        }
      }
      this.props.onSubmitSlide ?
      this.props.onSubmitSlide({ ...payload, mode: this.props.mode }) :
      this.props.onSubmit(this.state.replaceMode, {
        ...payload,
        mode: this.props.mode,
      });
      history.goBack()
    };
  }

  onSelectAddText() {
    this.setState(prevState => ({
      addTextMode: !prevState.addTextMode,
    }));
  }

  onAddText() {
    this._sketch.addText(this.state.newText);
    this.setState({
      addTextMode: false,
      newText: '',
      tool: TOOLS[1],
    });
  }

  onSelectUploadImage() {
    this._dropZone.open();
  }

  onDrop(acceptedFiles, rejectedFiles) {
    if (this.state.imageEditing) {
      window.URL.revokeObjectURL(this.state.imageEditing);
    }
    setTimeout(() => {
      this.editImage(acceptedFiles[0]);
    }, 1000);
  }

  editImage(file) {
    this.setState({ imageEditing: file });
    let sketch = this._sketch;
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () =>
        sketch.setBackgroundFromDataUrl(reader.result, {
          stretched: true,
          stretchedX: false,
          stretchedY: false,
          originX: 'left',
          originY: 'top',
        }),
      false,
    );
    reader.readAsDataURL(file);
  }

  render() {
    const { slide } = this.state;
    return (
      <Modal
        closeOnEscape={true}
        onClose={() => history.goBack()}
        size="small"
        dimmer="inverted"
        closeOnDocumentClick={false}
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <div
            className="modalHeader"
            style={{ background: 'rgb(122, 96, 155)' }}
          >
            <Button id="modalClose" icon onClick={() => history.goBack()}>
              <Icon name="close" />
            </Button>
            <span>Doodle Type</span>
            <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-doodle.svg" />
          </div>
          {!this.state.showSettings && (
            <Modal.Description>
              <DropZone
                onDrop={this.onDrop.bind(this)}
                disableClick={true}
                style={dropZoneStyles}
                multiple={false}
                ref={c => (this._dropZone = c)}
              />
              <section>
                {this.state.addTextMode && (
                  <Input
                    className="addTextWidget"
                    value={this.state.newText}
                    onChange={this.updateState('newText')}
                    icon={
                      <Icon
                        name="checkmark"
                        inverted
                        circular
                        link
                        color="green"
                        onClick={this.onAddText.bind(this)}
                      />
                    }
                  />
                )}
                <Suspense fallback={<Loading />}>
                  <SketchField
                    width="640px"
                    height="300px"
                    tool={this.state.tool.value}
                    lineColor={this.state.lineColor}
                    lineWidth={3}
                    ref={c => (this._sketch = c)}
                  />
                </Suspense>
              </section>
              <section>
                <CompactPicker
                  id="lineColor"
                  color={this.state.lineColor}
                  onChange={color => this.setState({ lineColor: color.hex })}
                />
                <Menu compact>
                  <Dropdown
                    text={this.state.tool.text}
                    item
                    options={TOOLS}
                    onChange={this.selectTool}
                    value={this.state.tool.value}
                  />
                </Menu>
                <Button.Group style={{ float: 'right' }}>
                  <Button icon onClick={this.onSelectAddText.bind(this)}>
                    <Icon name="font" />
                  </Button>
                  <Button icon onClick={this.onSelectUploadImage.bind(this)}>
                    <Icon name="image" />
                  </Button>
                </Button.Group>
              </section>
            </Modal.Description>
          )}
          {this.state.showSettings && (
            <Modal.Description className="settingsPanel">
              <SlideSettings
                canSetIsTemplate={this.props.article.type === 'TEMPLATE'}
                settings={getSlideSettings(
                  this.state.slide,
                  this.props.article.type,
                )}
                onSettingsChanged={this.updateSlideSettings.bind(this)}
                onCloseSettings={this.closeSettings.bind(this)}
              />
            </Modal.Description>
          )}
        </Modal.Content>
        <Modal.Actions
          style={{ background: contentMetaData['DOODLE'].primaryColor }}
        >
          <SlideModalActions
            caption={slide.data.caption}
            captionChanged={this.updateDataState('caption').bind(this)}
            audioCaption={slide.data.audioCaption}
            onAudioCaptionChanged={this.audioCaptionChanged.bind(this)}
            showSettings={this.state.showSettings}
            canNavigateBack={false}
            settingsClicked={() =>
              this.setState({ showSettings: !this.state.showSettings })
            }
            nextClicked={this.createSlide}
            settings={getSlideSettings(
              this.state.slide,
              this.props.article.type,
            )}
            onSettingsChanged={this.updateSlideSettings.bind(this)}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DoodleModal);
