import * as React from 'react';
import { Slide } from '../../../models/Slide';
import GideImage from '../../Shared/Image/GideImage';

export enum HideType {
  Gide = 1,
  Slide = 2,
}
export interface HideSlideProps {
  slide: Slide;
}

export default class HideSlide extends React.Component<HideSlideProps> {
  public render() {
    return (
      <div className="hideSlide">
        <GideImage src='/icons/creationprocess/events.svg'
          alt={`${this.props.slide.data.entityType === HideType.Gide ? 'Gide is Hidden' : 'Slide is hidden'}`}
        />
      </div>
    );
  }
}
