export interface Author {
  connections: {following: any []};
  favorites: any[];
  following: boolean;
  id: string;
  username: string;
  image: string;
  verified: boolean;
  views: any[];
  country?: string;
  language?: string;
  business?: boolean;
  firstName?: string;
  secondName?: string;
  businessFocus?: string;
  publishedGides?: number;
  publicChannels?: number;
}

export const NullAuthor: Author = {
  connections: {following: []},
  favorites: [],
  following: false,
  id: '',
  username: '',
  image: '',
  verified: true,
  views: []
}

export const MockAuthor: Author = {
  connections: {following: []},
  favorites: [],
  following: false,
  id: '1',
  username: '',
  image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFs55HhEt6w0kYAHuY89BygETYobDZWOyn1rInBkxqBc_lIWPs&s',
  verified: true,
  views: []
}
