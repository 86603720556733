import React from 'react';
import { Slide } from '../../../models/Slide';
import { User } from '../../../models/User';
import Header from '../../Header';
import { isNullOrUndefined } from 'util';
import Swipeable from 'react-swipeable';
import SlideView, { ViewMode } from '../../Gide/SlideView/SlideView';
import AudioPlayer, { AudioPlayerType } from '../../media/AudioPlayer/AudioPlayer';
import classNames from 'classnames';
import { SwipeHeaderNavigation } from './SwipeHeaderNavigation';
import SlideshowSlide from '../SlideshowSlide/SlideshowSlide';
import { Article } from '../../../models/Article';
import GideImage from '../../Shared/Image/GideImage';
const createDOMPurify = require('dompurify');
export interface SwipeViewProps {
  slide: Slide;
  article: Article;
  currentUser: User;
  primarySlide: Slide;
  parentHeader?: Slide;
  showChrome: boolean;
  displayViewBar: boolean;
  zoomed: boolean;
  showInfoPanel: boolean;
  showCaptionPanel: boolean;
  appName: string;
  viewMode: ViewMode;
  nextViewMode: ViewMode;
  percentArticleCompleted: number;
  percentEmbeddedGideCompleted?: number;
  fileNum?: number;
  slug: string;
  hasChildArticleSlideTypes: boolean;
  headerSlides?: Slide[];
  headerIsCollapsed?: boolean;
  headerSlideLabel?: string;
  canNavigateToPreviousHeader?: boolean;
  previousHeaderSection?: string;
  nextHeaderSection?: string;
  numberOfSlidesUnderCurrentHeader?: number;
  onCollapseSlide?: (slide: Slide) => void;
  onClickPreviousHeaderSlide?: (e: any) => void;
  onClickNextHeaderSlide?: (e: any) => void;
  getNextSlide: () => void;
  getPreviousSlide: () => void;
  swipedLeft: (e: any, absX: any) => void;
  swipedRight: (e: any, absX: any) => void;
  onTouchStart: (e: any) => void;
  onTouchEnd: (e: any) => void;
  handleSettingsPressed: (e: any, slide: Slide) => void;
  handleAudioEnded: () => void;
  setNextViewMode: (mode: ViewMode) => void;
  onSetViewMode: (mode: ViewMode) => void;
  // setLoading: () => void;
}

export const SwipeView = (props: SwipeViewProps) => {
  const getFileType = (slide: Slide) => {
    if (slide && slide.slideType === 'FILE') {
      if (slide.data.files && slide.data.files.length === 1) {
        let nameParts = slide.data.files[0].name.split('.');
        if (nameParts.length > 1) {
          return nameParts[nameParts.length - 1].toUpperCase();
        }
      }
    }
    return null;
  };

  const articleContentClasses = classNames(
    'row',
    'article-content',
    'flexColumnFull',
    props.slide ? props.slide.slideType : '',
    getFileType(props.slide),
    props.showChrome ? 'chrome' : 'no-chrome',
    props.zoomed ? 'zoomed' : '',
  );

  const slideViewClasses = classNames(
    'col-xs-12',
    'flexColumnFull',
    props.parentHeader ? 'parentHeader' : 'noParentHeader',
    props.slide && props.slide.slideType === 'GIDE'
      ? 'viewingEmbeddedGide'
      : 'notViewingEmbeddedGide',
  );

  // These classes are for the header that shows up when a slide is a child of a header
  const parentHeaderClasses = classNames(
    'swipeHeader swipeHeaderEllipsis',
    props.parentHeader
      ? props.parentHeader.data.level === 0
        ? 'ti'
        : `headerLevel${props.parentHeader.data.level}`
      : null,
  );

  const captionPanelClasses = classNames(
    'overlayCaption',
  );

  const slideCaptionClasses = classNames(
    'flexColumnFull',
    'slideCaptionInnerContainer',
    props.slide
      ? props.showCaptionPanel &&
        props.slide.data.caption &&
        props.slide.data.audioCaption
        ? ''
        : 'singleLineOverlay'
      : '',
  );

  // Determine whether to hide or show the Caption Panel
  const showCaptionPanel = () => {
    return (
      props.slide &&
      ((props.showChrome && props.showCaptionPanel) || props.showCaptionPanel)
    );
  };

  // Determine whether to hide or show the Info Panel
  const showInfoPanel = () => {
    return (
      props.primarySlide &&
      ((props.showChrome && props.showInfoPanel) || props.showInfoPanel)
    );
  };

  if (
    props.slide &&
    (props.nextViewMode === 'SLIDESHOW' || !props.displayViewBar)
  ) {
    // return (<div style={{height: '100%', width: '100%'}}>
    //   <SlideshowSlide {...props} />
    // </div>);
    const slideshowSlideClasses =
      props.slide.slideType === 'SLIDESHOW'
        ? 'article-page slide flexColumnFull'
        : '';
    // const slideshowSlideStyle = props.slide.slideType === 'SLIDESHOW' ? {height: '100%'} : {};
    const slideshowSlideStyle = { height: '100%' };
    return (
      <div className={slideshowSlideClasses} style={slideshowSlideStyle}>
        {props.slide.slideType === 'SLIDESHOW' && (
          // <SlideshowSlide {...props} />
          // <div className="article-page slide flexColumnFull" style={{height: '100%'}}>
          // <span style={{height: '100%', width: '100%'}}>
          <>
            {!props.showChrome && (
              <div className="overlaySwipeHeader">
                <Header
                  showLeftSidebar={false}
                  appName={props.appName}
                  currentUser={props.currentUser}
                  nextViewMode={props.nextViewMode}
                  percentArticleCompleted={props.percentArticleCompleted}
                />
              </div>
            )}
            <SlideshowSlide {...props} />
          </>
        )}
        {props.slide.slideType !== 'SLIDESHOW' && (
          // <div style={{height: '100%'}}>
          <>
            {/* The following 2 divs are the hover overlays that sit on the right/left in swipe/non-mobile to allow the user to navigate between the slides */}
            <div className="swipeNavigatePreviousOverlay">
              <GideImage 
                onClick={props.getPreviousSlide}
                className="swipeOveralyActionIcon"
                src="/icons/nav/arrow/arrow-left.svg"
                alt={`□`}
              />
            </div>
            <div className="swipeNavigateNextOverlay">
              <GideImage 
                onClick={props.getNextSlide}
                className="swipeOveralyActionIcon swipeRight"
                src="/icons/nav/arrow/arrow-right.svg"
                alt={`□`}
              />
            </div>
            {showInfoPanel && (
              <div className="swipeInfoPanel">
                {props.primarySlide.slideType === 'GIDE' && (
                  <div className="embeddedGideInfo">
                    <div
                      className="embeddedGideInfoAuthor"
                      style={{ width: '24px', marginLeft: '7px' }}
                    >
                      <GideImage
                        src={props.primarySlide.data.gide.author.image}
                        alt={props.primarySlide.data.gide.author.username}
                        className="embedded-gide-author-pic"
                      />
                    </div>
                    <div className="flexRowFlexWrap">
                      <span className="embedGidePercentAndLabel">
                        {props.percentEmbeddedGideCompleted &&
                          props.percentEmbeddedGideCompleted > 0
                          ? `${props.percentEmbeddedGideCompleted} % Finished`
                          : ''}
                        <span style={{ marginLeft: '5px' }}>
                          EMBEDDED by{' '}
                          {props.primarySlide.data.gide.author.username}
                        </span>
                      </span>
                      <span className="embedGideTitle swipeHeaderEllipsis">
                        <div>
                          &quot;{props.primarySlide.data.gide.title}&quot;
                        </div>
                      </span>
                    </div>
                  </div>
                )}
                {!isNullOrUndefined(props.parentHeader) &&
                  props.primarySlide &&
                  props.primarySlide.slideType !== 'GIDE' && (
                    <span
                      className={parentHeaderClasses}
                      style={{ textAlign: 'left' }}
                    >
                      {/* Have to replace <p> tags becuase of semantic ui trying to style p tags. */}
                      {
                        <div
                          dangerouslySetInnerHTML={{
                            __html: createDOMPurify.sanitize(props.parentHeader.data.title
                              .replace('<p>', '')
                              .replace('</p>', '')),
                          }}
                        />
                      }
                    </span>
                  )}
              </div>
            )}
            <Swipeable
              className="swipeableContainer flexColumnFull"
              style={{ height: '100%' }}
              onSwipedLeft={props.swipedLeft}
              onSwipedRight={props.swipedRight}
            >
              <div className={articleContentClasses}>
                {props.slide && (
                  <div className={slideViewClasses}>
                    {props.slide.slideType === 'HEADER' && (
                      <SwipeHeaderNavigation
                        slide={props.slide}
                        headerIsCollapsed={props.headerIsCollapsed}
                        headerSlideLabel={props.headerSlideLabel}
                        canNavigateToPreviousHeader={
                          props.canNavigateToPreviousHeader
                        }
                        previousHeaderSection={props.previousHeaderSection}
                        nextHeaderSection={props.nextHeaderSection}
                        numberOfSlidesUnderCurrentHeader={
                          props.numberOfSlidesUnderCurrentHeader
                        }
                        onCollapseSlide={props.onCollapseSlide}
                        onClickNextHeaderSlide={props.onClickNextHeaderSlide}
                        onClickPreviousHeaderSlide={
                          props.onClickPreviousHeaderSlide
                        }
                      />
                    )}
                    <div
                      className="flexColumnFull"
                      onTouchStart={props.onTouchStart}
                      onTouchEnd={props.onTouchEnd}
                    >
                      <SlideView
                        // className="flexColumnFull"
                        slide={props.slide}
                        slidePosition={props.slide.position}
                        removeBottomMargin={true}
                        // showChrome={props.showChrome}
                        currentUser={props.currentUser}
                        // slug={props.slug}
                        fileNum={props.fileNum}
                        viewMode={props.viewMode}
                        nextViewMode={props.nextViewMode}
                        view={'SWIPE'}
                        viewOnly={true}
                        article={props.article}
                      />
                    </div>
                  </div>
                )}
              </div>
            </Swipeable>
            {showCaptionPanel && (
              <div style={{ display: 'flex' }} className={captionPanelClasses}>
                <div className={slideCaptionClasses}>
                  {props.slide.data.caption && (
                    <div className="flexRowCenter">
                      <div className="slideTextCaption">
                        {props.slide.data.caption}
                      </div>
                    </div>
                  )}
                  {/* TODO: Attribution (Now Media Credit)  */}
                  {/* TODO: Attachments  */}
                  {(props.hasChildArticleSlideTypes || props.slide.link) && (
                    <div
                      className="captionAreaAttachments"
                      onClick={e => props.handleSettingsPressed(e, props.slide)}
                    >
                      <span>
                        <GideImage src="/icons/paper-clip-arrow.svg"
                          style={{ width: '34px', height: '34px' }}
                          alt={`□`}
                        />
                      </span>
                      {props.slide.link && (
                        <span>
                          <GideImage
                            className="color-blue-100-svg"
                            src="/icons/slidetype/links/main.svg"
                          />
                        </span>
                      )}
                    </div>
                  )}
                  {props.slide.data.audioCaption && (
                    <div className="slideAudioCaptionContainer">
                      <AudioPlayer
                        playerType={props.viewMode === 'SWIPE' ? AudioPlayerType.AudioCaption : AudioPlayerType.Other}
                        autoPlay={
                          props.viewMode === 'SWIPE' &&
                            props.slide.autoPlayAudioCaption ? true : false
                        }
                        src={props.slide.data.audioCaption.url}
                        audioEnded={props.handleAudioEnded}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* </div> */}
          </>
        )}
        {/* {props.slide.slideType === 'SLIDESHOW' &&
          props.viewMode === 'SWIPE' &&
          props.nextViewMode === 'SLIDESHOW' &&
          props.showChrome && (
            <ViewBar
              headerSlides={props.headerSlides}
              onPrev={props.getPreviousSlide}
              onNext={props.getNextSlide}
              swipeMode={true}
            />
          )} */}
      </div>
    );
  } else {
    return (
      <div
        className="article-page slide flexColumnFull"
        style={{ height: '100%' }}
      >
        <div className="container page flexColumnFull">
          {/* This duplicate div is important because it limits the touch area */}
          <div className="container page flexColumnFull">
            {!props.showChrome && (
              <div className="overlaySwipeHeader">
                <Header
                  showLeftSidebar={false}
                  appName={props.appName}
                  currentUser={props.currentUser}
                  nextViewMode={props.nextViewMode}
                  percentArticleCompleted={props.percentArticleCompleted}
                />
              </div>
            )}
            {/* The following 2 divs are the hover overlays that sit on the right/left in swipe/non-mobile to allow the user to navigate between the slides */}
            <div className="swipeNavigatePreviousOverlay">
              <GideImage 
                onClick={props.getPreviousSlide}
                className="swipeOveralyActionIcon"
                src="/icons/nav/arrow/arrow-left.svg"
                alt={`□`}
              />
            </div>

            <div className="swipeNavigateNextOverlay">
              <GideImage 
                onClick={props.getNextSlide}
                className="swipeOveralyActionIcon swipeRight"
                src="/icons/nav/arrow/arrow-right.svg"
                alt={`□`}
              />
            </div>

            {showInfoPanel && (
              <div className="swipeInfoPanel">
                {props.primarySlide.slideType === 'GIDE' && (
                  <div className="embeddedGideInfo">
                    <div
                      className="embeddedGideInfoAuthor"
                      style={{ width: '24px', marginLeft: '7px' }}
                    >
                      <GideImage
                        src={props.primarySlide.data.gide.author.image}
                        alt={props.primarySlide.data.gide.author.username}
                        className="embedded-gide-author-pic"
                      />
                    </div>
                    <div className="flexRowFlexWrap">
                      <span className="embedGidePercentAndLabel">
                        {props.percentEmbeddedGideCompleted &&
                          props.percentEmbeddedGideCompleted > 0
                          ? `${props.percentEmbeddedGideCompleted} % Finished`
                          : ''}
                        <span style={{ marginLeft: '5px' }}>
                          EMBEDDED by{' '}
                          {props.primarySlide.data.gide.author.username}
                        </span>
                      </span>
                      <span className="embedGideTitle swipeHeaderEllipsis">
                        <div>
                          &quot;{props.primarySlide.data.gide.title}&quot;
                        </div>
                      </span>
                    </div>
                  </div>
                )}
                {!isNullOrUndefined(props.parentHeader) &&
                  props.primarySlide &&
                  props.primarySlide.slideType !== 'GIDE' && (
                    <span
                      className={parentHeaderClasses}
                      style={{ textAlign: 'left' }}
                    >
                      {/* Have to replace <p> tags becuase of semantic ui trying to style p tags. */}
                      {
                        <div
                          dangerouslySetInnerHTML={{
                            __html: createDOMPurify.sanitize(props.parentHeader.data.title
                              .replace('<p>', '')
                              .replace('</p>', '')),
                          }}
                        />
                      }
                    </span>
                  )}
              </div>
            )}
            <Swipeable
              className="swipeableContainer flexColumnFull"
              onSwipedLeft={props.swipedLeft}
              onSwipedRight={props.swipedRight}
            >
              <div className={articleContentClasses}>
                {props.slide && (
                  <div className={slideViewClasses}>
                    {props.slide.slideType === 'HEADER' && (
                      <SwipeHeaderNavigation
                        slide={props.slide}
                        headerIsCollapsed={props.headerIsCollapsed}
                        headerSlideLabel={props.headerSlideLabel}
                        canNavigateToPreviousHeader={
                          props.canNavigateToPreviousHeader
                        }
                        previousHeaderSection={props.previousHeaderSection}
                        nextHeaderSection={props.nextHeaderSection}
                        numberOfSlidesUnderCurrentHeader={
                          props.numberOfSlidesUnderCurrentHeader
                        }
                        onCollapseSlide={props.onCollapseSlide}
                        onClickNextHeaderSlide={props.onClickNextHeaderSlide}
                        onClickPreviousHeaderSlide={
                          props.onClickPreviousHeaderSlide
                        }
                      />
                    )}
                    <div
                      className="flexColumnFull"
                      onTouchStart={props.onTouchStart}
                      onTouchEnd={props.onTouchEnd}
                    >
                      <SlideView
                        slide={props.slide}
                        slidePosition={props.slide.position}
                        removeBottomMargin={true}
                        currentUser={props.currentUser}
                        viewMode={props.viewMode}
                        nextViewMode={props.nextViewMode}
                        view={'SWIPE'}
                        viewOnly={true}
                        article={props.article}
                        // className="flexColumnFull"
                        // slide={props.slide}
                        // removeBottomMargin={true}
                        // showChrome={props.showChrome}
                        // currentUser={props.currentUser}
                        // slug={props.slug}
                        fileNum={props.fileNum}
                      // viewMode={props.viewMode}
                      // nexViewMode={props.nextViewMode}
                      // viewOnly={true}
                      />
                    </div>
                  </div>
                )}
              </div>
            </Swipeable>
          </div>
          {showCaptionPanel && (
            <div style={{ display: 'flex' }} className={captionPanelClasses}>
              <div className={slideCaptionClasses}>
                {props.slide.data.caption && (
                  <div className="flexRowCenter">
                    <div className="slideTextCaption">
                      {props.slide.data.caption}
                    </div>
                  </div>
                )}
                {/* TODO: Attribution (Now Media Credit)  */}
                {/* TODO: Attachments  */}
                {(props.hasChildArticleSlideTypes || props.slide.link) && (
                  <div
                    className="captionAreaAttachments"
                    onClick={e => props.handleSettingsPressed(e, props.slide)}
                  >
                    <span>
                      <GideImage src="/icons/paper-clip-arrow.svg"
                        style={{ width: '34px', height: '34px' }}
                        alt={`□`}
                      />
                    </span>
                    {props.slide.link && (
                      <span>
                        <GideImage
                          className="color-blue-100-svg"
                          src="/icons/slidetype/links/main.svg"
                        />
                      </span>
                    )}
                  </div>
                )}
                {props.slide.data.audioCaption && (
                  <div className="slideAudioCaptionContainer">
                    <AudioPlayer
                      playerType={props.viewMode === 'SWIPE' ? AudioPlayerType.AudioCaption : AudioPlayerType.Other}
                      autoPlay={
                        props.viewMode === 'SWIPE' &&
                          props.slide.autoPlayAudioCaption ? true : false
                      }
                      src={props.slide.data.audioCaption.url}
                      audioEnded={props.handleAudioEnded}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {/* {props.displayViewBar &&
          props.showChrome && (
            <ViewBar
              headerSlides={props.headerSlides}
              onPrev={props.getPreviousSlide}
              onNext={props.getNextSlide}
              swipeMode={true}
            />
          )} */}
      </div>
    );
  }
};
