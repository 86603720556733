import React, { Component } from 'react';
import superagent from 'superagent';
import { connect } from 'react-redux';
import { Modal, List, Image, Button, Icon } from 'semantic-ui-react';

import agent from '../../agent';
import { API_ROOT } from '../../constants/paths';
import {
  ADD_SLIDE,
  REPLACE_SLIDE,
  SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES,
} from '../../constants/actionTypes';
import slideTools from '../../slideTools';

import SlideModalActions from './SlideModalActions';
import SlideSettings from '../SlideEditors/SlideSettings/SlideSettings';
import { getSlideSettings, updateSlideSettings } from '../SlideEditors/SlideSettings/SlideSettings';

import DropZone from 'react-dropzone';
import { history } from '../../store';
let dropZoneStyles = {
  width: '100%',
  height: '150px',
  backgroundColor: '#efefef',
  cursor: 'pointer',
  padding: '55px 0 0 0',
};

const mapStateToProps = slideTools.mapStateToProps;

const mapDispatchToProps = dispatch => ({
  onSubmit: (replaceSlide, payload) =>
    dispatch({ type: replaceSlide ? REPLACE_SLIDE : ADD_SLIDE, payload }),
  updateSlideAttachmentInfo: payload =>
    dispatch({ type: SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES, payload }),
});

export class FileModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slide: {
        slideType: 'FILE',
        data: {
          audioCaption: null,
          caption: '',
          files: [],
        },
      },
      showSettings: false,
      replaceMode: false,
    };

    // Populates the new slide with the settings from the parent slide which is this.props.slide
    if (props.mode === 'REPLACE' && props.slide) {
      this.state.replaceMode = true;
      const inputSlide = props.slides.find(s => s.id === props.slide);
      if (inputSlide) {
        const settings = getSlideSettings(inputSlide);
        this.state.slide = updateSlideSettings(this.state.slide, settings);
      }
    }

    this.updateSlideSettings = settings => {
      this.setState({ slide: updateSlideSettings(this.state.slide, settings) });
    };

    this.closeSettings = () => {
      this.setState({ showSettings: false });
    };

    this.audioCaptionChanged = audioFile => {
      const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
      slide.data.audioCaption = audioFile;
      this.setState({ slide });
    };

    if (props.editSlide) {
      this.state.slide = props.editSlide;
    }

    this.updateDataState = field => ev => {
      const slide = Object.assign({}, this.state.slide);
      slide.data[field] = ev.target.value;
      this.setState({ slide });
    };

    this.createSlide = async () => {
      let slidePosition = this.props.currentSlidePosition ? this.props.currentSlidePosition : this.props.position;
      let slide = {
        ...this.state.slide,
        slide: this.props.slide,
        createMode: this.props.mode,
        selection: props.selection,
        allowComments: this.props.editSlide
          ? this.state.slide.allowComments
          : this.props.article.allowSlideComments,
        allowQuestions: this.props.editSlide
          ? this.state.slide.allowQuestions
          : this.props.article.allowSlideQuestions,
        // If editing, don't change the position.
        position: this.props.editSlide
          ? this.state.slide.position
          : slidePosition,
      };
      let payload;
      if (this.state.replaceMode) {
        let replaceSlideId = slide.slide;
        slide.slide = null;
        payload = await agent.Slides.replace(
          this.props.article,
          replaceSlideId,
          slide,
        );
        payload = {
          ...payload,
          slideIdToRemove: replaceSlideId,
        };
      } else {
        if (this.props.editSlide) {
          payload = await agent.Slides.update(this.props.editSlide.id, slide);
        } else {
          payload = await agent.Slides.create(this.props.article, slide);
          if (this.props.childArticleEditInfo) {
            slideTools.getSlideAttachmentInfo(
              this.props.childArticleEditInfo.ownerSlide.id,
              this.props.updateSlideAttachmentInfo,
            );
          }
        }
      }
      this.props.onSubmitSlide ?
      this.props.onSubmitSlide({ ...payload, mode: this.props.mode }) :
      this.props.onSubmit(this.state.replaceMode, {
        ...payload,
        mode: this.props.mode,
      });
      history.goBack();
    };
  }

  onDrop(acceptedFiles, rejectedFiles) {

    acceptedFiles.forEach(f => {
      superagent
        .post(`${API_ROOT}/util/upload`)
        .attach('theseNamesMustMatch', f)
        .end((err, res) => {
          if (err) {
            console.log(err);
            return alert(
              'Sorry, there was a problem.  Please try again later.',
            );
          }
          let slide = Object.assign({}, this.state.slide);
          let file = {
            name: f.name,
            size: f.size,
            url: res.body.url,
          };
          slide.data.files = [...slide.data.files, file];
          this.setState({ slide });
        });
    });
  }

  render() {
    const { slide } = this.state;
    return (
      <Modal
        closeOnEscape={true}
        onClose={() => history.goBack()}
        className="fileModal"
        size="small"
        dimmer="inverted"
        open={true}
        style={{ background: 'rgb(227, 99, 100)' }}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <div
            className="modalHeader"
            style={{ background: 'rgb(227, 99, 100)' }}
          >
            <Button id="modalClose" icon onClick={() => history.goBack()}>
              <Icon name="close" />
            </Button>
            <span>File Type</span>
            <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-image.svg" />
          </div>
          {!this.state.showSettings && (
            <Modal.Description>
              <section>
                {slide.data.files.length > 0 && (
                  <aside className="pad">
                    <List relaxed bulleted selection size="large">
                      {slide.data.files.map(f => (
                        <List.Item key={f.name}>{f.name}</List.Item>
                      ))}
                    </List>
                  </aside>
                )}
                <aside>
                  <div className="dropzone">
                    <DropZone
                      onDrop={this.onDrop.bind(this)}
                      style={dropZoneStyles}
                    >
                      <p>Drop files or select.</p>
                    </DropZone>
                  </div>
                </aside>
              </section>
            </Modal.Description>
          )}
          {this.state.showSettings && (
            <Modal.Description className="settingsPanel">
              <SlideSettings
                canSetIsTemplate={this.props.article.type === 'TEMPLATE'}
                settings={getSlideSettings(
                  this.state.slide,
                  this.props.article.type,
                )}
                onSettingsChanged={this.updateSlideSettings.bind(this)}
                onCloseSettings={this.closeSettings.bind(this)}
              />
            </Modal.Description>
          )}
        </Modal.Content>
        <Modal.Actions>
          <SlideModalActions
            caption={slide.data.caption}
            captionChanged={this.updateDataState('caption').bind(this)}
            audioCaption={slide.data.audioCaption}
            onAudioCaptionChanged={this.audioCaptionChanged.bind(this)}
            showSettings={this.state.showSettings}
            canNavigateBack={false}
            settingsClicked={() =>
              this.setState({ showSettings: !this.state.showSettings })
            }
            nextClicked={this.createSlide}
            settings={getSlideSettings(
              this.state.slide,
              this.props.article.type,
            )}
            onSettingsChanged={this.updateSlideSettings.bind(this)}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileModal);
