import React, { useState, useEffect } from 'react';
import AvatarMenuSection from './AvatarMenuSection/AvatarMenuSection';
import { Accordion, SemanticShorthandItem, AccordionPanelProps } from 'semantic-ui-react';
import { isNil } from 'ramda';
import icons from '../../assets/icons';
import ChannelListItemPreview from '../Shared/Previews/ChannelPreview/ChannelListItemPreview/ChannelListItemPreview';
import GideListItemPreview, { ItemSelectionMode } from '../Shared/Previews/GidePreview/GideListItemPreview/GideListItemPreview';
import styles from './_avatar-menu.module.scss';
import UserTile, { UserTileStyle } from '../Shared/Previews/UserTile/UserTile';
import Avatar from '../Shared/Avatar/Avatar';
import { User } from '../../models/User';
import { Link } from 'react-router-dom';
import { isReadOnlyDevice } from '../../utils/helperFunctions';
import GideImage from '../Shared/Image/GideImage';
import classNames from 'classnames';
import { RoundedCornerButton } from '../Shared/RoundedCornerButton/RoundedCornerButton';
import { ASSET_VERSION_NUMBER } from '../../constants/strings';


export interface Shortcut {
  id: string;
  sortIndex: number;
  shortcutEntityId: string;
  shortcutType: string;
  shortcutEntityType?: string;
  authorUserName: string;  
  authorCountry: string;
  authorImageUrl: string | null;
  shortcutImageUrl: string;
  focus: string;
  shortcutEntitySlideLength?: number;
  displayName: string;
  createdAt: Date;
  updatedAt: Date;
}
export interface UserShortcut extends Shortcut {
  followers: number;
  fullName: string;
}


export interface AvatarMenuProps {
  clearActiveMenu?: {};
  // menuSections: ReactNode[];
  channelFavorites: Shortcut[];
  shortcuts: Shortcut[];
  currentUser?: User | null;
  isExpandedOpen: boolean;
  setIsExpandedOpen: (isExpandedOpen: boolean) => void;
  displayCloseButton: boolean;
  closeGidesMenu: () => void;
  onLogout: () => void;
  onOpenUserShortcutModal: () => void;
  onCreateGide: () => void;
  onNavigateToAbout: () => void;
  onNavigateToContactUs: () => void;
  onNavigateToAllChannels: () => void;
  onNavigateToPrivacyPolicy: () => void;
  onAvatarMenuOpened?: () => void;
  onSignUp: () => void;
  onSignIn: () => void;
  onViewProfile: (username: string) => void;
}
export default function AvatarMenu(props: AvatarMenuProps) {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
  if (activeIndex !== undefined && (!props.isExpandedOpen)) {
    setActiveIndex(undefined);
  }

  useEffect(() => {
    setActiveIndex(undefined);
  }, [ props.clearActiveMenu ]);

  const menuItems: SemanticShorthandItem<AccordionPanelProps>[]  = [
    {
      key: 'Shortcuts',
      onTitleClick: () => {
        if(activeIndex === 0) {
          setActiveIndex(undefined);
        } else {
          setActiveIndex(0);
        }
      },
      index: 0,
      active: activeIndex === 0 ? true : false,
      title: <AvatarMenuSection {...{
          onClick: () => {
            if(activeIndex === 0) {
              setActiveIndex(undefined);
            } else {
              setActiveIndex(0);
            }
          },
          expanded: activeIndex === 0,
          menuTitleImage: icons.Nav_Shortcuts,
          title: 'Your Shortcuts'
        }}
      />,
      content: <div className={styles.menuItemContent}
        style={{display: activeIndex === 0 ? 'flex' : 'none'}}
      >
       {props.shortcuts.map((shortcut: Shortcut | UserShortcut) => (
           <div className={styles.userShortuct} key={shortcut.id}>
            {shortcut.shortcutType === 'Channel' && (
              <Link to={`/channel/${shortcut.shortcutEntityId}`}>
                <ChannelListItemPreview
                    title={shortcut.displayName}
                    image={shortcut.shortcutImageUrl}
                    authorImage={!isNil(shortcut.authorImageUrl) ? shortcut.authorImageUrl : ''}
                    author={shortcut.authorUserName}
                    authorCountry={shortcut.authorCountry}
                    type={shortcut.shortcutEntityType as string}
                    onClick={() => {
                      props.closeGidesMenu();
                    }}
                    selectionMode={ItemSelectionMode.SingleDisplayNone}
                />
              </Link>
             )}
              {shortcut.shortcutType === 'Article' && (
                 <Link to={`/${shortcut.authorUserName}/${shortcut.shortcutEntityId}`}>
                  <GideListItemPreview
                      title={shortcut.displayName}
                      image={shortcut.shortcutImageUrl}
                      authorImage={!isNil(shortcut.authorImageUrl) ? shortcut.authorImageUrl : ''}
                      author={shortcut.authorUserName}
                      authorCountry={shortcut.authorCountry}
                      createdAt={new Date() } //shortcut.createdAt}
                      updatedAt={new Date() } // shortcut.updatedAt}
                      slideCount={shortcut.shortcutEntitySlideLength ? shortcut.shortcutEntitySlideLength : 0}
                      onClick={() => {
                        props.closeGidesMenu();
                      }}
                      selectionMode={ItemSelectionMode.SingleDisplayNone}
                  />
                </Link>
              )}
              {shortcut.shortcutType === 'User' && (
                <UserTile
                  username={shortcut.displayName}
                  fullName={(shortcut as UserShortcut).fullName}
                  userImage={shortcut.shortcutImageUrl}
                  category={shortcut.focus}
                  channels={9999}
                  followers={(shortcut as UserShortcut).followers}
                  userLanguage={'English'}
                  gides={9999}
                  tileStyle={UserTileStyle.ProfileListMain}
                  isShortcut={true}
                  onClick={() => {
                    props.closeGidesMenu();
                    props.onViewProfile(shortcut.displayName);
                  }}
                  country={'us'} // TODO: TODO: TODO:
                />
              )}
           </div>
         ))}
          <div className={styles.hyperlinkButton}
            onClick={() => {
              props.onOpenUserShortcutModal();
            }}
          >
            <span>EDIT YOUR SHORTCUTS</span>
          </div>
      </div>
    },
    {
      key: 'Channels',
      onTitleClick: () => {
        if(activeIndex === 1) {
          setActiveIndex(undefined);
        } else {
          setActiveIndex(1);
        }
      },
      index: 1,
      active: activeIndex === 1 ? true : false,
      title: <AvatarMenuSection {...{
          // active: activeIndex === 1,
          onClick: () => {
            if(activeIndex === 1) {
              setActiveIndex(undefined);
            } else {
              setActiveIndex(1);
            }
          },
          expanded: activeIndex === 1,
          menuTitleImage: icons.Nav_Channels,
          title: 'Your Channels'
        }}
      />,
      content: <div className={styles.menuItemContent} style={{display: activeIndex === 1 ? 'flex' : 'none'}}>
       {props.channelFavorites &&
         props.channelFavorites.map((channelShortcut: Shortcut) => (
           <div className={styles.channelFavorite} key={channelShortcut.id}>
             <Link to={props.currentUser ? `?profile=${props.currentUser.username}&page=profile&view=channel&channelSlug=${channelShortcut.shortcutEntityId}` : ''}>
             {/* <Link to={`/channel/${channelShortcut.shortcutEntityId}`}> */}
              <ChannelListItemPreview
                title={channelShortcut.displayName}
                image={channelShortcut.shortcutImageUrl}
                authorImage={!isNil(channelShortcut.authorImageUrl) ? channelShortcut.authorImageUrl : ''}
                author={channelShortcut.authorUserName}
                authorCountry={channelShortcut.authorCountry}
                type={channelShortcut.shortcutEntityType as string}
                onClick={() => {
                  props.closeGidesMenu();
                }}
                selectionMode={ItemSelectionMode.SingleDisplayNone}
              />
            </Link>
           </div>
         ))}
         <Link to={props.currentUser ? `?profile=${props.currentUser.username}&page=profile&view=channel` : ''}>
          <div className={styles.hyperlinkButton}
              onClick={() => {
                props.closeGidesMenu();
                props.onNavigateToAllChannels();
              }}
            >
            <span>VIEW MORE</span>
          </div>
        </Link>
      </div>,
  },
    {
      key: 'New',
      index: 2,
      onTitleClick: () => {setActiveIndex(2)},
      active: activeIndex === 2 ? true : false,
      title: <AvatarMenuSection {...{
            onClick: props.onCreateGide,
            menuTitleImage: icons.ContentAlteration_Newgide,
            title: 'New Gide'
          }}
        />
    },
    {
      key: 'About',
      index: 3,
      active: activeIndex === 3 ? true : false,
      onTitleClick: () => {setActiveIndex(3)},
      title: <AvatarMenuSection {...{
          onClick: () => {props.closeGidesMenu(); props.onNavigateToAbout()},
          menuTitleImage: icons.Nav_Logo_LogoIconSm,
          title: 'About Gides'
        }}
      />
    },
    {
      key: 'Privacy',
      index: 4,
      active: activeIndex === 4 ? true : false,
      onTitleClick: () => {setActiveIndex(4)},
      title: <AvatarMenuSection {...{
            onClick: () => {props.closeGidesMenu(); props.onNavigateToPrivacyPolicy()},
            menuTitleImage: icons.SlideType_File_Main,
            title: 'Privacy Policy'
          }}
        />
      },
      {
        key: 'Contact',
        index: 5,
        active: activeIndex === 5 ? true: false,
        onTitleClick: () => {setActiveIndex(5)},
        title: <AvatarMenuSection {...{
          onClick: () => {props.closeGidesMenu(); props.onNavigateToContactUs()},
            menuTitleImage: icons.SlideType_Contact_Main,
            title: 'Contact Us'
          }}
        />
      },
      {
        key: 'Logout',
        index: 6,
        active: activeIndex === 6 ? true : false,
        onTitleClick: () => {setActiveIndex(6)},
        title: <AvatarMenuSection {...{
          onClick: () => {props.onLogout()},
          menuTitleImage: icons.Nav_LogOut,
          title: 'Log out',
          doNotFillFromColor: true,
          }}
        />
      },
  ];
  const loggedOutMenuItems : SemanticShorthandItem<AccordionPanelProps>[] = [
    {
      key: 'About',
      index: 0,
      active: activeIndex === 3 ? true : false,
      onTitleClick: () => {setActiveIndex(3)},
      title: <AvatarMenuSection {...{
          onClick: () => {props.closeGidesMenu(); props.onNavigateToAbout()},
          menuTitleImage: icons.Nav_Logo_LogoIconSm,
          title: 'About Gides'
        }}
      />
    },
    {
      key: 'Privacy',
      index: 1,
      active: activeIndex === 4 ? true : false,
      onTitleClick: () => {setActiveIndex(4)},
      title: <AvatarMenuSection {...{
            onClick: () => {props.closeGidesMenu(); props.onNavigateToPrivacyPolicy()},
            menuTitleImage: icons.SlideType_File_Main,
            title: 'Privacy Policy'
          }}
        />
      },
      {
        key: 'Contact',
        index: 2,
        active: activeIndex === 5 ? true: false,
        onTitleClick: () => {setActiveIndex(5)},
        title: <AvatarMenuSection {...{
          onClick: () => {props.closeGidesMenu(); props.onNavigateToContactUs()},
            menuTitleImage: icons.SlideType_Contact_Main,
            title: 'Contact Us'
          }}
        />
      }
  ];
  return (
    <div className={styles.avatarMenuContainer}>
      <div className={styles.avatarMenuHeader}>
        {props.currentUser && (
          <Avatar
            avatarImage={props.currentUser.image ? props.currentUser.image : '/icons/content-alteration/follower.svg'}
            displayOuterBorder={true}
            outerBorderColor="var(--COLOR-PRIMARY-700)"
            imageWidthAndHeight={48}
            style={{marginLeft: '7px'}}
          />
        )}

        <div className={classNames(styles.headerTitleSection, isNil(props.currentUser) ? styles.readOnly : '')}
          onClick={props.closeGidesMenu}
        >
          {props.currentUser && (
            <>
              <span className={styles.headerTitle}>
                {props.currentUser.username}
              </span>
              <Link to={props.currentUser ? `?profile=${props.currentUser.username}&page=profile` : ''}>
                <div className={styles.profileLink}>
                  View profile
                </div>
              </Link>
            </>
          )}

          {!props.currentUser && (
            // <span className={styles.profileLink}
            //   onClick={props.onSignUp}
            // >
            //   Request Access for an account
            // </span>
            <div className={styles.avatarReadonlyBranding}>
              <GideImage
                src={`https://d2ettyk3s9h19.cloudfront.net/assets/images/brand-assets/hamburger.png?v=${ASSET_VERSION_NUMBER}`}
                className={styles.brandingImage}
              />
              <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '20px', marginBottom: '12px', padding: '0 5px'}}>
                <RoundedCornerButton
                  label="Request Access"
                  onClick={() => props.onSignUp()}
                  style={
                    {backgroundImage: 'linear-gradient(277deg, var(--COLOR-PRIMARY-700) 100%, #fab317 29%)', width: isReadOnlyDevice() !== true ? '150px' : '330px'}
                  }
                />
                {isReadOnlyDevice() !== true && (
                  <RoundedCornerButton
                    label="Sign in"
                    onClick={() => props.onSignIn()}
                    style={
                      {backgroundImage: 'linear-gradient(277deg, var(--COLOR-PRIMARY-700) 100%, #fab317 29%)', width: '150px'}
                    }
                  />
                )}                                
              </div>
              <span className={styles.brandingImageLabel}>Stand out from the crowd!</span>
            </div>
          )}
        </div>
        {props.displayCloseButton && (
          <icons.Nav_Exit_Main
            color="var(--COLOR-SECONDARY-500)"
            style={{marginRight: '11px', cursor: "pointer"}}
            onClick={props.closeGidesMenu}
          />
        )}
        {!props.displayCloseButton && props.isExpandedOpen && props.currentUser && (
          <icons.Nav_Arrow_ArrowUp
            color="var(--COLOR-SECONDARY-500)"
            style={{marginRight: '11px', cursor: "pointer"}}
            onClick={() => props.setIsExpandedOpen(false)}
          />
        )}
        {!props.displayCloseButton && !props.isExpandedOpen  && props.currentUser && (
          <icons.Nav_Arrow_ArrowDown
            color="var(--COLOR-SECONDARY-500)"
            style={{marginRight: '11px', cursor: "pointer"}}
            onClick={() => {
              props.onAvatarMenuOpened && props.onAvatarMenuOpened();
              props.setIsExpandedOpen(true);
            }}
          />
        )}
      </div>
      {(props.displayCloseButton === true ||
       props.isExpandedOpen) && (
        <Accordion
          panels={props.currentUser ? menuItems : loggedOutMenuItems}
          activeIndex={activeIndex}
          exclusive={true}
          styled
        />
       )}
    </div>
  );
}
