import { AuthConfig } from "../../models/AuthConfig";
import { ROUTE_TO_MODAL, SET_HOME_PAGE_GIDES_LOADING } from "../../constants/actionTypes";

export const ROUTER_LOCATION_CHANGE = '@@router/LOCATION_CHANGE';
export const TOGGLE_ZOOM = 'TOGGLE_ZOOM';
export const DISTRIBUTE_GIDE = 'DISTRIBUTE_GIDE';
export const EXIT_DISTRIBUTE_GIDE = 'EXIT_DISTRIBUTE_GIDE';
export const RENAVIGATE_TO_SAME_ARTICLE = 'RENAVIGATE_TO_SAME_ARTICLE';
export const CONFIG_LOADED = 'CONFIG_LOADED';


export interface RouterLocation {

}
export interface RouterPayload {
  location: {
    pathname: string;
    search: string;
    hash: string;
    state?: any;
  },
  action: string;
  isFirstRendering: boolean;
}
export interface GideAction_ROUTER_LOCATION_CHANGE {
  type: typeof ROUTER_LOCATION_CHANGE,
  payload: RouterPayload
}

interface GideAction_TOGGLE_ZOOM {
  type: typeof TOGGLE_ZOOM;
}
interface GideAction_DISTRIBUTE_GIDE {
  type: typeof DISTRIBUTE_GIDE;
}
interface GideAction_EXIT_DISTRIBUTE_GIDE {
  type: typeof EXIT_DISTRIBUTE_GIDE;
}

interface GideAction_RENAVIGATE_TO_SAME_ARTICLE {
  type: typeof RENAVIGATE_TO_SAME_ARTICLE;
  payload: {ignore: boolean}
}

interface GideAction_CONFIG_LOADED {
  type: typeof CONFIG_LOADED;
  payload: {config: AuthConfig};
}

interface GideAction_ROUTE_TO_MODAL {
  type: typeof ROUTE_TO_MODAL;
  payload: {
    modalType: string,
    modalProps?: any,
  };
}
interface GideAction_HOME_PAGE_LOAD {
  type: typeof SET_HOME_PAGE_GIDES_LOADING;
}

export type CommonActions =
  GideAction_ROUTER_LOCATION_CHANGE
  | GideAction_TOGGLE_ZOOM
  | GideAction_DISTRIBUTE_GIDE
  | GideAction_EXIT_DISTRIBUTE_GIDE
  | GideAction_RENAVIGATE_TO_SAME_ARTICLE
  | GideAction_CONFIG_LOADED
  | GideAction_ROUTE_TO_MODAL
  | GideAction_HOME_PAGE_LOAD;
