import React from 'react';
import { Input } from 'semantic-ui-react';
import { AudioCapture } from '../media/AudioCapture';
import GideImage from '../Shared/Image/GideImage';

export default props => {
  return (
    <div 
      style={{ display: 'flex', alignItems: 'center' }}>
      <GideImage src="/icons/nav/settings.svg"
        alt={`□`}
        className="whites-normal-1000-svg"
        onClick={props.settingsClicked}
        style={{ cursor: 'pointer' }}
      />
      {!props.showSettings &&
        props.canNavigateBack && (
          <span className="pointer backBtn" onClick={props.backClicked}>
            Back
          </span>
        )}
      <Input
        style={{ flex: 1}}
        placeholder="Caption"
        value={props.caption}
        onChange={props.captionChanged}
      />
      {/* <div style={{ marginTop: 17 }}>
        <Checkbox
          onChange={(evt, data) => {
            const settings = {
              ...props.settings,
              autoPlayAudioCaption: data.checked,
            };
            props.onSettingsChanged(settings);
          }}
          checked={props.settings.autoPlayAudioCaption}
          label="AP"
          style={{ margin: '0 5px' }}
          title="Auto Play Audio Caption"
        />
        <Checkbox
          onChange={(evt, data) => {
            const settings = {
              ...props.settings,
              autoAdvanceSlide: data.checked,
            };
            props.onSettingsChanged(settings);
          }}
          checked={props.settings.autoAdvanceSlide}
          label="AA"
          title="Auto Advance when Audio Caption ends"
        />
      </div> */}
      {props.onAudioCaptionChanged && (
        <AudioCapture
          audioFile={props.audioCaption}
          onAudioCaptionChanged={props.onAudioCaptionChanged}
        />
      )}
      {!props.showSettings &&
        !props.disableNextButton === true && (
          <span
            className="nextBtn"
            onClick={props.nextClicked}
            style={{ marginLeft: '5px' }}
          >
            Next
          </span>
        )}
    </div>
  );
};
