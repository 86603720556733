import { User } from "../../models/User";

export const LOAD_USERS = 'LOAD_USERS';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';

interface GideAction_LOAD_USERS {
  type: typeof LOAD_USERS;
  payload: {
    pageSize: number,
    skip: number,
  };
}
interface GideAction_LOAD_USERS_SUCCESS {
  type: typeof LOAD_USERS_SUCCESS;
  payload: {
    users: User[],
  };
}

export type AdministrationActions =
| GideAction_LOAD_USERS
| GideAction_LOAD_USERS_SUCCESS;
