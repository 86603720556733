import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export interface CheckboxChangeEvent {
  checked: boolean;
}

export interface CheckBoxProps {
  className?: string;
  label?: string;
  name?: string;
  checked?: boolean;
  change: (e: CheckboxChangeEvent) => void;
}

export const CheckBox: React.FC<CheckBoxProps> = (props) => {
  return (
    <FormGroup row>
      <FormControlLabel
        className={props.className}
        label={props.label}
        control={
          <Checkbox
            checked={props.checked}
            onChange={(e) => props.change(e.target)}
            name={props.name}
          />
        }
        />
    </FormGroup>
  );
}
