import React from 'react';

// import ToolbarIconGlobal from './ToolbarIconGlobal';

import './_InlineToolbarGlobal.scss';

// const AUTHOR_INLINE_STYLES = [
//   { label: 'P', style: 'NOTE_PRIVATE' },
//   { label: 'M', style: 'MAGNASLIDE' },
// ];
// const VIEWER_INLINE_STYLES = [
//   { label: 'N', style: 'NOTE' },
//   { label: 'P', style: 'NOTE_PRIVATE' },
// ];

export default ({ onToggle, position, isOwner }) => {
  return (
    <div className="toolbar" id="inlineToolbarGlobal" style={position}>
      {/* <ul className="toolbar-icons">
        {isOwner && AUTHOR_INLINE_STYLES.map(type => (
          <ToolbarIconGlobal
            key={type.label || type.icon}
            label={type.label}
            icon={type.icon}
            style={type.style}
            onToggle={onToggle}
          />
        ))}
        {!isOwner && VIEWER_INLINE_STYLES.map(type => (
          <ToolbarIconGlobal
            key={type.label || type.icon}
            label={type.label}
            icon={type.icon}
            style={type.style}
            onToggle={onToggle}
          />
        ))}        
      </ul> */}
    </div>
  );
};
