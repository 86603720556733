import React, { Component, Suspense, lazy } from 'react';
import { InlineTextEditor } from '../InlineTextEditor.js';
import agent from '../../agent';
import { formatDisplayTextWithSearchMarkup, saveInlineTextEdits } from '../../utils/helperFunctions';
import SearchContext from '../Shared/SearchBar/SearchContext';
import { RichTextEditor as NewRichTextEditor } from '../RichTextEditor/RichTextEditor';

const RichTextEditor = lazy(() => import('../Editor/RichTextEditor'));

const createDOMPurify = require('dompurify');
class TextSlide extends Component {
  render() {
    const { slide, viewMode, inlineSlideTextEditInfo, inlineEditedSlide, view, viewOnly, currentUser } = this.props;
    const authorViewing = currentUser && currentUser.username === slide.author.username ? true : false;
    return (
      <SearchContext.Consumer>
        {({searchText}) => (
          <div className="paddedCard">
            {slide.data &&
            slide.data.body &&
            (viewOnly ||
              !inlineSlideTextEditInfo ||
              inlineSlideTextEditInfo.inlineSlideTextEditId !== slide.id ||
              inlineSlideTextEditInfo.type !== 'body' ||
              this.props.disableInlineTextEditing) ? (
              <div
                className="flexColumnFull"
                onClick={async () => {
                  if ((viewMode === 'SLIDE' || viewMode === 'SCROLL') && authorViewing && view !== 'website'
                      && !this.props.disableInlineTextEditing) {
                    // First check to see if there is a slide with unsaved edits
                    if (inlineEditedSlide) {
                      // Make sure if the edit is for a caption that the caption has no p tags
                      if (inlineEditedSlide.data && inlineEditedSlide.data.caption) {
                        inlineEditedSlide.data.caption = inlineEditedSlide.data.caption.replace('<p>', '').replace('</p>', '');
                      }
                      const updatedSlide = await agent.Slides.update(inlineEditedSlide.id, inlineEditedSlide);
                      this.props.onSubmit({
                        slide: updatedSlide.slide,
                        isTextEdit: true,
                        preventScrollToSlide: true,
                      });
                    }
                    this.props.setInlineSlideTextEditInfo({
                      inlineSlideTextEditInfo: {
                        inlineSlideTextEditId: slide.id,
                        type: 'body',
                      },
                    });
                  }
                }}
              >
                {typeof slide.data.body === 'string' ? <>
                  {slide.data.fullEditor && (                    

                     <span style={{
                            wordBreak: 'break-word',
                          }}
                            dangerouslySetInnerHTML={{
                              __html: searchText
                                ? slide.data.body
                                : slide.data.body,
                            }}
                          />
                  )}
                  {!slide.data.fullEditor && (
                    <span style={{
                      wordBreak: 'break-word',
                    }}
                      dangerouslySetInnerHTML={{
                        __html: searchText
                          ? formatDisplayTextWithSearchMarkup(createDOMPurify.sanitize(slide.data.body), searchText)
                          : createDOMPurify.sanitize(slide.data.body),
                      }}
                    />
                  )}
                </> : <>
                  {/* New Rich Text Editor */}
                  <NewRichTextEditor readOnly value={slide.data.body} />
                </>
                }
                
              </div>
            ) : !viewOnly &&
            inlineSlideTextEditInfo &&
            inlineSlideTextEditInfo.inlineSlideTextEditId === slide.id &&
            inlineSlideTextEditInfo.type === 'body' &&
            !this.props.disableInlineTextEditing ? (
              typeof slide.data?.body === 'string' 
              ?
              <InlineTextEditor
                inlineEditorClasses={slide.data.fullEditor ? 'inlineRichTextEdit' : 'inlineNormalTextEdit'}
                dataField={'body'}
                onChange={this.props.updateSlideWithInlineEdits}
                slide={this.props.slide}
                saveEdits={this.props.onSubmit}                
                setInlineSlideTextEditInfo={this.props.setInlineSlideTextEditInfo}
                isReadOnly={false}
                onOpenEditor={this.props.openFullTextEditor}
              />
              :
              <NewRichTextEditor value={slide.data.body} 
                // onChange={(value) => {                
                //   const inlineEditedSlide = {
                //     ...slide,
                //     data: {
                //       ...slide.data,
                //       body: value,
                //     },
                //   };
                //   this.props.updateSlideWithInlineEdits({ inlineEditedSlide });
                // }} 
                onBlur={(value) => {
                  const inlineEditedSlide = {
                    ...slide,
                    data: {
                      ...slide.data,
                      body: value,
                    },
                  };
                  saveInlineTextEdits(inlineEditedSlide, 
                    this.props.onSubmit,
                    this.props.setInlineSlideTextEditInfo,
                    'body'
                  );
                }}
              />
            ) : null}
          </div>
        )}
      </SearchContext.Consumer>
    );
  }
}
export default TextSlide;




                    // <InlineTextEditor
                    //   inlineEditorClasses={'inlineNormalTextEdit'}
                    //   dataField={'body'}
                    //   slide={this.props.slide}
                    //   onChange={this.props.updateSlideWithInlineEdits}
                    //   setInlineSlideTextEditInfo={this.props.setInlineSlideTextEditInfo}
                    //   isReadOnly={true}
                    // />
