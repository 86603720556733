import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import agent from '../../agent';
import { contentMetaData } from '../../constants/contentMetaData';
import SlideView from '../Gide/SlideView/SlideView';
import {
  MODAL_OPEN,
  MODAL_CLOSE,
  SET_CREATE_MODE,
  COPY_ARTICLE,
} from '../../constants/actionTypes';
import GideImage from '../Shared/Image/GideImage';
import icons from '../../assets/icons';

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  viewMode: state.common.viewMode,
  article: state.article.article,
});

const mapDispatchToProps = dispatch => ({
  openModal: payload => dispatch({ type: MODAL_OPEN, payload }),
  closeModal: () => dispatch({ type: MODAL_CLOSE }),
  setCreateMode: payload => dispatch({ type: SET_CREATE_MODE, payload }),
  copyArticle: payload => dispatch({ type: COPY_ARTICLE, payload }),
});

class InputSlide extends Component {
  constructor(props) {
    super(props);

    this.respond = this._respond.bind(this);
  }

  async _respond() {
    const { slide, currentUser } = this.props;
    if (slide.slideType !== 'INPUT' || !slide.data.inputType) return;
    if (!currentUser) {
      return alert('Please register first.');
    }
    let isOwner = currentUser
      ? currentUser.username === this.props.slide.author.username
      : false;
    // the owner can only respond to an INPUT slide if the slide's entryType is template and the article type is NORMAL
    if (isOwner && !(this.props.slide.data.entryType === 'template' && this.props.article.type === 'TEMPLATE')) {
      return alert(
        'Sorry, you are not allowed to respond to your own Input Slide.',
      );
    } else if (!isOwner && this.props.slide.data.entryType === 'template') {
      const createTemplateGide = window.confirm('Do you want to create a template Gide from this Gide?');
      if (createTemplateGide) {
        // Create a new template gide from the selected gide and save it to the user's FYEO channel
        this.setState({ loading: false });
        const copyOfArticle = await agent.Articles.copyTemplate(this.props.article);
        this.props.copyArticle(copyOfArticle);
      }
      return;
    }
    // the owner can only respond to an INPUT slide if the slide's entryType is template and the article type is NORMAL
    // if (isOwner && !(this.props.slide.data.entryType === 'template' && this.props.article.type === 'NORMAL')) {
    //   return alert(
    //     'Sorry, you are not allowed to respond to your own Input Slide.',
    //   );
    // }
    // TODO check if one of the slides was created by this user
    // if (slide.feedbackSlides.length || slide.inputSlides.length) {
    //   return alert('You have already responded!');
    // }
    const inputType = this.props.slide.data.inputType;
    const modalType = `${inputType.charAt(0)}${inputType
      .slice(1)
      .toLowerCase()}Modal`;

    // set mode to REPLACE if it is a response to a template INPUT
    this.props.setCreateMode({
      mode: this.props.slide.data.entryType !== 'template' ? 'RESPOND' : 'REPLACE',
      slide: this.props.slide,
    });

    this.props.openModal({
      modalType,
      modalProps: {
        slide: slide,
        mode: this.props.slide.data.entryType !== 'template' ? 'RESPOND' : 'REPLACE',
      },
    });
  }

  render() {
    const { slide, viewMode, currentUser } = this.props;
    const contentData =
      slide.data && slide.data.inputType
        ? contentMetaData[slide.data.inputType]
        : {};
    if (viewMode === 'SWIPE') {
      return (
        <div className={slide.data ? slide.data.inputType : ''}>
          {slide.inputSlides.length === 0 && (
            <div
              className="top"
              style={{
                background: contentData.colors ? contentData.colors[0] : '',
              }}
            >
              <div
                className="outerOne"
                style={{ background: contentData.colors[1] }}
              >
                <p className="info">
                  <GideImage src="/icons/slidetype/input/main.svg"
                    className="color-purple-200-svg"
                    alt={`□`}
                  />{' '}
                  {slide.data.entryType === 'inline' && <span>Inline</span>}
                  {slide.data.entryType === 'template' && <span>Template</span>}
                  {slide.data.entryType === 'addition' && <span>Addition</span>}
                  {slide.data.entryType === 'feedback' && (
                    <span>Send to Creator</span>
                  )}
                  <span> • </span>
                  {slide.data.required ? (
                    <span>Required</span>
                  ) : (
                      <span>Optional</span>
                    )}
                </p>
                <div
                  className="outerTwo"
                  style={{ background: contentData.colors[2] }}
                >
                  <div
                    className="inner"
                    style={{ background: contentData.colors[0] }}
                  >
                    <div className="iconAndButton">
                      <div className="largeIcon">
                        <GideImage 
                          src={contentData.icon}
                          className="icon md"
                          alt={`□`}
                        />
                      </div>
                      <span className="goButton" onClick={this.respond}>
                        <div className="goButtonInner">
                          {/*<GideImage 
                            src={contentData.icon}
                            className="icon sm"
                            alt="logo"
                          />
                          */}
                          <span className="goWord">
                            {/* <Icon name="plus" color="white" /> */}
                            <icons.Nav_Plusicon
                              color='var(--WHITES-NORMAL-1000)'
                            />
                          </span>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {slide.data.entryType === 'template' &&
            slide.inputSlides.length > 0 &&
            slide.inputSlides.map((inputSlide, i) => {
              return (
                <div key={i}>
                  <SlideView slide={inputSlide} slidePosition={inputSlide.position} currentUser={currentUser} />
                </div>
              );
            })}

          {slide.data.entryType === 'feedback' &&
            slide.feedbackSlides.length > 0 &&
            slide.feedbackSlides.map((feedbackSlide, i) => {
              return (
                <div key={i}>
                  <SlideView
                    slide={feedbackSlide}
                    slidePosition={feedbackSlide.position}
                    currentUser={currentUser}
                    showAuthorHeader={true}
                  />
                </div>
              );
            })}
        </div>
      );
    }
    if (
      viewMode === 'SLIDE' ||
      viewMode === 'SCROLL' ||
      viewMode === '' ||
      !viewMode
    ) {
      return (
        <div className={slide.data ? slide.data.inputType : ''}>
          {slide.inputSlides.length === 0 && (
            <div>
              <div
                className="top"
                style={{ background: contentData.colors[0] }}
              >
                <div
                  className="outerOne"
                  style={{ background: contentData.colors[1] }}
                >
                  <div
                    className="outerTwo"
                    style={{ background: contentData.colors[2] }}
                  >
                    <div
                      className="inner"
                      style={{ background: contentData.colors[0] }}
                    >
                      <GideImage 
                        src={contentData.icon}
                        className="icon md"
                        alt={`□`}
                      />
                      <span className="inputType">{slide.data.inputType}</span>
                      <span className="goButton" onClick={this.respond}>
                        <GideImage 
                          src={contentData.icon}
                          className="icon sm"
                          alt={`□`}
                        />
                        <span className="goWord">
                          <Icon name="plus" inverted color='grey' />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ color: 'rgb(125,95,156)', padding: '10px 0 0 10px' }}
              >
                <div className="caption">
                  {slide.data.caption
                    ? slide.data.caption
                    : `Add ${slide.data.inputType.toLowerCase()}`}
                </div>
                <GideImage src="/icons/slidetype/input/main.svg"
                  className="color-purple-200-svg"
                  alt={`□`}
                />{' '}
                {' > '}
                {slide.data.entryType === 'inline' && <span>Inline</span>}
                {slide.data.entryType === 'template' && <span>Template</span>}
                {slide.data.entryType === 'addition' && <span>Addition</span>}
                {slide.data.entryType === 'feedback' && (
                  <span>Send to Creator</span>
                )}
                <span> • </span>
                {slide.data.required ? (
                  <span>Required</span>
                ) : (
                    <span>Optional</span>
                  )}
              </div>
            </div>
          )}

          {slide.data.entryType === 'template' &&
            slide.inputSlides.length > 0 &&
            slide.inputSlides.map((inputSlide, i) => {
              return (
                <div key={i}>
                  <SlideView slide={inputSlide} slidePosition={inputSlide.position} currentUser={currentUser} />
                </div>
              );
            })}

          {(slide.data.entryType === 'feedback' ||
            slide.data.entryType === 'inline') &&
            (slide.feedbackSlides.length > 0 &&
              slide.feedbackSlides.map((feedbackSlide, i) => {
                return (
                  <div key={i} className={slide.data.entryType}>
                    <SlideView
                      slide={feedbackSlide}
                      slidePosition={feedbackSlide.position}
                      currentUser={currentUser}
                      showAuthorHeader={true}
                    />
                  </div>
                );
              }))}
        </div>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InputSlide);
