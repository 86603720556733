import React from 'react';
import { RenderElementProps } from 'slate-react';
import { Editor, Transforms} from 'slate';
import { styled } from '@material-ui/core/styles';

export const LIST_TYPES = ['numbered', 'bulleted'];

const StyledAnchor = styled('a')({
  color: '#2e2ef1',
  '&:hover': {
    color: '#2e2ef1',
    textDecoration: 'underline',
  },
});

export const toggleBlock = (editor: Editor, format: string, props?: object) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  // Transforms.setNodes(editor, {
  //   type: isActive ? 'paragraph' : format,
  // })

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type as string),
    split: true,
  })

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  })

  if (!isActive && isList) {
    const block = { type: format, children: [], ...props }
    Transforms.wrapNodes(editor, block)
  }
}

export const isBlockActive = (editor: Editor, format: string) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === format,
  });
  return !!match
}

export const getBlockProperty = (editor: Editor, format: string) => {
  const [match] = Editor.nodes(editor, {
    match: n => !!n.type,
  });
  const value = match?.[0]?.[format] as any;
  return value;
}

export const setBlockJustification = (editor: Editor, justification?: string) => {
  if (justification === 'left') {
    justification = undefined;
  }
  Transforms.setNodes(editor, { justification });
}

export const increaseIndent = (editor: Editor) => {
  const nodes = Editor.nodes(editor);
  let largestIndent = 0;
  for (const [node, path] of nodes) {
    if (typeof node.indent === 'number'
      && node.indent > largestIndent
    ) {
      largestIndent = node.indent;
    }
  }

  Transforms.setNodes(editor, {
    indent: largestIndent + 1,
  });
}

export const decreaseIndent = (editor: Editor) => {
  const nodes = Editor.nodes(editor);
  let largestIndent = 1;
  for (const [node, path] of nodes) {
    if (typeof node.indent === 'number'
      && node.indent > largestIndent
    ) {
      largestIndent = node.indent;
    }
  }

  const indent = Math.max(0, largestIndent - 1);

  Transforms.setNodes(editor, {
    indent: indent || undefined,
  });
}

export const renderElement = (props: RenderElementProps) => {

  let marginLeft = props.element.indent as number * 40 || undefined;

  switch (props.element.type) {
    case 'embed':
      return (
        <pre {...props.attributes} 
          style={{ 
            display: 'inline-block', 
            backgroundColor: '#EEE',
            padding: 4,
            borderRadius: 4,
          }}
        >
          {props.children}
        </pre>
      );
    case 'link': 
      return (
        <StyledAnchor href={props.element.url as string} 
          title={props.element.url as string} 
          {...props.attributes}
        >
          {props.children}
        </StyledAnchor>
      );
    case 'bulleted':
      return (
        <ul {...props.attributes}>
          {props.children}
        </ul>
        // <ul {...props.attributes} style={{marginLeft }}>
        //   {React.Children.map(props.children, child => <li>{child}</li>)}
        // </ul>
      );
    case 'numbered': 
      return (
        <ol {...props.attributes}>
          {props.children}
        </ol>
        // <ol {...props.attributes} style={{marginLeft }}>
        //   {React.Children.map(props.children, child => <li>{child}</li>)}
        // </ol>
        );
    case 'list-item':
      return <li {...props.attributes} style={{ marginLeft }}>{props.children}</li>
    default:
      return (
      <div {...props.attributes} style={{ 
        marginLeft,
        textAlign: props.element.justification as "left" | "right" | "justify" | "center",
        }}
      >
        {props.children}
      </div>
      );
  }
};
