import React from 'react';
import {
  SlideEditorModalProps,
  BackIconMode,
  EditorState,
  ModalOverlayState,
} from '../../modals/SlideEditorModal/SlideEditorModal';
import { LinkSlideFile } from '../../../models/SlideFile';
import { v4 } from 'uuid';
import { lightTheme } from '../../../themes/custom-theme';
import {
  CommonContext,
  CommonDerivedState,
  getHeaderTitleAndIcon,
  CommonModes,
} from './Common';
import ChannelChooser from '../../Shared/ChannelChooser/ChannelChooser';
import { ItemSelectionMode } from '../../Shared/Previews/GidePreview/GideListItemPreview/GideListItemPreview';
import agent from '../../../agent';
import { ArticlePreviewLayout } from '../../../models/CommonEnums';
import { LinkModes } from './SharedLogicForLinkSlideEditor';
import { ChannelSearchElement } from '../../Shared/GideElementSearch/GideElementSearch';

const getSlideEditorModalProps = (context: CommonContext): SlideEditorModalProps => {
  const { state, setState, commonSlideEditorModalProps } = context;
  const newMode = state.slide.slideType === "LINKS" ? LinkModes.Link : CommonModes.New;
  return {
    ...commonSlideEditorModalProps,
    backIconMode: BackIconMode.Light,
    hideFooter: true,
    hideCommandBar: false,
    editorState: EditorState.Edit,
    loadingInfo: undefined,
    modalOverlayState: ModalOverlayState.None,
    selectedItemIndex: state.selectedItemIndex,
    hideActionContainer: true,
    showBackNavigation: true,
    showDefaultActions: false,
    createActions: [] as any,
    headerActions: getHeaderTitleAndIcon('Link Channels', '/icons/nav/channels.svg', 'black', 'blue'),
    onNavigateBack: () => {
      const mode = !state.slide.data.files.length ? newMode
        : state.selectedItemIndex > -1 ? CommonModes.Edit
        : CommonModes.Preview;
      setState({ ...state, mode, mediaChooserAction: undefined, hiddenMediaChooserAction: undefined });
    },
  };
}

const getContent = (context: CommonContext, slideEditorModalProps: SlideEditorModalProps) => {
  const { state, setState } = context;
  return (
    <ChannelChooser
      title={"Choose a channel to link"}
      subtitle={"Pick one or more channels to link to your slide"}
      channelSearch={agent.Users.channelSearch}
      selectionMode={ItemSelectionMode.MultipleDisplayCheckBox}
      onSelectMultipleChannels={(channelSummaries: ChannelSearchElement[]) => {
        const linkSlideFiles: LinkSlideFile[] = [];
        channelSummaries.forEach(channelSummary => {
            const newSlide: LinkSlideFile = {
              id: v4(),
              type: 'CHANNEL',
              displayMode: ArticlePreviewLayout.BlogImageLeft,
              slideFileReference: {
                referenceChannel: {
                  id: channelSummary.id,
                  slug: channelSummary.slug,
                  title: channelSummary.displayName,
                  image: channelSummary.imageUrl,
                  type: channelSummary.type,
                  author: channelSummary.authorUsername,
                  authorCountry: channelSummary.authorCountry,
                  authorImage: channelSummary.authorImageUrl,
                  public: channelSummary.public,
                  website: channelSummary.website,
                },
              },
            };
            linkSlideFiles.push(newSlide);
        });
        const slide = {
          ...state.slide,
          data: {
            ...state.slide.data,
            files: [...state.slide.data.files, ...linkSlideFiles],
          },
        };
        setState({
          ...state,
          mode: CommonModes.Edit,
          loadingInfo: undefined,
          slide,
          selectedItemIndex: slide.data.files.length - 1,
        });
      }}
    />
  );
}

export const getDerivedState = (context: CommonContext): CommonDerivedState => {
  const slideEditorModalProps = getSlideEditorModalProps(context);
  const content = getContent(context, slideEditorModalProps);
  return {
    ...context.state,
    slideEditorModalProps,
    content,
    theme: lightTheme,
  };
};

export const ChooseChannel = {
  getSlideEditorModalProps,
  getContent,
  getDerivedState,
};
