import React, { CSSProperties, useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import { GithubPicker, ColorResult } from 'react-color';
import { Transforms, Range } from 'slate';
import { useSlate } from 'slate-react';
/* Icons */
import { ReactComponent as UpIcon } from './icons/arrow-up.svg';
import { ReactComponent as DownIcon } from './icons/arrow-down.svg';
import Popover from '@material-ui/core/Popover';

const colors = ['#000000', '#FFFFFF', '#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB', '#EB9694', '#FAD0C3', '#FEF3BD', '#C1E1C5', '#BEDADC', '#C4DEF6', '#BED3F3', '#D4C4FB'];

export interface RichTextEditorColorProps {
  style?: CSSProperties,
  icon: React.ReactNode,
  value: string,
  labelWidth: number;
  onChange: (newColor: string) => void,
}

export const RichTextEditorColor: React.FC<RichTextEditorColorProps> = (props) => {
  const editor = useSlate();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [color, setColor] = useState<string>();
  // useEffect(() => {
  //   setColor(props.value);
  // }, [props.value]);
  const [open, setOpen] = useState(false);
  const [selection, setSelection] = useState<Range | null>(null);
  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpen(true);
    setColor(props.value);
    setSelection(editor.selection);
  };
  const handleClose = (e?: React.UIEvent, pickedColor?: string) => {
    e?.preventDefault();
    setOpen(false);
    if (selection) {
      Transforms.select(editor, selection);
      if (pickedColor || color) {
        props.onChange(pickedColor || color || '');
      }
    }
  };

  return (
    <span style={props.style}>
      <Button size="small" onMouseDown={handleOpen}
        ref={buttonRef}
        style={{ 
          background: '#F0F0F0',
          borderRadius: 2,
          width: props.labelWidth,
          minWidth: props.labelWidth
        }}
      >
        {props.icon}
      </Button>
      <Button size="small" onMouseDown={handleOpen} 
        disableRipple
        style={{
          background: '#F0F0F0',
          borderRadius: 2,
          marginLeft: 3,
          minWidth: 34,
        }}
      >
        {open ? <UpIcon /> : <DownIcon />}
      </Button>
      <Popover 
        anchorEl={buttonRef.current}
        keepMounted
        open={open}
        onClose={(e) => handleClose(e as any)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <GithubPicker color={color} 
          triangle="hide"
          colors={colors}
          onChange={(color: ColorResult) => {
            setColor(color.hex);
            handleClose(undefined, color.hex);
          }} 
        />
      </Popover>
    </span>
  );
}
