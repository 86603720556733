import React from 'react';
import {
  SlideEditorModalProps,
  BackIconMode,
  EditorState,
  ModalOverlayState,
} from '../../modals/SlideEditorModal/SlideEditorModal';
import { v4 } from 'uuid';
import { lightTheme } from '../../../themes/custom-theme';
import {
  CommonContext,
  CommonDerivedState,
  getHeaderTitleAndIcon,
  CommonModes,
} from './Common';
import { SlideFileReference } from '../../../models/SlideFileReference';
import SlideFileChooser from '../../Shared/SlideFileChooser/SlideFileChooser';
import { LinkModes } from './SharedLogicForLinkSlideEditor';
import agent from '../../../agent';

const getSlideEditorModalProps = (context: CommonContext): SlideEditorModalProps => {
  const { state, setState, commonSlideEditorModalProps } = context;
  const newMode = state.slide.slideType === "LINKS" ? LinkModes.Link : CommonModes.New;
  return {
    ...commonSlideEditorModalProps,
    backIconMode: BackIconMode.Light,
    hideFooter: true,
    hideCommandBar: true,
    editorState: EditorState.Edit,
    loadingInfo: undefined,
    modalOverlayState: ModalOverlayState.None,
    selectedItemIndex: state.selectedItemIndex,
    hideActionContainer: true,
    showBackNavigation: true,
    showDefaultActions: false,
    createActions: [] as any,
    headerActions: getHeaderTitleAndIcon('Link to a slide', '/icons/creationprocess/slide.svg', 'black', 'blue'),
    onNavigateBack: () => {
      const mode = !state.slide.data.files.length ? newMode
        : state.selectedItemIndex > -1 ? CommonModes.Edit
        : CommonModes.Preview;
      setState({ ...state, mode, mediaChooserAction: undefined, hiddenMediaChooserAction: undefined });
    },
  };
}

const getContent = (context: CommonContext, slideEditorModalProps: SlideEditorModalProps) => {
  const { props, state, setState } = context;
  const newMode = state.slide.slideType === "LINKS" ? LinkModes.Link : CommonModes.New;
  return (
    <SlideFileChooser
      agent={agent}
      hideActionBar={true}
      title="Find a slide"
      slideTypes={[]}
      chooseCompleteSlide={true}
      showNotification={props.showNotification}
      currentUser={props.currentUser}
      singleSelectionOnly={false}
      onNavigateBack={() => {
        const mode = !state.slide.data.files.length ? newMode
          : state.selectedItemIndex > -1 ? CommonModes.Edit
          : CommonModes.Preview;
        setState({ ...state, mode, mediaChooserAction: undefined, hiddenMediaChooserAction: undefined });
      }}
      addSlideFileReferences={(slideFileReferences: SlideFileReference[]) => {
        const newFiles = slideFileReferences.map(sfr => ({
          id: v4(),
          slideFileReference: sfr,
          type: 'SLIDE',
          // onSelect: {
          //   slideItemSelectionActionType: SlideItemSelectionActionType.OpenModal
          // }
        }));
        const slide = {
          ...state.slide,
          data: {
            ...state.slide.data,
            files: [...state.slide.data.files, ...newFiles],
          },
        };
        setState({
          ...state,
          mode: CommonModes.Edit,
          loadingInfo: undefined,
          slide,
          selectedItemIndex: slide.data.files.length - 1,
        });
      }}
    />
  );

  // return (
  //   <SlideChooser
  //     slideSelectionLabel="Select slide"
  //     title={"Choose a slide to link to"}
  //     subtitle={"Pick a gide to link to your slide"}
  //     showNotification={props.showNotification}
  //     currentUser={props.currentUser}
  //     onNavigateBack={() => {
  //       setState({
  //         ...state,
  //         mode: !state.slide.data.files.length ? CommonModes.New : CommonModes.Edit,
  //       });
  //     }}
  //     onSelectSlide={(slideFileReference: SlideSelection) => {
  //       const newFile = {
  //         id: v4(),
  //         slideFileReference: slideFileReference.slide,
  //         type: 'SLIDE',
  //         onSelect: {
  //           slideItemSelectionActionType: SlideItemSelectionActionType.OpenModal
  //         }
  //       };
  //       const slide = {
  //         ...state.slide,
  //         data: {
  //           ...state.slide.data,
  //           files: [...state.slide.data.files, newFile],
  //         },
  //       };
  //       setState({
  //         ...state,
  //         mode: CommonModes.Edit,
  //         loadingInfo: undefined,
  //         slide,
  //         selectedItemIndex: slide.data.files.length - 1,
  //       });
  //     }}
  //   />
  // );
}

export const getDerivedState = (context: CommonContext): CommonDerivedState => {
  const slideEditorModalProps = getSlideEditorModalProps(context);
  const content = getContent(context, slideEditorModalProps);
  return {
    ...context.state,
    slideEditorModalProps,
    content,
    theme: lightTheme,
  };
};

export const ChooseSlide = {
  getSlideEditorModalProps,
  getContent,
  getDerivedState,
};
