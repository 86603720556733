import React from 'react';
import { Link } from 'react-router-dom';

/*
 * Improvement over react-router's Link
 * Allows external linking.
 * Important because if the link is not external, the react-router should
 * handle the link, which uses history and avoids page reload.
 */

export default ({ to, children, ...props }) => {
  if (!to) return <span {...props}>{children}</span>;
  if (/^https?:\/\//.test(to))
    return (
      <a href={to} {...props}>
        {children}
      </a>
    );
  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};
