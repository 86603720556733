import React, { CSSProperties } from 'react';
import styles from './_picker.module.scss';
import { IconProps } from '../../../../assets/icons';
import { TertiaryButton } from '../../../Shared/Buttons/TertiaryButton/TertiaryButton';
import { CircleIconButton } from '../../../Shared/CircleIconButton/CircleIconButton';
import { hasValue } from '../../../../utils/helperFunctions';
import classNames from 'classnames';
import { contains } from 'ramda';
// Move these enums to where they are used from
export enum GideTitleSlideFormat {
  ImageAndText = 1,
  ImageOnly = 2,
  TextOnly = 3,
  NoTitle = 4,
  Blog = 5
}

export enum PickerDisplayMode {
  CircularTextOutsideUnder = 1,
  RectangularTextInsideUnder = 2,
}
export interface  PickerProps {
  title?: string;
  pickerItems: PickerItem[];
  selectedValue: number; // GideTitleSlideFormat | SlideWidthType;
  onChange: (value: number) => void; // (format: GideTitleSlideFormat | SlideWidthType) => void; 
  style?: CSSProperties;
  displayMode?: PickerDisplayMode;
  // itemWidth?: number;
  itemCSSClass?: string;
  itemsPerRow?: number;
  enabledValues?: number[];
}
export interface PickerItem {
  icon: (props: IconProps) => JSX.Element;
  label: string;
  value: number;
}

export default function Picker (props:  PickerProps) {
  const placeholders = [];
  // There are a max of 3 items per row so get the remainder and add place holder elements
  const remainder = props.pickerItems.length % (props.itemsPerRow ? props.itemsPerRow : 3);
  if(remainder !== 0) {
    for(let i = 0; i < 3 - remainder; i ++) {
      placeholders.push(i);
    }
  }
  return (
    <div className={styles.pickerContainer}
      style={props.style}
    >
      {hasValue(props.title) && (
        <span className={styles.title}>{props.title}</span>
      )}
      <div className={styles.pickerItems}>
        {props.pickerItems.map((pickerItem: PickerItem, i: number) => {
          const enabled = props.enabledValues === undefined || contains(pickerItem.value, props.enabledValues);
          return <React.Fragment key={i}>
            {props.displayMode !== PickerDisplayMode.CircularTextOutsideUnder && (            
              <TertiaryButton
                icon={pickerItem.icon}
                isSelected={props.selectedValue === pickerItem.value}
                key={i}
                enabled={enabled}
                onClick={() => {
                 
                  if(enabled) {
                    props.onChange(pickerItem.value);
                  }
                }}
                label={pickerItem.label}
                style={{margin: '6px'}}
              />
            )}
            {props.displayMode === PickerDisplayMode.CircularTextOutsideUnder && (
              <div className={classNames(styles.circularPickerItem, props.itemCSSClass)}>
                <CircleIconButton
                  backgroundColor={props.selectedValue === pickerItem.value
                    ? 'var(--COLOR-PRIMARY-300)'
                    : 'var(--WHITES-NORMAL-1000)'
                  }
                  enabled={enabled}
                  onClick={() => {                    
                    if(enabled) {
                      props.onChange(pickerItem.value);
                    }
                  }}
                  style={{
                    border: enabled === true
                    ? props.selectedValue === pickerItem.value
                      ? 'unset' 
                      : 'solid 1px var(--COLOR-SECONDARY-400)'
                    : 'solid 1px var(--COLOR-SECONDARY-200)'
                  }}
                >
                  <pickerItem.icon 
                    color={
                      enabled 
                      ? props.selectedValue === pickerItem.value
                        ? 'var(--WHITES-NORMAL-1000)' 
                        : 'var(--COLOR-SECONDARY-400)'
                      : 'var(--COLOR-SECONDARY-200)'
                    }
                    strokeColor={
                      enabled 
                      ? props.selectedValue === pickerItem.value
                        ? 'var(--WHITES-NORMAL-1000)' 
                        : 'var(--COLOR-SECONDARY-300)'
                      : 'var(--COLOR-SECONDARY-200)'
                    }
                  />
                </CircleIconButton>
                <span className={styles.circularPickerItemFormatLabel}>{pickerItem.label}</span>
              </div>
            )}            
          </React.Fragment>
          }
        )}
        {/* Need these items to push the content to the left */}
        {props.displayMode !== PickerDisplayMode.CircularTextOutsideUnder && (
          <>
          <span className={styles.pickerItemAligner} />
          <span className={styles.pickerItemAligner} />
          <span className={styles.pickerItemAligner} />
          <span className={styles.pickerItemAligner} />
          <span className={styles.pickerItemAligner} />
          <span className={styles.pickerItemAligner} />
          </>
        )}
        {props.displayMode === PickerDisplayMode.CircularTextOutsideUnder && placeholders.length > 0 && (
          placeholders.map(p => <div 
            // style={props.itemWidth ? {width: `${props.itemWidth}px`} : {}} 
            className={classNames(styles.circularPickerItem, props.itemCSSClass)}></div>)
        )}
      </div>

    </div>
  );
}
