import React, { useState } from 'react';
import styles from './_gide-element-search.module.scss';
import { Button as ButtonComponent } from '../Button/Button';
import { RadioButton as RadioButtonComponent } from '../RadioButton/RadioButton';
import { CheckBox as CheckBoxComponent } from '../CheckBox/CheckBox';

import GideImage from '../Image/GideImage';
import { append, reject, contains } from 'ramda';
import agent from '../../../agent';
import { Input } from 'semantic-ui-react';
import { Slide } from '../../../models/Slide';
import { Channel } from '../../../models/Channel';
import classNames from 'classnames';

export interface BaseSearchElement {
  id: string;
  imageUrl: string;
  displayName: string;
}
export interface GideSearchElement extends BaseSearchElement {
  authorImageUrl: string;
  authorUsername: string;
  authorCountry: string;
  description?: string;
  descriptionSlide?: Slide;
  hasGeotag?: boolean;
  slug: string;
  type: string;
  slidesLength?: number;
  viewCount?: number;
  isSelected?: boolean;
  createdAt: Date;
  updatedAt: Date;
}
export interface ChannelSearchElement extends GideSearchElement {
  public: boolean;
  website: boolean;
  numberOfGides: number;
  channel?: Channel;
  numberOfMembers: number;
}
export interface GideElementSearchProps {
  // API to get the user's search results
  loadGideElementSearchResults: (payload: { searchResults: BaseSearchElement[] }) => void;
  clearGideElementSearchResults: () => void;
  searchResults: BaseSearchElement[];
  action: (selectedItems: BaseSearchElement[], type: string) => void;
  actionLabel: string;
}
export interface GideElementSearchState {
  searchElementType: string;
  searchText?: string;
  selectedItemIds: string[];
  // gideSearchItems: BaseSearchElement[];
}

export default function GideElementSearch(props: GideElementSearchProps) {
  const [state, setState] = useState<GideElementSearchState>({
    searchElementType: 'Article',
    searchText: '',
    selectedItemIds: [],
  });
  const setSelectedElementType = (type: string) => {
    setState({
      ...state,
      searchElementType: type,
    });
    props.clearGideElementSearchResults();
  }
  const toggleSearchResult = (searchResult: BaseSearchElement, checked: boolean): void => {
    if (checked === true) {
      setState({...state, selectedItemIds: append(searchResult.id, state.selectedItemIds)});
    } else {
      setState({...state, selectedItemIds: reject((id: string) => contains(id, state.selectedItemIds), state.selectedItemIds) });
    }
  }
  const executeAction = (): void => {
    const selectedItems = props.searchResults.filter(sr => contains(sr.id, state.selectedItemIds));
    props.action(selectedItems, state.searchElementType);
  }
  const searchGideElement = async () => {
    const searchResults: BaseSearchElement[] = await agent.Users.search(state.searchElementType, { searchText: state.searchText });
    props.loadGideElementSearchResults({searchResults});
  }
  return (
    <div className={styles.gideElementSearchContainer}>
      <div className={styles.searchTypes}>
        <span className={styles.searchType}>
          <RadioButtonComponent
            checked={state.searchElementType === 'Article'}
            label="Gides"
            name="searchElementType"
            value="Article"
            change={() => setSelectedElementType('Article')}
          />
        </span>
        <span className={styles.searchType}>
          <RadioButtonComponent
            checked={state.searchElementType === 'User'}
            label="People"
            name="searchElementType"
            value="User"
            change={() => setSelectedElementType('User')}
          />
        </span>
        <span className={styles.searchType}>
          <RadioButtonComponent
            checked={state.searchElementType === 'Channel'}
            label="Channels"
            name="searchElementType"
            value="Channel"
            change={() => setSelectedElementType('Channel')}
          />
        </span>
      </div>
      <div className={styles.searchElements}>
        <Input
          type="text"
          placeholder="enter search ..."
          value={state.searchText}
          onChange={e => setState({ ...state, searchText: e ? e.target.value : '' })}
        />
        <ButtonComponent style={{ marginLeft: '10px' }} cssClass="e-outline" onClick={searchGideElement}>
          Search
        </ButtonComponent>
      </div>
      <div className={styles.searchResults}>
        {props.searchResults.map((searchResult: BaseSearchElement, key: number) => {
          return (
            <span key={key} className={styles.searchResultItem}>
              <CheckBoxComponent change={(e: any) => toggleSearchResult(searchResult, e.checked)} />
              <GideImage
                src={`${searchResult.imageUrl}`}
                className={
                  classNames(
                    styles.shortcutImage, 
                    state.searchElementType === 'Article'
                    ? styles.article
                    : state.searchElementType === 'Channel' 
                      ? styles.channel
                      : styles.user
                  )
                }
                alt={`${searchResult.displayName}`}
              />
              <span className={styles.shortcutTitle}>
                {searchResult.displayName}
              </span>
            </span>
          );
        })}
      </div>
      <ButtonComponent disabled={state.selectedItemIds.length < 1} onClick={executeAction}>
        {props.actionLabel}
      </ButtonComponent>
    </div>
  );
}
