import { New } from './New';
import { Preview } from './Preview';
import { Camera } from './Camera';
import { Uploading } from './Uploading';
import { Link } from './Link';
import { ChooseGideFile } from './ChooseGideFile';
import { Edit } from './Edit';
import { Format } from './Format';
import { Delete } from './Delete';
import { Tag } from './Tag';
import { CroppingImage } from './CroppingImage';
import { Crop } from './Crop';
import { Dimensions } from './Dimensions';
import { Doodle } from './Doodle';
import { Attribute } from './Attribute';
import { OnTap } from './OnTap';
import { Audio } from './Audio';
import { CommonModes, ModeHelperFunctions } from './Common';
import { ImageModes } from './SharedLogicForImageSlideEditor';
import { VideoModes } from './SharedLogicForVideoSlideEditor';
import { LinkModes } from './SharedLogicForLinkSlideEditor';
import { Trim } from './Trim';
import { ChooseChannel } from './ChooseChannel';
import { ChooseGide } from './ChooseGide';
import { ChooseSlide } from './ChooseSlide';

export const CommonMode: Record<keyof typeof CommonModes, ModeHelperFunctions> = {
  New,
  Preview,
  Camera,
  Uploading,
  Link,
  Edit,
  Format,
  Delete,
  Tag,
  Dimensions,
  Attribute,
  Audio,
  ChooseGideFile,
};

export const ImageMode: Record<keyof typeof ImageModes, ModeHelperFunctions> = {
  ...CommonMode,
  CroppingImage,
  Crop,  
  Doodle,  
  OnTap,
};

export const VideoMode: Record<keyof typeof VideoModes, ModeHelperFunctions> = {
  ...CommonMode,
  Trim,
};

export const LinkMode: Record<keyof typeof LinkModes, ModeHelperFunctions> = {
  Preview,
  Link,
  Edit,
  Delete,
  Tag,
  Dimensions,
  Attribute,
  Audio,
  ChooseChannel,
  ChooseGide,
  ChooseSlide,
};
