import React from 'react';
import styles from './_avatar.module.scss';
import classNames from 'classnames';
import GideImage from '../Image/GideImage';
export enum AvatarMenuLocation {
  Left = 1,
  Right = 2,
}
export interface  AvatarProps {
  avatarImage: string;
  displayOuterBorder: boolean;
  outerBorderColor?: string;
  innerBorderColor?: string;  
  imageWidthAndHeight?: number;

  alt?: string;
  imgStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
}

export default function Avatar (props:  AvatarProps) {
  const imageStyle: React.CSSProperties = props.imgStyle ? props.imgStyle : {};
  if(props.imageWidthAndHeight) {
    imageStyle.width 
      = imageStyle.maxWidth = imageStyle.minWidth 
      = imageStyle.height = imageStyle.maxHeight = imageStyle.minHeight 
      = `${props.imageWidthAndHeight}px`;
  }
  const style = props.style ? props.style : {};
  if(props.displayOuterBorder) {
    style.border = 'solid 1px var(--COLOR-PRIMARY-700)';
  }
  return (
    <div
      style={style}
      className={classNames(styles.avatarContainer,  props.className ? props.className : undefined)}  
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        props.onClick && props.onClick()
      }}    
    >
        <GideImage 
          style={imageStyle}
          className={styles.avatarImage}
          src={props.avatarImage}
          alt={props.alt}
        />
    </div>
  );
}
