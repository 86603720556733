import React from 'react';
import {
  SlideEditorModalProps,
  BackIconMode,
  EditorState,
  ModalOverlayState,
} from '../../modals/SlideEditorModal/SlideEditorModal';
import { LinkSlideFile } from '../../../models/SlideFile';
import { v4 } from 'uuid';
import { lightTheme } from '../../../themes/custom-theme';
import {
  CommonContext,
  CommonDerivedState,
  getHeaderTitleAndIcon,
  CommonModes,
} from './Common';
import { ItemSelectionMode } from '../../Shared/Previews/GidePreview/GideListItemPreview/GideListItemPreview';
import agent from '../../../agent';
import { ArticlePreviewLayout } from '../../../models/CommonEnums';
import GideChooser from '../../Shared/GideChooser/GideChooser';
import { LinkModes } from './SharedLogicForLinkSlideEditor';
import { GideSearchElement } from '../../Shared/GideElementSearch/GideElementSearch';

const getSlideEditorModalProps = (context: CommonContext): SlideEditorModalProps => {
  const { state, setState, commonSlideEditorModalProps } = context;
  const newMode = state.slide.slideType === "LINKS" ? LinkModes.Link : CommonModes.New;
  return {
    ...commonSlideEditorModalProps,
    backIconMode: BackIconMode.Light,
    hideFooter: true,
    hideCommandBar: false,
    editorState: EditorState.Edit,
    loadingInfo: undefined,
    modalOverlayState: ModalOverlayState.None,
    selectedItemIndex: state.selectedItemIndex,
    hideActionContainer: true,
    showBackNavigation: true,
    showDefaultActions: false,
    createActions: [] as any,
    headerActions: getHeaderTitleAndIcon('Link gides', '/icons/slidetype/insert/main.svg', 'black', 'blue'),
    onNavigateBack: () => {
      const mode = !state.slide.data.files.length ? newMode
        : state.selectedItemIndex > -1 ? CommonModes.Edit
        : CommonModes.Preview;
      setState({ ...state, mode, mediaChooserAction: undefined, hiddenMediaChooserAction: undefined });
    },
  };
}

const getContent = (context: CommonContext, slideEditorModalProps: SlideEditorModalProps) => {
  const { state, setState } = context;
  return (
    <GideChooser
      title={"Choose a gide to link to"}
      subtitle={"Pick one or more gides to link to your slide"}
      agent={agent}
      selectionMode={ItemSelectionMode.MultipleDisplayCheckBox}
      onSelectMultipleGides={(gideSummaries: GideSearchElement[]) => {
        const linkSlideFiles: LinkSlideFile[] = [];
        gideSummaries.forEach(gideSummary => {
          const newSlide: LinkSlideFile = {
            id: v4(),
            type: 'GIDE',
            displayMode: ArticlePreviewLayout.BlogImageLeft,
            slideFileReference: {
              article: {
                id: gideSummary.id,
                slug: gideSummary.slug,
                title: gideSummary.displayName,
                description: gideSummary.descriptionSlide ? gideSummary.descriptionSlide.data.body.text : gideSummary.description,
                hasGeotag: gideSummary.hasGeotag,
                type: gideSummary.type,
                author: gideSummary.authorUsername,
                authorCountry: gideSummary.authorCountry,
                authorImage: gideSummary.authorImageUrl,
                image: gideSummary.imageUrl,
                viewCount: gideSummary.viewCount ? gideSummary.viewCount : 0,
                updatedAt: gideSummary.updatedAt,
                createdAt: gideSummary.createdAt,
              },
            },
          };
          linkSlideFiles.push(newSlide);
        });
        const slide = {
          ...state.slide,
          data: {
            ...state.slide.data,
            files: [...state.slide.data.files, ...linkSlideFiles],
          },
        };
        setState({
          ...state,
          mode: CommonModes.Edit,
          loadingInfo: undefined,
          slide,
          selectedItemIndex: slide.data.files.length - 1,
        });
      }}
    />
  );
}

export const getDerivedState = (context: CommonContext): CommonDerivedState => {
  const slideEditorModalProps = getSlideEditorModalProps(context);
  const content = getContent(context, slideEditorModalProps);
  return {
    ...context.state,
    slideEditorModalProps,
    content,
    theme: lightTheme,
  };
};

export const ChooseGide = {
  getSlideEditorModalProps,
  getContent,
  getDerivedState,
};
