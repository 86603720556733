import React, { useState } from 'react';
import styles from './_authorize-slide.module.scss';
import { Slide } from '../../../models/Slide';
import { User } from '../../../models/User';
import { CircleIconButton } from '../../Shared/CircleIconButton/CircleIconButton';
import icons from '../../../assets/icons';
import { RequiredTextboxWithLabel } from '../../AccountProfile/ProfileEditor/ProfileEditor';
import classNames from 'classnames';
import { RoundedCornerButton } from '../../Shared/RoundedCornerButton/RoundedCornerButton';

export interface AuthorizeSlideProps {
  slide: Slide;
  currentUser: User;
  onAuthorizeSlide: (id: string, authInfo: any, isBlockAuthorizationSlide: boolean) => void;
  onUnauthenticatedAuthorizeSlide: (id: string, authInfo: any, isBlockAuthorizationSlide: boolean) => void;
}

export default function AuthorizeSlide(props: AuthorizeSlideProps) {
  const [password, setPassword] = useState('');
  // callback function for navigator.geolocation.getCurrentPosition when a position is found
  const currentPositionFound = (position: any) => {
    if (props.currentUser) {
      props.onAuthorizeSlide(props.slide.id, {
        lat: position.coords.latitude,
        long: position.coords.longitude,
      }, false);
    } else {
      props.onUnauthenticatedAuthorizeSlide(props.slide.id, {
        lat: position.coords.latitude,
        long: position.coords.longitude,
      }, false);
    }
  };
  // error callback function for navigator.geolocation.getCurrentPosition
  const errorFindingCurrentPosition = (error: any) => {
    console.warn(`ERROR(${error.code}): ${error.message}`);
  };

  const onSubmit = () => {
    if (props.slide.data.passcodeType === 'TEXT') {
      if (props.currentUser) {
        props.onAuthorizeSlide(props.slide.id, { password: password }, false);
      } else {
        props.onUnauthenticatedAuthorizeSlide(props.slide.id, { password: password }, false);
      }
    } else if (props.slide.data.passcodeType === 'GPS') {
        // Need to determine the user's current location
        if (navigator.geolocation.getCurrentPosition) {
          navigator.geolocation.getCurrentPosition(
            currentPositionFound,
            errorFindingCurrentPosition,
          );
        } else {
          alert(
            'Sorry, geolocation does not seem to be supported by your browser.',
          );
        }
    } else {
      // TIME
      if (props.currentUser) {
        props.onAuthorizeSlide(props.slide.id, {
          /* All handled on the server */
        }, false);
      } else {
        props.onUnauthenticatedAuthorizeSlide(props.slide.id, {
          /* All handled on the server */
        }, false);
      }
    }
  };

  if (props.slide.data.authorized || (props.currentUser && props.currentUser.username === props.slide.author.username)) {
    return (
      <div
        className={classNames(styles.authorizeSlideContainer, styles.success)}
        style={{ backgroundImage: "url('/icons/slidetype/authorize/success.png')" }}
      >
        <div className={classNames(styles.authorizeIcon, styles.success)}>
          {props.slide.data.passcodeType === 'TEXT' && (<icons.SlideType_Authorize_Main width={24} height={24} color={'var(--WHITES-NORMAL-1000)'} />)}
          {props.slide.data.passcodeType === 'GPS' && (<icons.SlideType_Location_Location width={24} height={24} color={'var(--WHITES-NORMAL-1000)'} />)}
        </div>
        <div className={styles.authorizeTitle}>Authorization success!</div>
        <div className={styles.authorizeLabel}>Continue viewing content below.</div>
      </div>
    );
  } else {
    return (
      <div className={styles.authorizeSlideContainer}>
        <div className={styles.authorizeIcon}>
          {props.slide.data.passcodeType === 'TEXT' && (<icons.SlideType_Authorize_Main width={24} height={24} color={'var(--WHITES-NORMAL-1000)'} />)}
          {props.slide.data.passcodeType === 'GPS' && (<icons.SlideType_Location_Location width={24} height={24} color={'var(--WHITES-NORMAL-1000)'} />)}
        </div>
        <div className={styles.authorizeTitle}>Authorization needed.</div>
        {props.slide.data.passcodeType === 'TEXT' && (
          <div className={styles.authorizePasswordDetails}>
            <div className={styles.authorizePasswordText}>
              <RequiredTextboxWithLabel
                type={'password'}
                error={!props.slide.data.authorized && props.slide.data.message ? props.slide.data.message : ''}
                inputClass={styles.passwordTextBox}
                label={'Enter the password to continue'}
                placeholder={'Type password'}
                onChange={(value: string | number | undefined) => {
                  setPassword(value as string);
                }}
                onKeyDown={(e: any) => {
                  if (e.keyCode === 13 && e.ctrlKey === false && e.shiftKey === false) {
                    onSubmit();
                  }
                }}
              />
            </div>
            <CircleIconButton
              className={classNames(styles.acceptButton)}
              onClick={onSubmit}
              backgroundColor={password === '' ? 'var(--COLOR-SECONDARY-200)' : 'var(--COLOR-PRIMARY-600)'}
              alt="accept changes"
              image={'/icons/content-alteration/check/main.svg'}
              iconCssClass={password === '' ? 'var(--COLOR-SECONDARY-300)' : 'whites-normal-1000-svg'}
            />
          </div>
        )}
        {props.slide.data.passcodeType === 'GPS' && (
          <div className={styles.authorizeGpsDetails}>
            <div style={{paddingBottom: '19px',}}>Submit your location by hitting the button</div>
            <RoundedCornerButton
                label="submit current gps location"
                style={{
                  backgroundColor: '#FEB213',
                  width: '328px',
                }}
                labelColor="white"
                onClick={onSubmit}
              />
            <div className={styles.accessDenied}>{!props.slide.data.authorized && props.slide.data.message ? props.slide.data.message : ''}</div>
          </div>
        )}
      </div>
    );
  }
}
