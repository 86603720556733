import React, { CSSProperties } from 'react';
import { extractHostname } from '../../../../utils/helperFunctions';
import { startsWith } from 'ramda';
import GideImage from '../../../Shared/Image/GideImage';
import { HyperLinkLayout } from '../../../../models/CommonEnums';
import HyperLink from '../HyperLink/HyperLink';

export interface LinkPreviewImage {
  url: string;
  width?: number;
  height?: number;
  type?: string;
}
export interface LinkPreviewProps {
  url: string;
  image?: LinkPreviewImage;
  title?: string;
  description?: string;
  style?: CSSProperties;
  layout?: HyperLinkLayout;
}

export function LinkPreview(props: LinkPreviewProps) {
  let previewLink: any;
  const url =
    props.image && props.image.url
      ? startsWith('http', props.image.url)
        ? props.image.url
        : startsWith('//', props.image.url)
        ? `${startsWith('https', props.url) ? 'https:' : 'http:'}${props.image.url}`
        : startsWith('/', props.image.url)
        ? `${props.url}${props.image.url}`
        : '/icons/slidetype/links/main.svg'
      : '/icons/slidetype/links/main.svg';
  const style = {...props.style, background: 'var(--WHITES-NORMAL-1000)'};
  return (
    <>
      {props.layout === HyperLinkLayout.HyperLink && <HyperLink url={props.url} />}
      {props.layout === HyperLinkLayout.BlogImageLeft && (
        <div
          className="blogImageLeftUrlLinkPreview"
          style={style}
          onClick={() => {
            if (previewLink) {
              previewLink.click();
            }
          }}
        >
          <div className="linkPreviewImage">
            <GideImage className="primaryImage" src={url} alt={props.title ? props.title : ''} />
            <div className="circleBackground">
              <GideImage className="whites-normal-1000-svg" src="/icons/slidetype/links/main.svg" alt="link" />
            </div>
          </div>
          <div className="linkPreviewDetails">
            <div className="linkPreviewTitleContainer">
              <div className="linkPreviewTitle">{props.title ? props.title : ''}</div>
              <div className="linkPreviewDescription">{props.description ? props.description : ''}</div>
            </div>
            <span className="linkPreviewUrl">
              <a href={props.url} ref={(link: any) => (previewLink = link)} target="_blank">
                <span className="TEXTSUBTITLEblackhigh-emphasisleft">From: </span>{extractHostname(props.url)}
              </a>
            </span>
          </div>
        </div>
      )}
      {props.layout === HyperLinkLayout.BlogImageTop && (
        <div
          className="blogImageTopUrlLinkPreview"
          style={props.style}
          onClick={() => {
            if (previewLink) {
              previewLink.click();
            }
          }}
        >
          <div className="linkPreviewImage">
            <GideImage className="primaryImage" src={url} alt={props.title ? props.title : ''} />
            <div className="circleBackground">
              <GideImage className="whites-normal-1000-svg" src="/icons/slidetype/links/main.svg" alt="link" />
            </div>
          </div>
          <div className="linkPreviewDetails">
            <div className="linkPreviewTitleContainer">
              <div className="linkPreviewTitle">{props.title ? props.title : ''}</div>
              <div className="linkPreviewDescription">{props.description ? props.description : ''}</div>
            </div>
            <span className="linkPreviewUrl">
              From:
              <a href={props.url} ref={(link: any) => (previewLink = link)} target="_blank">
                {extractHostname(props.url)}
              </a>
            </span>
          </div>
        </div>
      )}
    </>
  );
}
