import { Editor, Transforms, Element } from "slate";
import { isBlockActive } from "./Element";


export const withEmbeds = <T extends Editor>(editor: T) => {
  const { isInline, insertBreak } = editor;

  editor.isInline = element => {
    return element.type === 'embed' ? true : isInline(element);
  }

  editor.insertBreak = () => {
    const [parent] = Editor.parent(editor, editor.selection!);
    // const [node] = Editor.node(editor, editor.selection!);
    if (Element.isElement(parent) && parent.type === 'embed') {
      editor.insertText('\n');
    } else {
      insertBreak();
    }
  };

  return editor;
}

export const unwrapEmbed = (editor: Editor) => {
  Transforms.unwrapNodes(editor, { match: n => n.type === 'embed' });
}

export const wrapEmbed = (editor: Editor) => {
  if (isBlockActive(editor, 'embed')) {
    unwrapEmbed(editor);
  }
  const embed = {
    type: 'embed',
    children: [],
  };
  Transforms.wrapNodes(editor, embed, { split: true });
}

export const toggleEmbed = (editor: Editor) => {
  if (isBlockActive(editor, 'embed')) {
    unwrapEmbed(editor);
  } else {
    wrapEmbed(editor);
  }
}

