import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Icon } from 'semantic-ui-react';

import { SlideList } from '../Gide/SlideList/SlideList';
import { ADD_SLIDE, MODAL_CLOSE } from '../../constants/actionTypes';
import slideTools from '../../slideTools';

const mapStateToProps = (state, ownProps) => ({
  ...slideTools.mapStateToProps(state, ownProps),
  currentUser: state.common.currentUser,
  viewMode: state.common.viewMode,
});

const mapDispatchToProps = dispatch => ({
  onSubmit: payload => dispatch({ type: ADD_SLIDE, payload }),
  closeModal: payload => dispatch({ type: MODAL_CLOSE }),
});

export class MiniGideModal extends Component {
  render() {
    let slides = this.props.slides || [];
    let miniGideSlides = slides[this.props.slideNumber].magnaSlides;

    return (
      <Modal
        closeOnEscape={true}
        onClose={this.props.closeModal}
        className="miniGideModal"
        size="large"
        dimmer="inverted"
        closeOnDocumentClick={true}
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <div className="modalHeader">
            <Button id="modalClose" icon onClick={this.props.closeModal}>
              <Icon name="close" />
            </Button>
            <span>MiniGide</span>
          </div>
          <section>
            <SlideList
              slides={miniGideSlides}
              // showParent={true}
          />
          </section>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MiniGideModal);
