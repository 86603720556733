import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Icon,
  Input,
  Grid,
  Image,
  Form,
  Button,
  Radio,
} from 'semantic-ui-react';

import SlideModalActions from './SlideModalActions';
import SlideSettings from '../SlideEditors/SlideSettings/SlideSettings';
import { getSlideSettings, updateSlideSettings } from '../SlideEditors/SlideSettings/SlideSettings';

import slideTools from '../../slideTools';
import agent from '../../agent';
import {
  ADD_SLIDE,
  SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES,
} from '../../constants/actionTypes';
import { contentMetaData } from '../../constants/contentMetaData';
import { history } from '../../store';
const mapStateToProps = slideTools.mapStateToProps;

const mapDispatchToProps = dispatch => ({
  onSubmit: payload => dispatch({ type: ADD_SLIDE, payload }),
  updateSlideAttachmentInfo: payload =>
    dispatch({ type: SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES, payload }),
});

export class InputModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slide: {
        slideType: 'INPUT',
        data: {
          audioCaption: null,
          caption: '',
          inputType: '',
          entryType: 'feedback',
          required: false,
          lengthMin: '',
          lengthMax: '',
        },
      },
      showSettings: false,
    };

    if (props.editSlide) {
      this.state.slide = props.editSlide;
    }

    this.updateSlideSettings = settings => {
      this.setState({ slide: updateSlideSettings(this.state.slide, settings) });
    };

    this.closeSettings = () => {
      this.setState({ showSettings: false });
    };

    this.audioCaptionChanged = audioFile => {
      const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
      slide.data.audioCaption = audioFile;
      this.setState({ slide });
    };

    this.handleEntryTypeChange = (e, { value }) => {
      const slide = Object.assign({}, this.state.slide);
      slide.data.entryType = value;
      this.setState({ slide });
    };

    this.updateState = field => ev => {
      const slide = Object.assign({}, this.state.slide);
      slide[field] = ev.target.value;
      this.setState({ slide });
    };

    this.updateDataState = field => ev => {
      const slide = Object.assign({}, this.state.slide);
      slide.data[field] = ev.target.value;
      this.setState({ slide });
    };

    this.createSlide = async () => {
      let slidePosition = this.props.currentSlidePosition ? this.props.currentSlidePosition : this.props.position;
      let slide = {
        ...this.state.slide,
        slide: this.props.slide,
        createMode: this.props.mode,
        selection: props.selection,
        allowComments: this.props.editSlide
          ? this.state.slide.allowComments
          : this.props.article.allowSlideComments,
        allowQuestions: this.props.editSlide
          ? this.state.slide.allowQuestions
          : this.props.article.allowSlideQuestions,
        // If editing, don't change the position.
        position: this.props.editSlide
          ? this.state.slide.position
          : slidePosition,
      };
      let payload;
      if (this.props.editSlide) {
        payload = await agent.Slides.update(this.props.editSlide.id, slide);
      } else {
        payload = await agent.Slides.create(this.props.article, slide);
        if (this.props.childArticleEditInfo) {
          slideTools.getSlideAttachmentInfo(
            this.props.childArticleEditInfo.ownerSlide.id,
            this.props.updateSlideAttachmentInfo,
          );
        }
      }
      this.props.onSubmitSlide ?
      this.props.onSubmitSlide({ ...payload, mode: this.props.mode }) :
      this.props.onSubmit({ ...payload, mode: this.props.mode });
      history.goBack()
    };

    this.setInputType = inputType => {
      const slide = Object.assign({}, this.state.slide);
      slide.data.inputType = inputType;
      this.setState({ slide });
    };

    this.setRequired = required => {
      const slide = Object.assign({}, this.state.slide);
      slide.data.required = required;
      this.setState({ slide });
    };
  }

  isVideoOrAudio() {
    if (
      this.state.slide.data.inputType === 'AUDIO' ||
      this.state.slide.data.inputType === 'VIDEO'
    )
      return true;
  }
  render() {
    const { slide } = this.state;
    return (
      <Modal
        closeOnEscape={true}
        onClose={() => history.goBack()}
        className="inputModal"
        size="small"
        dimmer="inverted"
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Content scrolling>
          <div
            className="modalHeader"
            style={{ background: contentMetaData['INPUT'].primaryColor }}
          >
            <Button id="modalClose" icon onClick={() => history.goBack()}>
              <Icon name="close" />
            </Button>
            <span>
              <Icon name="unordered list" size="large" /> Input Type
              {slide.data.inputType}
            </span>
            <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-input.svg" />
          </div>
          {!this.state.showSettings && (
            <Modal.Description>
              <div className="inner">
                {slide.data.inputType === '' && (
                  <div>
                    <Grid className="choices">
                      <Grid.Row columns={3}>
                        <Grid.Column
                          onClick={() => this.setInputType('DOODLE')}
                        >
                          <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-doodle.svg" />
                          <p style={{ textAlign: 'center' }}>Doodle</p>
                        </Grid.Column>
                        <Grid.Column
                          onClick={() => this.setInputType('DIAGRAM')}
                        >
                          <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-diagram.svg" />
                          <p style={{ textAlign: 'center' }}>Diagram</p>
                        </Grid.Column>
                        <Grid.Column
                          onClick={() => this.setInputType('HEADER')}
                        >
                          <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-header.svg" />
                          <p style={{ textAlign: 'center' }}>Header</p>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Grid>
                      <Grid.Row columns={3}>
                        <Grid.Column onClick={() => this.setInputType('IMAGE')}>
                          <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-image.svg" />
                          <p style={{ textAlign: 'center' }}>Image</p>
                        </Grid.Column>
                        <Grid.Column onClick={() => this.setInputType('AUDIO')}>
                          <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-audio.svg" />
                          <p style={{ textAlign: 'center' }}>Audio</p>
                        </Grid.Column>
                        <Grid.Column onClick={() => this.setInputType('VIDEO')}>
                          <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-video.svg" />
                          <p style={{ textAlign: 'center' }}>Video</p>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Grid>
                      <Grid.Row columns={3}>
                        <Grid.Column
                          onClick={() => this.setInputType('LOCATION')}
                        >
                          <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-location.svg" />
                          <p style={{ textAlign: 'center' }}>Location</p>
                        </Grid.Column>
                        <Grid.Column onClick={() => this.setInputType('MAP')}>
                          <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-maps.svg" />
                          <p style={{ textAlign: 'center' }}>Map</p>
                        </Grid.Column>
                        <Grid.Column onClick={() => this.setInputType('FILE')}>
                          <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-image.svg" />
                          <p style={{ textAlign: 'center' }}>File</p>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Grid>
                      <Grid.Row columns={3}>
                        <Grid.Column onClick={() => this.setInputType('LEAD')}>
                          <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-image.svg" />
                          <p style={{ textAlign: 'center' }}>Lead</p>
                        </Grid.Column>
                        <Grid.Column onClick={() => this.setInputType('LIST')}>
                          <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-list.svg" />
                          <p style={{ textAlign: 'center' }}>List</p>
                        </Grid.Column>
                        <Grid.Column onClick={() => this.setInputType('LINKS')}>
                          <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-divide.svg" />
                          <p style={{ textAlign: 'center' }}>Link</p>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Grid>
                      <Grid.Row columns={3}>
                        <Grid.Column
                          onClick={() => this.setInputType('CONTACTS')}
                        >
                          <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-contact.svg" />
                          <p style={{ textAlign: 'center' }}>Contacts</p>
                        </Grid.Column>
                        <Grid.Column onClick={() => this.setInputType('GIDE')}>
                          <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-gide.svg" />
                          <p style={{ textAlign: 'center' }}>Gide</p>
                        </Grid.Column>
                        <Grid.Column onClick={() => this.setInputType('SLIDE')}>
                          <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-slides.svg" />
                          <p style={{ textAlign: 'center' }}>Slide</p>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Grid>
                      <Grid.Row columns={3}>
                        <Grid.Column onClick={() => this.setInputType('ITEM')}>
                          <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-item.svg" />
                          <p style={{ textAlign: 'center' }}>Item</p>
                        </Grid.Column>
                        <Grid.Column
                          onClick={() => this.setInputType('SIGNATURE')}
                          title="Sign"
                        >
                          <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-doodle.svg" />
                          <p style={{ textAlign: 'center' }}>Signature</p>
                        </Grid.Column>
                        <Grid.Column
                          onClick={() => this.setInputType('EMBED')}
                          title="Embed Twitter, Facebook, Instagram, or YouTube"
                        >
                          <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-doodle.svg" />
                          <p style={{ textAlign: 'center' }}>Embed</p>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Grid>
                      <Grid.Row columns={3}>
                        <Grid.Column onClick={() => this.setInputType('TEXT')}>
                          <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-list.svg" />
                          <p style={{ textAlign: 'center' }}>Text</p>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                )}
                {slide.data.inputType !== '' && (
                  <section>
                    <Form className="entryType">
                      <Form.Field>
                        <Radio
                          label="Template"
                          name="entryTypeRadioGroup"
                          value="template"
                          checked={slide.data.entryType === 'template'}
                          onChange={this.handleEntryTypeChange}
                        />
                        <p>Render Input for Viewer Only</p>
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="Feedback"
                          name="entryTypeRadioGroup"
                          value="feedback"
                          checked={slide.data.entryType === 'feedback'}
                          onChange={this.handleEntryTypeChange}
                        />
                        <p>Send Input to Creator</p>
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="Addition"
                          name="entryTypeRadioGroup"
                          value="addition"
                          checked={slide.data.entryType === 'addition'}
                          onChange={this.handleEntryTypeChange}
                        />
                        <p>Replace Slide via Addition</p>
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="Inline"
                          name="entryTypeRadioGroup"
                          value="inline"
                          checked={slide.data.entryType === 'inline'}
                          onChange={this.handleEntryTypeChange}
                        />
                        <p>
                          Display Response(s) inline for all viewers to see.
                        </p>
                      </Form.Field>
                    </Form>
                    <Form>
                      <Form.Group inline className="required">
                        <Form.Radio
                          label="Optional"
                          name="requiredRadioGroup"
                          checked={!slide.data.required}
                          onChange={() => this.setRequired(false)}
                        />
                        <Form.Radio
                          label="Required"
                          name="requiredRadioGroup"
                          checked={slide.data.required}
                          onChange={() => this.setRequired(true)}
                        />
                      </Form.Group>
                    </Form>
                    {this.isVideoOrAudio() && (
                      <section>
                        <Input
                          fluid
                          placeholder="Minimum time (optional)"
                          value={slide.data.lengthMin}
                          onChange={this.updateState('lengthMin')}
                        />
                        <Input
                          fluid
                          placeholder="Maximum time length (optional)"
                          value={slide.data.lengthMax}
                          onChange={this.updateState('lengthMax')}
                        />
                      </section>
                    )}
                  </section>
                )}
              </div>
            </Modal.Description>
          )}
          {this.state.showSettings && (
            <Modal.Description className="settingsPanel">
              <SlideSettings
                canSetIsTemplate={false}
                settings={getSlideSettings(
                  this.state.slide,
                  this.props.article.type,
                )}
                onSettingsChanged={this.updateSlideSettings.bind(this)}
                onCloseSettings={this.closeSettings.bind(this)}
              />
            </Modal.Description>
          )}
        </Modal.Content>
        {slide.data.inputType !== '' && (
          <Modal.Actions
            style={{ background: contentMetaData['INPUT'].primaryColor }}
          >
            <SlideModalActions
              caption={slide.data.caption}
              captionChanged={this.updateDataState('caption').bind(this)}
              audioCaption={slide.data.audioCaption}
              onAudioCaptionChanged={this.audioCaptionChanged.bind(this)}
              showSettings={this.state.showSettings}
              canNavigateBack={true}
              backClicked={() => this.setInputType('')}
              settingsClicked={() =>
                this.setState({ showSettings: !this.state.showSettings })
              }
              nextClicked={this.createSlide}
              settings={getSlideSettings(
                this.state.slide,
                this.props.article.type,
              )}
              onSettingsChanged={this.updateSlideSettings.bind(this)}
            />
          </Modal.Actions>
        )}
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InputModal);
