import { Article } from "./Article";
import { Slide } from "./Slide";

export enum ArticleViewMode {
  Scroll = 0,
  Slideshow = 1,
  Thumbnail = 2,
}

export enum ArticleLayoutView {
  Responsive = 0,
  WideScreen = 1,
}
export enum ChildArticleType {
  Attachments = 0,
  Comments = 1,
  PrivateNotes = 2,
  Questions = 3,
  Collapse = 4,
  AuthorizeBlock = 5,
  Slideshow = 6,
  Response = 7,
}
export interface ChildArticleSlideDetail {
  type: string;
  count: number;
}
export interface ChildArticleDetail {
  article: Article;
  slides?: Slide[];
  slidePosition: number;
  ownerSlide: Slide;
  articleType: ChildArticleType;
}
export const getChildArticleTypeName = (
  childArticleType: ChildArticleType,
): string => {
  switch (childArticleType) {
    case ChildArticleType.Attachments:
      return 'SETTINGS';
    case ChildArticleType.Comments:
      return 'COMMENTS';
    case ChildArticleType.PrivateNotes:
      return 'PRIVATENOTES';
    case ChildArticleType.Questions:
      return 'QUESTIONS';
    case ChildArticleType.Collapse:
      return 'COLLAPSE';
    case ChildArticleType.AuthorizeBlock:
      return 'AUTHORIZEBLOCK';
    case ChildArticleType.Slideshow:
      return 'SLIDESHOW';
    case ChildArticleType.Response:
      return 'RESPONSE';
  }
  return 'settings';
};
export const getChildArticleTypeEnum = (enumName: string): ChildArticleType => {
  switch (enumName) {
    case 'SETTINGS':
      return ChildArticleType.Attachments;
    case 'COMMENTS':
      return ChildArticleType.Comments;
    case 'PRIVATENOTES':
      return ChildArticleType.PrivateNotes;
    case 'QUESTIONS':
      return ChildArticleType.Questions;
    case 'COLLAPSE':
      return ChildArticleType.Collapse;
    case 'AUTHORIZEBLOCK':
      return ChildArticleType.AuthorizeBlock;
    case 'SLIDESHOW':
      return ChildArticleType.Slideshow;
    case 'RESPONSE':
      return ChildArticleType.Response;
  }
  return ChildArticleType.Attachments;
};
export const getChildArticleDisplayName = (
  childArticleType: string,
): string => {
  switch (childArticleType) {
    case 'SETTINGS':
      return 'Attachments';
    case 'COMMENTS':
      return 'Comments';
    case 'PRIVATENOTES':
      return 'Private Notes';
    case 'QUESTIONS':
      return 'Questions';
    case 'COLLAPSE':
      return 'Collapse';
    case 'AUTHORIZEBLOCK':
      return 'Authorize Block';
    case 'SLIDESHOW':
      return 'Slideshow';
    case 'RESPONSE':
      return 'Response';
  }
  return 'Settings';
};
export const getDisplayTextForChildArticleType = (
  childArticleType: ChildArticleType,
): string => {
  switch (childArticleType) {
    case ChildArticleType.Attachments:
      return 'Attachments';
    case ChildArticleType.Comments:
      return 'Comments';
    case ChildArticleType.PrivateNotes:
      return 'Private Notes';
    case ChildArticleType.Questions:
      return 'Questions';
    case ChildArticleType.Collapse:
      return 'Collapse';
    case ChildArticleType.AuthorizeBlock:
      return 'Authorize Block';
    case ChildArticleType.Slideshow:
      return 'Slideshow';
    case ChildArticleType.Response:
      return 'Response';
  }
  return 'Settings';
};

export const getIconForChildArticleTypeAsString = (childArticleType: string) => {
  return getIconForChildArticleType(getChildArticleTypeEnum(childArticleType));
};

export const getIconForChildArticleType = (
  childArticleType: ChildArticleType,
): string => {
  switch(childArticleType) {
    case ChildArticleType.Attachments:
      return '/icons/content-alteration/attach.svg';
    case ChildArticleType.Comments:
      return '/icons/content-alteration/comments.svg';
    case ChildArticleType.PrivateNotes:
        return '/icons/content-alteration/notes.svg';
    case ChildArticleType.Questions:
      return '/icons/content-alteration/questions.svg';
    case ChildArticleType.AuthorizeBlock:
      return '/icons/slidetype/authorize/main.svg';
    default:
        return '';

  }
};
