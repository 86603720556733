import React, { useState, useRef, CSSProperties } from 'react';
import { Menu, Button, MenuItem } from '@material-ui/core';
import { ReactComponent as UpIcon } from './icons/arrow-up.svg';
import { ReactComponent as DownIcon } from './icons/arrow-down.svg';

export interface RichTextEditorSelectProps {
  style?: CSSProperties,
  labelWidth: number;
  options?: {
    label: React.ReactNode;
    value: any;
  }[];
  value: any;
  onChange: (newValue: any) => void;
}

export const RichTextEditorSelect: React.FC<RichTextEditorSelectProps> = (props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpen(true);
  };
  const handleSelect = (e: React.MouseEvent<HTMLLIElement>, index: number) => {
    handleClose(e);
    const option = props.options?.[index]?.value;
    props.onChange(option);
  }
  const handleClose = (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    setOpen(false);
  };

  const label = props.options?.find(o => o.value === props.value)?.label || props.value;

  return (
    <span style={props.style}>
      <Button size="small" onMouseDown={handleOpen} 
        ref={buttonRef}
        style={{
          background: '#F0F0F0',
          borderRadius: 2,
          width: props.labelWidth,
          minWidth: props.labelWidth,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {label}
      </Button>
      <Button size="small" onMouseDown={handleOpen} 
        disableRipple
        style={{
          background: '#F0F0F0',
          borderRadius: 2,
          marginLeft: 3,
          minWidth: 32,
          width: 32
        }}
      >
        {open ? <UpIcon /> : <DownIcon />}
      </Button>
      <Menu
        anchorEl={buttonRef.current}
        keepMounted
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {props.options?.map((o, i) => 
          <MenuItem key={i}            
            onMouseDown={(e) => handleSelect(e, i)} 
            selected={props.value === o.value}
          >
            {o.label}
          </MenuItem>)
        }
      </Menu>
    </span>
  );
}
