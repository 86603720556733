import React, { useState, useEffect, useRef } from 'react';
import PickerModal from '../PickerModal/PickerModal';
import Picker, { PickerItem } from '../../Picker/Picker';
import icons from '../../../../../assets/icons';
import { VideoViewerMode } from '../../../../../models/CommonEnums';
import CarouselSettings, {
  CarouselSettingsData,
  DefaultCarouselSettings,
} from '../../../../SlideEditors/SlideSettings/CarouselSettings/CarouselSettings';

export interface VideoSlideFormatSettings {
  format: VideoViewerMode;
  carouselSettingsData?: CarouselSettingsData;
}
export interface VideoSlideFormatPickerProps {
  onCancel: (originalSettings: VideoSlideFormatSettings) => void;
  onSave: (value: VideoSlideFormatSettings) => void;
  onChange: (value: VideoSlideFormatSettings) => void;
  onUnhandled: (originalSettings: VideoSlideFormatSettings) => void;
  videoSlideFormatSettings: VideoSlideFormatSettings;
  className?: string;
}

const pickerItems: PickerItem[] = [
  { icon: icons.CreationProcess_DisplaySettings_Stacked, label: "Scroll", value: VideoViewerMode.Stacked },
  { icon: icons.CreationProcess_DisplaySettings_Swipe, label: VideoViewerMode[VideoViewerMode.Gallery], value: VideoViewerMode.Gallery },
];

export const VideoSlideFormatPicker: React.FC<VideoSlideFormatPickerProps> = props => {
  const [originalSettings] = useState<VideoSlideFormatSettings>(props.videoSlideFormatSettings);

  const [step, setStep] = useState(1);
  const handled = useRef(false);
  useEffect(() => {
    return () => {
      if (!handled.current) {
        setStep(1);
        props.onUnhandled(originalSettings);
      }
    };
  }, []);

  return (
    <PickerModal
      className={props.className}
      onCancel={() => {
        handled.current = true;
        setStep(1);
        props.onCancel(originalSettings);
      }}
      onSave={() => {
        if (
          props.videoSlideFormatSettings.format !== VideoViewerMode.Carousel ||
          (props.videoSlideFormatSettings.format === VideoViewerMode.Carousel && step === 2)
        ) {
          setStep(1);
          handled.current = true;
          props.onSave({
            format: props.videoSlideFormatSettings.format,
            carouselSettingsData: props.videoSlideFormatSettings.carouselSettingsData,
          });
        } else {
          setStep(2);
        }
      }}
      saveLabel={props.videoSlideFormatSettings.format === VideoViewerMode.Carousel && step === 1 ? 'Next' : undefined}
      saveIcon={props.videoSlideFormatSettings.format === VideoViewerMode.Carousel && step === 1 ? icons.Nav_Arrow_ArrowRight : undefined}
    >
      <>
        {(props.videoSlideFormatSettings.format !== VideoViewerMode.Carousel || step === 1) && (
          <Picker
            pickerItems={pickerItems}
            title="Choose a title format that fits your gide"
            selectedValue={props.videoSlideFormatSettings.format}
            onChange={(value: number) => {
              const carouselSettingsData = (value !== VideoViewerMode.Carousel) 
                ? undefined
                : props.videoSlideFormatSettings.carouselSettingsData;
              props.onChange({ format: value, carouselSettingsData });
            }}
            style={{ marginBottom: '26px', marginTop: '22px' }}
          />
        )}
        {props.videoSlideFormatSettings.format === VideoViewerMode.Carousel && step === 2 && (
          <CarouselSettings
            carouselSettingsData={
              props.videoSlideFormatSettings.carouselSettingsData
                ? props.videoSlideFormatSettings.carouselSettingsData
                : DefaultCarouselSettings
            }
            onCarouselSettingsChanged={(carouselSettings: CarouselSettingsData) => {
              props.onChange({ format: props.videoSlideFormatSettings.format, carouselSettingsData: carouselSettings });
            }}
          />
        )}
      </>
    </PickerModal>
  );
};
