import React, { Component } from 'react';
import { connect } from 'react-redux';
// import ImageMapper from 'react-image-mapper';

import ImageMapper from '../ImageMapper';
import { MODAL_OPEN, VIEW_SLIDE } from '../../constants/actionTypes';

const mapStateToProps = state => ({
  ...state.article,
});

const mapDispatchToProps = dispatch => ({
  openModal: payload => dispatch({ type: MODAL_OPEN, payload }),
  onViewSlide: number => dispatch({type: VIEW_SLIDE, number}),
});

export class DiagramSlide extends Component {

  onAreaClick(area, index, ev) {
    const point = this.props.slide.data.points[index];
    if (!point.slide) return;
    this.props.slides.forEach((s, i) => {
      if (point.slide.id === s.id) {
        const number = i + 1;
        this.props.openModal({
          modalType: 'SwipeViewModal',
        });
        this.props.onViewSlide(number);
      }
    });
  }

  render() {
    const { slide } = this.props;
    if (!slide.data.points) {
      return null;
    }
    const { points } = slide.data;
    const areas = points.map(point => {
      let area = {
        shape: 'circle',
        coords: [point.x, point.y, 15],
        label: point.label,
      };
      return area;
    });
    const map = {
      name: 'my-map',
      areas,
    };
    return (
      <div>
        <section>
          <ImageMapper
            src={slide.data.imageURL}
            map={map}
            // width={500}
            onClick={this.onAreaClick.bind(this)}
            strokeColor={'rgba(100, 200, 250, 0.5)'}
            lineWidth={1}
            alwaysShow
            className="imageMapper"
          />
        </section>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DiagramSlide);
