import { Article } from "./Article";

export enum PriceType {
  Fixed = 'FIXED',
  Donate = 'DONATE',
}

export enum PurchaseType {
  Own = 'OWN',
  Rent = 'RENT',
  Subscribe = 'SUBSCRIBE'
}

export enum SubscriptionPlan {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export const getSubscriptionPlanDisplayName = (
  subscriptionPlan: SubscriptionPlan,
): string => {
  switch (subscriptionPlan) {
    case SubscriptionPlan.Daily:
      return 'Daily';
    case SubscriptionPlan.Weekly:
      return 'Weekly';
    case SubscriptionPlan.Monthly:
      return 'Monthly';
    case SubscriptionPlan.Yearly:
      return 'Yearly'
  }
  return 'Undefined';
};

export interface GideGroup {
  groupId: string;
  groupTitle: string;
  gideList: Article[];
  hasMoreGides: boolean;
}
