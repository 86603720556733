import React, { Component } from 'react';
import { ImageObjectFit } from '../../../../models/CommonEnums';
import { Input, InputOnChangeData } from 'semantic-ui-react';
import { CarouselNavigationMode } from '../../../Viewers/ImageViewers/ImageCarousel/ImageCarousel';
import RadioButtonList from '../../../Navigation/NavElements/Radio/RadioButtonList/RadioButtonList';
import pickerModalStyles from '../../../DataEntry/Pickers/Modals/PickerModal/_picker-modal.module.scss';
export interface CarouselSettingsData {
  carouselNavigationMode: CarouselNavigationMode,
  carouselAdvanceSpeed: number,
  maxHeight: string,
  imageObjectFit: ImageObjectFit,
}
export const DefaultCarouselSettings = {
  carouselNavigationMode: CarouselNavigationMode.Thumbnail,
  carouselAdvanceSpeed: 0,  
  maxHeight: '400',
  imageObjectFit: ImageObjectFit.AutoCrop,
}
export interface CarouselSettingsState {
  displayAdvancedcarouselImageSettings: boolean;
}
export interface CarouselSettingsProps {
  carouselSettingsData: CarouselSettingsData;
  onCarouselSettingsChanged: (carouselSettings: CarouselSettingsData) => void;
}

export default class CarouselSettings extends Component<CarouselSettingsProps, CarouselSettingsState> {
  constructor(props: CarouselSettingsProps) {
    super(props);
    this.state = {
      displayAdvancedcarouselImageSettings: false,
    }
  }
  render() {
    return (
      <div className={`carouselImageSettings`}
        onClick={(e) => e.stopPropagation()}
      >
        <span style={{marginLeft: '10px'}} className="TEXTSUBTITLEblackhigh-emphasisleft">
          Style your carousel
        </span>
        <span className="TEXTSUBTITLE-2blackmedium-emphasisleft" style={{marginTop: '18px', marginLeft: '10px'}}>
          Choose a carousel style
        </span>
        <div className="viewOptions">         
          <RadioButtonList
              style={{width: 'calc(100% - 20px)', marginLeft: '10px', marginRight: '10px'}}
              itemStyle={{paddingLeft: '5px'}}
              displaySeparators={true}
              options={[
                {value: CarouselNavigationMode.Thumbnail, label: 'With thumbnails'},
                {value: CarouselNavigationMode.RotatingArrows, label: 'Rotating'},
              ]}
              groupName="carouselType"
              selectedValue={this.props.carouselSettingsData.carouselNavigationMode === CarouselNavigationMode.Thumbnail 
                ? CarouselNavigationMode.Thumbnail
                : CarouselNavigationMode.RotatingArrows // This covers RotatingArrows, RotatingBullets, and RotatingArrowsAndBullets for subsequent option
              }
              onChange={(value: string | number) => {
                  this.props.onCarouselSettingsChanged({
                    ...this.props.carouselSettingsData,
                    carouselNavigationMode: value as number
                  });
              }}
            />
        </div>
        
        <div className={this.props.carouselSettingsData.carouselNavigationMode !== CarouselNavigationMode.Thumbnail ? 'advancedCarouselOptions' : 'advancedCarouselOptions hidden'}>
            <div className={pickerModalStyles.horizontalLine} style={{marginTop: '13px', marginBottom: '10px'}} />
            <span className="TEXTSUBTITLE-2blackmedium-emphasisleft" style={{marginLeft: '10px'}}>Image Advance</span>
            {/* <div className='imageAdvanceMode'>               */}
              <RadioButtonList
                style={{width: 'calc(100% - 20px)', marginLeft: '10px', marginRight: '10px', marginTop: '10px'}}
                displaySeparators={true}
                itemStyle={{paddingLeft: '5px'}}
                options={[
                  {value: CarouselNavigationMode.RotatingBullets, label: 'Bullets'},
                  {value: CarouselNavigationMode.RotatingArrows, label: 'Arrows'},
                  {value: CarouselNavigationMode.RotatingArrowsAndBullets, label: 'Both'}
                ]}
                groupName="imageAdvanceMode"
                selectedValue={this.props.carouselSettingsData.carouselNavigationMode}
                onChange={(value: string | number) => {
                  this.props.onCarouselSettingsChanged({
                    ...this.props.carouselSettingsData,
                    carouselNavigationMode: value as number
                  });
                }}
              />
            {/* </div> */}
            <div className={pickerModalStyles.horizontalLine} style={{marginTop: '10px', marginBottom: '10px'}} />
            <div className="imageObjectFit">
              <span className="TEXTSUBTITLE-2blackmedium-emphasisleft" style={{marginLeft: '10px'}}>Image display</span>
              <div className="imageObjectFitOptions">
                <RadioButtonList
                  style={{width: 'calc(100% - 20px)', marginLeft: '10px', marginRight: '10px'}}
                  displaySeparators={true}
                  itemStyle={{paddingLeft: '5px'}}
                  options={[
                    {value: ImageObjectFit.AutoCrop, label: 'Auto crop'},
                    {value: ImageObjectFit.FullImage, label: 'Full image'},
                  ]}
                  groupName="imageCropSettings"
                  selectedValue={this.props.carouselSettingsData.imageObjectFit }
                  onChange={(value: string | number) => {
                      this.props.onCarouselSettingsChanged({
                        ...this.props.carouselSettingsData,
                        imageObjectFit: value as number
                      });
                  }}
                />

              </div>
            </div>            
            <div className={pickerModalStyles.horizontalLine} style={{marginTop: '10px', marginBottom: '10px'}} />
            <div className="imageAdvanceSpeed">
              <span className="TEXTSUBTITLE-2blackmedium-emphasisleft" style={{marginLeft: '10px'}}>Advance speed (seconds, blank =  user click)</span>
              <Input
                style={{marginTop: '-5px', marginLeft: '10px', marginRight: '10px'}}
                type="text"
                value={
                  this.props.carouselSettingsData.carouselAdvanceSpeed 
                    && this.props.carouselSettingsData.carouselAdvanceSpeed > 0
                    ? this.props.carouselSettingsData.carouselAdvanceSpeed
                    : ''
                }
                placeholder="speed"
                onChange={(event: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData) => {
                  this.props.onCarouselSettingsChanged({
                    ...this.props.carouselSettingsData,
                    carouselAdvanceSpeed: parseInt(data.value, 10)
                  });
                }}
              />
            </div>
            {/* <div className="imageMaxHeight">
              <span className="TEXTSUBTITLE-2blackmedium-emphasisleft">Max height (pixels)</span>
              <Input
                style={{marginLeft: '10px', marginRight: '10px'}}
                type="text"
                value={this.props.carouselSettingsData.maxHeight ? this.props.carouselSettingsData.maxHeight : ''}
                placeholder="max height"
                onChange={(event: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData) => {
                  this.props.onCarouselSettingsChanged({
                    ...this.props.carouselSettingsData,
                    maxHeight: data.value,
                  });
                }}
              />
            </div> */}
          </div>
      </div>
    );
  }
}
