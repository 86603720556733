import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export interface DraggableListItem {
  id: string;
  content: any;
}
export interface DragReorderableListProps {
  items: DraggableListItem[];
  onItemsReordered: (items: DraggableListItem[]) => void;
}

export interface DragReorderableListState {
  items: DraggableListItem[];
}

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: any, index: number) => {

  const basicStyles = {    
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      padding: 1,
      margin: `0 ${0}px 0 0`,
  };

  const styles = (isDragging) 
    ? {
      ...basicStyles,
      // change background colour if dragging
      background: "rgba(0,0,0, .9)",
      // styles we need to apply on draggables
      ...draggableStyle,
      // **** FIX CHROME BUG - need to use absolute instead of fixed! ****
      // https://stackoverflow.com/questions/54982182/react-beautiful-dnd-drag-out-of-position-problem
      // https://github.com/atlassian/react-beautiful-dnd/issues/485#issuecomment-385816391
      // position: 'absolute',
      top: 2,
      left: 62 + (index * 43),
      // right: '271px',
    } : {
      ...basicStyles,
      // change background colour if dragging
      background: "white",
      // styles we need to apply on draggables
      ...draggableStyle,
    };

  return styles;
};

const getListStyle = (isDraggingOver: any) => ({
  background: isDraggingOver ? "lightgrey" : "white",
  display: "flex",
  padding: 2,
  overflow: "auto"
});



export default class DragReorderableList extends Component<DragReorderableListProps, DragReorderableListState> {
  constructor(props: DragReorderableListProps) {
    super(props);

    this.state = {
      // items: getItems(6)
      items: props.items
    }
  }
  onDragEnd(result: any) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items
    });
    this.props.onItemsReordered(items);
  }
  componentDidUpdate(prevProps: DragReorderableListProps) {
    if (prevProps.items !== this.props.items) {
      this.setState({ items: this.props.items })
    }
  }
  public render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {this.state.items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index} disableInteractiveElementBlocking>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                        index
                      )}
                    >
                      {item.content}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}
