import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Form,
  Radio,
  Input,
  Grid,
  Image,
  Button,
  Icon,
} from 'semantic-ui-react';
import superagent from 'superagent';
import DropZone from 'react-dropzone';

import SlideModalActions from './SlideModalActions';
import SlideSettings from '../SlideEditors/SlideSettings/SlideSettings';
import { getSlideSettings, updateSlideSettings } from '../SlideEditors/SlideSettings/SlideSettings';

import agent from '../../agent';
import { API_ROOT } from '../../constants/paths';
import {
  ADD_SLIDE,
  REPLACE_SLIDE,
  MODAL_CLOSE,
  MODAL_OPEN,
  SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES,
} from '../../constants/actionTypes';
import slideTools from '../../slideTools';

let dropZoneStyles = {
  width: '100%',
  height: '150px',
  backgroundColor: '#efefef',
  cursor: 'pointer',
  padding: '55px 0 0 0',
};

const mapStateToProps = slideTools.mapStateToProps;

const mapDispatchToProps = dispatch => ({
  onSubmit: (replaceSlide, payload) =>
    dispatch({ type: replaceSlide ? REPLACE_SLIDE : ADD_SLIDE, payload }),
  closeModal: payload => dispatch({ type: MODAL_CLOSE }),
  openModal: payload => dispatch({ type: MODAL_OPEN, payload }),
  updateSlideAttachmentInfo: payload =>
    dispatch({ type: SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES, payload }),
});

export class ItemModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      slide: {
        slideType: 'ITEM',
        data: {
          audioCaption: null,
          caption: '',
          type: '',
          url: '',
          name: '',
          quantity: '',
          description: '',
          sku: '',
          linkText: '',
          showSettings: false,
          replaceMode: false,
        },
      },
    };

    // Populates the new slide with the settings from the parent slide which is this.props.slide
    if (props.mode === 'REPLACE' && props.slide) {
      this.state.replaceMode = true;
      const inputSlide = props.slides.find(s => s.id === props.slide);
      if (inputSlide) {
        const settings = getSlideSettings(inputSlide);
        this.state.slide = updateSlideSettings(this.state.slide, settings);
      }
    }

    if (props.editSlide) {
      this.state.slide = props.editSlide;
    }

    this.updateSlideSettings = settings => {
      this.setState({ slide: updateSlideSettings(this.state.slide, settings) });
    };

    this.closeSettings = () => {
      this.setState({ showSettings: false });
    };

    this.audioCaptionChanged = audioFile => {
      const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
      slide.data.audioCaption = audioFile;
      this.setState({ slide });
    };

    this.handleChange = (e, { value }) => {
      const slide = Object.assign({}, this.state.slide);
      slide.data.type = value;
      this.setState({ slide });
    };

    this.updateDataState = field => ev => {
      const slide = Object.assign({}, this.state.slide);
      slide.data[field] = ev.target.value;
      this.setState({ slide });
    };

    this.updateStateCheckbox = field => (ev, data) => {
      const slide = Object.assign({}, this.state.slide);
      slide.data[field] = data.checked;
      this.setState({ slide });
    };

    this.createSlide = async () => {
      let slidePosition = this.props.currentSlidePosition ? this.props.currentSlidePosition : this.props.position;
      let slide = {
        ...this.state.slide,
        slide: this.props.slide,
        createMode: this.props.mode,
        selection: props.selection,
        allowComments: this.props.editSlide
          ? this.state.slide.allowComments
          : this.props.article.allowSlideComments,
        allowQuestions: this.props.editSlide
          ? this.state.slide.allowQuestions
          : this.props.article.allowSlideQuestions,
        // If editing, don't change the position.
        position: this.props.editSlide
          ? this.state.slide.position
          : slidePosition,
      };

      if (!slide.data.name) {
        return alert('Name required');
      }

      let payload;
      if (this.state.replaceMode) {
        let replaceSlideId = slide.slide;
        slide.slide = null;
        payload = await agent.Slides.replace(
          this.props.article,
          replaceSlideId,
          slide,
        );
        payload = {
          ...payload,
          slideIdToRemove: replaceSlideId,
        };
      } else {
        if (this.props.editSlide) {
          payload = await agent.Slides.update(this.props.editSlide.id, slide);
        } else {
          payload = await agent.Slides.create(this.props.article, slide);
          if (this.props.childArticleEditInfo) {
            slideTools.getSlideAttachmentInfo(
              this.props.childArticleEditInfo.ownerSlide.id,
              this.props.updateSlideAttachmentInfo,
            );
          }
        }
      }
      this.props.onSubmitSlide ?
      this.props.onSubmitSlide({ ...payload, mode: this.props.mode }) :
      this.props.onSubmit(this.state.replaceMode, {
        ...payload,
        mode: this.props.mode,
      });
      this.props.closeModal();
    };

    this.handleNextClicked = ev => {
      ev.preventDefault();
      if (this.state.step === 1) {
        this.setState({ step: 2 });
      } else {
        this.createSlide();
      }
    };

    this.handleBackClicked = ev => {
      ev.preventDefault();
      this.setState({ step: 1 });
    };

    this.linkSlide = () => {
      this.props.openModal({
        modalType: 'SlideSelectModal',
        modalProps: {},
      });
    };
  }

  onDrop(acceptedFiles, rejectedFiles) {
    if (acceptedFiles.length) {
      let file = acceptedFiles[0];
      superagent
        .post(`${API_ROOT}/util/upload`)
        .attach('theseNamesMustMatch', file)
        .end((err, res) => {
          if (err) {
            // console.log(err);
            alert('Please try again later.');
          } else if (res.body && res.body.url) {
            const slide = Object.assign({}, this.state.slide);
            slide.data.image = res.body.url;
            this.setState({ slide });
          }
        });
    }
  }

  render() {
    const { slide } = this.state;
    return (
      <Modal
        closeOnEscape={true}
        onClose={this.props.closeModal}
        className="itemModal"
        size="small"
        dimmer="inverted"
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <div
            className="modalHeader"
            style={{ background: 'rgb(59, 128, 169)' }}
          >
            <Button id="modalClose" icon onClick={this.props.closeModal}>
              <Icon name="close" />
            </Button>
            <span>Item Type</span>
            <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-item.svg" />
          </div>
          <Modal.Description>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <Form.Field>
                    <Radio
                      label="Product"
                      name="radioGroup"
                      value="product"
                      checked={slide.data.type === 'product'}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <Radio
                      label="Component"
                      name="radioGroup"
                      value="component"
                      checked={slide.data.type === 'component'}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Field>
                    <Radio
                      label="Ingredient"
                      name="radioGroup"
                      value="ingredient"
                      checked={slide.data.type === 'ingredient'}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <Radio
                      label="Tool"
                      name="radioGroup"
                      value="tool"
                      checked={slide.data.type === 'tool'}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Field>
                    <Radio
                      label="Part"
                      name="radioGroup"
                      value="part"
                      checked={slide.data.type === 'part'}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <Radio
                      label="Other"
                      name="radioGroup"
                      value="other"
                      checked={slide.data.type === 'other'}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div>
              <aside>
                <div className="dropzone">
                  <DropZone
                    onDrop={this.onDrop.bind(this)}
                    style={dropZoneStyles}
                    multiple={false}
                  >
                    <p>Drop file or select.</p>
                  </DropZone>
                </div>
              </aside>
              {slide.data.image && (
                <Image src={slide.data.image} alt={`□`} className="centered" />
              )}
              <Form.Field>
                <Input
                  fluid
                  placeholder="Item Name"
                  onChange={this.updateDataState('name')}
                  value={slide.data.name}
                />
              </Form.Field>
              <Form.Field>
                <Input
                  fluid
                  placeholder="URL ex: http://www.example.com"
                  onChange={this.updateDataState('url')}
                  value={slide.data.url}
                />
              </Form.Field>
              <Form.Field>
                <Input
                  fluid
                  placeholder="Link Text"
                  onChange={this.updateDataState('linkText')}
                  value={slide.data.linkText}
                />
              </Form.Field>
              <Form.Field>
                <Input
                  fluid
                  placeholder="Quantity"
                  onChange={this.updateDataState('quantity')}
                  value={slide.data.quantity}
                />
              </Form.Field>
              <Form.Field>
                <Input
                  fluid
                  placeholder="Units"
                  onChange={this.updateDataState('units')}
                  value={slide.data.units}
                />
              </Form.Field>
              <Form.Field>
                <Input
                  fluid
                  placeholder="Description/Comment"
                  onChange={this.updateDataState('description')}
                  value={slide.data.description}
                />
              </Form.Field>
              <Form.Field>
                <Input
                  fluid
                  placeholder="Part #"
                  onChange={this.updateDataState('sku')}
                  value={slide.data.sku}
                />
              </Form.Field>
              <Form.Field>
                <span
                  style={{ margin: '0 5px 0 5px' }}
                  className="link underlined"
                  onClick={this.linkSlide}
                >
                  Link Slide
                </span>
              </Form.Field>
            </div>
          </Modal.Description>
          {this.state.showSettings && (
            <Modal.Description className="settingsPanel">
              <SlideSettings
                canSetIsTemplate={this.props.article.type === 'TEMPLATE'}
                settings={getSlideSettings(
                  this.state.slide,
                  this.props.article.type,
                )}
                onSettingsChanged={this.updateSlideSettings.bind(this)}
                onCloseSettings={this.closeSettings.bind(this)}
              />
            </Modal.Description>
          )}
        </Modal.Content>
        <Modal.Actions>
          <SlideModalActions
            caption={slide.data.caption}
            captionChanged={this.updateDataState('caption').bind(this)}
            audioCaption={slide.data.audioCaption}
            onAudioCaptionChanged={this.audioCaptionChanged.bind(this)}
            showSettings={this.state.showSettings}
            canNavigateBack={false}
            settingsClicked={() =>
              this.setState({ showSettings: !this.state.showSettings })
            }
            nextClicked={this.createSlide}
            settings={getSlideSettings(
              this.state.slide,
              this.props.article.type,
            )}
            onSettingsChanged={this.updateSlideSettings.bind(this)}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemModal);
