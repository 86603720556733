import React from 'react';
import { 
  SlideEditorModalProps, 
  BackIconMode, 
  EditorState, 
  ModalOverlayState, 
} from '../../modals/SlideEditorModal/SlideEditorModal';
import { SlideFile } from '../../../models/SlideFile';
import { lightTheme, darkTheme } from '../../../themes/custom-theme';
import styles from './_Common.module.scss';
import { reject } from 'ramda';
import PickerModal from '../../DataEntry/Pickers/Modals/PickerModal/PickerModal';
import icons from '../../../assets/icons';
import { 
  CommonContext, 
  CommonDerivedState, 
  getHeaderTitleAndIcon,
  CommonModes,
  getFileView, 
} from './Common';

const getSlideEditorModalProps = (context: CommonContext): SlideEditorModalProps => {
  const { state, setState, commonSlideEditorModalProps, isImageSlide, isVideoSlide, isLinkSlide } = context;
  const type = 
    isImageSlide ? 'image' :
    isVideoSlide ? 'video' :
    isLinkSlide ? 'link' :
      'ERROR';
  return {
    ...commonSlideEditorModalProps,
    backIconMode: isLinkSlide ? BackIconMode.Light : BackIconMode.Dark,
    hideFooter: true,
    hideCommandBar: false,
    editorState: EditorState.Edit,
    loadingInfo: undefined,
    modalOverlayState: ModalOverlayState.None,
    selectedItemIndex: -1,
    hideActionContainer: true,
    showBackNavigation: true,
    showDefaultActions: false,
    createActions: [] as any,
    headerActions: getHeaderTitleAndIcon(`Delete ${type}`, '/icons/content-alteration/delete.svg', isLinkSlide ? 'black' : 'white', 'blue'),
    onNavigateBack: () => {
      setState({ ...state, mode: CommonModes.Edit });
    },
  };
}

const getContent = (context: CommonContext): React.ReactNode => {
  const { state, setState, isImageSlide, isVideoSlide, isLinkSlide } = context;
  
  const selectedSlideFile: SlideFile = state.selectedItemIndex > -1 
      && state.slide.data.files.length > state.selectedItemIndex
    ? state.slide.data.files[state.selectedItemIndex]
    : {};

  const newSelectedItemIndex = state.selectedItemIndex + 1 === state.slide.data.files.length
    ? state.selectedItemIndex - 1
    : state.selectedItemIndex;

  const type = 
    isImageSlide ? 'image' :
    isVideoSlide ? 'video' :
    isLinkSlide ? 'link' :
      'ERROR';

  return (
    <div style={{ position: 'relative' }}>
      <PickerModal
        className={styles.deleteModal}
        onSave={() => {
          setState({
            ...state,
            mode: CommonModes.Edit,
            slide: {
              ...state.slide,
              data: {
                ...state.slide.data,
                files: reject((f: SlideFile) => f.id === selectedSlideFile.id, state.slide.data.files),
              }
            },
            selectedItemIndex: newSelectedItemIndex,
          });
        }}
        onCancel={() => {
          setState({ ...state, mode: CommonModes.Edit });
        }}
        saveLabel="Delete"
        saveIcon={icons.ContentAlteration_Delete}
      >
        <div className={styles.deleteMessage}>{`Are you sure you want to delete this ${type}?`}</div>
      </PickerModal>
      {getFileView(context)}
    </div>
  );
}

export const getDerivedState = (context: CommonContext): CommonDerivedState => {
  const slideEditorModalProps = getSlideEditorModalProps(context);
  const content = getContent(context);
  return {
    ...context.state,
    slideEditorModalProps,
    content,
    theme: context.isLinkSlide ? lightTheme : darkTheme,
  };
};

export const Delete = {
  getSlideEditorModalProps,
  getContent,
  getDerivedState,
};
