import React from 'react';
import GideImage from '../Shared/Image/GideImage';

const ButtonSlide = props => {
  const { url, linkText, image } = props.slide.data;
  let fullUrl = url;

  if (!/http/.test(fullUrl)) {
    fullUrl = `http://${fullUrl}`;
  }
  return (
    <div className="itemSlide">
      <div className="itemInfo" style={{ textAlign: 'center' }}>
        <a href={fullUrl} target="blank" className="itemLinkBtn">
          {linkText.length > 0 && !image && linkText}
          {image && <GideImage src={image} alt={`□`} />}
          {!linkText && !image && url}
        </a>
      </div>
    </div>
  );
};

export default ButtonSlide;
