import React, { useState } from 'react';
import styles from './_signin-modal.module.scss';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import ResizeableModal from '../ResizeableModal/ResizeableModal';
import { EditorState } from '../../Navigation/CreationProcess/EditorState/EditorState';
import icons from '../../../assets/icons';
import { RequiredTextboxWithLabel } from '../../AccountProfile/ProfileEditor/ProfileEditor';
import { RoundedCornerButton } from '../../Shared/RoundedCornerButton/RoundedCornerButton';
import MultiImage from '../../Shared/Image/MultiImage/MultiImage';
import { Link } from 'react-router-dom';
import { ASSET_VERSION_NUMBER } from '../../../constants/strings';

export interface SigninModalProps {
  mode: 'signin' | 'signup';
  closeModal: () => void;
  showNotification: (toasterMessageInfo: ToasterMessageInfo) => void;
  onSignUp: () => void;
  onSignin: (username: string, password: string) => Promise<boolean>;
  onChangeAuthField: (key: string, value: string) => void;
}

export default function SigninModal(props: SigninModalProps) {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [attemptedLogin, setLoginAttempt] = useState<boolean>(false);
  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    attempSignin();
  };
  const attempSignin = async () => {
    const successfulLogin = await props.onSignin(username, password);
    if (successfulLogin) {
      props.closeModal();
    }
    setLoginAttempt(true);
  };
  return (
    <ResizeableModal
      className={styles.signinModalContainer}
      closeModal={() => {
        props.closeModal();
      }}
      canResize={false}
      mode={'free'}
      style={{ maxWidth: '528px' }}
      navigationState="closeModal"
      rightToolbarContent={
        <div
          style={{ display: 'flex', alignItems: 'center', marginRight: '12px', marginLeft: '26px', flex: 1, justifyContent: 'flex-end' }}
        >
          <span className="TEXTSUBTITLEblackmedium-emphasisright">{props.mode === 'signin' ? 'Sign in' : 'Request Access'}</span>
          <EditorState
            title=""
            icon={<icons.ContentAlteration_Follower color="var(--COLOR-PRIMARY-600)" />}
            backgroundColor="var(--WHITES-NORMAL-1000)"
          />
        </div>
      }
    >
      <div className={styles.signinModalOuterFormContainer}>
        <MultiImage
          style={{ height: '195px' }}
          imageInfo={{
            imageSet: [
              // Top Left Corner Image
              {
                position: 'left top',
                repeat: 'no-repeat',
                desktopUrl: `https://d2ettyk3s9h19.cloudfront.net/assets/images/brand-assets/sign-up/step.1/layer.2/desktop&tablet.png?v=${ASSET_VERSION_NUMBER}`,
                mobileUrl: `https://d2ettyk3s9h19.cloudfront.net/assets/images/brand-assets/sign-up/step.1/layer.2/mobile.png?v=${ASSET_VERSION_NUMBER}`,
                desktopSize: '257px 96px',
                mobileSize: '200px 81px',
                visibilityMode: 'all',
              },
              // Bottom Right Corner Image
              {
                position: 'right bottom',
                repeat: 'no-repeat',
                desktopUrl: `https://d2ettyk3s9h19.cloudfront.net/assets/images/brand-assets/sign-up/step.1/layer.1/desktop&tablet.png?v=${ASSET_VERSION_NUMBER}`,
                visibilityMode: 'desktop and tablet',
                desktopSize: 'contain',
              },
              // Background Full Image
              {
                position: 'center',
                desktopVerticalOffset: 58.4,
                repeat: 'repeat',
                desktopUrl: `https://d2ettyk3s9h19.cloudfront.net/assets/images/brand-assets/sign-up/step.1/layer.3/desktop&tablet.png?v=${ASSET_VERSION_NUMBER}`,
                mobileUrl: `https://d2ettyk3s9h19.cloudfront.net/assets/images/brand-assets/sign-up/step.1/layer.3/mobile.png?v=${ASSET_VERSION_NUMBER}`,
                desktopSize: 'cover',
                visibilityMode: 'all',
              },
            ],
            positionedComponent: { component: <span className={styles.imageLabel}>Welcome back!</span>, bottom: 42, left: 18 },
          }}
        />
        <form onSubmit={submitForm}>
          {props.mode === 'signin' && (
            <div className={styles.signinModalDetails}>
              <span className={styles.subHeading}>
                Every day we are working to make gides the best experience for you to create your beautiful content.
              </span>
              <span className={styles.signup}>
                <span style={{marginRight: '5px'}}>Don't have an account?</span> 
                {process.env.REACT_APP__USE_MAILCHIMP_SIGNUP === 'true' && (
                  <Link to="#" className={styles.linkText}
                    onClick={() => {
                      props.closeModal();
                      props.onSignUp();
                    }}
                  >
                    Request Access
                  </Link>
                )}
                {process.env.REACT_APP__USE_MAILCHIMP_SIGNUP !== 'true' && (
                  <Link to="/register" className={styles.linkText}
                    onClick={props.closeModal}
                  >
                    Request Access
                  </Link>
                )}
              </span>
              <div className={styles.usernamePassword}>
                <div className={styles.userInput}>
                  <RequiredTextboxWithLabel
                    type={'input'}
                    label={'Email'}
                    placeholder={'Enter email'}
                    onChange={(value: string | number | undefined) => {
                      setUsername(value as string);
                      // props.onChangeAuthField('email', value as string);
                    }}
                    autoFocus={true}
                  />
                </div>
                <div className={styles.userInput}>
                  <RequiredTextboxWithLabel
                    type={'password'}
                    label={'Password'}
                    placeholder={'Enter password'}
                    onChange={(value: string | number | undefined) => {
                      setPassword(value as string);
                      // props.onChangeAuthField('password', value as string);
                    }}
                  />
                </div>
              </div>
              {attemptedLogin && <span style={{ marginTop: '5px', color: 'red' }}>Invalid login in attempt.</span>}
              <div className={styles.signinButton}>
                <RoundedCornerButton
                  label="Confirm"
                  style={{
                    backgroundColor: 'var(--COLOR-PRIMARY-700)',
                    width: '100%',
                  }}
                  labelColor="white"
                  imagePosition="right"
                  onClick={() => {
                    attempSignin();
                  }}
                />
                <input type="submit" style={{ display: 'none' }} />
              </div>
            </div>
          )}
        </form>
      </div>
    </ResizeableModal>
  );
}
