import React, { useState } from 'react';
import styles from './_end-header-modal.module.scss';
import ResizeableModal from '../../modals/ResizeableModal/ResizeableModal';
import icons from '../../../assets/icons';
import { RoundedCornerButton } from '../../Shared/RoundedCornerButton/RoundedCornerButton';
import { contains, reject, append, isNil, reverse } from 'ramda';
import { isNullOrUndefined } from 'util';
import { AppState } from '../../../reducer';
import { useSelector, useDispatch } from 'react-redux';
import { Article } from '../../../models/Article';
import agent from '../../../agent';
import { SlideUpdateInfo } from '../../../models/Slide';
import { ADD_MULTIPLE_SLIDES } from '../../../constants/actionTypes';
import { MultiSlideInsertParameters } from '../../../models/MultiSlideInsertParameters';

export interface EndHeaderModalProps {
  closeModal: () => void;
  headerSlidesThatCanEnd: {[key: string]: string};
  submitSlide: (payload: SlideUpdateInfo) => void;
}
interface StoreProps {
  currentSlidePosition: number;
  article: Article | null;
  numberOfSlides: number;
}
interface Actions {
  onSubmitMultipleSlides: (payload: MultiSlideInsertParameters)  => void;
}
const headerToolbarContent = (
  <div className={styles.headerHeaderContent}>
    <span className={styles.headerContentTitle}>End your headers</span>
    <icons.ContentAlteration_RichText_Header_End color="var(--COLOR-SECONDARY-400)" />
  </div>
);

export default function EndHeaderModal (props:  EndHeaderModalProps & StoreProps) {
  const [endedHeaders, setEndedHeaders] = useState<string[]>([]);
  const storeProps: StoreProps = useSelector<AppState, StoreProps> (
    (state: AppState): StoreProps => {
      return {
        currentSlidePosition: !isNil(state.common.slideNumber) ? state.common.slideNumber : state.article.slides.length,
        article: state.article.article,
        numberOfSlides: state.article.slides.length,
      }
    }
  );
  const dispatch = useDispatch();
  const actions: Actions = {
    onSubmitMultipleSlides: (payload: MultiSlideInsertParameters) => dispatch({ type: ADD_MULTIPLE_SLIDES, payload }),
  }
  const updateEndedHeaders = (headerId: string): void => {
    if(isHeaderSetToEnd(headerId)) {
      setEndedHeaders(reject(h => h === headerId, endedHeaders));
    } else {
      setEndedHeaders(append(headerId, endedHeaders));
    }
  }
  const isHeaderSetToEnd = (headerId: string): boolean => {
    return contains(headerId, endedHeaders);
  }
  // This is really a hacky way to do this. Need to add a server side method that allows
  // the ending of multiple headers simultaneously. BJ 7/2020
  const createEndHeaderSlides = async () => {
    if (!isNullOrUndefined(storeProps.article)) {

      const multiSlideInsertParameters: MultiSlideInsertParameters = {
        insertedPosition: storeProps.currentSlidePosition,
        slidesToInsert: [],
        slideCount: 0
      }
      // The keys are orderded from the highest header to lowest (eg. H1, H2, H3, H4, H5)
      // but when you end headers, they need to be ended lowest to highest for the ones selected (eg. H5, H3)
      const slidesToEnd = reverse(Object.keys(props.headerSlidesThatCanEnd)
        .filter((k: string) => isHeaderSetToEnd(props.headerSlidesThatCanEnd[k])));        

      
      for(let i = 0; i < slidesToEnd.length; i++) {
        const slide = {
          position: storeProps.currentSlidePosition + i,
          slideType: 'HEADER',
          data: {
            type: 'END',
            beginSlideId: props.headerSlidesThatCanEnd[slidesToEnd[i]],
            position: storeProps.currentSlidePosition + i,
          }
        };
        const response = await agent.Slides.create(storeProps.article, slide);
        multiSlideInsertParameters.slidesToInsert.push((response as any).slide);
        multiSlideInsertParameters.slideCount = multiSlideInsertParameters.slideCount + 1;
      }
      actions.onSubmitMultipleSlides(multiSlideInsertParameters);
      props.closeModal();
    }
  };
  return (
    <ResizeableModal
      canResize={false}
      closeModal={() => props.closeModal()}
      closeOnDimmer={true}
      mode="free"
      width={360}
      hideCommandBar={false}
      rightToolbarContent={headerToolbarContent}
      navigationState="closeModal"
    >
      <div className={styles.endHeaderModalContainer}>
        <div className={styles.endHeaderTitle}>Ending your Headers.</div>
        <div className={styles.endHeaderSubTitle}>
          Resetting a header allows you to return to your previous header but just under your current content.
        </div>
        {Object.keys(props.headerSlidesThatCanEnd).map((hs, index) => (
          <div className={styles.headerSlide} key={index}>
            <div>{`Header ${index + 1}`}</div>
            <div className={styles.endButton}
              onClick={() => {
                updateEndedHeaders(props.headerSlidesThatCanEnd[hs]);
              }}
            >
              {isHeaderSetToEnd(props.headerSlidesThatCanEnd[hs]) ? 'undo' : 'end'}
            </div>
          </div>
        ))}
        <div className={styles.finishContainer}>
          <RoundedCornerButton 
            label="Done"
            style={{backgroundColor: 'var(--COLOR-PRIMARY-300)'}}
            onClick={() => createEndHeaderSlides()}
          />
        </div>
      </div>
    </ResizeableModal>
  );
}
