import { ImageGalleryFile } from './../components/SlideEditors/ImageGallerySlideEditor/ImageGallerySlideEditor';
import { COMPLETE_SLIDE_FILE_PROCESSING, COMPLETE_TITLE_SLIDE_PROCESSING, COMPLETE_GALLERY_SLIDE_FILE_PROCESSING } from './FileProcessing/fileProcessing.actions';
import { SlideFile, MediaFile } from './../models/SlideFile';
import { Article } from './../models/Article';
import {
  ARTICLE_PAGE_LOADED,
  ARTICLE_PAGE_ARTICLE_LOADED,
  ARTICLE_PAGE_SLIDES_LOADED,
  ARTICLE_IMAGE_URL_UPDATED,
  ARTICLE_DELETE_SLIDES,
  ARTICLE_COPY_SLIDES,
  ARTICLE_MOVE_SLIDES,
  ARTICLE_MOVE_SLIDES_OUT_OF_GIDE,
  SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES,
  ARTICLE_COPY_SLIDES_FROM_CHILD_TO_PARENT,
  ADD_SLIDE,
  ADD_MULTIPLE_SLIDES,
  REPLACE_SLIDE,
  DELETE_SLIDE,
  COLLAPSE_SLIDES,
  EXPAND_SLIDE,
  COLLAPSE_ALL_SLIDES,
  EXPAND_ALL_SLIDES,
  ATTENDANCES_LOADED,
  ARTICLE_SUBSCRIBED,
  ARTICLE_UNSUBSCRIBED,
  ARTICLE_FAVORITED,
  ARTICLE_UNFAVORITED,
  ARTICLE_DISPLAY_OWNER_PANEL,
  AUTHORIZE_SLIDE,
  SCROLL_TO_SLIDE,
  SET_ARTICLE_PERCENT_COMPLETE,
  SLIDE_PAGE_UNLOADED,
  TOGGLE_MULTI_SLIDE_SELECTION_MODE,
  ADD_SLIDES_TO_SELECTION,
  REMOVE_SLIDES_FROM_SELECTION,
  SET_SELECTED_SLIDES,
  TOGGLE_COLUMNS,
  TOGGLE_ARTICLE_LAYOUT_VIEW,
  ENTER_ADVANCED_EDIT_MODE_FOR_SLIDE,
  ENTER_SLIDE_SELECTION_MODE,
  EXIT_SLIDE_SELECTION_MODE,
  TOGGLE_BLAME,
  UPDATE_TEMPLATE_SLIDES,
  ADD_INQUIRY_SLIDE,
  AUTHORIZE_BLOCK_SLIDE,
  UNAUTHENTICATED_AUTHORIZE_BLOCK_SLIDE,
  ARTICLE_UPDATED,
  CONVERT_MULTI_TEXT_SLIDES_TO_SINGLE_SLIDE,
  SCROLL_TO_SLIDE_ID,
  SET_SLIDE_APPROVAL,
  EXPIRE_ARTICLE,
  UPDATE_SLIDE_CHILD_GIDES,
  ARTICLE_FILTER_SLIDES,
  ARTICLE_PAGE_UNLOADED,
  ENABLE_SLIDE_COMMENTS,
  DISABLE_SLIDE_COMMENTS,
  ENABLE_SLIDE_QUESTIONS,
  DISABLE_SLIDE_QUESTIONS,
  UPDATE_SLIDE_SELECTION_METHOD,
  UPDATE_SLIDE_SELECTION_OPERATION_MODE,
  UPDATE_SLIDE_CHILD_GIDE_FOR_GIDE_TYPE,
  COLLAPSE_ALL_CHILD_GIDES_BY_GIDE_TYPE,
  FOLLOW_USER,
  UNFOLLOW_USER,
  SLIDE_ZERO_SUBMITTED,
  TOGGLE_NAVIGIDE,
  UNAUTHENTICATED_AUTHORIZE_SLIDE,
  ARTICLE_SUBMITTED,
} from '../constants/actionTypes';
import merge from 'lodash/merge';
import { isNullOrUndefined } from 'util';
import { reject, contains, sort, append, any } from 'ramda';
import { ArticleLayoutView } from '../models/ArticleLayoutEnum';
import { Slide, SlideUpdateInfo } from '../models/Slide';
import { v4 } from 'uuid';
import { SlideFilterState } from '../components/Gide/SlideFilter/SlideFilter';
import { combineReducers, Reducer } from 'redux';
import { NullAuthor } from '../models/Author';
import { SlideSelectionInfo, SlideSelectionOperation } from '../models/SlideSelectionInfo';

export interface SlideChildArticleViewDictionary {
  [slideId: string]: { articleTypes: string[] };
}

export interface ExpandCollapseSlideAdditionInfo {
  gideType: string,
  action: string,
}

export interface SlideInlineViewSlidesDictionary {
  [slideId: string]: { inlineViewSlides: InlineViewSlides[] };
}

export interface InlineViewSlides {
  articleType: string;
  isExpanded?: boolean;
  slides: Slide[];
}

export interface MultiSlideSelectionDetail {
  showMultiSelectSlideView: boolean;
  selectedSlideIds: string[];
  mode?: string;
}
export interface ArticleState {
  article: Article | null;
  renderColumns: boolean;
  slides: Slide[];
  slideErrors: string | null;
  showBlame: boolean;
  individualSlideIdInAdvancedEditMode: string | null;
  slideSelectionModeDetail: SlideSelectionInfo | null;
  preventScrollToSlide: boolean;
  scrollToPosition: number | null;
  authorizedBlockSlideIds: string[];
  multiSlideSelectionDetail: MultiSlideSelectionDetail;
  collapsedSlides: Slide[];
  scrollToSlideId: string | null;
  slideInlineViewSlidesDictionary: SlideInlineViewSlidesDictionary;
  slideFilter: SlideFilterState | null;
  attendances: string | null;
  percentArticleCompleted: number;
  displayGideOwnerPanel: boolean;
  displayingNavigide: boolean;
}

/**
 * Interface defining the properties and initial values of ArticleReducerState.
 */
export const INITIAL_STATE: ArticleState = {
  article: null,
  renderColumns: false,
  slides: [],
  slideErrors: null,
  showBlame: false,
  individualSlideIdInAdvancedEditMode: null,
  slideSelectionModeDetail: null,
  preventScrollToSlide: false,
  scrollToPosition: null,
  authorizedBlockSlideIds: [],
  collapsedSlides: [],
  multiSlideSelectionDetail: {
    showMultiSelectSlideView: false,
    selectedSlideIds: [],
    mode: undefined,
  },
  scrollToSlideId: null,
  slideInlineViewSlidesDictionary: {},
  slideFilter: null,
  attendances: null,
  percentArticleCompleted: 0,
  displayGideOwnerPanel: false,
  displayingNavigide: false,
};

/**
 * Returns the updated list of slides for an Add operation and handles resorting the slides.
 * @param {Slide[]} activeSlides - system's active slides
 * @param {addSlideDetail} - comes directly from action.payload
 */
const addSlideToArticle = (activeSlides: Slide[], addSlideDetail: SlideUpdateInfo): Slide[] => {
  const idx = activeSlides.findIndex(s => s.id === addSlideDetail.slide.id);
  const previousPosition = idx > -1 ? activeSlides[idx].position : -1;
  let slides: Slide[] = [];
  let oldSlides: Slide[] = [];

  // We don't want to update the order of the slides if this slide is an additional
  // slide since it does not affect the position of any other slide in the article.
  if (isNullOrUndefined(addSlideDetail.mode)) {
    if (previousPosition === -1) {
      oldSlides = activeSlides.map((s: Slide) => {
        if (!isNullOrUndefined(s.position) && s.position >= addSlideDetail.slide.position) {
          s.position += 1;
        }
        return s;
      });
    } else if (previousPosition > addSlideDetail.slide.position) {
      oldSlides = activeSlides.map(s => {
        if (s.position >= addSlideDetail.slide.position && s.position < previousPosition) {
          s.position += 1;
        }
        return s;
      });
    } else if (previousPosition < addSlideDetail.slide.position) {
      oldSlides = activeSlides.map(s => {
        if (s.position <= addSlideDetail.slide.position && s.position > previousPosition) {
          s.position -= 1;
        }
        return s;
      });
    }
  }
  if (idx !== -1) {
    slides = [...activeSlides];
    slides[idx] = addSlideDetail.slide;
  } else {
    if (addSlideDetail.slide.slide) {
      const idxParent = activeSlides.findIndex(s => s.id === addSlideDetail.slide.slide);
      const parent: Slide = activeSlides[idxParent];
      // TODO: I Need to refactor this. State is being mutated here.
      if (parent.slideType === 'INPUT') {
        if (parent.data.entryType === 'template' && parent.inputSlides) {
          parent.inputSlides = parent.inputSlides.concat([addSlideDetail.slide]);
        }
        if (parent.data.entryType === 'addition' && parent.additionSlides) {
          parent.additionSlides = [...parent.additionSlides, addSlideDetail.slide];
        }
        if (parent.data.entryType === 'feedback' || parent.data.entryType === 'inline') {
          parent.feedbackSlides = merge([], parent.feedbackSlides);
          parent.feedbackSlides = parent.feedbackSlides.concat([addSlideDetail.slide]);
        }
      }
      if (addSlideDetail.mode === 'MAGNASLIDE') {
        parent.magnaSlides = merge([], parent.magnaSlides);
        parent.magnaSlides = parent.magnaSlides.concat([addSlideDetail.slide]);
      } else if (addSlideDetail.mode === 'NOTE') {
        parent.additionSlides = merge([], parent.additionSlides);
        parent.additionSlides = parent.additionSlides.concat([addSlideDetail.slide]);
        if (parent.additionSlidesByAuthor && addSlideDetail.slide && parent.additionSlidesByAuthor[addSlideDetail.slide.author.id]) {
          parent.additionSlidesByAuthor[addSlideDetail.slide.author.id].count += 1;
        } else if (parent.additionSlidesByAuthor) {
          parent.additionSlidesByAuthor[addSlideDetail.slide.author.id] = {
            author: addSlideDetail.slide.author,
            count: 1,
          };
        }
        if (parent.additionSlidesCount) {
          parent.additionSlidesCount += 1;
        } else {
          parent.additionSlidesCount = 1;
        }
      } else if (addSlideDetail.mode === 'NOTE_PRIVATE') {
        parent.noteSlides = merge([], parent.noteSlides);
        parent.noteSlides = parent.noteSlides.concat([addSlideDetail.slide]);
      }
      slides = merge([], activeSlides);
      slides[idxParent] = parent;
    } else {
      slides = (oldSlides || []).concat([addSlideDetail.slide]);
    }
  }
  return sort((a, b) => {
    return a.position - b.position;
  }, slides);
};

const articleReducer = (state: Article | null = INITIAL_STATE.article, action: any): Article | null => {
  switch (action.type) {
    case ARTICLE_SUBSCRIBED:
    case ARTICLE_UNSUBSCRIBED:
    case ARTICLE_FAVORITED:
    case ARTICLE_UNFAVORITED:
    case ARTICLE_PAGE_ARTICLE_LOADED:
      return action.payload.article;
    case ARTICLE_PAGE_LOADED:
      return action.payload && action.payload.length > 0 ? action.payload[0].article : state;
    case ENABLE_SLIDE_QUESTIONS:
      return state
        ? {
          ...state,
          allowSlideQuestions: true,
        }
        : state;
    case DISABLE_SLIDE_QUESTIONS:
      return state
        ? {
          ...state,
          allowSlideQuestions: false,
        }
        : state;
    case ENABLE_SLIDE_COMMENTS:
      return state
        ? {
          ...state,
          allowSlideComments: true,
        }
        : state;
    case DISABLE_SLIDE_COMMENTS:
      return state
        ? {
          ...state,
          allowSlideComments: false,
        }
        : state;
    case SLIDE_ZERO_SUBMITTED:
      return state ? {
        ...state,
        slideZero: action.payload.slideZero,
        title: action.payload.slideZero.data.title,
        image: action.payload.slideZero.data.image,
        tagList: action.payload.tagList,
        description: action.payload.description,
        descriptionSlide: action.payload.descriptionSlide,
      } : state;
    case FOLLOW_USER:
      return state
        ? {
          ...state,
          author: {
            ...state.author,
            following: true
          },
        } : state;
    case UNFOLLOW_USER:
        return state
          ? {
            ...state,
            author: {
              ...state.author,
              following: false
            },
          } : state;
    case ARTICLE_IMAGE_URL_UPDATED:
      return state ? { ...state, image: action.payload.url } : state;
    case ARTICLE_UPDATED:
      return action.payload.article;
    case ARTICLE_PAGE_UNLOADED:
      return INITIAL_STATE.article;
    // TODO: Need to find out which fields need to be updated when this happens. Previously
    // this did not happen on the gide screen and when the gide was updated the user was
    // navigated back to the article page where it reloaded.
    case ARTICLE_SUBMITTED:
      return state
      ? {
        ...state,
        language: action.payload.article?.language,
      }
      : state;
    case COMPLETE_TITLE_SLIDE_PROCESSING:
      return state && state.slideZero && state.id === action.payload.titleSlideProcessorInfo.gideId
        ? { ...state,
            image: action.payload.fileUrl,
            slideZero: {
              ...state.slideZero,
              data: {
                ...state.slideZero.data,
                image: action.payload.fileUrl,
                processingId: undefined,
              }
            }
          }
        : state;
    default:
      return state;
  }
};

const attendanceReducer = (state: string | null = INITIAL_STATE.attendances, action: any): string | null => {
  switch (action.type) {
    case ATTENDANCES_LOADED:
      return action.payload.attendances;
    default:
      return state;
  }
};

const authorizedBlockSlideIdsReducer = (state: string[] = INITIAL_STATE.authorizedBlockSlideIds, action: any): string[] => {
  switch (action.type) {
    case ARTICLE_PAGE_LOADED:
    case ARTICLE_PAGE_ARTICLE_LOADED:
      return INITIAL_STATE.authorizedBlockSlideIds;
    case AUTHORIZE_BLOCK_SLIDE:
    case UNAUTHENTICATED_AUTHORIZE_BLOCK_SLIDE: {
      return append(action.payload.slideId, state);
    }
    default:
      return state;
  }
};

const collapsedSlidesReducer = (state: Slide[] = INITIAL_STATE.collapsedSlides, action: any): Slide[] => {
  switch (action.type) {
    case ARTICLE_PAGE_SLIDES_LOADED:
      return action.payload.filter((s: Slide) => s.slideType === 'HEADER' && !s.data.notCollapsible && s.data.defaultCollapsed);
    case EXPAND_ALL_SLIDES:
      return INITIAL_STATE.collapsedSlides;
    case COLLAPSE_ALL_SLIDES:
      return action.slides.filter(
        (s: Slide) =>
          (s.slideType === 'HEADER' && s.data.type !== 'END' && s.data.level > 0) ||
          (s.slideType === 'COLLAPSE' && s.data.type === 'BEGIN'),
      );
    case EXPAND_SLIDE:
      // if the slide is in a collapsed header,
      // then expand the collapse header and any parent that is collapsed
      if (action.payload.parentHeaderSlideIds) {
        return reject(s => contains(s.id, action.payload.parentHeaderSlideIds) || s.id === action.payload.slideId, state);
      } else {
        return reject(s => s.id === action.payload.slideId, state);
      }
    case COLLAPSE_SLIDES:
      // TODO: Verify but it looks like this is a toggle. I refactored it but need to make sure
      return state.findIndex(s => s.id === action.slide.id) === -1
        ? append(action.slide, state)
        : reject(s => s.id === action.slide.id, state);

    // REFACTOR TODO: Need to move this logic into the call to the action and pass the
    // ids of the slides to uncollaps
    case SCROLL_TO_SLIDE_ID: {
      // if the slide is in a collapsed header,
      // then expand the collapse header and any parent that is collapsed
      if (action.payload.parentHeaderSlides && state) {
        const parentHeaderIds = action.payload.parentHeaderSlides.map((ph: Slide) => {
          return ph.id;
        });
        return reject(s => contains(s.id, parentHeaderIds), state);
      } else {
        return state;
      }
    }

    default:
      return state;
  }
};

const individualSlideInAdvancedEditModeReducer = (
  state: string | null = INITIAL_STATE.individualSlideIdInAdvancedEditMode,
  action: any,
): string | null => {
  switch (action.type) {
    case ENTER_ADVANCED_EDIT_MODE_FOR_SLIDE:
      return action.slideId;
    default:
      return state;
  }
};

const multiSlideSelectionDetailReducer = (
  state: MultiSlideSelectionDetail = INITIAL_STATE.multiSlideSelectionDetail,
  action: any,
): MultiSlideSelectionDetail => {
  switch (action.type) {
    case ADD_SLIDES_TO_SELECTION:
      const slideIdsNotSelectedAlready = action.payload.slideIds.filter((sId: string) => !contains(sId, state.selectedSlideIds));
      return {
        ...state,
        selectedSlideIds: [
          ...state.selectedSlideIds,
          ...slideIdsNotSelectedAlready,
        ],
      };

    case SET_SELECTED_SLIDES:
      return {
        ...state,
        selectedSlideIds: action.payload.slideIds,
      };

    case TOGGLE_MULTI_SLIDE_SELECTION_MODE:
      return {
        showMultiSelectSlideView: action.payload.showMultiSelectSlideView,
        selectedSlideIds: [],
        mode: action.payload.mode,
      };
    case REMOVE_SLIDES_FROM_SELECTION:
      return {
        ...state,
        selectedSlideIds: reject(sId => contains(sId, action.payload.slideIds), state.selectedSlideIds),
      };
    case ARTICLE_COPY_SLIDES:
    case ARTICLE_DELETE_SLIDES:
    case ARTICLE_MOVE_SLIDES:
    case CONVERT_MULTI_TEXT_SLIDES_TO_SINGLE_SLIDE:
    case ARTICLE_MOVE_SLIDES_OUT_OF_GIDE:
    case ARTICLE_COPY_SLIDES_FROM_CHILD_TO_PARENT:
    case SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES:
    case EXIT_SLIDE_SELECTION_MODE:
      return INITIAL_STATE.multiSlideSelectionDetail;
    default:
      return state;
  }
};

const percentArticleCompletedReducer = (state: number = INITIAL_STATE.percentArticleCompleted, action: any): number => {
  switch (action.type) {
    case SET_ARTICLE_PERCENT_COMPLETE:
      return action.payload.percentCompleted;
    case SLIDE_PAGE_UNLOADED:
      return INITIAL_STATE.percentArticleCompleted;
    default:
      return state;
  }
};

const preventScrollToSlideReducer = (state: boolean = INITIAL_STATE.preventScrollToSlide, action: any): boolean => {
  switch (action.type) {
    case ADD_SLIDE:
      return action.payload.preventScrollToSlide === true;
    default:
      return state;
  }
};

const renderColumnsReducer = (state: boolean = INITIAL_STATE.renderColumns, action: any): boolean => {
  switch (action.type) {
    case TOGGLE_ARTICLE_LAYOUT_VIEW:
      return action.payload.articleLayoutView === ArticleLayoutView.WideScreen;
    case TOGGLE_COLUMNS:
      return !state;
    default:
      return INITIAL_STATE.renderColumns;
  }
};

const scrollToPositionReducer = (state: number | null = INITIAL_STATE.scrollToPosition, action: any): number | null => {
  switch (action.type) {
    case REPLACE_SLIDE:
      return action.payload.slide.position + 1;
    case ADD_MULTIPLE_SLIDES:
      return action.payload.insertedPosition + action.payload.slidesCount;
    case SCROLL_TO_SLIDE:
      return action.payload.position;
    case ADD_SLIDE:
      return action.payload.preventScrollToSlide ? state : action.payload.slide.position + 1;
    default:
      return state;
  }
};
const displayGideOwnerPanelReducer = (state: boolean = INITIAL_STATE.displayGideOwnerPanel, action: any): boolean => {
  switch (action.type) {
    case ARTICLE_DISPLAY_OWNER_PANEL:
      return action.payload.displayGideOwnerPanel;
    case ARTICLE_PAGE_ARTICLE_LOADED:
      return INITIAL_STATE.displayGideOwnerPanel
    default:
      return state;
  }
}
const scrollToSlideIdReducer = (state: string | null = INITIAL_STATE.scrollToSlideId, action: any): string | null => {
  switch (action.type) {
    case REPLACE_SLIDE:
      return action.payload.slide.id;
    case ADD_MULTIPLE_SLIDES:
      return action.payload.slideCount > 0 ? action.payload.slidesToInsert[action.payload.slideCount - 1] : state;
    case SCROLL_TO_SLIDE_ID:
    case UPDATE_SLIDE_CHILD_GIDES:
      return action.payload.slideId;
    case ADD_SLIDE:
      return action.payload.preventScrollToSlide ? state : action.payload.slide.id;
    default:
      return state;
  }
};

const displayingNavigideReducer = (state: boolean = INITIAL_STATE.displayingNavigide, action: any): boolean => {
  switch (action.type) {
    case TOGGLE_NAVIGIDE:
      return action.displayingNavigide;
    default:
      return state;
  }
};

const showBlameReducer = (state: boolean = INITIAL_STATE.showBlame, action: any): boolean => {
  switch (action.type) {
    case TOGGLE_BLAME:
      return !state;
    default:
      return INITIAL_STATE.showBlame;
  }
};

const slidesReducer = (state: Slide[] = INITIAL_STATE.slides, action: any): Slide[] => {
  // Before doing anything, need to revoke any ObjectUrls created while processing slide file images.
  state.forEach(s => {
    if(s.data && s.data.files) {
      s.data.files.forEach((f: SlideFile) => {
        if((f as MediaFile).processingId && (f as MediaFile).url) {
          URL.revokeObjectURL((f as MediaFile).url as string);
        }
      });
    }
  });
  switch (action.type) {
    case ARTICLE_PAGE_LOADED:
      return action.payload && action.payload.length > 1 ? action.payload[1].slides : state;
    case ARTICLE_PAGE_SLIDES_LOADED:
      return action.payload;
    case ADD_SLIDE:
      return action.error ? [] : addSlideToArticle(state, action.payload);
    case ADD_MULTIPLE_SLIDES:
      return [...state, ...action.payload.slidesToInsert];
    case UPDATE_TEMPLATE_SLIDES:
      return state.map(s => {
        return {
          ...s,
          data: {
            ...s.data,
          },
          isTemplate: s.slideType !== 'COLUMNS' ? action.payload.isTemplate : false,
        };
      });
    case SET_SLIDE_APPROVAL:
      return state.map(s => {
        if (s.id === action.payload.ownerSlide.id) {
          return {
            ...s,
            childArticlesSlideDetails: action.payload.ownerSlide.childArticlesSlideDetails,
            data: {
              ...s.data,
            },
          };
        }
        return s;
      });
    case REPLACE_SLIDE: {
      const slideList = state.filter(slide => slide.id !== action.payload.slideIdToRemove).concat(action.payload.slide);
      return slideList.sort(function (a, b) {
        return a.position - b.position;
      });
    }
    case ARTICLE_DELETE_SLIDES:
      return reject(s => contains(s.id, action.payload.slideIds), state).map((s, index) => {
        return {
          ...s,
          position: index,
        };
      });

    case ARTICLE_COPY_SLIDES: {
      const slidesPrecedingCopiedSlides = state.filter(
        (s: Slide) => !isNullOrUndefined(s.position) && s.position < action.payload.insertedPosition,
      );
      const slidesAfterCopiedSlides = state
        .filter((s: Slide) => !isNullOrUndefined(s.position) && s.position >= action.payload.insertedPosition)
        .map(s => {
          return {
            ...s,
            position: s.position + action.payload.slides.length,
          };
        });
      return [...slidesPrecedingCopiedSlides, ...action.payload.slides, ...slidesAfterCopiedSlides];
    }

    case ARTICLE_MOVE_SLIDES: {
      // slidesForEdit: slide[] - contains the slides that are from the article that
      // is currently in edit mode and initiated a multiple move operation. This moves
      // slides within the same article.
      const updatedSlides = sort(
        (s1, s2) => {
          return s1.position - s2.position;
        },
        // getSlidesForEdit(state).map(s => {
        state.map(s => {
          const slideInfo = action.payload.movedSlideInfoList.find((si: Slide) => si.id === s.id);
          if (slideInfo) {
            return {
              ...s,
              position: slideInfo.position,
            };
          } else {
            return s;
          }
        }),
      );
      // creates the new state for the list of slides that will populate property article.slides
      // if the primary article is not the one being edited then just return the same slides
      // else return the updated slides.
      // return getNewArticleStateForSlideUpdates(state, updatedSlides);
      return updatedSlides;
    }
    case CONVERT_MULTI_TEXT_SLIDES_TO_SINGLE_SLIDE: {
      const slides = append(action.payload.combinationSlide, reject(s => contains(s.id, action.payload.slideIds), state));
      return sort(
        (s1, s2) => {
          return s1.position - s2.position;
        },
        slides.map(s => {
          const slideInfo = action.payload.movedSlideInfoList.find((si: Slide) => si.id === s.id);
          if (slideInfo) {
            return {
              ...s,
              position: slideInfo.position,
            };
          } else {
            return s;
          }
        }),
      );
    }
    case ARTICLE_MOVE_SLIDES_OUT_OF_GIDE: {
      return reject((s: Slide) => contains(s.id, action.payload.slideIds), state).map((s: Slide, index: number) => {
        return {
          ...s,
          position: index,
        };
      });
    }
    case ARTICLE_COPY_SLIDES_FROM_CHILD_TO_PARENT: {
      return [...state, ...action.payload.slides];
    }
    case SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES: {
      return state.map(s => {
        if (s.id === action.payload.slideId) {
          return {
            ...s,
            childArticlesSlideTypes: action.payload.childArticlesSlideTypes,
            childArticlesSlideDetails: action.payload.childArticlesSlideDetails,
          };
        } else {
          return s;
        }
      });
    }
    case DELETE_SLIDE: {
      const slideId = action.payload.slideId;
      // need to update the slide positions
      const deletedSlide: Slide | undefined = state.find(s => s.id === slideId);
      return reject(
        s => s.id === slideId,
        state.map((s: Slide) => {
          return {
            ...s,
            position:
              deletedSlide &&
                !isNullOrUndefined(deletedSlide.position) &&
                !isNullOrUndefined(s.position) &&
                deletedSlide.position < s.position
                ? s.position - 1
                : s.position,
          };
        }),
      );
    }
    case AUTHORIZE_SLIDE:
    case UNAUTHENTICATED_AUTHORIZE_SLIDE: {
      const slideId = action.payload.slideId;
      const slides = action.payload.authorized === true ? [...state, ...action.payload.slides] : state;

      slides.sort(function (a, b) {
        return a.position - b.position;
      });
      return slides.map(slide => {
        if (slide.id === slideId) {
          return {
            ...slide,
            data: {
              ...slide.data,
              maxAttemptsReached: slide.data.maxAttempts ? action.payload.numberOfAttempts >= slide.data.maxAttempts : false,
              authorized: action.payload.authorized,
              message:
                action.payload.authorized === true
                  ? undefined
                  : slide.data.passcodeType === 'TEXT'
                    ? slide.data.maxAttempts
                      ? `Incorrect password (${slide.data.maxAttempts - action.payload.numberOfAttempts} attempts remaining)`
                      : 'Incorrect password'
                    : slide.data.passcodeType === 'GPS' ? 'Access denied. Please move and try again.' : 'Invalid Date',
            },
          };
        } else {
          return slide;
        }
      });
    }
    case EXPIRE_ARTICLE: {
      return [
        {
          id: v4(),
          slideType: 'HIDE',
          author: NullAuthor,
          data: {
            entityType: 'ARTICLE',
          },
          position: 0,
        },
      ];
    }
    case ADD_INQUIRY_SLIDE: {
      return state.map(s => {
        if (s.id === action.payload.ownerSlide.id) {
          return {
            ...s,
            childArticlesSlideDetails: action.payload.ownerSlide.childArticlesSlideDetails,
          };
        } else {
          return s;
        }
      });
    }
    case ENABLE_SLIDE_COMMENTS: {
      if (action.payload.updateAllSlides) {
        return state.map(s => {
          return {
            ...s,
            data: {
              ...s.data,
            },
            allowComments: true,
          };
        });
      } else {
        return state;
      }
    }
    case DISABLE_SLIDE_COMMENTS: {
      if (action.payload.updateAllSlides) {
        return state.map(s => {
          return {
            ...s,
            data: {
              ...s.data,
            },
            allowComments: false,
          };
        });
      } else {
        return state;
      }
    }
    case ENABLE_SLIDE_QUESTIONS: {
      if (action.payload.updateAllSlides) {
        return state.map(s => {
          return {
            ...s,
            data: {
              ...s.data,
            },
            allowQuestions: true,
          };
        });
      } else {
        return state;
      }
    }
    case DISABLE_SLIDE_QUESTIONS: {
      if (action.payload.updateAllSlides) {
        return state.map(s => {
          return {
            ...s,
            data: {
              ...s.data,
            },
            allowQuestions: false,
          };
        });
      } else {
        return state;
      }
    }
    case COMPLETE_SLIDE_FILE_PROCESSING: {
      return state.map(s => {
        if(s.id === action.payload.slideFileProcessorInfo.slideId) {
          return {
            ...s,
            data: {
              ...s.data,
              files: s.data.files.map((f: SlideFile) => {
                if(f.id === action.payload.slideFileProcessorInfo.slideFileId) {
                  return {
                    ...f,
                    url: action.payload.fileUrl,
                    processingId: undefined,
                  };
                } else {
                  return f;
                }
              })
            }
          }
        } else {
          return s;
        }
      });
    }
    case COMPLETE_GALLERY_SLIDE_FILE_PROCESSING: {
      return state.map(s => {
        if(s.id === action.payload.gallerySlideFileProcessingInfo.slideId) {
          return {
            ...s,
            data: {
              ...s.data,
              files: s.data.files.map((f: SlideFile) => {
                if(f.id === action.payload.gallerySlideFileProcessingInfo.slideFileId) {
                  return {
                    ...f,
                    imageFiles: (f as ImageGalleryFile).imageFiles.map(imgFile => {
                      if(imgFile.id === action.payload.gallerySlideFileProcessingInfo.galleryFileId) {
                        return {
                          ...imgFile,
                          url: action.payload.fileUrl,
                          processingId: undefined,
                        }
                      } else {
                        return imgFile;
                      }
                    })
                  };
                } else {
                  return f;
                }
              })
            }
          }
        } else {
          return s;
        }
      });
    }
    default:
      return state;
  }
};

// REFACTOR TODO: Check an older version of the code to make sure this is correctly
// capturing slide errors
const slideErrorsReducer = (state: string | null = INITIAL_STATE.slideErrors, action: any): string | null => {
  switch (action.type) {
    case ADD_SLIDE:
      return action.error ? action.payload.errors : null;
    default:
      return state;
  }
};

const slideFilterReducer = (state: SlideFilterState | null = INITIAL_STATE.slideFilter, action: any): SlideFilterState | null => {
  switch (action.type) {
    case ARTICLE_FILTER_SLIDES:
      return action.payload.slideFilter;
    default:
      return state;
  }
};

const slideInlineViewSlidesDictionaryReducer = (state: SlideInlineViewSlidesDictionary = INITIAL_STATE.slideInlineViewSlidesDictionary, action: any): SlideInlineViewSlidesDictionary => {
  switch (action.type) {
    case COLLAPSE_ALL_CHILD_GIDES_BY_GIDE_TYPE: {
      const updatedSlideInlineViewSlidesDictionary: SlideInlineViewSlidesDictionary = { ...state };
      Object.keys(updatedSlideInlineViewSlidesDictionary).forEach(key => {
        updatedSlideInlineViewSlidesDictionary[key].inlineViewSlides.forEach((lvs: InlineViewSlides) => {
          if (lvs.articleType === action.payload.gideType) {
            updatedSlideInlineViewSlidesDictionary[key].inlineViewSlides = reject(ivsi => ivsi.articleType === lvs.articleType, updatedSlideInlineViewSlidesDictionary[key].inlineViewSlides);
          }
        });
      });
      return updatedSlideInlineViewSlidesDictionary;
    }
    case UPDATE_SLIDE_CHILD_GIDE_FOR_GIDE_TYPE: {
      const copy = { ...state };
      if (state[action.payload.slideId]) {
        // Here we know that the dictionary has an entry for the slide id but still need to determine if the dictionary has an entry
        // with the slideId for the given articleType.
        const inlineSlideInfoExistsForArticleType = any(inlineSlideInfo => inlineSlideInfo.articleType === action.payload.articleType, state[action.payload.slideId].inlineViewSlides);
        if (inlineSlideInfoExistsForArticleType) {
          const inlineViewSlides = state[action.payload.slideId].inlineViewSlides;
          // If the slide being toggled open exists in the dictionary and has an entry in the inlineSlideInfo list for the given article type
          copy[action.payload.slideId] = {
            ...state[action.payload.slideId], inlineViewSlides: inlineViewSlides.map(
              (s: InlineViewSlides) => {
                if (s.articleType === action.payload.articleType) {
                  const updatedSlides = reject(sl => contains(sl.id, action.payload.slides.map((ns: Slide) => ns.id)), s.slides);
                  const slides = [...updatedSlides, ...action.payload.slides];
                  return {
                    ...s,
                    slides: slides,
                  };
                } else {
                  return s;
                }
              },
            )
          };
        } else {
          // The slide has an entry in the dictionary but does not have an item that is for the given article Type
          copy[action.payload.slideId] = { ...state[action.payload.slideId], inlineViewSlides: append({ articleType: action.payload.articleType as string, slides: action.payload.slides as Slide[] }, state[action.payload.slideId].inlineViewSlides) };
        }
      } else {
        // The slide being toggled open does not exist in the dictionary so add an entry in the inlineSlideInfo list for the given article type
        copy[action.payload.slideId] = { inlineViewSlides: [{ articleType: action.payload.articleType as string, slides: action.payload.slides as Slide[] }] };
      }
      return copy;
    }
    case UPDATE_SLIDE_CHILD_GIDES: {
      const updatedSlideInlineViewSlidesDictionary: SlideInlineViewSlidesDictionary = { ...state };
      action.payload.inlineViewSlidesList.forEach((lvs: InlineViewSlides) => {
        updatedSlideInlineViewSlidesDictionary[action.payload.slideId].inlineViewSlides = reject(ivsi => ivsi.articleType === lvs.articleType, updatedSlideInlineViewSlidesDictionary[action.payload.slideId].inlineViewSlides);
        updatedSlideInlineViewSlidesDictionary[action.payload.slideId].inlineViewSlides = append(lvs, updatedSlideInlineViewSlidesDictionary[action.payload.slideId].inlineViewSlides);
      });
      return updatedSlideInlineViewSlidesDictionary;
    }
    default:
      return state;
  }
};

const slideSelectionModeDetailReducer = (state: SlideSelectionInfo | null = INITIAL_STATE.slideSelectionModeDetail, action: any): SlideSelectionInfo | null => {
  switch (action.type) {
    case ARTICLE_COPY_SLIDES:
    case ARTICLE_MOVE_SLIDES:
    case CONVERT_MULTI_TEXT_SLIDES_TO_SINGLE_SLIDE:
    case ARTICLE_MOVE_SLIDES_OUT_OF_GIDE:
    case EXIT_SLIDE_SELECTION_MODE:
      return INITIAL_STATE.slideSelectionModeDetail;
    case ADD_SLIDE:
      return state && (state.operation === SlideSelectionOperation.AddSlideBelow || state.operation === SlideSelectionOperation.AddSlideAbove)
        ?
          {
            ...state,
            originSlideId: action.payload.slide.id,
          }
        : INITIAL_STATE.slideSelectionModeDetail;
    case ENTER_SLIDE_SELECTION_MODE:
      return action.payload;
    case UPDATE_SLIDE_SELECTION_METHOD:
      return {
        ...state as SlideSelectionInfo,
        multiSlideSelectionMethod: action.payload.multiSlideSelectionMethod
      };
    case UPDATE_SLIDE_SELECTION_OPERATION_MODE:
      return {
        ...state as SlideSelectionInfo,
        transferType: action.payload.transferType
      };
    default:
      return state;
  }
};

export const article: Reducer<ArticleState> = combineReducers<ArticleState>({
  article: articleReducer,
  slides: slidesReducer,
  renderColumns: renderColumnsReducer,
  slideErrors: slideErrorsReducer,
  showBlame: showBlameReducer,
  slideInlineViewSlidesDictionary: slideInlineViewSlidesDictionaryReducer,
  individualSlideIdInAdvancedEditMode: individualSlideInAdvancedEditModeReducer,
  slideSelectionModeDetail: slideSelectionModeDetailReducer,
  preventScrollToSlide: preventScrollToSlideReducer,
  scrollToPosition: scrollToPositionReducer,
  authorizedBlockSlideIds: authorizedBlockSlideIdsReducer,
  multiSlideSelectionDetail: multiSlideSelectionDetailReducer,
  collapsedSlides: collapsedSlidesReducer,
  scrollToSlideId: scrollToSlideIdReducer,
  attendances: attendanceReducer,
  percentArticleCompleted: percentArticleCompletedReducer,
  slideFilter: slideFilterReducer,
  displayGideOwnerPanel: displayGideOwnerPanelReducer,
  displayingNavigide: displayingNavigideReducer,
});
