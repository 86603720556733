import * as React from 'react';
import { useSlate } from 'slate-react';
import { isMarkActive, toggleMark } from './Leaf';
import { queryCache, useQuery } from 'react-query';
/* Icons */
import { ReactComponent as BoldIcon } from './icons/bold.svg';
import { ReactComponent as ItalicIcon } from './icons/italic.svg';
import { ReactComponent as UnderlineIcon } from './icons/underline.svg';

export interface RichTextEditorInlineButtonsProps {
  key: string;
}
export const RichTextEditorInlineButtons: React.FC<RichTextEditorInlineButtonsProps> = props => {
  const richTextEditorInlineButtons = useRichTextEditorInlineButtons(props.key);

  return (
    <>
      <div style={{ cursor: 'pointer', marginLeft: 48 }}>
        <BoldIcon xlinkTitle="bold" 
          style={{
            border: richTextEditorInlineButtons?.bold ? 'black 1px solid' : undefined,
          }}
          onMouseDown={e => e.preventDefault()}
          onClick={richTextEditorInlineButtons?.toggleBold}
        />
      </div>
      <div style={{ cursor: 'pointer', marginLeft: 48}}>
        <ItalicIcon xlinkTitle="italic" 
          style={{
            border: richTextEditorInlineButtons?.italic ? 'black 1px solid' : undefined,
          }}
          onMouseDown={e => e.preventDefault()}
          onClick={richTextEditorInlineButtons?.toggleItalic}
        />
      </div>
      <div style={{ cursor: 'pointer', marginLeft: 48 }}>
        <UnderlineIcon xlinkTitle="underline" 
          style={{
            border: richTextEditorInlineButtons?.underline ? 'black 1px solid' : undefined,
          }}
          onMouseDown={e => e.preventDefault()}
          onClick={richTextEditorInlineButtons?.toggleUnderline}
        />
      </div>
    </>
  );
}

export interface RichTextEditorInlineButtonsSynchronizerProps {
  key: string;
}
export const RichTextEditorInlineButtonsSynchronizer: React.FC<RichTextEditorInlineButtonsSynchronizerProps> = props => {
  const editor = useSlate();
    
  queryCache.setQueryData(props.key || DefaultKey, {
    bold: isMarkActive(editor, 'bold'),
    italic: isMarkActive(editor, 'italic'),
    underline: isMarkActive(editor, 'underline'),
    toggleBold: () => toggleMark(editor, 'bold'),
    toggleItalic: () => toggleMark(editor, 'italic'),
    toggleUnderline: () => toggleMark(editor, 'underline'),
  });
  
  return null; // This component does not show up visually.
}

export const DefaultKey = 'GlobalRichTextEditorInlineButtonsStatus'

export interface RichTextEditorInlineButtonsStatus {
  bold: boolean,
  italic: boolean,
  underline: boolean,
  toggleBold: () => void,
  toggleItalic: () => void,
  toggleUnderline: () => void,
};

const useRichTextEditorInlineButtons = (key?: string): RichTextEditorInlineButtonsStatus | undefined => {
  const { data } = useQuery(key || DefaultKey, async () => {
    return undefined; // Will return undefined, until a synchronizer calls setQueryData. 
  }, {
    staleTime: undefined, // Never allow it to go stale.
    refetchOnWindowFocus: false,
  });
  return data;
}
