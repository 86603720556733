import React from 'react';
import {
  SlideEditorModalProps,
  BackIconMode,
  EditorState,
  ModalOverlayState,
} from '../../modals/SlideEditorModal/SlideEditorModal';
import { darkTheme } from '../../../themes/custom-theme';
import {
  CommonContext,
  CommonDerivedState,
  getHeaderTitleAndIcon,
  CommonModes,
  getFileView,
} from './Common';
import { LabeledTextInput } from '../../DataEntry/CreationProcess/LabeledTextInput/LabeledTextInput';
import { ImageFile } from '../../../models/SlideFile';
import commonStyles from './_Common.module.scss';

const getSlideEditorModalProps = (context: CommonContext): SlideEditorModalProps => {
  const { state, setState, commonSlideEditorModalProps } = context;

  return {
    ...commonSlideEditorModalProps,
    backIconMode: BackIconMode.Dark,
    hideFooter: true,
    hideCommandBar: false,
    editorState: EditorState.Edit,
    loadingInfo: undefined,
    modalOverlayState: ModalOverlayState.None,
    selectedItemIndex: -1,
    hideActionContainer: true,
    showBackNavigation: true,
    showDefaultActions: false,
    createActions: [] as any,
    headerActions: getHeaderTitleAndIcon('Attribute', '/icons/creationprocess/citing.svg', 'white'),
    onNavigateBack: () => {
      setState({ ...state, mode: CommonModes.Edit, slide: context.state.slide });
    },
  };
}

const getContent = (context: CommonContext, slideEditorModalProps: SlideEditorModalProps) => {
  const { state, setState } = context;

  const file = state.slide.data.files[state.selectedItemIndex] as ImageFile;
  const attribution = file.attribution || "";

  const getUpdatedSlide = (attribution: string) => {
    const slide = {
      ...state.slide,
      data: {
        ...state.slide.data,
        files: state.slide.data.files.map((file: ImageFile, i: number) => {
          if (state.selectedItemIndex === i) {
            return {
              ...file,
              attribution,
            };
          } else {
            return file;
          }
        }),
      },
    };
    return slide;
  };

  const onConfirm = (text: string) => {
    const updatedSlide = getUpdatedSlide(text);
    setState({
      ...state,
      slide: updatedSlide,
      mode: CommonModes.Edit,
    });
  }

  const onClear = () => {};

  return (<>
  {getFileView(context, commonStyles.blur)}
  <div style={{
      display: "flex",
      bottom: "0px",
      left: "0px",
      right: "0px",
      position: "fixed",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <LabeledTextInput
        skipAcceptStep={true}
        style={{
          padding: "14px 7px",
          width: "100%",
          maxWidth: "500px",
        }}
        label={`Photo by: ${attribution}`}
        sublabel={"Give someone credit for the photo"}
        placeholder="Paste or type a link"
        isAcceptable={text => true}
        onAccept={async text => {
          setState({
            ...state,
            url: text,
          });
          return true;
        }}
        onConfirm={onConfirm}
        onClear={onClear}
        text={attribution}
      />
    </div>
  </>);
}

export const getDerivedState = (context: CommonContext): CommonDerivedState => {
  const slideEditorModalProps = getSlideEditorModalProps(context);
  const content = getContent(context, slideEditorModalProps);
  return {
    ...context.state,
    slideEditorModalProps,
    content,
    theme: darkTheme,
  };
};

export const Attribute = {
  getSlideEditorModalProps,
  getContent,
  getDerivedState,
};
