import React, { CSSProperties, useState } from 'react';
import PickerModal from '../PickerModal/PickerModal';
import styles from './_keyword-selector.module.scss';
import pickerModalStyles from '../PickerModal/_picker-modal.module.scss';
import SearchBar from '../../../../SearchBar';
import { SearchCriteria } from '../../../../Header/GidesAppHeader/GidesAppHeader';
import TagButton from '../../../../Shared/Buttons/TagButton/TagButton';
import icons from '../../../../../assets/icons';
import { reject, isEmpty, isNil, uniq } from 'ramda';

export interface KeywordSelectorProps {
  className?: string;
  keywords: string[];
  style?: CSSProperties;
  onCancel: (originalKeywords: string[]) => void;
  onAddKeywords: (keywords: string[]) => void;
  onRemoveKeyword: (keyword: string) => void;
  onSave: (keywords: string[]) => void;
}

export default function KeywordSelector(props: KeywordSelectorProps) {
  const [originalKeywords] = useState<string[]>(props.keywords);
  return (
    <div className={styles.keywordSelectorContainer} style={props.style}>
      <PickerModal
        headerContent={
          <div style={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
            <div style={{marginLeft: '11px'}} className={styles.keywordTitle}>Key words</div>
            <div style={{marginLeft: '11px'}} className={styles.keywordSubtitle}>Enter keywords so users can discover your Gide</div>

            <div className={pickerModalStyles.horizontalLine} style={{ marginTop: '6px', marginBottom: '12px' }} />
          </div>
        }
        className={props.className}
        onCancel={() => props.onCancel(originalKeywords)}
        onSave={() => props.onSave(props.keywords)}
      >
          <SearchBar
            style={{ marginLeft: '12px', marginRight: '12px', minHeight: '42px', maxHeight: '42px', borderRadius: '21px' }}
            onSearch={(searchCriteria: SearchCriteria) => {
              if(searchCriteria.searchText){
                const keywords = searchCriteria.searchText.split(' ');
                if(keywords) {
                  props.onAddKeywords(uniq(reject(kw => isEmpty(kw) || isNil(kw), keywords)));
                }
              }
            }}
            clearOnSearch={true}
            onToggleSearch={() => {}}
            placeholder={'Enter a tag'}
            hideSearchIcon={false}
            hideClearIcon={true}
          />
          <div className={styles.keywordsContainer}>
            <div className={styles.keywords}>
              {props.keywords.map((keyword: string) => 
                <TagButton 
                  label={keyword} style={{backgroundColor: '#fef8e2', margin: '5px'}}
                  labelCssClass="TEXTSUBTITLEorangeleft"
                  icon={<icons.Nav_Exit_Alternative color="var(--COLOR-PRIMARY-600)"
                    style={{cursor: 'pointer', minWidth: '24px' }}
                    onClick={() => {
                      props.onRemoveKeyword(keyword);
                    }}
                  />} 
                />
              )}
            </div>
            <div style={{flex: 1}}></div>
          </div>
      </PickerModal>
    </div>
  );
}
