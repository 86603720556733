import React, { Component, Suspense, lazy } from 'react';
import { saveInlineTextEdits, stripTags } from '../utils/helperFunctions';
import { Loading } from './Shared/Loading/Loading';

const RichTextEditor = lazy(() => import('./Editor/RichTextEditor'));

/**
 * This text editor can be used to edit any field that is set on the
 * data field of a slide. It is currently being used with the following:
 * 1. Text/RichText (Slide) - slide.data.body
 * 2. Header (Slide) - slide.data.title
 * 3. Caption (Content) - slide.data.caption
 */
export class InlineTextEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      textEditorValue: this.props.slide.data[this.props.dataField],
      originalValue: this.props.slide.data[this.props.dataField]
    };
    this.allowEdits = true;
    // this.updateText = editorState => {
    //   this.setState({ editorState });
    // };
    this.handleEditorChange = textEditorValue => {
      this.setState({ textEditorValue: textEditorValue });      
      const inlineEditedSlide = {
        ...this.props.slide,
        data: {
          ...this.props.slide.data,
        },
      };
      inlineEditedSlide.data[this.props.dataField] = textEditorValue;
      this.props.onChange({ inlineEditedSlide });
    };
    this.cancelTextEdits = () => {
      this.props.setInlineSlideTextEditInfo({
        inlineSlideTextEditInfo: { inlineSlideTextEditId: null, type: null },
      });
    };
    this.saveTextEdits = () => {                  
      const isCaption = this.props.dataField === 'caption';
      if(this.props.saveEdits && this.props.setInlineSlideTextEditInfo) {
        const slide = {
          ...this.props.slide,
          data: {
            ...this.props.slide.data,                      
          },
        };
        slide.data[this.props.dataField] = isCaption 
          ? stripTags(this.state.textEditorValue) 
          : this.state.textEditorValue;
        saveInlineTextEdits(slide,
          this.props.saveEdits, this.props.setInlineSlideTextEditInfo, this.props.dataField);
      }
    };    
    this.getSlide = () => {
      const slide = {
        ...this.props.slide,
        data: {
          ...this.props.slide.data,
        },
      };
      slide.data[this.props.dataField] = this.state.textEditorValue;
      return slide;
    };
    this.saveTextEdits = () => {
      if (this.props.onSubmit) {
        this.props.onSubmit(this.getSlide());
      }
    };
    this.openEditor = () => {
      if (this.props.onOpenEditor) {
        this.props.onOpenEditor(this.getSlide());
      }
    };
    // Initialize the inlineEditedSlide in the reducer otherwise it will
    // be null unless the user makes a change in the RTE
    if(this.props.onChange) {
      const slide = {
        ...this.props.slide,
        data: {
          ...this.props.slide.data,
        },
      };
      this.props.onChange({ inlineEditedSlide: slide });
    }
  }
  render() {
    return (
      <div className="flexColumnFull">
        <div className="flexRowFullFlexTop">
          <div className="flexRowFull" style={{ overflowY: 'auto' }}>
            {/* <textarea style={{width: '100%'}} rows="3" value={this.state.editText} onChange={this.updateText} /> */}
            {/* <Editor
              onChange={this.updateText}
              editorState={this.state.editorState}
            /> */}
            <Suspense fallback={<Loading />}>
              <RichTextEditor          
                classes={this.props.inlineEditorClasses}
                style={{ display: 'flex', flex: 1, border: 'none' }}
                onChange={this.handleEditorChange}
                value={this.state.textEditorValue}
                limitHeight={false}
                isReadOnly={this.props.isReadOnly ? this.props.isReadOnly : false}
                focusInputOnLoad={true}
                inline={true}
                onCancel={this.cancelTextEdits}
                openFullTextEditor={this.openEditor}
                onBlur={() => {
                  if(this.props.isReadOnly) return;

                  const isCaption = this.props.dataField === 'caption';
                  if(this.props.saveEdits && this.props.setInlineSlideTextEditInfo) {
                    const slide = {
                      ...this.props.slide,
                      data: {
                        ...this.props.slide.data,                      
                      },
                    };
                    slide.data[this.props.dataField] = isCaption 
                      ? stripTags(this.state.textEditorValue) 
                      : this.state.textEditorValue;
                    saveInlineTextEdits(slide,
                      this.props.saveEdits, this.props.setInlineSlideTextEditInfo, this.props.dataField);
                  }
                }}
              />
            </Suspense>
          </div>          
        </div>
      </div>
    );
  }
}
export default InlineTextEditor;
