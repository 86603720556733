import React, { CSSProperties } from 'react';
import styles from './_channel-list-item.module.scss';
import icons from '../../../../../assets/icons';
import PreviewAvatar from '../../PreviewAvatar/PreviewAvatar';
import GideImage from '../../../../Shared/Image/GideImage';
import { ItemSelectionMode } from '../../GidePreview/GideListItemPreview/GideListItemPreview';
export interface ChannelListItemPreviewProps {
  image: string;
  title: string;
  authorImage: string;
  author: string;
  authorCountry: string;
  type: string;
  isSubChannel?: boolean;
  className?: string;
  style?: CSSProperties;
  selected?: boolean;
  selectionMode: ItemSelectionMode;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export default function ChannelListItemPreview(props: ChannelListItemPreviewProps) {
  return (
    <div className={`${styles.channelListItemPreviewContainer} ${props.className}`}
      style={props.style}
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if(props.onClick) {
          props.onClick(event);
        }
      }}
    >
      <div className={styles.channelListItemImage}>
        <GideImage src={props.image} alt={props.title} />
      </div>
      <div className={styles.channelListItemPreviewDetails}>
        <div className={styles.channelListItemTitle}>{props.title === 'FYEO' ? 'For Your Eyes Only' : props.title}</div>
        <div className={styles.channelListItemGideDetails}>
          <PreviewAvatar disabled={true} authorImage={props.authorImage} author={props.author} authorCountry={props.authorCountry} />
          <div className={styles.channelInfo}>
            <div className={styles.channelInfoDetail}>{props.type}</div>
            {props.isSubChannel && <div className={styles.channelInfoDetail}>SUB CHANNEL</div>}
          </div>
        </div>
      </div>
      {props.selectionMode === ItemSelectionMode.MultipleDisplayCheckBox && (
          <>
          {props.selected && (
            <div className={styles.channelSelection}>
              <icons.ContentAlteration_Checkbox_CheckboxActive color='var(--COLOR-PRIMARY-600)'/>
            </div>
          )}
          {!props.selected && (
            <div className={styles.channelSelection}>
              <icons.ContentAlteration_Checkbox_CheckboxDeactive/>
            </div>
          )}
          </>
        )}
        {props.selectionMode === ItemSelectionMode.SingleDisplayRadioButton && (
          <>
          {props.selected && (
            <div className={styles.channelSelection}>
              <icons.ContentAlteration_Radio_Active color="var(--COLOR-PRIMARY-600)"/>
            </div>
          )}
          {!props.selected && (
            <div className={styles.channelSelection}>
              <icons.ContentAlteration_Radio_Deactive/>
            </div>
          )}
          </>
        )}
      <icons.Nav_Channels className={styles.logo} />
      <div className={styles.logoBackground} />
    </div>
  );
}
