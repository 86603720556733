/* eslint-disable no-undef */
/* Because the HOC brings in google obj but eslint doesn't know about it */
import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
const {
  MarkerWithLabel
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");

const MapWithAMarker = withScriptjs(
  withGoogleMap(
    ({ mapId, onMapTypeIdChanged, lat, lng, onDrag, onClick, showMarker, refMarker, refMap, points, showDirections, zoom, options, defaultMapTypeId }) => (
      <GoogleMap
        key={mapId}
        defaultZoom={zoom || 8}
        defaultCenter={{ lat, lng }}
        onDrag={onDrag}
        onClick={onClick}
        defaultMapTypeId={defaultMapTypeId}
        onMapTypeIdChanged={onMapTypeIdChanged}
        ref={refMap}
        options={options}
      >
        {showMarker && <Marker position={{ lat, lng }} ref={refMarker} />}

        {!showDirections && points && points.map(({ lat, lng, label }, i) => {
          return (
            <MarkerWithLabel
              key={i}
              position={{ lat, lng }}
              labelAnchor={new google.maps.Point(0, 0)}
              labelStyle={{
                backgroundColor: "#efefef",
                fontSize: "15px",
                padding: "10px"
              }}
            >
              <div>{label}</div>
            </MarkerWithLabel>
          );
        })}
      </GoogleMap>
    )
  )
);

export default MapWithAMarker;
