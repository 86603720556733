import React from 'react';
import { 
  SlideEditorModalProps, 
  BackIconMode, 
  EditorState, 
  ModalOverlayState, 
} from '../../modals/SlideEditorModal/SlideEditorModal';
import { darkTheme } from '../../../themes/custom-theme';
import { 
  CommonContext, 
  CommonDerivedState, 
  getHeaderTitleAndIcon,
  CommonModes,
  getFileView, 
} from './Common';

const getSlideEditorModalProps = (context: CommonContext): SlideEditorModalProps => {
  const { state, setState, commonSlideEditorModalProps } = context;
  return {
    ...commonSlideEditorModalProps,
    backIconMode: BackIconMode.Light,
    hideFooter: true,
    hideCommandBar: false,
    editorState: EditorState.Edit,
    loadingInfo: undefined,
    modalOverlayState: ModalOverlayState.None,
    selectedItemIndex: -1,
    hideActionContainer: true,
    showBackNavigation: true,
    showDefaultActions: false,
    createActions: [] as any,
    headerActions: getHeaderTitleAndIcon('Trim', '/icons/creationprocess/trim.svg', 'white'),
    onNavigateBack: () => {
      setState({ ...state, mode: CommonModes.Edit, slide: context.state.slide });
    },
  };
}

const getContent = (context: CommonContext): React.ReactNode => {
  return getFileView(context);
}

export const getDerivedState = (context: CommonContext): CommonDerivedState => {
  const slideEditorModalProps = getSlideEditorModalProps(context);
  const content = getContent(context);
  return {
    ...context.state,
    slideEditorModalProps,
    content,
    theme: darkTheme,
  };
};

export const Trim = {
  getSlideEditorModalProps,
  getContent,
  getDerivedState,
};
