import React from 'react';
import { Image } from 'semantic-ui-react';

const SignatureSlide = ({ slide }) => (
  <div>
    <Image src={slide.data.dataURL} alt={`□`} fluid />
  </div>
);

export default SignatureSlide;
