import React from 'react';
import ImageView from '../ImageView/ImageView';
import { Slide } from '../../../../models/Slide';
import { SlideItemSelectionActionType, ImageFile } from '../../../../models/SlideFile';
import ImageSlide, { buildImageSlideFromSlideFile } from '../../../Slides/ImageSlide';

export interface ImageListProps {
  imageList: ImageFile[];
  slide?: Slide;
  openModal?: (payload: any) => void;
  closeModal?: () => void;
}

export default function ImageList(props: ImageListProps) {
  return (
    <div className='imageListViewer'
    >
      {props.imageList.map((imageFile: ImageFile, i: number) => {
        return (
          <ImageView
            className={i < props.imageList.length - 1 ? 'hasEnsuingItem' : undefined}
            key={i}
            imageFile={imageFile}
            isReferenceType={imageFile.type === 'SLIDEFILE'}
            openModal={props.openModal}
            closeModal={props.closeModal}
            slide={props.slide}
            onClick={() => {
              if((!imageFile.onSelect?.slideItemSelectionActionType
                  || imageFile.onSelect.slideItemSelectionActionType === SlideItemSelectionActionType.SwipeView)
                && props.openModal && props.closeModal && props.slide) {
                const slide = {
                  ...props.slide,
                  position: 0,
                  data: {
                    files: [{
                      ...imageFile,
                      onSelect: undefined, // PREVENT INFINITE ABILITY TO SELECT
                      caption: '',
                      audioCaption: undefined,
                      attribution: '',
                    }],
                    caption: imageFile.caption,
                    audioCaption: imageFile.audioCaption,
                    attribution: imageFile.attribution,
                  },
                  autoAdvanceSlide: imageFile.audioCaption ? imageFile.audioCaption.autoAdvance : false,
                  autoPlayAudioCaption: imageFile.audioCaption ? imageFile.audioCaption.autoAdvance : false,
                };
                const swipeableItems = [{
                    slide: slide,
                    component: <ImageSlide {...props} slide={slide} viewMode="SWIPE" />
                  }
                ];
                props.openModal({
                  modalType: 'SwipeViewModal',
                  modalProps: {
                    closeModal: props.closeModal,
                    closeOnNavigatePastLastItem: true,
                    showHeader: true,
                    swipeableItems,
                    totalNumberOfSlides: 1
                  },
                });
              } else if (imageFile.onSelect && imageFile.onSelect.slideItemSelectionActionType === SlideItemSelectionActionType.OpenModal
                && props.openModal && props.closeModal && props.slide) {
                  const slideFileImageSlide = buildImageSlideFromSlideFile(props.slide, imageFile, 0);
                  props.openModal({
                    modalType: 'ImageViewModal',                    
                    modalProps: {
                      slide: slideFileImageSlide,                      
                    }
                  })
                }
            }}
          />
        );
      })}
    </div>
  );
}
