import React, { useState } from 'react';
import styles from './_input-bar.module.scss';
import icons from '../../../assets/icons';
import InputBarEditor from './InputBarEditor/InputBarEditor';
import classNames from 'classnames';
import { InputBarOpenMenu, HeaderLevel } from '../../../constants/strings';
import { hasValue } from '../../../utils/helperFunctions';
import { withTheme } from 'styled-components';
import GideImage from '../../Shared/Image/GideImage';
import isNil from 'ramda/es/isNil';
export interface  InputBarProps {
  slideCount: number;
  currentSlidePosition?: number;
  placeholderText: string;
  inputBarState: InputBarOpenMenu;
  inputBarValue?: string;
  focusInputBar?: any;
  blurInputBar?: any;
  theme?: any;
  disabled?: boolean;
  currentHeaderLevel?: HeaderLevel;
  onSelectDisabled:() => void;
  onChange: (value: string) => void;
  onAcceptInput: (value: string) => void;
  onInputEditorFocus: (isFocused: boolean) => void;
  displayElipsisOnLostFocus?: boolean;
  onCloseInputBarSlideMenu: () => void;
  onOpenInputBarSlideMenu: () => void;
  onChangeHeaderLevel: () => void;
  onCameraClicked: () => void;
  onSlideNumberClicked: () => void;
  onEmojiClicked: () => void;  
  onPaste?: (event: React.ClipboardEvent<HTMLTextAreaElement>) => void;
}

function TextSettingsIcon (props: {
    headerLevel?: HeaderLevel,
    disabled?: boolean,
    inputBarState: InputBarOpenMenu,
    onChangeHeaderLevel: () => void,
  }) {
  return (

    <>  
      {props.inputBarState !== InputBarOpenMenu.HEADER && (
        <>
        {props.headerLevel === HeaderLevel.UNSET && (
          <icons.Nav_Settings
            color='var(--COLOR-PRIMARY-300)'
            className={styles.textSettingsIcon}
            onClick={() => {
              if(!props.disabled) {
                props.onChangeHeaderLevel()
              }
            }}              
          />
        )}
        {props.headerLevel === HeaderLevel.Ti && (
          <icons.ContentAlteration_RichText_Header_Title
            color='var(--COLOR-PRIMARY-300)'
            className={styles.textSettingsIcon}
            onClick={() => {
              if(!props.disabled) {
                props.onChangeHeaderLevel()
              }
            }}              
          />
        )}      
        {props.headerLevel === HeaderLevel.H1 && (
          <icons.ContentAlteration_RichText_Header_H1
            color='var(--COLOR-PRIMARY-300)'
            className={styles.textSettingsIcon}
            onClick={() => {
              if(!props.disabled) {
                props.onChangeHeaderLevel()
              }
            }}              
          />
        )}
        {props.headerLevel === HeaderLevel.H2 && (
          <icons.ContentAlteration_RichText_Header_H2
            color='var(--COLOR-PRIMARY-300)'
            className={styles.textSettingsIcon}
            onClick={() => {
              if(!props.disabled) {
                props.onChangeHeaderLevel()
              }
            }}              
          />
        )}
        {props.headerLevel === HeaderLevel.H3 && (
          <icons.ContentAlteration_RichText_Header_H3
            color='var(--COLOR-PRIMARY-300)'
            className={styles.textSettingsIcon}
            onClick={() => {
              if(!props.disabled) {
                props.onChangeHeaderLevel()
              }
            }}              
          />
        )}
        {props.headerLevel === HeaderLevel.H4 && (
          <icons.ContentAlteration_RichText_Header_H4
            color='var(--COLOR-PRIMARY-300)'
            className={styles.textSettingsIcon}
            onClick={() => {
              if(!props.disabled) {
                props.onChangeHeaderLevel()
              }
            }}              
          />
        )}                  
        {props.headerLevel === HeaderLevel.H5 && (
          <icons.ContentAlteration_RichText_Header_H5
            color='var(--COLOR-PRIMARY-300)'
            className={styles.textSettingsIcon}
            onClick={() => {
              if(!props.disabled) {
                props.onChangeHeaderLevel()
              }
            }}              
          />
        )}      
      </>
      )}
    </>
  )
}

function InputBar (props:  InputBarProps) {
  const [inputEditorFocusState, setInputEditorFocusState] = useState<boolean>(false);   
  const slidePosition = !isNil(props.currentSlidePosition)
  ? props.currentSlidePosition + 1
  : props.slideCount + 1;
  const slideNumberInputBarClasses = classNames(
    styles.slideNumberContainer,
    !isNil(props.currentSlidePosition)
      ? props.currentSlidePosition < props.slideCount 
        ? styles.outOfOrder 
        : null
      : null
  );
  const slideNumberClasses = classNames(
    'slideNumberCreate',
    slidePosition < 10 ? 'oneToNine' : 'tenToMax',
    !isNil(props.currentSlidePosition) && props.currentSlidePosition < props.slideCount ? 'outOfOrder' : '',
  );  
  return (
    <div className={styles.inputBarContainer}>
      <div className={styles.inputBar}>
        {props.inputBarState !== InputBarOpenMenu.SLIDE && (        
          <icons.Nav_Plusicon
            color='var(--COLOR-SECONDARY-500)'
            style={{marginBottom: '7px', cursor: 'pointer'}}
            onClick={() => {
              if(!props.disabled) {
                props.onOpenInputBarSlideMenu();
              } else {
                props.onSelectDisabled()
              }
            }}
          />
        )}
        {props.inputBarState === InputBarOpenMenu.SLIDE && (        
          <icons.Nav_Exit_Main
            color='var(--COLOR-SECONDARY-500)'
            style={{marginBottom: '7px', cursor: 'pointer'}}
            onClick={() => {
              if(!props.disabled) {
                props.onCloseInputBarSlideMenu();
              }
            }}
          />
        )}        
        <div className={classNames(styles.inputBarEditor, !inputEditorFocusState && !hasValue(props.inputBarValue) ? styles.cameraVisible : undefined)}>
          <InputBarEditor
            onPaste={props.onPaste}
            inputBarValue={props.inputBarValue}
            onInputEditorFocusChanged={(isFocused: boolean) => {
              setInputEditorFocusState(isFocused);
              props.onInputEditorFocus(isFocused);
            }}
            placeholderText={props.placeholderText}
            onAcceptInput={props.onAcceptInput}
            onChange={props.onChange}
            // actions={distributionActions}
            displayElipsisOnLostFocus={props.displayElipsisOnLostFocus ? props.displayElipsisOnLostFocus : false}
            focusInputBar={props.focusInputBar}
            blurInputBar={props.blurInputBar}
            disabled={props.disabled}
          >
            <>
              {!hasValue(props.inputBarValue) && props.currentHeaderLevel === HeaderLevel.UNSET && (
                <icons.ContentAlteration_Camera_Default
                  color='var(--COLOR-SECONDARY-500)'
                  // style={{marginRight: '11px', marginBottom: '5px', cursor: 'pointer'}}
                  className={styles.cameraIcon}
                  onClick={() => {
                    if(!props.disabled) {
                      props.onCameraClicked();
                    }
                  }}
                />
              )}
              {props.currentHeaderLevel !== HeaderLevel.UNSET && (              
                <TextSettingsIcon
                  headerLevel={props.currentHeaderLevel}
                  onChangeHeaderLevel={props.onChangeHeaderLevel}
                  disabled={props.disabled}
                  inputBarState={props.inputBarState}
                />
              )}
              {hasValue(props.inputBarValue) && (
                <span className="flexRowFlexNormal slideNumberCreateContainer">
                  <span className={slideNumberClasses}>
                    {slidePosition}
                  </span>
                  <GideImage
                    className="pointer"
                    onClick={(e) => {
                      props.onAcceptInput(props.inputBarValue ? props.inputBarValue : '');
                    }}
                    onMouseUp={(e) => {
                      //props.onAcceptInput(props.inputBarValue ? props.inputBarValue : '');
                    }}
                    onMouseDown={(e) => {
                      e.preventDefault();
                    }}
                    src="/icons/content-alteration/input-bar/slide/finalise.svg"
                    style={{marginBottom: '3px'}}
                  />
                </span>
              )}
              {!hasValue(props.inputBarValue) && (        
                <div className={slideNumberInputBarClasses}
                  onClick={() => {
                    if(!props.disabled) {
                      props.onSlideNumberClicked();
                    }
                  }}
                >
                  {slidePosition}
                </div>
              )}


            </>
          </InputBarEditor>
        </div>        
      </div>
    </div>
  );
}

export default withTheme(InputBar);
