import React from 'react';
import { 
  SlideEditorModalProps, 
  BackIconMode, 
  EditorState, 
  ModalOverlayState, 
} from '../../modals/SlideEditorModal/SlideEditorModal';
import { SlideItemSelectionActionType } from '../../../models/SlideFile';
import { v4 } from 'uuid';
import { darkTheme } from '../../../themes/custom-theme';
import { 
  CommonContext, 
  CommonDerivedState, 
  getHeaderTitleAndIcon,
  CommonModes,
} from './Common';
import SlideFileChooser from '../../Shared/SlideFileChooser/SlideFileChooser';
import { SlideFileReference } from '../../../models/SlideFileReference';
import agent from '../../../agent';

const getSlideEditorModalProps = (context: CommonContext): SlideEditorModalProps => {
  const { state, setState, commonSlideEditorModalProps, isImageSlide, isVideoSlide } = context;

  const title = 
    isImageSlide ? "an image" :
    isVideoSlide ? "a video" :
      "a file";

  return {
    ...commonSlideEditorModalProps,
    backIconMode: BackIconMode.Dark,
    hideFooter: true,
    hideCommandBar: true,
    editorState: EditorState.Edit,
    loadingInfo: undefined,
    modalOverlayState: ModalOverlayState.None,
    selectedItemIndex: state.selectedItemIndex,
    hideActionContainer: true,
    showBackNavigation: true,
    showDefaultActions: false,
    createActions: [] as any,
    headerActions: getHeaderTitleAndIcon(`Find ${title} in a gide`, '/icons/slidetype/insert/main.svg', 'white'),
    onNavigateBack: () => {
      const mode = !state.slide.data.files.length ? CommonModes.New 
        : state.selectedItemIndex > -1 ? CommonModes.Edit
        : CommonModes.Preview;
      setState({ ...state, mode, mediaChooserAction: undefined, hiddenMediaChooserAction: undefined });
    },
  };
}

const getContent = (context: CommonContext, slideEditorModalProps: SlideEditorModalProps) => {
  const { props, state, setState, isImageSlide, isVideoSlide } = context;
  
  const title = 
    isImageSlide ? "an image" :
    isVideoSlide ? "a video" :
      "a file";
  const subtitle =
    isImageSlide ? "image" :
    isVideoSlide ? "video" :
      "file"
  const slideType =
    isImageSlide ? 'IMAGE' :
    isVideoSlide ? 'VIDEO' :
      '';

  return (
    <SlideFileChooser
      title={`Find ${title} slide`}
      subtitle={`Pick a gide that your ${subtitle} resides in`}
      hideActionBar={true}
      showNotification={props.showNotification}
      currentUser={props.currentUser}
      slideTypes={[slideType]}
      agent={agent}
      singleSelectionOnly={false}
      onNavigateBack={() => {
        setState({ 
          ...state,
          mode: !state.slide.data.files.length ? CommonModes.New : CommonModes.Edit,
        });
      }}
      addSlideFileReferences={(slideFileReference: SlideFileReference[]) => {
        const newSlides = slideFileReference.map(sfr => {
          return {
            id: v4(),
            slideFileReference: sfr,
            type: 'SLIDEFILE',
            onSelect: {
              slideItemSelectionActionType: SlideItemSelectionActionType.OpenModal
            }
          };
        });
        const slide = {
          ...state.slide,
          data: {
            ...state.slide.data,
            files: [...state.slide.data.files, ...newSlides]
          }
        }
        setState({
          ...state,
          mode: CommonModes.Edit,
          loadingInfo: undefined,
          slide,
          selectedItemIndex: slide.data.files.length - 1,
        });
      }}
    />
  );
}

export const getDerivedState = (context: CommonContext): CommonDerivedState => {
  const slideEditorModalProps = getSlideEditorModalProps(context);
  const content = getContent(context, slideEditorModalProps);
  return {
    ...context.state,
    slideEditorModalProps,
    content,
    theme: darkTheme,
  };
};

export const ChooseGideFile = {
  getSlideEditorModalProps,
  getContent,
  getDerivedState,
};
