import React, { Component } from 'react';
import { Modal, Button, Input, List, Grid, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import agent from '../../agent';

import {
  MODAL_CLOSE,
  COLLECTION_MODAL_LOADED,
  COLLECTION_MODAL_UNLOADED,
} from '../../constants/actionTypes';
import GideImage from '../Shared/Image/GideImage';

const mapDispatchToProps = dispatch => ({
  onLoad: payload => dispatch({ type: COLLECTION_MODAL_LOADED, payload }),
  onUnload: () => dispatch({ type: COLLECTION_MODAL_UNLOADED }),
  closeModal: payload => dispatch({ type: MODAL_CLOSE }),
});

const mapStateToProps = state => ({
  article: state.article.article,
  currentUser: state.common.currentUser,
  collections: state.collectionModal.collections,
});

export class SelectCollectionModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      layout: 'LIST',
      articleId: null,
      searchText: '',
    };

    this.changeLayout = layout => {
      this.setState({ layout });
    };

    this.searchTextChanged = ev => {
      this.setState({ searchText: ev.target.value });
    };

    this.searchGides = async () => {
      return alert('Coming soon.');
      // Needs byAuthor endpoint updated to allow search.
      // if (!hasValue(this.state.searchText)) {
      //   const payload = await agent.Articles.all();
      //   this.props.onSearch(payload);
      // } else {
      //   const searchText = `searchText=${this.state.searchText}`;
      //   const payload = await agent.Articles.bySearch(searchText);
      //   this.props.onSearch(payload);
      // }
    };

    this.selectCollection = collection => {
      this.setState({ collection });
    };
  }

  componentWillMount() {
    this.props.onLoad(agent.Users.getCollections());
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    const { layout, collection } = this.state;
    let { collections, closeModal } = this.props;

    return (
      <Modal
        style={{ width: '300px' }}
        closeOnEscape={true}
        onClose={closeModal}
        className="slideModal"
        size="tiny"
        dimmer="inverted"
        closeOnDocumentClick={false}
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Content className="preventSemanticActionStyle">
          <Modal.Description
            className="headerLevel3 preventSemanticActionStyle"
            style={{ padding: '10px' }}
          >
            <span>
              <strong>Select a Collection</strong>
            </span>
          </Modal.Description>

          <section>
            {(!collections || !collections.length) && (
              <div>No Collections available.</div>
            )}
            <div className="flexRowSpaceBetween" style={{ margin: '5px' }}>
              <div className="flexRowFull selectGideModal">
                <Input
                  style={{ flex: 1 }}
                  placeholder="Search Gides"
                  value={this.state.searchText}
                  onChange={this.searchTextChanged}
                />
                <Button
                  style={{ marginLeft: '5px' }}
                  onClick={this.searchGides}
                  icon
                >
                  <Icon name="search" />
                  Search
                </Button>
              </div>
              {/* <div>
                <Button.Group>
                  <Button
                    icon="block layout"
                    onClick={() => this.changeLayout('GRID')}
                  />
                  <Button
                    icon="list layout"
                    onClick={() => this.changeLayout('LIST')}
                  />
                </Button.Group>
              </div> */}
            </div>
            {layout === 'LIST' && (
              <List selection divided relaxed>
                {collections.map((c, i) => (
                  <List.Item
                    key={i}
                    onClick={() => this.selectCollection(c)}
                    className={
                      collection
                        ? collection._id === c._id ? 'selected' : ''
                        : ''
                    }
                  >
                    <List.Content>{c.title}</List.Content>
                  </List.Item>
                ))}
              </List>
            )}
            {layout === 'GRID' && (
              <Grid columns={3} padded>
                {collections.map((c, i) => (
                  <Grid.Column
                    key={i}
                    onClick={() => this.selectCollection(c)}
                    className={
                      collection
                        ? collection._id === c._id ? 'selected' : ''
                        : ''
                    }
                  >
                    <GideImage src={c.image} alt="cover" />
                  </Grid.Column>
                ))}
              </Grid>
            )}
          </section>
        </Modal.Content>
        <Modal.Actions className="preventSemanticActionStyle">
          <div className="flexRowFlexEnd">
            <Button
              disabled={!this.state.collection}
              color="blue"
              content={this.props.saveButtonTitle}
              onClick={() => {
                this.props.saveCallback({
                  collectionId: this.state.collection._id,
                  collection: this.state.collection,
                });
                closeModal();
              }}
            />
            <Button onClick={closeModal}>Cancel</Button>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  SelectCollectionModal,
);
