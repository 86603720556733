import React from 'react';
import GideViewHeader from './GideViewHeader/GideViewHeader';
import GidesAppHeader, { SearchCriteria } from './GidesAppHeader/GidesAppHeader';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../reducer';
import { User } from '../../models/User';
import { GideAction } from './GideViewHeader/GideActions/GideActions';
import { isNil, contains } from 'ramda';
import {
  ARTICLE_TITLE_IMAGE_UPDATED,
  LOCK_GIDE_INLINE_TEXT_EDIT,
  UNLOCK_GIDE_INLINE_TEXT_EDIT,
  SET_VIEW_MODE,
  TOGGLE_ARTICLE_EDIT_MODE,
  EXIT_SLIDE_SELECTION_MODE,
  UPDATE_SLIDE_SELECTION_METHOD,
  UPDATE_SLIDE_SELECTION_OPERATION_MODE,
  ARTICLE_SUBMITTED,
  MODAL_OPEN,
  FOLLOW_USER,
  UNFOLLOW_USER,
  CLOSE_AVATAR_MENU,
  SEARCH_EXECUTED,
  HOME_PAGE_GIDES_LOADED,
  SLIDE_ZERO_SUBMITTED,
  CHANNELS_LOADED,
  ARTICLE_DISPLAY_OWNER_PANEL,
} from '../../constants/actionTypes';
import { ToasterMessageInfo } from '../../models/ToasterMessageInfo';
import { NotificationType } from '../../constants/strings';
import {
  getCustomDomain,
  titleToPath,
  getRecommendedGides,
  isValidSearchCriteria,
  slideEditorModalLookup,
  shareGide,
  urlForArticle,
  displayGideTitle,
  isMobileDevice,
} from '../../utils/helperFunctions';
import SlideEditGideHeader from '../Slides/SlideEditGideHeader/SlideEditGideHeader';
import { SlideSelectionInfo } from '../../models/SlideSelectionInfo';
import { Article } from '../../models/Article';
import agent from '../../agent';
import { Slide, SlideUpdateInfo } from '../../models/Slide';
import { AvatarMenuLocation } from '../Shared/Avatar/Avatar';
import { DISTRIBUTE_GIDE } from '../../reducers/common/common.actions';
import { history } from '../../store';
import { TitleSlideProcessorInfo } from '../../reducers/FileProcessing/fileProcessing';
/**
 * Header component determines whether to show the in gide header or the header
 *
 */

export enum HeaderMode {
  InGideHeader = 1,
  GideAppHeader = 2,
}

export interface HeaderProps {
  headerMode: HeaderMode;
  signUp: () => void;
  signIn: () => void;
  showNotification: (toasterMessageInfo: ToasterMessageInfo) => void;
  toggleAvatarMenu: (location: AvatarMenuLocation, openExpanded: boolean) => void;
  onOpenNotifications: () => void;
  openLeftSidebar: () => void;
}
interface HeaderStoreProps {
  article: Article | null;
  titleSlide?: Slide;
  authorViewing: boolean;
  gideImage?: string;
  gideTitle?: string;
  viewMode: string | null;
  selectedGideAction: GideAction;
  lockInlineTextEdit: boolean;
  leftSidebarOpen: boolean;
  currentUser: User | null;
  slideSelectionModeDetail: SlideSelectionInfo | null;
  displayGideOwnerPanel: boolean;
  searchText?: string;
  titleProcessorItem?: TitleSlideProcessorInfo | null;
}
interface HeaderActions {
  setImageAndTitle: (image?: string, title?: string) => void;
  onDistribute: () => void;
  lockInlineTextEdit: () => void;
  unlockInlineTextEdit: () => void;
  setGideViewMode: (action: GideAction) => void;
  updateSlideSelectionMethod: (payload: any) => void;
  updateSlideSelectionOperationMode: (payload: any) => void;
  exitSlideSelectionMode: () => void;
  onSubmitArticle: (payload: any) => void;
  openModal: (payload: any) => void;
  followUser: (username: string) => void;
  unFollowUser: (username: string) => void;
  closeAvatarMenu: () => void;
  onSearch: (payload: any) => void;
  clearSearch: () => void;
  onSubmitSlideZero: (
    articleId: string, 
    slideZero: Slide, 
    articleSlug: string, 
    tagList?: string[],
    description?: string,
    descriptionSlide?: string
  ) => void;
  loadChannels: (payload: any) => void;
  setDisplayGideOwnerPanel: (display: boolean) => void;
}
// temporary to make accessing window.cordova be possible without TypeScript compiler error
declare let window: any;

export default function Header(props: HeaderProps) {
  const dispatch = useDispatch();
  const actions: HeaderActions = {
    onDistribute: () => dispatch({ type: DISTRIBUTE_GIDE }),
    setImageAndTitle: (image?: string, title?: string) => dispatch({ type: ARTICLE_TITLE_IMAGE_UPDATED, payload: { image, title } }),
    lockInlineTextEdit: () => dispatch({ type: LOCK_GIDE_INLINE_TEXT_EDIT }),
    unlockInlineTextEdit: () => dispatch({ type: UNLOCK_GIDE_INLINE_TEXT_EDIT }),
    onSubmitArticle: (payload: any) => dispatch({ type: ARTICLE_SUBMITTED, payload }),
    openModal: (payload: any) => dispatch({ type: MODAL_OPEN, payload }),
    loadChannels: (payload: any) => dispatch({ type: CHANNELS_LOADED, payload }),
    setGideViewMode: (action: GideAction) => {
      if (action === GideAction.AdvancedEdit) {
        dispatch({ type: SET_VIEW_MODE, mode: 'SLIDE' });
        dispatch({ type: TOGGLE_ARTICLE_EDIT_MODE, payload: { articleEditMode: 'advancedEdit' } });
      } else if (action === GideAction.CleanEdit) {
        dispatch({ type: SET_VIEW_MODE, mode: 'SLIDE' });
        dispatch({ type: TOGGLE_ARTICLE_EDIT_MODE, payload: { articleEditMode: 'cleanEdit' } });
      } else {
        // Scroll
        dispatch({ type: SET_VIEW_MODE, mode: 'SCROLL' });
      }
    },
    exitSlideSelectionMode: () => dispatch({ type: EXIT_SLIDE_SELECTION_MODE }),
    updateSlideSelectionMethod: payload => dispatch({ type: UPDATE_SLIDE_SELECTION_METHOD, payload }),
    updateSlideSelectionOperationMode: payload => dispatch({ type: UPDATE_SLIDE_SELECTION_OPERATION_MODE, payload }),
    followUser: (username: string) =>
      dispatch({
        type: FOLLOW_USER,
        payload: agent.Profile.follow(username),
      }),
    unFollowUser: (username: string) =>
      dispatch({
        type: UNFOLLOW_USER,
        payload: agent.Profile.unfollow(username),
      }),
    closeAvatarMenu: () => dispatch({ type: CLOSE_AVATAR_MENU }),
    onSearch: (payload: any) => dispatch({ type: SEARCH_EXECUTED, payload }),
    clearSearch: () => dispatch({ type: HOME_PAGE_GIDES_LOADED, payload: getRecommendedGides() }),
    onSubmitSlideZero: (articleId: string, slideZero: Slide, articleSlug: string, tagList?: string[], description?: string, descriptionSlide?: string) => dispatch({ type: SLIDE_ZERO_SUBMITTED, payload: { articleId, slideZero, articleSlug, tagList, description, descriptionSlide  } }),
    setDisplayGideOwnerPanel: (displayGideOwnerPanel: boolean) => dispatch({ type: ARTICLE_DISPLAY_OWNER_PANEL, payload: { displayGideOwnerPanel } }),
  };
  const headerStoreProps: HeaderStoreProps = useSelector<AppState, HeaderStoreProps>(
    (state: AppState): HeaderStoreProps => {
      const article = state.article.article;
      const titleProcessorItem = article && state.fileProcessor.titleSlideProcessorList.find(t => t.gideId === article.id);
      return {
        titleProcessorItem: article && state.fileProcessor.titleSlideProcessorList.find(t => t.gideId === article.id),
        article: article,
        authorViewing: !isNil(state.common.currentUser) && !isNil(article) && article.author.username === state.common.currentUser.username,
        gideImage: article && article.slideZero && article.slideZero.data.image
          ? article.slideZero.data.image
          : titleProcessorItem && titleProcessorItem.dataUrl ? URL.createObjectURL(titleProcessorItem.dataUrl) : '',
        gideTitle: !isNil(article) ? displayGideTitle(article.title, article.type) : undefined,
        viewMode: state.common.viewMode,
        selectedGideAction:
          state.common.viewMode === 'SCROLL'
            ? GideAction.GidePreview
            : state.common.articleEditMode === 'cleanEdit'
            ? GideAction.CleanEdit
            : GideAction.AdvancedEdit,
        lockInlineTextEdit: state.common.lockInlineTextEdit,
        leftSidebarOpen: state.common.showLeftSidebar,
        currentUser: state.common.currentUser,
        slideSelectionModeDetail: state.article.slideSelectionModeDetail,
        titleSlide: state.article.slides.find((s: Slide) => s.slideType === 'HEADER' && s.data.level === 0),
        displayGideOwnerPanel: state.article.displayGideOwnerPanel,
        searchText: state.articleList.searchText
      };
    },
  );

  const followUser = (username: string) => {
    if (headerStoreProps.currentUser) {
      actions.followUser(username);
    }
  };
  const unFollowUser = (username: string) => {
    if (headerStoreProps.currentUser) {
      actions.unFollowUser(username);
    }
  };

  const openDistributionDialog = async () => {
    if (!isNil(headerStoreProps.article)) {
      if (!headerStoreProps.article.slug) {
        const article = {
          title: 'Untitled',
        };
        actions.onSubmitArticle(agent.Articles.create(article));
      } else {

        if(headerStoreProps.article.slideZero) {
          const channels = await agent.Channels.byAuthor(headerStoreProps.currentUser?.username, true);
          actions.loadChannels(
            channels
          );
          actions.onDistribute();
        } else {
          await forceUserToPickGideTitle();
        }
      }
    }
  };

  const createSlideZero = async (slideUpdateInfo: SlideUpdateInfo) => {
    if (headerStoreProps.article) {
      const articleSlug = titleToPath(slideUpdateInfo.slide.data.title);
      const slideZeroResponse = await agent.Slides.createSlideZero(headerStoreProps.article.id,
        {
          slide: slideUpdateInfo.slide,
          articleSlug,
          tagList: slideUpdateInfo.tagList,
          description: slideUpdateInfo.description,
          descriptionSlideId: slideUpdateInfo.descriptionSlideId
        });
      history.replace(urlForArticle({slug: articleSlug, author: {username: headerStoreProps.currentUser?.username!}}));
      actions.onSubmitSlideZero(
        headerStoreProps.article.id,
        slideZeroResponse.slide,
        slideZeroResponse.articleSlug,
        slideUpdateInfo.tagList,
        slideZeroResponse.description,
        slideZeroResponse.descriptionSlide
      );
    }
  };
  const updateSlideZero = async (slideUpdateInfo: SlideUpdateInfo) => {
    if (headerStoreProps.article) {
      const articleSlug = titleToPath(slideUpdateInfo.slide.data.title);
      const slideZeroResponse = await agent.Slides.updateSlideZero(headerStoreProps.article.id,
        {
          slide: slideUpdateInfo.slide,
          articleSlug, tagList:
          slideUpdateInfo.tagList,
          description: slideUpdateInfo.description,
          descriptionSlideId: slideUpdateInfo.descriptionSlideId
        });
      history.replace(urlForArticle({slug: articleSlug, author: {username: headerStoreProps.currentUser?.username!}}));
      actions.onSubmitSlideZero(
        headerStoreProps.article.id,
        slideZeroResponse.slide,
        slideZeroResponse.articleSlug,
        slideUpdateInfo.tagList,
        slideZeroResponse.description,
        slideZeroResponse.descriptionSlide
      );
    }
  };

  const share = () => {
    if(headerStoreProps.article) {
      shareGide(`${headerStoreProps.article ? headerStoreProps.article.title : 'Gides'}`, urlForArticle(headerStoreProps.article), props.showNotification);
    }
  }

  const forceUserToPickGideTitle = async () => {
    const modalType = `${slideEditorModalLookup['GIDETITLE']}`;
    const modalProps = {
      currentUser: headerStoreProps.currentUser,
      article: headerStoreProps.article,
      onSubmitSlide: async (slideUpdateInfo: SlideUpdateInfo) =>
        { await onSubmitSlideAfterPickingGideTitle(slideUpdateInfo) },
      position: -1,
      modalType: modalType,
    };
    actions.openModal({modalType, modalProps});
  }
  const onSubmitSlideAfterPickingGideTitle = async (slideUpdateInfo: SlideUpdateInfo) => {
    const articleSlug = titleToPath(slideUpdateInfo.slide.data.title);
    const slideZeroResponse = await agent.Slides.createSlideZero((headerStoreProps.article as Article).id, {
      slide: slideUpdateInfo.slide,
      tagList: slideUpdateInfo.tagList,
      articleSlug: articleSlug,
      description: slideUpdateInfo.description,
      descriptionSlideId: slideUpdateInfo.descriptionSlideId
    });
    actions.onSubmitSlideZero && actions.onSubmitSlideZero((headerStoreProps.article as Article).id, slideZeroResponse.slide, slideZeroResponse.articleSlug, slideUpdateInfo.tagList, slideUpdateInfo.description, slideUpdateInfo.descriptionSlideId);
    const channels = await agent.Channels.byAuthor(headerStoreProps.currentUser?.username, true);
    actions.loadChannels(
      channels
    );
    actions.onDistribute();
  }
  const onSetSlideZero = () => {
    if(headerStoreProps.titleProcessorItem) {
      props.showNotification({
        message: 'You must wait until the gide image has been processed before you can edit the title slide. Try again in a few seconds.',
        type: NotificationType.WARNING
      });
      return;
    }
    if (headerStoreProps.article) {
      actions.openModal({
        modalType: `${slideEditorModalLookup['GIDETITLE']}`,
        modalProps: {
          currentUser: headerStoreProps.currentUser,
          article: headerStoreProps.article,
          onSubmitSlide: headerStoreProps.article.slideZero ? updateSlideZero : createSlideZero,
          position: -1,
          editSlide: headerStoreProps.article.slideZero,
          modalType: `${slideEditorModalLookup['GIDETITLE']}`,
        },
      });
    }
  };

  const onSearchArticles = async (searchCriteria: SearchCriteria) => {
    let q = `limit=${isMobileDevice() ? 12 : 36}&`;
    if (searchCriteria.searchText) {
      q += `searchText=${searchCriteria.searchText}&`;
    }
    if (searchCriteria.startDate) {
      q += `startDate=${searchCriteria.startDate}&`;
    }
    if (searchCriteria.endDate) {
      q += `endDate=${searchCriteria.endDate}&`;
    }
    if (searchCriteria.address) {
      q += `address=${searchCriteria.address}&`;
    }
    if (searchCriteria.latLng) {
      q += `lat=${searchCriteria.latLng.lat}&lng=${searchCriteria.latLng.lng}`;
    }
    if (!q.length) {
      q = `searchText=`;
    }
    const searchResults = await agent.Articles.bySearch(q);
    actions.onSearch({ ...searchResults, searchText: searchCriteria.searchText, searchParams: q });
  };
  if (getCustomDomain()) {
    return <></>;
  }
  if (headerStoreProps.slideSelectionModeDetail) {
    return (
      <SlideEditGideHeader
        slideSelectionModeDetail={headerStoreProps.slideSelectionModeDetail}
        updateSlideSelectionMethod={actions.updateSlideSelectionMethod}
        updateSlideSelectionOperationMode={actions.updateSlideSelectionOperationMode}
        onClose={actions.exitSlideSelectionMode}
      />
    );
  }
  if (props.headerMode === HeaderMode.InGideHeader) {
    return (
      <GideViewHeader
        onSetSlideZero={onSetSlideZero}
        onSetGideViewMode={actions.setGideViewMode}
        onLockInlineTextEdit={actions.lockInlineTextEdit}
        onUnlockInlineTextEdit={actions.unlockInlineTextEdit}
        onOpenAvatarMenu={props.toggleAvatarMenu}
        onCloseAvatarMenu={actions.closeAvatarMenu}
        onOpenLeftSidebar={props.openLeftSidebar}
        onDistribute={openDistributionDialog}
        onShare={share}
        authorViewing={headerStoreProps.authorViewing}
        gideAuthorName={headerStoreProps.article ? headerStoreProps.article.author.username : 'Unknown'}
        gideAuthorImage={headerStoreProps.article ? headerStoreProps.article.author.image : 'Unknown'}
        gideImage={headerStoreProps.gideImage}
        gideTitle={headerStoreProps.gideTitle}
        viewMode={headerStoreProps.viewMode}
        lockInlineTextEdit={headerStoreProps.lockInlineTextEdit}
        selectedGideAction={headerStoreProps.selectedGideAction}
        leftSidebarOpen={headerStoreProps.leftSidebarOpen}
        displayGideOwnerPanel={headerStoreProps.displayGideOwnerPanel}
        followUser={headerStoreProps.currentUser ? followUser : undefined}
        unFollowUser={unFollowUser}
        isFollowing={!isNil(headerStoreProps.article) && headerStoreProps.article.author.following}
        onSignUp={props.signUp}
        onSignIn={props.signIn}
        onDisplayGideOwnerPanel={actions.setDisplayGideOwnerPanel}
      />
    );
  } else {
    return (
      <GidesAppHeader
        onSearchArticles={(searchCriteria: SearchCriteria) => {
          isValidSearchCriteria(searchCriteria)
            ? onSearchArticles(searchCriteria)
            : props.showNotification({
                message: 'Invalid Search Criteria',
                type: NotificationType.ERROR,
              });
        }}
        leftSidebarOpen={headerStoreProps.leftSidebarOpen}
        currentUser={headerStoreProps.currentUser}
        onOpenAvatarMenu={props.toggleAvatarMenu}
        onOpenLeftSidebar={props.openLeftSidebar}
        onOpenNotifications={props.onOpenNotifications}
        onClearSearch={actions.clearSearch}
        searchText={headerStoreProps.searchText}
        onSignUp={props.signUp}
        onSignIn={props.signIn}
      />
    );
  }
}
