import React, { Component } from 'react';
import { User } from '../../models/User';
import classNames from 'classnames';
import { isNil } from 'ramda';
import { isNullOrUndefined } from 'util';
import agent from '../../agent';
import {
  hasValue, isValidURL, slideEditorModalLookup, getLinkPreviewDetails, ensureImageFileOrientation, HeaderSlideAvailableTypeInfo
} from '../../utils/helperFunctions';
import {
  InputBarOpenMenu,
  HeaderLevel,
  InputBarShortcutMode,
  NotificationType,
} from '../../constants/strings';
import { Article } from '../../models/Article';
import { Slide, GideTitleImageInfo, SlideUpdateInfo } from '../../models/Slide';

import { ToasterMessageInfo } from '../../models/ToasterMessageInfo';
import icons from '../../assets/icons';
import { HyperLinkLayout } from '../../models/CommonEnums';

import InputBarNew from '../../components/DataEntry/InputBar/InputBar';
import { ImageFile } from '../../models/SlideFile';
import { v4 } from 'uuid';
import Picker, { PickerDisplayMode } from '../DataEntry/Pickers/Picker/Picker';
import { MultiSlideInsertParameters } from '../../models/MultiSlideInsertParameters';
import { SlideTypeFilter } from './NewSlideTypeChooserModal/NewSlideTypeChooserModal';
interface InputBarProps {
  currentUser?: User | null;
  article?: Article | null;
  displayToolBar: boolean;
  currentSlidePosition?: number;
  numberOfSlides: number;
  displayViewBar: boolean;
  placeholderText?: string;
  textSlideEditing: Slide | null;
  slideType: string | null;
  openMenu: InputBarOpenMenu;
  showLeftSidebar: boolean;
  showRightSidebar: boolean;
  ensureInputBarModalsClosedOnFocus?: boolean;
  lastCurrentlyOpenedModal?: string;
  showSignup: () => void;
  openModal: (payload: {modalType: string, modalProps: any}) => void;
  closeModal: (closeDialogList?: string[]) => void;
  getAvailableHeaderLevelsForCurrentSlidePosition: () => HeaderSlideAvailableTypeInfo;
  setFooterInput?: (key: string, value: any) => void;
  onSubmitSlide: (payload: SlideUpdateInfo) => void;
  toggleViewBarDisplay: () => void;
  toggleToolBarDisplay: () => void;
  onSubmitMultipleSlides: (multiSlideInsertDetails: MultiSlideInsertParameters) => void;
  onUpdateArticleTitleAndImage?: (gideTitleImageInfo: GideTitleImageInfo) => void;
  updateSlideNumber: (number: number) => void;
  scrollToSlidePosition: (slidePosition: number) => void;
  createArticle?: () => Promise<Article>;
  setOpenMenu: (menuState: InputBarOpenMenu) => void;
  showNotification: (toasterMessageInfo: ToasterMessageInfo) => void;

}

interface InputBarState {
  inputBarValue?: string;
  inputBarShortcutMode: InputBarShortcutMode;
  inputBarMenuImage: string;
  currentHeaderLevel: HeaderLevel;
  focusInputBar: {} | undefined;
  blurInputBar: any;
  pendingInputBarText: string;
}

export enum HeaderMenuOptions {
  H1 = 1,
  H2 = 2,
  H3 = 3,
  H4 = 4,
  H5 = 5,
  HE = 6,
  HeaderModal = 7,
  Settings = 8
}
export default class InputBar extends Component<InputBarProps, InputBarState> {
  constructor(props: InputBarProps) {
    super(props);
    this.state = {
      inputBarValue: undefined,
      inputBarMenuImage: '/icons/nav/settings.svg',
      currentHeaderLevel: HeaderLevel.UNSET,
      inputBarShortcutMode: InputBarShortcutMode.Position,
      focusInputBar: undefined,
      blurInputBar: undefined,
      pendingInputBarText: '',
    };
  }

  componentDidUpdate(prevProps: InputBarProps) {
    // Coming from Gide but no longer in Gide need to clear out contents of the footer.
    if (
      (!isNullOrUndefined(prevProps.article) &&
      isNullOrUndefined(this.props.article)) || (prevProps.article?.id !== this.props.article?.id) 
    ) {
      this.clearFooterInput();
    }
    if (
      !prevProps.textSlideEditing &&
      this.props.textSlideEditing
    ) {
      this.setState({ inputBarValue: this.props.textSlideEditing.data.body });
      this.setState({focusInputBar: {}});
    }
  }

  clearFooterInput = () => {
    this.setState({ inputBarValue: '' });
  };

  enterFullEditor = () => {
    this.props.setFooterInput && this.props.setFooterInput('value', `<p>${this.state.inputBarValue ? this.state.inputBarValue : ''}</p>`);
    this.clearFooterInput();
    this.props.openModal({
      modalType: 'TextModal',
      modalProps: {
        position: this.props.currentSlidePosition,
      },
    });
  };

  showHeaderEndModal = () => {
    this.props.closeModal();
    setTimeout(() => {
      const headerInfo = this.availableHeaderInfo();
      this.props.openModal({
        modalType: 'EndHeaderModal',
        modalProps: {
          closeModal: this.closeSlideTypeChooserModal,
          headerSlidesThatCanEnd: headerInfo.headerSlidesToEnd,
          submitSlide: this.props.onSubmitSlide
        }
      });
    }, 500);

    // this.props.closeModal(['NewSlideTypeChooserModal']);
  }
  createEndHeaderSlide = async (beginSlideId: string) => {
    if (!isNullOrUndefined(this.props.article)) {
      const slide = {
        position: this.props.currentSlidePosition,
        slideType: 'HEADER',
        data: {
          type: 'END',
          beginSlideId: beginSlideId,
          position: this.props.currentSlidePosition,
        },
      };
      const response = await agent.Slides.create(this.props.article, slide);
      const payload = {
        slide: response.slide,
        preventScrollToSlide:
          !isNil(this.props.currentSlidePosition) &&
          this.props.numberOfSlides !== this.props.currentSlidePosition,
      };
      this.props.onSubmitSlide(payload);
    }
  };

  onHeaderItemSelected = (menu: { menuImageSourceOnSelection?: string, headerLevel: number, beginSlideId?: string}) => {
    this.setOpenMenu(InputBarOpenMenu.NONE);
    this.setState({
      inputBarMenuImage: menu.menuImageSourceOnSelection as string,
      currentHeaderLevel: menu.headerLevel,
      inputBarShortcutMode: InputBarShortcutMode.Header,
      focusInputBar: {},
    });    
  };

  openSlideMenu = () => {
    this.setOpenMenu(InputBarOpenMenu.SLIDE);
  };

  onInputFocus = () => {
    if(!this.props.currentUser) {
      this.props.showSignup();

      return;
    }
    const temp = this.props;
    console.log(temp.lastCurrentlyOpenedModal);
    if(this.props.lastCurrentlyOpenedModal !== 'GideViewerManagerModal') {
      this.props.closeModal();
    }
    if (!this.props.article && this.props.createArticle) {
      this.setState({blurInputBar: {}});
      this.props.createArticle();
    }
  };

  setOpenMenu = (openMenuOption: InputBarOpenMenu) => {
    this.props.setOpenMenu(openMenuOption);
  };

  openSlideModal = (modalType: string, article?: Article | null) => {
    this.setOpenMenu(InputBarOpenMenu.NONE);
    if (slideEditorModalLookup[modalType]) {
      this.props.openModal({
        modalType: slideEditorModalLookup[modalType],
        modalProps: {
          currentUser: this.props.currentUser,
          article: article,
          position: this.props.currentSlidePosition,
          startCamera: modalType === 'IMAGECAMERA' ? true : false,
          onSubmitSlide: this.props.onSubmitSlide,
          modalType: slideEditorModalLookup[modalType],
        }
      });
    } else {
      this.props.openModal({
        modalType: `${modalType}Modal`,
        modalProps: {
          slideType: modalType,
          article: article,
          onSubmitSlide: this.props.onSubmitSlide,
          currentSlidePosition: this.props.currentSlidePosition,
          position: this.props.currentSlidePosition,
          modalType: `${modalType}Modal`
        },
        // onSubmitSlide: this.props.onSubmitSlide,
        // currentSlidePosition: this.props.currentSlidePosition,
      });
    }
  };
  ensureInputBarModalsClosed = () => {
    this.props.closeModal();
    this.setOpenMenu(InputBarOpenMenu.NONE);
    this.setState({
      inputBarShortcutMode: InputBarShortcutMode.Position,
      inputBarMenuImage: '/icons/nav/settings.svg',
    });
  }

  /**
   * Get the menu items for the add (+) slide vertical menu.
   */
  getSlideMenus = () => {
    const slideMenus = [];

    const slideMenuItem = {
      icon: '/icons/nav/exit/alternative.svg',
      sortIndex: 10,
      label: '',
      modal: '',
      className: 'innerInputMenuLargeIcon noBottomMargin color-primary-500-svg',
      containerClassName: '',
      detailItemClassName: '',
      handler: (slideMenuItem: any) => {
        this.props.setOpenMenu(InputBarOpenMenu.NONE);
        this.props.closeModal();
      },
    };
    slideMenus.push(slideMenuItem);
    return slideMenus;
  };

  availableHeaderInfo = (): HeaderSlideAvailableTypeInfo => this.props.getAvailableHeaderLevelsForCurrentSlidePosition && this.props.getAvailableHeaderLevelsForCurrentSlidePosition();

  handlePaste = async (e: any) => {
    const { items } = e.clipboardData;
    const item = items[items.length - 1];
    if (item && /image/.test(item.type)) {
      let blob = item.getAsFile();
      // Verify the size:
      const sizeInMB = Math.round(blob.size / 1048576);

      if(sizeInMB <= 10) {
        const mediaFile = await ensureImageFileOrientation(blob);
        const file: ImageFile = {
          id: v4(),
          name: 'name',
          url: URL.createObjectURL(mediaFile),
          dataUrl: mediaFile,
          processingId: v4(),
          type: 'UPLOAD',
          caption: '',
        };
        this.props.openModal({
          modalType: 'ImageSlideEditor',
          modalProps: {
            file,
            currentUser: this.props.currentUser,
            article: this.props.article,
            position: this.props.currentSlidePosition,
            onSubmitSlide: this.props.onSubmitSlide,
          },
        });
      } else {
        this.props.showNotification({
          message: `The file size of ${sizeInMB}MB is too big. NOTE: When using the clipboard, files can be much larger than their original size in chromium browsers. See: https://bugs.chromium.org/p/chromium/issues/detail?id=361145`,
          title: "SIZE LIMIT EXCEEDED: ",
          type: NotificationType.ERROR,
          timeoutMilliseconds: 5000
        });
      }
    }
  };
  handleCameraClicked = () => {
    if(!this.props.currentUser) {
      this.props.showSignup();
      return;
    }
    if(this.props.ensureInputBarModalsClosedOnFocus) {
      this.ensureInputBarModalsClosed();
    }
    this.openSlideModal('IMAGECAMERA',  this.props.article);
  };
  /**
   * Adusts the input bar height based on the scroll height.
   * Stops at a max height of
   */
  setInputBarHeight = (input: any, value?: any) => {
    setTimeout(() => {
      // This line is necessary to force the height back
      input.style.cssText = 'height:24px; padding:0';
      if (value) {
        input.style.cssText = 'height:' + value + 'px';
      } else {
        input.style.cssText = 'height:' + input.scrollHeight + 'px';
      }
    }, 0);
  };

  onInputChanged = (value: string) => {
    this.setState({ inputBarValue: value });
  };

  inputBarConfirmClicked = () => {
    const content = this.state.inputBarValue;
    if (!hasValue(content)) {
      alert('Please enter text first.');
      return;
    }
    if (!this.props.displayToolBar) {
      this.props.toggleToolBarDisplay();
    }
  };

  isUserEnteringMultiSlideInput = (parts: string[], isText: boolean): boolean => {
    return (this.props.slideType === 'HEADER' || this.state.inputBarShortcutMode === InputBarShortcutMode.Header || isText) &&
    this.state.inputBarShortcutMode !== InputBarShortcutMode.Collapse && parts.length > 1
  }

  handleInputBarConfirmClicked = async () => {
    if(!this.props.currentUser) {
      this.props.showSignup();
      return;
    }
    const content = this.state.inputBarValue;
    if (!hasValue(content)) {
      this.props.showNotification({message: 'Please enter text first.', type: NotificationType.ERROR});
      return;
    }

    if (this.props.textSlideEditing) {
      let slide = {
        slideType: this.props.textSlideEditing.slideType,
        data: {
          ...this.props.textSlideEditing.data,
          body: content, // [{ type: 'paragraph', children: [{text: content}] }], // set it to JSON, if you want to use the "new" Slate Rich Text Editor.
          fullEditor: true,
        },
      };
      const response = await agent.Slides.update(
        this.props.textSlideEditing.id,
        slide,
      );
      const payload = {
        slide: response.slide,
        preventScrollToSlide:
          !isNil(this.props.currentSlidePosition) &&
          this.props.numberOfSlides !== this.props.currentSlidePosition,
      };
      this.props.onSubmitSlide(payload);
      this.setState({
        inputBarValue: '',
        inputBarShortcutMode: InputBarShortcutMode.Position,
        currentHeaderLevel: HeaderLevel.UNSET,
        inputBarMenuImage: '/icons/nav/settings.svg',
      });
      if (!this.props.displayToolBar) {
        this.props.toggleToolBarDisplay();
      }
    } else if (!isNullOrUndefined(this.props.article)) {
      const isText =
        isNullOrUndefined(this.props.slideType) &&
        this.state.inputBarShortcutMode !== InputBarShortcutMode.Header;
      // For Collapse we don't allow multiple slide inserts at the same time.
      let parts = content ? content.split('\n') : [];
      if (this.isUserEnteringMultiSlideInput(parts, isText)) {
        const headerLevel = this.state.currentHeaderLevel;
        const slide = {
          position: this.props.currentSlidePosition,
          slideType: isText ? 'TEXT' : 'HEADER',
          data: isText
            ? {
              body: content,
              values: parts,
            }
            : {
              level: headerLevel,
              values: parts,
            },
        };
        const response = await agent.Slides.createMultipleSlides(
          this.props.article,
          slide,
        );
        const slidesToAdd: Slide[] = response.slides;
        const multiSlideInsertDetails: MultiSlideInsertParameters =
         {
          slidesToInsert: slidesToAdd,
          insertedPosition: !isNullOrUndefined(this.props.currentSlidePosition)
            ? this.props.currentSlidePosition
            : this.props.numberOfSlides,
          slideCount: parts.length,
        };
        this.props.onSubmitMultipleSlides(multiSlideInsertDetails);
      } else {
        let slide;
        if (
          this.props.slideType === 'HEADER' ||
          this.state.inputBarShortcutMode === InputBarShortcutMode.Header
        ) {
          slide = {
            position: this.props.currentSlidePosition,
            slideType: 'HEADER',
            data: {
              level: this.state.currentHeaderLevel,
              title: content,
            },
          };
        } else {
          // Determine if this is a link.
          const isValidUrl = isValidURL(content as string);
          if (isValidUrl) {
            const linkFileDetails = await getLinkPreviewDetails(content as string);
            slide = {
              slideType: 'LINKS',
              position: this.props.currentSlidePosition,
              data: {
                files: [{
                  url: linkFileDetails.url,
                  title: linkFileDetails.title,
                  image: linkFileDetails.image,
                  description: linkFileDetails.description,
                  type: 'URL',
                  displayMode: HyperLinkLayout.BlogImageLeft
                }],
              }
            }
          } else {
            slide = {
              position: this.props.currentSlidePosition,
              slideType: 'TEXT',
              data: {
                body: content, // [{ type: 'paragraph', children: [{text: content}] }], // set it to JSON, if you want to use the "new" Slate Rich Text Editor.
                fullEditor: true,
              },
            };
          }
        }
        const response = await agent.Slides.create(this.props.article, slide);
        const payload = {
          slide: response.slide,
          preventScrollToSlide:
            !isNil(this.props.currentSlidePosition) &&
            this.props.numberOfSlides !== this.props.currentSlidePosition,
        };
        this.props.onSubmitSlide(payload);
      }
      this.setState({
        inputBarValue: '',
        pendingInputBarText: '',
        inputBarShortcutMode: InputBarShortcutMode.Position,
        currentHeaderLevel: HeaderLevel.UNSET,
        inputBarMenuImage: '/icons/nav/settings.svg',
        focusInputBar: {},
      });
      if (!this.props.displayToolBar) {
        this.props.toggleToolBarDisplay();
      }
    }
  };

  handleFocusSlideNumber = (ev: any) => {
    ev.target.select();
  };

  closeSlideTypeChooserModal = () => {
    this.props.closeModal();
    this.setOpenMenu(InputBarOpenMenu.NONE);
  }

  headerItemPicker = () => <Picker
    pickerItems={
      [
        {icon: icons.ContentAlteration_RichText_Header_H5, label: "Header 5", value: HeaderMenuOptions.H5},
        {icon: icons.ContentAlteration_RichText_Header_End, label: "Header end", value: HeaderMenuOptions.HE},
        {icon: icons.ContentAlteration_RichText_Header_Modal, label: "Header Slide", value: HeaderMenuOptions.HeaderModal},
        {icon: icons.Nav_Settings, label: "settings", value: HeaderMenuOptions.Settings},
        {icon: icons.ContentAlteration_RichText_Header_H4, label: "Header 4", value: HeaderMenuOptions.H4},
        {icon: icons.ContentAlteration_RichText_Header_H3, label: "Header 3", value: HeaderMenuOptions.H3},
        {icon: icons.ContentAlteration_RichText_Header_H2, label: "Header 2", value: HeaderMenuOptions.H2},
        {icon: icons.ContentAlteration_RichText_Header_H1, label: "Header 1", value: HeaderMenuOptions.H1},
      ]
    }
    displayMode={PickerDisplayMode.CircularTextOutsideUnder}
    selectedValue={this.state.currentHeaderLevel !== HeaderLevel.UNSET ? this.state.currentHeaderLevel : -1}
    onChange={(value: number) => {
      switch(value) {
        case HeaderMenuOptions.H5:
        case HeaderMenuOptions.H4:
        case HeaderMenuOptions.H3:
        case HeaderMenuOptions.H2:
        case HeaderMenuOptions.H1:
          this.onHeaderItemSelected({
            menuImageSourceOnSelection: `/icons/slidetype/header/h${value}.svg`,
            headerLevel: value
          });
          // this.props.closeModal();
          // No need to navigate back because selecting the item focuses the input bar which closes the modal.
          break;
        case HeaderMenuOptions.HeaderModal:
          const availableHeaderInfo = this.availableHeaderInfo();
          this.props.closeModal();
          setTimeout(() => {
            this.props.openModal({
              modalType: 'HeaderModal',
              modalProps: {
                article: this.props.article,
                submitSlide: this.props.onSubmitSlide,
                currentSlidePosition: this.props.currentSlidePosition,
                availableHeaderLevels: availableHeaderInfo.headerLevels,
                showNotification: this.props.showNotification
              },
            });
          }, 500)
          break;
        case HeaderMenuOptions.HE:
          this.showHeaderEndModal();
          break;
        default:
          break;
      }
    }}
    // itemWidth={90}
    itemCSSClass={'pickerItem'}
    itemsPerRow={4}
    enabledValues={[...this.availableHeaderInfo().headerLevels, HeaderMenuOptions.HeaderModal, HeaderMenuOptions.Settings, this.availableHeaderInfo().canAddHeaderEnd ? HeaderMenuOptions.HE : -1]}
  />;

 
  onSelectSlideType = async() => {
    if(!this.props.currentUser) {
      this.props.showSignup();
      return;
    }
    this.props.closeModal(['QuickSlideTypeChooser']);
    this.setOpenMenu(InputBarOpenMenu.NONE);
    this.setState({
      inputBarShortcutMode: InputBarShortcutMode.Position,
      inputBarMenuImage: '/icons/nav/settings.svg',
    });

    let article = this.props.article;
    if (!this.props.article && this.props.createArticle) {
      article = await this.props.createArticle();
    }
    this.openSlideMenu();
    const className = this.props.showLeftSidebar && this.props.showRightSidebar
    ? ' showLeftSidebar showRightSidebar'
    : this.props.showLeftSidebar
      ? ' showLeftSidebar'
      : this.props.showRightSidebar
        ? ' showRightSidebar'
        : undefined;

    this.props.openModal({
      modalType: 'NewSlideTypeChooserModal',
      modalProps: {
        closeModal: this.closeSlideTypeChooserModal,
        openModal: this.props.openModal,
        onSubmitSlide: this.props.onSubmitSlide,
        showNotification: this.props.showNotification,
        headerOptionPicker: this.headerItemPicker,
        mode: this.state.currentHeaderLevel !== HeaderLevel.UNSET ? SlideTypeFilter.Header : SlideTypeFilter.SimpleText,
        currentUser: this.props.currentUser,
        slidePosition: this.props.currentSlidePosition,
        article: article,
        className: className,
        pendingInputBarText: this.state.pendingInputBarText,
        onReset: (closeModal: boolean) => {
          this.setState({currentHeaderLevel : HeaderLevel.UNSET});
          if(closeModal) {
            this.props.closeModal();
          }
        }
      }
    });
  };

  closeSlideMenu = () => {
    if(!this.props.currentUser) {
      this.props.showSignup();
      return;
    }
    this.props.setOpenMenu(InputBarOpenMenu.NONE);
    this.props.closeModal();
  };

  handleSlideNumberClicked = (displaySlidePosition: number) => {
    if(!this.props.currentUser) {
      this.props.showSignup();
      return;
    }
    if (this.props.article) {
      if(this.props.ensureInputBarModalsClosedOnFocus) {
        this.ensureInputBarModalsClosed();
      }

      this.props.openModal({
        modalType: 'SlideNumberModal',
        modalProps: {
          slideNumber: displaySlidePosition,
          lastPosition: this.props.numberOfSlides + 1,
          updateSlideNumber: this.props.updateSlideNumber,
          closeModal: this.props.closeModal,
          scrollToSlidePosition: this.props.scrollToSlidePosition,
        },
      });
    }
  };

  render() {
    const displaySlidePosition = !isNil(this.props.currentSlidePosition)
      ? this.props.currentSlidePosition + 1
      : this.props.numberOfSlides + 1;

    const classes = classNames(
      'footer',
      this.props.displayToolBar ? 'toolbarOn' : 'toolbarOff',
      this.props.openMenu === InputBarOpenMenu.SLIDE
        || this.props.openMenu === InputBarOpenMenu.HEADER ? 'showOverModalBackdrop' : '',
     );

    const isNewGide = this.props.numberOfSlides < 1;

    return (
      <div className={classes}>
        <span className="flexRowFullCenter footerMenu">
          <InputBarNew
            slideCount={this.props.numberOfSlides}
            currentSlidePosition={this.props.currentSlidePosition}
            inputBarState={this.props.openMenu}
            inputBarValue={this.state.inputBarValue}
            onAcceptInput={() => this.handleInputBarConfirmClicked()}
            onChange={(value) => {
              this.setState({
                pendingInputBarText: value,
              });
              this.onInputChanged(value);
            }}
            onOpenInputBarSlideMenu={this.onSelectSlideType}
            onCloseInputBarSlideMenu={this.closeSlideMenu}
            onInputEditorFocus={this.onInputFocus}
            focusInputBar={this.state.focusInputBar}
            blurInputBar={this.state.blurInputBar}
            placeholderText={ this.props.placeholderText
              ? this.props.placeholderText
              : isNewGide || !this.props.article
                ? 'Create a Gide...'
                : 'Next please...'
            }
            onChangeHeaderLevel={() => {/** TODO: Make it so that clicking on the header scrolls through to the available header levels based on the context. */}}
            onPaste={this.handlePaste}
            onCameraClicked={this.handleCameraClicked}
            onEmojiClicked={() => this.props.showNotification({
              title: 'Coming Soon!',
              message: 'Emojis are coming soon',
              type: NotificationType.INFO
            })}
            onSlideNumberClicked={() => {
              if(!this.props.currentUser) {
                this.props.showSignup();
                return;
              }
              if (this.props.article) {
                if(this.props.ensureInputBarModalsClosedOnFocus) {
                  this.ensureInputBarModalsClosed();
                }

                this.props.openModal({
                  modalType: 'SlideNumberModal',
                  modalProps: {
                    slideNumber: displaySlidePosition,
                    lastPosition: this.props.numberOfSlides + 1,
                    updateSlideNumber: this.props.updateSlideNumber,
                    closeModal: this.props.closeModal,
                    scrollToSlidePosition: this.props.scrollToSlidePosition,
                  },
                });
              }
            }}
            displayElipsisOnLostFocus={this.props.openMenu !== InputBarOpenMenu.NONE}
            disabled={isNil(this.props.currentUser)}
            onSelectDisabled={() => this.props.showSignup()}
            currentHeaderLevel={this.state.currentHeaderLevel}
          />
        </span>
      </div>
    );
  }
}
