import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import agent from '../../agent';
import { store, history } from '../../store';
import { Loader } from 'semantic-ui-react';
import {
  slideContainsOverlayItem,
  slideHasParentHeader,
  getParentHeaderSlide,
  slideIsEmbeddedGide,
  slideContainsInfoPanelItem,
  getHeaderSlideTocLabelMap,
  getSlidesThatAreNotCollapsed,
  urlForArticle,
  getHeaderRange,
  hasChildArticleSlideTypes,
} from '../../utils/helperFunctions';
import {
  ARTICLE_PAGE_LOADED,
  ARTICLE_PAGE_ARTICLE_LOADED,
  ARTICLE_PAGE_SLIDES_LOADED,
  SLIDE_PAGE_UNLOADED,
  SET_VIEW_MODE,
  MODAL_OPEN,
  SET_NEXT_VIEW_MODE,
  SET_ZOOM,
  SET_CHROME_VISIBILITY,
  SET_OVERLAY_ITEM_VISIBILITY,
  SET_SWIPE_SLIDE_POSITION,
  SET_ARTICLE_PERCENT_COMPLETE,
  COLLAPSE_SLIDES,
  SET_TOASTER_MESSAGE,
} from '../../constants/actionTypes';
import { NotificationType } from '../../constants/strings';
import { isNullOrUndefined } from 'util';
import { SwipeView } from './SwipeView/SwipeView';

const mapStateToProps = (state, ownProps) => {
  const slideNumber = ownProps.match.params.number;
  let slide;
  let displaySlidesNotCollapsed;
  let displaySlides;
  let collapsedSlides;
  if (
    state.article &&
    state.article.slides &&
    state.article.slides[slideNumber - 1]
  ) {
    slide = state.article.slides[slideNumber - 1];
    // displaySlides don't contain COLLAPSE, COLUMN or HEADER END slides
    displaySlides = state.article.slides
      .filter(
        s =>
          s.slideType !== 'COLLAPSE' &&
          s.slideType !== 'COLUMN' &&
          (s.slideType !== 'HEADER' ||
            (s.slideType === 'HEADER' && s.data.type !== 'END')),
      )
      .sort(function(a, b) {
        return a.position - b.position;
      })
      .map((s, idx) => {
        return { slideIndex: idx, slide: s };
      });
    collapsedSlides = state.article.collapsedSlides;
    // get all of the displaySlides that are not collapsed
    displaySlidesNotCollapsed = getSlidesThatAreNotCollapsed(
      displaySlides.map(ds => ds.slide),
      collapsedSlides,
    );
  }
  return {
    slideNumber,
    article: state.article.article,
    displaySlides: displaySlides,
    displaySlidesNotCollapsed: displaySlidesNotCollapsed,
    slides: state.article.slides,
    collapsedSlides: collapsedSlides,
    slide,
    currentUser: state.common.currentUser,
    viewMode: state.common.viewMode,
    nextViewMode: state.common.nextViewMode,
    zoomed: state.common.zoomed,
    showChrome: state.common.showChrome,
    showCaptionPanel: state.common.showCaptionPanel,
    showInfoPanel: state.common.showInfoPanel,
    toggleOverlayCount: state.common.toggleOverlayCount,
    percentArticleCompleted: state.article.percentArticleCompleted,
    childArticleEditInfo: state.article.childArticleEditInfo,
  };
};

const mapDispatchToProps = dispatch => ({
  openModal: payload => dispatch({ type: MODAL_OPEN, payload }),
  onSetViewMode: mode => dispatch({ type: SET_VIEW_MODE, mode }),
  onSetChromeVisibility: payload =>
    dispatch({ type: SET_CHROME_VISIBILITY, payload }),
  onSetOverlayItemsVisibility: payload =>
    dispatch({ type: SET_OVERLAY_ITEM_VISIBILITY, payload }),
  onLoad: payload => dispatch({ type: ARTICLE_PAGE_LOADED, payload }),
  onLoadArticle: payload =>
    dispatch({ type: ARTICLE_PAGE_ARTICLE_LOADED, payload }),
  onLoadSlides: payload =>
    dispatch({ type: ARTICLE_PAGE_SLIDES_LOADED, payload }),
  onUnload: () => dispatch({ type: SLIDE_PAGE_UNLOADED }),
  setNextViewMode: mode => dispatch({ type: SET_NEXT_VIEW_MODE, mode }),
  resetZoom: () => dispatch({ type: SET_ZOOM, zoom: false }),
  setSwipeSlidePosition: payload =>
    dispatch({ type: SET_SWIPE_SLIDE_POSITION, payload }),
  setArticlePercentCompleted: payload =>
    dispatch({ type: SET_ARTICLE_PERCENT_COMPLETE, payload }),
  collapseSlides: slide => {
    dispatch({ type: COLLAPSE_SLIDES, slide: slide });
  },
  showNotification: payload => dispatch({ type: SET_TOASTER_MESSAGE, payload }),
});

class Slide extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileNum: 0,
      touchingStartedAt: new Date(), // To determine if the user is tapping or zooming.
      touchX: 0,
      touchY: 0,
      // These state variables are needed to navigate through an embedded gide and still maintain the header
      // navigation and overlay functionality
      continueEmbeddedGide: true,
      embeddedGide: null,
      embeddedGideSlide: null,
      filteredEmbeddedGideSlides: [],
      allEmbeddedGideSlides: [],
      initialized: false,
      loading: false,
    };

    this.props.onSetViewMode('SWIPE');

    // Initialize the overlay settings
    this.props.onSetOverlayItemsVisibility({
      showChrome: true,
      showCaptionPanel: slideContainsOverlayItem(this.props.slide),
      showInfoPanel: slideContainsInfoPanelItem(
        this.props.slide,
        this.props.displaySlidesNotCollapsed,
      ),
    });

    this.percentComplete = (slideIndex, slideCount) => {
      const slideNumber = slideIndex + 1;
      const percentCompleted = slideCount > 0 ? slideNumber / slideCount : 0;
      return Math.round(percentCompleted * 100);
    };
    this.onBack = () => {
      const nextViewMode = this.props.nextViewMode || 'SLIDE';
      this.props.onSetViewMode(nextViewMode);
      history.push(
        `${urlForArticle(this.props.article)}/?slide=${this.props.slideNumber}`,
      );
    };

    this.previousSlide = () => {
      this.props.resetZoom();
      let prevSlideNumber; // = slideNumber - 1;
      const previousSlides = this.props.displaySlidesNotCollapsed.filter(
        s => s.position < this.props.slide.position,
      );
      if (previousSlides.length > 0) {
        prevSlideNumber =
          previousSlides[previousSlides.length - 1].position + 1;
      } else {
        prevSlideNumber =
          this.props.displaySlidesNotCollapsed[
            this.props.displaySlidesNotCollapsed.length - 1
          ].position + 1;
      }

      history.push(
        `${urlForArticle(this.props.article)}/slide/${prevSlideNumber}`,
      );
      window.scrollTo(0, 0);

      const previousSlide = this.props.displaySlidesNotCollapsed[
        prevSlideNumber - 1
      ];
      const isEmbeddedGide = slideIsEmbeddedGide(previousSlide);

      // If chrome is currently turned off and the new slide is an embedded gide, then turn it back on.
      if (this.props.showChrome === false && isEmbeddedGide) {
        this.props.onSetOverlayItemsVisibility({
          showChrome: true,
          showCaptionPanel: slideContainsOverlayItem(previousSlide),
          showInfoPanel: slideContainsInfoPanelItem(
            previousSlide,
            this.props.displaySlidesNotCollapsed,
          ),
        });
      } else {
        this.props.onSetOverlayItemsVisibility({
          showCaptionPanel: slideContainsOverlayItem(previousSlide),
          showInfoPanel:
            isEmbeddedGide ||
            (this.props.showChrome &&
              slideHasParentHeader(
                previousSlide,
                this.props.displaySlidesNotCollapsed,
              )),
        });
        this.setState({ fileNum: 0 });
      }
      this.props.setSwipeSlidePosition({ slidePosition: prevSlideNumber });
      const currentDisplaySlide = this.props.displaySlides
        ? this.props.displaySlides.find(
            ds => ds.slide.position === prevSlideNumber - 1,
          )
        : null;
      const slideNumber = currentDisplaySlide
        ? currentDisplaySlide.slideIndex + 1
        : 0;
      const percentCompleted =
        this.props.displaySlides.length > 0 && currentDisplaySlide
          ? slideNumber / this.props.displaySlides.length
          : 0;
      this.props.setArticlePercentCompleted({
        percentCompleted: Math.round(percentCompleted * 100),
      });
    };

    this.getNextSlide = (
      slide,
      displaySlidesNotCollapsed,
      returnToBeginning,
    ) => {
      if (displaySlidesNotCollapsed.length < 1) {
        return null;
      }
      const nextSlides = displaySlidesNotCollapsed.filter(
        s => s.position > slide.position,
      );
      if (nextSlides.length > 0) {
        return nextSlides[0];
      } else if (returnToBeginning) {
        return displaySlidesNotCollapsed[0];
      } else {
        return null;
      }
    };
    this.getPreviousSlide = (slide, displaySlidesNotCollapsed, returnToEnd) => {
      if (displaySlidesNotCollapsed.length < 1) {
        return null;
      }
      const previousSlides = displaySlidesNotCollapsed.filter(
        s => s.position < slide.position,
      );
      if (previousSlides.length > 0) {
        return previousSlides[previousSlides.length - 1];
      } else if (returnToEnd) {
        return displaySlidesNotCollapsed[displaySlidesNotCollapsed.length - 1];
      } else {
        return null;
      }
    };

    this.nextSlide = () => {
      this.props.resetZoom();
      let nextSlideNumber;
      // Cant just add one because collapsed slides should not be navigated to.
      const nextSlides = this.props.displaySlidesNotCollapsed.filter(
        s => s.position > this.props.slide.position,
      );
      if (nextSlides.length > 0) {
        nextSlideNumber = nextSlides[0].position + 1;
      } else {
        nextSlideNumber = this.props.displaySlidesNotCollapsed[0].position + 1;
      }

      history.push(
        `${urlForArticle(this.props.article)}/slide/${nextSlideNumber}`,
      );
      window.scrollTo(0, 0);

      const nextSlide = this.props.displaySlidesNotCollapsed[
        nextSlideNumber - 1
      ];
      const isEmbeddedGide = slideIsEmbeddedGide(nextSlide);

      // If chrome is currently turned off and the new slide is an embedded gide, then turn it back on.
      if (this.props.showChrome === false && isEmbeddedGide) {
        this.props.onSetOverlayItemsVisibility({
          showChrome: true,
          showCaptionPanel: slideContainsOverlayItem(nextSlide),
          showInfoPanel: slideContainsInfoPanelItem(
            nextSlide,
            this.props.displaySlidesNotCollapsed,
          ),
        });
      } else {
        this.props.onSetOverlayItemsVisibility({
          showCaptionPanel: slideContainsOverlayItem(nextSlide),
          showInfoPanel:
            isEmbeddedGide ||
            (this.props.showChrome &&
              slideHasParentHeader(
                nextSlide,
                this.props.displaySlidesNotCollapsed,
              )),
        });
        this.setState({ fileNum: 0 });
      }
      this.props.setSwipeSlidePosition({ slidePosition: nextSlideNumber });
      const currentDisplaySlide = this.props.displaySlides
        ? this.props.displaySlides.find(
            ds => ds.slide.position === nextSlideNumber - 1,
          )
        : null;
      const slideNumber = currentDisplaySlide
        ? currentDisplaySlide.slideIndex + 1
        : 0;
      const percentCompleted =
        this.props.displaySlides.length > 0 && currentDisplaySlide
          ? slideNumber / this.props.displaySlides.length
          : 0;
      this.props.setArticlePercentCompleted({
        percentCompleted: Math.round(percentCompleted * 100),
      });
    };

    this.goToSlide = position => {
      history.push(`${urlForArticle(this.props.article)}/slide/${position}`);
      window.scrollTo(0, 0);

      const nextSlide = this.props.displaySlidesNotCollapsed[position - 1];
      const isEmbeddedGide = slideIsEmbeddedGide(nextSlide);
      // If chrome is currently turned off and the new slide is an embedded gide, then turn it back on.
      if (this.props.showChrome === false && isEmbeddedGide) {
        this.props.onSetOverlayItemsVisibility({
          showChrome: true,
          showCaptionPanel: slideContainsOverlayItem(nextSlide),
          showInfoPanel: slideContainsInfoPanelItem(
            nextSlide,
            this.props.displaySlidesNotCollapsed,
          ),
        });
      } else {
        this.props.onSetOverlayItemsVisibility({
          showCaptionPanel: slideContainsOverlayItem(nextSlide),
          showInfoPanel:
            isEmbeddedGide ||
            (this.props.showChrome &&
              slideHasParentHeader(
                nextSlide,
                this.props.displaySlidesNotCollapsed,
              )),
        });
        this.setState({
          fileNum: 0,
        });
      }
      this.props.setSwipeSlidePosition({ slidePosition: position });
      const currentDisplaySlide = this.props.displaySlides
        ? this.props.displaySlides.find(
            ds => ds.slide.position === position + 1,
          )
        : null;
      const slideNumber = currentDisplaySlide
        ? currentDisplaySlide.slideIndex + 1
        : 0;
      const percentCompleted =
        this.props.displaySlides.length > 0 && currentDisplaySlide
          ? slideNumber / this.props.displaySlides.length
          : 0;
      this.props.setArticlePercentCompleted({
        percentCompleted: Math.round(percentCompleted * 100),
      });
    };

    this.getPreviousHeaderSlide = (slide, displaySlidesNotCollapsed) => {
      if (slide) {
        const previousHeaderSlides = displaySlidesNotCollapsed.filter(
          s => s.slideType === 'HEADER' && s.position < slide.position,
        );
        if (previousHeaderSlides && previousHeaderSlides.length > 0) {
          return previousHeaderSlides[previousHeaderSlides.length - 1];
        } else {
          // In this case there is no header slide before the current header slide so go to the very last header slide if
          // there is one since we are navigating backwards
          const followingHeaderSlides = displaySlidesNotCollapsed.filter(
            s => s.slideType === 'HEADER' && s.position > slide.position,
          );
          if (followingHeaderSlides && followingHeaderSlides.length > 0) {
            return followingHeaderSlides[followingHeaderSlides.length - 1];
          }
        }
      }
      return null;
    };

    this.getNextHeaderSlide = (slide, displaySlidesNotCollapsed) => {
      if (slide) {
        const followingHeaderSlides = displaySlidesNotCollapsed.filter(
          s => s.slideType === 'HEADER' && s.position > slide.position,
        );
        if (followingHeaderSlides && followingHeaderSlides.length > 0) {
          return followingHeaderSlides[0];
        } else {
          // In this case there are no header slides after the current header slide so get the very first slide if
          // there is one since we are navigating forward
          const previousHeaderSlides = displaySlidesNotCollapsed.filter(
            s => s.slideType === 'HEADER' && s.position < slide.position,
          );
          if (previousHeaderSlides && previousHeaderSlides.length > 0) {
            return previousHeaderSlides[0];
          }
        }
      }
      return null;
    };

    this.onDistribute = () => {
      this.props.openModal({
        modalType: 'DistributeModal',
      });
    };

    this.onPlayCaption = e => {
      e.stopPropagation();
    };

    this.handleAudioEnded = () => {
      if (this.props.slide && this.props.slide.autoAdvanceSlide) {
        this.onNext();
      }
    };

    /**
     * When user taps screen or clicks on the overlay icon in the header, this method is called
     * to hadle setting which overlays should be on or off.
     *
     * If this action was initiated from the Gide Header toggle icon, we are notified by this from a change in props
     * 'toggleOverlayCount' in componentDidUpdate which then calls this method.
     */
    this.onOverlay = ev => {
      const {
        showChrome,
        showCaptionPanel,
        slide,
        displaySlidesNotCollapsed,
      } = this.props;
      const isEmbeddedGide = slideIsEmbeddedGide(slide);
      if (isEmbeddedGide) {
        this.props.onSetChromeVisibility({
          showChrome,
          showCaptionPanel,
          isEmbeddedGide: true,
          slide: this.state.embeddedGideSlide,
          displaySlidesNotCollapsed: this.state.allEmbeddedGideSlides,
        });
      } else {
        this.props.onSetChromeVisibility({
          showChrome,
          isEmbeddedGide: false,
          showCaptionPanel,
          slide,
          displaySlidesNotCollapsed,
        });
      }
      if (ev) {
        ev.stopPropagation();
      }
    };

    this.onNextHeader = () => {
      const { slide, displaySlidesNotCollapsed } = this.props;
      const { embeddedGide } = this.state;
      const nextHeaderSlide = this.getNextHeaderSlide(
        slide,
        displaySlidesNotCollapsed,
      );
      if (!isNullOrUndefined(nextHeaderSlide)) {
        if (!isNullOrUndefined(embeddedGide)) {
          this.setState({ embeddedGideSlide: nextHeaderSlide });
        } else {
          this.goToSlide(nextHeaderSlide.position + 1);
          this.props.onSetOverlayItemsVisibility({ showInfoPanel: false });
        }
      }
    };

    this.onPreviousHeader = () => {
      const { slide, displaySlidesNotCollapsed } = this.props;
      const { embeddedGide } = this.state;
      const previousHeaderSlide = this.getPreviousHeaderSlide(
        slide,
        displaySlidesNotCollapsed,
      );
      if (!isNullOrUndefined(previousHeaderSlide)) {
        if (!isNullOrUndefined(embeddedGide)) {
          this.setState({ embeddedGideSlide: previousHeaderSlide });
        } else {
          this.goToSlide(previousHeaderSlide.position + 1);
        }
      }
    };

    this.onPrev = async () => {
      const { slide } = this.props;
      const { fileNum } = this.state;
      if (
        slide &&
        ((slide.slideType === 'IMAGE' || slide.slideType === 'VIDEO') &&
          slide.data.files)
      ) {
        if (fileNum > 0) {
          this.setState(state => ({ fileNum: state.fileNum - 1 }));
        } else {
          this.previousSlide();
        }
      } else if (
        slide &&
        slide.slideType === 'GIDE' &&
        slide.data.embed === true
      ) {
        if (
          this.state.embeddedGide &&
          this.state.embeddedGide.id === slide.data.gide.id
        ) {
          const embeddedGideSlide = this.getPreviousSlide(
            this.state.embeddedGideSlide,
            this.state.filteredEmbeddedGideSlides,
          );
          // Since calling setState triggers a re-render, need to consider a better way to do this if we need to exit the
          // end of the embedded gide.
          this.setState({ embeddedGideSlide: embeddedGideSlide });
          if (isNullOrUndefined(embeddedGideSlide)) {
            // TODO: May want to keep the embedded gide in state in case it is re-navigated to so that it
            // won't have to make a service call to re-get the slides since it already has them. For now just re-get.
            this.setState({
              embeddedGide: null,
              embeddedGideSlide: null,
              allEmbeddedGideSlides: [],
              filteredEmbeddedGideSlides: [],
            });
            // When navigating backwards and we are not in the embedded gide, but the previous slide from the
            // primary list of slides is an embedded gide, go back to the embedded gide slide itself.
            // Don't want to start on the last slide of the embedded gide
            this.goToSlide(this.props.slide.position + 1);
          } else {
            // Navigate back to the previous slide in the embedded gide.
            this.setState({ embeddedGideSlide: embeddedGideSlide });
            this.props.onSetOverlayItemsVisibility({
              showCaptionPanel: slideContainsOverlayItem(embeddedGideSlide),
              showInfoPanel: true,
            });
          }
        } else {
          this.previousSlide();
        }
      } else {
        this.previousSlide();
      }
    };

    this.onNext = async () => {
      const { slide } = this.props;
      const { fileNum } = this.state;
      if (
        slide &&
        ((slide.slideType === 'IMAGE' ||
          slide.slideType === 'VIDEO' ||
          slide) &&
          slide.data.files)
      ) {
        if (fileNum < slide.data.files.length - 1) {
          this.setState(state => ({ fileNum: state.fileNum + 1 }));
        } else {
          this.nextSlide();
        }
      } else if (
        slide &&
        slide.slideType === 'GIDE' &&
        slide.data.embed === true
      ) {
        try {
          if (
            !this.state.embeddedGide ||
            this.state.embeddedGide.id !== slide.data.gide.id
          ) {
            let resp = await agent.Slides.forArticle(slide.data.gide);
            let displaySlidesForEmbeddedGide = resp.slides
              .filter(
                s =>
                  s.slideType !== 'COLLAPSE' &&
                  s.slideType !== 'COLUMN' &&
                  (s.slideType !== 'HEADER' ||
                    (s.slideType === 'HEADER' && s.data.type !== 'END')),
              )
              .sort(function(a, b) {
                return a.position - b.position;
              })
              .map((s, idx) => {
                return { slideIndex: idx, slide: s };
              });
            this.setState({
              embeddedGide: slide.data.gide,
              embeddedGideSlide: resp.slides[0],
              allEmbeddedGideSlides: resp.slides,
              displaySlidesForEmbeddedGide: displaySlidesForEmbeddedGide,
              filteredEmbeddedGideSlides: getSlidesThatAreNotCollapsed(
                displaySlidesForEmbeddedGide.map(ds => ds.slide),
                this.props.collapsedSlides,
              ),
            });
            this.props.onSetOverlayItemsVisibility({
              showCaptionPanel: slideContainsOverlayItem(resp.slides[0]),
              showInfoPanel: true,
            });
          } else {
            const embeddedGideSlide = this.getNextSlide(
              this.state.embeddedGideSlide,
              this.state.filteredEmbeddedGideSlides,
            );
            // Since calling setState triggers a re-render, need to consider a better way to do this if we need to exit the
            // end of the embedded gide.
            this.setState({ embeddedGideSlide: embeddedGideSlide });
            if (isNullOrUndefined(embeddedGideSlide)) {
              // TODO: May want to keep the embedded gide in state in case it is re-navigated to so that it
              // won't have to make a service call to re-get the slides since it already has them. For now just re-get.
              this.setState({
                embeddedGide: null,
                embeddedGideSlide: null,
                allEmbeddedGideSlides: [],
                filteredEmbeddedGideSlides: [],
              });
              this.nextSlide(); // Go to the next slide after the embedded gide. (Exit embedded)
            } else {
              this.setState({
                embeddedGideSlide: embeddedGideSlide,
              }); // Go to the next slide in the embedded gide
              this.props.onSetOverlayItemsVisibility({
                showCaptionPanel: slideContainsOverlayItem(embeddedGideSlide),
                showInfoPanel: true,
              });
            }
          }
        } catch (e) {
          console.log('error', e);
        }
      } else {
        this.nextSlide();
      }
    };

    this.onTouchStart = ev => {
      const touchX = ev.changedTouches[0].pageX;
      const touchY = ev.changedTouches[0].pageY;
      this.setState({ touchingStartedAt: new Date(), touchX, touchY });
    };
    this.onTouchEnd = ev => {
      const startTime = this.state.touchingStartedAt;
      const endTime = new Date();
      // Use logic to determine if it was a swipe or a click.
      // Check both the duration height and the distance traveled.
      const duration = Math.abs(endTime.getTime() - startTime.getTime());
      const distance = Math.abs(ev.changedTouches[0].pageX - this.state.touchX);
      const height = Math.abs(ev.changedTouches[0].pageY - this.state.touchY);
      if (duration < 120 && distance < 150 && height < 100) {
        this.onOverlay(ev);
      }
    };

    this.handleSettingsPressed = (ev, slide) => {
      this.props.openModal({
        modalType: 'SlideSettingsModal',
        modalProps: {
          slide: slide,
        },
      });
      ev.stopPropagation();
      return true;
    };
    this.onScrollModeClick = this._onScrollModeClick.bind(this);
    this.onSwipeModeClick = this._onSwipeModeClick.bind(this);
    this.onSlideModeClick = this._onSlideModeClick.bind(this);
    this.handleKeyDown = this._handleKeyDown.bind(this);
    this.swipedLeft = this._swipedLeft.bind(this);
    this.swipedRight = this._swipedRight.bind(this);
    this.purchase = this._purchase.bind(this);
    this.load = this._load.bind(this);
    this.onCollapseSlide = slide => {
      this.props.collapseSlides(slide);
    };
    this.getHeaderSlideCount = (headerSlide, slides, displaySlides) => {
      // need to get the slides in a headerSlide's range,
      // need to pass in slides, which has all of the slides for a gide,
      // because we need the COLUMN and END slides to get the range for the headerSlide
      // these have been filtered out of the displaySlides
      const headerRange = getHeaderRange(headerSlide, slides);
      // the displaySlides that have a position in the headerRange
      if (headerRange) {
        const displaySlidesInHeaderRange = displaySlides.filter(
          ds =>
            headerRange.startPosition < ds.slide.position &&
            (!headerRange.endPosition ||
              headerRange.endPosition > ds.slide.position),
        );
        return displaySlidesInHeaderRange
          ? displaySlidesInHeaderRange.length
          : 0;
      }
      return 0;
    };
  }

  _purchase() {
    const { article } = this.props;
    this.setState({ loading: true });
    agent.Articles.purchase(article).then(resp => {
      if (resp.success) {
        window.setTimeout(() => {
          this.load();
          this.props.showNotification({
            toasterMessageInfo: {
              message: `Purchased.`,
              type: NotificationType.INFO,
            },
          });
        }, 2000);
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false });
        this.props.showNotification({
          toasterMessageInfo: {
            message: resp.msg || `Sorry, please try again.`,
            type: NotificationType.INFO,
          },
        });
      }
    });
  }

  _handleKeyDown(e) {
    if (e.keyCode === 37 || e.keyCode === 33) {
      e.preventDefault();
      this.onPrev();
    }
    if (e.keyCode === 39 || e.keyCode === 34) {
      e.preventDefault();
      this.onNext();
    }
    if (e.keyCode === 27) {
      this.props.onSetOverlayItemsVisibility({
        showChrome: true,
        showCaptionPanel: true,
        showInfoPanel: true,
      });
      this.onBack();
    }
    if (e.keyCode === 66) {
      this.onOverlay(e);
    }
  }

  _onSlideModeClick() {
    this.props.onSetViewMode('SLIDE');
    store.dispatch(
      push(`/gide/${this.props.article.slug}/?slide=${this.props.slideNumber}`),
    );
  }

  _onScrollModeClick() {
    this.props.onSetViewMode('SCROLL');
    store.dispatch(
      push(`/gide/${this.props.article.slug}/?slide=${this.props.slideNumber}`),
    );
  }

  _onSwipeModeClick() {
    this.props.onSetViewMode('SWIPE');
    store.dispatch(push(`/gide/${this.props.article.slug}/slide/1`));
  }

  _swipedLeft(e, absX) {
    e.stopPropagation();
    this.onNext();
  }

  _swipedRight(e, absX) {
    e.stopPropagation();
    this.onPrev();
  }

  async _load(username, slug) {
    const { slide, displaySlidesNotCollapsed } = this.props;
    if (!slide) {
      let articleResp;
      if (this.props.match.params.id) {
        articleResp = await agent.Articles.get(this.props.match.params.id);
      } else if (
        this.props.match.params.username &&
        this.props.match.params.slug
      ) {
        const username = this.props.match.params.username;
        const slug = this.props.match.params.slug;
        articleResp = await agent.Articles.getByUsernameAndSlug(username, slug);
      }
      this.props.onLoadArticle(articleResp);

      let resp;
      try {
        resp = await agent.Slides.forArticle(articleResp.article);
      } catch (err) {
        this.props.showNotification({
          toasterMessageInfo: {
            message:  err, // `Purchase required.`,
            type: NotificationType.INFO,
          },
        });
        this.setState({ loading: false });
      }
      if (resp) {
        this.props.onLoadSlides(resp.slides);
        this.setState({ loading: false });
        // this.props.onLoad(
        //   Promise.all([
        //     articleResp,
        //     resp,
        //   ]),
        // );
      }
    } else {
      // Call to initialize Chrome when user is in swipe and then refreshes the browser.
      // Passing in false as the current value so it will toggle it to true in reducer state.
      if (!this.state.initialized) {
        this.props.onSetChromeVisibility({
          showChrome: false,
          showCaptionPanel: false,
          slide,
          displaySlidesNotCollapsed,
        });
        this.setState({ initialized: true, loading: false });
      }
      this.props.onLoad();
    }
  }

  componentWillMount() {
    document.addEventListener('keydown', this.handleKeyDown);
    this.load();
  }
  componentDidMount() {
    const slideViewContainer = document.getElementById('slideViewContainer');
    if (!isNullOrUndefined(slideViewContainer)) {
      slideViewContainer.addEventListener('touchstart', this.onTouchStart, {
        passive: true,
      });
      slideViewContainer.addEventListener('touchend', this.onTouchStart, {
        passive: true,
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.slide !== prevProps.slide ||
      (prevProps.match &&
        prevProps.match.params.articleId !== this.props.match.params.articleId)
    ) {
      this.load(prevProps.match.params.articleId);
    } else {
      // To handle the case when the user clicks the overlay button on the Gide Bar (Header)
      if (this.props.toggleOverlayCount !== prevProps.toggleOverlayCount) {
        this.onOverlay();
      }
      // This will initially set the percent completed if it has not been set yet. It is cleared
      // in unmount using the SLIDE_PAGE_UNLOADED action in the atricle reducer.
      if (
        isNullOrUndefined(this.props.percentArticleCompleted) &&
        this.props.displaySlidesNotCollapsed
      ) {
        const currentDisplaySlide = this.props.displaySlides
          ? this.props.displaySlides.find(
              ds => ds.slide.position === this.props.slideNumber - 1,
            )
          : null;
        const slideNumber = currentDisplaySlide
          ? currentDisplaySlide.slideIndex + 1
          : 0;
        const percentCompleted =
          this.props.displaySlides &&
          this.props.displaySlides.length > 0 &&
          currentDisplaySlide
            ? slideNumber / this.props.displaySlides.length
            : 0;
        this.props.setArticlePercentCompleted({
          percentCompleted: Math.round(percentCompleted * 100),
        });
      }

      // Handle the case when the user collapses a embedded gide header
      if (
        !isNullOrUndefined(this.state.embeddedGide) &&
        !isNullOrUndefined(this.props.collapsedSlides) &&
        (this.props.collapsedSlides !== prevProps.collapsedSlides ||
          this.props.collapsedSlides.length !==
            prevProps.collapsedSlides.length)
      ) {
        this.setState({
          filteredEmbeddedGideSlides: getSlidesThatAreNotCollapsed(
            this.state.displaySlidesForEmbeddedGide.map(ds => ds.slide),
            this.props.collapsedSlides,
          ),
        });
      }
    }
  }

  // Determine whether to hide or show the Caption Panel
  showCaptionPanel(slide, showChrome, showPanel) {
    return slide && ((showChrome && showPanel) || showPanel);
  }
  // Determine whether to hide or show the Info Panel
  showInfoPanel(slide, showChrome, showPanel) {
    return slide && ((showChrome && showPanel) || showPanel);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    const slideViewContainer = document.getElementById('slideViewContainer');
    if (!isNullOrUndefined(slideViewContainer)) {
      slideViewContainer.removeEventListener('touchstart', this.onTouchStart);
      slideViewContainer.addEventListener('touchend', this.onTouchStart);
    }
    this.props.onUnload();
  }

  render() {
    // const headerSlides = this.props.slides ? this.props.slides.filter(s => s.slideType === 'HEADER') : null;
    const {
      showChrome,
      collapsedSlides,
      childArticleEditInfo,
      article,
    } = this.props;
    const { loading } = this.state;
    const displaySlides = !isNullOrUndefined(this.state.embeddedGideSlide)
      ? this.state.displaySlidesForEmbeddedGide
      : this.props.displaySlides;
    const slides = !isNullOrUndefined(this.state.embeddedGideSlide)
      ? this.state.allEmbeddedGideSlides
      : this.props.slides;
    const filteredSlides = !isNullOrUndefined(this.state.embeddedGideSlide)
      ? this.state.filteredEmbeddedGideSlides
      : this.props.displaySlidesNotCollapsed;
    const headerSlides = filteredSlides
      ? filteredSlides.filter(
          s =>
            s.slideType === 'HEADER' && (!s.data.type || s.data.type !== 'END'),
        )
      : [];
    const primarySlide = this.props.slide;
    const slide = !isNullOrUndefined(this.state.embeddedGideSlide)
      ? this.state.embeddedGideSlide
      : this.props.slide;
    const headerSlideMap = headerSlides
      ? getHeaderSlideTocLabelMap(headerSlides)
      : {};

    // Need to handle the case when the Info Panel is visible because the content
    // is centered vertically based on a calculation of the available vertical height.
    // The caption area is not relevant here because it has a fixed position and overlays
    // the screen. The Info Panel however takes up space and the amount of space depends
    // on a few things.
    // 1. Is this an embedded gide slide?
    // 2. Does the slide have a parent header?
    const parentHeader = getParentHeaderSlide(slide, filteredSlides);
    const headerIsCollapsed =
      slide &&
      collapsedSlides &&
      slide.slideType === 'HEADER' &&
      !isNullOrUndefined(collapsedSlides.find(s => s.id === slide.id));
    const previousHeaderSection = this.getPreviousHeaderSlide(
      slide,
      headerSlides,
    );
    const nextHeaderSection = this.getNextHeaderSlide(slide, headerSlides);
    const numberOfSlidesUnderCurrentHeader =
      slide && slide.slideType === 'HEADER'
        ? this.getHeaderSlideCount(slide, slides, displaySlides)
        : 0;
    const indexDisplaySlide =
      displaySlides && displaySlides.length > 0
        ? displaySlides.find(ds => ds.slide.id === slide.id)
        : null;
    const slideNumber = indexDisplaySlide ? indexDisplaySlide.slideIndex : 0;
    const percentEmbeddedGideCompleted =
      slide &&
      primarySlide.slideType === 'GIDE' &&
      indexDisplaySlide &&
      filteredSlides.length > 0
        ? this.percentComplete(slideNumber, filteredSlides.length)
        : 0;

    return (
      <div style={{ height: '100%' }}>
        {loading && (
          <div style={{ textAlign: 'center', padding: '10px 0' }}>
            <Loader active inline="centered" />
          </div>
        )}
        {!loading &&
          slide && (
            <span>
              <SwipeView
                article={article}
                slide={slide}
                currentUser={this.props.currentUser}
                primarySlide={primarySlide}
                parentHeader={parentHeader}
                headerIsCollapsed={headerIsCollapsed}
                headerSlideLabel={
                  headerSlideMap ? headerSlideMap[slide.id] : ''
                }
                canNavigateToPreviousHeader={
                  previousHeaderSection &&
                  headerSlideMap[previousHeaderSection.id]
                }
                previousHeaderSection={
                  previousHeaderSection
                    ? headerSlideMap[previousHeaderSection.id]
                    : ''
                }
                nextHeaderSection={
                  nextHeaderSection ? headerSlideMap[nextHeaderSection.id] : ''
                }
                numberOfSlidesUnderCurrentHeader={
                  numberOfSlidesUnderCurrentHeader
                }
                onCollapseSlide={this.onCollapseSlide}
                onClickPreviousHeaderSlide={this.onPreviousHeader}
                onClickNextHeaderSlide={this.onNextHeader}
                showChrome={showChrome}
                displayViewBar={true}
                appName={this.props.appName}
                viewMode={this.props.viewMode}
                nextViewMode={this.props.nextViewMode}
                percentArticleCompleted={this.props.percentArticleCompleted}
                percentEmbeddedGideCompleted={percentEmbeddedGideCompleted}
                fileNum={this.state.fileNum}
                slug={this.props.slug}
                hasChildArticleSlideTypes={hasChildArticleSlideTypes(
                  slide.childArticlesSlideTypes,
                )}
                getNextSlide={this.onNext}
                getPreviousSlide={this.onPrev}
                swipedLeft={this.swipedLeft}
                swipedRight={this.swipedRight}
                onTouchStart={this.onTouchStart}
                onTouchEnd={this.onTouchEnd}
                handleSettingsPressed={e => {
                  if (!isNullOrUndefined(childArticleEditInfo)) {
                    this.handleSettingsPressed(e, slide);
                  }
                }}
                handleAudioEnded={this.handleAudioEnded.bind(this)}
                onSetViewMode={this.props.onSetViewMode}
                setNextViewMode={this.props.setNextViewMode}
                setLoading={this.props.setLoading}
              />
            </span>
          )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Slide);
