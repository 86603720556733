import { 
  SlideEditorModalProps, 
  BackIconMode, 
  EditorState, 
  ModalOverlayState, 
} from '../../modals/SlideEditorModal/SlideEditorModal';
import { lightTheme, darkTheme } from '../../../themes/custom-theme';
import { 
  CommonContext, 
  CommonDerivedState, 
  getHeaderTitleAndIcon, 
  getSlideView,
  getFileView,
} from './Common';

const getSlideEditorModalProps = (context: CommonContext): SlideEditorModalProps => {
  const { commonSlideEditorModalProps, isLinkSlide } = context;

  return {
    ...commonSlideEditorModalProps,
    backIconMode: isLinkSlide ? BackIconMode.Light : BackIconMode.Dark,
    hideFooter: true,
    hideCommandBar: false,
    editorState: EditorState.Edit,
    loadingInfo: undefined,
    modalOverlayState: ModalOverlayState.None,
    selectedItemIndex: context.state.selectedItemIndex,
    hideActionContainer: true,
    showBackNavigation: true,
    showDefaultActions: false,
    createActions: [] as any,
    headerActions: getHeaderTitleAndIcon('Audio', '/icons/slidetype/audio/main.svg', isLinkSlide ? 'black' : 'white', 'blue'),
    onNavigateBack: commonSlideEditorModalProps.onHideAudioCaptionControls,
    showAudioCaptionRecorder: {},
  };
};

const getContent = (context: CommonContext, slideEditorModalProps: SlideEditorModalProps) => {  
  if (context.state.selectedItemIndex > -1) {
    const fileView = getFileView(context);
    return fileView;
  } else {
    const slideView = getSlideView(context.state.slide);
    return slideView;
  }
};

export const getDerivedState = (context: CommonContext): CommonDerivedState => {
  const slideEditorModalProps = getSlideEditorModalProps(context);
  const content = getContent(context, slideEditorModalProps);
  return {
    ...context.state,
    slideEditorModalProps,
    content,
    theme: context.isLinkSlide ? lightTheme : darkTheme,
  };
};

export const Audio = {
  getSlideEditorModalProps,
  getContent,
  getDerivedState,
};
