import React, { useState } from 'react';
import styles from './_account-profile.module.scss';
import { User } from '../../models/User';
import { RoundedCornerButton } from '../Shared/RoundedCornerButton/RoundedCornerButton';
import icons from '../../assets/icons';
import classNames from 'classnames';
import TabMenu from '../Shared/TabMenu/TabMenu';
import GidePreviewList from '../Home/GidePreviewList/GidePreviewList';
import { ChannelList } from '../ChannelList';
import { Profile } from '../../models/Profile';
import { LabeledTextInput } from '../DataEntry/CreationProcess/LabeledTextInput/LabeledTextInput';
import { ToasterMessageInfo } from '../../models/ToasterMessageInfo';
import { NotificationType } from '../../constants/strings';
import { NewChannelButton } from '../Channel/NewChannelButton/NewChannelButton';
import { Channel } from '../../models/Channel';

export interface AccountProfileProps {
  profile: Profile;
  channels: Channel[];
  currentUser?: User | null;
  selectedActivityTab?: number;
  onActivityTabChanged?: (tabIndex: number) => void;
  onNavigateToChannel: (slug: string) => void;
  onNavigateToSettings: () => void;
  onNavigateToMyGide: (myGideSlug: string) => void;
  onContactUser: (message: string, toUsername: string, fromUsername: string) => void;
  onCreateNewChannel: () => void;
  showNotification: (toasterMessageInfo?: ToasterMessageInfo) => void;
}

export default function AccountProfile(props: AccountProfileProps) {
  const [selectedTab, setSelectedTab] = useState<number>(props.selectedActivityTab ? props.selectedActivityTab : 0);
  const isUser = props.currentUser && props.currentUser.username === props.profile.username;
  const [displayContactUser, setDisplayContactUser] = useState<boolean>(false);
  const [contactInfo, setContactInfo] = useState<string>('');
  const name =
    props.profile.firstName && props.profile.secondName ? `${props.profile.firstName} ${props.profile.secondName}` : props.profile.username;

  return (
    <div className={styles.profileContainer}>
      <div className={styles.profileHeader}>
        <img
          className={styles.profileBrandingImage}
          src={
            props.profile.brandingImage
              ? props.profile.brandingImage
              : 'https://images.unsplash.com/photo-1471553342576-d066fea8dd03?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80'
          }
        />

        <img src={props.profile.image} className={styles.avatar} />
      </div>
      <div className={styles.profileDescription}>
        <span className={styles.title}>{name}</span>
        <span className={styles.accountType}>• {props.profile.business ? 'Business' : 'Person'}</span>
        <div className={styles.descriptionContainer}>
          <div className={classNames(styles.description, isUser ? styles.isUser : styles.isViewer)}>{props.profile.bio}</div>
          <div className={classNames(styles.actions, isUser ? styles.isUser : styles.isViewer)}>
            {/* <Link to={urlForArticle({ slug: props.profile.myGideSlug, author: { username: props.profile.username } })}>
              <RoundedCornerButton
                label="About me"
                style={{ backgroundColor: 'var(--COLOR-SECONDARY-200)', width: '113px' }}
                labelColor="var(--COLOR-SECONDARY-500)"
                icon={<icons.Nav_Mygide color="var(--COLOR-SECONDARY-500)" />}
                imagePosition="right"
                onClick={() => props.onNavigateToMyGide(props.profile.myGideSlug)}
              />
            </Link> */}
            <RoundedCornerButton
              label="Contact me"
              style={{ backgroundColor: 'var(--COLOR-SECONDARY-200)', marginLeft: '12px', width: '125px' }}
              labelColor="var(--COLOR-SECONDARY-500)"
              icon={<icons.SlideType_Contact_Main color="var(--COLOR-SECONDARY-500)" />}
              imagePosition="right"
              onClick={() => {
                if (props.profile.allowEmailContact && props.currentUser) {
                  setDisplayContactUser(true);
                } else {
                  props.showNotification({
                    message: props.currentUser ? ' This user does not allow email contact.' : ' You must be logged in to contact user.',
                    title: props.currentUser ? 'NO EMAIL CONTACT ALLOWED: ' : 'LOG IN TO CONTACT USER: ',
                    type: NotificationType.INFO,
                    timeoutMilliseconds: 4000,
                  });
                }
              }}
            />
            {isUser && (
              <RoundedCornerButton
                className={styles.settingsButton}
                label="Settings"
                style={{
                  backgroundImage: 'linear-gradient(291deg, var(--COLOR-PRIMARY-700) 102%, #fab317 29%)',
                  marginLeft: '12px',
                  width: '105px',
                }}
                icon={<icons.Nav_Settings color="var(--WHITES-NORMAL-1000)" />}
                imagePosition="right"
                onClick={props.onNavigateToSettings}
              />
            )}
          </div>
        </div>
      </div>
      {displayContactUser && props.currentUser && (
        <LabeledTextInput
          style={{ margin: '0 10px 0 10px' }}
          skipAcceptStep={true}
          onConfirm={(text: string) => {
            props.onContactUser(text, props.profile.username, props.currentUser ? props.currentUser.username : '');
            setDisplayContactUser(false);
          }}
          label=""
          sublabel=""
          placeholder="Enter a message"
          text={contactInfo}
          onClear={() => true}
          onAccept={async () => true}
          isAcceptable={() => true}
          theme="light"
        />
      )}
      <div className={styles.activityAndChannels}>
        <TabMenu
          style={{ maxWidth: '374px' }}
          selectedTab={selectedTab}
          tabStyle="default"
          tabs={[
            {
              label: 'Activity',
            },
            {
              label: 'Channels',
            },
          ]}
          onTabClicked={i => {
            setSelectedTab(i);
            props.onActivityTabChanged && props.onActivityTabChanged(i);
          }}
        />
        {selectedTab === 1 && props.currentUser && props.currentUser.username === props.profile.username && (
          <NewChannelButton onCreateNewChannel={props.onCreateNewChannel} />
        )}
        <div className={styles.listItems}>
          {selectedTab === 0 && props.profile.gides.length > 0 && 
            <GidePreviewList gideList={props.profile.gides} currentUser={props.currentUser} />
          }
          {selectedTab === 0 && props.profile.gides.length === 0 && 
            <span style={{marginLeft: '10px'}} className="TEXTSUBTITLEblackhigh-emphasiscenter">You have no recently published gides</span>
          }
          {selectedTab === 1 && (
            <ChannelList
              channels={props.channels}
              displayMode="tiles"
              onClick={(event: any, channelSlug: string) => {
                props.onNavigateToChannel(channelSlug);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
