import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { ReactComponent as LoadingIcon } from '../../../assets/loading.svg';
import { motion } from 'framer-motion';

export interface LoadingProps {
  style?: CSSProperties;
  circularSpinner?: boolean;
  widthAndHeight?: number;
  
}

export const Loading: React.FC<LoadingProps> = (props) => {
  if (props.circularSpinner) {
    return (
      <CircularProgress style={props.style} size={60} />
    );
  } else {
    return (
      <motion.div style={props.style}
        initial={{ '--loading-icon-rotate': '0deg' } as any}
        animate={{ '--loading-icon-rotate': '360deg' } as any}        
        transition={{
          duration: 2,
          // type: "tween",
          loop: Infinity,
        }}
      >
        <LoadingIcon width={props.widthAndHeight? props.widthAndHeight: 50} height={props.widthAndHeight ? props.widthAndHeight : 50} style={{
          transform: 'rotate(var(--loading-icon-rotate))',
        }} />
      </motion.div>
    );
  }
}

