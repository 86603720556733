import { 
  SlideEditorModalProps, 
  BackIconMode, 
  EditorState, 
  ModalOverlayState, 
} from '../../modals/SlideEditorModal/SlideEditorModal';
import { darkTheme } from '../../../themes/custom-theme';
import { 
  CommonContext, 
  CommonDerivedState, 
  getHeaderTitleAndIcon,
  CommonModes, 
} from './Common';
import { New } from './New';

const getSlideEditorModalProps = (context: CommonContext): SlideEditorModalProps => {
  const { state, setState, commonSlideEditorModalProps } = context;
  return {
    ...commonSlideEditorModalProps,
    backIconMode: BackIconMode.Dark,
    hideFooter: true,
    hideCommandBar: false,
    editorState: EditorState.New,
    loadingInfo: undefined,
    modalOverlayState: ModalOverlayState.None,
    selectedItemIndex: -1,
    hideActionContainer: true,
    showBackNavigation: true,
    showDefaultActions: false,
    createActions: [] as any,
    headerActions: getHeaderTitleAndIcon('Open camera', '/icons/content-alteration/camera/default.svg', 'white'),
    onNavigateBack: () => {
      const mode = !state.slide.data.files.length ? CommonModes.New 
        : state.selectedItemIndex > -1 ? CommonModes.Edit
        : CommonModes.Preview;
      const mediaChooserAction = mode === CommonModes.New ? { type: 'StopCamera' as 'StopCamera' } : undefined;
      setState(state => ({ ...state, mode, hiddenMediaChooserAction: undefined, mediaChooserAction }));
    },
  };
}

export const getDerivedState = (context: CommonContext): CommonDerivedState => {
  const slideEditorModalProps = getSlideEditorModalProps(context);
  const content = New.getContent(context);
  return {
    ...context.state,
    slideEditorModalProps,
    content,
    theme: darkTheme,
  };
};

export const Camera = {
  getSlideEditorModalProps,
  getContent: New.getContent,
  getDerivedState,
};
