import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Input,
  Dropdown,
  List,
  Image,
  Menu,
  Button,
} from 'semantic-ui-react';

import { history } from '../../store';
import agent from '../../agent';
import {
  MODAL_CLOSE,
  USERS_LOADED,
  ARTICLE_UPDATED,
  CHANNELS_LOADED,
  DELETE_ARTICLE,
} from '../../constants/actionTypes';
import slideTools from '../../slideTools';
import GideImage from '../Shared/Image/GideImage';

const mapStateToProps = (state, ownProps) => ({
  ...slideTools.mapStateToProps(state, ownProps),
  currentUser: state.common.currentUser,
  viewMode: state.common.viewMode,
  users: state.common.users,
  channels: state.common.channels,
  meta: state.common.meta,
});

const mapDispatchToProps = dispatch => ({
  closeModal: payload => dispatch({ type: MODAL_CLOSE }),
  onUsersLoaded: payload => dispatch({ type: USERS_LOADED, payload }),
  onSubmit: payload => {
    dispatch({ type: ARTICLE_UPDATED, payload });
  },
  onChannelsLoaded: payload => dispatch({ type: CHANNELS_LOADED, payload }),
  onDeleteArticle: payload => dispatch({ type: DELETE_ARTICLE, payload }),
});

export class DistributeModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userList: [],
    };

    this.state.type = props.type ? props.type : '';

    this.onCopyLinkClick = this._onCopyLinkClick.bind(this);

    this.onNew = () => {
      this.props.closeModal();
      history.push('/editor');
    };

    this.handleChangeChannel = (ev, data) => {
      const channel = data.value;
      const promise = agent.Articles.update(
        Object.assign(this.props.article, { channel }),
      );
      this.props.onSubmit(promise);
      window.setTimeout(() => {
        this.forceUpdate();
      }, 1000);
    };

    this.handleChangeUsers = (ev, data) => {
      this.setState({ userList: data.value });
    };

    this.handleAddUsers = () => {
      let updatedArticle = Object.assign(this.props.article, {
        userList: this.state.userList,
      });
      agent.Articles.update(updatedArticle).then(resp => {
        this.props.onSubmit(resp);
      });
      // this.props.onSubmit(promise);
      window.setTimeout(() => {
        this.forceUpdate();
      }, 1000);
      this.setState({ userList: [] });
    };
    this.removeUserHandler = () => {};
  }

  _getShareUrl() {
    if (!this.props.article) {
      return '';
    }
    const url = `http://www.gides/gide/${this.props.article.slug}`;
    return url;
  }

  _onCopyLinkClick() {
    // this._linkNode.focus();
    var textField = document.createElement('textarea');
    textField.innerText = `https://www.gides.com/gide/${
      this.props.article.slug
    }`;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();

    alert('Link copied to clipboard.');
  }

  componentWillMount() {
    this.props.onUsersLoaded(agent.Users.getAll());
    this.props.onChannelsLoaded(
      agent.Channels.byAuthor(this.props.currentUser.username)
    );
  }

  render() {
    const { users, channels, article, meta, currentUser } = this.props;
    const userChannels = channels ? channels.filter(c => c.author.username === this.props.currentUser.username) : undefined;

    if (!article) {
      this.props.closeModal();
      return null;
    }

    let isAuthor = false;
    if (currentUser) {
      if (article.author.username === currentUser.username) {
        isAuthor = true;
      }
    }
    let userOptions = [];
    if (users) {
      userOptions = users
        .filter(user => {
          return user.username !== this.props.currentUser.username;
        })
        .map(user => {
          return {
            text: user.username,
            username: user.username,
            value: user._id,
            image: {
              avatar: true,
              src: user.image,
            },
          };
        });
    }
    let channelOptions = [];
    if (userChannels) {
      channelOptions = userChannels.map(channel => {
        return {
          text: channel.title,
          value: channel._id,
        };
      });
    }

    let collectionOptions = [];
    collectionOptions = meta.collections.map(c => {
      return {
        text: c.title,
        value: c._id,
      };
    });
    return (
      <Modal
        closeOnEscape={true}
        onClose={this.props.closeModal}
        className="distributeModal"
        size="small"
        dimmer="inverted"
        closeIcon="close"
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <div className="modalHeader">
            <span>Distribute</span>
          </div>
          <div>
            <span style={{ padding: '20px' }}>
              <Button
                onClick={() => {
                  const confirmed = window.confirm('Are you sure?');
                  if (!confirmed) return;
                  this.props.onDeleteArticle(agent.Articles.del(article));
                }}
                content="Delete"
                icon="trash"
              />
            </span>
          </div>
          <Modal.Description>
            <h2>Save</h2>
            <div style={{ padding: '20px' }}>
              <h3>Collections</h3>
              <Dropdown
                placeholder="Select Collection"
                search
                fluid
                selection
                multiple
                options={collectionOptions}
              />
            </div>
            <h2>Send</h2>
            <div style={{ padding: '20px' }}>
              <fieldset>
                <h3>Users</h3>
                <Dropdown
                  value={this.state.userList}
                  placeholder="Select User(s)"
                  search
                  fluid
                  selection
                  multiple
                  options={userOptions}
                  onChange={this.handleChangeUsers}
                />
                {/*
                <Button
                  onClick={this.handleAddUsers}
                  content="Add"
                  icon="add"
                />
                */}
                <Menu compact>
                  <Dropdown text="Send" simple item closeOnChange>
                    <Dropdown.Menu>
                      <Dropdown.Item>Send GidePost</Dropdown.Item>
                      <Dropdown.Item>Create GideChat</Dropdown.Item>
                      <Dropdown.Item>Send in Message</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Menu>
              </fieldset>
              <List relaxed selection divided>
                {(article.userList || []).map((user, i) => {
                  return (
                    <List.Item key={i}>
                      <GideImage 
                        className={`svgIcon small pointer color-secondary-500-svg`}
                        src="/icons/content-alteration/delete.svg"
                        alt={`□`}
                        onClick={this.removeUserHandler(user)}
                      />
                      <Image avatar src={user.image} />
                      <List.Content>
                        <List.Header as="a">{user.username}</List.Header>
                      </List.Content>
                    </List.Item>
                  );
                })}
              </List>
              <h3>Email</h3>
              <p>Coming soon.</p>
            </div>

            <h2>Share</h2>
            <div style={{ padding: '20px' }}>
              {isAuthor && (
                <div>
                  <h3>Channel</h3>
                  <Dropdown
                    placeholder="Select Channel"
                    options={channelOptions}
                    search
                    fluid
                    selection
                    value={article.channel}
                    onChange={this.handleChangeChannel}
                  />
                </div>
              )}
              <h3
                className="link undelined"
                style={{ color: 'white' }}
                onClick={this.onCopyLinkClick}
              >
                Copy link
              </h3>
              <Input
                ref={n => (this._linkNode = n)}
                autoFocus
                fluid
                value={`https://www.gides.com/gide/${article.slug}`}
              />
              <Button onClick={this.onNew}>New Gide</Button>
            </div>
          </Modal.Description>
        </Modal.Content>
        {this.state.type !== '' && (
          <Modal.Actions>
            <span
              className="pointer backBtn"
              onClick={() => this.setState({ type: '' })}
            >
              Back
            </span>
          </Modal.Actions>
        )}
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DistributeModal);
