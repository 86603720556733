import React, { ReactNode, CSSProperties } from 'react';
import styles from './_picker-modal.module.scss';
import { RoundedCornerButton } from '../../../../Shared/RoundedCornerButton/RoundedCornerButton';
import { IconProps } from '../../../../../assets/icons';
import classNames from 'classnames';
export interface PickerModalProps {
  headerContent?: ReactNode;
  children?: ReactNode;
  hideActions?: boolean;  
  className?: string;
  cancelLabel?: string;
  saveLabel?: string;
  style?: CSSProperties;
  saveIcon?: (props: IconProps) => JSX.Element;
  onCancel: () => void;
  onSave: () => void;  
}

export default function PickerModal(props: PickerModalProps) {
  return (
    <div className={classNames(styles.pickerModalContainer, props.className)} style={props.style}>
      
      {props.headerContent && (
        <div className={styles.header}>
          {props.headerContent}
        </div>
      )}

      {props.children}

      {props.hideActions !== true && (<>
      
        <div className={styles.horizontalLine} /> 

        <div className={styles.actions}>
          {/* <RoundedCornerButton
            label={props.cancelLabel || "Cancel"}
            style={{
              backgroundColor: '#fafafa',
              marginRight: '14px',
              width: '79px',
              height: '32px'
            }}
            fontCssClass="TEXTSUBTITLEblackmedium-emphasisleft"
            onClick={props.onCancel}
          /> */}
          <RoundedCornerButton            
            imagePosition={'right'}
            label={props.saveLabel || "done"}
            fontCssClass="TEXTSUBTITLEwhitehigh-emphasisleft"
            style={{ 
              backgroundColor: 'var(--COLOR-PRIMARY-300)',
              marginRight: '12px',
              minWidth: '83px',
              height: '32px',
              borderRadius: '2px',
              width: '328px',
              alignSelf: 'center'
            }}
            onClick={props.onSave}
          />
        </div>
      </>)}
    </div>
  );
}
