import React, { Component } from 'react';
import { Loader } from 'semantic-ui-react';
import agent from '../../agent';
import { ArticlePreviewLayout } from '../../models/CommonEnums';
import ArticlePreview from '../Shared/Previews/ArticlePreview/ArticlePreview';
class BlogSlide extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      channel: null,
    };

    this.load = this._load.bind(this);
  }

  async _load() {
    try {
      let resp = await agent.Channels.getContent(this.props.channelSlug ? this.props.channelSlug : this.props.slide.data.channel.slug);
      this.setState({ channel: resp, loading: false });
    } catch (e) {
      this.setState({ loading: false });
      alert('Sorry, please try again later.');
    }
  }

  componentDidMount() {
    this.load();
  }

  render() {
    const { loading, channel } = this.state;

    return (
      <div className="channelSlide">
        {loading && <Loader active inline="centered" />}
        {/* {channel && <ArticleList articles={channel.content.articles} layout='BLOG' center={true} addHorizontalRule={true} />} */}
        {channel && channel.content.articles.map((article, index) => 
          <ArticlePreview
            style={{marginBottom: '10px'}}
            key={index}
            layout={ArticlePreviewLayout.BlogImageLeft}
            slug={article.slug}
            image={article.slideZero ? article.slideZero.data.image : article.image}
            title={article.slideZero ? article.slideZero.data.title : article.title}
            description={article.descriptionSlide ? article.descriptionSlide.data.body : article.description}
            author={article.author.username}
            authorImage={article.author.image}
            updatedAt={article.updatedAt}
            createdAt={article.createdAt}
            slideCount={article.slideCount}
            views={article.viewCount}
            isOfficalGide={true}
            language={article.language}

          />
        )}
      </div>
    );
  }
}
export default BlogSlide;
