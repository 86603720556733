import React, { Component, Dispatch } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Icon, Input } from 'semantic-ui-react';
import superagent from 'superagent';
import agent from '../../agent';
import {
  ADD_SLIDE,
  MODAL_OPEN,
  SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES,
} from '../../constants/actionTypes';
import SlideModalActions from './SlideModalActions';
import SlideSettings, { Settings } from '../SlideEditors/SlideSettings/SlideSettings';
import { getSlideSettings, updateSlideSettings } from '../SlideEditors/SlideSettings/SlideSettings';
import slideTools from '../../slideTools';
import { User } from '../../models/User';
import { GideAction } from '../../models/GideAction';
import { Slide, SlideModel } from '../../models/Slide';
import { Article } from '../../models/Article';
import { ChildArticleEditorInfo } from '../../models/ChildArticleEditorInfo';
import {
  getChildArticleTypeName,
  ChildArticleType,
} from '../../models/ArticleLayoutEnum';
import {
  Dictionary,
  SlideshowSlideInfo,
  SlideshowSlideDetails,
} from '../../models/SlideShowSlide';
import DropZone from 'react-dropzone';
import { API_ROOT } from '../../constants/paths';
import { isNullOrUndefined } from 'util';
import { SlideshowEditor } from '../Slides/SlideshowSlide/SlideshowEditor';
import { merge } from 'ramda';
import { ArticleAction } from '../../models/ArticleAction';
import GideImage from '../Shared/Image/GideImage';
import isNil from 'ramda/es/isNil';
import { history } from '../../store';

interface SlideshowModalProps {
  currentUser: User;
  editSlide: Slide;
  slide: Slide;
  mode: string;
  selection: string;
  position: number;
  currentSlidePosition: number;
  article: Article;
  childArticleEditInfo: ChildArticleEditorInfo;
  onSubmit: (payload: any) => void;
  onSubmitSlide?: (payload: any) => void;
  openModal: (payload: any) => void;
  updateSlideAttachmentInfo: (payload: any) => void;
}

interface SlideshowModalState {
  slide: SlideModel;
  showSettings: boolean;
  headerCaption: string;
  slideshowImage: string;
  audioTrack?: string;
  slideshowSlideArticle?: Article;
  slides?: Slide[];
  slideshowSlidesInfo: Dictionary<SlideshowSlideInfo>;
  slideshowSlideDetails: SlideshowSlideDetails;
}

const mapStateToProps = slideTools.mapStateToProps;

const mapDispatchToProps = (dispatch: Dispatch<GideAction | ArticleAction>) => ({
  onSubmit: (payload: any) => dispatch({ type: ADD_SLIDE, payload }),
  openModal: (payload: any) => dispatch({ type: MODAL_OPEN, payload }),
  updateSlideAttachmentInfo: (payload: any) =>
    dispatch({ type: SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES, payload }),
});

export class SlideshowModal extends Component<
  SlideshowModalProps,
  SlideshowModalState
> {
  constructor(props: SlideshowModalProps) {
    super(props);

    this.state = {
      slide: props.editSlide
        ? props.editSlide
        : {
            slideType: 'SLIDESHOW',
            data: {
              headerCaption: '',
              audioCaption: null,
              caption: '',
              audioTrack: null,
              slideshowImage: '',
              slideshowSlidesInfo: null,
              slideshowSlideDetails: {
                useAudioTrack: false,
                allowManualScrolling: false,
                showSlideshowControls: false,
                useTimeDuration: false,
                beginTime: null,
                endTime: null,
                duration: 0,
              },
            },
          },
      showSettings: false,
      headerCaption: props.editSlide ? props.editSlide.data.headerCaption : '',
      slideshowImage: props.editSlide
        ? props.editSlide.data.slideshowImage
        : '',
      audioTrack: props.editSlide ? props.editSlide.data.audioTrack : '',
      slideshowSlidesInfo: props.editSlide
        ? props.editSlide.data.slideshowSlidesInfo
        : {},
      slideshowSlideDetails:
        props.editSlide && props.editSlide.data.slideshowSlideDetails
          ? props.editSlide.data.slideshowSlideDetails
          : {},
    };
  }

  onUpdateSlideshowSlidesInfo = (
    slideId: string,
    slideshowSlideInfo: SlideshowSlideInfo,
  ) => {
    let slideshowSlidesInfo = merge([], this.state.slideshowSlidesInfo);
    slideshowSlidesInfo[slideId] = slideshowSlideInfo;
    this.setState({ slideshowSlidesInfo: slideshowSlidesInfo });
  };

  onUpdateSlideshowSlideDetails = (
    updatedSlideshowSlideDetails: SlideshowSlideDetails,
  ) => {
    let slideshowSlideDetails = merge(
      this.state.slideshowSlideDetails,
      updatedSlideshowSlideDetails,
    );
    this.setState({ slideshowSlideDetails });
  };

  updateSlideSettings(settings: Settings) {
    this.setState({ slide: updateSlideSettings(this.state.slide as Slide, settings) });
  }

  closeSettings() {
    this.setState({ showSettings: false });
  }

  audioCaptionChanged(audioFile: any) {
    const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
    slide.data.audioCaption = audioFile;
    this.setState({ slide });
  }

  onDropAudioTrack = (acceptedFiles: any) => {
    acceptedFiles.forEach((file: any) => {
      superagent
        .post(`${API_ROOT}/util/upload`)
        .attach('theseNamesMustMatch', file)
        .end((err: any, res: { body: { url: any } }) => {
          if (err) console.log(err);
          let f = {
            name: file.name,
            url: res.body.url,
          };
          this.setState({ audioTrack: f.url });
        });
    });
  };

  onRemoveSlideshowAudioTrack = () => {
    this.setState({ audioTrack: undefined });
  };

  onRemoveAudio(audioIdx: any) {
    const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
    let files = slide.data.files.slice();
    files.splice(audioIdx, 1);
    slide.data.files = files;
    this.setState({ slide });
  }

  onDropSlideshowImage(acceptedFiles: any) {
    acceptedFiles.forEach((file: any) => {
      superagent
        .post(`${API_ROOT}/util/upload`)
        .attach('theseNamesMustMatch', file)
        .end((err: any, res: { body: { url: any } }) => {
          if (err) console.log(err);
          let f = {
            name: file.name,
            url: res.body.url,
          };
          this.setState({ slideshowImage: f.url });
        });
    });
  }

  async createSlide() {
    let slidePosition = !isNil(this.props.currentSlidePosition) ? this.props.currentSlidePosition : this.props.position;
    let slide = {
      ...this.state.slide,
      slide: this.props.slide,
      createMode: this.props.mode,
      selection: this.props.selection,
      allowComments: this.props.editSlide
          ? this.state.slide.allowComments
          : this.props.article.allowSlideComments,
        allowQuestions: this.props.editSlide
          ? this.state.slide.allowQuestions
          : this.props.article.allowSlideQuestions,
      // If editing, don't change the position.
      position: this.props.editSlide
        ? this.props.editSlide.position
        : slidePosition,
    };
    slide.data.headerCaption = this.state.headerCaption;
    slide.data.slideshowImage = this.state.slideshowImage;
    slide.data.audioTrack = this.state.audioTrack;
    slide.data.slideshowSlidesInfo = this.state.slideshowSlidesInfo;
    slide.data.beginTime = this.state.slide.data.beginTime;
    slide.data.endTime = this.state.slide.data.endTime;
    slide.data.slideshowSlideDetails = this.state.slideshowSlideDetails;
    let payload;
    if (this.props.editSlide) {
      payload = await agent.Slides.update(this.props.editSlide.id, slide);
    } else {
      payload = await agent.Slides.create(this.props.article, slide);
      if (this.props.childArticleEditInfo) {
        slideTools.getSlideAttachmentInfo(
          this.props.childArticleEditInfo.ownerSlide.id,
          this.props.updateSlideAttachmentInfo,
        );
      }
    }
    this.props.onSubmitSlide ?
      this.props.onSubmitSlide({ ...payload, mode: this.props.mode }) :
    this.props.onSubmit({ ...payload, mode: this.props.mode });
    history.goBack()
  }

  async load() {
    if (!this.props.editSlide) {
      const slide = {
        ...this.state.slide,
        data: { ...this.state.slide.data },
      };
      this.setState({ slide });
    } else {
      const childArticleResp = await agent.Slides.getSettings(
        this.props.editSlide.id,
        getChildArticleTypeName(ChildArticleType.Slideshow),
      );
      const childArticleSlidesResp = await agent.Slides.forArticle(
        childArticleResp.article,
      );
      let slideshowSlidesInfo: Dictionary<SlideshowSlideInfo> = this.props
        .editSlide.data.slideshowSlidesInfo
        ? this.props.editSlide.data.slideshowSlidesInfo
        : {};
      childArticleSlidesResp.slides.forEach((ssSlide: Slide) => {
        if (!isNullOrUndefined(ssSlide) && !isNullOrUndefined(ssSlide.id)) {
          if (!slideshowSlidesInfo[ssSlide.id]) {
            slideshowSlidesInfo[ssSlide.id] = {
              startTimeInSeconds: 0,
            };
          }
        }
      });

      const sortedSlides = childArticleSlidesResp.slides
        ? childArticleSlidesResp.slides.sort((s1: Slide, s2: Slide) => {
            let s1StartTime = slideshowSlidesInfo[s1.id]
              ? slideshowSlidesInfo[s1.id].startTimeInSeconds
              : 0;
            let s2StartTime = slideshowSlidesInfo[s2.id]
              ? slideshowSlidesInfo[s2.id].startTimeInSeconds
              : 0;
            const timeDiff = s1StartTime - s2StartTime;
            return timeDiff !== 0 ? timeDiff : s1.position - s2.position;
          })
        : [];

      this.setState({
        slideshowSlideArticle: childArticleResp.article,
        slides: sortedSlides,
        slideshowSlidesInfo: slideshowSlidesInfo,
      });
    }
  }

  componentDidMount() {
    this.load();
  }

  render() {
    const { slide } = this.state;
    return (
      <Modal
        closeOnEscape={true}
        onClose={() => history.goBack()}
        className="slideSelectModal"
        size="small"
        dimmer="inverted"
        open={true}
        style={{ background: 'rgb(227, 99, 100)', height: '90%' }}
        closeOnDimmerClick={false}
      >
        <Modal.Content
          style={{ background: 'rgb(227, 99, 100)', height: '92%' }}
        >
          <div
            className="modalHeader"
            style={{ background: 'rgb(227, 99, 100)' }}
          >
            <Button id="modalClose" icon onClick={() => history.goBack()}>
              <Icon name="close" />
            </Button>
            <span>Slideshow Type</span>
            <GideImage src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-image.svg" />
          </div>
          {!this.state.showSettings && (
            <Modal.Description className="dataEntry" style={{ height: '100%' }}>
              <h3 style={{ color: 'white' }}>Slideshow Title:</h3>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {!this.state.slideshowImage && (
                  <DropZone
                    className="gideBarArticleNoImageDropZone "
                    accept="image/jpeg, image/png"
                    onDrop={accepted => this.onDropSlideshowImage(accepted)}
                    multiple={false}
                  >
                    <div className="gideBarArticleNoImage">
                      <GideImage src="/icons/slidetype/image/main.svg"
                        className="articleImage"
                        alt={`□`}
                      />
                      <GideImage src="/icons/nav/plusicon.svg"
                        className="addArticleImage"
                        alt={`□`}
                      />
                    </div>
                  </DropZone>
                )}
                {this.state.slideshowImage && (
                  <DropZone
                    className="gideBarArticleNoImageDropZone"
                    accept="image/jpeg, image/png"
                    onDrop={accepted => this.onDropSlideshowImage(accepted)}
                    multiple={false}
                  >
                    <div className="gideBarArticleImage">
                      <GideImage 
                        src={this.state.slideshowImage}
                        className="articleImage"
                        alt={`□`}
                      />
                    </div>
                  </DropZone>
                )}
                <div
                  style={{ width: '100%', height: '37px', marginLeft: '5px' }}
                >
                  <Input
                    fluid
                    value={this.state.headerCaption}
                    onChange={e =>
                      this.setState({ headerCaption: e.target.value })
                    }
                    autoFocus
                  />
                </div>
              </div>
              <SlideshowEditor
                slideshowAudioUrl={this.state.audioTrack}
                slideshowSlides={this.state.slides}
                slideshowSlidesInfo={this.state.slideshowSlidesInfo}
                slideshowSlideDetails={this.state.slideshowSlideDetails}
                onUpdateSlideshowSlidesInfo={this.onUpdateSlideshowSlidesInfo}
                onUpdateSlideshowSlideDetails={
                  this.onUpdateSlideshowSlideDetails
                }
                onDropAudioTrack={this.onDropAudioTrack}
                onRemoveSlideshowAudioTrack={this.onRemoveSlideshowAudioTrack}
              />
            </Modal.Description>
          )}
          {this.state.showSettings && (
            <Modal.Description className="settingsPanel">
              <SlideSettings
                hideTemplateIndicator={true}
                settings={getSlideSettings(
                  this.state.slide as Slide,
                  this.props.article.type,
                )}
                onSettingsChanged={this.updateSlideSettings.bind(this)}
                onCloseSettings={this.closeSettings.bind(this)}
              />
            </Modal.Description>
          )}
        </Modal.Content>
        <Modal.Actions>
          <SlideModalActions
            caption={slide.data.caption}
            captionChanged={(ev: any) => {
              this.setState({
                slide: {
                  ...slide,
                  data: {
                    ...slide.data,
                    caption: ev.target.value,
                  },
                },
              });
            }}
            audioCaption={slide.data.audioCaption}
            onAudioCaptionChanged={this.audioCaptionChanged.bind(this)}
            showSettings={this.state.showSettings}
            canNavigateBack={false}
            settingsClicked={() =>
              this.setState({ showSettings: !this.state.showSettings })
            }
            nextClicked={this.createSlide.bind(this)}
            settings={getSlideSettings(
              this.state.slide as Slide,
              this.props.article.type,
            )}
            onSettingsChanged={this.updateSlideSettings.bind(this)}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SlideshowModal);
