import React from 'react';

import Map from '../maps/Map';
import { history } from '../../store';
import { connect } from 'react-redux';
const mapStateToProps = state => ({
  ...state.article,
});

export class MapSlide extends React.Component {
  handlePointSelected(p, i) {
    const point = this.props.slide.data.points[i];
    if (!point.slide) return;
    this.props.slides.forEach((s, i) => {
      if (point.slide === s.id) {
        const num = i + 1;
        history.push('/gide/' + this.props.article.slug + '/slide/' + num);
      }
    });
  }
  render() {
    const { slide } = this.props;
    return (
      <div>
        {slide.data.title ? (
          <h4 style={{ textAlign: 'center' }}>{slide.data.title}</h4>
        ) : null}
        <Map
          mapId={slide.data.mapId}
          points={slide.data.points}
          showDirections={slide.data.createRouteMap}
          onPointClick={this.handlePointSelected.bind(this)}
          zoom={slide.data.zoom}
          lat={slide.data.lat}
          lng={slide.data.long || slide.data.lng}
          showTitle={slide.data.title ? true : false}
          options={{gestureHandling: 'cooperative'}}
          defaultMapTypeId={slide.data.mapTypeId ? slide.data.mapTypeId : 'roadmap'}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(MapSlide);
