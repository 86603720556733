import { MockGides } from './../testing/models/GideItems.mock';
import { Collection } from './Collection';
import { Article } from './Article';
import { Channel, MockChannels } from './Channel';

export interface Profile {
  id: string;
  username: string;
  email?: string;
  phone?: string;
  bio: string;
  image: string;
  password?: string;
  following: boolean;
  favorites: any[];
  connections?: {
    following: { _id: string; image: string; username: string }[];
    followers: { _id: string; image: string; username: string }[];
  };
  views: any[];
  verified: boolean;
  language?: string;
  country?: string;
  business: boolean;
  businessFocus?: string;
  public: boolean;
  myGideSlug: string;
  brandingImage?: string;
  firstName?: string;
  secondName?: string;
  channels: Channel[];
  gides: Article[];
  collections: Collection[];
  myGide?: Article;
  allowEmailContact?: boolean;
}

export interface ProfileUpdate {
  id: string;
  username: string;
  email?: string;
  phone?: string;
  bio: string;
  image: string;
  password?: string;
  language?: string;
  country?: string;
  business: boolean;
  businessFocus?: string;
  brandingImage?: string;
  firstName?: string;
  secondName?: string;
  allowEmailContact?: boolean;
}

export const getProfileUpdate = (profile: Profile): ProfileUpdate => {
  return {
    id: profile.id,
    username: profile.username,
    email: profile.email,
    phone: profile.phone,
    bio: profile.bio,
    image: profile.image,
    language: profile.language,
    country: profile.country,
    business: profile.business,
    businessFocus: profile.businessFocus,
    brandingImage: profile.brandingImage,
    firstName: profile.firstName,
    secondName: profile.secondName,
    allowEmailContact: profile.allowEmailContact,
  };
};

export const updateProfile = (profileUpdate: ProfileUpdate, profile: Profile) => {
  return {
    id: profileUpdate.id,
    username: profileUpdate.username,
    email: profileUpdate.email,
    phone: profileUpdate.phone,
    bio: profileUpdate.bio,
    image: profileUpdate.image,
    language: profileUpdate.language,
    country: profileUpdate.country,
    business: profileUpdate.business,
    businessFocus: profileUpdate.businessFocus,
    brandingImage: profileUpdate.brandingImage,
    firstName: profileUpdate.firstName,
    secondName: profileUpdate.secondName,
    allowEmailContact: profileUpdate.allowEmailContact,
    following: profile.following,
    favorites: profile.favorites,
    connections: profile.connections,
    views: profile.views,
    verified: profile.verified,
    public: profile.public,
    myGideSlug: profile.myGideSlug,
    channels: profile.channels,
    gides: profile.gides,
    collections: profile.collections,
    myGide: profile.myGide,
  };
};

export const MockProfile: Profile = {
  id: '5d42930d4310230016e1f799',
  username: 'janedoe@gides.com',
  bio: 'There are numerous success stories you will hear about businesses making it good on the internet . I will show you the way.',
  image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/4650404-blob',
  email: 'janedoe@gides.com',
  brandingImage:
    'https://images.unsplash.com/photo-1571501679680-de32f1e7aad4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2058&q=80',
  firstName: 'Jane',
  secondName: 'Doe',
  allowEmailContact: true,
  business: true,
  businessFocus: 'Cat walking',
  public: true,
  myGideSlug: 'mygide',
  following: true,
  favorites: [],
  connections: {
    following: [
      {
        image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/4650404-blob',
        _id: '5d1218c8fc272900161e5420',
        username: 'bjacobs1',
      },
      {
        image: 'https://static.productionready.io/images/smiley-cyrus.jpg',
        _id: '5dc37048de1d8c001683a202',
        username: 'daleb123',
      },
    ],
    followers: [
      {
        image: 'https://static.productionready.io/images/smiley-cyrus.jpg',
        _id: '5d0192dc59bf290016339fc9',
        username: 'grant',
      },
      {
        image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/4759705-blob',
        _id: '5d0901937ac2c600168a74d5',
        username: 'jeffme',
      },
      {
        image: 'https://static.productionready.io/images/smiley-cyrus.jpg',
        _id: '5d32083ebe6e860016e8faeb',
        username: 'mattnystrom',
      },
      {
        image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/3715598-blob',
        _id: '5d43431c64d40800169c955e',
        username: 'parttyboibates',
      },
      {
        image: 'https://static.productionready.io/images/smiley-cyrus.jpg',
        _id: '5e17a26bea79130016cbfaf7',
        username: 'lukeooooo',
      },
    ],
  },
  views: [],
  verified: true,
  language: 'en',
  gides: MockGides,
  channels: MockChannels,
  collections: [],
};
