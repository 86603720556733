import React, { useState, useEffect } from 'react';
import ResizeableModal from '../../modals/ResizeableModal/ResizeableModal';
import styles  from './_new-slide-type-chooser-modal.module.scss';
import icons from '../../../assets/icons';
import { slideEditorModalLookup } from '../../../utils/helperFunctions';
import { User } from '../../../models/User';
import { Article } from '../../../models/Article';
import { Slide } from '../../../models/Slide';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import Picker, { PickerDisplayMode } from '../../DataEntry/Pickers/Picker/Picker';
export interface  NewSlideTypeChooserModalProps {
  closeModal: () => void;
  openModal: (payload: {modalType: string, modalProps: any}) => void;
  onSubmitSlide: (slide: Slide) => void;
  showNotification: (toasterMessageInfo: ToasterMessageInfo) => void;
  headerOptionPicker: () => JSX.Element;
  mode: SlideTypeFilter;
  currentUser: User;
  article: Article;
  slidePosition: number;
  className?: string;
  pendingInputBarText: string;
  onReset: (closeModal: boolean) => void;
}

export const AllSlideTypes = (props: {openModal: (slideType: string) => void}) => <>
  <icons.SlideType_Image_ProductIcon
    style={{width: '70px', height: '70px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal('Image');
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Video_ProductIcon
    style={{width: '70px', height: '70px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal("Video");
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Audio_ProductIcon
    style={{width: '70px', height: '70px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal("Audio");
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Link_ProductIcon
    style={{width: '70px', height: '70px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal("Link");
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Gallery_ProductIcon
    style={{width: '70px', height: '70px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal('ImageGallery');
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Slideshow_ProductIcon
    style={{width: '70px', height: '70px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal('Slideshow');
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Location_ProductIcon
    style={{width: '70px', height: '70px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal('Location');
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Socialmedia_ProductIcon
    style={{width: '70px', height: '70px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal('Embed');
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Authorize_ProductIcon
    style={{width: '70px', height: '70px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal('Authorize');
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Doodle_ProductIcon
    style={{width: '70px', height: '70px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal('Doodle');
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Columns_ProductIcon
    style={{width: '70px', height: '70px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal('Column');
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_File_ProductIcon
    style={{width: '70px', height: '70px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal('File');
    }}
    className={styles.slideTypeItem}
  />

                          
  <span className="emptySpan" />
  <span className="emptySpan" />
  <span className="emptySpan" />
  <span className="emptySpan" />
  <span className="emptySpan" />
  <span className="emptySpan" />
  <span className="emptySpan" />
  <span className="emptySpan" />
  <span className="emptySpan" />
  <span className="emptySpan" />
</>;

export const LimitedSlideTypes = (props: {openModal: (slideType: string) => void}) => <>
  <icons.SlideType_Image_ProductIcon
    style={{width: '70px', height: '70px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal('Image');
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Video_ProductIcon
    style={{width: '70px', height: '70px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal("Video");
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Audio_ProductIcon
    style={{width: '70px', height: '70px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal("Audio");
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Link_ProductIcon
    style={{width: '70px', height: '70px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal("Link");
    }}
    className={styles.slideTypeItem}
  />
    <span className="emptySpan" />
    <span className="emptySpan" />
    <span className="emptySpan" />
    <span className="emptySpan" />
    <span className="emptySpan" />
    <span className="emptySpan" />
    <span className="emptySpan" />
    <span className="emptySpan" />
    <span className="emptySpan" />
    <span className="emptySpan" />
</>;

export enum SlideTypeFilter {
  SimpleText = 1,
  TextEditor = 2,
  List = 3,
  Header = 4,
  EndHeader = 5,
}

export default function NewSlideTypeChooserModal (props:  NewSlideTypeChooserModalProps) {
  const [mode, setMode] = useState<SlideTypeFilter>(props.mode);
  const [selectedSlideTypeFilter, setSelectedSlideTypeFilter] = useState<SlideTypeFilter>(SlideTypeFilter.SimpleText);
  useEffect(() => {
    if(mode === SlideTypeFilter.TextEditor) {
      openModal("RichTextSlideEditor");
    } else if(mode === SlideTypeFilter.List) {
      openModal("List")
    }
  }, [mode])
  const openModal = (modalType: string) => {
    props.closeModal();
    setTimeout(() => {
      if (slideEditorModalLookup[modalType]) {
        props.openModal({
          modalType: slideEditorModalLookup[modalType],
          modalProps: {
            currentUser: props.currentUser,
            article: props.article,
            position: props.slidePosition,
            startCamera: modalType === 'IMAGECAMERA' ? true : false,
            onSubmitSlide: props.onSubmitSlide,
            modalType: slideEditorModalLookup[modalType]
          }
        });
      } else {
        props.openModal({
          modalType: modalType === 'RichTextSlideEditor' ? modalType : `${modalType}Modal`,
          modalProps: {
            currentUser: props.currentUser,
            slideType: modalType,
            article: props.article,
            onSubmitSlide: props.onSubmitSlide,
            currentSlidePosition: props.slidePosition,
            position: props.slidePosition,
            modalType: modalType === 'RichTextSlideEditor' ? modalType : `${modalType}Modal`,
            pendingInputBarText: props.pendingInputBarText,
          },
        });
      }
    }, 500);    
  };
  const headerToolbarContent = (
    <div className={styles.headerHeaderContent}>
      <span className={styles.headerContentTitle}>Pick a header</span>
      <icons.ContentAlteration_RichText_Header_H color="var(--COLOR-SECONDARY-400)" />
    </div>
  );
  return (
    <ResizeableModal
      canResize={false}
      closeModal={() => props.closeModal()}
      className={`useMaxWidth${props.className ? ' ' + props.className : ''}`}
      showInputBar={true}
      mode='auto'
      width={360}
      navigationState='backNavigation'
      navigateBack={() => setSelectedSlideTypeFilter(SlideTypeFilter.SimpleText)} 
      rightToolbarContent={headerToolbarContent}
      hideCommandBar={selectedSlideTypeFilter === SlideTypeFilter.SimpleText}
      closeOnDimmer={true}
    >
      <div className={styles.newSlideTypeChooser}>
        <div className={styles.slideTypes}>
          <>
          {selectedSlideTypeFilter !== SlideTypeFilter.Header && (
            <>
            {(props.currentUser.allowFullAccess === true) && (
              <AllSlideTypes openModal={openModal} />
            )}
            {(props.currentUser.allowFullAccess !== true) &&
              <LimitedSlideTypes openModal={openModal} />
            }
            </>
          )}
          {selectedSlideTypeFilter === SlideTypeFilter.Header && (
            <props.headerOptionPicker />
          )}       
          </>
        </div>
        {selectedSlideTypeFilter !== SlideTypeFilter.Header && (
          <div className={styles.additionalActions}>
          <Picker
            pickerItems={
              [
                {icon: icons.ContentAlteration_RichText_Header_Simple_Text, label: "Simple text", value: 1,},
                {icon: icons.ContentAlteration_RichText_Main, label: "Text editor", value: 2},
                {icon: icons.ContentAlteration_List, label: "List", value: 3},
                {icon: icons.ContentAlteration_RichText_Header_H, label: "Header", value: 4},
              ]
            }
            displayMode={PickerDisplayMode.CircularTextOutsideUnder}
            selectedValue={mode}
            onChange={(value: number) => {
              setMode(value);              
              setSelectedSlideTypeFilter(value);
              if(value !== SlideTypeFilter.Header) {
                props.onReset(value === SlideTypeFilter.SimpleText);
              }
            }}
            // itemWidth={90}
            itemCSSClass={styles.pickerItem}
            itemsPerRow={4}
          />
        </div>
        )}
      </div>
    </ResizeableModal>
  );
}
