import agent from './agent';
import isNil from 'ramda/es/isNil';

const mapStateToProps = (state, ownProps) => {
  return {
    article: ownProps.article ? ownProps.article : state.article.article,
    slides: ownProps.slides ? ownProps.slides : state.article.slides,
    mode: state.common.mode,
    slide: state.common.slide ? state.common.slide.id : null,
    selection: state.common.selection,
    position: !isNil(ownProps.position)
                ? ownProps.position
                : !isNil(state.common.slideNumber)
                    ? state.common.slideNumber
                    : state.article.slides
                      ? state.article.slides.length
                      : null,
    currentUser: state.common.currentUser,
  };
};
const getSlideAttachmentInfo = async (slideId, updateSlideAttachmentInfo) => {
  const result = await agent.Slides.getAttachmentDetails(slideId);
  if (result) {
    updateSlideAttachmentInfo({
      slideId: slideId,
      childArticlesSlideTypes: result.childArticlesSlideTypes,
      childArticlesSlideDetails: result.childArticlesSlideDetails,
    });
  }
};
export default {
  mapStateToProps,
  getSlideAttachmentInfo,
};
