import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, List, Grid, Button, Icon } from 'semantic-ui-react';

import { contentMetaData } from '../../constants/contentMetaData';
import {
  ADD_SLIDE,
  MODAL_CLOSE,
  SLIDE_SELECTED,
} from '../../constants/actionTypes';
import slideTools from '../../slideTools';
import GideImage from '../Shared/Image/GideImage';

const mapStateToProps = slideTools.mapStateToProps;

const mapDispatchToProps = dispatch => ({
  onSubmit: payload => dispatch({ type: ADD_SLIDE, payload }),
  closeModal: payload => dispatch({ type: MODAL_CLOSE }),
  onSlideSelected: payload => dispatch({ type: SLIDE_SELECTED, payload }),
});

export class SlideSelectModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      caption: '',
      slide: null,
      layout: 'GRID',
    };

    this.updateState = field => ev => {
      const state = this.state;
      const newState = Object.assign({}, state, { [field]: ev.target.value });
      this.setState(newState);
    };

    this.setBody = ev => {
      this.setState({ body: ev.target.value });
    };

    this.selectSlide = slide => {
      this.setState({ slide });
      this.props.onSlideSelected({ slide });
      this.props.closeModal();
    };

    this.changeLayout = this._changeLayout.bind(this);
  }

  _changeLayout(layout) {
    this.setState({ layout });
  }

  render() {
    const { layout } = this.state;
    const { slides } = this.props;
    return (
      <Modal
        closeOnEscape={true}
        onClose={this.props.closeModal}
        className="slideSelectModal"
        size="small"
        dimmer="inverted"
        closeOnDocumentClick={false}
        open={true}
        closeOnDimmerClick={false}
      >
        <div className="modalHeader" style={{ background: 'rgb(152, 63, 85)' }}>
          <Button id="modalClose"
                icon
                onClick={this.props.closeModal}>
              <Icon name="close" />
            </Button>
          <Button.Group>
            <Button
              icon="block layout"
              onClick={() => this.changeLayout('GRID')}
            />
            <Button
              icon="list layout"
              onClick={() => this.changeLayout('LIST')}
            />
          </Button.Group>
        </div>
        <Modal.Content>
          <section>
            {(!slides || !slides.length) &&
              <div>No slides available.</div>
            }
            {layout === 'LIST' && (
              <List selection divided relaxed>
                {slides.map((s, i) => (
                  <List.Item key={i} onClick={() => this.selectSlide(s)}>
                    <GideImage 
                      src={contentMetaData[s.slideType].icon}
                      className="icon sm"
                      alt={`□`}
                    />
                    <List.Content>
                      slide {i + 1}, type: {s.slideType}
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            )}
            {layout === 'GRID' && (
              <Grid columns={3} padded>
                {slides.map((s, i) => (
                  <Grid.Column
                    style={{
                      background: contentMetaData[s.slideType].primaryColor,
                    }}
                    key={i}
                    onClick={() => this.selectSlide(s)}
                  >
                    <span className="slideNumber">{i + 1}</span>
                    <GideImage 
                      src={contentMetaData["SLIDE"].icon}
                      className="icon sm"
                      alt={`□`}
                    />
                  </Grid.Column>
                ))}
              </Grid>
            )}
          </section>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SlideSelectModal);
