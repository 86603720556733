import React from 'react';
import styles from './_editor-state.module.scss';
import { CircleIconButton } from '../../../Shared/CircleIconButton/CircleIconButton';
export interface EditorStateProps {
  title: string;
  backgroundColor: string;
  labelColor?: string;
  icon: JSX.Element;
}
export const EditorState: React.FC<EditorStateProps> = (props: EditorStateProps) => {
  const style = props.labelColor ? {color: props.labelColor} : undefined;
  return (
    <div className={styles.editorStateContainer}>
      <span className={styles.title} style={style}>{props.title}</span>
      <CircleIconButton
        width={30}
        height={30}
        alt={props.title}
        backgroundColor={props.backgroundColor}
      >
        {props.icon}
      </CircleIconButton>
    </div>
  );
};
