
import React, { useRef, useEffect } from 'react';
import MapWithAMarker from '../../maps/MapWithAMarker';
import { GOOGLE_MAPS_URL } from '../../../constants/paths';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import { NotificationType } from '../../../constants/strings';

export interface GeoLocation {
  lat: number;
  lng: number;
}
export const DefaultGeoLocation: GeoLocation = {
  lat: 20.447663034883696,
  lng: -80.18723080957031
};
export const UndefinedGeoLocation: GeoLocation = {
  lat: 17.03248,
  lng: 8.56327
};
export interface GeoLocationChooserProps {
  location?: GeoLocation;
  onLocationChanged: (location: GeoLocation) => void;
  showNotification: (toasterMessageInfo: ToasterMessageInfo) => void;
  key: string;
}
export interface GeoPosition {
  coords: {
    latitude: number;
    longitude: number;
  }
}
export default function GeoLocationChooser (props:  GeoLocationChooserProps) {
  //const [location, setLocation] = useState<GeoLocation>(props.location);
  let geotagLocationMap = useRef();
  const captureGeotagLocation = (): void => {
    if (props.location) {
      // setLocation(location);
      props.onLocationChanged(props.location);      
    } else if (navigator.geolocation.getCurrentPosition) {
      // Need to determine the user's current location
      navigator.geolocation.getCurrentPosition(
        (position: GeoPosition) => {
          const location = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          // setLocation(location);
          props.onLocationChanged(location);
        },
        (error: PositionError) => {
          // setLocation(DefaultGeoLocation);
          props.onLocationChanged(DefaultGeoLocation);
          console.warn(`ERROR(${error.code}): ${error.message}`);
        }
      );
    } else {
      // setLocation(DefaultGeoLocation);
      props.onLocationChanged(DefaultGeoLocation);
      props.showNotification({
        message:'Sorry, geolocation does not seem to be supported by your browser.',
        type: NotificationType.WARNING
      });        
    }
  };

  useEffect(() => {
    captureGeotagLocation();
  }, []);

  return (
    <>
    {props.location && (
      React.createElement(MapWithAMarker, {
        googleMapURL: GOOGLE_MAPS_URL,
        showMarker: true,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement:<div style={{ height: `400px`, width: `100%`, maxWidth: `752px` }} />,
        mapElement:<div style={{ height: `100%` }} />,
        defaultMapTypeId: 'roadmap',
        lat: props.location?.lat ? props.location.lat : DefaultGeoLocation.lat,
        lng: props.location?.lng ? props.location.lng : DefaultGeoLocation.lng,
        zoom: 8,
        mapId: props.key,
        onDrag: (e: any) => {
          const location = {
            lat: (geotagLocationMap as any).getCenter().lat(),
            lng: (geotagLocationMap as any).getCenter().lng(),
          };
          // setLocation(location);
          props.onLocationChanged(location);
        },
        refMap: (ref: any) => geotagLocationMap = ref,
        options: {gestureHandling: 'auto'},
        } as any)
      )}
    </>
  );
}
    