import React, { Component, useEffect, useState} from 'react';
import { User } from '../../../models/User';
import { Slide, SlideUpdateInfo } from '../../../models/Slide';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import { Modal } from 'semantic-ui-react';
import { withTheme } from 'styled-components';
import {
  ChildArticleType,
  getDisplayTextForChildArticleType,
  getChildArticleTypeName,
  getChildArticleTypeEnum,
  getChildArticleDisplayName,
  getIconForChildArticleType,
} from '../../../models/ArticleLayoutEnum';
import { attachmentMetaData } from '../../../constants/contentMetaData';
import GideViewer from '../GideViewer/GideViewer';
import { Article } from '../../../models/Article';
import {
  InlineViewSlides,
  MultiSlideSelectionDetail,
} from '../../../reducers/article';
import { Dictionary } from '../../../models/SlideShowSlide';
import {
  getChildArticlesForSlide,
  getChildArticlesForArticle,
  getAvailableHeaderLevelsForSlidePosition,
  articleDisplayTime,
  noop,
  HeaderSlideAvailableTypeInfo,
} from '../../../utils/helperFunctions';
import agent from '../../../agent';
import { isNullOrUndefined } from 'util';
import Footer from '../../Footer/Footer';
import { SlideView, InlineSlideTextEditInfo, ViewMode } from '../SlideView/SlideView';
import { SlideSelectionInfo } from '../../../models/SlideSelectionInfo';
import { InputBarOpenMenu } from '../../../constants/strings';
import { append, contains, reject, sort } from 'ramda';
import {
  InquiryResponseMode,
  InquiryResponseHeader,
} from '../../Shared/InquiryResponseHeader/InquiryResponseHeader';
import { RadioButton as RadioButtonComponent } from '../../Shared/RadioButton/RadioButton';
import GideImage from '../../Shared/Image/GideImage';
import { MultiSlideInsertParameters } from '../../../models/MultiSlideInsertParameters';
import { AppState } from '../../../reducer';
import { FileProcessorState } from '../../../reducers/FileProcessing/fileProcessing';
import { useSelector } from 'react-redux';

export interface SlidePositionInfo {
  id: string;
  position: number;
}

export interface SlidesPositionInfo {
  slides: Slide[];
  position: number;
}



export interface GideViewerManagerModalProps {
  currentUser: User;
  view: string;
  viewMode: ViewMode;
  nextViewMode: ViewMode;
  articleEditMode: string;
  viewOnly?: boolean;
  ownerSlide: Slide;
  selectedArticleType: ChildArticleType;
  selectedArticleId?: string;
  articleTypeList: ChildArticleType[];
  disableInlineTextEditing: boolean;
  lastCurrentlyOpenedModal?: string;
  openModal: (payload: any) => void;
  showNotification: (toasterMessageInfo: ToasterMessageInfo) => void;
  closeModal: () => void;
  closeGideViewer: (
    ownerSlideId?: string,
    articleInlineSlideChangesDictionary?: Dictionary<InlineViewSlides>,
  ) => void;
}

export interface ArticleViewDetail {
  article: Article;
  slides: Slide[];
  currentSlidePosition?: number;
  collapsedSlides: Slide[];
  parentArticleId?: string;
}

interface GideViewerManagerModalState {
  selectedArticleType: ChildArticleType;
  editingSelectedArticle?: boolean;
  selectedArticleViewDetail?: ArticleViewDetail;
  parentArticleViewDetail?: ArticleViewDetail;
  showOwnerSlide: boolean;
  articleInlineSlideChangesDictionary: Dictionary<InlineViewSlides>;
  isLoading: boolean;
  articleTypeLoaded: ChildArticleType[];
  // articleViewDetailList: ArticleViewDetail[];
  scrollToSlideId?: string;
  slideIdOfSlideOutOfOrder?: string;
  textSlideEditing: Slide | null;
  multiSlideSelectionDetail: MultiSlideSelectionDetail;
  slideSelectionModeDetail?: SlideSelectionInfo;
  openMenu: InputBarOpenMenu;
  showTableOfContentsView: boolean;
  renderColumns: boolean;
  showBlame: boolean;
  hideEndSlides: boolean;
  displayCollapsedHeaders: boolean;
  currentSlideType: string | null;
  inlineSlideTextEditInfo?: InlineSlideTextEditInfo;
  inlineEditedSlide?: Slide;
  displayPendingArticles: boolean;
}

function GideViewerManagerModal (props: GideViewerManagerModalProps) {
  const [state, setState] = useState<GideViewerManagerModalState>({
    isLoading: false,
    showOwnerSlide: false,
    articleInlineSlideChangesDictionary: {},
    // articleViewDetailList: [],
    articleTypeLoaded: [],
    selectedArticleType: props.selectedArticleType,
    textSlideEditing: null,
    slideIdOfSlideOutOfOrder: undefined,
    multiSlideSelectionDetail: {
      showMultiSelectSlideView: false,
      selectedSlideIds: [],
      mode: undefined,
    },
    slideSelectionModeDetail: undefined,
    openMenu: InputBarOpenMenu.NONE,
    showTableOfContentsView: false,
    renderColumns: false,
    showBlame: false,
    hideEndSlides: false,
    displayCollapsedHeaders: false,
    inlineSlideTextEditInfo: undefined,
    inlineEditedSlide: undefined,
    selectedArticleViewDetail: undefined,
    displayPendingArticles: false,
    currentSlideType: null
  });

  const [articleViewDetailList, setArticleViewDetailList] = useState<ArticleViewDetail[]>([]);
  const fileProcessorState = useSelector<AppState, FileProcessorState>(
    (state: AppState): FileProcessorState => {
      return {
       ...state.fileProcessor,
      }
    }
  );
  // constructor(props: GideViewerManagerModalProps) {
  //   super(props);
  //   state = {
  //     isLoading: false,
  //     showOwnerSlide: false,
  //     articleInlineSlideChangesDictionary: {},
  //     articleViewDetailList: [],
  //     articleTypeLoaded: [],
  //     selectedArticleType: props.selectedArticleType,
  //     textSlideEditing: null,
  //     slideIdOfSlideOutOfOrder: undefined,
  //     multiSlideSelectionDetail: {
  //       showMultiSelectSlideView: false,
  //       selectedSlideIds: [],
  //       mode: undefined,
  //     },
  //     slideSelectionModeDetail: undefined,
  //     openMenu: InputBarOpenMenu.NONE,
  //     showTableOfContentsView: false,
  //     renderColumns: false,
  //     showBlame: false,
  //     hideEndSlides: false,
  //     displayCollapsedHeaders: false,
  //     inlineSlideTextEditInfo: undefined,
  //     inlineEditedSlide: undefined,
  //     selectedArticleViewDetail: undefined,
  //     displayPendingArticles: false,
  //     currentSlideType: null
  //   };
  // }

  useEffect(() => {
    load();
  },[]);

 
  
  const load = async() => {
    if (!state.isLoading) {
      setState({
        ...state,
        isLoading: true
      });
      await loadArticles(props.selectedArticleType);
      setState({
        ...state,
        isLoading: false
      });
    }
  }

  const setInlineSlideTextEditInfo = (payload: any) => {
    setState({
      ...state,
      inlineSlideTextEditInfo: payload.inlineSlideTextEditInfo,
      inlineEditedSlide: undefined,
    });
  };

  const updateSlideWithInlineEdits = (inlineEditedSlide: Slide) => {
    setState({
      ...state,
      inlineEditedSlide: inlineEditedSlide,
    });
  };

  const copySlides = (copySlidesInfo: SlidesPositionInfo) => {
    if (state.selectedArticleViewDetail) {
      const slidesPrecedingCopiedSlides = state.selectedArticleViewDetail.slides.filter(
        (s: Slide) =>
          !isNullOrUndefined(s.position) &&
          s.position < copySlidesInfo.position,
      );
      const slidesAfterCopiedSlides = state.selectedArticleViewDetail.slides
        .filter(
          (s: Slide) =>
            !isNullOrUndefined(s.position) &&
            s.position >= copySlidesInfo.position,
        )
        .map(s => {
          return {
            ...s,
            position: s.position + copySlidesInfo.slides.length,
          } as Slide;
        });

      const updatedSlides = [
        ...(slidesPrecedingCopiedSlides ? slidesPrecedingCopiedSlides : []),
        ...copySlidesInfo.slides,
        ...(slidesAfterCopiedSlides ? slidesAfterCopiedSlides : []),
      ];

      const articleViewDetail = {
        ...state.selectedArticleViewDetail,
        slides: updatedSlides,
        currentSlidePosition: copySlidesInfo.position,
      };

      const newArticleViewDetailList = reject(
        ad => ad.article.id === articleViewDetail.article.id,
        articleViewDetailList,
      );
      newArticleViewDetailList.push(articleViewDetail);

      setState({
        ...state,
        selectedArticleViewDetail: articleViewDetail,
        scrollToSlideId: articleViewDetail.slides[copySlidesInfo.position]
          ? articleViewDetail.slides[copySlidesInfo.position].id
          : undefined,
        slideSelectionModeDetail: undefined,
      });
      setArticleViewDetailList(newArticleViewDetailList);
    }
  };

  const moveSlides = async (selectedSlideIds: string[], moveToPosition: number) => {
    if (state.selectedArticleViewDetail) {
      const payload = await agent.Articles.moveSlides(
        state.selectedArticleViewDetail.article,
        moveToPosition,
        selectedSlideIds,
      );
      const slides = state.selectedArticleViewDetail.slides
        ? state.selectedArticleViewDetail.slides
        : [];
      const slideInfoList: SlidePositionInfo[] = payload.movedSlideInfoList;
      const updatedSlides = sort(
        (s1: Slide, s2: Slide) => {
          return s1.position - s2.position;
        },
        slides.map((s: Slide) => {
          const slideInfo = slideInfoList.find(si => si.id === s.id);
          if (slideInfo) {
            return {
              ...s,
              position: slideInfo.position,
            };
          } else {
            return s;
          }
        }),
      );

      const articleViewDetail = {
        ...state.selectedArticleViewDetail,
        slides: updatedSlides,
        currentSlidePosition: moveToPosition,
      };

      const newArticleViewDetailList = reject(
        ad => ad.article.id === articleViewDetail.article.id,
        articleViewDetailList,
      );
      newArticleViewDetailList.push(articleViewDetail);

      setState({
        ...state,
        selectedArticleViewDetail: articleViewDetail,
        scrollToSlideId: articleViewDetail.slides[moveToPosition]
          ? articleViewDetail.slides[moveToPosition].id
          : undefined,
        slideSelectionModeDetail: undefined,
      });
      setArticleViewDetailList(newArticleViewDetailList);
    }
  };

  const deleteSlide = (deletedSlideId: string) => {
    if (state.selectedArticleViewDetail) {
      const deletedSlide = state.selectedArticleViewDetail.slides.find(
        (s: Slide) => s.id === deletedSlideId,
      );
      const updatedSlides = reject(
        s => s.id === deletedSlideId,
        state.selectedArticleViewDetail.slides,
      );
      const reorderedSlides = updatedSlides.map((s: Slide) => {
        return {
          ...s,
          position:
            deletedSlide &&
            !isNullOrUndefined(deletedSlide.position) &&
            !isNullOrUndefined(s.position) &&
            deletedSlide.position < s.position
              ? s.position - 1
              : s.position,
        };
      });
      if (reorderedSlides) {
        const articleViewDetail = {
          ...state.selectedArticleViewDetail,
          slides: reorderedSlides,
        };

        const newArticleViewDetailList = reject(
          ad => ad.article.id === articleViewDetail.article.id,
          articleViewDetailList,
        );
        newArticleViewDetailList.push(articleViewDetail);

        setState({
          ...state,
          selectedArticleViewDetail: articleViewDetail,
          scrollToSlideId:
            deletedSlide && state.scrollToSlideId === deletedSlide.id
              ? undefined
              : state.scrollToSlideId,
        });
        setArticleViewDetailList(newArticleViewDetailList);
      }
    }
  };

  const onSubmitMultipleSlides = (
    multiSlideInsertDetails: MultiSlideInsertParameters,
  ) => {
    if (state.selectedArticleViewDetail) {
      let slides = state.selectedArticleViewDetail.slides;
      slides
        .filter(
          (s: Slide) =>
            !isNullOrUndefined(s.position) &&
            s.position >= multiSlideInsertDetails.insertedPosition,
        )
        .forEach(s => {
          s.position = s.position + multiSlideInsertDetails.slideCount;
        });
      // add the new slides
      slides = [...slides, ...multiSlideInsertDetails.slidesToInsert];
      // sort slides by position
      slides.sort((s1: Slide, s2: Slide) => {
        return s1.position - s2.position;
      });

      // check to see if the current slide position is not the last position in the gide
      const slidesOutOfOrder = slides.filter(
        s =>
          s.position ===
          multiSlideInsertDetails.insertedPosition +
            multiSlideInsertDetails.slidesToInsert.length,
      );

      const articleViewDetail = {
        ...state.selectedArticleViewDetail,
        slides: slides,
      };

      const newArticleViewDetailList = reject(
        ad => ad.article.id === articleViewDetail.article.id,
        articleViewDetailList,
      );
      newArticleViewDetailList.push(articleViewDetail);

      setState({
        ...state,
        selectedArticleViewDetail: articleViewDetail,
        scrollToSlideId: articleViewDetail.slides[
          multiSlideInsertDetails.insertedPosition
        ]
          ? articleViewDetail.slides[multiSlideInsertDetails.insertedPosition]
              .id
          : undefined,
        slideIdOfSlideOutOfOrder:
          slidesOutOfOrder && slidesOutOfOrder.length > 0
            ? slidesOutOfOrder[0].id
            : undefined,
      });
      setArticleViewDetailList(newArticleViewDetailList);
    }
  };

  const updateSlideNumber = (currentSlidePosition: number) => {
    if (state.selectedArticleViewDetail) {
      const slides = state.selectedArticleViewDetail.slides
        ? state.selectedArticleViewDetail.slides
        : [];
      // check and see if any of the slides have the same position as the currentSlidePosition
      // this would mean that the slide insert is out of order
      // and the slide should be displayed as out of order
      const slidesOutOfOrder = slides.filter(
        s => s.position === currentSlidePosition,
      );
      setState({...state,
        slideIdOfSlideOutOfOrder:
          slidesOutOfOrder && slidesOutOfOrder.length > 0
            ? slidesOutOfOrder[0].id
            : undefined,
      });
    }
  };

  const scrollToSlidePosition = (slidePosition: number) => {
    if (state.selectedArticleViewDetail) {
      const slides = state.selectedArticleViewDetail.slides;

      const scrollToSlide = slides[slidePosition];

      setState({...state,
        scrollToSlideId: scrollToSlide
          ? scrollToSlide.id
          : slides.length > 0 ? slides[slides.length - 1].id : undefined,
      });
    }
  };

  /**
   * Collapses all the slides
   *  if the selectedArticleViewDetail exists, then all the slide fom the selectedArticleViewDetail are collapsed
   *  if the selectedArticleViewDetail doesn't exist, then collapses all the slides in the articleViewDetailsDictionary for the selectedArticleType
   */
  const collapseAllSlides = () => {
    if (state.selectedArticleViewDetail) {
      const slides = state.selectedArticleViewDetail.slides;

      setState({...state,
        selectedArticleViewDetail: {
          ...state.selectedArticleViewDetail,
          collapsedSlides: slides.filter(
            s =>
              (s.slideType === 'HEADER' &&
                s.data.type !== 'END' &&
                s.data.level > 0) ||
              (s.slideType === 'COLLAPSE' && s.data.type === 'BEGIN'),
          ),
        },
      });
    } else {
      const updatedArticleViewDetails = articleViewDetailList
        .filter(
          ad =>
            getChildArticleTypeEnum(ad.article.type) ===
            state.selectedArticleType,
        )
        .map(articleViewDetails => {
          return {
            ...articleViewDetails,
            collapsedSlides: articleViewDetails.slides.filter(
              s =>
                (s.slideType === 'HEADER' &&
                  s.data.type !== 'END' &&
                  s.data.level > 0) ||
                (s.slideType === 'COLLAPSE' && s.data.type === 'BEGIN'),
            ),
          };
        });

      const newArticleViewDetails = reject(
        ad =>
          getChildArticleTypeEnum(ad.article.type) ===
          state.selectedArticleType,
        articleViewDetailList,
      );
      setArticleViewDetailList( [
        ...newArticleViewDetails,
        ...updatedArticleViewDetails,
      ]);
    }
  };

  /**
   * Expands all the slides
   *  if the selectedArticleViewDetail exists, then all the slide fom the selectedArticleViewDetail are expanded
   *  if the selectedArticleViewDetail doesn't exist, then expands all the slides in the articleViewDetailsDictionary for the selectedArticleType
   */
  const expandAllSlides = () => {
    if (state.selectedArticleViewDetail) {
      setState({...state,
        selectedArticleViewDetail: {
          ...state.selectedArticleViewDetail,
          collapsedSlides: [],
        },
      });
    } else {
      const updatedArticleViewDetails = articleViewDetailList
        .filter(
          ad =>
            getChildArticleTypeEnum(ad.article.type) ===
            state.selectedArticleType,
        )
        .map(articleViewDetails => {
          return {
            ...articleViewDetails,
            collapsedSlides: [],
          };
        });
      const newArticleViewDetails = reject(
        ad =>
          getChildArticleTypeEnum(ad.article.type) ===
          state.selectedArticleType,
        articleViewDetailList,
      );
      setArticleViewDetailList( [
        ...newArticleViewDetails,
        ...updatedArticleViewDetails,
      ]);
    }
  };

  /**
   * collapses the slide in the article with the id of articleId for the selectedArticleType
   */
  const collapseSlides = (articleId: string, slide: Slide) => {
    const articleIndex = articleViewDetailList.findIndex(
      avd => avd.article.id === articleId,
    );
    if (articleIndex > -1) {
      const articleViewDetail = {
        ...articleViewDetailList[articleIndex],
        collapsedSlides:
          articleViewDetailList[
            articleIndex
          ].collapsedSlides.findIndex(s => s.id === slide.id) === -1
            ? append(
                slide,
                articleViewDetailList[articleIndex].collapsedSlides,
              )
            : articleViewDetailList[
                articleIndex
              ].collapsedSlides.filter(s => s.id !== slide.id),
      };
      const newArticleViewDetails = reject(
        ad => ad.article.id === articleId,
        articleViewDetailList,
      );
      newArticleViewDetails.push(articleViewDetail);


      setArticleViewDetailList(newArticleViewDetails);
    }
  };

  const handleDistribute = () => {
    if (state.slideSelectionModeDetail) {
      props.openModal({
        modalType: 'DistributeModal',
      });
    }
  };

  const toggleTableOfContents = () => {
    setState({...state,
      showTableOfContentsView: !state.showTableOfContentsView,
    });
  };

  const toggleColumns = () => {
    setState({...state,
      renderColumns: !state.renderColumns,
    });
  };

  const toggleBlame = () => {
    setState({...state,
      showBlame: !state.showBlame,
    });
  };

  const toggleCollapsedHeaderDisplay = () => {
    setState({...state,
      displayCollapsedHeaders: !state.displayCollapsedHeaders,
    });
  };

  const toggleEndSlideDisplay = () => {
    setState({...state,
      hideEndSlides: !state.hideEndSlides,
    });
  };

  const setOpenMenu = (openMenuStatus: InputBarOpenMenu) => {
    setState({...state, openMenu: openMenuStatus });
  };

  const turnOverlayOff = () => {
    setState({...state, openMenu: InputBarOpenMenu.NONE });
  };

  const onEditingText = (slide: Slide) => {
    setState({...state, textSlideEditing: slide });
  };

  const enterSlideSelectionMode = (slideSelectionModeDetail: SlideSelectionInfo) => {
    setState({...state, slideSelectionModeDetail: slideSelectionModeDetail });
  };

  const multiSlideSelectionChanged = (slideId: string, isSelected: boolean) => {
    if (
      isSelected &&
      !contains(slideId, state.multiSlideSelectionDetail.selectedSlideIds)
    ) {
      const multiSlideSelectionDetail = {
        ...state.multiSlideSelectionDetail,
        selectedSlideIds: [
          ...state.multiSlideSelectionDetail.selectedSlideIds,
          ...[slideId], // TODO: Not sure why I am adding all ids here. VERIFY THIS
        ],
      };
      setState({...state, multiSlideSelectionDetail: multiSlideSelectionDetail });
    } else {
      const multiSlideSelectionDetail = {
        ...state.multiSlideSelectionDetail,
        selectedSlideIds: reject(
          sId =>
            contains(
              slideId,
              state.multiSlideSelectionDetail.selectedSlideIds,
            ),
          state.multiSlideSelectionDetail.selectedSlideIds,
        ),
      };
      setState({...state, multiSlideSelectionDetail: multiSlideSelectionDetail });
    }
  };

  // Get the available header choices for the current slide position
  const getAvailableHeaderLevelsForCurrentSlidePosition = (): HeaderSlideAvailableTypeInfo => {
    if (state.selectedArticleViewDetail) {
      const nextSlideNumber = !isNullOrUndefined(state.selectedArticleViewDetail.currentSlidePosition)
        ? state.selectedArticleViewDetail.currentSlidePosition
        : state.selectedArticleViewDetail.slides
          ? state.selectedArticleViewDetail.slides.length
          : 0;

      return getAvailableHeaderLevelsForSlidePosition(
        nextSlideNumber,
        state.selectedArticleViewDetail.slides
          ? state.selectedArticleViewDetail.slides
          : [],
      );
    }
    return {
      headerLevels: []
    }
  };

  const onReply = async (
    parentArticleId: string,
    parentPendingApproval?: boolean,
  ) => {
    if (parentPendingApproval) {
      await onUpdateArticleStatus(parentArticleId, 'APPROVED');
    }
    addArticleArticle(ChildArticleType.Response, parentArticleId);
  };

  const addArticleArticle = async (
    articleType: ChildArticleType,
    parentArticleId: string,
  ) => {
    // create additionType article article
    const article = {
      title: `ARTICLEARTICLE - ${getChildArticleTypeName(articleType)}`,
      type: getChildArticleTypeName(articleType),
      article: parentArticleId,
      status:
        props.ownerSlide.author.id !== props.currentUser.id
          ? 'PENDING'
          : 'APPROVED',
      statusDate: new Date(),
    };
    const articleViewDetail = await createAdditonArticle(
      article,
      parentArticleId,
    );
    const parentArticleViewDetail = articleViewDetailList.find(
      ad => ad.article.id === parentArticleId,
    );

    setState({...state,
      selectedArticleViewDetail: articleViewDetail,
      parentArticleViewDetail: parentArticleViewDetail,
    });
    setArticleViewDetailList( [
      ...articleViewDetailList,
      ...[articleViewDetail]
    ]);
  };

  const addSlideArticle = async (articleType: ChildArticleType): Promise<Article> => {
    // create additionType slide article
    const article = {
      title: `SLIDEARTICLE - ${getChildArticleTypeName(articleType)}`,
      type: getChildArticleTypeName(articleType),
      slide: props.ownerSlide.id,
      status:
        props.ownerSlide.author.id !== props.currentUser.id
          ? 'PENDING'
          : 'APPROVED',
      statusDate: new Date(),
    };
    const articleViewDetail = await createAdditonArticle(article);

    setState({...state,
      selectedArticleViewDetail: articleViewDetail,
    });
    setArticleViewDetailList( [
      ...articleViewDetailList,
      ...[articleViewDetail]
    ]);
    // return new Promise(resolve => resolve(articleViewDetail.article));
    return articleViewDetail.article;
  };

  const createAdditonArticle = async (
    article: {
      title: string;
      type: string;
      article?: string;
      slide?: string;
    },
    parentArticleId?: string,
  ) => {
    const articleResponse = await agent.Articles.create(article);
    const articleViewDetails: ArticleViewDetail = {
      article: articleResponse.article,
      slides: [],
      collapsedSlides: [],
      parentArticleId: parentArticleId,
      currentSlidePosition: 0,
    };

    return articleViewDetails;
  };

  const onSubmitArticle = async (
    article: Article,
    slides: Slide[],
    currentSlidePosition?: number,
  ) => {
    const articleResponse = await agent.Articles.update(article);

    const articleViewDetail: ArticleViewDetail = {
      article: articleResponse.article,
      slides: slides,
      currentSlidePosition: currentSlidePosition,
      collapsedSlides: [],
      parentArticleId: state.parentArticleViewDetail
        ? state.parentArticleViewDetail.article.id
        : undefined,
    };

    const articleIndex = articleViewDetailList.findIndex(
      avd => avd.article.id === articleViewDetail.article.id,
    );
    if (articleIndex > -1) {
      const newArticleViewDetails = reject(
        ad => ad.article.id === articleViewDetail.article.id,
        articleViewDetailList,
      );

      setState({...state,
        selectedArticleViewDetail: undefined,
        parentArticleViewDetail: undefined,
      });
      setArticleViewDetailList( [
        ...newArticleViewDetails,
        ...[articleViewDetail]
      ]);
    } else {
      setState({...state,
        selectedArticleViewDetail: undefined,
        parentArticleViewDetail: undefined,
      });

      setArticleViewDetailList( [
        ...articleViewDetailList,
        ...[articleViewDetail]
      ]);
    }
  };

  const submitSlide = (addSlideDetail: SlideUpdateInfo) => {
    if (state.selectedArticleViewDetail) {
      const updatedSelectedArticleViewDetail = state.selectedArticleViewDetail;
      const currentSlides = [...updatedSelectedArticleViewDetail.slides];
      const idx = currentSlides.findIndex(
        (s: Slide) => s.id === addSlideDetail.slide.id,
      );
      const previousPosition = idx > -1 ? currentSlides[idx].position : -1;
      let slides: Slide[] = [];
      let oldSlides: Slide[] = [];

      if (previousPosition === -1) {
        oldSlides = currentSlides.map((s: Slide) => {
          if (
            !isNullOrUndefined(s.position) &&
            s.position >= addSlideDetail.slide.position
          ) {
            s.position += 1;
          }
          return s;
        });
      }
      if (idx !== -1) {
        slides = currentSlides.slice();
        slides[idx] = addSlideDetail.slide;
      } else {
        slides = (oldSlides || []).concat([addSlideDetail.slide]);
      }
      const sortedSlides = sort((l, r) => l.position - r.position, slides);
      const newSlideIndex = sortedSlides.findIndex(
        s => s.id === addSlideDetail.slide.id,
      );
      const newSlidePosition =
        newSlideIndex > -1 && sortedSlides[newSlideIndex]
          ? sortedSlides[newSlideIndex].position + 1
          : updatedSelectedArticleViewDetail.currentSlidePosition
            ? updatedSelectedArticleViewDetail.currentSlidePosition + 1
            : 1;

      const articleViewDetail = {
        ...updatedSelectedArticleViewDetail,
        slides: sortedSlides,
        currentSlidePosition: newSlidePosition,
      };

      setState({...state,
        selectedArticleViewDetail: articleViewDetail,
        scrollToSlideId: !addSlideDetail.preventScrollToSlide
          ? addSlideDetail.slide.id
          : undefined,
      });
    }
  };

  const onCreateGide = async (): Promise<Article> => {
    // if the new article's type is one that is associated with InquriyResponse, which is an addition to a slide or article
    debugger;
    if (
      state.selectedArticleType === ChildArticleType.Questions ||
      state.selectedArticleType === ChildArticleType.Comments ||
      state.selectedArticleType === ChildArticleType.Attachments ||
      state.selectedArticleType === ChildArticleType.PrivateNotes
    ) {
      return await addSlideArticle(state.selectedArticleType);
    } else {
      const article = {
        title: 'Untitled',
      };
      const articleResponse = await agent.Articles.create(article);
      onSubmitArticle(
        articleResponse.article,
        articleResponse.article.slides,
      );
      return articleResponse.article;
    }
  };

  const onUpdateArticleStatus = async (articleId: string, articleStatus: string) => {
    const articleIndex = articleViewDetailList.findIndex(
      avd => avd.article.id === articleId,
    );
    if (articleIndex > -1) {
      const existingArticleViewDetail = articleViewDetailList[
        articleIndex
      ];

      const statusUpdateResponse = existingArticleViewDetail.parentArticleId
        ? await agent.Articles.updateArticleStatusOnArticle(
            articleId,
            existingArticleViewDetail.parentArticleId,
            articleStatus,
          )
        : await agent.Articles.updateArticleStatusOnSlide(
            articleId,
            props.ownerSlide.id,
            articleStatus,
          );

      const articleViewDetail: ArticleViewDetail = {
        ...existingArticleViewDetail,
        article: statusUpdateResponse.article,
      };

      const newArticleViewDetails = reject(
        ad => ad.article.id === articleId,
        articleViewDetailList,
      );

      setArticleViewDetailList( [
        ...newArticleViewDetails,
        ...[articleViewDetail]
      ]);
    }
  };

  const onDeleteArticle = async (articleId: string) => {
    const articleIndex = articleViewDetailList.findIndex(
      avd => avd.article.id === articleId,
    );
    if (articleIndex > -1) {
      const article = articleViewDetailList[articleIndex].article;
      await agent.Articles.deleteOwnerSlideArticle(
        article.id,
        props.ownerSlide.id,
        {
          full: article.trashedDate !== null,
        },
      );

      setState({...state,
        selectedArticleViewDetail: undefined,
        parentArticleViewDetail: undefined,
      });

      setArticleViewDetailList(reject(
        ad => ad.article.id === articleId,
        articleViewDetailList,
      ));
    }
  };

  const onEditArticle = (articleId: string) => {
    const articleIndex = articleViewDetailList.findIndex(
      avd => avd.article.id === articleId,
    );
    if (articleIndex > -1) {
      const parentArticleId = articleViewDetailList[articleIndex]
        .parentArticleId;
      if (parentArticleId) {
        const parentArticleIndex = articleViewDetailList.findIndex(
          avd => avd.article.id === parentArticleId,
        );
        if (parentArticleIndex > -1) {
          setState({...state,
            selectedArticleViewDetail: articleViewDetailList[
              articleIndex
            ],
            parentArticleViewDetail: articleViewDetailList[
              parentArticleIndex
            ],
          });
        }
      } else {
        setState({...state,
          selectedArticleViewDetail: articleViewDetailList[
            articleIndex
          ],
          parentArticleViewDetail: undefined,
        });
      }
    }
  };

  const loadArticles = async (articleType: ChildArticleType) => {
    // check if article and slides have been or are being populated for the articleType, if not then populate them
    if (!state.articleTypeLoaded.includes(articleType)) {
      setState({...state,
        articleTypeLoaded: [...state.articleTypeLoaded, ...[articleType]],
      });
      const articleResponse = await getChildArticlesForSlide(
        props.ownerSlide,
        articleType,
      );

      // NOTE: I had to remove the create article from the footer. 
      // It was creating 2 separate articles for the same attachment and this caused the attachments
      // to show up randomly. Here I am checking to see if when the component initially loads that 
      // there is an article for the given type (eg. SETTING = Attachments). If there is not one
      // then I create one. One is never created from the footer any more. This component needs to be
      // rethought and rewritten. 
      let articles = articleResponse.articles;
      if(articles.length < 1) {
        const createArticleResponse = await addSlideArticle(state.selectedArticleType);
        articles = [createArticleResponse];
      }
      // if(articleResponse.article)
      // load the slides for the articles

      // Handle background upload:




      articles.map(async (article: Article) => {
        const slidesResponse = await agent.Slides.forArticle(article);
        let currentSlidePosition = -1;
        if (slidesResponse) {
          currentSlidePosition = slidesResponse.slides
            ? slidesResponse.slides.length
            : undefined;
        }

        const articleViewDetail: ArticleViewDetail = {
          article: article,
          slides: slidesResponse.slides.map((slide: Slide) => {

            // TODO: Handle file processing
            const slideProcessingInfoList = fileProcessorState.slideFileProcessorList.filter(
              slideFileProccessInfo => slideFileProccessInfo.slideId === slide.id,
            );
            const gallerySlideProcessingInfoList = fileProcessorState.gallerySlideFileProcessorList.filter(
              gallerySlideFileProccessInfo => gallerySlideFileProccessInfo.slideId === slide.id,
            );

            if (slideProcessingInfoList.length > 0 || gallerySlideProcessingInfoList.length > 0) {
              return {
                ...slide,
                data: {
                  ...slide.data,
                  files: slide.data.files.map((slideFile: any) => {
                    const slideFileProcessInfo = slideProcessingInfoList.find(item => item.slideFileId === slideFile.id);
      
                    if (slideFileProcessInfo && slideFile.processingId === slideFileProcessInfo.processingId) {
                      return {
                        ...slideFile,
                        url: URL.createObjectURL(slideFileProcessInfo.dataUrl),
                      };
                    } else if (gallerySlideProcessingInfoList.length > 0 && slideFile.imageFiles) {
                      // Image Gallery
                      return {
                        ...slideFile,
                        imageFiles: slideFile.imageFiles.map((igf: any) => {
                          const gallerySlideFileProccessInfo = gallerySlideProcessingInfoList.find(
                            gsfi => igf.processingId === gsfi.processingId && igf.id === gsfi.galleryFileId,
                          );
      
                          if (gallerySlideFileProccessInfo) {
                            return {
                              ...igf,
                              url: URL.createObjectURL(gallerySlideFileProccessInfo.dataUrl),
                            };
                          } else {
                            return igf;
                          }
                        }),
                      };
                    } else {
                      return slideFile;
                    }
                  }),
                },
              };
            } else {
              return slide;
            }

            return slide;
          }),
          currentSlidePosition: currentSlidePosition,
          collapsedSlides: [],
        };

        if (
          props.selectedArticleType === ChildArticleType.Questions ||
          props.selectedArticleType === ChildArticleType.Comments
        ) {
          // populate article responses
          const childArticleArticleResponse = await getChildArticlesForArticle(
            article.id,
            ChildArticleType.Response,
          );

          childArticleArticleResponse.articles.map(
            async (childArticle: Article) => {
              const childArticleSlidesResponse = await agent.Slides.forArticle(
                childArticle,
              );
              let currentSlidePosition = -1;
              if (childArticleSlidesResponse) {
                currentSlidePosition = childArticleSlidesResponse.slides
                  ? childArticleSlidesResponse.slides.length
                  : undefined;
              }

              const articleViewDetail: ArticleViewDetail = {
                article: childArticle,
                slides: childArticleSlidesResponse.slides,
                currentSlidePosition: currentSlidePosition,
                collapsedSlides: [],
                parentArticleId: article.id,
              };

              setState({...state,
                selectedArticleViewDetail:
                  props.selectedArticleId &&
                  articleViewDetail.article.id === props.selectedArticleId
                    ? articleViewDetail
                    : state.selectedArticleViewDetail,
              });
              setArticleViewDetailList( [
                ...articleViewDetailList,
                ...[articleViewDetail]
              ]);
            },
          );
          setState({...state,
            selectedArticleViewDetail:
              props.selectedArticleId &&
              articleViewDetail.article.id === props.selectedArticleId
                ? articleViewDetail
                : state.selectedArticleViewDetail,
          });
          setArticleViewDetailList( [
            ...articleViewDetailList,
            ...[articleViewDetail]
          ]);
        } else {
          const selectedArticleViewDetail =
            props.selectedArticleType !== ChildArticleType.Response
              ? articleViewDetail
              : state.selectedArticleViewDetail;
          setState({...state,         
            selectedArticleViewDetail: selectedArticleViewDetail,
          });
          setArticleViewDetailList( [
            ...articleViewDetailList,
            ...[articleViewDetail]
          ]);
        }
      });
    }
  };

  const onSwitchArticleTab = (selectedArticleType: ChildArticleType) => {
    setState({...state, selectedArticleType: selectedArticleType });
    loadArticles(selectedArticleType);
  };

  const toggleOwnerSlide = () => {
    setState({...state, showOwnerSlide: !state.showOwnerSlide });
  };

  const buildArticleTab = (
    tabArticleType: ChildArticleType,
    index: number,
  ): JSX.Element => {
    return (
      <span
        key={index}
        className={
          state.selectedArticleType === tabArticleType
            ? 'tabText selectedTab'
            : 'tabText'
        }
        onClick={e => onSwitchArticleTab(tabArticleType)}
      >
        {getDisplayTextForChildArticleType(tabArticleType)}
      </span>
    );
  };

  const onArticleInlineSlidesChanged = (
    articleId: string,
    inlineViewSlides: InlineViewSlides,
  ) => {
    const articleInlineSlideChangesDictionary = state
      .articleInlineSlideChangesDictionary;
    articleInlineSlideChangesDictionary[articleId] = inlineViewSlides;
    setState({...state,
      articleInlineSlideChangesDictionary: articleInlineSlideChangesDictionary,
    });
  };

  const onCloseGideViewerManager = () => {
    // if the selectedArticleViewDetail, then set it to undefined
    if (
      state.selectedArticleViewDetail &&
      (state.selectedArticleType === ChildArticleType.PrivateNotes ||
        state.selectedArticleType === ChildArticleType.Attachments)
    ) {
      const articleInlineSlideChangesDictionary = state
        .articleInlineSlideChangesDictionary;
      articleInlineSlideChangesDictionary[state.selectedArticleType] = {
        articleType: getChildArticleTypeName(state.selectedArticleType),
        slides: state.selectedArticleViewDetail.slides,
      };
      props.closeGideViewer(
        props.ownerSlide && props.ownerSlide.id,
        articleInlineSlideChangesDictionary,
      );
    } else {
      props.closeGideViewer(
        props.ownerSlide && props.ownerSlide.id,
        state.articleInlineSlideChangesDictionary,
      );
    }
  };


    const classNames = `${props.viewMode} gideViewModal`;
    const showDoneButton =
      state.selectedArticleType !== ChildArticleType.Comments &&
      state.selectedArticleType !== ChildArticleType.Questions;
    const selectedArticleViewDetails = articleViewDetailList.filter(
      adv =>
        getChildArticleTypeEnum(adv.article.type) ===
        state.selectedArticleType,
    );
    const footerArticle = state.selectedArticleViewDetail
      ? state.selectedArticleViewDetail.article
      : undefined;
    const footerPlaceHolderText =
      !state.selectedArticleViewDetail &&
      state.selectedArticleType === ChildArticleType.Questions
        ? 'Ask something'
        : 'Say something';
    const pendingArticleViewDetails = selectedArticleViewDetails
      ? props.ownerSlide.author.id === props.currentUser.id
        ? selectedArticleViewDetails.filter(
            avd => avd.article.status && avd.article.status === 'PENDING',
          )
        : selectedArticleViewDetails.filter(
            avd =>
              avd.article.author.id === props.currentUser.id &&
              avd.article.status &&
              avd.article.status === 'PENDING',
          )
      : undefined;
    const approvedArticleViewDetails = selectedArticleViewDetails
      ? selectedArticleViewDetails.filter(
          avd => avd.article.status && avd.article.status === 'APPROVED',
        )
      : undefined;
    const approvedParentWithChildPending =
      approvedArticleViewDetails &&
      approvedArticleViewDetails.filter(avd =>
        articleViewDetailList.some(
          pavd =>
            pavd.article.status === 'PENDING' &&
            pavd.parentArticleId === avd.article.id &&
            (props.ownerSlide.author.id === props.currentUser.id ||
              pavd.article.author.id === props.currentUser.id),
        ),
      );

    const hasPendingArticles =
      (pendingArticleViewDetails && pendingArticleViewDetails.length > 0) ||
      (approvedParentWithChildPending &&
        approvedParentWithChildPending.length > 0);

    const filteredArticleViewDetails =
      state.displayPendingArticles && hasPendingArticles
        ? sort(
            (a1: ArticleViewDetail, a2: ArticleViewDetail) => {
              return (
                new Date(a2.article.updatedAt).getTime() -
                new Date(a1.article.updatedAt).getTime()
              );
            },
            [
              ...(approvedParentWithChildPending
                ? approvedParentWithChildPending
                : []),
              ...(pendingArticleViewDetails ? pendingArticleViewDetails : []),
            ],
          )
        : sort((a1: ArticleViewDetail, a2: ArticleViewDetail) => {
            return (
              new Date(a2.article.updatedAt).getTime() -
              new Date(a1.article.updatedAt).getTime()
            );
          }, approvedArticleViewDetails ? approvedArticleViewDetails : []);

    return (
      <Modal
        className={classNames}
        closeOnEscape={true}
        onClose={props.closeModal}
        size="fullscreen"
        dimmer="inverted"
        closeOnDocumentClick={false}
        open={true}
        closeOnDimmerClick={false}
      >
        {state.parentArticleViewDetail && (
          <Modal.Header className="gideViewHeader">
            <div className="gideViewerContainer">
              <InquiryResponseHeader
                articleId={state.parentArticleViewDetail.article.id}
                author={state.parentArticleViewDetail.article.author}
                headingText={articleDisplayTime(
                  state.parentArticleViewDetail.article,
                )}
                inquiryResponseMode={InquiryResponseMode.Response}
                onBack={onCloseGideViewerManager}
                toggleOwnerSlide={toggleOwnerSlide}
                ownerType={getChildArticleDisplayName(
                  state.parentArticleViewDetail.article.type,
                )}
                displayingOwner={state.showOwnerSlide}
              />
              {state.showOwnerSlide && (
                <div className="gideViewerDetails">
                  <div
                    className={`${
                      state.parentArticleViewDetail.article.type
                    }`}
                  >
                    <GideViewer
                      key={state.parentArticleViewDetail.article.id}
                      currentUser={props.currentUser}
                      view={props.view}
                      viewMode={'SLIDE'}
                      nextViewMode={props.nextViewMode}
                      articleViewDetail={state.parentArticleViewDetail}
                      articleEditMode={''}
                      viewOnly={true}
                      showInquiryResponseHeader={false}
                      onSubmitArticle={onSubmitArticle}
                      showNotification={props.showNotification}
                      openModal={props.openModal}
                      closeModal={props.closeModal}
                      onArticleInlineSlidesChanged={
                        onArticleInlineSlidesChanged
                      }
                      disableInlineTextEditing={props.disableInlineTextEditing}
                      collapseSlides={collapseSlides}
                      onCreateGide={onCreateGide}
                      toggleTableOfContents={toggleTableOfContents}
                      onEditArticle={onEditArticle}
                      onDeleteArticle={onDeleteArticle}
                    />
                  </div>
                </div>
              )}
            </div>
          </Modal.Header>
        )}
        {!state.parentArticleViewDetail && (
          <Modal.Header className="gideViewHeader">
            <div className="navigationBar">
              <div className="completeAction">
                <GideImage src="/icons/nav/arrow/arrow-left.svg"
                  className="hoverIcon color-secondary-800-svg"
                  onClick={onCloseGideViewerManager}
                  alt="close dialog"
                />
                {showDoneButton === true && (
                  <div
                    className="button done"
                    onClick={onCloseGideViewerManager}
                  >
                    <div>Done</div>
                    <GideImage
                      className="gideTypeImage"
                      src={getIconForChildArticleType(
                        state.selectedArticleType,
                      )}
                    />
                  </div>
                )}
              </div>
              <div className="slideTypeIndicator">
                <GideImage
                  className="slideTypeImage sortFilter color-secondary-800-svg"
                  src="/icons/nav/sort&filter.svg"
                />
                <div className="separatorBar" />
                {props.ownerSlide && (
                  <>
                    <GideImage
                      className="slideTypeImage"
                      src={
                        (attachmentMetaData as any)[
                          props.ownerSlide.slideType as string
                        ]
                          ? (attachmentMetaData as any)[
                              props.ownerSlide.slideType
                            ].icon
                          : ''
                      }
                    />
                    <GideImage 
                      src={`/icons/nav/arrow/${
                        state.showOwnerSlide
                          ? 'arrow-up.svg'
                          : 'arrow-down.svg'
                      }`}
                      className="hoverIcon color-secondary-800-svg"
                      onClick={toggleOwnerSlide}
                      alt="toggle dialog display view"
                    />
                  </>
                )}
              </div>
            </div>
            {props.ownerSlide &&
              state.showOwnerSlide && (
                <div className="ownerSlide">
                  <div className="slideWrap">
                    <SlideView
                      slide={props.ownerSlide}
                      slidePosition={props.ownerSlide.position}
                      currentUser={props.currentUser}
                      view={props.view}
                      viewMode={props.viewMode}
                      nextViewMode={props.nextViewMode}
                      article={
                        state.selectedArticleViewDetail
                          ? state.selectedArticleViewDetail.article
                          : undefined
                      }
                      viewOnly={true}
                      hideSlideInformationSection={true}
                    />
                  </div>
                </div>
              )}
            <div className="gideViewTab">
              {props.articleTypeList.map(
                (articleType: ChildArticleType, index) =>
                  buildArticleTab(articleType, index),
              )}
            </div>
          </Modal.Header>
        )}

        <Modal.Content
          className={`gideViewerManagerContent ${
            state.selectedArticleViewDetail ? ' hidden' : ''
          }`}
        >
          {hasPendingArticles && (
            <div className="articleApprovalContainer">
              <div
                className={`articleApprovalFilter ${
                  !state.displayPendingArticles ? 'selected' : ''
                }`}
              >
                <RadioButtonComponent
                  checked={!state.displayPendingArticles}
                  label="Approved"
                  name="approvedArticles"
                  value={'APPROVED'}
                  change={(args) => {
                    if (args && args.value === 'APPROVED') {
                      setState({...state, displayPendingArticles: false });
                    }
                  }}
                />
              </div>
              <div
                className={`articleApprovalFilter ${
                  state.displayPendingArticles ? 'selected' : ''
                }`}
              >
                <RadioButtonComponent
                  checked={state.displayPendingArticles}
                  label="Pending approval"
                  name="pendingArrovalArticles"
                  value={'PENDING'}
                  change={(args) => {
                    if (args && args.value === 'PENDING') {
                      setState({...state, displayPendingArticles: true });
                    }
                  }}
                />
              </div>
            </div>
          )}

          <div className="gideViewerManagerContainer">
            {filteredArticleViewDetails &&
              filteredArticleViewDetails.map(
                (articleViewDetail: ArticleViewDetail) => {
                  const isInquiryResponse =
                    articleViewDetail.article.type === 'QUESTIONS' ||
                    articleViewDetail.article.type === 'COMMENTS' ||
                    articleViewDetail.article.type === 'RESPONSE';
                  const canApprove =
                    isInquiryResponse &&
                    articleViewDetail.article.requireApprovalForInquiries &&
                    articleViewDetail.article.status === 'PENDING' &&
                    props.ownerSlide.author.id ===
                      props.currentUser.id;

                  const sortedChildArticles = sort(
                    (a1: ArticleViewDetail, a2: ArticleViewDetail) => {
                      return (
                        new Date(a2.article.updatedAt).getTime() -
                        new Date(a1.article.updatedAt).getTime()
                      );
                    },
                    articleViewDetailList.filter(
                      avd =>
                        avd.parentArticleId === articleViewDetail.article.id &&
                        avd.article.status &&
                        ((avd.article.status === 'APPROVED' &&
                          (!hasPendingArticles ||
                            !state.displayPendingArticles)) ||
                          (avd.article.status === 'PENDING' &&
                            state.displayPendingArticles)),
                    ),
                  );
                  return (
                    <div
                      className="gideViewerDetails view"
                      key={articleViewDetail.article.id}
                    >
                      <div
                        className={`slideList ${
                          sortedChildArticles.length > 0 ? ' inline' : ''
                        }`}
                      >
                        <div className={`${articleViewDetail.article.type}`}>
                          <GideViewer
                            key={articleViewDetail.article.id}
                            currentUser={props.currentUser}
                            view={props.view}
                            viewMode={props.viewMode}
                            nextViewMode={props.nextViewMode}
                            articleViewDetail={articleViewDetail}
                            showInquiryResponseHeader={isInquiryResponse}
                            articleEditMode={props.articleEditMode}
                            viewOnly={props.viewOnly}
                            canApprove={canApprove}
                            disableInlineTextEditing={props.disableInlineTextEditing}
                            slideSelectionModeDetail={
                              state.slideSelectionModeDetail
                            }
                            displayCollapsedHeaders={
                              state.displayCollapsedHeaders
                            }
                            inlineSlideTextEditInfo={
                              state.inlineSlideTextEditInfo
                            }
                            showTableOfContentsView={
                              state.showTableOfContentsView
                            }
                            inlineEditedSlide={state.inlineEditedSlide}
                            inquiryResponseMode={InquiryResponseMode.View}
                            onSubmitArticle={onSubmitArticle}
                            showNotification={props.showNotification}
                            openModal={props.openModal}
                            closeModal={props.closeModal}
                            onArticleInlineSlidesChanged={
                              onArticleInlineSlidesChanged
                            }
                            addSlideArticle={addSlideArticle}
                            onEditArticle={onEditArticle}
                            onDeleteArticle={onDeleteArticle}
                            onUpdateArticleStatus={onUpdateArticleStatus}
                            onReply={onReply}
                            onEditingText={onEditingText}
                            collapseSlides={collapseSlides}
                            enterSlideSelectionMode={
                              enterSlideSelectionMode
                            }

                            setInlineSlideTextEditInfo={
                              setInlineSlideTextEditInfo
                            }
                            updateSlideWithInlineEdits={
                              updateSlideWithInlineEdits
                            }
                            onCreateGide={onCreateGide}
                            submitSlide={submitSlide}
                            updateSlideNumber={updateSlideNumber}
                            deleteSlide={deleteSlide}
                            copySlides={copySlides}
                            moveSlides={moveSlides}
                            toggleTableOfContents={toggleTableOfContents}
                          />
                        </div>
                        {sortedChildArticles.map(
                          (
                            inlineArticleViewDetail: ArticleViewDetail,
                            index: number,
                          ) => {
                            const canApprove =
                              isInquiryResponse &&
                              inlineArticleViewDetail.article
                                .requireApprovalForInquiries &&
                              inlineArticleViewDetail.article.status ===
                                'PENDING' &&
                              props.ownerSlide.author.id ===
                                props.currentUser.id;
                            return (
                              <div
                                className="responseContainer"
                                key={inlineArticleViewDetail.article.id}
                              >
                                <div
                                  className={`${
                                    inlineArticleViewDetail.article.type
                                  }`}
                                  key={index}
                                >
                                  <GideViewer
                                    key={inlineArticleViewDetail.article.id}
                                    currentUser={props.currentUser}
                                    view={props.view}
                                    viewMode={props.viewMode}
                                    nextViewMode={props.nextViewMode}
                                    articleViewDetail={inlineArticleViewDetail}
                                    showInquiryResponseHeader={true}
                                    articleEditMode={props.articleEditMode}
                                    viewOnly={props.viewOnly}
                                    canApprove={canApprove}
                                    disableInlineTextEditing={props.disableInlineTextEditing}
                                    slideSelectionModeDetail={
                                      state.slideSelectionModeDetail
                                    }
                                    displayCollapsedHeaders={
                                      state.displayCollapsedHeaders
                                    }
                                    inlineSlideTextEditInfo={
                                      state.inlineSlideTextEditInfo
                                    }
                                    showTableOfContentsView={
                                      state.showTableOfContentsView
                                    }
                                    inlineEditedSlide={
                                      state.inlineEditedSlide
                                    }
                                    inquiryResponseMode={
                                      InquiryResponseMode.View
                                    }
                                    onSubmitArticle={onSubmitArticle}
                                    showNotification={
                                      props.showNotification
                                    }
                                    openModal={props.openModal}
                                    closeModal={props.closeModal}
                                    onArticleInlineSlidesChanged={
                                      onArticleInlineSlidesChanged
                                    }
                                    addSlideArticle={addSlideArticle}
                                    onEditArticle={onEditArticle}
                                    onDeleteArticle={onDeleteArticle}
                                    onUpdateArticleStatus={
                                      onUpdateArticleStatus
                                    }
                                    onReply={onReply}
                                    onEditingText={onEditingText}
                                    collapseSlides={collapseSlides}
                                    enterSlideSelectionMode={
                                      enterSlideSelectionMode
                                    }
                                    setInlineSlideTextEditInfo={
                                      setInlineSlideTextEditInfo
                                    }
                                    updateSlideWithInlineEdits={
                                      updateSlideWithInlineEdits
                                    }
                                    onCreateGide={onCreateGide}
                                    submitSlide={submitSlide}
                                    updateSlideNumber={updateSlideNumber}
                                    deleteSlide={deleteSlide}
                                    copySlides={copySlides}
                                    moveSlides={moveSlides}
                                    toggleTableOfContents={
                                      toggleTableOfContents
                                    }
                                  />
                                </div>
                              </div>
                            );
                          },
                        )}
                      </div>
                    </div>
                  );
                },
              )}
          </div>
        </Modal.Content>
        <Modal.Content
          className={`gideViewerManagerContent ${
            !state.selectedArticleViewDetail ? ' hidden' : ''
          } ${state.parentArticleViewDetail ? ' withParent' : ''}`}
        >
          <div className="gideViewerManagerContainer">
            {state.selectedArticleViewDetail && (
              <div
                className="gideViewerDetails"
                key={state.selectedArticleViewDetail.article.id}
              >
                <div
                  className={`${
                    state.selectedArticleViewDetail.article.type
                  }`}
                >
                  <GideViewer
                    key={state.selectedArticleViewDetail.article.id}
                    currentUser={props.currentUser}
                    view={props.view}
                    viewMode={'SLIDE'}
                    nextViewMode={props.nextViewMode}
                    articleViewDetail={state.selectedArticleViewDetail}
                    articleEditMode={'advancedEdit'}
                    viewOnly={false}
                    slideSelectionModeDetail={
                      state.slideSelectionModeDetail
                    }
                    displayCollapsedHeaders={state.displayCollapsedHeaders}
                    inlineSlideTextEditInfo={state.inlineSlideTextEditInfo}
                    showTableOfContentsView={state.showTableOfContentsView}
                    disableInlineTextEditing={props.disableInlineTextEditing}
                    showInquiryResponseHeader={
                      state.selectedArticleType ===
                        ChildArticleType.Questions ||
                      state.selectedArticleType ===
                        ChildArticleType.Comments ||
                      state.selectedArticleType ===
                        ChildArticleType.Response
                    }
                    inlineEditedSlide={state.inlineEditedSlide}
                    inquiryResponseMode={InquiryResponseMode.Edit}
                    onSubmitArticle={onSubmitArticle}
                    showNotification={props.showNotification}
                    openModal={props.openModal}
                    closeModal={props.closeModal}
                    onArticleInlineSlidesChanged={
                      onArticleInlineSlidesChanged
                    }
                    addSlideArticle={addSlideArticle}
                    onEditArticle={onEditArticle}
                    onDeleteArticle={onDeleteArticle}
                    onReply={onReply}
                    onEditingText={onEditingText}
                    collapseSlides={collapseSlides}
                    enterSlideSelectionMode={enterSlideSelectionMode}
                    setInlineSlideTextEditInfo={setInlineSlideTextEditInfo}
                    updateSlideWithInlineEdits={updateSlideWithInlineEdits}
                    onCreateGide={onCreateGide}
                    submitSlide={submitSlide}
                    updateSlideNumber={updateSlideNumber}
                    deleteSlide={deleteSlide}
                    copySlides={copySlides}
                    moveSlides={moveSlides}
                    toggleTableOfContents={toggleTableOfContents}
                  />
                </div>
              </div>
            )}
          </div>
        </Modal.Content>
        <Modal.Content className="gideViewerManagerFooter">
          <Footer
            currentUser={props.currentUser}
            article={
              state.selectedArticleViewDetail
                ? state.selectedArticleViewDetail.article
                : undefined
            }
            viewMode={props.viewMode}
            numberOfSlides={
              state.selectedArticleViewDetail
                ? state.selectedArticleViewDetail.slides.length
                : 0
            }
            currentSlidePosition={
              state.selectedArticleViewDetail
                ? state.selectedArticleViewDetail.currentSlidePosition
                : 0
            }
            // scrollToSlideById={scrollToSlideById}
            lastCurrentlyOpenedModal={props.lastCurrentlyOpenedModal}
            headerSlides={state.selectedArticleViewDetail ? state.selectedArticleViewDetail.slides.filter(s => s.slideType === 'HEADER') : undefined}
            canDisplayViewBar={false}
            viewBarAlwaysDisplayed={false}
            allowBlame={footerArticle ? footerArticle.allowBlame : false}
            showBlame={state.showBlame}
            allowSlideComments={
              footerArticle ? footerArticle.allowSlideComments : false
            }
            pauseSlideComments={
              footerArticle ? footerArticle.pauseSlideComments : false
            }
            allowSlideQuestions={
              footerArticle ? footerArticle.allowSlideQuestions : false
            }
            pauseSlideQuestions={
              footerArticle ? footerArticle.pauseSlideQuestions : false
            }
            renderColumns={state.renderColumns}
            displayCollapsedHeaders={state.displayCollapsedHeaders}
            hideEndSlides={state.hideEndSlides}
            textSlideEditing={state.textSlideEditing}
            placeholderText={footerPlaceHolderText}
            slideType={state.currentSlideType}
            openMenu={state.openMenu}
            onCollapseAllSlides={collapseAllSlides}
            onExpandAllSlides={expandAllSlides}
            onToggleColumns={toggleColumns}
            onToggleBlame={toggleBlame}
            toggleTableOfContents={toggleTableOfContents}
            showNotification={props.showNotification}
            toggleCollapsedHeaderDisplay={toggleCollapsedHeaderDisplay}
            toggleEndSlideDisplay={toggleEndSlideDisplay}
            enterSlideSelectionMode={enterSlideSelectionMode}
            // distribute={handleDistribute}
            // createArticle={state.selectedArticleViewDetail && state.selectedArticleViewDetail.article ? undefined : onCreateGide}
            openModal={props.openModal}
            closeModal={props.closeModal}
            getAvailableHeaderLevelsForCurrentSlidePosition={
              getAvailableHeaderLevelsForCurrentSlidePosition
            }
            onSubmitSlide={submitSlide}
            onSubmitMultipleSlides={onSubmitMultipleSlides}
            updateSlideNumber={updateSlideNumber}
            scrollToSlidePosition={scrollToSlidePosition}
            setOpenMenu={setOpenMenu}
            showLeftSidebar={false}
            showRightSidebar={false}
            showSignup={noop}
          />
        </Modal.Content>
      </Modal>
    );
  
}
export default GideViewerManagerModal;
