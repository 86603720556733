import * as React from 'react';
import { useState, useEffect } from 'react';
import SlideEditorModal, {
  SlideEditorProps,
  BackIconMode,
  SlideEditorModalProps,
  EditorState,
  ModalOverlayState,
} from '../../modals/SlideEditorModal/SlideEditorModal';
import { useDispatch } from 'react-redux';
import { Slide, SlideUpdateInfo } from '../../../models/Slide';
import { ThemeProvider } from 'styled-components';
import { lightTheme } from '../../../themes/custom-theme';
import { CircleIconButton } from '../../Shared/CircleIconButton/CircleIconButton';
import { Dispatch } from 'redux';
import { GideAction_ADD_SLIDE, GideAction_MODAL_CLOSE, GideAction_SET_TOASTER_MESSAGE } from '../../../models/GideAction';
import { MODAL_CLOSE, SET_TOASTER_MESSAGE, REPLACE_SLIDE } from '../../../constants/actionTypes';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import { assertNever } from '@uifabric/utilities';
import styles from './_rich-text-slide-editor.module.scss';
import SlideDimensionsPicker from '../../DataEntry/Pickers/Modals/SlideDimensionsPicker/SlideDimensionsPicker';
import produce from "immer"
import { RichTextEditor } from '../../RichTextEditor/RichTextEditor';

export type RichTextSlideEditorEvents = 
  GideAction_ADD_SLIDE | 
  GideAction_MODAL_CLOSE | 
  GideAction_SET_TOASTER_MESSAGE |
  {
    type: typeof REPLACE_SLIDE,
    payload: { 
      slide: Slide,
    },
  }
  ;

export type Mode = 'Edit' | 'Format' | 'Dimensions';

export interface RichTextSlideEditorProps extends SlideEditorProps {
  refreshable?: { mode: Mode };
  pendingInputBarText?: string;
}

interface State {
  mode: Mode;
  slide: Slide;
  tagList: string[];
}

interface DerivedState extends State {
  slideEditorModalProps: SlideEditorModalProps;
  content: React.ReactNode;
  theme: { mode: string };
}

const getHeaderTitleAndIcon = (title: string, icon: string, color: 'black' | 'white') => {
  return (
    <div className={`headerActionsFlexEnd TEXTSUBTITLE${color}high-emphasisleft`}>
      <div
        style={{
          marginRight: '6px',
        }}
      >
        {title}
      </div>
      <CircleIconButton
        width={30}
        height={30}
        alt={title}
        backgroundColor={color === 'white' ? 'var(--COLOR-BLUE-100)' : 'var(--COLOR-PRIMARY-600)'}
        iconCssClass="whites-normal-1000-svg"
        image={icon}
      />
    </div>
  );
};

const getSlide = (props: RichTextSlideEditorProps) => {
  const slide: Slide = props.editSlide
    ? {
        ...props.editSlide,
        data: {
          ...props.editSlide.data,
          // caption: props.editSlide.data.title,
          files: [{}],
        },
      }
    : {
        id: '',
        author: {
          id: '',
          image: props.currentUser.image,
          username: props.currentUser.username,
          connections: {following: []},
          favorites: [],
          views: [],
          following: false,
          verified: false,
        },
        position: props.position,
        slideType: 'TEXT',
        data: {
          caption: '', // props.article.title,
          files: [{}],
          slideTypeSpecificSettings: {
            format: undefined,
          },
          body: props.pendingInputBarText, // [{ type: 'paragraph', children: [{text: props.pendingInputBarText}] }], // set it to JSON, if you want to use the "new" Slate Rich Text Editor.
          fullEditor: true,
        },
        width: "NORMAL",
        widthPercentage: undefined,
      };
  return slide;
}

const onAnswerQuestion = () => {};
const onCloseAllModals = () => {};
const onNavigateBack = () => {};
const onShowPreview = () => {};

const getSlideEditorModalProps = (context: Context): SlideEditorModalProps => {
  const { props, state, setState, closeModal, onSubmitSlide, showNotification } = context;
  
  const common = {
    slide: context.state.slide,
    article: props.article,
    currentUser: props.currentUser,
    position: props.position,
    closeModal: closeModal,
    onSubmitSlide: onSubmitSlide,
    showNotification: showNotification,
    onAnswerQuestion: onAnswerQuestion,
    onCloseAllModals: onCloseAllModals,
    onNavigateBack: onNavigateBack,
    onShowPreview: onShowPreview,
    addTitleSlideProcessingInfo: () => {},
    onSlideUpdated: (slide: Slide) => {
      setState({
        ...state,
        mode: state.mode,
        slide: {
          ...slide,
          data: {
            ...slide.data,
            //title: slide.data.caption as string,
          },
        },
      });
    },
    onDisplaySlideWidthSettings: () => {
      setState({ ...state, mode: 'Dimensions' });
    },
    updateGideDescription: (description: string) => {
      setState({
        ...state,
      });
    },
  };

  switch (state.mode) {
    case 'Edit': {
      return {
        ...common,
        backIconMode: BackIconMode.Light,
        hideFooter: false,
        hideCommandBar: false,
        displaySlideAttribution: false,
        question: undefined,
        editorState: EditorState.Edit,
        loadingInfo: undefined,
        modalOverlayState: ModalOverlayState.None,
        selectedItemIndex: -1,
        hideActionContainer: true,
        showBackNavigation: false,
        showDefaultActions: true,
        showDeleteFile: false,
        createActions: [] as any,
        tagList: state.tagList,
        gideDescription: undefined,
        gideDescriptionSlideId: undefined,
        isSlideZero: false,
        headerActions: (
          <>
            <div className="headerActionsFlexEnd">
              {/* <ExpandingIconButton
                style={{
                  marginLeft: '9px',
                  marginRight: '5px',
                  paddingLeft: '9px',
                  height: '32px',
                  backgroundImage: 'linear-gradient(290deg, #faa114, #fab317)',
                }}
                iconCssClass="color-secondary-300-svg"
                expandedIconCssClass={classNames(styles.formatIcon, 'whites-normal-1000-svg')}
                circleIconButtonCssClass={styles.formatCircleIconButtonCssClass}
                iconBackgroundColor="transparent"
                alt="sticker"
                src="/icons/slidetype/image/main.svg"
                expandedSrc="/icons/slidetype/image/main.svg"
                expandWidth={108}
                label="Format"
                onClick={e => {
                  setState({ ...state,mode: 'Format', slide: context.state.slide });
                }}
                labelLocation={HorizontalAlignment.Left}
                expanded={true}
                visible={true}
              /> */}
            </div>
          </>
        ),
        onNavigateBack: () => {
          closeModal();
        },
      };
    }
    case 'Format': {
      return {
        ...common,
        backIconMode: BackIconMode.Light,
        hideFooter: false,
        hideCommandBar: false,
        displaySlideAttribution: false,
        question: undefined,
        editorState: EditorState.Edit,
        loadingInfo: undefined,
        modalOverlayState: ModalOverlayState.None,
        selectedItemIndex: -1,
        hideActionContainer: true,
        showBackNavigation: true,
        showDefaultActions: false,
        showDeleteFile: false,
        createActions: [] as any,
        tagList: state.tagList,
        headerActions: getHeaderTitleAndIcon('Format', '/icons/creationprocess/bloglist/image-top.svg', 'black'),
        onNavigateBack: () => {
          setState({ ...state,mode: 'Edit', slide: context.state.slide });
        },
      };
    }
    case 'Dimensions': {
      return {
        ...common,
        backIconMode: BackIconMode.Light,
        hideFooter: false,
        hideCommandBar: false,
        displaySlideAttribution: false,
        question: undefined,
        editorState: EditorState.Edit,
        loadingInfo: undefined,
        modalOverlayState: ModalOverlayState.None,
        selectedItemIndex: -1,
        hideActionContainer: true,
        showBackNavigation: true,
        showDefaultActions: false,
        showDeleteFile: false,
        createActions: [] as any,
        tagList: state.tagList,
        gideDescription: undefined,
        gideDescriptionSlideId: undefined,
        isSlideZero: false,
        headerActions: getHeaderTitleAndIcon('Slide Dimensions', '/icons/nav/display/normal.svg', 'black'),
        onNavigateBack: () => {
          setState({ ...state, mode: 'Edit', slide: context.state.slide });
        },
      };
    }
    default: {
      return assertNever(state.mode);
    }
  }
}

const getContent = (context: Context, slideEditorModalProps: SlideEditorModalProps): React.ReactNode => {
  const { state, setState, showNotification } = context;
  const article = context.props.article;
  const onModifiedTitle = (newTitle: string) => {
    setState(produce((state: State) => {
      state.slide.data.title = newTitle;
    }));
  };

  const slideView = 
    <RichTextEditor key="RichTextSlideEditor"
      toolbars 
      value={state.slide?.data?.body} 
      onChange={(value) => {
        // TODO
        setState({
          ...state,
          mode: state.mode,
          slide: {
            ...state.slide,
            data: {
              ...state.slide.data,
              //title: slide.data.caption as string,
              body: value || ' ',
            },
          },
        });
      }} 
    />;

  switch (state.mode) {
    case 'Edit': {
      return slideView;
    }
    case 'Format': {
      return (
        <div style={{ position: 'relative' }}>
          <div>
            {/* TODO */}
          </div>
          {slideView}
        </div>
      );
    }
    case 'Dimensions': {
      return (
        <div style={{ position: 'relative' }}>
          <div>
            <SlideDimensionsPicker
              className={styles.dimensionsModal}
              onSave={() => {
                setState({ mode: 'Edit', slide: state.slide, tagList: state.tagList });
              }}
              onCancel={(slideWidthType, slideAlignment, slideWidthPercentage) => {
                setState({
                  ...state,
                  mode: 'Edit',
                  slide: {
                    ...state.slide,
                    width: slideWidthType,
                    contentHorizontalAlignment: slideAlignment,
                    widthPercentage: slideWidthPercentage,
                  },
                });
              }}
              onChange={(slideWidthType, slideAlignment, slideWidthPercentage) => {
                setState({
                  ...state,
                  mode: state.mode,
                  slide: {
                    ...state.slide,
                    width: slideWidthType,
                    contentHorizontalAlignment: slideAlignment,
                    widthPercentage: slideWidthPercentage,
                  },
                });
              }}
              slideWidthType={state.slide.width || 'NORMAL'}
              slideAlignment={state.slide.contentHorizontalAlignment || 'CENTER'}
              slideWidthPercentage={state.slide.widthPercentage || 100}
            />
          </div>
          {slideView}
        </div>
      );
    }
    default: {
      return assertNever(state.mode);
    }
  }
}

const getDerivedState = (context: Context): DerivedState => {
  const slideEditorModalProps = getSlideEditorModalProps(context);
  return {
    ...context.state,
    slideEditorModalProps: slideEditorModalProps,
    content: getContent(context, slideEditorModalProps),
    theme: lightTheme,
  };
};

interface Context {
  props: RichTextSlideEditorProps;
  state: State;
  setState: (value: React.SetStateAction<State>) => void;
  onSubmitSlide: (payload: SlideUpdateInfo) => Promise<void>;
  closeModal: () => void;
  showNotification: (toasterMessageInfo: ToasterMessageInfo) => void;
}

export const RichTextSlideEditor: React.FC<RichTextSlideEditorProps> = (props) => {
  const [state, setState] = useState<State>(() => {
    const slide = getSlide(props);
    const mode: Mode =
        props.refreshable && props.refreshable.mode ? props.refreshable.mode
        : "Edit";
    return { 
      mode, 
      slide,
      tagList: [...props.article.tagList]
    };
  });
  useEffect(() => {
    setState({
        mode: (props.refreshable && props.refreshable.mode) || state.mode,
        slide: state.slide,
        tagList: state.tagList,
    });
  }, [props.refreshable && props.refreshable.mode]);
  const dispatch = useDispatch<Dispatch<RichTextSlideEditorEvents>>();
  const closeModal = () => dispatch({ type: MODAL_CLOSE });
  const showNotification =
    props.showNotification ||
    ((toasterMessageInfo: ToasterMessageInfo) => dispatch({ type: SET_TOASTER_MESSAGE, payload: { toasterMessageInfo } }));
  const onSubmitSlide = async (payload: SlideUpdateInfo) => {    
    // dispatch({
    //     type: REPLACE_SLIDE,
    //     payload: {
    //       slide: payload.slide,
    //     }
    //   });
    props.onSubmitSlide(payload);
  }
  const context: Context = {
    props,
    state,
    setState,
    onSubmitSlide,
    closeModal,
    showNotification,
  };

  const derivedState = getDerivedState(context);

  const validationError = undefined;

  return (
    <ThemeProvider theme={derivedState.theme}>
      <SlideEditorModal {...derivedState.slideEditorModalProps} validationError={validationError}>
        {derivedState.content}
      </SlideEditorModal>      
    </ThemeProvider>
  );
}
