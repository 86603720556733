import React, { useEffect, useState } from 'react';
import { ChannelEditorModeProps } from './ChannelEditorModesShared';
import SlideEditorCommandBar from '../../SlideEditors/SlideEditorCommandBar/SlideEditorCommandBar';
import { BackIconMode } from '../../modals/SlideEditorModal/SlideEditorModal';
import { darkTheme } from '../../../themes/custom-theme';
import { EditorState } from '../../Navigation/CreationProcess/EditorState/EditorState';
import icons from '../../../assets/icons';
import ImageEditor from '../../Viewers/ImageViewers/ImageEditor/ImageEditor';
import { ensureImageFileOrientation } from '../../../utils/helperFunctions';
import { v4 } from 'uuid';
import { ImageFile } from '../../../models/SlideFile';
import ProgressBar from '../../Shared/ProgressBar/ProgressBar';

export const ChannelEditorModeCrop: React.FC<ChannelEditorModeProps> = (props) => {
  
  const navigateBack = () => {
    props.setMode('Edit');
  };

  const [content, setContent] = useState(<></>);

  useEffect(() => {
    setContent(<>
      {props.mode !== 'CroppingImage' && 
        <div style={{
          display: 'flex', 
          flex: 1, 
          maxWidth: '100%',  
          height: '100%', 
          flexDirection: 'row'}}>      
          <ImageEditor
            image={{url: props.image?.url || '', type: 'IMAGE'}}
            saveImage={async (image: any) => {
              props.setMode('CroppingImage');
              // catch the returned data and add to list. will need to save it
              const imageFile = await ensureImageFileOrientation(image);          
              const croppedFile: ImageFile = {
                id: v4(),
                name: 'name',
                url: URL.createObjectURL(imageFile),
                dataUrl: imageFile,
                processingId: v4(),
                type: 'UPLOAD',
                caption: '',
              };
              props.setImage(croppedFile);
              props.setMode('Edit');
            }}
            responseType='base64'         
            cropperOptions={{
              guides : true,
              autoCropArea: 1,
              rotatable: true,
              aspectRatio: 1.5
            }}
          />
        </div>
      }
      {props.mode === 'CroppingImage' && 
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <div className="loadingOverlay">
            <div className="progressIndicator">
              <ProgressBar indicatorWidth={30} />
            </div>
            <span className="loadingMessage">
              Cropping image
            </span>
          </div>
        </div>
      }
    </>);
  }, [props.mode]);

  return (
    <div style={{ 
      backgroundColor: 'black',
      width: '100%',
      height: '100%',
      // display: 'flex',
      // flexDirection: 'column',
    }}>
      <SlideEditorCommandBar
        backIconMode={BackIconMode.Dark}
        showBackNavigationButton={true}
        showDefaultActions={false}
        onExit={navigateBack}
        onNavigateBack={navigateBack}
        theme={darkTheme}
      >
        <EditorState title={props.mode === 'Crop' ? 'Crop channel image' : 'Cropping channel image'}
          labelColor="var(--WHITES-NORMAL-1000)"
          backgroundColor="var(--COLOR-BLUE-100)"
          // backgroundColor="var(--COLOR-PRIMARY-600)"
          icon={<icons.Nav_Channels color="var(--WHITES-NORMAL-1000)" />}
        />
      </SlideEditorCommandBar>
      {content}
    </div>
  );
}
