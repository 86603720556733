import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Icon } from 'semantic-ui-react';

import { SlideList } from '../Gide/SlideList/SlideList';
import { ADD_SLIDE, MODAL_CLOSE } from '../../constants/actionTypes';

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  viewMode: state.common.viewMode,
  slides: state.article.slides,
});

const mapDispatchToProps = dispatch => ({
  onSubmit: payload => dispatch({ type: ADD_SLIDE, payload }),
  closeModal: payload => dispatch({ type: MODAL_CLOSE }),
});

export class AdditionSlidesModal extends Component {
  render() {
    let slides = this.props.slides || [];
    let additionSlides = slides[this.props.slideNumber].additionSlides;

    return (
      <Modal
        closeOnEscape={true}
        onClose={this.props.closeModal}
        className="additionSlidesModal"
        size="large"
        dimmer="inverted"
        closeOnDocumentClick={true}
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <div className="modalHeader flexRowSpaceBetween">
            <span>Additions</span>
            <Button id="modalClose" icon onClick={this.props.closeModal}>
              <Icon name="close" />
            </Button>
          </div>
          <section className="inner">
            <SlideList
              slides={additionSlides}
              // showParent={true}
              // showParentAuthor={true}
              // showAuthor={true}
              // hideNumbers
            />
          </section>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  AdditionSlidesModal,
);
