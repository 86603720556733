import React, { Component } from 'react';
import { Slide } from '../../../models/Slide';
import { User } from '../../../models/User';
import ChildArticleEditorSlide from '../ChildArticleEditor/ChildArticleEditorSlide';
import { Article } from '../../../models/Article';
import { ChildArticleEditorInfo } from '../../../models/ChildArticleEditorInfo';
import ChildArticleViewer from '../ChildArticleViewer/ChildArticleViewer';
import agent from '../../../agent';
import {
  ChildArticleType,
  getChildArticleTypeName,
} from '../../../models/ArticleLayoutEnum';
import { Dictionary, SlideshowSlideInfo } from '../../../models/SlideShowSlide';
import { urlForArticle } from '../../../utils/helperFunctions';
import { history } from '../../../store';
import { SlideshowPlayer } from './SlideshowPlayer';
import { SlideshowSlideHeader } from './SlideshowSlideHeader';
import { isNullOrUndefined } from 'util';
import { ViewMode } from '../../Gide/SlideView/SlideView';

export interface SlideshowSlideProps {
  currentUser: User;
  article: Article;
  slide: Slide;
  childArticleEditInfo?: ChildArticleEditorInfo;
  showChrome: boolean;
  appName: string;
  view?: string;
  viewMode: ViewMode;
  nextViewMode: ViewMode;
  toggleChildArticleViewMode?: (
    payload: { slideId: string; articleType: string },
  ) => void;
  openChildArticleModal?: (slide: Slide, childArticleType: ChildArticleType, viewOnly?: boolean) => void;
  exitChildArticleEditor?: () => void;
  showNotification?: (
    payload: { toasterMessageInfo: { message: string; type: any } },
  ) => void;
  // setLoading: () => void;
  setNextViewMode: (mode: ViewMode) => void;
  onSetViewMode: (mode: ViewMode) => void;
}

interface SlideshowSlideState {
  slides: Slide[];
  slideshowSlideArticle?: Article;
  slideshowSlidesInfo: Dictionary<SlideshowSlideInfo>;
  isViewerExpanded: boolean;
  isLoading: boolean;
  playSlideshow: boolean;
}

export default class SlideshowSlide extends Component<
  SlideshowSlideProps,
  SlideshowSlideState
> {
  constructor(props: SlideshowSlideProps) {
    super(props);
    this.state = {
      slides: [],
      slideshowSlidesInfo: {},
      isViewerExpanded: false,
      isLoading: true,
      playSlideshow: false,
    };
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps: SlideshowSlideProps) {
    if (
      prevProps.article !== this.props.article ||
      prevProps.slide !== this.props.slide
    ) {
      this.load();
    }
  }

  // shouldComponentUpdate(
  //   nextProps: SlideshowSlideProps,
  //   nextState: SlideshowSlideState,
  // ) {
  //   const shouldUpdate =
  //     ((!isNullOrUndefined(nextProps) && !isNullOrUndefined(this.props)) ||
  //     !isNullOrUndefined(this.props)) &&
  //     (nextProps.article !== this.props.article ||
  //     nextProps.childArticleEditInfo !== this.props.childArticleEditInfo ||
  //     nextProps.slide !== this.props.slide ||
  //     nextState.slideshowSlideArticle !== this.state.slideshowSlideArticle ||
  //     nextState.slides !== this.state.slides ||
  //     nextState.playSlideshow !== this.state.playSlideshow ||
  //     nextState.isViewerExpanded !== this.state.isViewerExpanded ||
  //     nextState.slideshowSlidesInfo !== this.state.slideshowSlidesInfo ||
  //     (nextState.isLoading !== this.state.isLoading && !this.state.isLoading) ||
  //     nextProps.viewMode !== this.props.viewMode ||
  //     nextProps.nextViewMode !== this.props.nextViewMode ||
  //     !isNullOrUndefined(nextProps.slide)
  //       ? nextProps.slide.data.headerCaption
  //       : undefined !== !isNullOrUndefined(this.props.slide)
  //         ? this.props.slide.data.headerCaption
  //         : undefined);

  //   return shouldUpdate;
  // }

  async load() {
    this.setState({ isLoading: true });
    const article = await agent.Slides.getSettings(
      this.props.slide.id,
      getChildArticleTypeName(ChildArticleType.Slideshow),
    );
    const resp = await agent.Slides.forArticle(article.article);
    const slideshowSlidesInfo = this.props.slide
      ? this.props.slide.data.slideshowSlidesInfo
      : {};
    const sortedSlides = resp.slides
      ? resp.slides.sort((s1: Slide, s2: Slide) => {
          let s1StartTime =
            slideshowSlidesInfo && slideshowSlidesInfo[s1.id]
              ? slideshowSlidesInfo[s1.id].startTimeInSeconds
              : 0;
          let s2StartTime =
            slideshowSlidesInfo && slideshowSlidesInfo[s2.id]
              ? slideshowSlidesInfo[s2.id].startTimeInSeconds
              : 0;
          const timeDiff = s1StartTime - s2StartTime;
          return timeDiff !== 0 ? timeDiff : s1.position - s2.position;
        })
      : [];
    this.setState({
      slideshowSlideArticle: article.article,
      slides: sortedSlides,
      slideshowSlidesInfo: slideshowSlidesInfo,
      isViewerExpanded: false,
      isLoading: false,
      playSlideshow: false,
    });
  }
  collapseExpandViewer = () => {
    this.setState({ isViewerExpanded: !this.state.isViewerExpanded });
  };

  exitSwipeMode = () => {
    // if (this.props.nextViewMode === 'SLIDESHOW') {
    //   this.props.onSetViewMode('SLIDE');
    //   this.props.setNextViewMode('SLIDE');
    //   const url = this.props.slide.position
    //     ? `${urlForArticle(this.props.article)}?slide=${this.props.slide
    //         .position + 1}`
    //     : `${urlForArticle(this.props.article)}`
    //   history.push(url, undefined);
    // }
  };

  playSlideshow = () => {
    // if (this.props.viewMode !== 'SWIPE') {
      // this.props.setLoading();
      this.props.setNextViewMode(this.props.viewMode);
      this.props.onSetViewMode('SWIPE');
    // } else {
    //   this.props.setNextViewMode(this.props.viewMode);
    // }
    window.setTimeout(() => {
      history.push(
        `${urlForArticle(this.props.article)}/slideshow/${this.props.slide.id}`,
      );
    }, 200);
  };

  handleShowingEditorOrViewer = (e: any) => {
    if (e) {
      e.stopPropagation();
    }
    this.props.openChildArticleModal &&
    this.props.openChildArticleModal(this.props.slide, ChildArticleType.Slideshow, false);
  };

  render() {
    if (this.state.isLoading) {
      return <div />;
    }
    const slideId = this.props.slide.id ? this.props.slide.id : '';
    const isAuthor =
      this.props.currentUser &&
      this.props.slide.author &&
      this.props.slide.author.username === this.props.currentUser.username
        ? true
        : false;
    const isEditing =
      this.props.childArticleEditInfo &&
      this.props.slide.id === this.props.childArticleEditInfo.ownerSlide.id &&
      this.props.childArticleEditInfo.article.type === 'SLIDESHOW'
        ? true
        : false;
    const isSwipeView = this.props.viewMode === 'SWIPE';
    const viewerIsExpanded = this.state.isViewerExpanded;
    const slideshowTitle =
      this.props.slide && this.props.slide.data
        ? this.props.slide.data.headerCaption
        : '';
    // const playSlideshow = false;//this.state.playSlideshow || (isSwipeView && this.props.nextViewMode === 'SLIDESHOW');
    // const classes = playSlideshow ? 'container page flexColumnFull' : '';
    const classes = '';
    // const style = this.props.slide.slideType === 'SLIDESHOW' ? {height: '100%', display: 'flex', alignItems: 'center'} : {};
    // const style = playSlideshow ? {height: '100%'} : {height: '100%', display: 'flex', alignItems: 'center'};
    const style = {height: '100%', display: 'flex', alignItems: 'center'};
    return (
      <div className={classes} style={style}>
        {/* {!playSlideshow && ( */}
          <div className="slideshowSlideSlide">
            {!isSwipeView && (
              <div>
                {isAuthor &&
                  this.props.viewMode === 'SLIDE' &&
                  this.props.slide.data && (
                    <div>
                      <SlideshowSlideHeader
                        viewerIsExpanded={viewerIsExpanded}
                        isEditing={isEditing}
                        showHeaderCaption={
                          !this.state.slides.length || this.state.slides.length <= 0
                        }
                        headerCaption={slideshowTitle}
                        handleShowingEditorOrViewer={
                          this.handleShowingEditorOrViewer
                        }
                        collapseExpandViewer={this.collapseExpandViewer}
                      />
                    </div>
                  )}
                {/* Show Editor */}
                {isEditing && !isNullOrUndefined(this.props.childArticleEditInfo) && (
                  <ChildArticleEditorSlide
                    article={this.props.article}
                    childArticleEditInfo={this.props.childArticleEditInfo}
                    currentUser={this.props.currentUser}
                    view={this.props.view}
                    viewMode={this.props.viewMode}
                    slide={this.props.slide}
                  />
                )}
                {/* Show Viewer */}
                {viewerIsExpanded &&
                  !isEditing &&
                  this.state.slideshowSlideArticle && (
                    <ChildArticleViewer
                      removeBorder={true}
                      slideId={slideId}
                      slide={this.props.slide}
                      articleType={this.state.slideshowSlideArticle.type}
                      view={this.props.view}
                      viewMode={this.props.viewMode}
                      hideFooter={true}
                      currentUser={this.props.currentUser}
                    />
                  )}
              </div>
            )}
            {/* Show Play Slideshow */}
            {!viewerIsExpanded &&
              this.props.slide.data.slideshowSlideDetails &&
              !isEditing &&
              this.state.slides.length > 0 &&
              this.props.article && (
                <div
                  className="swipeableContainer flexColumnFull"
                  onClick={this.playSlideshow}
                >
                  <div className="row article-content flexColumnFull EMBED chrome">
                    <SlideshowPlayer
                      article={this.props.article}
                      showChrome={this.props.showChrome}
                      showCaptionPanel={true}
                      showInfoPanel={true}
                      appName={this.props.appName}
                      currentUser={this.props.currentUser}
                      articleSlug={this.props.article.slug}
                      slideshowSlides={this.state.slides}
                      slideshowSlidesInfo={this.state.slideshowSlidesInfo}
                      slideshowBeginTime={
                        this.props.slide.data.slideshowSlideDetails.beginTime
                      }
                      slideshowDuration={
                        this.props.slide.data.slideshowSlideDetails.endTime
                      }
                      showSlideshowControls={
                        this.props.slide.data.slideshowSlideDetails
                          .showSlideshowControls
                      }
                      useAudioTrack={
                        this.props.slide.data.slideshowSlideDetails.useAudioTrack
                      }
                      useSlideTimings={this.props.slide.data.slideshowSlideDetails.useTimeDuration}
                      view={this.props.view}
                      viewMode={this.props.viewMode}
                      nextViewMode={this.props.nextViewMode}
                      slideshowTitle={slideshowTitle}
                      slideshowImageUrl={
                        this.props.slide.data.slideshowImage
                          ? this.props.slide.data.slideshowImage
                          : this.props.article.image
                      }
                      audioTrack={this.props.slide.data.audioTrack}
                      playSlideshow={false}
                      finishedPlayingSlideshow={this.exitSwipeMode}
                      // setLoading={this.props.setLoading}
                      setNextViewMode={this.props.setNextViewMode}
                      onSetViewMode={this.props.onSetViewMode}
                    />
                  </div>
                </div>
              )}
          </div>
        {/* )} */}
      </div>
    );
  }
}
