import React, { Component } from 'react';
import { Icon, IconGroup } from 'semantic-ui-react';
import { API_ROOT } from '../../constants/paths';
import superagent from 'superagent';

export class AudioCapture extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recording: false,
      fileRecorded: false,
    }

    this.onMediaRecorderClick = this._onMediaRecorderClick.bind(this);
  };

  recordAudio() {
    return new Promise(resolve => {
      navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
        const mediaRecorder = new MediaRecorder(stream, {
          mimeType: 'video/webm',
        });
        const audioChunks = [];

        mediaRecorder.addEventListener('dataavailable', event => {
          audioChunks.push(event.data);
        });

        this.setState({ mediaRecorder });

        const start = () => {
          mediaRecorder.start();
        };

        const stop = () => {
          return new Promise(resolve => {
            mediaRecorder.addEventListener('stop', () => {
              const audioBlob = new Blob(audioChunks, { type: 'audio/mp4' });
              const audioUrl = URL.createObjectURL(audioBlob);
              const audio = new Audio(audioUrl);
              const play = () => {
                audio.play();
              };

              resolve({ audioBlob, audioUrl, play });
            });

            mediaRecorder.stop();
          });
        };

        resolve({ start, stop });
      });
    });
  }

  async _onMediaRecorderClick() {
    if (!this.state.mediaRecorder || this.state.mediaRecorder.state !== 'recording') {
      const recorder = await this.recordAudio();
      recorder.start();
      this.setState({ recorder });
    } else {
      if (!this.state.mediaRecorder) return;

      if (this.state.mediaRecorder.state === 'inactive') {
        alert('Not recording.');
        return;
      }
      if (this.state.mediaRecorder.state === 'recording') {
        const audio = await this.state.recorder.stop();
        superagent
          .post(`${API_ROOT}/util/upload`)
          .attach('theseNamesMustMatch', audio.audioBlob, 'foo.m4a')
          .end((err, res) => {
            if (err) {
              return alert('Please try again.');
            }
            let f = {
              name: 'recorded audio',
              url: res.body.url,
            };
            this.props.onAudioCaptionChanged(f);
          });
      }
      let tracks = this.state.mediaRecorder.stream.getTracks();
      tracks.forEach(t => {
        t.stop();
      });
      this.setState({ mediaRecorder: null });
      this.setState({ fileRecorded: true })
    }
  }

  render() {
    return (
      <span style={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }} className="audioCaptionIcon">
        <span>
          {(this.state.fileRecorded && (!this.state.mediaRecorder || this.state.mediaRecorder.state !== 'recording')) &&
          (
            <IconGroup size='large' onClick={this.onMediaRecorderClick}>
              <Icon inverted name='check circle outline'/>
              {this.props.audioFile && (<Icon inverted corner name='microphone'/>)}
            </IconGroup>
          )}
          {(!this.state.fileRecorded && (!this.state.mediaRecorder || this.state.mediaRecorder.state !== 'recording')) &&
          (
            <IconGroup size='large' onClick={this.onMediaRecorderClick}>
              <Icon inverted name='microphone'/>
              {this.props.audioFile && (<Icon inverted corner name='file audio outline'/>)}
            </IconGroup>
          )}
        </span>
        <span>
          {this.state.mediaRecorder && this.state.mediaRecorder.state === 'recording' &&
          (
            <Icon
              size='large'
              onClick={this.onMediaRecorderClick}
              name='stop circle outline'
              inverted
            />
          )}
        </span>
      </span>
    );
  }
}

export default AudioCapture;