import React, { useState, ChangeEvent } from 'react';
import { languages } from '../../../utils/useLanguage';
import MenuItem from '@material-ui/core/MenuItem';
import { uuid4 } from '../../../lib/SketchField/utils';
import TextField from '@material-ui/core/TextField';

export interface LanguagePickerProps {
  code?: string;
  onChange?: (code?: string) => void;
}

export const LanguagePicker: React.FC<LanguagePickerProps> = (props) => {
  const [id] = useState(() => uuid4());
  const code = props.code || '';
  return (
    <div
      style={{
        margin: "10px"
      }}
    >
      <TextField 
        id={id} 
        label="Language"
        fullWidth
        select
        value={code}
        onChange={(e: ChangeEvent<HTMLInputElement>) => props.onChange?.(e.target.value)}
      >
        <MenuItem value="">&nbsp;</MenuItem>
        {languages.map(l => 
          <MenuItem value={l.code}>
            <i className={l?.flag + ' flag'}></i>
            {l.display}
          </MenuItem>
        )}      
      </TextField>
    </div>
  );
}
