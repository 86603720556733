import React from 'react';
import { List } from 'semantic-ui-react';
import GideImage from '../Shared/Image/GideImage';

const ContactsSlide = props => {
  return (
    <div>
      {props.slide.data.title && (
        <h4 style={{ textAlign: 'center' }}>{props.slide.data.title}</h4>
      )}
      <List divided selection size="large">
        {props.slide.data.contacts.map((contact, i) => {
          return (
            <List.Item key={i}>
              <GideImage
                avatar
                src={contact.image ? contact.image : "https://static.productionready.io/images/smiley-cyrus.jpg"}
              />
              <List.Content>
                <List.Header as="a">{contact.name}</List.Header>
                <List.Description>
                  {contact.link && <a href={contact.link}>View</a>}
                  {contact.phone && <div>{contact.phone}</div>}
                </List.Description>
              </List.Content>
            </List.Item>
          );
        })}
      </List>
    </div>
  );
};

export default ContactsSlide;
