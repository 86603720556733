import React, { useState, useEffect, useRef } from 'react';
import PickerModal from '../PickerModal/PickerModal';
import Picker, { PickerItem } from '../../Picker/Picker';
import icons from '../../../../../assets/icons';
import pickerModalStyles from '../PickerModal/_picker-modal.module.scss';
import styles from './_slide-dimensions-picker.module.scss';
import { Slider } from 'office-ui-fabric-react/lib/Slider';
import { Input, InputOnChangeData } from 'semantic-ui-react';
export enum SlideWidthType {
  Normal = 1,
  Half = 2,
  Edge = 3
}
export enum SlideAlignment {
  Left = 1,
  Center = 2,
  Right = 3
}
export interface  SlideDimensionsPickerProps {
  className?: string;
  slideWidthType: string;
  slideAlignment: string;
  slideWidthPercentage: number;
  displayAsNonModal?: boolean;
  onCancel?: (slideWidthType: string, slideAlignment: string, slidePercentage: number) => void;
  onSave?: (slideWidthType: string, slideAlignment: string, slidePercentage: number) => void;
  onChange: (slideWidthType: string, slideAlignment: string, slidePercentage: number) => void;
  onUnhandled?: (slideWidthType: string, slideAlignment: string, slidePercentage: number) => void;
}
const slideWidthTypes: PickerItem[] = [
  {icon: icons.Nav_Display_Normal, label: "Normal", value: SlideWidthType.Normal},
  {icon: icons.Nav_Display_Halfandhalf, label: "half and half", value: SlideWidthType.Half},
  {icon: icons.Nav_Display_MaxWidth, label: "Max width", value: SlideWidthType.Edge},
];

const slideAlignments: PickerItem[] = [
  {icon: icons.CreationProcess_Justification_JustificationLeft, label: "Left", value: SlideAlignment.Left},
  {icon: icons.CreationProcess_Justification_JustificationCenter, label: "Center", value: SlideAlignment.Center},
  {icon: icons.CreationProcess_Justification_JustificationRight, label: "Right", value: SlideAlignment.Right},
];

export default function SlideDimensionsPicker (props:  SlideDimensionsPickerProps) {
  const [originalSlideWidthType] = useState(props.slideWidthType);
  const [originalSlideAlignment] = useState(props.slideAlignment);
  const [originalSlideWidthPercentage] = useState(props.slideWidthPercentage);  
  const handled = useRef(false);
  useEffect(() => {
    return () => {
      if (!handled.current && props.onUnhandled) {
        props.onUnhandled(originalSlideWidthType, originalSlideAlignment, originalSlideWidthPercentage);
      }
    }
  }, []);

  const getSlideWidthTypeString  = (slideWidthType: SlideWidthType): string => {
    switch (slideWidthType) {
      case SlideWidthType.Edge:
        return 'EDGE';
      case SlideWidthType.Half:
        return 'HALF';
      case SlideWidthType.Normal:
        return 'NORMAL';
      default: 
        return 'NORMAL';      
    }
  }
  const getSlideWidthTypeEnum  = (slideWidthType: string): SlideWidthType => {
    switch (slideWidthType) {
      case 'EDGE':
        return SlideWidthType.Edge;
      case 'HALF':
        return SlideWidthType.Half;
      case 'NORMAL':
        return SlideWidthType.Normal;
      default: 
        return SlideWidthType.Normal;      
    }
  }
  const getSlideAlignmentString  = (slideAlignment: SlideAlignment): string => {
    switch (slideAlignment) {
      case SlideAlignment.Left:
        return 'LEFT';
      case SlideAlignment.Center:
        return 'CENTER';
      case SlideAlignment.Right:
        return 'RIGHT';
      default: 
        return 'CENTER';      
    }
  }
  const getSlideAlignmentEnum  = (slideAlignment: string): SlideAlignment => {
    switch (slideAlignment) {
      case 'LEFT':
        return SlideAlignment.Left;
      case 'CENTER':
        return SlideAlignment.Center;
      case 'RIGHT':
        return SlideAlignment.Right;
      default: 
        return SlideAlignment.Center;      
    }
  }
  return (
    <PickerModal
      className={props.className}
      onCancel={() => {
        handled.current = true;
        props.onCancel && props.onCancel(originalSlideWidthType, originalSlideAlignment, originalSlideWidthPercentage);
      }}
      onSave={() => {
        handled.current = true;
        props.onSave && props.onSave(props.slideWidthType, props.slideAlignment, props.slideWidthPercentage);
      }}
      hideActions={props.displayAsNonModal}
    >
      <div className={styles.slideDimensionsPickerModalContainer}>
        <Picker
          pickerItems={slideWidthTypes}
          title="Slide width"
          selectedValue={getSlideWidthTypeEnum(props.slideWidthType)}
          onChange={(slideWidthType: SlideWidthType) => props.onChange(getSlideWidthTypeString(slideWidthType), props.slideAlignment, props.slideWidthPercentage)}
        />

        <div className={pickerModalStyles.horizontalLine} style={{marginTop: '7px', marginBottom: '25px'}} />

        <div className={styles.widthPercentageSection}>
          <span className={styles.sizeTitle} style={{marginBottom: '0px'}}>Size</span>
          <div className={styles.percentageComponents}>
            <Input
                label={{ basic: true, content: '%' }}
                labelPosition="right"
                className={styles.slideWidthInput}
                value={props.slideWidthPercentage}
                placeholder=""
                onChange={(event: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData) => {
                  const percentage = parseInt(data.value, 10);
                  props.onChange(props.slideWidthType, props.slideAlignment, percentage);
                }}
              />  
              <div style={{marginTop: '0px'}}>          
                <Slider
                  max={100}
                  value={props.slideWidthPercentage}
                  showValue={false}
                  onChange={(value: number) => props.onChange(props.slideWidthType, props.slideAlignment, value)}
                  className={styles.slideDimensionsPickerModuleSlider}
                  styles={{
                    thumb: {borderColor: 'var(--COLOR-GREEN-100)', width: '14px', height: '14px'},
                    line: {borderColor: 'var(--COLOR-GREEN-100)'},
                    inactiveSection: {backgroundColor: 'var(--COLOR-GREEN-100)', height: '2px' },
                    activeSection: {backgroundColor: 'var(--COLOR-GREEN-100)', height: '2px'},                  
                  }}                
                />
              </div>
          </div>
        </div>
        <div className={pickerModalStyles.horizontalLine}  style={{marginTop: '14px', marginBottom: '20px'}} />

        <Picker
          style={{marginBottom: '4px'}}
          pickerItems={slideAlignments}
          title="Slide justification"
          selectedValue={getSlideAlignmentEnum(props.slideAlignment)}
          onChange={(alignment: number) => props.onChange(props.slideWidthType, getSlideAlignmentString(alignment), props.slideWidthPercentage)}
        />
      </div>
    </PickerModal>
  );
}
