import React, { CSSProperties, useRef } from 'react';
import { Article } from '../../../models/Article';
import styles from './_gide-preview-list.module.scss';
import GideTilePreview from '../../Shared/Previews/GidePreview/GideTilePreview/GideTilePreview';
import isNil from 'ramda/es/isNil';
import { User } from '../../../models/User';
import icons from '../../../assets/icons';
import { displayGideTitle, isMobileDevice } from '../../../utils/helperFunctions';
import agent from '../../../agent';
import { useDispatch } from 'react-redux';
import { SEARCH_EXECUTED_NEXT_PAGE } from '../../../constants/actionTypes';
export interface GidePreviewListProps {
  currentUser?: User | null;
  gidePreviewId?: string;
  gidePreviewTitle?: string;
  gideList: Article[];
  searchText?: string;
  searchParams?: string;
  nextSearchPage?: number;
  style?: CSSProperties;
  onLoadMoreGides?: (gideGroupId: string, page?: number) => void;
  isReadOnly?: boolean;
  onDeleteGide?: (gideId: string) => void;
}

export default function GidePreviewList(props: GidePreviewListProps) {
  const dispatch = useDispatch();
  const [showMoreComponentWidth, setShowMoreComponentWidth] = React.useState(0);
  const [gideListPageNumber, setGideListPageNumber] = React.useState(0);
  const previewContainerRef = useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const handleWindowResize = () => {      
      if(previewContainerRef.current !== null) {
        const width = (navigator?.userAgent?.toLowerCase().indexOf('firefox') > -1) ? previewContainerRef.current.clientWidth : window.innerWidth;
        const tileWidthNeeded = width < 365 ? 180 : width < 375 ? 182 : width < 520 ? 184 : 188;

        const maxNumberOfTiles = Math.min(6, Math.floor(width / tileWidthNeeded));
        const moreComponentWidth = maxNumberOfTiles * tileWidthNeeded;
        setShowMoreComponentWidth(moreComponentWidth);
      }
    };
    handleWindowResize(); // Run it initially then the resize event will take over
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const buildGidePreviewGrid = (gideList: Article[]) => {
    return gideList.map((gide: Article, index: number) => (
      <GideTilePreview
        id={gide.id}
        slug={gide.slug}
        key={`${gide.slug}-${index}`}
        image={
          !isNil(gide.slideZero) && !isNil(gide.slideZero.data)
            ? (gide.slideZero.data.image as string)
            : !isNil(gide.image)
            ? gide.image
            : ''
        }
        title={displayGideTitle(gide.title, gide.type)}
        description={gide.description}
        authorImage={gide.author.image}
        author={gide.author.username}
        authorCountry={gide.author.country || ''}
        language={!isNil(gide.language) ? (gide.language as string) : 'English'}
        hasGeotag={!isNil(gide.slideZero) && (gide.slideZero.geotagLocation && gide.slideZero.geotagLocation.type === 'Point') ? true : false}
        views={gide.viewCount}
        slideCount={!isNil(gide.slideCount) ? (gide.slideCount as number) : 0}
        gideCost={gide.price}
        priceType={gide.priceType}
        isOwner={!isNil(props.currentUser) && props.currentUser.username === gide.author.username}
        subscriptionPlan={gide.subscribeType}
        createdAt={new Date(gide.createdAt)}
        updatedAt={new Date(gide.updatedAt)}
        isReadOnly={gide.type === 'SPECIAL' || props.isReadOnly || !Boolean(props.currentUser && (props.currentUser.username === 'gides' || props.currentUser.id === gide.author.id))}
        allowToAddToCollections={Boolean(props.currentUser && props.currentUser.username === 'gides')}
        onDeleteGide={props.onDeleteGide}
      />
    ));
  };

  return (
    <div className={styles.gidePreviewListContainer} ref={previewContainerRef} style={props.style}>
      <div className={`${styles.gidePreviewList} ${props.searchText ? styles.searchResults : ''}`}>
        <div className={styles.gidePreviewTitleContainer}
          style={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            minWidth: showMoreComponentWidth,
            maxWidth: showMoreComponentWidth,
            alignSelf: 'center',
          }}
        >
          {props.gidePreviewTitle && <div className={styles.gidePreviewTitle}>{props.gidePreviewTitle}</div>}
        </div>
        <div className={styles.gidePreviewGrid}>
          {buildGidePreviewGrid(props.gideList)}
          <span className={styles.emptyGidePreview} />
          <span className={styles.emptyGidePreview} />
          <span className={styles.emptyGidePreview} />
          <span className={styles.emptyGidePreview} />
          <span className={styles.emptyGidePreview} />
          <span className={styles.emptyGidePreview} />
        </div>
        {props.onLoadMoreGides && (
          <div
            onClick={async () => {
              const newGideListPageNumber = gideListPageNumber + 1;
              setGideListPageNumber(newGideListPageNumber);
              if (props.onLoadMoreGides && props.gidePreviewId) { 
                props.onLoadMoreGides(props.gidePreviewId, newGideListPageNumber);
              } else if (props.searchText) {
                const offset = (props.nextSearchPage || 0) * (isMobileDevice() ? 12 : 36);
                const search = `offset=${offset}&${props.searchParams}`;
                const searchResults = await agent.Articles.bySearch(search);
                const payload = {
                  articles: searchResults.articles,
                  articlesCount: searchResults.articlesCount,
                };
                dispatch({ type: SEARCH_EXECUTED_NEXT_PAGE, payload });
              }
            }}
            style={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              minWidth: showMoreComponentWidth,
              maxWidth: showMoreComponentWidth,
              alignSelf: 'center',
              marginTop: '4px',
              marginBottom: '38px',
              paddingLeft: 6,
              paddingRight: 6,
            }}
          >
            <span style={{
              marginRight: '3px',
              background: '#F0F0F0',
              borderRadius: '2px',
              width: '32px',
              height: '32px',
              padding: '4px',
            }}>
              <icons.Nav_Arrow_ArrowDown />
            </span>
            <span className={styles.viewMoreButton}>
              View more
            </span>
            <div className={styles.moreItemsHorizontalLine} />
          </div>
        )}
      </div>
    </div>
  );
}
