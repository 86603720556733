import React from 'react';
import { ChannelEditorModeProps } from './ChannelEditorModesShared';
import SlideEditorCommandBar from '../../SlideEditors/SlideEditorCommandBar/SlideEditorCommandBar';
import { BackIconMode } from '../../modals/SlideEditorModal/SlideEditorModal';
import { EditorState } from '../../Navigation/CreationProcess/EditorState/EditorState';
import { lightTheme } from '../../../themes/custom-theme';
import icons from '../../../assets/icons';
import ExpandingIconButton, { HorizontalAlignment } from '../../Shared/ExpandingIconButton/ExpandingIconButton';
import { getChannelEditorPreviewContent } from './ChannelEditorModesShared';
import { NotificationType } from '../../../constants/strings';
import InputBarEditor from '../../DataEntry/InputBar/InputBarEditor/InputBarEditor';

export const ChannelEditorModeEdit: React.FC<ChannelEditorModeProps> = (props) => {

  const navigateBack = () => {    
    props.onCancel();
  };
  
  const onSave = () => {
    if (!props.image) {
      props.showNotification({
        type: NotificationType.ERROR,
        message: 'Please add an image.',
      });  
    } else if (!props.title) {
      props.showNotification({
        type: NotificationType.ERROR,
        message: 'Please enter a title.',
      });
    } else if (!props.description) {
      props.showNotification({
        type: NotificationType.ERROR,
        message: 'Please enter a description.',
      });
    } else {
      props.onSave(props.image, props.title, props.description);
    }
  };

  const imageHeaderActions = (
    <div style={{
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginRight: 22,
    }}>
      {/* Crop */}
      <ExpandingIconButton
          style={{
            marginLeft: '9px',
            height: '32px',
          }}
          alt="Crop"
          src="/icons/creationprocess/cropandrotate/cropandrotate.svg"
          expandWidth={125}
          label="crop image"
          onClick={e => {
            props.setMode('Crop');
          }}
          labelLocation={HorizontalAlignment.Left}
          expanded={false}
          visible={true}
        />
        {/* Delete */}        
        <ExpandingIconButton
          style={{
            marginLeft: '12px',
            marginRight: '7px',
            height: '32px',
          }}
          alt="Delete"
          src="/icons/content-alteration/delete.svg"
          expandWidth={80}
          label="Delete"
          onClick={e => {
            props.setMode('Delete');
          }}
          labelLocation={HorizontalAlignment.Left}
          expanded={false}
          visible={true}
        />
        <div className="slideSettingsSeparator"></div>
    </div>
  );

  const content = getChannelEditorPreviewContent(props);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
    }}>
      <SlideEditorCommandBar
        backIconMode={BackIconMode.Light}
        showBackNavigationButton={false}
        showDefaultActions={false}
        onExit={navigateBack}
        onNavigateBack={navigateBack}
        theme={lightTheme}
      >
        {imageHeaderActions}
        <EditorState title='Edit channel'
          backgroundColor="var(--COLOR-PRIMARY-600)"
          icon={<icons.Nav_Channels color="var(--WHITES-NORMAL-1000)" />}
        />
      </SlideEditorCommandBar>
      {content}
      <div style={{
        display: 'flex',
        alignItems: 'center',
        minHeight: 52,
      }}>
        <InputBarEditor 
          onChange={props.setDescription}
          onAcceptInput={onSave}
          onInputEditorFocusChanged={(isFocused) => {}}
          // onPaste={props.setDescription}
          inputBarValue={props.description || ''}
          placeholderText="Please enter a description"
          displayElipsisOnLostFocus={false}
        />
        <img src="/icons/content-alteration/input-bar/slide/basic.svg" 
          alt="Create or Update Channel" 
          className="pointer" 
          style={{
            height: 34,
            width: 34,
          }}
          onClick={onSave}
        />        
      </div>
    </div>
  );
}
