import React from 'react';
import { Link } from 'react-router-dom';
import ChannelTilePreview from './Shared/Previews/ChannelTilePreview/ChannelTilePreview';
const ChannelPreviewList = ({ channel }) => {
  return (
    <div>
      <Link to={`/channel/${channel.slug}`}>
        {channel.title}
      </Link>
    </div>
  );
};



export const ChannelList = ({
  channels,
  displayMode,
  onClick = undefined,
}) => {

  if (!channels) {
    return null;
  }
  if (channels.length === 0) {
    // return <div className="channel-preview">No results.</div>;
    return <div style={{display: 'flex', justifyContent: 'center'}}>No Longer Supported</div>;
  }
  const Preview = ({channel}) => (
    <ChannelTilePreview
      onClick={onClick ? (event, slug) => {
        if(onClick) {
          onClick(event, slug);
        }
      }: undefined}
      style={{cursor: 'pointer'}}      
      image={channel.image}
      title={channel.title}
      author={channel.author ? channel.author.username : ''}
      authorImage={channel.author ? channel.author.image : ''}
      slug={channel.slug}
      type={channel.type}
      numberOfGides={channel.numberOfGides ? channel.numberOfGides : 0}
      numberOfMembers={channel.numberOfMembers ? channel.numberOfMembers : 0}
    />
  );
  return (
    // <div style={{ padding: 10 }}>
    <div style={{ display: 'flex', flex: 1, flexFlow: 'column' }}>
      <div className="channelList center">
        {channels.map((channel, i) => {
          return displayMode === 'LIST' ? (
            <ChannelPreviewList channel={channel} key={i} />
          ) : (
            <React.Fragment key={i}>
              {onClick && (
                <div className={'profileChannelTitlePreview'}  >
                  <Preview channel={channel} />
                </div>
              )}
              {!onClick && (
                <Link className={'profileChannelTitlePreview'} to={`/channel/${channel.slug}`}>
                  <Preview channel={channel} />
                </Link>
              )}
            </React.Fragment>
          );
        })}
        <span className="channelPreviewEmptySpan" />
        <span className="channelPreviewEmptySpan" />
        <span className="channelPreviewEmptySpan" />
        <span className="channelPreviewEmptySpan" />
        <span className="channelPreviewEmptySpan" />
        <span className="channelPreviewEmptySpan" />
        <span className="channelPreviewEmptySpan" />
        <span className="channelPreviewEmptySpan" />
      </div>
    </div>
  );
};

export default ChannelList;
