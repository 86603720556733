import React, { Component } from 'react';
import { connect } from 'react-redux';
import ChoicesModal from './ChoicesModal';
import LinksModal from './LinksModal';
import ContactsModal from './ContactsModal';
import GideModal from './GideModal';
import AudioModal from './AudioModal';
import VideoModal from './VideoModal';
import FileModal from './FileModal';
import ListModal from './ListModal';
import LocationModal from './LocationModal';
import ChooseModal from './ChooseModal';
import AuthorizeModal from './AuthorizeModal';
import SlideModal from './SlideModal';
import SlideSelectModal from './SlideSelectModal';
// import HeaderModal from './HeaderModal';
import HeaderModal from '../modals/HeaderModal/HeaderModal';
import DiagramModal from './DiagramModal';
import InputModal from './InputModal';
import LeadModal from './LeadModal';
import ItemModal from './ItemModal';
import DoodleModal from './DoodleModal';
import MiniGideModal from './MiniGideModal';
import AdditionSlidesModal from './AdditionSlidesModal';
import SlideSettingsModal from './SlideSettingsModal';
import SwipeModeModal from './SwipeModeModal';
import DistributeModal from './DistributeModal';
// import SwipeViewModal from './SwipeViewModal';
import SwipeViewModal from './SwipeViewModal/SwipeViewModal';
import SignatureModal from './SignatureModal';
import EmbedModal from './EmbedModal';
import TextModal from './TextModal';
import ColumnModal from './ColumnModal';
import BlogModal from './BlogModal';
import ButtonModal from './ButtonModal';
import EndCollapseModal from './EndCollapseModal';
import ImageViewModal from './ImageViewModal';
import SlideNumberModal from './SlideNumberModal';
import SelectGideModal from './SelectGideModal';
import ShortcutManagerModal from './ShortcutManagerModal';
import CollapseModal from './CollapseModal.tsx';
import SlideshowModal from './SlideshowModal.tsx';
import SlideEditorModal from './SlideEditorModal/SlideEditorModal.tsx';
import { RichTextSlideEditor } from '../SlideEditors/RichTextSlideEditor/RichTextSlideEditor';
import ImageSlideEditor from '../SlideEditors/ImageSlideEditor/ImageSlideEditor';
import ImageGallerySlideEditor from '../SlideEditors/ImageGallerySlideEditor/ImageGallerySlideEditor';
import VideoSlideEditor from '../SlideEditors/VideoSlideEditor/VideoSlideEditor';
import SelectCollectionModal from './SelectCollectionModal';
import SelectFolderModal from './SelectFolderModal';
import GideSlideFilterModal from './GideSlideFilterModal/GideSlideFilterModal';
import AudioSlideEditor from '../SlideEditors/AudioSlideEditor/AudioSlideEditor';
import GideViewerManagerModal from '../Gide/GideViewerManagerModal/GideViewerManagerModal';
import LinkSlideEditor from '../SlideEditors/LinkSlideEditor/LinkSlideEditor.tsx';
import NewSlideTypeChooserModal from '../InputBar/NewSlideTypeChooserModal/NewSlideTypeChooserModal';
import QuickSlideTypeChooser from '../InputBar/QuickSlideTypeChooserModal/QuickSlideTypeChooser';
import SlideZeroEditor from '../SlideEditors/SlideZeroEditor/SlideZeroEditor';
import AccountProfileModal from '../modals/AccountProfileModal/AccountProfileModal';
import MailChimpSignupModal from '../modals/MailChimpSignupModal/MailChimpSignupModal';
import SigninModal from '../modals/SigninModal/SigninModal';
import EndHeaderModal from '../InputBar/EndHeaderModal/EndHeaderModal';

// TODO - Consider deleting these older modals...
import ImageModal from './ImageModal';
import ImageEditModal from './ImageEditModal';
import MapModal from './MapModal';


const modalComponentLookupTable = {
  ChoicesModal,
  LinksModal,
  ContactsModal,
  GideModal,
  AudioModal,
  VideoModal,
  ImageModal,
  ImageEditModal,
  FileModal,
  ListModal,
  LocationModal,
  MapModal,
  ChooseModal,
  AuthorizeModal,
  SlideModal,
  SlideSelectModal,
  InputModal,
  DiagramModal,
  HeaderModal,
  LeadModal,
  ItemModal,
  DoodleModal,
  MiniGideModal,
  AdditionSlidesModal,
  SlideSettingsModal,
  SwipeModeModal,
  DistributeModal,
  SwipeViewModal,
  SignatureModal,
  EmbedModal,
  TextModal,
  ColumnModal,
  BlogModal,
  ButtonModal,
  CollapseModal,
  EndCollapseModal,
  ImageViewModal,
  VideoSlideEditor,
  SlideNumberModal,
  SelectGideModal,
  ShortcutManagerModal,
  SlideshowModal,
  SlideEditorModal,
  RichTextSlideEditor,
  ImageSlideEditor,
  ImageGallerySlideEditor,
  SelectCollectionModal,
  SelectFolderModal,
  GideSlideFilterModal,
  GideViewerManagerModal,
  AudioSlideEditor,
  LinkSlideEditor,
  NewSlideTypeChooserModal,
  QuickSlideTypeChooser,
  SlideZeroEditor,
  AccountProfileModal,
  MailChimpSignupModal,
  SigninModal,
  EndHeaderModal
};

const mapStateToProps = state => ({
  currentModals: state.modalManager,
});

export class ModalManager extends Component {
  render() {
    const { currentModals } = this.props;

    let renderedModals = currentModals.map((modal, index) => {
      const { modalType, modalProps = {} } = modal;
      const ModalComponent = modalComponentLookupTable[modalType];
      return <ModalComponent {...modalProps} key={modalType + index} />;
    });
    // if(currentModal.modalType) {
    //   const {modalType, modalProps = {}} = currentModal;
    //   const ModalComponent = modalComponentLookupTable[modalType];
    //   renderedModal = <ModalComponent {...modalProps} />;
    // }
    return <span>{renderedModals}</span>;
  }
}

export default connect(mapStateToProps)(ModalManager);
