import React, { Component } from 'react';
import { Modal, Button, Input, List, Grid, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import agent from '../../agent';
import { contains } from 'ramda';
import {
  MODAL_CLOSE,
  GIDE_MODAL_LOADED,
  GIDE_MODAL_SEARCHED,
  SET_TOASTER_MESSAGE,
} from '../../constants/actionTypes';
import GideImage from '../Shared/Image/GideImage';
import { NotificationType } from '../../constants/strings';
import { hasValue } from '../../utils/helperFunctions';
// import { hasValue } from '../../utils/helperFunctions';

const mapDispatchToProps = dispatch => ({
  onLoad: payload => dispatch({ type: GIDE_MODAL_LOADED, payload }),
  onSearch: payload => dispatch({ type: GIDE_MODAL_SEARCHED, payload }),
  closeModal: payload => dispatch({ type: MODAL_CLOSE }),
  showNotification: toasterMessageInfo => dispatch({ type: SET_TOASTER_MESSAGE, payload: { toasterMessageInfo } }),
});

const mapStateToProps = state => ({
  article: state.article.article,
  currentUser: state.common.currentUser,
});

export class SelectGideModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      layout: 'LIST',
      articleId: null,
      searchText: '',
      gideResults: [],
    };

    this.changeLayout = layout => {
      this.setState({ layout });
    };

    this.searchTextChanged = ev => {
      this.setState({ searchText: ev.target.value });
    };

    this.searchGides = async () => {
      
      if (!hasValue(this.state.searchText)) {
        this.props.showNotification({
          message: 'You must enter a search criteria.',
          type: NotificationType.INFO,
        });
      } else {

        const searchResults = await agent.Users.gideSearch({ searchText: this.state.searchText });        
        this.setState({gideResults: searchResults});
      }
    };

    this.selectGide = gide => {
      this.setState({ gide: gide });
    };
  }

  render() {
    const { layout, gide } = this.state;
    let { articles, closeModal } = this.props;
    let articleIds = [];
    if (!articles) {
      articles = [];
    } else {
      articleIds = articles.map(a => a.id);
    }
    return (
      <Modal
        // style={{ width: '300px' }}
        closeOnEscape={true}
        onClose={closeModal}
        className="slideModal"
        size="tiny"
        dimmer="inverted"
        closeOnDocumentClick={false}
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Content className="preventSemanticActionStyle">
          <Modal.Description
            className="headerLevel3 preventSemanticActionStyle"
            style={{ padding: '10px' }}
          >
            <span>
              <strong>Select a Gide</strong>
            </span>
          </Modal.Description>

          <section>
            {(!this.state.gideResults || this.state.gideResults.length < 1) && <div>No Gides available.</div>}
            <div className="flexRowSpaceBetween" style={{margin: '5px'}}>
              <div className="flexRowFull selectGideModal">
                <Input
                  style={{ flex: 1 }}
                  placeholder="Search Gides"
                  value={this.state.searchText}
                  onChange={this.searchTextChanged}
                />
                <Button
                  style={{ marginLeft: '5px' }}
                  onClick={this.searchGides}
                  icon
                >
                  <Icon name="search" />
                  Search
                </Button>
              </div>
              <div>
                <Button.Group>
                  <Button
                    icon="block layout"
                    onClick={() => this.changeLayout('GRID')}
                  />
                  <Button
                    icon="list layout"
                    onClick={() => this.changeLayout('LIST')}
                  />
                </Button.Group>
              </div>
            </div>
            {layout === 'LIST' && (
              <List selection divided relaxed>
                {this.state.gideResults.map((g, i) => (
                  <List.Item
                    key={i}
                    onClick={() => this.selectGide(g)}
                    className={
                      gide ? (gide.slug === g.slug ? 'selected' : '') : ''
                    }
                  >
                    <GideImage src={g.imageUrl} className="icon sm" alt="logo" />
                    <List.Content>{g.displayName}</List.Content>
                  </List.Item>
                ))}
              </List>
            )}
            {layout === 'GRID' && (
              <Grid columns={3} padded>
                {this.state.gideResults.map((g, i) => (
                  <Grid.Column
                    key={i}
                    onClick={() => this.selectGide(g)}
                    className={
                      gide ? (gide.slug === g.slug ? 'selected' : '') : ''
                    }
                  >
                    <GideImage src={g.imageUrl} alt="cover" />
                  </Grid.Column>
                ))}
              </Grid>
            )}
          </section>
        </Modal.Content>
        <Modal.Actions className="preventSemanticActionStyle">
          <div className="flexRowFlexEnd">
            <Button
              disabled={
                !this.state.gide || !contains(this.state.gide.id, articleIds)
              }
              color="blue"
              content={this.props.saveButtonTitle}
              onClick={() => {
                this.props.saveCallback({ articleId: this.state.gide.id, article: this.state.gide });
                closeModal();
              }}
            />
            <Button onClick={closeModal}>Cancel</Button>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectGideModal);
