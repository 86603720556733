import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import GideImage from '../../Shared/Image/GideImage';

export interface SlideshowSlideHeaderProps {
  // showEditAndViewButtons: boolean;
  showHeaderCaption: boolean;
  viewerIsExpanded: boolean;
  isEditing: boolean;
  headerCaption: string;
  handleShowingEditorOrViewer: (e: any) => void;
  collapseExpandViewer: () => void;
}
export class SlideshowSlideHeader extends Component<SlideshowSlideHeaderProps> {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <GideImage 
            className={`editButton`}
            src={
              this.props.isEditing
                ? '/icons/nav/exit/main.svg'
                : '/icons/nav/edit/edit-main.svg'
            }
            alt="edit slideshow slides"
            onClick={(e: any) => {
              this.props.handleShowingEditorOrViewer(e);
            }}
          />
        </div>
        {this.props.showHeaderCaption && (
          <span style={{ margin: 'auto' }}>{this.props.headerCaption}</span>
        )}
        <span
          onClick={() => {
            this.props.collapseExpandViewer();
          }}
        >
          <Icon name={this.props.viewerIsExpanded ? 'close' : 'film'} />
        </span>
      </div>
    );
  }
}
