import * as React from 'react';
import { CircleIconButton } from '../../Shared/CircleIconButton/CircleIconButton';
import {
  SlideSelectionInfo,
  SlideSelectionOperation,
  MultiSlideSelectionMethod,
  SlideSelectionTransferType,
} from '../../../models/SlideSelectionInfo';
import { ChildArticleType } from '../../../models/ArticleLayoutEnum';
import { RoundedCornerButton } from '../../Shared/RoundedCornerButton/RoundedCornerButton';
import GideImage from '../../Shared/Image/GideImage';

export interface SlideEditGideHeaderProps {
  slideSelectionModeDetail: SlideSelectionInfo;
  onClose: () => void;
  updateSlideSelectionMethod?: (payload: { multiSlideSelectionMethod: MultiSlideSelectionMethod }) => void;
  updateSlideSelectionOperationMode?: (payload: { transferType: SlideSelectionTransferType }) => void;

  // actions: Action
}

export default function SlideEditGideHeader(props: SlideEditGideHeaderProps) {
  const SlideSelectionActions = () => (
    <div className="multiSelectionActions">
      <RoundedCornerButton
        label="Select"
        className={`slideSelectionActionButton${
          props.slideSelectionModeDetail && props.slideSelectionModeDetail.multiSlideSelectionMethod === MultiSlideSelectionMethod.Selection
            ? ' selected'
            : ''
        }`}
        fontCssClass={ props.slideSelectionModeDetail && props.slideSelectionModeDetail.multiSlideSelectionMethod === MultiSlideSelectionMethod.Selection
          ? ' TEXTSUBTITLEwhitehigh-emphasiscenter'
          : ' TEXTSUBTITLEblackhigh-emphasiscenter'}        
        onClick={() => {
          props.updateSlideSelectionMethod &&
            props.updateSlideSelectionMethod({
              multiSlideSelectionMethod: MultiSlideSelectionMethod.Selection,
            });
        }}
      />
      <RoundedCornerButton
        label="Range"
        className={`slideSelectionActionButton${
          props.slideSelectionModeDetail && props.slideSelectionModeDetail.multiSlideSelectionMethod === MultiSlideSelectionMethod.Range
            ? ' selected'
            : ''
        }`}
        fontCssClass={ props.slideSelectionModeDetail && props.slideSelectionModeDetail.multiSlideSelectionMethod === MultiSlideSelectionMethod.Range
          ? ' TEXTSUBTITLEwhitehigh-emphasiscenter'
          : ' TEXTSUBTITLEblackhigh-emphasiscenter'}
        onClick={() => {
          props.updateSlideSelectionMethod &&
            props.updateSlideSelectionMethod({
              multiSlideSelectionMethod: MultiSlideSelectionMethod.Range,
            });
        }}
      />
    </div>
  );

  const getSlideSelectionName = () => {
    switch (props.slideSelectionModeDetail.operation) {
      case SlideSelectionOperation.AddSlideBelow:
        return 'Add slide below';
      case SlideSelectionOperation.AddSlideAbove:
        return 'Add slide above';
      case SlideSelectionOperation.Delete:
        return 'Delete slide';
      case SlideSelectionOperation.Select:
        return '';
      case SlideSelectionOperation.Move:
        return 'Move slide';
      case SlideSelectionOperation.Like:
        return 'Like this slide';
      case SlideSelectionOperation.Transfer:
        return 'Transfer';
      case SlideSelectionOperation.Copy:
          return 'Copy';
      case SlideSelectionOperation.AttachToSlide:
        return 'Attach to slide';
      case SlideSelectionOperation.AddSlideAddition: {
        switch (props.slideSelectionModeDetail.childArticleType) {
          case ChildArticleType.Comments:
            return 'Add comments to slide';
          case ChildArticleType.Questions:
            return 'Add questions to slide';
          case ChildArticleType.PrivateNotes:
            return 'Add privte notes to slide';
          default:
            return '';
        }
      }
      default:
        return '';
    }
  };
  const getSlideSelecitonIcon = () => {
    switch (props.slideSelectionModeDetail.operation) {
      case SlideSelectionOperation.AddSlideBelow:
        return '/icons/nav/arrow/add-below.svg';
      case SlideSelectionOperation.AddSlideAbove:
        return '/icons/nav/arrow/add-above.svg';
      case SlideSelectionOperation.Delete:
        return '/icons/content-alteration/delete.svg';
      case SlideSelectionOperation.Select:
        return '/icons/content-alteration/checkbox/checkbox-active.svg';
      case SlideSelectionOperation.Like:
        return '/icons/content-alteration/like/de-active.svg';
      case SlideSelectionOperation.Move:
        return '/icons/nav/arrow/arrow-up-down.svg';
      case SlideSelectionOperation.AttachToSlide:
        return '/icons/content-alteration/attach.svg';
      case SlideSelectionOperation.Transfer:
        return '/icons/nav/logo/logo-icon-md.svg';
      case SlideSelectionOperation.Copy:
          return '/icons/content-alteration/copy.svg';    
      case SlideSelectionOperation.AddSlideAddition: {
        switch (props.slideSelectionModeDetail.childArticleType) {
          case ChildArticleType.Comments:
            return '/icons/content-alteration/comments.svg';
          case ChildArticleType.Questions:
            return '/icons/content-alteration/questions.svg';
          case ChildArticleType.PrivateNotes:
            return '/icons/content-alteration/notes.svg';
          default:
            return '';
        }
      }
      default:
        return '';
    }
  };
  return (
    <div className="slideEditGideHeaderContainer">
      <GideImage src="/icons/nav/exit/main.svg"
        alt="exit"
        className="exitButton color-secondary-800-svg"
        onClick={() => {
          props.onClose();
        }}
      />
      {(props.slideSelectionModeDetail.operation === SlideSelectionOperation.Transfer
        || props.slideSelectionModeDetail.operation === SlideSelectionOperation.AttachToSlide) &&
        props.slideSelectionModeDetail.options &&
        props.slideSelectionModeDetail.options}
      <div className="actionType">
        {props.slideSelectionModeDetail.operation === SlideSelectionOperation.Select && <SlideSelectionActions />}

        <span style={{ marginRight: '9px' }}>{getSlideSelectionName()}</span>
        <CircleIconButton
          className="actionIcon"
          alt={getSlideSelectionName()}
          onClick={() => {}}
          iconCssClass="whites-normal-1000-svg"
          backgroundColor="var(--COLOR-PRIMARY-600)"
          image={getSlideSelecitonIcon()}
        />
      </div>
    </div>
  );
}
