import React, { useEffect, useState, CSSProperties } from 'react';
import classNames from 'classnames';
import { contains } from 'ramda';
import { Icon, Checkbox, Button } from 'semantic-ui-react';
import { Slide, SlideUpdateInfo } from '../../../models/Slide';
import { User } from '../../../models/User';
import { isNullOrUndefined } from 'util';
import agent from '../../../agent';

import {
  hasValue,
  stripTags,
  hasChildArticleSlideTypes,
  isAddonSlideType,
  getChildArticleForSlide,
  convertToMilliseconds,
  slideEditorModalLookup,
  formatDisplayTextWithSearchMarkup,
} from '../../../utils/helperFunctions';
import AuthorHeader from '../../Article/AuthorHeader';
import { NotificationType } from '../../../constants/strings';
import { Article } from '../../../models/Article';
import {
  SlideSelectionOperation,
  SlideSelectionInfo,
  SlideSelectionTransferType,
  MultiSlideSelectionMethod,
} from '../../../models/SlideSelectionInfo';
import InlineTextEditor from '../../InlineTextEditor';
import AudioPlayer, { AudioPlayerType } from '../../media/AudioPlayer/AudioPlayer';

import {
  ChildArticleType,
  getChildArticleTypeEnum,
  getChildArticleTypeName,
  getIconForChildArticleTypeAsString,
  ChildArticleDetail,
} from '../../../models/ArticleLayoutEnum';
import { InlineViewSlides, MultiSlideSelectionDetail, ExpandCollapseSlideAdditionInfo } from '../../../reducers/article';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import { Expiration, DateTimeExpiration, DurationExpiration } from '../../../models/ExpirationSettings';

import { slideComponentLookup } from '../../../utils/SlideComponentLookup';
import { withTheme } from 'styled-components';
import SlideEditPanel, { SlideEditMode } from '../../Slides/SlideEditPanel/SlideEditPanel';
import { SlideList } from '../SlideList/SlideList';
import SlideSelector from '../../Slides/SlideSelector/SlideSelector';
import { RoundedCornerButton } from '../../Shared/RoundedCornerButton/RoundedCornerButton';
import { RadioButton as RadioButtonComponent } from '../../Shared/RadioButton/RadioButton';
import SearchContext from '../../Shared/SearchBar/SearchContext';
import GideImage from '../../Shared/Image/GideImage';
import icons from '../../../assets/icons';
import { RichTextEditorInlineButtons } from '../../RichTextEditor/RichTextEditorInlineButtons';

const createDOMPurify = require('dompurify');

export interface InlineSlideTextEditInfo {
  inlineSlideTextEditId: string;
  type: string;
}

export type ViewMode = 'SWIPE' | 'SLIDESHOW' | 'SLIDE' | 'SCROLL';

export interface SlideViewProps {
  currentUser: User;
  view: string;
  viewMode: ViewMode;
  nextViewMode?: ViewMode;
  className?: string;
  // Used when this slide is a referenced slide within another slide
  // We want to take the width settings of the container slide (parent referencing this slide)
  // so the parent's width setting is passed through the overrideWidth
  overrideWidth?: string;
  article?: Article;
  slide: Slide;
  slidePosition: number;
  articleEditMode?: string;
  viewOnly?: boolean;
  canPerformMultipleEditAction?: boolean;
  removeBottomMargin?: boolean;
  showAuthorHeader?: boolean;
  slideChecked?: boolean;
  inlineSlideTextEditInfo?: InlineSlideTextEditInfo;
  inlineEditedSlide?: Slide;
  disableInlineTextEditing?: boolean;
  slideSelectionModeDetail?: SlideSelectionInfo;
  multiSlideSelectionDetail?: MultiSlideSelectionDetail; // TODO: Combine this and the one above it and rename to more meaningful name.
  wideScreenEditModeEnabled?: boolean;
  slideInAdvancedEditMode?: boolean;
  isOutOfOrder?: boolean;
  collapsed?: boolean;
  totalNumberOfSlides?: number;
  viewingInline?: boolean;
  inlineViewSlides?: InlineViewSlides[];
  expandCollapseSlideAdditionInfo?: ExpandCollapseSlideAdditionInfo;
  hideSlideInformationSection?: boolean;
  disableExpiration?: boolean;
  hideThreeDotMenu?: boolean;
  fileNum?: number;
  theme?: any;
  ignoreTheme?: boolean;
  style?: CSSProperties;
  slideCount?: number;
  setNextViewMode?: (mode: ViewMode) => void;
  showNotification?: (toasterMessageInfo: ToasterMessageInfo) => void;
  openModal?: (payload: any) => void;
  onSubmit?: (payload: any) => void;
  multiSlideSelectionChanged?: (slideId: string, checked: boolean) => void;
  onSlideSelected?: (slideId: string) => void;
  onEditingText?: (slide: Slide) => void;
  copyArticle?: (payload: any) => void;
  enterSlideSelectionMode?: (payload: SlideSelectionInfo) => void;
  updateSlideSelectionMethod?: (payload: { multiSlideSelectionMethod: MultiSlideSelectionMethod }) => void;
  updateSlideSelectionOperationMode?: (payload: { transferType: SlideSelectionTransferType }) => void;
  onEnterAdvancedEditForSlide?: (slideId: string) => void;
  exitSlideSelectionMode?: () => void;
  copySlides?: (payload: any) => void;
  moveSlides?: (selectedSlideIds: string[], moveToPosition: number) => void;
  updateSlideNumber?: (number: number) => void;
  updateSlideWithInlineEdits?: (payload: any) => void;
  setInlineSlideTextEditInfo?: (inlineSlideTextEditInfo?: InlineSlideTextEditInfo) => void;
  deleteSlide?: (slideId: string) => void;
  openChildArticleModal?: (
    slide: Slide,
    childArticleTypeList: ChildArticleType[],
    selectedArticleType: ChildArticleType,
    viewOnly?: boolean,
  ) => void;
  collapseSlides?: (slide: Slide) => void;
  onSetViewMode?: (mode: ViewMode) => void;
  setMultiSelectSlideView?: (payload: any) => void;
  // onToggleSlideInlineView?: (payload: any) => void;
  onAuthorizeSlide?: (id: string, authInfo: any, isBlockAuthorizationSlide: boolean) => void;
  onUnauthenticatedAuthorizeSlide?: (id: string, authInfo: any, isBlockAuthorizationSlide: boolean) => void;
  addSlideAddition?: (slide: Slide, additionType: string) => void;
  updateChildGidesOnSlideForGideType?: (payload: { slideId: string; articleType: string; slides: Slide[] }) => void;
  onClick?: () => void;
  onModifiedTitle?: (newTitle: string) => void;
}

export const SlideView = (props: SlideViewProps) => {
  const searchContext = React.useContext(SearchContext);
  const [isExpired, setIsExpired] = useState(props.slide.isExpired);
  const [openSlideAdditionListGideType, setOpenSlideAdditionListGideType] = useState<string | undefined>(undefined);

  useEffect(
    () => {
      if (
        props.expandCollapseSlideAdditionInfo &&
        props.slide.childArticlesSlideTypes &&
        props.slide.childArticlesSlideTypes[props.expandCollapseSlideAdditionInfo.gideType] &&
        props.slide.childArticlesSlideTypes[props.expandCollapseSlideAdditionInfo.gideType].length > 0
      ) {
        if (
          props.expandCollapseSlideAdditionInfo.action === 'EXPAND' &&
          openSlideAdditionListGideType !== props.expandCollapseSlideAdditionInfo.gideType
        ) {
          toggleInlineView(props.expandCollapseSlideAdditionInfo.gideType);
        } else if (
          props.expandCollapseSlideAdditionInfo.action === 'COLLAPSE' &&
          openSlideAdditionListGideType === props.expandCollapseSlideAdditionInfo.gideType
        ) {
          setOpenSlideAdditionListGideType(undefined);
        }
      }
    },
    [props.expandCollapseSlideAdditionInfo],
  );

  useEffect(
    () => {
      const canExpire =
        props.disableExpiration !== true &&
        props.currentUser &&
        props.slide.author.username !== props.currentUser.username &&
        !isExpired &&
        props.slide.expirationSettings !== undefined;

      let timer: number = 0; // 0 is equivalent to undefined or false when checking if(timer)
      if (canExpire && props.slide.expirationSettings && props.currentUser && props.slide.expirationSettings.source) {
        if (props.slide.expirationSettings.source.expiration === Expiration.DateTime) {
          const duration =
            new Date((props.slide.expirationSettings.source as DateTimeExpiration).dateTime).getTime() - new Date().getTime();
          // In this case we only need to set it to expired locally. Next time the user loads the gide
          // this slide will not be returned as viewable if it is returned at all. It may appear hidden
          // by default if it is set to hide
          if (duration > 0) {
            timer = window.setTimeout(() => {
              setIsExpired(true);
            }, duration);
          } else {
            setIsExpired(true);
          }
        } else {
          // Currently the only other expiration type is duration
          const duration = convertToMilliseconds(
            (props.slide.expirationSettings.source as DurationExpiration).duration,
            (props.slide.expirationSettings.source as DurationExpiration).durationUnits,
          );
          // This will initialize the itemexpiration entry in the database with the expiration time  so that once it does expire
          agent.Slides.expire(props.slide.id);
          timer = window.setTimeout(() => {
            setIsExpired(true);
          }, duration);
        }
      }

      return () => {
        if (timer) {
          window.clearTimeout(timer);
        }
      };
    },
    [isExpired],
  );
  let style: React.CSSProperties = props.style ? {...props.style, display: 'flex', minHeight: 0 } : { display: 'flex', minHeight: 0 };
  if (props.view === 'website') {
    if (props.slide.floatVertical === 'TOP') {
      // TODO (may not be possible to have this be applicable since there's no concept of a blank slide to make there be blanks)
    } else if (props.slide.floatVertical === 'BOTTOM') {
      // TODO check we are within a column, otherwise the position absolute pulls the slide out of its row.
      style = {
        ...style,
        position: 'absolute',
        bottom: '0',
        width: '100%',
        padding: '0 15px',
      };
    }
  }
  // This is to allow the header action buttons to be displayed on header slides in swipe view.
  // The action buttons allow the user to collapse/expand the header navigate forward/back to the next header slide.
  if (props.view === 'website' || props.slide.slideType !== 'HEADER') {
    // View should always be either website/SWIPE
    // TODO: See why this was set to 1 for website.
    // style.flex = 1;
  }
  if (props.slide.font) {
    style.font = `${props.slide.font} !important`;
  }

  let authorViewing = false;
  if (!props.viewOnly) {
    if (props.slide.author && props.slide.author.username) {
      authorViewing = props.currentUser && props.currentUser.username === props.slide.author.username ? true : false;
    } else if (!isNullOrUndefined(props.slide.author)) {
      authorViewing = props.currentUser && props.currentUser.username === props.slide.author.username ? true : false;
    }
  }

  const SlideComponent = slideComponentLookup[props.slide.slideType];

  const isSlideshowSlidePlaying = props.slide.slideType === 'SLIDESHOW' && props.nextViewMode === 'SLIDESHOW';

  let isFileType = props.slide.slideType === 'FILE';
  let fileType;
  if (isFileType) {
    if (props.slide.data.files && props.slide.data.files.length === 1) {
      let nameParts = props.slide.data.files[0].name.split('.');
      if (nameParts.length > 1) {
        fileType = nameParts[nameParts.length - 1].toUpperCase();
      }
    }
  }

  let classes = isSlideshowSlidePlaying
    ? classNames('slideViewContainer', props.theme && props.theme.mode && props.ignoreTheme !== true ? props.theme.mode : '')
    : classNames(
        'slideViewContainer',
        props.className,
        props.theme && props.theme.mode && props.ignoreTheme !== true ? props.theme.mode : '',
        (props.slide && (props.slide.slideType === 'HEADER' && props.slide.data.type !== 'END') && props.viewMode === 'SWIPE') ||
        props.removeBottomMargin === true ||
        props.nextViewMode === 'SLIDESHOW'
          ? 'swipe-view'
          : 'card',
        isSlideshowSlidePlaying
          ? 'SLIDESHOWPLAYING'
          : props.slide.slideType === 'COLLAPSE' && props.slide.data.type === 'END'
            ? 'COLLAPSEEND'
            : props.slide.slideType === 'AUTHORIZE' && props.slide.data.passcodeType === 'END'
              ? 'AUTHORIZEEND'
              : props.slide.slideType === 'HEADER' && props.slide.data.type === 'END' ? 'HEADEREND' : props.slide.slideType,
        { withAuthor: props.showAuthorHeader },
        { authorViewing: authorViewing },
        { embeddedGide: props.slide.data ? props.slide.data.embed : null },
        fileType,
        { file: isFileType },
        props.slide.width === 'EDGE' ? 'EDGE' : props.slide.width === 'HALF' ? 'HALF' : '',
        !authorViewing ? 'viewer' : '',
      );

  // TODO: Update this to all the embeds that need to be centered
  const centerEmbed =
    !isNullOrUndefined(props.slide.data.body) &&
    (contains('twitter', props.slide.data.body) || contains('facebook', props.slide.data.body));
  // TODO: Update this to all the embeds that need height calculated.
  const isFacebook = !isNullOrUndefined(props.slide.data.body) && contains('facebook', props.slide.data.body);

  let componentClasses = isSlideshowSlidePlaying
    ? ''
    : classNames(
        'slideComponent',
        isSlideshowSlidePlaying
          ? 'SLIDESHOWPLAYING'
          : props.slide.slideType === 'COLLAPSE' && props.slide.data.type === 'END' ? 'COLLAPSEEND' : props.slide.slideType,
        centerEmbed ? 'center-embed' : '',
        isFacebook ? 'facebook' : 'calculateIFrameHeight',
      );

  const styles: CSSProperties = {
    borderRadius: 5,
  };
  if (props.slide.backgroundColor) {
    styles.backgroundColor = props.slide.backgroundColor;
  }
  // if (props.slide.fontColor) {
  //   styles.color =  props.slide.fontColor;
  // }
  styles.color = "rgba(0, 0, 0, 0.7)";
  if (props.slide.font) {
    styles.font = `${props.slide.font} !important`;
  }
  const slideViewClasses = isSlideshowSlidePlaying ? '' : classNames('slideCardContainer', 'adjustEmbedHeight', 'adjustSlideshowHeight');

  const footerActionClasses = classNames(
    'slideFooterActions',
    props.slideSelectionModeDetail && (props.slideSelectionModeDetail.operation === 0 || props.slideSelectionModeDetail.operation === 3)
      ? ''
      : !authorViewing || props.viewMode !== 'SLIDE' || props.viewOnly ? 'hideFooter' : '',
  );

  const captionDisplayText =
    props.slide.data.caption && searchContext && searchContext.searchText
      ? formatDisplayTextWithSearchMarkup(createDOMPurify.sanitize(props.slide.data.caption), searchContext.searchText)
      : createDOMPurify.sanitize(props.slide.data.caption);

  const displaySlideInformationSection =
    !props.hideSlideInformationSection &&
    (!isNullOrUndefined(props.slide.data.audioCaption) ||
      hasValue(captionDisplayText) ||
      hasChildArticleSlideTypes(props.slide.childArticlesSlideTypes) ||
      props.slide.link ||
      (props.slide.childArticlesSlideTypes &&
        props.slide.childArticlesSlideTypes['SETTINGS'] &&
        props.slide.childArticlesSlideTypes['SETTINGS'].length > 0) ||
      (props.slide.childArticlesSlideDetails &&
        (props.slide.childArticlesSlideDetails.find(cad => cad.type === 'COMMENTS') ||
          props.slide.childArticlesSlideDetails.find(cad => cad.type === 'QUESTIONS') ||
          props.slide.childArticlesSlideDetails.find(cad => cad.type === 'PRIVATENOTES'))));

  const cancelTextEdits = () => {
    props.setInlineSlideTextEditInfo && props.setInlineSlideTextEditInfo(undefined);
  };

  const slideInlineSlideDetails = () => {
    const inlineSlideTypes = ['PRIVATENOTES', 'SETTINGS', 'AUTHORIZEBLOCK', 'COLLAPSE'];
    const slideInlineTypes = props.slide.childArticlesSlideDetails
      ? Object.values(props.slide.childArticlesSlideDetails).map(sd => sd.type)
      : [];
    const filteredInlineSlideTypes = inlineSlideTypes.filter(inst => slideInlineTypes.includes(inst));
    return (
      <div className={`captionAreaAttachments`}>
        {filteredInlineSlideTypes &&
          filteredInlineSlideTypes.map((inlineSlideType: string, index: number) => {
            const inlineSlideInfo = props.inlineViewSlides && props.inlineViewSlides.find(i => i.articleType === inlineSlideType);

            let viewingArticleInline = inlineSlideInfo && inlineSlideInfo.articleType === openSlideAdditionListGideType;
            return (
              <React.Fragment key={index}>
                {index === 1 && <div className="captionAreaVerticalLine" />}
                <div className={`inlineView ${inlineSlideType.toLowerCase()}`} key={index}>
                  {props.slide.childArticlesSlideDetails &&
                    props.slide.childArticlesSlideDetails.find(cad => cad.type === inlineSlideType) && (
                      <>
                        {!viewingArticleInline && (
                          <div
                            className={'inlineViewDetails'}
                            onClick={() => {
                              if (inlineSlideType === openSlideAdditionListGideType) {
                                setOpenSlideAdditionListGideType(undefined);
                              } else {
                                toggleInlineView(inlineSlideType);
                              }
                            }}
                          >
                            <span>
                              <GideImage
                                src={`${getIconForChildArticleTypeAsString(inlineSlideType)}`}
                                style={{
                                  cursor: 'pointer',
                                  width: '24px',
                                  height: '24px',
                                  marginRight: '8px',
                                }}
                                className="color-secondary-600-svg"
                                alt="inline slides"
                              />
                            </span>
                            {props.slide.childArticlesSlideTypes &&
                              props.slide.childArticlesSlideTypes[inlineSlideType] &&
                              props.slide.childArticlesSlideTypes[inlineSlideType].map((type: string, i: number) => {
                                if (i === 4) {
                                  return (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" key={i}>
                                      <text
                                        fill="#919191"
                                        fillOpacity=".45"
                                        fillRule="evenodd"
                                        fontFamily="Aileron-Bold, Aileron"
                                        fontSize="10"
                                        fontWeight="bold"
                                        letterSpacing=".179"
                                      >
                                        <tspan x="5.571" y="15.5">
                                          +
                                          {props.slide.childArticlesSlideTypes &&
                                            props.slide.childArticlesSlideTypes[inlineSlideType].length - 4}
                                        </tspan>
                                      </text>
                                    </svg>
                                  );
                                } else if (i < 4) {
                                  return (
                                    <span className={`attachmentIcon ${props.hideThreeDotMenu ? ' hide' : ''}`} key={i}>
                                      <GideImage
                                        style={{width: '16px', height: '16px', marginRight: '8px'}}
                                        src={`/icons/slidetype/${type.toLowerCase()}/main.svg`} 
                                        alt={type.toLowerCase()}
                                      />
                                    </span>
                                  );
                                }
                                return <></>;
                              })}

                            <GideImage
                              style={{cursor: 'pointer'}}
                              src={viewingArticleInline ? '/icons/nav/arrow/arrow-up.svg' : '/icons/nav/arrow/arrow-down.svg'}
                              className={inlineSlideType === 'PRIVATENOTES' ? 'color-blue-100-svg' : 'color-primary-600-svg'}
                              alt="toggle inline display view"
                            />
                          </div>
                        )}
                        {viewingArticleInline && (
                          <div
                            className="button collapse"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <GideImage
                              style={{ marginRight: '3px' }}
                              src="/icons/nav/edit/edit-main.svg"
                              onClick={e => {
                                e.preventDefault();
                                props.openChildArticleModal &&
                                  inlineSlideInfo &&
                                  props.openChildArticleModal(
                                    props.slide,
                                    [getChildArticleTypeEnum(inlineSlideInfo.articleType)],
                                    getChildArticleTypeEnum(inlineSlideInfo.articleType),
                                    false,
                                  );
                              }}
                              alt={`edit inline slides`}
                            />
                            <div
                              style={{ display: 'flex' }}
                              onClick={() => {
                                if (inlineSlideType === openSlideAdditionListGideType) {
                                  setOpenSlideAdditionListGideType(undefined);
                                } else {
                                  toggleInlineView(inlineSlideType);
                                }
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: '100px',
                                  justifyContent: 'center',
                                }}
                              >
                                <div>Collapse</div>
                                <GideImage
                                  src={`${getIconForChildArticleTypeAsString(inlineSlideType)}`}
                                  style={{
                                    cursor: 'pointer',
                                    width: '24px',
                                    height: '24px',
                                  }}
                                  alt="inline slides"
                                />
                              </div>

                              <GideImage
                                src={`/icons/nav/arrow/arrow-up.svg`}
                                className={inlineSlideType === 'PRIVATENOTES' ? 'color-blue-100-svg' : 'color-primary-600-svg'}
                                alt="toggle inline display view"
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                </div>
              </React.Fragment>
            );
          })}
        {props.slide.link && (
          <span>
            <GideImage className="color-blue-100-svg" src="/icons/slidetype/links/main.svg" />
          </span>
        )}
      </div>
    );
  };

  const openFullTextEditor = () => {
    // this.props.inlineEditedSlide will only have a value if the slide was edited
    const slide = props.inlineEditedSlide ? props.inlineEditedSlide : props.slide;
    if (slide && props.openModal) {
      editSlide(slide, true);
    }

    // Blur the inline-text editor so that we will not have two editors open at once...
    // Which causes the page to crash... maybe there is a better way to handle this...
    (document.activeElement as HTMLElement).blur();
  };

  const onDeleteSlide = (e: any) => {
    // if (!isNullOrUndefined(props.article)) {
    //   e.stopPropagation();
    //   if (!window.confirm('Are you sure?')) return;
    //   agent.Slides.delete(props.article, props.slide.id);
    //   props.deleteSlide && props.deleteSlide(props.slide.id);
    // }

    props.enterSlideSelectionMode &&
      props.enterSlideSelectionMode({
        operation: SlideSelectionOperation.Delete,
        originSlideId: props.slide.id,
        actions: (
          <div className="deleteSlideFooter">
            <RoundedCornerButton
              label="Cancel"
              labelColor="var(--COLOR-SECONDARY-500)"
              style={{ height: '24px', backgroundColor: 'var(--COLOR-SECONDARY-200)' }}
              onClick={() => {
                // Reset the slide number to null which acts as setting it to end.
                props.updateSlideNumber && props.updateSlideNumber(-1);
                props.exitSlideSelectionMode && props.exitSlideSelectionMode();
              }}
              iconCssClass="color-secondary-600-svg"
              image={'/icons/nav/exit/alternative.svg'}
              imagePosition={'right'}
            />

            <GideImage src="/icons/content-alteration/delete.svg"
              className="color-secondary-300-svg"
              onClick={() => {
                agent.Slides.delete(props.article, props.slide.id);
                props.deleteSlide && props.deleteSlide(props.slide.id);
                props.exitSlideSelectionMode && props.exitSlideSelectionMode();
              }}
              alt="delete"
            />
            <RoundedCornerButton
              label="Delete"
              labelColor="white"
              style={{ height: '24px', backgroundColor: 'var(--COLOR-PRIMARY-600)' }}
              onClick={() => {
                agent.Slides.delete(props.article, props.slide.id);
                props.deleteSlide && props.deleteSlide(props.slide.id);
                props.exitSlideSelectionMode && props.exitSlideSelectionMode();
              }}
              icon={<icons.ContentAlteration_Delete color='var(--WHITES-NORMAL-1000)' />}
              imagePosition={'right'}
            />
          </div>
        ),
      });
  };

  /**
   * Set focus to input bar and change slide number to be 1 minus the current slide number
   */
  const onAddBelowSlide = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    const newSlideNumber = props.slidePosition + 1;
    props.updateSlideNumber && props.updateSlideNumber(newSlideNumber);
    props.enterSlideSelectionMode &&
      props.enterSlideSelectionMode({
        operation: SlideSelectionOperation.AddSlideBelow,
        originSlideId: props.slide.id,
        actions: (
          <>
            <RoundedCornerButton
              style={{ backgroundColor: 'transparent' }}
              label="Reset to end"
              labelColor="var(--COLOR-PRIMARY-600)"
              onClick={() => {
                // Reset the slide number to null which acts as setting it to end.
                props.updateSlideNumber && props.updateSlideNumber(-1);
                props.exitSlideSelectionMode && props.exitSlideSelectionMode();
              }}
              iconCssClass="color-primary-600-svg"
              image={'/icons/nav/exit/alternative.svg'}
              imagePosition={'right'}
            />
          </>
        ),
      });
  };
  /**
   * Set focus to input bar and change slide number to be 1 minus the current slide number
   */
  const onAddAboveSlide = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    // if (!isNullOrUndefined(props.openModal)) {
    //   e.stopPropagation();
    //   props.updateSlideNumber && props.updateSlideNumber(props.slidePosition);

    //   // Ensure this click outside the modal does not immediately close it.
    //   setTimeout(() => {
    //     if (!isNullOrUndefined(props.openModal)) {
    //       props.openModal({
    //         modalType: 'ChoicesModal',
    //       });
    //     }
    //   }, 200);
    // }
    const newSlideNumber = props.slidePosition;
    props.updateSlideNumber && props.updateSlideNumber(newSlideNumber);
    props.enterSlideSelectionMode &&
      props.enterSlideSelectionMode({
        operation: SlideSelectionOperation.AddSlideAbove,
        originSlideId: props.slide.id,
        actions: (
          <>
            <RoundedCornerButton
              style={{ backgroundColor: 'transparent' }}
              label="Reset to end"
              labelColor="var(--COLOR-PRIMARY-600)"
              onClick={() => {
                // Reset the slide number to null which acts as setting it to end.
                props.updateSlideNumber && props.updateSlideNumber(-1);
                props.exitSlideSelectionMode && props.exitSlideSelectionMode();
              }}
              iconCssClass="color-primary-600-svg"
              image={'/icons/nav/exit/alternative.svg'}
              imagePosition={'right'}
            />
          </>
        ),
      });
  };
  const onSelectSlide = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    props.enterSlideSelectionMode &&
      props.enterSlideSelectionMode({
        operation: SlideSelectionOperation.Select,
        originSlideId: props.slide.id,
        multiSlideSelectionMethod: MultiSlideSelectionMethod.Selection,
      });
  };
  const onAttachToSlide = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.stopPropagation();
    props.openChildArticleModal &&
      props.openChildArticleModal(props.slide, [ChildArticleType.Attachments], ChildArticleType.Attachments, false);
  };

  const clearTemplate = async () => {
    if (!isNullOrUndefined(props.onSubmit)) {
      const slide = {
        ...props.slide,
        data: {
          ...props.slide.data,
        },
        isTemplate: false,
      };
      const payload = await agent.Slides.update(slide.id, slide);
      // props.onSubmit(payload);
      onSubmitSlide(payload);
    }
  };
  const toggleInlineView = async (articleType: string) => {
    const inlineSlideInfo =
      props.inlineViewSlides && props.inlineViewSlides.find((ivs: InlineViewSlides) => ivs.articleType === articleType);
    if (!inlineSlideInfo) {
      const slideChildArticleResponse: ChildArticleDetail | undefined = await getChildArticleForSlide(
        props.slide,
        getChildArticleTypeEnum(articleType),
      );
      const slides = slideChildArticleResponse && slideChildArticleResponse.slides ? slideChildArticleResponse.slides : [];
      props.updateChildGidesOnSlideForGideType &&
        props.updateChildGidesOnSlideForGideType({ slideId: props.slide.id, articleType, slides });
      if (!slideChildArticleResponse) {
        setOpenSlideAdditionListGideType(undefined);
      } else {
        setOpenSlideAdditionListGideType(articleType);
      }
    } else {
      setOpenSlideAdditionListGideType(articleType);
    }
  };

  /**
   * This is used when the user enters slide selection mode where they can manually select
   * the slide they want to move or copy to. The user selects the slide then this fires
   * and does the move or copy to that location.
   */
  const handleSlideSelection = async () => {
    if (
      !isNullOrUndefined(props.article) &&
      props.slideSelectionModeDetail &&
      props.slideSelectionModeDetail.operation === SlideSelectionOperation.Copy
    ) {
      const response = await agent.Articles.copySlides(props.article.id, props.slidePosition, [
        props.slideSelectionModeDetail.originSlideId,
      ]);
      props.copySlides &&
        props.copySlides({
          insertedPosition: props.slidePosition,
          slides: response.slides,
        });
      props.updateSlideNumber && props.updateSlideNumber(props.slidePosition + 1);
    } else if (
      !isNullOrUndefined(props.article) &&
      props.slideSelectionModeDetail &&
      props.slideSelectionModeDetail.operation === SlideSelectionOperation.Move
    ) {
      if (props.moveSlides) {
        const selectedSlideIds: string[] = !isNullOrUndefined(props.slideSelectionModeDetail.originSlideId)
          ? [props.slideSelectionModeDetail.originSlideId]
          : props.multiSlideSelectionDetail ? props.multiSlideSelectionDetail.selectedSlideIds : [];

        let moveToPosition = props.slidePosition;
        if(props.slideSelectionModeDetail.originSlidePosition !== undefined && props.slidePosition > props.slideSelectionModeDetail.originSlidePosition) {
          moveToPosition -= 1;
        }
        if(props.slideSelectionModeDetail.originSlidePosition === undefined || moveToPosition !== props.slideSelectionModeDetail.originSlidePosition) {
          props.moveSlides(selectedSlideIds, moveToPosition);
        }
      }
      props.updateSlideNumber && props.updateSlideNumber(props.slidePosition + 1);
    } else if (
      !isNullOrUndefined(props.article) &&
      props.slideSelectionModeDetail &&
      props.slideSelectionModeDetail.operation === SlideSelectionOperation.EditChildArticle
    ) {
      if (props.openChildArticleModal && props.slideSelectionModeDetail && props.slideSelectionModeDetail.childArticleType) {
        props.openChildArticleModal(
          props.slide,
          [props.slideSelectionModeDetail.childArticleType],
          props.slideSelectionModeDetail.childArticleType,
          false,
        );
      }
    } else if (
      !isNullOrUndefined(props.article) &&
      props.slideSelectionModeDetail &&
      props.slideSelectionModeDetail.operation === SlideSelectionOperation.AddSlideAddition
    ) {
      if (props.addSlideAddition && props.slideSelectionModeDetail && props.slideSelectionModeDetail.childArticleType) {
        props.addSlideAddition(props.slide, getChildArticleTypeName(props.slideSelectionModeDetail.childArticleType));
      }
    }
    props.exitSlideSelectionMode && props.exitSlideSelectionMode();
  };

  const onEditSlide = () => {
    if (isAddonSlideType(props.slide.slideType)) {
      props.showNotification &&
        props.showNotification({
          message: `Edit is not currently supported for ${props.slide.slideType} slides`,
          type: NotificationType.INFO,
        });
    } else {
      editSlide(props.slide, false);
    }
  };

  const onSubmitSlide = (payload: SlideUpdateInfo) => {
    if (props.slide.slideType === 'TEXT') {
      payload.isTextEdit = true;
    };
    props.onSubmit && props.onSubmit(payload);
  };

  const editSlide = (slide: Slide, openTextSlideInFullEditor: boolean) => {
    if (slide.slideType === 'TEXT' && !slide.data.fullEditor && !openTextSlideInFullEditor) {
      props.onEditingText && props.onEditingText(slide);
    } else {
      // Need to replace any html tags if it is a simple text (i.e. NOT Rich Text) slide or header slide
      let slideToEdit = slide;
      if (slideToEdit.slideType === 'HEADER' || (slideToEdit.slideType === 'TEXT' && !slideToEdit.data.fullEditor)) {
        const field = slide.slideType === 'HEADER' ? 'title' : 'body';
        slideToEdit = {
          ...slideToEdit,
          data: {
            ...slideToEdit.data,
          },
        };
        if (!slide.data.fullEditor && slide.slideType === 'TEXT') {
          slideToEdit.data[field] = `<p>${stripTags(slideToEdit.data[field])}</p>`;
        } else {
          slideToEdit.data[field] = stripTags(slideToEdit.data[field]);
        }
      }
      // Replace block for the AuthorizeBlock vs Authorize slideType which both display
      // in the AuthorizeModal
      const slideType = slide.slideType.replace('BLOCK', '');
      const modalType = `${slideType.charAt(0)}${slideType.slice(1).toLowerCase()}Modal`;
      // window.setTimeout(() => {
        if (!isNullOrUndefined(props.openModal)) {
          if (slideEditorModalLookup[slideType]) {            
            props.openModal({
              modalType: `${slideEditorModalLookup[slideType]}`,
              modalProps: {
                currentUser: props.currentUser,
                article: props.article,
                editSlide: slideToEdit,
                position: slideToEdit.position,
                onSubmitSlide: onSubmitSlide,
                modalType: `${slideEditorModalLookup[slideType]}`
              },
            });
          } else {
            // HEADER Slide is a special case for now
            if(modalType === 'HeaderModal') {
              props.openModal({
                modalType: 'HeaderModal',
                modalProps: {
                  article: props.article,
                  slide: slideToEdit,
                  submitSlide: onSubmitSlide,
                  currentSlidePosition: props.slidePosition,
                  availableHeaderLevels: [slideToEdit.data.level],
                }
              });
            } else if (slideType === 'TEXT' /* && typeof slide.data.body !== 'string' */) {
              props.openModal({
                modalType: 'RichTextSlideEditor',
                modalProps: {
                  currentUser: props.currentUser,
                  article: props.article,
                  editSlide: slideToEdit,
                  position: slideToEdit.position,
                  onSubmitSlide: onSubmitSlide,
                  modalType: 'RichTextSlideEditor'
                },
              });
            } else {
              props.openModal({
                modalType: modalType,
                modalProps: {
                  article: props.article,
                  editSlide: slideToEdit,
                  currentSlidePosition: props.slidePosition,
                  onSubmitSlide: onSubmitSlide,
                  modalType: modalType
                },
              });
            }
          }
        }
      // }, 0);
    }
  };

  const onMoveToAttachments = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    props.enterSlideSelectionMode &&
      props.enterSlideSelectionMode({
        options: (
          <div className="slideSelectionTransferType">
            <RadioButtonComponent
              checked={false}
              label="Copy"
              name="transferType"
              value={SlideSelectionTransferType.Copy.toString()}
              change={args => {
                if (args && args.value === SlideSelectionTransferType.Copy.toString()) {
                  props.updateSlideSelectionOperationMode &&
                    props.updateSlideSelectionOperationMode({
                      transferType: SlideSelectionTransferType.Copy,
                    });
                }
              }}
            />
            <RadioButtonComponent
              checked={true}
              label="Move"
              name="transferType"
              value={SlideSelectionTransferType.Move.toString()}
              change={args => {
                if (args && args.value === SlideSelectionTransferType.Move.toString()) {
                  props.updateSlideSelectionOperationMode &&
                    props.updateSlideSelectionOperationMode({
                      transferType: SlideSelectionTransferType.Move,
                    });
                }
              }}
            />
          </div>
        ),
        operation: SlideSelectionOperation.AttachToSlide,
        transferType: SlideSelectionTransferType.Move,
        originSlideId: props.slide.id,
      });
  };
  const onTransfer = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    props.enterSlideSelectionMode &&
      props.enterSlideSelectionMode({
        options: (
          <div className="slideSelectionTransferType">
            <RadioButtonComponent
              checked={true}
              label="Copy"
              name="transferType"
              value={SlideSelectionTransferType.Copy.toString()}
              change={(args) => {
                if (args && args.value === SlideSelectionTransferType.Copy.toString()) {
                  props.updateSlideSelectionOperationMode &&
                    props.updateSlideSelectionOperationMode({
                      transferType: SlideSelectionTransferType.Copy,
                    });
                }
              }}
            />
            <RadioButtonComponent
              checked={false}
              label="Move"
              name="transferType"
              value={SlideSelectionTransferType.Move.toString()}
              change={(args) => {
                if (args && args.value === SlideSelectionTransferType.Move.toString()) {
                  props.updateSlideSelectionOperationMode &&
                    props.updateSlideSelectionOperationMode({
                      transferType: SlideSelectionTransferType.Move,
                    });
                }
              }}
            />
          </div>
        ),
        operation: SlideSelectionOperation.Transfer,
        transferType: SlideSelectionTransferType.Copy,
        originSlideId: props.slide.id,
      });
  };
  const onCopySlide = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    props.enterSlideSelectionMode &&
      props.enterSlideSelectionMode({
        operation: SlideSelectionOperation.Copy,
        originSlideId: props.slide.id,
      });
  };
  const onUndo = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {};

  // (See _image-view.scss (search SLIDE-WIDTH) for an additional setting regarding this.)
  const slideWidthPercentage = props.slide.widthPercentage ? props.slide.widthPercentage : 100;

  // TODO: BJ 10/30/2019 pass slideContentWidth as a var(--SomeVAR) so that the calculation for 'slideContentMaxWidth'
  // can be handled in css

  // Leave this here in case Jeff does not want the settings to take effect for Edge unless in website view. I don't think that should or will be the case.
  // const slideContentWidth =
  //   (props.view === 'website' || props.slide.width !== 'EDGE') && props.viewMode !== 'SWIPE' ? `${slideWidthPercentage}%` : '100%'; // When in non-website view for EDGE Or in SWIPE ignore the percentage setting.

  const slideContentWidth = `${slideWidthPercentage}%`;
  const slideContentMinWidth = props.viewMode === 'SWIPE' ? slideContentWidth : undefined;
  const slideContentMaxWidth =
    (props.view === 'website' || props.slide.width !== 'EDGE') && props.viewMode !== 'SWIPE'
      ? (props.view === 'website' && (props.slide.width === 'EDGE' || props.overrideWidth === 'EDGE')) || props.overrideWidth === 'TITLE'
        ? `${slideWidthPercentage}%`
        : props.slide.width === 'HALF' || props.overrideWidth === 'HALF'
          ? `calc((752px + ((100vw - 752px) / 2)) * ${slideWidthPercentage / 100})`
          : `${752 * (slideWidthPercentage / 100)}px`
      : '';

  // TODO: Do we need both maxHeight and maxContentHeight? Not sure if both are needed?
  const slideContentHeight = props.view === 'website' && props.slide.maxHeight ? `${props.slide.maxHeight}px` : undefined;

  const displayInlineTextEditing = authorViewing &&
    (props.viewMode === 'SCROLL' || props.viewMode === 'SLIDE') &&
    props.inlineSlideTextEditInfo &&
    props.inlineSlideTextEditInfo.inlineSlideTextEditId === props.slide.id;

  const displayEditorPanel = !displayInlineTextEditing && (
    !props.slideSelectionModeDetail ||
    (props.slideSelectionModeDetail &&
      ((props.slideSelectionModeDetail.operation === SlideSelectionOperation.AddSlideAbove ||
        props.slideSelectionModeDetail.operation === SlideSelectionOperation.AddSlideBelow ||
        props.slideSelectionModeDetail.operation === SlideSelectionOperation.Delete ||
        props.slideSelectionModeDetail.operation === SlideSelectionOperation.Transfer) &&
        props.slide.id !== props.slideSelectionModeDetail.originSlideId))
  );

  const displaySlideSelector =
    props.slideSelectionModeDetail &&
    ((props.slideSelectionModeDetail.operation !== SlideSelectionOperation.AddSlideAbove &&
      props.slideSelectionModeDetail.operation !== SlideSelectionOperation.AddSlideBelow &&
      props.slideSelectionModeDetail.operation !== SlideSelectionOperation.Delete &&
      props.slideSelectionModeDetail.operation !== SlideSelectionOperation.Transfer) ||
      props.slide.id === props.slideSelectionModeDetail.originSlideId);

  const slideContentContainerStyle: CSSProperties = {
    borderRadius: 5,
    border: (authorViewing &&
      (props.viewMode === 'SCROLL' || props.viewMode === 'SLIDE') &&
      props.inlineSlideTextEditInfo &&
      props.inlineSlideTextEditInfo.inlineSlideTextEditId === props.slide.id)
      ? '1px solid #FEB213' : undefined,
  };
    // Default is centered
    // NOTE: Alignment only makes sense if the slide is less than 100% of the container it is in.
    // (i.e.) Edge, Normal or Half but @ a percentage less than 100.
    // Need to double check this with Jeff, but that is my current understanding.
    if (props.slide.contentHorizontalAlignment === 'LEFT') {
      slideContentContainerStyle.alignItems = 'flex-start';
    } else if (props.slide.contentHorizontalAlignment === 'RIGHT') {
      slideContentContainerStyle.alignItems = 'flex-end';
    }

    if (props.slide.contentVerticalAlignment === 'TOP') {
      slideContentContainerStyle.justifyContent = 'flex-start';
    } else if (props.slide.contentVerticalAlignment === 'BOTTOM') {
      slideContentContainerStyle.justifyContent = 'flex-end';
    }

    styles.padding = `${props.slide.contentPaddingTop}px ${props.slide.contentPaddingRight}px ${props.slide.contentPaddingBottom}px ${
      props.slide.contentPaddingLeft
    }px`;

  const inlineView = props.inlineViewSlides && props.inlineViewSlides.find(iv => iv.articleType === openSlideAdditionListGideType);

  const inlineViewClass = inlineView ? ` inlineSlide ${inlineView.articleType.toLowerCase()}` : '';




  return (
    <div
      style={!isSlideshowSlidePlaying ? style : {}}
      id={props.slide.id}
      data-slide-id={props.slide.id}
      className={!isSlideshowSlidePlaying ? `slideView ${inlineViewClass}` : ''}
    >
      <div className={classes} style={slideContentContainerStyle}
      >
        <div
          className={slideViewClasses}
          style={{
            borderRadius: 5,
            maxWidth: slideContentMaxWidth,
            width: slideContentWidth,
            minHeight: slideContentHeight,
            minWidth: slideContentMinWidth,
          }}
        >
          {props.showAuthorHeader && (
            <AuthorHeader verb={'From:'} author={props.slide.author} date={props.slide.createdAt} authorViewing={authorViewing} />
          )}
          <div className={!isSlideshowSlidePlaying ? componentClasses : ''} style={!isSlideshowSlidePlaying ? styles : {}}>
            {/* ALL PROPS PASSED FROM SLIDEVIEW to SLIDES */}
            {SlideComponent && <SlideComponent {...{...props, openFullTextEditor}} />}
            {props.article &&
              props.article.type !== 'TEMPLATE' &&
              props.slide &&
              props.slide.isTemplate === true &&
              !isNullOrUndefined(props.onSubmit) && (
                <div className="slideTemplateOverlay">
                  <Button onClick={onEditSlide}>{`Edit ${props.slide.slideType}`} </Button>
                  <Button className="accept" onClick={clearTemplate}>
                    {`Accept`}
                  </Button>
                </div>
              )}
            {props.article &&
              props.article.type === 'TEMPLATE' &&
              props.slide &&
              props.slide.isTemplate === true && (
                <div
                  className="slideTemplateOverlay"
                  onClick={async e => {
                    if (props.article) {
                      // NOT SURE WHY THE Check above did not work but had to add this.
                      if (props.currentUser && props.currentUser.username === props.article.author.username) {
                        onEditSlide();
                      } else {
                        const response = window.confirm(`Would you like to copy this template from ${props.article.author.username}?`);
                        if (response) {
                          const copyOfArticle = await agent.Articles.copyTemplate(props.article);
                          props.copyArticle && props.copyArticle(copyOfArticle);
                        }
                      }
                    }
                  }}
                >
                  {props.currentUser &&
                    props.currentUser.username !== props.article.author.username &&
                    props.slidePosition === 0 && (
                      <span className="templateSlide">
                        <GideImage style={{ width: '20px', height: '20px' }} src={`/icons/content-alteration/copy.svg`} alt="copy template" />
                      </span>
                    )}
                  {props.currentUser &&
                    props.currentUser.username === props.article.author.username && (
                      <span className="templateSlide">
                        <GideImage
                          className="whites-normal-1000-svg"
                          style={{ width: '20px', height: '20px' }}
                          src={`/icons/slidetype/${props.slide.slideType.toLowerCase()}/main.svg`}
                          alt="slide type"
                        />
                      </span>
                    )}
                </div>
              )}
          </div>
          {props.slide.slideType !== 'INPUT' &&
            props.viewMode !== 'SWIPE' &&
            props.nextViewMode !== 'SLIDESHOW' &&
            // a slide's has child articles (attachments, notes, tbd) or Text Caption or Audio Caption
            displaySlideInformationSection && (
              <div className="slideViewSlideAddonsContainer">
                {hasValue(captionDisplayText) &&
                  (!props.inlineSlideTextEditInfo ||
                    props.inlineSlideTextEditInfo.inlineSlideTextEditId !== props.slide.id ||
                    props.inlineSlideTextEditInfo.type !== 'caption' ||
                    props.disableInlineTextEditing) && (
                    /* Text Caption */
                    <span
                      className="textCaption"
                      onClick={async () => {
                        if (props.viewMode === 'SLIDE' && authorViewing && !props.disableInlineTextEditing) {
                          // First check to see if there is a slide with unsaved edits
                          if (props.inlineEditedSlide && !isNullOrUndefined(props.onSubmit)) {
                            // Make sure if the edit is for a caption that the caption has no p tags
                            if (props.inlineEditedSlide.data.caption) {
                              props.inlineEditedSlide.data.caption = props.inlineEditedSlide.data.caption
                                .replace('<p>', '')
                                .replace('</p>', '');
                            }
                            const updatedSlide = await agent.Slides.update(props.inlineEditedSlide.id, props.inlineEditedSlide);
                            // props.onSubmit({
                            //   slide: updatedSlide.slide,
                            //   isTextEdit: true,
                            //   preventScrollToSlide: true,
                            // });
                            onSubmitSlide({
                              slide: updatedSlide.slide,
                              isTextEdit: true,
                              preventScrollToSlide: true,
                            });
                          }
                          props.setInlineSlideTextEditInfo &&
                            props.setInlineSlideTextEditInfo({
                              inlineSlideTextEditId: props.slide.id,
                              type: 'caption',
                            });
                        }
                      }}
                      dangerouslySetInnerHTML={{ __html: captionDisplayText }}
                    >
                      {/* {props.slide.data.caption} */}
                    </span>
                  )}
                {/* This allows the user to edit the caption of a slide inline */}
                {authorViewing &&
                  !props.disableInlineTextEditing &&
                  props.inlineSlideTextEditInfo &&
                  props.inlineSlideTextEditInfo.inlineSlideTextEditId === props.slide.id &&
                  props.inlineSlideTextEditInfo.type === 'caption' && (
                    <InlineTextEditor
                      inlineEditorClasses="inlineNormalTextEdit"
                      dataField={'caption'}
                      onChange={props.updateSlideWithInlineEdits}
                      slide={props.slide}
                      // saveEdits={props.onSubmit}
                      saveEdits={onSubmitSlide}
                      cancelTextEdits={cancelTextEdits}
                      setInlineSlideTextEditInfo={props.setInlineSlideTextEditInfo}
                      openFullTextEditor={openFullTextEditor}
                      isReadOnly={false}
                    />
                  )}

                {/* TODO: Attribution  */}

                {/* Slide Child Articles Details (NOTES, ATTACHMENTS)  */}
                {displaySlideInformationSection && slideInlineSlideDetails()}
                {!isNullOrUndefined(props.slide.data.audioCaption) && (
                  <AudioPlayer
                    playerType={AudioPlayerType.AudioCaption}
                    autoPlay={false}
                    src={props.slide.data.audioCaption.url}
                    color={'blue'}
                  />
                )}
              </div>
            )}
        </div>

        {(props.view !== 'website' || (props.view === 'website' && props.viewMode !== 'SCROLL')) && (
          <div className="slideFooter">
            {inlineView && (
              <div className="slideList inlineSlides">
                <div className={`inlineSlide ${inlineView.articleType}`}>
                  <SlideList
                    slides={inlineView.slides}
                    currentUser={props.currentUser}
                    view={props.view}
                    viewMode={props.viewMode}
                    viewOnly={true}
                    disableInlineTextEditing={true}
                  />
                  <div className={`collapse ${inlineView.articleType}`}>
                    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <div
                        onClick={() => {
                          if (inlineView.articleType === openSlideAdditionListGideType) {
                            setOpenSlideAdditionListGideType(undefined);
                          } else {
                            toggleInlineView(inlineView.articleType);
                          }
                        }}
                        style={{ display: 'flex', alignItems: 'center', width: '100px', justifyContent: 'center' }}
                      >
                        <span>Collapse</span>
                        <GideImage
                          src={`${getIconForChildArticleTypeAsString(inlineView.articleType)}`}
                          style={{
                            cursor: 'pointer',
                            width: '24px',
                            height: '24px',
                          }}
                          alt="collapse inline display view"
                        />
                      </div>
                      {inlineView.articleType !== 'SETTINGS' && (
                        <GideImage src="/icons/nav/edit/edit-main.svg"
                          onClick={e => {
                            e.preventDefault();

                            props.openChildArticleModal &&
                              props.openChildArticleModal(
                                props.slide,
                                [getChildArticleTypeEnum(inlineView.articleType)],
                                getChildArticleTypeEnum(inlineView.articleType),
                              );
                          }}
                          alt={`edit`}
                        />
                      )}
                    </span>
                    <GideImage
                      onClick={() => {
                        if (inlineView.articleType === openSlideAdditionListGideType) {
                          setOpenSlideAdditionListGideType(undefined);
                        } else {
                          toggleInlineView(inlineView.articleType);
                        }
                      }}
                      src="/icons/nav/arrow/arrow-up.svg"
                      className={inlineView.articleType === 'PRIVATENOTES' ? 'color-blue-100-svg' : 'color-primary-600-svg'}
                      alt="toggle inline display view"
                    />
                  </div>
                </div>
              </div>
            )}

            {/* {displayFooterActions && ( */}
            <>
              {displayEditorPanel && (
                <div className={`slideEditor`}>
                  <SlideEditPanel
                    className={footerActionClasses}
                    editMode={
                      props.viewMode !== 'SLIDE' || props.viewOnly
                        ? SlideEditMode.ViewOnly
                        : props.articleEditMode === 'cleanEdit' ? SlideEditMode.CleanEdit : SlideEditMode.AdvancedEdit
                    }
                    onEdit={onEditSlide}
                    onDelete={onDeleteSlide}
                    onAddBelow={onAddBelowSlide}
                    onMove={() => {
                      props.enterSlideSelectionMode &&
                        props.enterSlideSelectionMode({
                          operation: SlideSelectionOperation.Move,
                          originSlideId: props.slide.id,
                          originSlidePosition: props.slide.position
                        });
                    }}
                    slideNumber={props.slide.position + 1}
                    questionCommentInfo={
                      props.slide.childArticlesSlideDetails
                        ? {
                            questionsCount: props.slide.childArticlesSlideDetails
                              .filter(cad => cad.type === 'QUESTIONS')
                              .reduce((acc, cgq) => acc + cgq.count, 0),
                            commentsCount: props.slide.childArticlesSlideDetails
                              .filter(cad => cad.type === 'COMMENTS')
                              .reduce((acc, cgc) => acc + cgc.count, 0),
                          }
                        : undefined
                    }
                    onViewChildGide={(childGideType: ChildArticleType) => {
                      props.openChildArticleModal &&
                        props.openChildArticleModal(
                          props.slide,
                          [ChildArticleType.Comments, ChildArticleType.Questions],
                          childGideType,
                          true,
                        );
                    }}
                    onAttach={onAttachToSlide}
                    onSelect={onSelectSlide}
                    onAddAbove={onAddAboveSlide}
                    onTransfer={onTransfer}
                    onCopy={onCopySlide}
                    onMoveTo={onMoveToAttachments}
                    onUndo={onUndo}
                  />
                </div>
              )}
              {displaySlideSelector &&
                props.slideSelectionModeDetail && (
                  <SlideSelector
                    slideSelectionModeDetail={props.slideSelectionModeDetail}
                    multiSlideSelectionDetail={props.multiSlideSelectionDetail}
                    slidePosition={props.slidePosition}
                    onHandleSlideSelection={handleSlideSelection}
                    slideEditMode={props.articleEditMode === 'cleanEdit' ? SlideEditMode.CleanEdit : SlideEditMode.AdvancedEdit}
                    slideId={props.slide.id}
                    onMultiSlideSelection={(slideId: string, isChecked: boolean) => {
                      props.multiSlideSelectionChanged && props.multiSlideSelectionChanged(slideId, isChecked);
                    }}
                  />
                )}
            </>
            {/* )} */}
            {/* Icons for inline text editing */}
            
          </div>
        )}

        {props.multiSlideSelectionChanged &&
          authorViewing &&
          props.canPerformMultipleEditAction && (
            <div className="cardInlineEditCheckBox">
              <Checkbox
                onChange={(ev, data) =>
                  props.multiSlideSelectionChanged && props.multiSlideSelectionChanged(props.slide.id, data.checked ? data.checked : false)
                }
                checked={props.slideChecked}
                label=""
              />
            </div>
          )}

        {/* {isExpired && ( */}
        <div className={`expiredSlideViewOverlay${isExpired ? '' : ' hidden'}`}>
          <div className={`expired-slide${isExpired ? ' animate' : ''}`}>
            <span className="TEXTH2blackhigh-emphasisleft">Content has expired</span>
            <GideImage src="/icons/creationprocess/events.svg" alt="logo" />
          </div>
        </div>
        {/* )} */}
      </div>
      <div className={classNames('slideSelector', displaySlideSelector
        && props.slideSelectionModeDetail?.operation !== SlideSelectionOperation.Delete
        && props.slideSelectionModeDetail?.operation !== SlideSelectionOperation.Select
          ? 'displaySelector'
          : undefined
        )}
        onClick={(e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
          e.stopPropagation();
          handleSlideSelection();
        }}>
      </div>
    </div>
  );
};

export default withTheme(SlideView);
