import { SlideFileReference, SlideFileReferenceId } from './SlideFileReference';
import { LinkLayout } from './CommonEnums';
import { LinkPreviewImage } from '../components/Viewers/LinkViewers/LinkPreview/LinkPreview';
import { AudioFile } from './AudioFile';
export enum SlideItemSelectionActionType {
  OpenModal = 1,
  SwipeView = 2,
  OpenLink = 3,
  DoNothing = 4
}
export interface SlideItemSelectionSettings {
  slideItemSelectionActionType: SlideItemSelectionActionType;
  // The following should only have a value
  // Can be one of 3 things (SLIDE, ARTICLE, or URL)
  linkItemType?: string;
  // Can be one of 3 things (slideId, articleId, or exact url)
  linkItemReference?: string;
  openLinkInNewWindow?: boolean;
}
export const DefaultSlideItemTouchSettings: SlideItemSelectionSettings = {
  slideItemSelectionActionType: SlideItemSelectionActionType.SwipeView,
}
export interface SlideFile {
  id?: string;
  caption?: string;
  audioCaption?: AudioFile;
  attribution?: string;
  location?: string;
  hashTags?: string[];
  name?: string;
  onSelect?: SlideItemSelectionSettings;
}

export interface SlideReferenceFile extends SlideFile {
  slideFileReference?: SlideFileReference | SlideFileReferenceId;
  type: string; // GIDE or SLIDE or SLIDEFILE when slideFileReference has a value
  url?: string;
}

/**
 * Image, Video and Audio Files and soon other slide types will be able
 * to be simple {typeFile}s or they can be SlideReferenceFiles to other slides of 
 * the same type. 
 */
export interface MediaFile extends SlideReferenceFile {
  processingId?: string; 
  dataUrl?: any;
}
/**
 * Image, Video and Audio files are Media files and the files updloaded by the
 * user can be in a processing state handled by the fileProcessingReducer.
 */
export interface ImageFile extends MediaFile { }
export interface VideoFile extends MediaFile { }
export interface AudioSlideFile extends MediaFile {
  autoPlay: boolean;
  autoAdvance: boolean;
}

export interface LinkSlideFile extends SlideReferenceFile {
  displayMode?: LinkLayout;
  title?: string;
  image?: LinkPreviewImage;
  description?: string;
}
