import React from 'react';
import { SlideSelectionOperation, SlideSelectionInfo, SlideSelectionTransferType } from '../../../models/SlideSelectionInfo';
import { getIconForChildArticleType, getDisplayTextForChildArticleType, ChildArticleType } from '../../../models/ArticleLayoutEnum';
import { SlideEditMode } from '../SlideEditPanel/SlideEditPanel';
import { CheckBox as CheckBoxComponent } from '../../Shared/CheckBox/CheckBox';
import { MultiSlideSelectionDetail } from '../../../reducers/article';
import { contains } from 'ramda';
import GideImage from '../../Shared/Image/GideImage';
export interface SlideSelectorProps {
  slideSelectionModeDetail: SlideSelectionInfo;

  slideId: string;
  slidePosition: number;
  slideEditMode: SlideEditMode;
  multiSlideSelectionDetail?: MultiSlideSelectionDetail;
  onHandleSlideSelection: () => void;
  onMultiSlideSelection?: (slideId: string, isChecked: boolean) => void;
}

export default function SlideSelector(props: SlideSelectorProps) {
  const getLabel = (): string | undefined => {
    switch (props.slideSelectionModeDetail.operation) {
      case SlideSelectionOperation.AddSlideAddition:
        return `Add ${getDisplayTextForChildArticleType(props.slideSelectionModeDetail
          .childArticleType as ChildArticleType).toLowerCase()} to this slide`;
      case SlideSelectionOperation.Copy:
        return 'Copy to position';
      case SlideSelectionOperation.Move:
        return  props.slideSelectionModeDetail.originSlidePosition !== undefined && props.slideSelectionModeDetail.originSlidePosition !== props.slidePosition
         ? `Insert before position` // ${props.slideSelectionModeDetail.originSlidePosition && props.slideSelectionModeDetail.originSlidePosition > props.slidePosition ? 'before' : 'after'} position`
         : '';
      case SlideSelectionOperation.AddSlideBelow:
        return 'Next slide below here';
      case SlideSelectionOperation.AddSlideAbove:
        return 'Next slide above here';
      case SlideSelectionOperation.Transfer:
        return `${props.slideSelectionModeDetail.transferType === SlideSelectionTransferType.Copy ? 'Copy ' : 'Move '} to new gide`;
      case SlideSelectionOperation.Delete:
      case SlideSelectionOperation.Select:
        return undefined;
      default:
        return 'Unknown Operation';
    }
  };
  // const label =
  //   props.slideSelectionModeDetail.childArticleType && props.slideSelectionModeDetail.operation === SlideSelectionOperation.AddSlideAddition
  //     ? `Add ${getDisplayTextForChildArticleType(props.slideSelectionModeDetail.childArticleType).toLowerCase()} to this slide`
  //     : `${props.slideSelectionModeDetail.operation === SlideSelectionOperation.Copy ? 'Copy' : 'Move'} Slide To Position ${props.slidePosition + 1}`;
  const getIconForOperation = () => {
    switch (props.slideSelectionModeDetail.operation) {
      case SlideSelectionOperation.AddSlideBelow:
        return '/icons/nav/arrow/add-below.svg';
      case SlideSelectionOperation.AddSlideAbove:
        return '/icons/nav/arrow/add-above.svg';
      case SlideSelectionOperation.Transfer:
        return '/icons/content-alteration/newgide.svg';
      case SlideSelectionOperation.Copy:
        return '';
      case SlideSelectionOperation.Delete:
      case SlideSelectionOperation.Like:
      case SlideSelectionOperation.Move:
      case SlideSelectionOperation.Select:
        return '';
      default:
        return '';
    }
  };
  const icon =
    props.slideSelectionModeDetail.childArticleType && props.slideSelectionModeDetail.operation === SlideSelectionOperation.AddSlideAddition
      ? getIconForChildArticleType(props.slideSelectionModeDetail.childArticleType)
      : getIconForOperation();

  const label = getLabel();
  return (
    <div className="slideSelectorContainer">
      {props.slideSelectionModeDetail.operation !== SlideSelectionOperation.Select
        && props.slideSelectionModeDetail.actions && 
        <div className={`actions${props.slideSelectionModeDetail.operation === SlideSelectionOperation.Delete 
            ? ' deleteSlide' 
            : ''}`
          }>
          {props.slideSelectionModeDetail.actions}
        </div>
      }
      {props.slideSelectionModeDetail.operation !== SlideSelectionOperation.Select
        && !props.slideSelectionModeDetail.actions && (
        // Spacer - Don't delete
        <span />
      )}
      {(props.slideSelectionModeDetail.operation === SlideSelectionOperation.Select) && (
        <div style={{marginLeft: '4px'}}>
          <CheckBoxComponent
            className="TEXTSUBTITLEblackboldmedium-emphasisright"
            checked={props.multiSlideSelectionDetail
              && props.multiSlideSelectionDetail.selectedSlideIds
              && contains(props.slideId, props.multiSlideSelectionDetail.selectedSlideIds)
            }
            change={(e: any) => {
              props.onMultiSlideSelection
              && props.onMultiSlideSelection(props.slideId as string, e.checked === true);
            }}
            label="Select"
          />
        </div>
      )}
      {props.slideSelectionModeDetail.operation !== SlideSelectionOperation.Delete && (
        <div
          className={`selector${props.slideSelectionModeDetail.operation !== SlideSelectionOperation.AddSlideBelow ? ' preventClick' : '' }`}
          onClick={() => {
            if(props.slideSelectionModeDetail.operation !== SlideSelectionOperation.AddSlideBelow) {
              props.onHandleSlideSelection();
            }
          }}
        >
          {label && <span style={{ marginRight: '5px' }}>{label}</span>}
          {icon && <GideImage src={icon} alt={icon} className="color-secondary-600-svg" />}
          {props.slidePosition !== undefined && props.slideSelectionModeDetail.operation !== SlideSelectionOperation.Transfer && (
            <span className="slideSelectorSlideNumber" style={{ marginRight: '5px' }}>
              {props.slidePosition + 1}
            </span>
          )}
        </div>
      )}
    </div>
  );
}
