import React from 'react';

import SlideView from '../Gide/SlideView/SlideView';

const SlideSlide = props => {
  return (
    <div>
      <SlideView
        slide={props.slide.data.slide}
        slidePosition={props.slide.data.slide.position}
        currentUser={props.currentUser}
        article={props.article}
        view={props.view}
        viewMode={props.viewMode}
        openModal={props.openModal}
        onSubmit={props.onSubmit}
        onEditingText={props.onEditingText}
      />
    </div>
  );
};

export default SlideSlide;
