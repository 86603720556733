import React from 'react';
import { RenderLeafProps } from 'slate-react';
import { Editor } from 'slate';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const toggleMark = (editor: Editor, format: string) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

export const isMarkActive = (editor: Editor, format: string) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

export const getMarkProperty = (editor: Editor, format: string) => {
  const marks = Editor.marks(editor);
  const value = marks?.[format];
  return value;
}

export const renderLeaf = (props: RenderLeafProps) => {
  if (props.leaf.bold) {
    props.children = <strong>{props.children}</strong>
  }

  if (props.leaf.italic) {
    props.children = <em>{props.children}</em>
  }

  if (props.leaf.underline) {
    props.children = <u>{props.children}</u>
  }

  const style: CSSProperties = {
    fontFamily: props.leaf.font as string,
    fontSize: props.leaf.fontSize as string,
    color: props.leaf.color as string,
    backgroundColor: props.leaf.highlight as string,
  };

  return <span {...props.attributes} style={style}>{props.children}</span>
};
