import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { contains } from 'ramda';
import agent from '../../agent';
import { hasValue } from '../../utils/helperFunctions';
import DropZone from 'react-dropzone';
import { API_ROOT } from '../../constants/paths';
import { isNullOrUndefined } from 'util';
import superagent from 'superagent';
import {
  ensureImageFileOrientation,
  titleToPath,
} from '../../utils/helperFunctions';
import { ArticleLayoutView } from '../../models/ArticleLayoutEnum';
import GideImage from '../Shared/Image/GideImage';
class ArticleHeader extends Component {
  constructor(props) {
    super(props);

    this.state = { displaySearch: false, classes: 'flexRowFullFlexEnd' };
    this.toggleSearch = () => {
      if (this.state.displaySearch) {
        this.setState({ displaySearch: false, classes: 'flexRowFullFlexEnd' });
        this.props.onSearchStateChanged(false);
      } else {
        this.setState({ displaySearch: true, classes: 'flexRowFlexStart' });
        this.props.onSearchStateChanged(true);
      }
    };
    this.toggleChrome = () => {
      // this.props.setChromeVisibility({ showChrome: !this.props.showChrome, slide: this.props.slide, slides: this.props.slides });
      if (this.props.toggleChrome) {
        this.props.toggleChrome();
      } else {
        this.props.onToggleSwipeOverlays();
      }
    };
    this.searchGide = searchCriteria => {
      alert('Coming soon.');
    };
    this.deleteArticle = () => {
      if (!window.confirm('Are you sure you want to delete this Gide?')) return;
      this.props.deleteArticle(agent.Articles.del(this.props.article));
    };
    this.updateArticle = async (title, imageUrl) => {
      const article = {
        id: this.props.article.id,
        title: !isNullOrUndefined(title) ? title : undefined,
        image: imageUrl, // This can be undefined. If it is it won't modify the value on the server
      };
      if (!isNullOrUndefined(title)) {
        article.slug = titleToPath(title);
      }
      const result = await agent.Articles.update(article);
      this.props.onUpdateArticleTitleAndImage({title: article.title, image: article.image, slug: result.article.slug});
    };
    this.onDrop = (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length) {
        ensureImageFileOrientation(acceptedFiles[0]).then(imageFile => {
          superagent
            .post(`${API_ROOT}/util/upload`)
            .attach('theseNamesMustMatch', imageFile)
            .end((err, res) => {
              if (err) console.log(err);
              if (res.body && res.body.url) {
                const url = res.body.url;
                // Now save the article with the new image.
                this.updateArticle(null, url);
              }
            });
        });
        return;
      }
    };
    this.setArticleTitle = () => {
      const titleSlide = this.props.slides.find(
        s => s.slideType === 'HEADER' && s.data.level === 0,
      );
      const slide = titleSlide
        ? titleSlide
        : {
          slideType: 'HEADER',
          data: {
            audioCaption: null,
            caption: '',
            title: '',
            level: 0,
          },
        };
      this.props.openModal({
        modalType: 'HeaderModal',
        modalProps: {
          editSlide: slide,
          isNew: titleSlide ? false : true,
          isEditingPrimaryArticleTitle: true,
        },
      });
    };
  }

  render() {
    const authorViewing =
      this.props.currentUser &&
      this.props.currentUser.username === this.props.article.author.username;

    const imageNotSet =
      !hasValue(this.props.article.image) ||
      contains('visual-story-telling', this.props.article.image);

    const authorClassNames = classNames(
      authorViewing ? 'flexRowFlexNormal user-is-author' : 'flexRowFlexNormal',
    );
    const titleNotSet =
      !hasValue(this.props.article.title) ||
      this.props.article.title === 'Untitled';

    const headerClassNames = classNames(
      'articleTitleBar',
      'flexRowFull',
      this.props.showLeftSidebar ? 'leftSidebarVisible' : '',
    );

    return (
      <div className={headerClassNames}>
        <div className={authorClassNames}>
          <div className="verticalLine gide-author-separator" />
          {this.props.viewMode === 'SWIPE' && (
            <div className="percentGideCompleted">
              {this.props.percentArticleCompleted} % finished
            </div>
          )}
          {!authorViewing && (
            <Link
              to={`/@${this.props.article.author.username}`}
              className="gide-author-link"
            >
              <Image
                src={this.props.article.author.image}
                alt={this.props.article.author.username}
                className="gide-author-pic"
              />
            </Link>
          )}
          {authorViewing &&
            imageNotSet &&
            this.props.viewMode === 'SLIDE' && (
              <DropZone
                className="gideBarArticleNoImageDropZone"
                accept="image/jpeg, image/png"
                onDrop={this.onDrop.bind(this)}
                multiple={false}
              >
                <div className="gideBarArticleNoImage">
                  <GideImage src="/icons/slidetype/image/main.svg"
                    className="articleImage whites-normal-1000-svg"
                    alt={`□`}
                  />
                  <GideImage src="/icons/nav/plusicon.svg"
                    className="addArticleImage whites-normal-1000-svg"
                    alt={`□`}
                  />
                </div>
              </DropZone>
            )}
          {authorViewing &&
            hasValue(this.props.article.image) &&
            !contains('visual-story-telling', this.props.article.image) &&
            this.props.viewMode === 'SLIDE' && (
              <DropZone
                className="gideBarArticleNoImageDropZone"
                accept="image/jpeg, image/png"
                onDrop={this.onDrop.bind(this)}
                multiple={false}
              >
                <div className="gideBarArticleImage">
                  <GideImage
                    src={this.props.article.image}
                    className="articleImage"
                    alt={`□`}
                  />
                </div>
              </DropZone>
            )}
        </div>
        <div className="flexRowFlexStart">
          <span className="articleTitle">
            <span
              className="rootTitle truncate"
              onClick={() => {
                // Need to make sure not in inline slide article edit mode any more.
                this.props.exitInlineSlideArticleEditor();
                if(authorViewing && !titleNotSet) {
                  this.setArticleTitle();
                }
              }}
            >
              {this.props.article.title}

              {authorViewing &&
                titleNotSet &&
                this.props.viewMode === 'SLIDE' && (
                  <div className="editTitleBackground">
                    <GideImage src="/icons/nav/edit/edit-main.svg"
                      className="editArticleTitleIcon whites-normal-1000-svg"
                      onClick={this.setArticleTitle.bind(this)}
                      alt={`□`}
                    />
                  </div>
                )}
            </span>{' '}
            {this.props.additionUsername
              ? `- ADDition by @${this.props.additionUsername}`
              : ''}
          </span>
        </div>
        <div className={this.state.classes}>
          <span className="articleActions">
            {this.props.viewMode === 'SWIPE' && (
              <GideImage
                style={{ marginRight: '21px' }}
                className="icon gideBarOverlayIcon whites-normal-1000-svg"
                src="/icons/nav/swipe/overlay.svg"
                onClick={this.toggleChrome.bind(this)}
                alt={`□`}
              />
            )}
            {/* {this.props.viewMode === 'SWIPE' && (
              <GideImage
                style={{ marginRight: '11px' }}
                className="viewBarToggleIcon whites-normal-1000-svg"
                src="/icons/content-alteration/share.svg"
                onClick={() => {
                  alert('coming soon');
                }}
                alt={`□`}
              />
            )} 
            */}
            {this.props.viewMode === 'SCROLL' &&
              this.props.articleLayoutView === ArticleLayoutView.WideScreen && (
                <GideImage
                  className="viewModeIcon"
                  style={{ marginTop: '3px' }}
                  src="/icons/gide-bar/widescreen-edit.svg"
                  onClick={this.props.toggleWidewcreenEditMode}
                  alt={`□`}
                />
              )}
            {this.props.viewMode !== 'SWIPE' && (
              <GideImage
                className="viewModeIcon"
                style={{ marginTop: '3px' }}
                src="/icons/nav/display/swipe.svg"
                onClick={this.props.onSwipeModeClick}
                alt={`□`}
              />
            )}
            {/* <GideImage
              src={
                this.props.articleLayoutView === ArticleLayoutView.WideScreen
                  ? '/icons/nav/display/max-width.svg'
                  : '/icons/nav/display/normal.svg'
              }
              className={`viewModeIcon ${
                this.props.viewMode === 'SCROLL' ? 'active' : ''
                }`}
              alt={
                this.props.articleLayoutView === ArticleLayoutView.WideScreen
                  ? 'wide screen'
                  : 'responsive'
              }
              onClick={() => {
                if (this.props.viewMode !== 'SLIDE') {
                  this.props.onWideViewModeClick();
                } else {
                  this.props.onScrollModeClick();
                }
              }}
            /> */}

            {/* TODO: It appears that displaySearch will no longer used in the article header so may want to remove so it is not confusing.
              (e.g. this.state.displaySearch === false &&)
            */}

            {authorViewing &&
              this.props.viewMode !== 'SWIPE' && (
                <GideImage
                  className={`viewModeIcon ${
                    this.props.viewMode === 'SLIDE' ? 'active' : ''
                    }`}
                  src={
                    this.props.articleEditMode === 'cleanEdit'
                      ? '/icons/nav/edit/edit-main.svg'
                      : '/icons/nav/edit/edit-advanced.svg'
                  }
                  onClick={() => {
                    if (this.props.viewMode === 'SLIDE') {
                      this.props.toggleArticleEditMode({
                        articleEditMode:
                          this.props.articleEditMode === 'cleanEdit'
                            ? 'advancedEdit'
                            : 'cleanEdit',
                      });
                    } else {
                      this.props.onSlideModeClick();
                    }
                  }}
                  alt={`□`}
                />
              )}
          </span>
        </div>
      </div>
    );
  }
}
export default ArticleHeader;
