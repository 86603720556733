import { useEffect, useState, useCallback } from 'react';
import { 
  GideAction_ADD_SLIDE, 
  GideAction_MODAL_CLOSE, 
  GideAction_SET_TOASTER_MESSAGE 
} from '../../models/GideAction';
import { Dispatch } from 'redux';
import { ToasterMessageInfo } from '../../models/ToasterMessageInfo';
import { SlideFileProcessorInfo } from '../../reducers/FileProcessing/fileProcessing';
import { noop, closeModalForType } from '../../utils/helperFunctions';
import { Slide } from '../../models/Slide';
import { MODAL_CLOSE, SET_TOASTER_MESSAGE } from '../../constants/actionTypes';
import { ADD_SLIDE_FILE_FOR_PROCESSING, GideAction_ADD_SLIDE_FILE_FOR_PROCESSING } from '../../reducers/FileProcessing/fileProcessing.actions';
import { useDispatch } from 'react-redux';
import { CommonContext, CommonSlideEditorModalProps, CommonState, CommonSlideEditorProps } from './Modes/Common';
import { history } from '../../store';


export type SlideEditorEvents = GideAction_ADD_SLIDE | GideAction_MODAL_CLOSE | GideAction_SET_TOASTER_MESSAGE | GideAction_ADD_SLIDE_FILE_FOR_PROCESSING;

const isModeWhichRequiresSelectedItem = (mode: string) => {
  return (mode === "Edit" || mode === "Attribute" || mode === "OnTap" || mode === "Doodle" || mode === "Crop" || mode === "Delete");
}

export const useSlideEditorContext = (props: CommonSlideEditorProps, getSlide: () => Slide) => {
  const getModeAndSelectedItemIndex = useCallback((mode: string, slide: Slide, selectedItemIndex: number) => {
    const hasFiles = slide.data && slide.data.files && slide.data.files.length;
    const newMode = slide.slideType === "LINKS" ? 'Link' : 'New';
    return { 
      mode: isModeWhichRequiresSelectedItem(mode) && !hasFiles ? newMode : mode,
      selectedItemIndex: hasFiles ? selectedItemIndex : -1,
    };
  }, []);

  const [state, setState] = useState<CommonState>(() => {
    const slide = getSlide();
    const newMode = slide.slideType === "LINKS" ? 'Link' : 'New';
    const desiredMode: string = 
      (props.set && props.set.mode) ? props.set.mode
      : (slide.id || props.file) ? (slide.data && slide.data.files && slide.data.files.length === 1 ? "Edit" : "Preview")
      : newMode;
    const { mode, selectedItemIndex } = getModeAndSelectedItemIndex(desiredMode, slide, 0);
    
    return { mode, slide, tagList: [...props.article.tagList], selectedItemIndex };
  });
  useEffect(() => {
    const desiredMode = (props.set && props.set.mode) || state.mode;
    const { mode, selectedItemIndex } = getModeAndSelectedItemIndex(desiredMode, state.slide, state.selectedItemIndex);
    setState({ 
        ...state,
        mode,
        selectedItemIndex,
    });
  }, [props.set && props.set.mode]);
  const dispatch = useDispatch<Dispatch<SlideEditorEvents>>();
  const closeModal = () => dispatch({ type: MODAL_CLOSE });
  const showNotification =
    props.showNotification ||
    ((toasterMessageInfo?: ToasterMessageInfo) => dispatch({ type: SET_TOASTER_MESSAGE, payload: { toasterMessageInfo } }));
  const onSubmitSlide = props.onSubmitSlide;
  const addMediaFileProcessingInfo = (slidefileProcessingInfoList: SlideFileProcessorInfo[]) =>
    dispatch({type: ADD_SLIDE_FILE_FOR_PROCESSING, payload: {slidefileProcessingInfoList}});

  
  const commonSlideEditorModalProps: CommonSlideEditorModalProps = {    
    slide: state.slide,
    article: props.article,
    currentUser: props.currentUser,
    position: props.position,
    closeModal: () => closeModalForType(closeModal, history, props.modalType),
    onSubmitSlide: onSubmitSlide,
    showNotification: showNotification,
    addMediaFileProcessingInfo,
    onAnswerQuestion: noop,
    onCloseAllModals: noop,
    onShowPreview: () => {
      setState({
        ...state,
        mode: 'Preview',
        selectedItemIndex: -1,
      })
    },    
    displaySlideAttribution: false,
    question: undefined,
    showDeleteFile: false,
    tagList: state.tagList,
    onSlideUpdated: (slide: Slide) => {
      setState({
        ...state,
        mode: state.mode,
        slide: {
          ...slide,
          data: {
            ...slide.data,
          },
        },
      });
    },
    onDisplaySlideWidthSettings: () => {
      setState({ ...state, mode: 'Dimensions' });
    },
    onHashtagClicked: () => {
      setState({ ...state, mode: 'Tag'});
    },
    onShowAudioCaptionControls: () => {
      setState({ ...state, mode: 'Audio' });
    },
    onHideAudioCaptionControls: () => {
      if (state.selectedItemIndex > -1) {
        setState((state) => ({ ...state, mode: 'Edit' }));
      } else {
        setState((state) => ({ ...state, mode: 'Preview' }));
      }
    },
  }

  const isImageSlide = state.slide.slideType === "IMAGE";
  const isVideoSlide = state.slide.slideType === "VIDEO";
  const isLinkSlide = state.slide.slideType === "LINKS";

  const context: CommonContext = {
    props,
    state,
    setState,
    commonSlideEditorModalProps,
    isImageSlide,
    isVideoSlide,
    isLinkSlide,
  };

  return context;

}
