import React, { useState } from 'react';
import {
  Button,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
/* Icons */
import { ReactComponent as BoldIcon } from './icons/bold.svg';
import { ReactComponent as ItalicIcon } from './icons/italic.svg';
import { ReactComponent as UnderlineIcon } from './icons/underline.svg';
import { ReactComponent as BulletedIcon } from './icons/bulleted.svg';
import { ReactComponent as NumberedIcon } from './icons/numbered.svg';
import { ReactComponent as IncreaseIcon } from './icons/increase.svg';
import { ReactComponent as DecreaseIcon } from './icons/decrease.svg';
import { ReactComponent as LinkIcon } from './icons/link.svg';
import { ReactComponent as UndoIcon } from './icons/undo.svg';
import { ReactComponent as RedoIcon } from './icons/redo.svg';
import { Editor, Transforms, Range } from 'slate';
import { useSlate, ReactEditor } from 'slate-react';
import { isMarkActive, toggleMark } from './Leaf';
import { isBlockActive, toggleBlock, decreaseIndent, increaseIndent } from './Element';
import { HistoryEditor } from 'slate-history';
import { unwrapLink, wrapLink } from './withLinks';
import { toggleEmbed } from './withEmbeds';
import { ReactComponent as UpIcon } from './icons/arrow-up.svg';
import { ReactComponent as DownIcon } from './icons/arrow-down.svg';
import styles from './_riche-text-editor.module.scss';
import { RichTextEditorTypographyToolbar } from './RichTextEditorTypographyToolbar';
const StyledToggleButton = withStyles(theme => ({
  root: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButton);

export interface RichTextEditorButtonToolbarProps {
  onOpenResponsiveMenus: (isOpen: boolean) => void;
  useNormalWidth: boolean;
}

export const RichTextEditorButtonToolbar: React.FC<RichTextEditorButtonToolbarProps> = props => {
  // We need useSlate instead of useEditor, since it seems to re-render when the cursor moves.
  const editor = useSlate() as ReactEditor & HistoryEditor;
  // const useNormalWidth = useMediaQuery('(min-width:520px)');

  const mark = (format: string) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    toggleMark(editor, format);
  };

  const block = (format: string) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    toggleBlock(editor, format);
  };

  const increase = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    increaseIndent(editor);
  };

  const decrease = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    decreaseIndent(editor);
  };

  const undo = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    editor.undo();
  };

  const redo = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    editor.redo();
  };

  const embed = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    toggleEmbed(editor);
  };

  const [url, setUrl] = useState<string>();
  const [selection, setSelection] = useState<Range>();
  const [open, setOpen] = React.useState(false);
  const [displayResponsiveToolbarItems, setDisplayResponsiveToolbarItems] = useState(false);
  const handleOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (editor.selection) {
      if (!isBlockActive(editor, 'link')) {
        const url = editor.selection ? Editor.string(editor, editor.selection) : '';
        setUrl(url);
      }
      setSelection(editor.selection);
      setOpen(true);
    }
  };
  const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (selection) {
      Transforms.select(editor, selection);
      if (isBlockActive(editor, 'link')) {
        const [, nodePath] = Editor.node(editor, selection);
        const start = Editor.start(editor, nodePath);
        const end = Editor.end(editor, nodePath);
        Transforms.select(editor, { anchor: start, focus: end });
      }
      if (url) {
        wrapLink(editor, url);
      } else if (isBlockActive(editor, 'link')) {
        unwrapLink(editor);
      }
    }
    setOpen(false);
  };
  const ResponsiveToolBarItems = () => (<>
    <StyledToggleButton size="small" value={''} onMouseDown={increase}>
      <IncreaseIcon />
    </StyledToggleButton>
    <StyledToggleButton size="small" value={''} onMouseDown={decrease}>
      <DecreaseIcon />
    </StyledToggleButton>
    <StyledToggleButton size="small" value={''} selected={isBlockActive(editor, 'link')} onMouseDown={handleOpen}>
      <LinkIcon />
    </StyledToggleButton>

    <StyledToggleButton size="small" value={''} onMouseDown={undo}>
      <UndoIcon />
    </StyledToggleButton>
    <StyledToggleButton size="small" value={''} onMouseDown={redo}>
      <RedoIcon />
    </StyledToggleButton>
  </>);
  return (
    <div className={styles.richTextEditorToolbarContainer}>
      <div
        style={{
          paddingLeft: props.useNormalWidth ? 24 : 16,
          paddingRight: props.useNormalWidth ? 24 : 15,
          borderStyle: 'solid',
          borderColor: '#F4F5F5',
          borderWidth: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <StyledToggleButton size="small" value={''} selected={isMarkActive(editor, 'bold')} onMouseDown={mark('bold')}>
          <BoldIcon />
        </StyledToggleButton>
        <StyledToggleButton size="small" value={''} selected={isMarkActive(editor, 'italic')} onMouseDown={mark('italic')}>
          <ItalicIcon />
        </StyledToggleButton>
        <StyledToggleButton size="small" value={''} selected={isMarkActive(editor, 'underline')} onMouseDown={mark('underline')}>
          <UnderlineIcon />
        </StyledToggleButton>
        <StyledToggleButton size="small" value={''} selected={isBlockActive(editor, 'bulleted')} onMouseDown={block('bulleted')}>
          <BulletedIcon />
        </StyledToggleButton>
        <StyledToggleButton size="small" value={''} selected={isBlockActive(editor, 'numbered')} onMouseDown={block('numbered')}>
          <NumberedIcon />
        </StyledToggleButton>
        {props.useNormalWidth && (
          <ResponsiveToolBarItems />
        )}
        {!props.useNormalWidth && (
          <>
            <Button
              size="small"
              onMouseDown={() => {
                const display = !displayResponsiveToolbarItems;                
                setDisplayResponsiveToolbarItems(display);     
                props.onOpenResponsiveMenus(display);           
              }}
              disableRipple
              style={{
                background: '#F0F0F0',
                borderRadius: 2,
                marginLeft: 3,
                minWidth: 34,
                height: 34
              }}
            >
              {displayResponsiveToolbarItems ? <UpIcon /> : <DownIcon />}
            </Button>
          </>
        )}
      </div>
      {!props.useNormalWidth && displayResponsiveToolbarItems && (      
        <div className={styles.responsiveToolbarItems}>
          <ResponsiveToolBarItems />
        </div>
      )}
      {(props.useNormalWidth || (!props.useNormalWidth && displayResponsiveToolbarItems)) && (
         <RichTextEditorTypographyToolbar useNormalWidth={props.useNormalWidth} />
      )}
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Link</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter a URL for this link.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="url"
            label="URL"
            type="url"
            fullWidth
            value={url}
            onChange={(e: any) => {
              setUrl(e.target.value);
            }}
            onKeyDown={(e: any) => {
              console.log(e.key);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    
    </div>
  );
};
