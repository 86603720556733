import React, { useEffect, useState, useCallback, lazy, Suspense } from 'react';
// Import the Slate editor factory.
import { createEditor, Node, Element, Editor, Range, Point } from 'slate';
// Import the Slate components and React plugin.
import { Slate, Editable, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import isHotkey from 'is-hotkey'
import { RichTextEditorButtonToolbar } from './RichTextEditorButtonToolbar';
import { toggleBlock, renderElement } from './Element';
import { toggleMark, renderLeaf } from './Leaf';
import { withLinks } from './withLinks';
import { withEmbeds } from './withEmbeds';
import { RichTextEditorInlineButtonsSynchronizer } from './RichTextEditorInlineButtons';
import { useMediaQuery } from '@material-ui/core';

const SyncfusionRichTextEditor = lazy(() => import('../Editor/RichTextEditor'));

export const isPointAtRoot = (point: Point) => point.path.length === 2;
export const isRangeAtRoot = (range: Range) => isPointAtRoot(range.anchor) || isPointAtRoot(range.focus);

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'embed',
};

export interface RichTextEditorProps {
  key: string;
  value?: Node[] | string;
  onChange?: (value: Node[] | string) => void;
  onBlur?: (value: Node[] | string) => void;
  readOnly?: boolean;
  toolbars?: boolean;
}

export const RichTextEditor: React.FC<RichTextEditorProps> = (props) => {
  const useNormalWidth = useMediaQuery('(min-width:675px)');
  const useMobileWidth = useMediaQuery('(max-width:528px)')
  const useMiniMobileWidth = useMediaQuery('(max-width:358px)')
  const [editor] = useState(() => {
    // Create a Slate editor object that won't change across renders.
    const editor = withEmbeds(withLinks(withReact(withHistory(createEditor()))));
    const { insertBreak } = editor;
    editor.insertBreak = () => {  
      if (editor.selection) {
        const [lastNode, lastNodePath] = Editor.last(editor, editor.selection);
        const [listItemNode, listItemNodePath] = Editor.parent(editor, lastNodePath);
        if (Element.isElement(listItemNode)
          && (listItemNode.type === 'list-item')
        ) {
          if (!lastNode.text) {
            toggleBlock(editor, listItemNode.type);
            return;
          }
        }
      } 

      insertBreak();
    };
    return editor;
  });

  const useSyncfusionRichTextEditor = typeof props.value === "string";
  const defaultValue = useSyncfusionRichTextEditor
    ? ''
    : [{type:'paragraph', children:[{text:''}]}];
  const [value, setValue] = useState(props.value || defaultValue);
  const [responsiveMenuIsOpen, setResponsiveMenuIsOpen] = useState<boolean>(false);


  const [maxHeight, setMaxHeight] = useState<string>('calc(100vh - 225px)');
  
  useEffect(() => {
    setValue(props.value || defaultValue)
  }, [props.value]);

  useEffect(() => {
    setMaxHeight((useNormalWidth)
    ? 'calc(100vh - 225px)'
    : !responsiveMenuIsOpen 
      ? 'calc(100vh - 174px)'
      : useMiniMobileWidth 
        ? 'calc(100vh - 366px)'
        : useMobileWidth
          ? 'calc(100vh - 320px)'
          : 'calc(100vh - 270px)'
    );
    console.log('maxHeight', maxHeight);
  }, [responsiveMenuIsOpen, useNormalWidth, useMobileWidth]);

  const outerStyle: React.CSSProperties = props.toolbars ? {
    borderStyle: 'solid',
    borderColor: '#F4F5F5',
    borderWidth: 1,
    // height: "100%",
    flex: '1 1 100%',    
    paddingTop: 9,
    paddingBottom: 9,
    paddingLeft: 24,
    paddingRight: 24, 
    overflowY: 'scroll',
    maxHeight: maxHeight,
  } : {};
  const innerStyle: React.CSSProperties = props.toolbars ? {
    color: '#333',
    backgroundColor: '#FBFBFC',
    border: '1px solid #F0F0F0',
    borderRadius: 20,
    padding: 15,
    minHeight: "100%",
  } : !props.readOnly ? {
    color: '#333',
    padding: '10px 12px 8px 12px',
  } : {
    color: '#333',
  };

  const onChange = useCallback((newValue: Node[] | string) => {
    setValue(newValue);
    props.onChange?.(newValue);
  }, [props.onChange]);

  const onBlur = useCallback(() => {
    props.onBlur?.(value);
  }, [props.onBlur, value]);
  return (
    <div style={{
      backgroundColor: '',
      display: 'flex',
      flex: '1 1 0%',
      flexDirection: 'column',
      maxHeight: "calc(100% - 48px)",
    }}>
      {useSyncfusionRichTextEditor &&
        <Suspense fallback={<div style={{height: '23px'}} />}>
          <SyncfusionRichTextEditor 
            showToolbar={true}
            limitHeight={false}
            isReadOnly={!!props.readOnly}
            focusInputOnLoad={!props.readOnly}
            onExpand={(isExpanded: boolean) => {setResponsiveMenuIsOpen(isExpanded)}}
            // classes={}
            style={{
              height: maxHeight,
              padding: '5px',
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: '#f0f0f0',
              borderRadius: '20px',
              backgroundColor: "#fbfbfc",
              margin: '10px',              
            }}
            value={value as string}
            placeholder="Start typing"
            onChange={onChange}
            onBlur={onBlur}
          />
        </Suspense>
      }
      {!useSyncfusionRichTextEditor && 
      <Slate editor={editor}
        value={value as Node[]} 
        onChange={onChange}
      >
        <RichTextEditorInlineButtonsSynchronizer key={props.key} />
        {props.toolbars && 
          <>
            <RichTextEditorButtonToolbar
              useNormalWidth={useNormalWidth}
              onOpenResponsiveMenus={(isOpen: boolean) => {
              setResponsiveMenuIsOpen(isOpen);
            }} />           
          </>
        }
        <div style={outerStyle}>
          <Editable
            style={innerStyle}
            readOnly={props.readOnly}
            placeholder='Start typing'
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            onKeyDown={event => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event as any)) {
                  event.preventDefault()
                  const mark = (HOTKEYS as any)[hotkey]
                  toggleMark(editor, mark)
                }
              }
            }}
            onBlur={onBlur}
            autoFocus={!props.readOnly}
          />
        </div>
      </Slate>
      }
      {/* <pre>
        {JSON.stringify(value, undefined, 2)}
      </pre> */}
    </div>
  )
}
