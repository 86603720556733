import React, { CSSProperties } from 'react';
import styles from './_gide-title-dropdown-panel.module.scss';
import GideTitle, { GideTitleProps } from '../GideTitle/GideTitle';
import Avatar, { AvatarMenuLocation } from '../../../Shared/Avatar/Avatar';
import classNames from 'classnames';
export interface GideTitleDropdownPanelProps extends GideTitleProps {
  avatarImage: string;
  expanded: boolean;
  style?: CSSProperties;
  onOpenAvatarMenu: (location: AvatarMenuLocation, openExpanded: boolean) => void;
}
export default function GideTitleDropdownPanel(props: GideTitleDropdownPanelProps) {
  return (
    <div className={classNames(styles.gideTitleDropdownPanelContainer, props.expanded ? undefined : styles.collapsed)} style={props.style}>
      <Avatar
        avatarImage={props.avatarImage}
        imgStyle={{ width: '32px', height: '32px' }}
        onClick={() => props.onOpenAvatarMenu(AvatarMenuLocation.Left, true)}
        outerBorderColor="var(--COLOR-PRIMARY-700)"
        displayOuterBorder={true}
      />
      <div className={styles.dropdownTitle}>
        <GideTitle
          authorViewing={props.authorViewing}
          gideImage={props.gideImage}
          viewMode={props.viewMode}
          gideTitle={props.gideTitle}
          isMobile={props.isMobile}
          leftSidebarOpen={props.leftSidebarOpen}
          onSetSlideZero={props.onSetSlideZero}
        />
      </div>
    </div>
  );
}
